import { Col, Form, Input, Row, Switch } from "antd";
import { Select } from "../../../../components";
import { common_post } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";
import { apis, keys } from "../../../../constants";
import { fieldICD10, fieldNhomICD10 } from "../fieldsDanhMuc";
import formDanhMuc from "./formDanhMuc.module.less";
import { useState, useCallback } from "react";
import { debounce } from "lodash";

export default function FormIcd10() {
  const [dsNhomIcd10, setDsNhomIcd10] = useState([]);

  const layDsNhomIcd10 = async (search_string = "") => {
    const res = await common_post(apis.dm_ICD_lay_ds_nhom_co_hieu_luc, {
      search_string,
      limit: keys.limit,
    });

    if (res.status === "OK") {
      const id = fieldNhomICD10.id;
      const name = fieldNhomICD10.ten_nhom_icd;

      const data = res.result.map((item) => ({
        [id]: item[id],
        [name]: item[name],
      }));

      setDsNhomIcd10(data);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timKiemNhomIcd10 = useCallback(
    debounce((val) => layDsNhomIcd10(val), 1000),
    []
  );

  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={fieldICD10.ma_icd}
                label={i18n.t(languageKeys.field_Ma_icd)}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldICD10.ten_icd}
                label={i18n.t(languageKeys.field_Ten_icd)}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldICD10.nhom_icd}
                label={i18n.t(languageKeys.field_Nhom_icd)}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={dsNhomIcd10}
                  showSearch
                  onSearch={timKiemNhomIcd10}
                  onDropdownVisibleChange={(bool) => bool && layDsNhomIcd10()}
                  titleKey={fieldNhomICD10.ten_nhom_icd}
                />
              </Form.Item>
            </Col>

            {/* <Col span={12}>
              <Form.Item
                name={fieldICD10.loai_icd}
                label={i18n.t(languageKeys.field_Loai_icd)}
              >
                <Select placeholder={i18n.t(languageKeys.common_Chon)} />
              </Form.Item>
            </Col> */}
          </Row>
        </div>
      </div>

      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Ghi_chu)}</h4>

        <Form.Item name={fieldICD10.ghi_chu}>
          <Input.TextArea
            rows={3}
            placeholder={i18n.t(languageKeys.common_Nhap)}
          />
        </Form.Item>

        <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

        <Form.Item
          name={fieldICD10.hien_thi}
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
      </div>
    </>
  );
}
