import { Col, Form, Input, InputNumber, Row, Switch } from "antd";
import { memo, useEffect, useState } from "react";
import { Select } from "../../../../components";
import { apis } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";
import { fieldLoaiDvKiThuat, fieldNhomDvKiThuat, fieldNhomBhyt } from "../fieldsDanhMuc";
import formDanhMuc from "./formDanhMuc.module.less";
import { useSelector } from "react-redux";

const FormNhomDvkt = ({
  is_create_new = false,
  visible = false,
  initData = {},
  form,
}) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [dsNhomBhyt, setDsNhomBhyt] = useState([]);

  useEffect(() => {
    if (visible) {
      apiLayDsNhomBhyt("", initData[fieldNhomDvKiThuat.nhom_bhyt]);
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);


  const apiLayDsNhomBhyt = async (search_string = "", ID_NHOM_BHYT) => {
    console.log(ID_NHOM_BHYT);
    try {
      const requestData = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        search_string,
        partner_code: userProfile.partner_code,
        limit: 1000,
      };

      const res = await common_post(apis.dm_nhom_bhyt_lay_ds, requestData);

      if (res.status === "OK") {
        setDsNhomBhyt(res.result);
      }
    } catch (error) {
      HLog("Api lay ds nhom bhyt error", error);
    }
  };

  const handleSelectNhom = async (id) => {
    let nhom = dsNhomBhyt.filter((item) => {
      return item.ID === id;
    });
  };

  return (
    <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              name={fieldNhomDvKiThuat.ma_nhom}
              label={i18n.t(languageKeys.field_Ma_nhom)}
              rules={
                [
                  // {
                  //   required: true,
                  //   message: i18n.t(languageKeys.vui_long_nhap),
                  // },
                ]
              }
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={fieldNhomDvKiThuat.ten_nhom}
              label={i18n.t(languageKeys.field_Ten_nhom)}
              rules={[
                {
                  required: true,
                  message: i18n.t(languageKeys.vui_long_nhap),
                },
              ]}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
          <Col span={12}>
              <Form.Item
                name={fieldNhomDvKiThuat.nhom_bhyt}
                label={i18n.t(languageKeys.field_Nhom_bhyt)}
                // rules={[
                //   {
                //     required: true,
                //     message: i18n.t(languageKeys.vui_long_nhap),
                //   },
                // ]}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={dsNhomBhyt}
                  titleKey={fieldNhomBhyt.ten_nhom}
                  valueKey={fieldNhomBhyt.id}
                  showSearch
                  onSelect={(event) => {
                    handleSelectNhom(event);
                  }}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </Form.Item>
            </Col>
        </Row>
      </div>
    </div>

    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

      <Form.Item
        name={fieldNhomDvKiThuat.hien_thi}
        valuePropName="checked"
        initialValue={false}
      >
        <Switch />
      </Form.Item>
    </div>
  </>
  );
};

export default memo(FormNhomDvkt);
