import { ArrowRightOutlined } from "@ant-design/icons";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useMemo, useRef, useState } from "react";
import { LoginBg } from "../../assets/img";
import { LogoNew } from "../../assets/svg";
import apiServices from "../../config/apiServices";
import { keys, paths } from "../../constants";
import { HLog, clearLocalData, handleRedirect } from "../../helpers";
import { useEventListener } from "../../hooks";
import styles from "./home.module.less";
import {  Spin } from "antd";
import i18n,{ languageKeys,languages } from "../../i18n";

export const HomeLogin = () => {
  const [searchParams, setSearchParams] = useState();
  const forgotPassRef = useRef();
  const { keycloak } = useKeycloak();
  const keyCloakWindow = useRef();
  const [loading, setLoading] = useState(false);
  const localGetConfig = localStorage.getItem(keys.configHospital) || {};
  const configHospital = useMemo(() => {
    try {
      let result = JSON.parse(localGetConfig);
      return result;
    } catch (error) {
      return {};      
    }
  },[localGetConfig]);

  useEffect(() => {
    setSearchParams(new URL(window.location).searchParams);
  }, [window.location]);

  //  useEffect(() => {

  //  },[searchParams])

  useEffect(() => {
    if (+sessionStorage.getItem(keys.isReload)) {
      sessionStorage.clear();
      // Empty cache and hard reload the browser
      window.location.reload(true);
    }
  }, [sessionStorage]);

  useEffect(() => {

    if(!!configHospital && !!configHospital.CUSTOM_DOMAIN) {
      Object.keys(configHospital.CUSTOM_DOMAIN).forEach(key => {
        if(key in window) {
          window[key] = configHospital.CUSTOM_DOMAIN[key];
        }
      })
    }
  },[configHospital])


  const getPublicImage = (ID) => {

    try {
      let string = "";
      if(ID) {
        string = window.GATEWAY + `cis-public/public/file?ID=${ID}`
      } else {
        throw new Error('Không có ID');
      }

      return string;
    } catch (error) {
     HLog(error);
    }

  }

  const handleClickLogin = () => {
    if (keyCloakWindow.current && !keyCloakWindow.current.closed) {
      keyCloakWindow.current.focus();
      return;
    }
    const WIDTH = 800;
    const HEIGHT = 600;

    const TOP = window.top.outerHeight / 2 + window.top.screenY - HEIGHT / 2;
    const LEFT = window.top.outerWidth / 2 + window.top.screenX - WIDTH / 2;

    const loginUrl = keycloak.createLoginUrl({
      redirectUri: window.location.origin + paths.authenticated,
    });

    let url = loginUrl;

    const logoutFromLis = localStorage.getItem(keys.REDIRECT) === apiServices.URL_LIS;
    const logoutFromDanhMuc = localStorage.getItem(keys.REDIRECT) === apiServices.URL_DANH_MUC;
    const userName = searchParams.get("username");

    if ((!!logoutFromLis || !!logoutFromDanhMuc ) && !!keycloak.authenticated) {
      const logoutUrl = keycloak.createLogoutUrl({
        redirectUri: loginUrl,
        loginHint: typeof userName === "string" ? userName : "null",
      });

      localStorage.removeItem(keys.REDIRECT);

      url = logoutUrl;
    }

    keyCloakWindow.current = window.open(url, "_blank", `width=${WIDTH}, height=${HEIGHT}, top=${TOP}, left=${LEFT}`);
  };

  const handleMessage = (e) => {
    if (e.data === "LOGIN SUCCESS") {
      console.log("EVENT MSG", e);
      keyCloakWindow.current?.close();
      clearLocalData();
      keycloak.login();
    }
  };

  useEventListener("message", handleMessage, window);

  const onOpenModalPassword = () => {
    forgotPassRef.current.open();
  };

  const renderLogo = () => {
    if(configHospital && configHospital.CUSTOM_DOMAIN) {
       let { LOGO_ID, DIA_CHI } = configHospital.CUSTOM_DOMAIN;
      return <img src={getPublicImage(LOGO_ID)} className={styles['logo']}/>

    } else { 
        return <LogoNew 
        // onClick={() => handleRedirect("https://deepcare.io/")} 
        />
    }
  };

  const renderAddress = () => {
    if(configHospital && configHospital.CUSTOM_DOMAIN) {
      let { DIA_CHI } = configHospital.CUSTOM_DOMAIN;

      if (DIA_CHI) {
        if (typeof DIA_CHI === "string") {
          return i18n.t(languageKeys.dia_chi) + ': ' + DIA_CHI;
        } else {
          return i18n.t(languageKeys.dia_chi) + ': ' + i18n.language === languages.tieng_viet ? DIA_CHI["vi"] : DIA_CHI["en"];
        }
      } else {
        return '';
      }

   } else { 
      return i18n.t(languageKeys.dia_chi) + ': ' + i18n.t(languageKeys.dia_chi_deepcare);
   }
  }

  const renderContact = () => {
    try {
      if(configHospital && configHospital.CUSTOM_DOMAIN) {
        let { WEBSITE, HOTLINE,EMAIL,LINK_WEB } = configHospital.CUSTOM_DOMAIN;
  
        return {
          WEBSITE:LINK_WEB ? `Website: ${LINK_WEB}` : '',
          HOTLINE:HOTLINE ? `Hotline: ${HOTLINE}` : '',
          EMAIL:EMAIL ? `Email: ${EMAIL}` : '',
        }
     } else { 
       return {
        WEBSITE : 'online.h247.com.vn',
        HOTLINE: '1900 068 856',
        EMAIL : 'contact@h247.com.vn',
       };
     }
    } catch (error) {
      return {};
    }
  }
  const isDeepcare = !(configHospital && configHospital.CUSTOM_DOMAIN)

  return (
    <Spin spinning={loading}>
      <div style={{ backgroundImage: `url(${LoginBg})`, visibility: loading ? "hidden" : "visible" }} className={styles["container"]}>
        <div className={styles["main"]}>
          <div className={styles["inner"]}>
            <div className={styles["logo-wrapper"]}>{renderLogo()}</div>

            <p>{i18n.t(languageKeys.title_giai_phap_phong_kham)}</p>

            <div className={styles["btn-wrapper"]} onClick={handleClickLogin}>
              <button>{i18n.t(languageKeys.Dang_nhap)}</button>
              <div className={styles["arrow-wrapper"]}>
                <ArrowRightOutlined />
              </div>
            </div>
            {/* <Typography.Link onClick={onOpenModalPassword} style={{marginTop:12}} underline>Quên mật khẩu?</Typography.Link> */}
          </div>
        </div>

        <div className={styles["footer"]}>
          <div>{renderAddress()}</div>

          {/* <div>Tel: 0983 858 100</div> */}
          {isDeepcare ? (
            <>
            <div>Website: {renderContact().WEBSITE}</div>

            <div>Hotline: {renderContact().HOTLINE}</div>

            <div>Email: {renderContact().EMAIL}</div>
          </>
          ) : (
            <>
              <div>{renderContact().WEBSITE}</div>

              <div>{renderContact().HOTLINE}</div>

              <div>{renderContact().EMAIL}</div>
            </>
          )}
        </div>
        {/* <QuenMatKhau ref={forgotPassRef}/> */}
      </div>
    </Spin>
  );
};
