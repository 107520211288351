import React, { useState, useEffect, useImperativeHandle } from 'react'
import { FormDrawer, Select } from '../../../../../components'
import { Row, Col, Form, Input, Button, Spin, Space } from "antd"
import { useForm } from 'antd/lib/form/Form'
import i18n, { languageKeys } from '../../../../../i18n'
import { notification, Switch } from 'antd'
import { common_post, handleCheckPermissions, HLog } from '../../../../../helpers'
import { apis, keys } from '../../../../../constants'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { EditFilled, PlusOutlined } from '@ant-design/icons'
import { useEventListener } from '../../../../../hooks'
import styles from "./modalKho.module.less"
import { featureKeys, widthDrawerKeys } from '../../../../../constants/keys'
import { CheckRoleTooltip } from 'components/CheckRoleTooltip/CheckRoleTooltip'
import { useMemo } from 'react'
const { Item } = Form
function ModalKho({
    reloadList = () => {},
    isAddAllowed=true,
    isEditAllowed=true
 }, ref) {
    const [form] = useForm()
    const [visible, setVisible] = useState(false)
    const userProfile = useSelector(state => state.auth.user)


    const [isEdit, setIsEdit] = useState(false)
    const [itemEdit, setItemEdit] = useState()
    const [dsPhong, setDsPhong] = useState()
    const [loadingPhong, setLoadingPhong] = useState(false)
    const [selectedPhong, setSelectedPhong] = useState([])
    const [selectLoaiKho, setSelectedLoaiKho] = useState() // loại kho

    const [dsKhoChuyen, setDsKhoChuyen] = useState()
    const [loadingKhoChuyen, setLoadingKhoChuyen] = useState(false)
    const [selectedKhoChuyen, setSelectedKhoChuyen] = useState([])

    const [loadingNhanSu, setLoadingNhanSu] = useState(false)
    const [dsNhanSu, setDsNhanSu] = useState([])
    const [selectedNhanSu, setSelectedNhanSu] = useState()
    const [loading, setLoading] = useState(false)

    const [selectedNguoiBan , setSelectedNguoiBan] = useState([])
    const checkRoleArr = useMemo(() => handleCheckPermissions(userProfile, featureKeys.ton_kho), [userProfile]);

    useImperativeHandle(ref, () => ({
        open: (data) => {
            if (data) {
                handleOpenEdit(data)
            }
            setVisible(true)
        },
    }));
    useEffect(() => {
        if (selectLoaiKho) {
          layDanhKhoHieuLuc()
        }
      }, [selectLoaiKho])

    function handleClose() {
        form.resetFields()
        isEdit && setIsEdit(false)
        itemEdit && setItemEdit()
        setSelectedLoaiKho()
        setSelectedKhoChuyen([])
        setSelectedPhong([])
        setSelectedNguoiBan([])
        setVisible(false);

    }

      //api lấy danh sách kho thuốc hiện có
   const layDanhKhoHieuLuc = async (search_string = "", page = 1) => {
    const LOAI_THUOC = selectLoaiKho === "NHA_THUOC" ? "NHA_THUOC" : "THUOC_THUONG"
    let body = {
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
      search_string,
      page : page,
      limit : keys.limit,
      LOAI_THUOC,
      LOAI_TRU : isEdit ? [itemEdit.ID] :  [],
      FROM_ADD_EDIT : true
    };
    setLoadingKhoChuyen(true)

    try {
      let res = await common_post(apis.lay_ds_kho_thuoc_hieu_luc, body, false);
      if (res && res.status === "OK") {
        // let { total, current_page, result } = res;
        setLoadingKhoChuyen(false)
        setDsKhoChuyen(res.result.map(item => ({
          ID : item.ID,
          TEN_KHO : item.TEN_KHO
        })))

      }
    } catch (error) {
      HLog("Lỗi lấy danh sách phòng: ", error);
    }
  }

    const handleOpenEdit = async (record) => {
        setIsEdit(true)
        setLoading(true)
        let body = {
            BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
            partner_code: userProfile.partner_code,
            ID: record.ID
        };
        try {
            const res = await common_post(apis.lay_chi_tiet_kho_thuoc, body)
            if (res && res.status === "OK") {
                let data = res.result
                //setVisible(true)
                setItemEdit(data)
                layDsNhanSu()
                setSelectedLoaiKho(data.LOAI_KHO)
                form.setFields(
                    Object.values(fieldNames).map(item => {
                        if (item === fieldNames.KHO_CHUYEN) {
                            let list_kho_chuyen = data[item].map(_i => JSON.stringify({ ID: _i.ID, TEN_KHO: _i.TEN_KHO }))
                            setSelectedKhoChuyen(list_kho_chuyen)
                            return {
                                name: item,
                                value: list_kho_chuyen
                            }
                        }
                        if (item === fieldNames.PHONG_KE_DON) {
                            let list_phong = data[item].map(_i => JSON.stringify({ ID: _i.ID, TEN_KHOA_PHONG: _i.TEN_KHOA_PHONG }))
                            setSelectedPhong(list_phong)
                            return {
                                name: item,
                                value: list_phong
                            }
                        }
                        if (item === fieldNames.NHANSU_ID) {
                            return {
                                name: item,
                                value: data.THU_KHO
                            }
                        }
                        if (item === fieldNames.LIST_NGUOI_BAN) {
                            let list_nguoi_ban = data[item].map(_i => JSON.stringify({ NHANSU_ID: _i.NHANSU_ID, THU_KHO: _i.HO_TEN }))
                            setSelectedNguoiBan(list_nguoi_ban)
                            return {
                                name: item,
                                value: list_nguoi_ban
                            }
                        }
                        return {
                            name: item,
                            value: data[item]
                        }
                    })
                )
            }
            setLoading(false)
        } catch (error) {

        }
    }

    // api lấy danh sách người tiếp đón
    const layDsNhanSu = async (search_string = "") => {
        setLoadingNhanSu(true)
        let req = {
            partner_code: userProfile.partner_code,
            BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
            search_string,
            limit:100,
            //  page,
        }
        try {
            let res = await common_post(apis.dm_lay_ds_user, req, false)
            if (!!res && res.status === "OK") {
                let { result } = res
                setDsNhanSu(result.map(item => {
                    return {
                        NHANSU_ID: item.NHANSU_ID,
                        THU_KHO: item.HO.trim() + " " + item.TEN.trim()
                    }
                }))
            }
        } catch (error) {
            HLog("LAYDS NGUOI TIEP DON FAIL", error)
        }
        setLoadingNhanSu(false)
    }

    //api lấy danh sách kho thuốc hiện có
    const layDanhSachPhong = async (search_string = "", page = 1) => {
        let body = {
            BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
            partner_code: userProfile.partner_code,
            search_string,
            page: page,
            limit: keys.limit,
            LOAI_PHONG: "KHAM_BENH"
        };
        setLoadingPhong(true)

        try {
            let res = await common_post(apis.khambenh_lay_dm_phong, body, false);
            if (res && res.status === "OK") {
                // let { total, current_page, result } = res;
                setLoadingPhong(false)
                setDsPhong(res.result.map(item => ({ ID: item.ID, TEN_KHOA_PHONG: item.TEN_KHOA_PHONG })))
            }
        } catch (error) {
            HLog("Lỗi lấy danh sách phòng: ", error);
        }
    };

    // api thêm mới kho thuốc
    const handleFinish = async (value) => {
        console.log(value)
        setLoading(true)
        let body = {
            ...value,
            BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
            partner_code: userProfile.partner_code,
            THOI_GIAN_TAO: moment().format("YYYYMMDD"),
            NHANSU_ID: !isEdit ? value.NHANSU_ID : value.NHANSU_ID.includes(" ") ? itemEdit.NHANSU_ID : value.NHANSU_ID,
            LIST_PHONG: value.LIST_PHONG ? value.LIST_PHONG.map(item => JSON.parse(item).ID) : [],
            KHO_CHUYEN: value.KHO_CHUYEN ? value.KHO_CHUYEN.map(item => JSON.parse(item).ID) : [],
            LIST_NGUOI_BAN : value.LIST_NGUOI_BAN ? value.LIST_NGUOI_BAN.map(item => JSON.parse(item).NHANSU_ID) : []
        };
        let dataRequest = isEdit ? { ...body, ID: itemEdit.ID } : body
        try {
            let url = isEdit ? apis.cap_nhat_thong_tin_kho_thuoc : apis.them_kho_thuoc
            const res = await common_post(url, dataRequest, false);
            if (res && res.status === "OK") {
                notification.success({
                    message: isEdit ? i18n.t(languageKeys.cap_nhat_thanh_cong) : i18n.t(languageKeys.them_moi_thanh_cong),
                    placement: "bottomLeft",
                });
                reloadList()
                handleClose();
            } else {
                notification.error({
                    message: i18n.t(languageKeys.thao_tac_that_bai),
                    placement: "bottomLeft",
                });
            }
            setLoading(false)
        } catch (error) {
            HLog("Lỗi tạo::", error);
        }
    };

      // hàm xử lý sự kiện phím tắt
  const keydownEvent = (event) => {

    if(event.ctrlKey && event.key === 's'){
      event.stopPropagation();
      event.preventDefault();
      if(!itemEdit && !isAddAllowed || !!itemEdit && !isEditAllowed)    return
      form.submit();
    }else if(event.ctrlKey && event.key === 'd'){
      event.stopPropagation();
      event.preventDefault();
      form.resetFields();
    }
  }
    useEventListener("keydown", keydownEvent, window.document, visible);
    return (
        <FormDrawer
            width={widthDrawerKeys.small}
            visible={visible}
            title={isEdit ? i18n.t(languageKeys.chi_tiet_kho) : i18n.t(languageKeys.txt_them_moi_kho_thuoc)}
            //txt_them_moi_kho_thuoc
            //onClickBack={() => handleClose()}
            onCancel={() => handleClose()}
            hiddenTopAction={true}
            // showPlusIcon={!isEdit ? true : false}
            footer={
                <Row gutter={10} justify = "end">
                    <Col>
                       <Button onClick={handleClose} ghost type="primary">
                            {i18n.t(languageKeys.common_Thoat) + " (ESC)"}
                        </Button>
                    </Col>

                    <Col>
                    <CheckRoleTooltip hasTooltip={false} isAllowed={!itemEdit && isAddAllowed || !!itemEdit && isEditAllowed} placement='topRight'>
                        <Button onClick={() => form.submit()} type="primary"
                            // icon = {isEdit ? <EditFilled  /> : <PlusOutlined />}
                            disabled={!itemEdit && !isAddAllowed || !!itemEdit && !isEditAllowed}
                        >
                            { i18n.t(languageKeys.luu) + ` (Ctrl + S)`}
                        </Button>
                    </CheckRoleTooltip>
                    </Col>
                </Row>
            }
        >
            <Spin spinning={loading}>
            <h4 style={{ margin: "20px" }}>{i18n.t(languageKeys.field_Thong_tin_co_ban)}</h4>
            <Form
                form={form}
                onFinish={handleFinish}
                layout="vertical"
                //className={kho["add-kho-container"]}
                style={{ margin: "20px", border: "1px solid #E2E2E2", borderRadius: 10, overflow : "auto" }}
                className={styles.container}
                disabled={!itemEdit && !isAddAllowed || !!itemEdit && !isEditAllowed}
            >
                <Row style={{ padding: "15px" }}>
                    <Col span={8} >
                        <Item label={i18n.t(languageKeys.field_Ma_kho)} name={fieldNames.MA_KHO}>
                            <Input placeholder={i18n.t(languageKeys.placeholder_Tao_tu_dong)} />
                        </Item>
                    </Col>

                    <Col span={8} style={{paddingLeft : "10px"}}>
                        <Item label={i18n.t(languageKeys.field_Ten_kho)} name={fieldNames.TEN_KHO} rules={[
                            {
                                required: true,
                                message : i18n.t(languageKeys.vui_long_nhap)
                            }
                        ]}>
                            <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
                        </Item>
                    </Col>

                    <Col span={8} style={{paddingLeft : "10px"}}>
                        <Item
                            label={i18n.t(languageKeys.field_Thu_kho)}
                            name={fieldNames.NHANSU_ID}
                            rules={[
                                {
                                    required: true,
                                    message : i18n.t(languageKeys.vui_long_nhap)
                                }
                            ]}
                        >
                            <Select
                                disabled={!itemEdit && !isAddAllowed || !!itemEdit && !isEditAllowed}
                                onDropdownVisibleChange={(value) => {
                                    value && dsNhanSu.length < 1 && layDsNhanSu()
                                }}
                                dataSource={dsNhanSu}
                                titleKey="THU_KHO"
                                valueKey="NHANSU_ID"
                                loading={loadingNhanSu}
                            />
                        </Item>
                    </Col>

                    <Col span={12}>
                        <Item label={i18n.t(languageKeys.field_Loai_kho)} name={fieldNames.LOAI_KHO} rules={[
                            {
                                required: true,
                                message : i18n.t(languageKeys.vui_long_nhap)
                            }
                        ]}>
                            <Select
                                dataSource={DATA_LOAI_KHOA}
                                titleKey="name"
                                valueKey="key"
                                onSelect={(value) => {
                                    if (selectLoaiKho && selectLoaiKho != value) {
                                        setSelectedKhoChuyen([])
                                        form.setFields([
                                            {
                                                name: fieldNames.KHO_CHUYEN,
                                                value: []
                                            }
                                        ])
                                    }
                                    setSelectedLoaiKho(value)
                                }}
                                disabled= {(isEdit && selectedKhoChuyen.length > 0 ? true : false) || (!itemEdit && !isAddAllowed || !!itemEdit && !isEditAllowed)}
                            />
                        </Item>
                    </Col>


                    <Col span={12} style={{paddingLeft : "10px"}}>
                        <Item label={i18n.t(languageKeys.field_Doi_tuong_benh_nhan)} name={fieldNames.LOAI_BENH_NHAN_AP_DUNG}>
                            <Select
                                dataSource={LOAI_BENH_NHAN_AP_DUNG}
                                titleKey="name"
                                valueKey="key"
                                disabled={!itemEdit && !isAddAllowed || !!itemEdit && !isEditAllowed}
                            />
                        </Item>
                    </Col>

                    {selectLoaiKho !== "KHO_TONG" &&<Col span={24}>

                    <Item label={i18n.t(languageKeys.kho_chuyen)} name={fieldNames.KHO_CHUYEN}>
                        <Select
                            onDropdownVisibleChange={(value) => {
                                if (!selectLoaiKho) {
                                    notification.warning({  message : i18n.t(languageKeys.duoc_message_chua_chon_loai_kho) })
                                }
                            }}
                            dataSource={dsKhoChuyen ? dsKhoChuyen : []}
                            // valueKey = "ID"
                            titleKey="TEN_KHO"
                            loading={loadingKhoChuyen}
                            multiple={true}
                            selectedList={selectedKhoChuyen}
                            onChange={setSelectedKhoChuyen}
                            setSelectedList={(arr) => {
                                setSelectedKhoChuyen(arr)
                                form.setFields([
                                    {
                                        name: fieldNames.KHO_CHUYEN,
                                        value: arr
                                    }
                                ])
                            }}
                            disabled={!itemEdit && !isAddAllowed || !!itemEdit && !isEditAllowed}
                        />
                    </Item>

                    </Col>}


                    {selectLoaiKho !== "KHO_TONG" && <Col span={24} >
                        <Item label={i18n.t(languageKeys.field_Ten_phong)} name={fieldNames.PHONG_KE_DON}>
                            <Select
                                onDropdownVisibleChange={(value) => {
                                    value && !dsPhong && layDanhSachPhong()
                                }}
                                dataSource={dsPhong ? dsPhong : []}
                                // valueKey = "ID"
                                titleKey="TEN_KHOA_PHONG"
                                loading={loadingPhong}
                                multiple={true}
                                selectedList={selectedPhong}
                                onChange={setSelectedPhong}
                                setSelectedList={(arr) => {
                                    setSelectedPhong(arr)
                                    form.setFields([
                                        {
                                            name: fieldNames.PHONG_KE_DON,
                                            value: arr
                                        }
                                    ])
                                }}
                                disabled={!itemEdit && !isAddAllowed || !!itemEdit && !isEditAllowed}
                            />
                        </Item>
                    </Col> }

                    {selectLoaiKho !== "KHO_TONG" && <Col span={24} >
                        <Item label={i18n.t(languageKeys.phan_quyen_nguoi_ban)} name={fieldNames.LIST_NGUOI_BAN} rules={[{required : true ,  message : i18n.t(languageKeys.duoc_chon_nguoi_ban)}]}>
                            <Select
                                onDropdownVisibleChange={(value) => {
                                    value && dsNhanSu.length < 1 && layDsNhanSu()
                                }}
                                dataSource={dsNhanSu}
                                titleKey="THU_KHO"
                                loading={loadingNhanSu}
                                multiple
                                selectedList={selectedNguoiBan}
                                onChange={setSelectedNguoiBan}
                                setSelectedList={(arr) => {
                                    setSelectedNguoiBan(arr)
                                    form.setFields([
                                        {
                                            name: fieldNames.LIST_NGUOI_BAN,
                                            value: arr
                                        }
                                    ])
                                }}
                                disabled={!itemEdit && !isAddAllowed || !!itemEdit && !isEditAllowed}
                            />
                        </Item>
                    </Col> }

                </Row>

                <Row style={{
                    borderTop: "1px dashed #E2E2E2",
                }}>
                    {/*<Col span={8} style={{*/}
                    {/*    borderRight: "1px solid #E2E2E2",*/}
                    {/*}} className={styles.item_status}>*/}
                    {/*   <Space align="center">*/}
                    {/*        <label className={styles.lable}>{i18n.t(languageKeys.duyet_khi_ke_thuoc)}</label>*/}
                    {/*        <Item  style={{marginBottom : "5px"}}  name={fieldNames.DUYET_KHI_KE_THUOC} valuePropName="checked" initialValue={false} >*/}
                    {/*            <Switch />*/}
                    {/*        </Item>*/}
                    {/*   </Space>*/}
                    {/*</Col>*/}
                    <Col span={12} style={{
                        borderRight: "1px solid #E2E2E2",
                    }} className={styles.item_status}>
                        <Space align='center' >
                            <label className={styles.lable}>{i18n.t(languageKeys.duyet_khi_chuyen_cac_kho)}</label>
                            <Item  style={{marginBottom : "5px"}} name={fieldNames.DUYET_KHI_CHUYEN_CAC_KHO} valuePropName="checked" initialValue={false}>
                                <Switch disabled={!checkRoleArr.DUYET} />
                            </Item>
                        </Space>
                    </Col>

                    <Col span={12} className={styles.item_status}>
                        <Space align='center'>
                            <label className={styles.lable}>{i18n.t(languageKeys.field_Hien_thi)}</label>
                            <Item  style={{marginBottom : "5px"}} name={fieldNames.KHOA} valuePropName="checked" initialValue={false}>
                                <Switch disabled={!checkRoleArr.KHOA} />
                            </Item>
                        </Space>
                    </Col>
                </Row>

            </Form>
            </Spin>


        </FormDrawer>
    )
}

export default React.forwardRef(ModalKho)


const fieldNames = {
    MA_KHO: "MA_KHO",
    TEN_KHO: "TEN_KHO",
    LOAI_KHO: "LOAI_KHO",
    LOAI_BENH_NHAN_AP_DUNG: "LOAI_BENH_NHAN_AP_DUNG",
    KHO_CHUYEN: "KHO_CHUYEN",
    PHONG_KE_DON: "LIST_PHONG",
    DUYET_KHI_KE_THUOC: "DUYET_KHI_KE_THUOC",
    DUYET_KHI_CHUYEN_CAC_KHO: "DUYET_KHI_CHUYEN_CAC_KHO",
    KHOA: "KHOA",
    TRANG_THAI: "TRANG_THAI",
    NHANSU_ID: "NHANSU_ID",
    LIST_NGUOI_BAN : "LIST_NGUOI_BAN"
}

export const TEN_LOAI_KHO = {
    KHO_TONG: i18n.t(languageKeys.kho_tong),
    KHO_LE: i18n.t(languageKeys.kho_le),
    KHO_CHAN: "Kho chẵn",
    TU_TRUC: i18n.t(languageKeys.data_Tu_truc),
    NHA_THUOC: i18n.t(languageKeys.nha_thuoc)
}

export const DATA_LOAI_KHOA = [
    {
        key: "KHO_TONG",
        name: i18n.t(languageKeys.kho_tong)
    },
    {
        key: "KHO_LE",
        name: i18n.t(languageKeys.kho_le)
    },
    {
        key: "NHA_THUOC",
        name: i18n.t(languageKeys.nha_thuoc)
    },
    {
        key: "TU_TRUC",
        name: i18n.t(languageKeys.data_Tu_truc),
    }
]

export const LOAI_BENH_NHAN_AP_DUNG = [
    {
        key: "BHYT",
        name: i18n.t(languageKeys.benh_nhan_bhyt),
    },
    {
        key: "NOI_TRU",
        name: i18n.t(languageKeys.benh_nhan_noi_tru),
    },
    {
        key: "NGOAI_TRU",
        name: i18n.t(languageKeys.benh_nhan_ngoai_tru),
    },

]
