import { Button, Col, Form, Modal, Row } from "antd";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Select } from "../../../components";
import { brands, paths } from "../../../constants";
import { HLog } from "../../../helpers";
import i18n, { languageKeys } from "../../../i18n";
import { layDSPhongBs } from "../../QuanLyKhamBenh/apisKhamBenh/apisSiderBar";
import style from "./mhc.module.less";

const ModalHangCho = forwardRef((props, ref) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [dsPhong, setDsPhong] = useState([]);
  const [loadingList, setLoadingList] = useState(false);

  useImperativeHandle(ref, () => ({
    async open() {
      setVisible(true);
      setLoadingList(true);

      const dataRequest = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        LOAI_PHONG: "KHAM_BENH",
        search_string: "",
      };

      const res = await layDSPhongBs(dataRequest);

      if (res.status === "OK") {
        let result = res.result;
        setDsPhong(result);
      }
      setLoadingList(false);
    },
    close: handleClose,
  }));

  const handleClose = () => {
    setVisible(false);
    setLoadingList(false);
    setDsPhong([]);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    HLog("Apply room to export queue", values);

    const brand = userProfile.benh_vien.BRAND;
    const exportQueue = userProfile.config.XUAT_HANG_CHO;

    let search = `${paths.tivi}?hospital_id=${userProfile.BENH_VIEN_ID}&partner_code=${userProfile.partner_code}&brand=${
      userProfile.benh_vien.BRAND
    }&payment_required=${!!userProfile.config.THANH_TOAN_TRUOC_KHAM ? 1 : 0}`;

    if (brand === brands.medihub && !!exportQueue && !!exportQueue.isCo) {
      search += `&version=${!!exportQueue.isCu ? 0 : 1}`;
    }

    for (let i = 1; i <= total_room; i++) {
      if (!!values[`room_id_${i}`]) {
        search += `&room_id_${i}=${values[`room_id_${i}`]}`;
      }
    }

    window.open(search, "_blank");
  };

  const total_room = useMemo(() => {
    const brand = userProfile.benh_vien.BRAND;

    switch (brand) {
      case brands.tmedical:
        return 4;
      case brands.medihub:
        return 1;
      default:
        return 1;
    }
  }, [userProfile.benh_vien.BRAND]);

  return (
    <Modal
      title={i18n.t(languageKeys.title_Hang_cho_kham_benh)}
      width={500}
      visible={visible}
      onCancel={handleClose}
      closable={false}
      footer={
        <Row justify="end">
          <Col>
            <Button type="primary" onClick={handleClose} ghost>
              {i18n.t(languageKeys.common_Huy)} (ESC)
            </Button>

            <Button type="primary" onClick={() => form.submit()}>
              {i18n.t(languageKeys.common_Ap_dung)}
            </Button>
          </Col>
        </Row>
      }
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} className={style["container"]}>
        <Row gutter={15}>
          {new Array(total_room).fill(null).map((_, index) => (
            <Col span={12} key={index}>
              <Form.Item name={`room_id_${index + 1}`} label={i18n.t(languageKeys.title_Hang_cho) + (total_room > 1 ? ` ${index + 1}` : "")}>
                <Select dataSource={dsPhong} titleKey="TEN_KHOA_PHONG" valueKey="ID" loading={loadingList} />
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    </Modal>
  );
});

export default ModalHangCho;
