import { notification } from "antd";
import { apis, keys } from "../../../../../constants";
import { common_post, getErrorMessage, HLog, isJsonString } from "../../../../../helpers";
import i18n, { languageKeys } from "../../../../../i18n";

//api lấy danh sách các kho thuốc mà phòng được phép kê
export const apiLayDsKhoThuoc = async (dataRequest) => {
  try {
    HLog("apisTiepDon apiLayDsKhoThuoc...");
    const res = await common_post(apis.tiep_don_ds_da_tiep_nhan, dataRequest, false);

    if (res.status === "OK") {
      return res;
    } else {
      HLog("apiLayDsKhoThuoc false", res);
      return null;
    }
  } catch (error) {
    HLog("apiLayDsKhoThuoc error", error);
    return null;
  }
};

//api tìm ds thuốc theo kho đã chọn để kê hoặc bán cho bệnh nhân
export const apiTimDsThuocTheoKho = async (dataRequest) => {
    try {
      HLog("apisTiepDon apiTimDsThuocTheoKho...");
      const res = await common_post(apis.tim_kiem_thuoc_theo_kho, dataRequest, false);
  
      if (res.status === "OK") {
        return res;
      } else {
        HLog("apiTimDsThuocTheoKho false", res);
        return null;
      }
    } catch (error) {
      HLog("apiTimDsThuocTheoKho error", error);
      return null;
    }
  };

  //api lấy ds đơn thuốc mẫu
export const apiLayDsDonThuocMau = async (dataRequest) => {
  try {
    HLog("apisKeDonThuoc apiLayDsDonThuocMau...");
    const res = await common_post(apis.lay_danh_sach_don_thuoc_mau, dataRequest, false);

    if (res.status === "OK") {
      return res;
    } else {
      HLog("apiLayDsDonThuocMau false", res);
      return null;
    }
  } catch (error) {
    HLog("apiLayDsDonThuocMau error", error);
    return null;
  }
};

//api lấy chi tiet đơn thuốc mẫu
export const apiLayChiTietDonThuocMau = async (dataRequest) => {
  try {
    HLog("apisKeDonThuoc apiLayChiTietDonThuocMau...");
    const res = await common_post(apis.lay_chi_tiet_don_thuoc_mau, dataRequest, false);

    if (res.status === "OK") {
      return res;
    } else {
      HLog("apiLayChiTietDonThuocMau false", res);
      return null;
    }
  } catch (error) {
    HLog("apiLayChiTietDonThuocMau error", error);
    return null;
  }
};

//api lấy chi tiet đơn thuốc mẫu
export const apiXoaDonThuocMau = async (dataRequest) => {
  try {
    HLog("apisKeDonThuoc apiXoaDonThuocMau...");
    const res = await common_post(apis.xoa_don_thuoc_mau, dataRequest, false);

    if (res.status === "OK") {
      return res;
    } else {
      HLog("apiXoaDonThuocMau false", res);
      notification.error({message: getErrorMessage(res.error_code)})
      return null;
    }
  } catch (error) {
    HLog("apiXoaDonThuocMau error", error);
    return null;
  }
};

  //api tìm ds thuốc theo kho đã chọn để kê hoặc bán cho bệnh nhân
export const apiTimDsThuocKhaDungTheoKho = async (dataRequest) => {
  try {
    HLog("apisTiepDon apiTimDsThuocKhaDungTheoKho...");
    const res = await common_post(apis.tim_kiem_thuoc_kha_dung_theo_kho, dataRequest, false);

    if (res.status === "OK") {
      return res;
    } else {
      HLog("apiTimDsThuocKhaDungTheoKho false", res);
      return null;
    }
  } catch (error) {
    HLog("apiTimDsThuocKhaDungTheoKho error", error);
    return null;
  }
}


  //lưu đơn thuốc kê đơn
export const apiLuuDonThuoc = async ({
  BENH_VIEN_ID,
  search_string,
  partner_code,
  DON_THUOC_ID,
  BAC_SI_ID='', BENH_NHAN_ID='', LUOT_DIEU_TRI_ID='', CHO_PHEP_GHI_DE = false,
        NGAY_Y_LENH='', GIO_Y_LENH='', KHO_THUOC='',  KHO_THUOC_ID = "" ,GHI_CHU='', MA_DON_THUOC_MAU = null, TEN_DON_THUOC_MAU= null, IS_DON_THUOC_MAU = false,
        list_thuoc // = [{Thuoc_ID,Thuoc_Code,Thuoc_Ten,Thuoc_Dvt, Thuoc_SL, Thuoc_HoatChat, Duongdung_Code,Duongdung,Lieuluong,Tansuat,Chidan,GHI_CHU, KHO_THUOC }]
  
}) => {
  let dataRequest = { BENH_VIEN_ID,
    search_string,
    partner_code,
    DON_THUOC_ID, CHO_PHEP_GHI_DE ,
    BAC_SI_ID, BENH_NHAN_ID, LUOT_DIEU_TRI_ID, MA_DON_THUOC_MAU, TEN_DON_THUOC_MAU, IS_DON_THUOC_MAU,
    NGAY_Y_LENH, GIO_Y_LENH, KHO_THUOC,  KHO_THUOC_ID ,GHI_CHU, 
    list_thuoc};
  try {
    const res = await common_post(apis.khambenh_luu_don_thuoc, dataRequest)
    if (res.status === "OK") {
      return res;
    }else{
      if(res.error_code === "003"){
        let thuoc_het = await layTenThuocKhongKhaDung(list_thuoc, res.arr_THUOC_ERROR)
          notification.error({message: i18n.t(languageKeys.noti_warning_thuoc) + thuoc_het + i18n.t(languageKeys.noti_warning_thuoc_khong_du)})
      }
      return res;
    }
    throw new Error({message: 'failed'})
  } catch (error) {
    HLog("Apis luuDonThuoc lỗi: ", error);
    throw new Error({message: 'failed'})
    // return;
  }
};


const layTenThuocKhongKhaDung = async (array_thuoc = [], array_error = []) => {
  let array_name = []
  let map_thuoc = array_thuoc.map(item => item.Thuoc_ID)
  await array_error.forEach(item => {
    let index_item = map_thuoc.indexOf(item)
    if(index_item !== -1){
      array_name.push(array_thuoc[index_item].Thuoc_Ten)
    }
  })

  return array_name.join(", ");
}