import { Layout, notification } from "antd";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BottomBar, SideBar, TopBar } from "./layouts";
// import { resetTitle, updateTitle } from "../../../ducks/slices/titlePageSlice";
import { FormMoTa } from "./components";
import { HLog, layDiaChi } from "../../../helpers";
import { useSaveSample } from "./mauMoTaCdha.hook";
import i18n, { languageKeys } from "../../../i18n";
import { handleCheckPermissions } from "../../../helpers";
import keys, { featureKeys, loai_doi_tuong } from "constants/keys";
import moment from "moment";
import { ModalPDF } from "components";

const MauMoTaCdha = () => {
  const dispatch = useDispatch();
  const [selectedSample, setSelectedSample] = useState();
  const refFormMoTa = useRef();
  const refSideBar = useRef();
  const userProfile = useSelector((state) => state.auth.user);
  const { createSample, updateSample, loadingSave, updateStatusSample, deleteSample } = useSaveSample();

  const checkRoleArr = useMemo(() => handleCheckPermissions(userProfile, featureKeys.mau_mo_ta_cdha_ris));
  const pdfRef = useRef();


  HLog.render("Mau mo ta cdha");

  // useEffect(() => {
  //   dispatch(updateTitle(i18n.t(languageKeys.menu_mau_mo_ta_cdha)));

  //   return () => {
  //     dispatch(resetTitle());
  //   };
  // }, [dispatch]);

  const handleSelectSample = useCallback((sample) => {
    HLog("[INFO] Select sample", sample);

    refFormMoTa.current?.resetForm();
    refFormMoTa.current?.initForm(sample);
    setSelectedSample(sample);
  }, []);

  const handleDeselectSample = useCallback((sample) => {
    HLog("[INFO] Deselect sample", sample);

    setSelectedSample();
    refFormMoTa.current?.resetForm();
  }, []);

  const handleCreateSample = useCallback(
    async (values) => {
      try {
        const res = await createSample(values);
        if (res.message === "DV_EMPTY") {
          notification.error({
            message: i18n.t(languageKeys.error_khong_co_dich_vu),
          });
          return;
        }
        notification.success({
          message: i18n.t(languageKeys.them_moi_thanh_cong),
        });

        refFormMoTa.current?.resetForm();
      } catch (errorMessage) {
        notification.error({
          message: i18n.t(languageKeys.them_moi_that_bai),
        });

        throw Error(`Create sample: ${errorMessage}`);
      }
    },
    [createSample]
  );

  const handleUpdateSample = useCallback(
    async (values) => {
      try {
        await updateSample({
          ...values,
          ID: selectedSample?.ID,
        });

        notification.success({
          message: i18n.t(languageKeys.cap_nhat_thanh_cong),
        });
      } catch (errorMessage) {
        notification.error({
          message: i18n.t(languageKeys.cap_nhat_that_bai),
        });

        throw Error(`Update sample: ${errorMessage}`);
      }
    },
    [selectedSample?.ID, updateSample]
  );

  const handleClickSave = useCallback(async () => {
    try {
      const values = await refFormMoTa.current?.submitForm();



      const isAtCreateView = !selectedSample;

      if (isAtCreateView) {
        await handleCreateSample(values);
      } else {
        await handleUpdateSample(values);
      }

      refSideBar.current?.reset();
      refSideBar.current?.refetch();
    } catch (error) {
      HLog(`[ERROR] Save`, error);
    }
  }, [handleCreateSample, handleUpdateSample, selectedSample]);

  const onClickPreview = async() =>  {
    const {MO_TA, KET_LUAN, LOAI_DV} = await refFormMoTa.current?.submitForm();

    

    let data = {
      TEN: userProfile.benh_vien.TEN_CSKCB,
      GIOI_TINH: "nam",
      NGAY_SINH: "2000",
      SO_DIEN_THOAI: userProfile.benh_vien.SO_DIEN_THOAI,
      MA_KH: "123123123",
      TEN_DOI_TUONG: loai_doi_tuong[keys.VIEN_PHI],
      MA_DOI_TUONG: "009",
      DM_DOI_TUONG_ID: 138,
      BAC_SI: userProfile.benh_vien.TEN_CSKCB,
      CHU_KY: userProfile.benh_vien.TEN_CSKCB,
      CHAN_DOAN_LAM_SANG: null,
      KY_THUAT_CHI_DINH: "............",
      DIA_CHI_CHI_TIET: layDiaChi(userProfile.benh_vien),
      MO_TA: MO_TA,
      KET_LUAN: KET_LUAN,
      ANH_MO_TA: [],
      NGUOI_TRA_KQ: userProfile.benh_vien.TEN_CSKCB,
      TEN_LOAI_PHIEU:`${i18n.t(languageKeys.phieu_ket_qua)} ` + LOAI_DV?.TEN_LOAI,
      NGAY_TRA_KET_QUA: moment().format("YYYYMMDD"),
      GIO_TRA_KET_QUA: moment().format("HH:mm"),
      LINK_VIEW: null,
      MA_PHIEU: "678678",
      maumota: MO_TA,
      ket_luan: KET_LUAN,
    };
    
    pdfRef.current?.openModalWithData(data, "PhieuTraKq");

  }

  const handleClickEscape = useCallback(() => {
    setSelectedSample();
    refFormMoTa.current?.resetForm();
  }, []);

  const handleClickAddNew = useCallback(() => {
    setSelectedSample();
    refFormMoTa.current?.resetForm();
  }, []);

  const handleUpdateStatus = async (status) => {
    let req = {
      arr_ID: [selectedSample.ID],
      KHOA: Number(status),
    };
    try {
      await updateStatusSample(req);
      notification.success({
        message: i18n.t(languageKeys.cap_nhat_thanh_cong),
      });
      setSelectedSample({ ...selectedSample, KHOA: Number(status) });
      refSideBar.current.refetch();
    } catch (error) {
      notification.error({
        message: i18n.t(languageKeys.cap_nhat_that_bai),
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteSample([selectedSample.ID]);
      notification.success({
        message: i18n.t(languageKeys.noti_Xoa_thanh_cong),
      });
      refSideBar.current.refetch();
      setSelectedSample();
      refFormMoTa.current?.resetForm();
    } catch (e) {
      notification.error({
        message: i18n.t(languageKeys.cap_nhat_that_bai),
      });
    }
  };

  return (
    <Layout>
      <SideBar ref={refSideBar} onSelectSample={handleSelectSample} onDeselectSample={handleDeselectSample} selectedSample={selectedSample} />

      <Layout>
        <TopBar
          selectedSample={selectedSample}
          onClickAddNew={handleClickAddNew}
          handleDelete={handleDelete}
          handleUpdateStatus={handleUpdateStatus}
          loadingSwitch={loadingSave}
          isAddAllowed={checkRoleArr?.THEM_MOI}
          isLockAllowed={checkRoleArr?.KHOA}
          isDeleteAllowed={checkRoleArr?.XOA}
        />

        <Layout.Content>
          <FormMoTa
            ref={refFormMoTa}
            onSave={handleClickSave}
            selectedSample={selectedSample}
            onClickEscape={handleClickEscape}
            isEditAllowed={checkRoleArr?.SUA}
            isAddAllowed={checkRoleArr?.THEM_MOI}
          />
        </Layout.Content>

        <BottomBar
          selectedSample={selectedSample}
          onClickSave={handleClickSave}
          loadingSave={loadingSave}
          onClickEscape={handleClickEscape}
          isEditAllowed={checkRoleArr?.SUA}
          isAddAllowed={checkRoleArr?.THEM_MOI}
          onClickPreview={onClickPreview}
        />
        <ModalPDF ref={pdfRef} />

      </Layout>
    </Layout>
  );
};

export default MauMoTaCdha;