import axios from "axios";
import { apis } from "../../../constants";
import { common_post, HLog } from "../../../helpers";

export async function apiLayDsPhieuChiDinh(req) {
  try {
    const response = await common_post(apis.ris_lay_danh_sach_phieu, req);
    if (response && response.status === "OK") {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    console.log("parse error", error);
    return null;
  }
}

export async function apiLayChiTietPhieu(req) {
  try {
    const response = await common_post(apis.ris_lay_chi_tiet_phieu, req);
    if (response && response.status === "OK") {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    HLog("lấy chi tiết phiếu error", error);
    return null;
  }
}

export async function capNhatTrangThaiPhieu(req) {
  try {
    const response = await common_post(apis.ris_cap_nhat_trang_thai_phieu_chi_dinh, req);
    if (response && response.status === "OK") {
      return response;
    } else {
      throw response;
    }
  } catch (error) {
    HLog("lấy chi tiết phiếu error", error);
    throw error;
  }
}

export async function guiLaiChiDinhVin(req) {
  try {
    const response = await common_post(apis.ris_gui_lai_sang_vin, req);
    if (response && response.status === "OK") {
      return response;
    } else {
      throw response;
    }
  } catch (error) {
    HLog("lấy chi tiết phiếu error", error);
    throw error;
  }
}

export async function huyTraKetQua(req) {
  try {
    const response = await common_post(apis.ris_huy_ket_qua_phieu, req);
    if (response && response.status === "OK") {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    HLog("lấy chi tiết phiếu error", error);
    return null;
  }
}

export async function apiLayDsPhong(req) {
  try {
    const response = await common_post(apis.ris_lay_ds_phong, req);
    if (response && response.status === "OK") {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    HLog("lấy chi tiết phiếu error", error);
    return null;
  }
}

export async function apiPhieuTra(req, pacs = false) {
  try {
    const url = !pacs ? apis.ris_in_phieu_tra_kq : apis.ris_in_phieu_tra_kq_PACS;
    const response = await common_post(url, req);
    if (response && response.status === "OK") {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    HLog("lấy chi tiết phiếu error", error);
    return null;
  }
}

export async function apiCapNhatTrangThaiDichVu(req) {
  try {
    const response = await common_post(apis.cap_nhat_trang_thai_dich_vu_cls, req);
    if (response && response.status === "OK") {
      return response;
    }
    return;
  } catch (error) {
    HLog("Cập nhật trạng thái dịch vụ false", error);
    throw error;
  }
}

export async function apiHuyTraKetQua(req) {
  try {
    const response = await common_post(apis.ris_huy_dich_vu_phieu_tra_kq, req);
    if (response && response.status === "OK") {
      return response;
    }
    return;
  } catch (error) {
    HLog("Huỷ trạng thái dịch vụ false", error);
    throw error;
  }
}