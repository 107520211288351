import { PrinterOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, InputNumber, notification, Row, Layout, Space, Tooltip, Dropdown, Select as SelectAntd, Spin } from "antd";
import _, { isArray, debounce } from "lodash";
import moment from "moment";
import { useCallback, useMemo, useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Calendar, DownVector, IconTemplate } from "../../../../assets/svg";
import { ConfirmModal, Select } from "../../../../components";
import ModalPDF from "../../../../components/Modal/ModalPDF";
import { apis, keys } from "../../../../constants";
import { danhSachLoaiTaiKham, keyFormKham } from "../../../../constants/data";
import { dataPartnerCode, featureKeys, keyLoaiNhanVien } from "../../../../constants/keys";
import { getWeekdays, HLog, isEmptyObject, handleCheckPermissions } from "../../../../helpers";
import { useEventListener } from "../../../../hooks";
import i18n, { languageKeys } from "../../../../i18n";
import { apiLayThongTinDonThuoc, apiSaveOnlyChiSoSinhTon, layDanhSachBenh, layThongTinBenhAn, luuThongTinKhamBenh, layDsBacSi, kiemTraLichTaiKham } from "../../apisKhamBenh/apisKhamBenh";
import style from "./ttk.module.less";
import { useAntForm } from "./components/useAntForm";
import ChiSoSinhTon from "./components/ChiSoSinhTon";
import ChanDoanXuTri, { FormChanDoanXuTriDinhDuong } from "./components/ChanDoanXuTri";
import HoiBenh from "./components/HoiBenh";
import KhamTongQuan from "./components/KhamTongQuan";
import {
  KhamBenhActions,
  resetThongTinKhamBenh,
  saveThongTinKhamBenh,
  setDataDetail
} from "../../../../ducks/slices/khamBenhSlice";
import PopupThongTinKham from "./popup/PopupThongTinKham";
import { value_SO_DO_RANG } from "./components/constToothDiafram";
import Constants from "constants/Constants";
import { fieldVongBung } from "pages/QuanLyTiepDon/components/KhaiThacLichSuBenh/fieldKhaiThac";
import XacNhanTaiKham from "./popup/XacNhanTaiKham";
import { fieldKhamBenh, tinhTuoiThai } from "./constantsTTK";

const tabThongTin = {
  HOI_BENH: "HOI_BENH",
  KHAM_TONG_QUAN: "KHAM_TONG_QUAN",
  CHAN_DOAN: "CHAN_DOAN",
};

const ThongTinKham = forwardRef(({
  trang_thai = "",
  patientInfo,
  isVisible,
  moTa = "",
  onChuyenSidebarTabSangHoanThanh = () => {},
  onChuyenSidebarTabSangDangKham = () => {},
  onChuyenHeaderTabSangKeThuoc = () => {},
  onChuyenSidebarTabSangChoKham = () => {},
  onReloadTab = () => {},
  phongChiDinh,
  disableKeyEvent = false,
  setCollapsed = () => {},
}, ref) => {

  // console.log("phongChiDinh",phongChiDinh);
  // const [form] = useForm();

  const pdfRef = useRef();
  const finishRef = useRef();
  const rebornRef = useRef(); // popup xác nhận "hồi sinh" cuộc khám (chuyển trạng thái từ hoàn thành về đang khám)
  const userProfile = useSelector((state) => state.auth.user);
  const { thongTinKhamBenh } = useSelector((state) => state.khamBenh);
  const { form, submitForm } = useAntForm({ phongChiDinh });
  const ketThucRef = useRef();
  const mauTTKRef = useRef();
  const taiKhamRef = useRef();


  const [dsICD10Chinh, setDsICD10Chinh] = useState([]); // tìm kiếm danh sách bệnh chính
  const [dsICD10Phu, setDsICD10Phu] = useState([]); // tìm kiếm danh sách bệnh phụ
  const [dsBenhPhu, setDsBenhPhu] = useState([]);
  const [loadingFinishExam, setLoadingFinishExam] = useState(false); // loading nút kết thúc cuộc khám
  const [loadingSaveExam, setLoadingSaveExam] = useState(false); // loading nút lưu cuộc khám
  const [dataExam, setDataExam] = useState();
  const [disabledTaiKham, setDisabledTaiKham] = useState(false);
  const [tabActive, setTabActive] = useState(tabThongTin.HOI_BENH);
  const [lichKham, setLichKham] = useState();
  const [requireChanDoan, setReqireChanDoan] = useState(true);
  const [checkLuuMauTT, setCheckLuuMauTT] = useState(false);

  const [dsYHCTChinh, setDsYHCTChinh] = useState([]); // tìm kiếm danh sách bệnh chính
  const [dsYHCTPhu, setDsYHCTPhu] = useState([]); // tìm kiếm danh sách bệnh phụ
  const [dsBenhYHCTPhu, setDsBenhYHCTPhu] = useState([]);
  const [isOpenTTKham, setIsOpenTTKham] = useState(false); //đóng mở giao diện chọn mẫu thông tin khám

  const dispatch = useDispatch();
  const dataLichKham = useSelector((state) => state.khamBenh.dataDetail);
  const { dsBacSi } = useSelector((state) => state.khamBenh);

  const KHAM_NOI_GIAC_NGU_TEXT = Form.useWatch("KHAM_NOI_GIAC_NGU_TEXT", form);
  const TAI_KHAM = Form.useWatch(fieldKhamBenh.TAI_KHAM, form)

  // const userIsAdmin = userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.quan_tri || userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.bac_si || userProfile.QUYEN_MODULE.includes('CIS.QL_KHAM_BENH.THONG_TIN_KHAM.SUA'); // nếu user là admin hoặc bác sĩ, user được phép khám bệnh
  // const maCchnExist = !!userProfile.MA_CCHN; // user có MÃ CCHN

  // const readOnly = !userIsAdmin;

  const checkRoleArr = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.thong_tin_kham)
  }, [userProfile])

  useImperativeHandle(ref, () => ({
    saveValueTemporary() {
      saveTamThoi()
    },
    reset() {
      setDsBenhPhu([]);
      setDsBenhYHCTPhu([]);
    },
    onSave(type = "", print = false, showNoti = true, notCallApi = false, onlyValidator = false, isFromDonThuoc = false) {
      return handleSave(type, print, showNoti, notCallApi, onlyValidator, isFromDonThuoc)
    }
  }));
  useEffect(() => {

    !requireChanDoan && setReqireChanDoan(true);
    if (!!patientInfo && !!isVisible && !thongTinKhamBenh) { // khi có bệnh nhân và visibletab , trong store k có thongTinKhamBenh
      setLichKham(patientInfo);
      setDsBenhPhu([]);
      setDsBenhYHCTPhu([]);
      setTabActive(tabThongTin.HOI_BENH);
      layThongTinKhamBenh();
      //timKiemICD10("", "CHINH")
    }

    if (!patientInfo) {
      form.resetFields();
      //setDsBenhPhu([]);
      setDsICD10Phu([]);
      //setDsBenhYHCTPhu([]);
      setLichKham();
      dispatch(resetThongTinKhamBenh());
      setDsBenhPhu([]);
      setDsBenhYHCTPhu([]);
    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientInfo, isVisible]);



  useEffect(() => {
    if (thongTinKhamBenh && isVisible) {
      parseDataInfo(thongTinKhamBenh)
    }
  }, [thongTinKhamBenh, isVisible]);

  const isCanDo = useMemo(() => {
    // return locdau(phongChiDinh?.TEN_KHOA_PHONG || "")?.toLocaleLowerCase()?.includes('can do')
    return phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.CAN_DO_CHI_SO_SINH_TON)
  }, [phongChiDinh])


  const layThongTinKhamBenh = async () => {
    let dataRequest = {
      partner_code: userProfile.partner_code,
      LUOT_DIEU_TRI_ID: patientInfo?.LUOT_DIEU_TRI_ID,
      LICH_KHAM_ID: patientInfo?.LICH_KHAM_ID,
      PHONG_ID: patientInfo?.PHONG_ID,
    };
    setLoadingSaveExam(true)
    let response = await layThongTinBenhAn(dataRequest);
    if (response) {
      // if (phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase().includes("nội") && (response.result.TRANG_THAI == "CHO_KHAM" || response.result.TRANG_THAI_HIEN_TAI == "CHO_KHAM")) {
      if (
        (phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_NOI_GIAC_NGU) || phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_NOI_TONG_QUAT)) &&
        (response.result.TRANG_THAI === "CHO_KHAM" || response.result.TRANG_THAI_HIEN_TAI === "CHO_KHAM")
      ) {
        // response.result.KHAM_NOI_GIAC_NGU = (userProfile?.config?.FORM_KHAM === "KHAM_NOI_GIAC_NGU" ? true : false)
        response.result.KHAM_NOI_GIAC_NGU = (phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_NOI_GIAC_NGU) ? true : false)
      }

      let listDoctor = []
      if (!dsBacSi[0]) {
        const resBS = await layDsBacSi()
        if (resBS) {
          dispatch(KhamBenhActions.setDsBacSi(resBS))
          listDoctor = resBS
        }
      } else {
        listDoctor = dsBacSi
      }
      dispatch(saveThongTinKhamBenh({
        ...response.result,
        BAC_SI_ID: listDoctor.find(i => i.NHANSU_ID === response.result.BAC_SI_ID)
          ? response.result.BAC_SI_ID
          : userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.bac_si
            ? userProfile.NHANSU_ID
            : undefined
      }))

      // dispatch(saveThongTinKhamBenh(response.result))
      //parseDataInfo(response.result);
    }
    setLoadingSaveExam(false)
  };

  const saveTamThoi = () => {
    if (patientInfo && lichKham) {
      handleSave("", false, false, true).then().catch();
    }
  }

  //hàm xử lí parse dữ liệu response và hiển thị thông tin khám lên giao diện
  const parseDataInfo = (dataResponse) => {
    HLog("parseDataInfo dataResponse: ", dataResponse);
    if (!dataResponse || isEmptyObject(dataResponse)) {
      return;
    }

    let DIEM_STOP_BANG = null
    let THANG_DIEM_STOP_BANG = null

    let fieldData = Object.values(fieldKhamBenh).map((name) => {
      if (name === fieldKhamBenh.TAI_KHAM_NGAY && !!dataResponse[name]) {
        return {
          name,
          value: getWeekdays(moment(dataResponse[name], "YYYYMMDD").valueOf()) + ", " + moment(dataResponse[name], "YYYYMMDD").format("DD/MM/YYYY"),
          errors: [],
        };
      }

      if (name === fieldKhamBenh.TAI_KHAM) {
        setDisabledTaiKham(!dataResponse[name]);
      }

      if (name === fieldKhamBenh.LY_DO_VAO_VIEN && !dataResponse[name]) {
        return {
          name,
          value: moTa,
          errors: [],
        };
      }
      if (name === fieldKhamBenh.HUYET_AP && dataResponse[fieldKhamBenh.TAM_THU] && dataResponse[fieldKhamBenh.TAM_TRUONG]) {
        return {
          name,
          value: dataResponse[fieldKhamBenh.TAM_THU] + "/" + dataResponse[fieldKhamBenh.TAM_TRUONG],
          errors: [],
        };
      }

      // if (name === fieldKhamBenh.BAC_SI_ID) {
      //   return {
      //     name,
      //     value: dataResponse["BAC_SI_ID"] ? dataResponse["BAC_SI_ID"] : userProfile?.NHANSU_ID,
      //     errors: [],
      //   };
      // }

      if (name === fieldKhamBenh.SO_DO_RANG) {
        return {
          name,
          value: dataResponse[fieldKhamBenh.SO_DO_RANG] || value_SO_DO_RANG.adult_jaw,
          errors: [],
        };
      }

      if (name === fieldKhamBenh.RANG_TON_THUONG) {
        let dataFormat = []

        if (dataResponse[fieldKhamBenh.RANG_TON_THUONG]) {
          if (isArray(dataResponse[fieldKhamBenh.RANG_TON_THUONG])) {
            dataFormat = dataResponse[fieldKhamBenh.RANG_TON_THUONG]
          } else {
            dataFormat = dataResponse[fieldKhamBenh.RANG_TON_THUONG].split(",")
          }
        }
        return {
          name,
          value: dataFormat,
          errors: [],
        };
      }

      if (name === fieldKhamBenh.TIEN_SU_BENH) {
        return {
          name,
          value: dataResponse[fieldKhamBenh.TIEN_SU_BENH] == `""` ? null : dataResponse[fieldKhamBenh.TIEN_SU_BENH],
          errors: [],
        };
      }

      if (name === fieldKhamBenh.NGAY_KINH_CUOI || name === fieldKhamBenh.DU_KIEN_SINH) {
        return {
          name,
          value: dataResponse[name] ? moment(dataResponse[name], "YYYYMMDD") : undefined,
          errors: [],
        };
      }
      if (name === fieldKhamBenh.TUOI_THAI_HIEN_TAI) {
        return {
          name,
          value: tinhTuoiThai(dataResponse[fieldKhamBenh.NGAY_KINH_CUOI]),
          errors: [],
        };
      }
      if (name === fieldKhamBenh.DAU_OI_TIME) {
        return {
          name,
          value: dataResponse[name] ? moment(dataResponse[name], "HH:mm") : undefined,
          errors: [],
        };
      }

      return {
        name,
        value: dataResponse[name],
        errors: [],
      };
    });
    if ([dataPartnerCode.dth].includes(userProfile?.partner_code)) {
      const vong_bung = fieldData?.find(x => x.name === fieldKhamBenh.VONG_BUNG);
      if (vong_bung?.value) {
        let rs = vong_bung?.value?.split(",");
        Object.values(fieldVongBung).forEach((x, id) => {
          fieldData.push({
            name: x,
            value: rs?.[id] || undefined
          })
        })
      }
    }

    // if này để: init các số tiếm stopbang, tự tích thang điểm, nếu ở trạng thái chờ khám
    if (dataResponse?.TRANG_THAI != "KHAM_XONG"
      && dataResponse?.TRANG_THAI != "DANG_KHAM"
      && dataResponse?.TRANG_THAI != "HOAN_THANH"
      && dataResponse?.TRANG_THAI != "HUY"
      && dataResponse?.TRANG_THAI_HIEN_TAI == "CHO_KHAM") {

      const arrayInitStopBAng = []
      let DO_TUOI = null
      if (dataResponse.BMI > Constants.PROPOSE_BMI) {
        arrayInitStopBAng.push("STOP_BANG_MBI")
      }
      if (dataResponse.VONG_CO > Constants.PROPOSE_VONG_CO) {
        arrayInitStopBAng.push("STOP_BANG_CHUVI")
      }
      if (dataResponse?.NGAY_SINH) {
        if (dataResponse?.NGAY_SINH?.length == 4) {
          const ngaySinh = moment(dataResponse?.NGAY_SINH).format('YYYY')
          DO_TUOI = moment().diff(ngaySinh, 'years');
        }

        if (dataResponse?.NGAY_SINH?.length == 8) {
          const ngaySinh = moment(dataResponse?.NGAY_SINH).format('YYYYMMDD')
          DO_TUOI = moment().diff(ngaySinh, 'years');
        }

        if (DO_TUOI > Constants.PROPOSE_AGE) {
          arrayInitStopBAng.push("STOP_BANG_TUOI")
        }
      }
      if (dataResponse?.GIOI_TINH == Constants.PROPOSE_SEX) {
        arrayInitStopBAng.push("STOP_BANG_GTNAM")
      }

      DIEM_STOP_BANG = arrayInitStopBAng?.length
      THANG_DIEM_STOP_BANG = arrayInitStopBAng
      fieldData.push({ name: "DIEM_STOP_BANG", value: arrayInitStopBAng?.length, errors: [] }, { name: "THANG_DIEM_STOP_BANG", value: arrayInitStopBAng, errors: [] })
    }

    if (!!dataResponse["data_benh"] && dataResponse["data_benh"].length > 0) {
      let benh_chinh = null;
      let benh_phu = [];
      let data_benh = dataResponse["data_benh"] || [];
      benh_chinh = data_benh.find((item) => item.PHAN_LOAI === "CHINH");
      benh_phu = data_benh.filter((item) => item.PHAN_LOAI === "PHU");

      if (benh_chinh) {
        let data = {
          ID_ICD: benh_chinh.ID_ICD,
          MA_ICD: benh_chinh.MA_ICD,
          TEN_ICD: benh_chinh.TEN_ICD,
        };
        setDsICD10Chinh([data]);

        benh_chinh = JSON.stringify(data);
      }

      if (benh_phu && benh_phu.length > 0) {
        benh_phu = benh_phu.map((item) =>
          JSON.stringify({
            ID_ICD: item.ID_ICD,
            MA_ICD: item.MA_ICD,
            TEN_ICD: item.TEN_ICD,
          })
        );
        setDsBenhPhu(benh_phu);
      }

      if (!!benh_chinh) {
        fieldData.push({
          name: fieldKhamBenh.benh_chinh,
          value: benh_chinh,
        });
      }

      if (benh_phu.length > 0) {
        fieldData.push({
          name: fieldKhamBenh.benh_phu,
          value: benh_phu,
        });
      }
    }
    // benh_yhct_chinh : "benh_yhct_chinh",
    //     benh_yhct_phu : "benh_yhct_phu",
    if (!!dataResponse["data_benh_yhct"] && dataResponse["data_benh_yhct"].length > 0) {
      let benh_yhct_chinh = null;
      let benh_yhct_phu = [];
      let data_benh_yhct = dataResponse["data_benh_yhct"] || [];
      benh_yhct_chinh = data_benh_yhct.find((item) => item.PHAN_LOAI === "CHINH");
      benh_yhct_phu = data_benh_yhct.filter((item) => item.PHAN_LOAI === "PHU");

      if (benh_yhct_chinh) {
        let data = {
          ID_ICD: benh_yhct_chinh.ID_ICD,
          MA_ICD: benh_yhct_chinh.MA_ICD,
          TEN_ICD: benh_yhct_chinh.TEN_ICD,
        };
        setDsYHCTChinh([data]);
        benh_yhct_chinh = JSON.stringify(data);
      }

      if (benh_yhct_phu && benh_yhct_phu.length > 0) {
        benh_yhct_phu = benh_yhct_phu.map((item) =>
          JSON.stringify({
            ID_ICD: item.ID_ICD,
            MA_ICD: item.MA_ICD,
            TEN_ICD: item.TEN_ICD,
          })
        );
        setDsBenhYHCTPhu(benh_yhct_phu);
      }

      if (!!benh_yhct_chinh) {
        fieldData.push({
          name: fieldKhamBenh.benh_yhct_chinh,
          value: benh_yhct_chinh,
        });
      }

      if (benh_yhct_phu.length > 0) {
        fieldData.push({
          name: fieldKhamBenh.benh_yhct_phu,
          value: benh_yhct_phu,
        });
      }
    }


    let objDataDetail = JSON.parse(JSON.stringify(dataResponse))
    if (_.isObject(objDataDetail) && !_.isArray(objDataDetail)) {
      if (DIEM_STOP_BANG) objDataDetail.DIEM_STOP_BANG = DIEM_STOP_BANG
      if (THANG_DIEM_STOP_BANG) objDataDetail.THANG_DIEM_STOP_BANG = THANG_DIEM_STOP_BANG
    }
    dispatch(setDataDetail(objDataDetail))
    form.setFields(fieldData);
    // if(dataExam && !isEmptyObject(dataExam))  { //nếu dữ liệu thông tin khám khác rỗng, thì đẩy LUOT_DIEU_TRI_ID cũ vào
    //   dataResponse = {...dataResponse, LUOT_DIEU_TRI_ID: dataExam.LUOT_DIEU_TRI_ID}
    // }
    //HLog("setDataExam dataResponse: ", dataResponse);
    setDataExam(dataResponse?.LUOT_DIEU_TRI_ID
      ? dataResponse
      : {
        ...dataResponse,
        // LUOT_DIEU_TRI_ID: thongTinKhamBenh.LUOT_DIEU_TRI_ID
        LUOT_DIEU_TRI_ID: thongTinKhamBenh?.LUOT_DIEU_TRI_ID
      }
    );
  };

  //hàm xử lí reset nút check lưu mẫu thông tin
  const onResetSauLuuTTK = () => {
    form.setFields([
      {
        name: "TEN_MAU",
        value: "",
      },
      {
        name: "IS_MAU_THONG_TIN",
        value: false
      }
    ]);
    setCheckLuuMauTT(false);
  }

  // tìm kiếm danh mục bệnh
  const timKiemICD10 = async (search_string = "", LOAI = "", PHAN_LOAI = "ICD") => {
    const response = await layDanhSachBenh(search_string, PHAN_LOAI);

    if (response) {
      const data = response.result.map((item) => ({
        ID_ICD: item.ID_ICD,
        MA_ICD: item.MA_ICD,
        TEN_ICD: item.TEN_ICD,
      }));

      if (PHAN_LOAI === "YHCT") {
        if (LOAI === "CHINH") setDsYHCTChinh(data);
        if (LOAI === "PHU") setDsYHCTPhu(data);
      } else {
        if (LOAI === "CHINH") setDsICD10Chinh(data);
        if (LOAI === "PHU") setDsICD10Phu(data);
      }
      // else {
      //   setDsICD10Chinh(data);
      //   setDsICD10Phu(data);
      // }
    }
  };

  // cleanup
  useEffect(() => () => {
    setLoadingFinishExam(false);
    setLoadingSaveExam(false);
  }, []);

  /**
   * * Xử lý bấm Lưu - bấm Lưu và In - bấm Kết thúc cuộc khám
   * ! Dùng khi bấm Lưu - Lưu và IN - Kết thúc cuộc khám
   * @param {DANG_KHAM | KHAM_XONG} type type mà người dùng mong muốn cập nhật trạng thái
   * @param {boolean} print check xem là bấm Lưu (false) hay là bấm Lưu và In (true)
   * @param {boolean} showNoti
   * @param {boolean} notCallApi
   * @param {boolean} onlyValidator
   * @returns
   */
  const handleSave = async (type = "", print = false, showNoti = true, notCallApi = false, onlyValidator = false, isFromDonThuoc = false) => {

    /**
     * * Nếu là bác sĩ và chưa có mã chứng chỉ hành nghề
     * * (chắc chắn chỉ có bác sĩ và admin mới được thao tác "khám bệnh" thôi nên không cần kiểm tra người dùng thuộc nhóm nhân viên Quản trị hoặc Bác sĩ hay không)
     */

    // if (!maCchnExist) {
    //    notification.error({
    //     placement: "bottomLeft",
    //     message: i18n.t(languageKeys.bac_si_chua_co_ma_cchn),
    //   });

    //   return false;
    // }
    try {
      let currentData = await submitForm();

      // console.log(currentData, 'currentDatacurrentData  ')
      // return 
      // HLog("handleSave currentData: ", currentData);

      // * nếu đã tích checkbox tái khám mà chưa chọn thông tin tái khám
      if (currentData.TAI_KHAM && type === "KHAM_XONG") {
        // if (!currentData[fieldKhamBenh.TAI_KHAM_LOAI] || !currentData[fieldKhamBenh.TAI_KHAM_SAU]) {
        //   notification.error({
        //     placement: "bottomLeft",
        //     message: i18n.t(languageKeys.noti_Chua_chon_ngay_tai_kham),
        //   });
        //   return false;
        // }
        if (!currentData.ACTION_TAI_KHAM) {
          const res = await kiemTraLichTaiKham({ BENH_NHAN_ID: patientInfo.BENH_NHAN_ID })
          if (res) {
            taiKhamRef.current?.open({ data: res, print })
            return;
          }
        }
      }

      // if (phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongRHM) && !notCallApi && !(currentData.HAM_TREN_SO_BO || currentData.HAM_DUOI_SO_BO)) {
      //   notification.error({
      //     placement: "bottomLeft",
      //     message: i18n.t(languageKeys.error_maxillary_mandibular),
      //   });
      //   form.setFields([{ name: fieldKhamBenh.HAM_TREN_SO_BO, errors: ' ' },{ name: fieldKhamBenh.HAM_DUOI_SO_BO, errors: ' ' }])
      //   ketThucRef.current.loading(false);
      //   return false;
      // }

      // const checkValidatorChuanDoanLamSan = () => {
      //   if (currentData?.SO_DO_RANG === value_SO_DO_RANG.child_jaw || currentData?.SO_DO_RANG === value_SO_DO_RANG.adult_jaw ||  phongChiDinh?.TEN_KHOA_PHONG == NamePhongRHM) {
      //     return false
      //   }

      //   return true
      // }
      // if ( checkValidatorChuanDoanLamSan() &&!notCallApi && !currentData.CHAN_DOAN_LAM_SANG) {
      //   notification.error({
      //     placement: "bottomLeft",
      //     message: i18n.t(languageKeys.vui_long_nhap_chan_doan_so_bo),
      //   });
      //   form.setFields([{ name: fieldKhamBenh.CHAN_DOAN_LAM_SANG, errors: ' ' }])
      //   ketThucRef.current.loading(false);
      //   return false;
      // }

      if (notCallApi) {
        handleUpdateExamStatus("", currentData, false, false, notCallApi)
        return
      }

      if (onlyValidator) {
        return true
      }

      // * [kiểm tra type trạng thái mong muốn cập nhật] nếu bấm nút kết thúc cuộc khám (người dùng muốn kết thúc cuộc khám)
      if (type === "KHAM_XONG") {
        // * kiểm tra xem cuộc khám này đã được kê đơn thuốc (tự nguyện) hay chưa
        const resDonThuocTuNguyen = await apiLayThongTinDonThuoc({
          LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
          partner_code: userProfile.partner_code,
          KHO_THUOC: keys.KT_TU_NGUYEN,
        });

        // * nếu có đơn thuốc thì cho phép kết thúc cuộc khám (cập nhật trạng thái là KHÁM XONG)
        if (!!resDonThuocTuNguyen || isFromDonThuoc) {

          handleUpdateExamStatus(type, currentData, print, showNoti);
        }
        // * nếu không thì bật Modal cảnh báo "chưa có đơn thuốc"
        else {
          finishRef.current.open();
          //HLog("setDataExam currentData: ", currentData)
          setDataExam(currentData);
          return;
        }
      }

      // * [kiểm tra trạng thái hiện tại của cuộc khám] nếu muốn chuyển trạng cuộc khám thái từ HOÀN THÀNH sang ĐANG KHÁM
      else if (trang_thai === "KHAM_XONG") {
        rebornRef.current?.open({ currentData, print });
        return;
      }

      // * nếu chỉ muốn LƯU cuộc khám thì cho phép cập nhật trạng thái là ĐANG KHÁM
      else {

        handleUpdateExamStatus(type, currentData, print, showNoti);
      }

      if (trang_thai === 'TAT_CA') {
        setTimeout(() => {
          onReloadTab(patientInfo);
        }, 500)
      }
      return { message: "success" };
    } catch (errors) {
      console.log("Errors submit form", errors);
      if (
        JSON.stringify(errors?.error?.errorFields || []).includes(fieldKhamBenh.CHAN_DOAN_LAM_SANG) ||
        JSON.stringify(errors?.error?.errorFields || []).includes(fieldKhamBenh.benh_chinh)
      ) {
        setTabActive(tabThongTin.CHAN_DOAN);
        ketThucRef.current.close();
      }
      throw { error: errors };
    }
  };


  const handeSaveOnlyCSST = async (print = false) => {
    try {

      let currentData = await submitForm();

      if (currentData.TAI_KHAM) {
        // if (!currentData[fieldKhamBenh.TAI_KHAM_LOAI] || !currentData[fieldKhamBenh.TAI_KHAM_SAU]) {
        //   notification.error({
        //     placement: "bottomLeft",
        //     message: i18n.t(languageKeys.noti_Chua_chon_ngay_tai_kham),
        //   });
        //   return false;
        // }
        if (!currentData.ACTION_TAI_KHAM) {
          const res = await kiemTraLichTaiKham({ BENH_NHAN_ID: patientInfo.BENH_NHAN_ID })
          if (res) {
            taiKhamRef.current?.open({ data: res, print })
            return;
          }
        }
      }

      let payload = {
        ...currentData,
        TRANG_THAI: "KHAM_XONG",
        LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
        LICH_KHAM_ID: patientInfo.LICH_KHAM_ID,
        partner_code: userProfile.partner_code,
        BENH_NHAN_ID: patientInfo.BENH_NHAN_ID,
        BAC_SI_ID: userProfile.NHANSU_ID,

      }

      if (currentData[fieldKhamBenh.HUYET_AP]) {
        // nếu có huyết áp thì phải tách ra tâm thu tâm trương
        let arr = currentData[fieldKhamBenh.HUYET_AP].split("/");
        payload.TAM_THU = arr[0];
        payload.TAM_TRUONG = arr[1];
      }
      console.log(`payload`, payload);
      // return
      const res = await apiSaveOnlyChiSoSinhTon(payload);
      if (res.status === "OK") {

        onChuyenSidebarTabSangHoanThanh();

        print && handlePrint()
        notification.success({
          message: i18n.t(languageKeys.thao_tac_thanh_cong),
          placement: "bottomLeft",
        });
      }

    } catch (error) {

    }
  }

  /**
   * * Xử lý cập nhật trạng thái của cuộc khám khi muốn Lưu hoặc Kết thúc cuộc khám
   * ! Dùng khi bấm Lưu (đã valid) - Lưu và In (đã valid) - Kết thúc cuộc khám (đã valid) - Modal warning chưa kê đơn thuốc - Modal warning chuyển trạng thái từ "Hoàn thành" sang "Đang khám"
   * @param {DANG_KHAM | KHAM_XONG} type type mà người dùng mong muốn cập nhật trạng thái
   * @param {boolean} print check xem là bấm Lưu (false) hay là bấm Lưu và In (true)
   * @param {object} currentData dữ liệu Thông tin khám hiện tại của cuộc khám (thông tin trong form Thông tin khám)
   * @param showNoti
   * @param notCallApi
   */
  const handleUpdateExamStatus = async (type = "", currentData = {}, print = false, showNoti = true, notCallApi = false) => {
    //HLog("handleUpdateExamStatus currentData: ", currentData)
    // * Nếu muốn update trạng thái từ "Chờ khám" hoặc "Đang khám" sang "Hoàn thành"
    // console.log('11111111111', currentData)
    try {
      if (type === "KHAM_XONG" && !finishRef.current.visible) {
        setLoadingFinishExam(true);
      } else if (type === "DANG_KHAM" && !rebornRef.current?.visible) {
        setLoadingSaveExam(true);
      } else {
        setLoadingSaveExam(true);
      }

      let benh_chinh = null;
      let benh_phu = [];
      if (!!currentData.benh_chinh) {
        benh_chinh = { ...JSON.parse(currentData.benh_chinh), PHAN_LOAI: "CHINH" };
      }

      if (!!currentData.benh_phu) {
        benh_phu = currentData.benh_phu.map((item) => ({
          ...JSON.parse(item),
          PHAN_LOAI: "PHU",
        }));
      }
      // benh_yhct_chinh : "benh_yhct_chinh",
      //     benh_yhct_phu : "benh_yhct_phu",
      let benh_yhct_chinh = null;
      let benh_yhct_phu = [];
      if (!!currentData.benh_yhct_chinh) {
        benh_yhct_chinh = { ...JSON.parse(currentData.benh_yhct_chinh), PHAN_LOAI: "CHINH" };
      }

      if (!!currentData.benh_yhct_phu) {
        benh_yhct_phu = currentData.benh_yhct_phu.map((item) => ({
          ...JSON.parse(item),
          PHAN_LOAI: "PHU",
        }));
      }

      let data_benh = benh_chinh ? [benh_chinh, ...benh_phu] : benh_phu;
      let data_benh_yhct = benh_yhct_chinh ? [benh_yhct_chinh, ...benh_yhct_phu] : benh_yhct_phu;
      // fomat thời gian
      let dateTime = "";
      if (!!currentData[fieldKhamBenh.TAI_KHAM] && !!currentData.TAI_KHAM_NGAY) {
        dateTime = moment(currentData.TAI_KHAM_NGAY.slice(-10), "DD/MM/YYYY").format("YYYYMMDD");
      }

      // fomat huyết áp
      let TAM_THU,
        TAM_TRUONG = null;
      if (currentData[fieldKhamBenh.HUYET_AP]) {
        // nếu có huyết áp thì phải tách ra tâm thu tâm trương
        let arr = currentData[fieldKhamBenh.HUYET_AP].split("/");
        TAM_THU = arr[0];
        TAM_TRUONG = arr[1];
      }

      let dataRequest = {
        ...currentData,
        TAI_KHAM_NGAY: dateTime,
        data_benh,
        data_benh_yhct,
        TAM_THU,
        TAM_TRUONG,
        LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
        LICH_KHAM_ID: patientInfo.LICH_KHAM_ID,
        // BAC_SI_ID: userProfile.NHANSU_ID,
        partner_code: userProfile.partner_code,
        TRANG_THAI: type === "KHAM_XONG" ? "KHAM_XONG" : "DANG_KHAM",
        BENH_NHAN_ID: patientInfo.BENH_NHAN_ID,
        PHONG_ID: phongChiDinh.ID,
        TEN_KHOA_PHONG: phongChiDinh.TEN_KHOA_PHONG
      };

      //xử lí kiểm tra có chọn lưu mẫu thông tin không
      let { IS_MAU_THONG_TIN } = currentData;
      if (IS_MAU_THONG_TIN) { //nếu có tick chọn lưu mẫu thông tin khám

        mauTTKRef.current.callApiLuuMauTTK(currentData);
      }

      if (notCallApi) {
        dispatch(saveThongTinKhamBenh(dataRequest))
        return
      }
      const response = await luuThongTinKhamBenh(dataRequest);
      ketThucRef.current.close();
      if (response) {
        if (showNoti) {
          notification.success({
            message: i18n.t(languageKeys.thao_tac_thanh_cong),
            placement: "bottomLeft",
          });
        }
        dispatch(saveThongTinKhamBenh(dataRequest))

        // if (dataExam?.BAC_SI_ID && userProfile.NHANSU_ID !== dataExam?.BAC_SI_ID) {
        //   form.setFields([
        //     {
        //       name: fieldKhamBenh.BAC_SI_KHAM,
        //       value: getFullName(userProfile),
        //     },
        //   ]);
        // }
        if (print) handlePrint();

        // * Nếu đang muốn chuyển từ "Chờ khám" hoặc "Đang khám" sang "Hoàn thành"
        if (type === "KHAM_XONG") {
          finishRef.current.close();
          setLoadingFinishExam(false);
          // onChuyenSidebarTabSangHoanThanh();
          onChuyenSidebarTabSangChoKham();
        }

        // * Nếu đang muốn chuyển từ HOÀN THÀNH sang ĐANG KHÁM
        else if (trang_thai === "KHAM_XONG") {
          rebornRef.current.close();
          setLoadingSaveExam(false);

          onChuyenSidebarTabSangDangKham();
        }

        // * Nếu chỉ muốn Lưu thôi
        else {
          setLoadingSaveExam(false);

          // TODO - Chuyển tab từ Chờ khám sang Đang Khám nếu trạng thái hiện tại là Chờ khám
          // ! Không check theo type vì đang ở tab Đang khám khi bấm Lưu thì cũng sẽ truyền vào type là DANG_KHAM
          if (trang_thai === "CHO_KHAM") {
            onChuyenSidebarTabSangDangKham();
          }
        }
      } else {
        finishRef.current.loading(false);
        rebornRef.current.loading(false);
        ketThucRef.current.loading(false);
        setLoadingFinishExam(false);
        setLoadingSaveExam(false);

        notification.error({
          message: i18n.t(languageKeys.thao_tac_that_bai),
          placement: "bottomLeft",
        });
      }
    } catch (error) {
      HLog("[Quản lý khám bệnh] - handleUpdateExamStatus: ", error)
    } finally {
      setLoadingFinishExam(false);
      setLoadingSaveExam(false);
    }
  };

  const handleChuyenTabKeThuoc = async () => {
    onChuyenHeaderTabSangKeThuoc();
  };

  const handlePrint = () => {

    const dataRequest = {
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
      LUOT_DIEU_TRI_ID: dataExam.LUOT_DIEU_TRI_ID,
      LICH_KHAM_ID: patientInfo?.LICH_KHAM_ID,
      BENH_NHAN_ID: patientInfo?.BENH_NHAN_ID,
    };
    //HLog("handlePrint dataRequest: ", dataRequest, " dataExam: ", dataExam);
    const api = apis.lay_data_in_phieu_tom_tat_kham;
    pdfRef.current.openModal(dataRequest, api);
  };

  const handleTaiKhamSau = (val) => {
    const tai_kham_loai = form.getFieldValue(fieldKhamBenh.TAI_KHAM_LOAI);

    if (!!tai_kham_loai && !!val) {
      let today = new Date();

      let value = Number(val);
      if (tai_kham_loai === "NGAY") {
        today.setDate(today.getDate() + value);
      }
      if (tai_kham_loai === "TUAN") {
        today.setDate(today.getDate() + value * 7);
      }
      if (tai_kham_loai === "THANG") {
        today.setMonth(today.getMonth() + value);
      }
      let milisecond = new Date(today).getTime();

      form.setFields([
        {
          name: fieldKhamBenh.TAI_KHAM_NGAY,
          value: getWeekdays(milisecond) + ", " + moment(milisecond).format("DD/MM/YYYY"),
        },
      ]);
    }
  };

  const handleTaiKhamLoai = (val) => {
    const tai_kham_sau = form.getFieldValue(fieldKhamBenh.TAI_KHAM_SAU);

    if (!!tai_kham_sau && !!val) {
      let today = new Date();

      if (val === "NGAY") {
        today.setDate(today.getDate() + Number(tai_kham_sau));
      }
      if (val === "TUAN") {
        today.setDate(today.getDate() + Number(tai_kham_sau) * 7);
      }
      if (val === "THANG") {
        today.setMonth(today.getMonth() + Number(tai_kham_sau));
      }
      let milisecond = new Date(today).getTime();

      form.setFields([
        {
          name: fieldKhamBenh.TAI_KHAM_NGAY,
          value: getWeekdays(milisecond) + ", " + moment(milisecond).format("DD/MM/YYYY"),
        },
      ]);
    }
  };

  const handleThemMaBenhKemTheo = (value, PHAN_NHOM = "ICD") => {
    const data = {
      ID_ICD: null,
      MA_ICD: null,
      TEN_ICD: value,
      PHAN_LOAI: "PHU",
    };
    if (PHAN_NHOM === "YHCT") {
      const newList = [...dsBenhYHCTPhu, JSON.stringify(data)];
      form.setFields([{ name: fieldKhamBenh.benh_yhct_phu, value: newList }]);
      setDsBenhYHCTPhu(newList);
    } else {
      const newList = [...dsBenhPhu, JSON.stringify(data)];
      form.setFields([{ name: fieldKhamBenh.benh_phu, value: newList }]);
      setDsBenhPhu(newList);
    }
  };
  // xử lý phím tắt
  const keydownEvent = (event) => {
    if (disableKeyEvent) return;
    const stopDefaultEvent = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    if (event.ctrlKey && event.key === "i") {
      stopDefaultEvent(event);
      if (!checkRoleArr?.IN_PDF) return;
      handlePrint();
    } else if (event.ctrlKey && event.key === "s") {
      stopDefaultEvent(event);
      if (!(checkRoleArr?.SUA || checkRoleArr?.THEM)) return;
      isCanDo ? handeSaveOnlyCSST() : handleSave()
      // handleSave();
    } else if (event.ctrlKey && event.key === "l") {
      stopDefaultEvent(event);
      if (!(checkRoleArr?.SUA || checkRoleArr?.THEM) || !checkRoleArr?.IN_PDF) return;
      isCanDo ? handeSaveOnlyCSST(true) : handleSave(undefined, true);
      // handleSave(undefined, true);
    }
  };

  //Hàm xử lí check lưu mẫu thông tin khám
  const onCheckLuuMauTT = (e) => {
    const { checked } = e.target;
    setCheckLuuMauTT(checked);
    if (!checked) {

    }
  };

  //hàm xử lí chọn mẫu thông tin điền sẵn
  const onClickMauThongTin = () => {};

  useEventListener("keydown", keydownEvent, window, isVisible);

  const isCheckRender = () => {
    const listCheckRender = [keyFormKham.KHAM_RANG_HAM_MAT, keyFormKham.KHAM_TAI_MUI_HONG, keyFormKham.KHAM_DINH_DUONG]
    let check = false;
    for (const key of listCheckRender) {
      if (phongChiDinh?.FORM_KHAM_BENH?.includes?.(key)) {
        check = true;
        break;
      }
    }
    return check;

  }

  const timKiemICD102 = useCallback(
    debounce((value, LOAI, PHAN_LOAI) => {
      timKiemICD10(value, LOAI, PHAN_LOAI)
    }, 1000),
    []
  );

  const handleSearch = (value, LOAI, PHAN_LOAI) => {
    timKiemICD102(value, LOAI, PHAN_LOAI);
  };


  const rennderLayout = useMemo(() => {
    if (isCanDo) {
      return <ChiSoSinhTon form={form} dataExam={dataExam} isOnlyCSST={true} />
    }
    return <Layout style={{ height: "100%" }}>
      <Layout>
        <Layout.Content className={style["left-thongTin"]}>
          <Row align="middle" justify="space-between">
            <Col>
              <Button
                className={tabActive === tabThongTin.HOI_BENH ? style["button-active"] : style["button"]}
                onClick={() => setTabActive(tabThongTin.HOI_BENH)}
              >
                {i18n.t(languageKeys.hoi_benh)}
              </Button>
              {!isCheckRender() && (<Button
                className={tabActive === tabThongTin.KHAM_TONG_QUAN ? style["button-active"] : style["button"]}
                onClick={() => setTabActive(tabThongTin.KHAM_TONG_QUAN)}
              >
                {i18n.t(languageKeys.kham_tong_quan)}
              </Button>
              )}

              <Button
                className={tabActive === tabThongTin.CHAN_DOAN ? style["button-active"] : style["button"]}
                onClick={() => setTabActive(tabThongTin.CHAN_DOAN)}
              >
                {i18n.t(languageKeys.chan_doan_xu_tri)}
              </Button>
            </Col>
            <Space>
              <PopupThongTinKham
                ref={mauTTKRef}
                isOpen={isOpenTTKham}
                setIsOpen={setIsOpenTTKham}
                parseDataInfo={parseDataInfo}
                patientInfo={patientInfo}
                onResetSauLuuTTK={onResetSauLuuTTK}
                isEditAllowed={checkRoleArr?.SUA}
                configPermissions={checkRoleArr}
                phongChiDinh={phongChiDinh}
                isRenderKTQ={!isCheckRender()}
                form={form}
              >
                <Button type="primary" ghost={true} onClick={() => onClickMauThongTin()}>
                  <span style={{ display: "flex", alignItems: "center", gap: 10, color: "#2C3782" }}>
                    <IconTemplate />
                    {i18n.t(languageKeys.mau_thong_tin)}
                    <DownVector />
                  </span>
                </Button>
              </PopupThongTinKham>
            </Space>
          </Row>

          <HoiBenh
            containerStyle={{ display: tabActive === tabThongTin.HOI_BENH ? "block" : "none" }}
            form={form}
            isEditAllowed={checkRoleArr?.SUA}
            roomTemplate={phongChiDinh?.FORM_KHAM_BENH}
          />

          {!isCheckRender() && (
            <KhamTongQuan
              phongChiDinh={phongChiDinh}
              containerStyle={{ display: tabActive === tabThongTin.KHAM_TONG_QUAN ? "block" : "none" }}
              isEditAllowed={checkRoleArr?.SUA}
              form={form}
            />
          )}

          {
            phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_DINH_DUONG)
              ? (
                <FormChanDoanXuTriDinhDuong
                  containerStyle={{ display: tabActive === tabThongTin.CHAN_DOAN ? "block" : "none" }}
                  isEditAllowed={checkRoleArr?.SUA}
                  readOnly={false}
                />
              ) : (
                <ChanDoanXuTri
                  isNotRequired={!requireChanDoan}
                  containerStyle={{ display: tabActive === tabThongTin.CHAN_DOAN ? "block" : "none" }}
                  form={form}
                  dsICD10Chinh={dsICD10Chinh}
                  dsICD10Phu={dsICD10Phu}
                  dsBenhPhu={dsBenhPhu}
                  onSearchDsICD10Chinh={(value) => handleSearch(value, "CHINH")}
                  onSearchDsICD10Phu={(value) => handleSearch(value, "PHU")}
                  onSelectICDPhu={setDsBenhPhu}
                  onChuyenTabKeThuoc={handleChuyenTabKeThuoc}
                  onPressEnterThemICD={(value) => handleThemMaBenhKemTheo(value, "ICD")}
                  dsYHCTChinh={dsYHCTChinh}
                  dsYHCTPhu={dsYHCTPhu}
                  dsBenhYHCTPhu={dsBenhYHCTPhu}
                  onSelectBenhYHCTPhu={setDsBenhYHCTPhu}
                  onSearchDsYHCTChinh={(value) => handleSearch(value, "CHINH", "YHCT")}
                  onSearchDsYHCTPhu={(value) => handleSearch(value, "PHU", "YHCT")}
                  onPressEnterThemYHCT={(value) => handleThemMaBenhKemTheo(value, "YHCT")}
                  isEditAlowed={checkRoleArr?.SUA}
                  phongChiDinh={phongChiDinh}
                  patientInfo={patientInfo}
                  setDisabledTaiKham={setDisabledTaiKham}
                  setCollapsed={setCollapsed}
                />
              )
          }

        </Layout.Content>
      </Layout>
      <Layout.Sider width={300} className={style["right-chiso"]}>
        <ChiSoSinhTon form={form} dataExam={dataExam} />
      </Layout.Sider>
    </Layout>
  })

  const items = [
    {
      key: '1',
      label: (
        <span onClick={() => {
          form.setFieldsValue({
            KHAM_NOI_GIAC_NGU: false,
            KHAM_NOI_GIAC_NGU_TEXT: "KHAM_TONG_QUAT"
          })
        }} >
          {i18n.t(languageKeys.kham_noi_tq)}
        </span>
      ),
    },
    {
      key: '2',
      label: (
        <span onClick={() => {
          form.setFieldsValue({
            KHAM_NOI_GIAC_NGU: true,
            KHAM_NOI_GIAC_NGU_TEXT: "KHAM_NOI_GIAC_NGU"
          })
        }} >
          {i18n.t(languageKeys.kham_noi_giac_ngu)}
        </span>
      ),
    }
  ];

  const buttonFormKham = useMemo(() => {
    if ((phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_NOI_GIAC_NGU) || phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_NOI_TONG_QUAT))) {
      return <Dropdown
        menu={{
          items,
        }}
        placement="bottomLeft"
      >
        <div className={style["header_ion"]}>
          <IconTemplate />
          {KHAM_NOI_GIAC_NGU_TEXT == "KHAM_NOI_GIAC_NGU" ? i18n.t(languageKeys.kham_noi_giac_ngu) : i18n.t(languageKeys.kham_noi_tq)} <DownVector />
        </div>
      </Dropdown>
    }
    return ""
  }, [phongChiDinh, KHAM_NOI_GIAC_NGU_TEXT])

  return (
    <div className={style["layout"]}>
      <Spin spinning={loadingSaveExam} wrapperClassName={style.spin}>

        <div className={style["header-kham-benh"]}>
          <h2>
            {i18n.t(languageKeys.field_Dich_vu)}: {dataLichKham?.TEN_DICHVU || ""}
          </h2>
          {buttonFormKham}
        </div>

        <div className={style["content"]}>
          <Form
            layout="vertical"
            style={{ height: "100%" }}
            form={form}
            onFinishFailed={() => {
              ketThucRef.current.loading(false);
            }}
          >
            <div className={style["container"]}>
              <Layout className={style["layout-container"]}>
                <Layout.Content style={{ height: "100%" }}>{rennderLayout}</Layout.Content>

                <Layout.Footer className={style["kham-lai"]}>
                  {!isCanDo && (
                    <>
                      <Row justify="space-between" className={style["form-bottom"]}>
                        <Col>
                          <Space align="center" style={{ marginLeft: 15 }}>
                            <p style={styles.title}>{i18n.t(languageKeys.bs_kham)}</p>
                            <Form.Item
                              name={fieldKhamBenh.BAC_SI_ID}
                              style={{ marginBottom: 0 }}
                              rules={[{ required: true, message: "" }]}
                            >
                              <SelectAntd
                                style={{ width: '200px' }}
                                showSearch
                                filterOption={(input, option) =>
                                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={dsBacSi?.map(obj => ({ label: obj.TEN_BAC_SI, value: obj.NHANSU_ID, key: obj.NHANSU_ID }))}
                                placeholder={i18n.t(languageKeys.common_Chon)}
                              />
                            </Form.Item>
                          </Space>
                        </Col>
                        <Col>
                          <Row justify="end" align="middle" gutter={10} style={{ marginRight: 10 }}>
                            <Col className={style["blue-txt"]}>
                              <Tooltip title={checkRoleArr?.SUA ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                                <Form.Item style={{ marginBottom: 0 }} valuePropName="checked" name={fieldKhamBenh.TAI_KHAM}>
                                  <Checkbox
                                    disabled={!checkRoleArr?.SUA}
                                    onChange={(e) => {
                                      const { checked } = e.target;
                                      if (!checked) {
                                        form.resetFields([fieldKhamBenh.TAI_KHAM_LOAI, fieldKhamBenh.TAI_KHAM_SAU, fieldKhamBenh.TAI_KHAM_NGAY, fieldKhamBenh.GHI_CHU_TAI_KHAM]);
                                      }
                                      setDisabledTaiKham(!checked);
                                      form.setFieldValue(fieldKhamBenh.TAI_KHAM, +checked);
                                    }}
                                  >
                                    {i18n.t(languageKeys.field_Tai_kham)}
                                  </Checkbox>
                                </Form.Item>
                              </Tooltip>
                            </Col>

                            <Col className={style["blue-txt"]} style={{ borderLeft: "1px solid #DFDFDF", paddingLeft: 10 }}>
                              {i18n.t(languageKeys.field_Tai_kham_sau)}
                            </Col>

                            <Col>
                              <Form.Item
                                style={{ marginBottom: 0 }}
                                name={fieldKhamBenh.TAI_KHAM_SAU}
                                rules={[{
                                  required: !disabledTaiKham,
                                  message: ""
                                }]}
                              >
                                <InputNumber
                                  style={{ width: 80 }}
                                  placeholder={i18n.t(languageKeys.common_Nhap)}
                                  onChange={handleTaiKhamSau}
                                  readOnly={disabledTaiKham}
                                  min={1}
                                />
                              </Form.Item>
                            </Col>

                            <Col>
                              <Form.Item
                                style={{ marginBottom: 0 }}
                                name={fieldKhamBenh.TAI_KHAM_LOAI}
                                rules={[{
                                  required: !disabledTaiKham,
                                  message: ""
                                }]}
                              >
                                <Select
                                  style={{ width: 100 }}
                                  dataSource={danhSachLoaiTaiKham}
                                  valueKey="key"
                                  titleKey="name"
                                  disabled={disabledTaiKham}
                                  onSelect={handleTaiKhamLoai}
                                />
                              </Form.Item>
                            </Col>

                            <Col>
                              <Form.Item style={{ marginBottom: 0 }} name={fieldKhamBenh.TAI_KHAM_NGAY}>
                                <Input readOnly prefix={<Calendar className={style["icon-calendar"]} />} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {!!TAI_KHAM ? (
                        <Row style={{ padding: '5px 15px 0' }}>
                          <Col span={24}>
                            <label>{i18n.t(languageKeys.ghi_chu)}</label>
                          </Col>
                          <Col span={24}>
                            <Form.Item name={fieldKhamBenh.GHI_CHU_TAI_KHAM}>
                              <Input
                                placeholder={i18n.t(languageKeys.common_Nhap)}
                                disabled={!patientInfo || !(checkRoleArr?.SUA || checkRoleArr?.THEM)}
                              />
                            </Form.Item>
                          </Col>
                          <Form.Item name={"ACTION_TAI_KHAM"} hidden />
                        </Row>
                      ) : <></>}

                      <Row justify="start" style={{ paddingLeft: 10 }}>
                        <Col span={24}>
                          <Tooltip title={checkRoleArr?.THEM_MAU ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                            <Form.Item name="IS_MAU_THONG_TIN" valuePropName="checked">
                              <Checkbox disabled={!patientInfo || !checkRoleArr?.THEM_MAU} className="navy-txt" onChange={onCheckLuuMauTT}>
                                {i18n.t(languageKeys.txt_luu_mau_thong_tin)}
                              </Checkbox>
                            </Form.Item>
                          </Tooltip>
                        </Col>

                        {checkLuuMauTT && (
                          <>
                            <Space>
                              <p className={styles["title-input"]}>
                                {" "}
                                <span style={{ color: "#FF0000" }}>* </span>
                                {i18n.t(languageKeys.ten_mau)}
                              </p>
                              <Form.Item
                                label=""
                                name="TEN_MAU"
                                rules={[
                                  {
                                    required: true,
                                    message: i18n.t(languageKeys.msg_ten_mau),
                                  },
                                ]}
                              >
                                <Input readOnly={!patientInfo} className={styles["input"]} placeholder={i18n.t(languageKeys.common_Nhap)} />
                              </Form.Item>
                            </Space>
                          </>
                        )}
                      </Row>
                    </>
                  )}

                  <Row gutter={10} justify="end" style={{ paddingRight: 10, marginBottom: 5 }}>
                    {!isCanDo && trang_thai !== "KHAM_XONG" && (
                      <Col>
                        <Tooltip title={checkRoleArr?.SUA ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                          <Button
                            type="primary"
                            ghost
                            danger
                            onClick={() => handleSave("KHAM_XONG")}
                            loading={loadingFinishExam}
                            disabled={!patientInfo || !checkRoleArr?.SUA}
                          >
                            {i18n.t(languageKeys.Ket_thuc_cuoc_kham)}
                          </Button>
                        </Tooltip>
                      </Col>
                    )}
                    <Col>
                      <Tooltip title={checkRoleArr?.IN_PDF ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                        <Button
                          type="primary"
                          icon={<PrinterOutlined style={{ color: "#fff" }} />}
                          onClick={handlePrint}
                          disabled={!patientInfo || !checkRoleArr?.IN_PDF}
                        >
                          {i18n.t(languageKeys.field_In_phieu)} (Ctrl + I)
                        </Button>
                      </Tooltip>
                    </Col>

                    <Col>
                      <Tooltip
                        title={
                          (checkRoleArr?.SUA || checkRoleArr?.THEM) && checkRoleArr?.IN_PDF
                            ? null
                            : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)
                        }
                      >
                        <Button
                          type="primary"
                          onClick={() => (isCanDo ? handeSaveOnlyCSST(true) : handleSave(undefined, true))}
                          loading={loadingSaveExam}
                          disabled={!patientInfo || !(checkRoleArr?.SUA || checkRoleArr?.THEM) || !checkRoleArr?.IN_PDF}
                        >
                          {i18n.t(languageKeys.luu_va_in)} (CTRL + L)
                        </Button>
                      </Tooltip>
                    </Col>

                    <Col>
                      <Tooltip title={checkRoleArr?.SUA || checkRoleArr?.THEM ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                        <Button
                          type="primary"
                          onClick={() => (isCanDo ? handeSaveOnlyCSST() : handleSave())}
                          loading={loadingSaveExam}
                          disabled={!patientInfo || !(checkRoleArr?.SUA || checkRoleArr?.THEM)}
                        >
                          {i18n.t(languageKeys.common_Luu)} (Ctrl + S)
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>

                </Layout.Footer>
              </Layout>
            </div>
          </Form>
        </div>
      </Spin>

      <ModalPDF ref={pdfRef} ly_do_kham={moTa} isOnlyCSST={isCanDo} />

      <ConfirmModal
        ref={finishRef}
        title={i18n.t(languageKeys.common_Canh_bao)}
        okText={i18n.t(languageKeys.common_Tiep_tuc)}
        content={i18n.t(languageKeys.noti_Tiep_tuc_khi_chua_ke_don_thuoc)}
        onOk={() => {
          finishRef.current.loading(true);
          handleUpdateExamStatus("KHAM_XONG", dataExam);
        }}
        onCancel={() => ketThucRef.current.close()}
        danger
        onPressCancelSuccess={() => ketThucRef.current.close()}
      />

      <ConfirmModal
        ref={rebornRef}
        title={i18n.t(languageKeys.title_Xac_nhan)}
        okText={i18n.t(languageKeys.common_Tiep_tuc)}
        content={i18n.t(languageKeys.confirm_Chuyen_cuoc_kham_tu_hoan_thanh_sang_dang_kham)}
        onOk={({ currentData, print }) => {
          rebornRef.current.loading(true);
          handleUpdateExamStatus("DANG_KHAM", currentData, print);
        }}
      />

      <ConfirmModal
        ref={ketThucRef}
        title={i18n.t(languageKeys.common_Canh_bao)}
        okText={i18n.t(languageKeys.common_Tiep_tuc)}
        content={i18n.t(languageKeys.confirm_ket_thuc_cuoc_kham)}
        onOk={() => {
          ketThucRef.current.loading(true);
          handleSave("KHAM_XONG");
        }}
        danger
      />

      <XacNhanTaiKham
        ref={taiKhamRef}
        form={form}
        handleSave={() => (isCanDo ? handeSaveOnlyCSST() : handleSave())}
      />

    </div>
  );
});

export default ThongTinKham;


const styles = {
  input: {
    border: "none",
    backgroundColor: "#F5F7FB",
  },
  title: {
    fontWeight: "700",
    fontSize: "13px",
    color: "#2C3782",
  },
};
