import { Col, Layout, Row } from "antd";
import { Check_box, Uncheckbox } from "assets/svg";
import { Table } from "components";
import { debtManagementState, getAdvanceMoney, getUnpaidDebt } from "ducks/slices/QlyVienPhi/QlyCongNo.slice";
import { tableLoadingState } from "ducks/slices/loadingSlice";
import { formatCurrency2, isEmptyObject, rid } from "helpers";
import i18n, { languageKeys } from "i18n";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as style from './index.module.less'

const Content = ({}) => {
  const dispatch = useDispatch()
  const { patientInfo, unpaidDebtList, debtList } = useSelector(debtManagementState)
  const isLoadingTable = useSelector(tableLoadingState)

  useEffect(() => {
    if (!isEmptyObject(patientInfo) && patientInfo?.ID && patientInfo?.billId) {
      dispatch(getUnpaidDebt(patientInfo))
      dispatch(getAdvanceMoney(patientInfo.ID))
    }
  }, [patientInfo])

  const columnsDichVu = [
    {
      title: i18n.t(languageKeys.field_Ten_dich_vu),
      key: "TEN_DICHVU",
      dataIndex: "TEN_DICHVU",
      width: 200,
    },
    {
      title: i18n.t(languageKeys.vien_phi_Don_gia),
      key: "DON_GIA",
      width: 150,
      render: (_, record) => <span>{formatCurrency2(record.DON_GIA, '', true)}</span>
    },
    {
      title: i18n.t(languageKeys.field_SL),
      key: "SO_LUONG",
      dataIndex: "SO_LUONG",
      width: 90,
    },
    {
      title: i18n.t(languageKeys.field_Mien_giam),
      key: "MIEN_GIAM_DICH_VU",
      dataIndex: "MIEN_GIAM_DICH_VU",
      width: 180,
      render: (_, record) => <span>{formatCurrency2(record.MIEN_GIAM_DICH_VU, '', true)}</span>
    },
    {
      title: i18n.t(languageKeys.vien_phi_thanh_tien),
      key: "THANH_TIEN",
      width: 150,
      render: (_, record) => <span>{formatCurrency2(record.SO_LUONG * record.DON_GIA - record.MIEN_GIAM_DICH_VU, '', true)}</span>
    },
    {
      title: i18n.t(languageKeys.da_thuc_hien),
      key: "TRANG_THAI_KHAM",
      width: 80,
      render: (_, record) => {
        return (record.TRANG_THAI === null || record.TRANG_THAI === "CHO_KHAM") ? <Uncheckbox /> : <Check_box />
      }
    },
  ];

  const formatDataTable = (data) => {
    return data?.DS_DV?.map(item => ({
      ...item,
      key: item.ID,
      TEN_DICHVU: item.TEN_DV,
      DON_GIA: item.DON_GIA,
      SO_LUONG: item.SO_LUONG,
      MIEN_GIAM_DICH_VU: item.GIAM_TIEN,
      THANH_TIEN: item.THANH_TIEN,
      key_second: rid(4)
    }))
  }

  return (
    <Layout.Content style={{ padding: 10 }}>
      {(debtList.length > 0 && !patientInfo.examinationCode) && (
        <Layout.Header style={{ backgroundColor: "transparent" }}>
          <Row>
            <Col flex={"auto"}>
              <Row className={style["title"]} >
                <Col span={12}><span>{i18n.t(languageKeys.goi_dv)}: {unpaidDebtList?.TEN_GOI_DV}</span></Col>
                <Col span={12}><span>{i18n.t(languageKeys.loai_goi_dv)}: {unpaidDebtList?.TEN_LOAI_GOI}</span></Col>
              </Row>
            </Col>
          </Row>
        </Layout.Header>
      )}
      <Table columns={columnsDichVu} scroll={{ y: "calc(100vh - 230px)" }} rowKey={"key_second"} dataSource={formatDataTable(unpaidDebtList)} loading={isLoadingTable} />
    </Layout.Content>
  )
}

export default Content