import { Col, Divider, Layout, notification, Row, Tooltip } from "antd";
import { flattenDeep } from "lodash";
import { forwardRef, memo, useImperativeHandle, useLayoutEffect, useState } from "react";
import { Checked, UnCheck } from "../../../../../assets/svg";
import { FormDrawer, Table } from "../../../../../components";
import { widthDrawerKeys } from "../../../../../constants/keys";
import { formatCurrency } from "../../../../../helpers";
import { useEventListener } from "../../../../../hooks";
import i18n, { languageKeys } from "../../../../../i18n";
import style from "./hoanTien.module.less";
import { layChaCon } from "../../apisDvThanhToan/apisDvThanhToan";
import { nanoid } from "@reduxjs/toolkit";

const ModalHoanTien = forwardRef(
  ({ serviceList = [], handleHoanTien = () => { }, onPrint = () => { }, patientInfo, infoGoiDv }, ref) => {

    const [visible, setVisible] = useState(false);
    const [refundService, setRefundService] = useState([]); // dịch vụ ban đầu
    const [checkRefund, setCheckRefund] = useState([]); // dịch vụ có thể hoàn tiền
    const [districtService, setDistrictService] = useState([]); // dịch vụ Khong thể hoàn tiền
    const [loading, setLoading] = useState(false);
    // const [tongTien, setTongTien] = useState(0);
    const [selectedService, setSelectedService] = useState([]);

    useImperativeHandle(ref, () => ({
      checkRefund: selectedService,
      open: () => {
        setVisible(true);
      },
      close: () => {
        handleClose();
      },
      submit: (print = false) => thanhToan(print),
    }));

    const handleClose = () => {
      setVisible(false);
    };

    const columns = [
      {
        title: i18n.t(languageKeys.field_Ten_dich_vu),
        dataIndex: patientInfo.LOAI === 'THANH_TOAN_MUA_GOI' ? "TEN_DV" : "TEN_DICHVU",
        width: 500,
      },
      {
        title: i18n.t(languageKeys.field_don_gia),
        dataIndex: "GIA_DICH_VU",
        render: (money, record) => (patientInfo.LOAI === 'THANH_TOAN_MUA_GOI' && record.DV_KEM_THEO) ? "" : formatCurrency(money, "đ", true),
        width: 200,
      },
      {
        title: i18n.t(languageKeys.field_So_luong),
        dataIndex: "SO_LUONG",
        width: 200,
        render: (quantity, record) => (patientInfo.LOAI === 'THANH_TOAN_MUA_GOI' && record.DV_KEM_THEO) ? "" : quantity
      },
      {
        title: i18n.t(languageKeys.da_thuc_hien),
        dataIndex: "TRANG_THAI",
        width: 150,
        render: (status, record) => {
          if (patientInfo.LOAI === 'THANH_TOAN_MUA_GOI' && record.DV_KEM_THEO) return <></>
          if (record.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN") {
            return (
              <div style={{ marginLeft: 6 }} >
                <Tooltip title={i18n.t(languageKeys.da_thuc_hien)}>
                  <Checked style={{ marginTop: 3, marginBottom: -3 }} />
                </Tooltip>
              </div>
            );
          }
          if (patientInfo?.LOAI === "THANH_TOAN_MUA_GOI"
            ? (status === null || status === "CHUA_KHAM")
            : (status === "CHO_XAC_NHAN" || status === "XAC_NHAN" || status === "CHO_KHAM")
          ) {
            return (
              <div style={{ marginLeft: 6 }} >
                <Tooltip title={i18n.t(languageKeys.chua_thuc_hien)}>
                  <UnCheck style={{ marginTop: 3, marginBottom: -3 }} />
                </Tooltip>
              </div>
            );
          } else {
            return (
              <div style={{ marginLeft: 6 }} >
                <Tooltip title={i18n.t(languageKeys.da_thuc_hien)}>
                  <Checked style={{ marginTop: 3, marginBottom: -3 }} />
                </Tooltip>
              </div>
            );
          }
        },
      },
      {
        title: i18n.t(languageKeys.field_Mien_giam),
        dataIndex: "TONG_TIEN_GIAM",
        width: 200,
        render: (mien_giam, record) => (patientInfo.LOAI === 'THANH_TOAN_MUA_GOI' && record.DV_KEM_THEO) ? "" : formatCurrency(mien_giam, "đ", true)
      },
      {
        title: i18n.t(languageKeys.vien_phi_thanh_tien),
        dataIndex: "TONG_THANH_TOAN",
        render: (money, record) => {
          let tong_tien = Number(record.GIA_DICH_VU) * Number(record.SO_LUONG) - record.TONG_TIEN_GIAM;
          return (patientInfo.LOAI === 'THANH_TOAN_MUA_GOI' && record.DV_KEM_THEO) ? "" : <span className="green-txt">{formatCurrency(Math.round(tong_tien), "đ", true)}</span>;
        },
        width: 200,
      },
    ];

    useLayoutEffect(() => {
      if (!!serviceList.length) {
        let refundList = [];

        if (infoGoiDv && patientInfo?.LOAI === "THANH_TOAN_MUA_GOI") {
          refundList = serviceList.filter(i => i.GOI_DV_ID === infoGoiDv.GOI_DV_ID).map((phieu_dv, item) => phieu_dv.children);
        } else {
          refundList = serviceList.map((phieu_dv, item) => phieu_dv.children);
        }

        refundList = flattenDeep(refundList);
        let checklist = refundList.filter( //dịch vụ có thể hoàn tiền
          (item) => patientInfo?.LOAI === "THANH_TOAN_MUA_GOI"
            ? (item.TRANG_THAI === null || item.TRANG_THAI === "CHUA_KHAM")
            : ((item.TRANG_THAI === "CHO_XAC_NHAN" || item.TRANG_THAI === "XAC_NHAN" || item.TRANG_THAI === "CHO_KHAM") && item.TRANG_THAI_THUC_HIEN !== "DA_THUC_HIEN")
        );
        let districtList = refundList.filter( // dịch vụ Khong thể hoàn tiền
          (item) => {
            if (item.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN") {
              return item
            }
            if (patientInfo?.LOAI === "THANH_TOAN_MUA_GOI"
              ? !(item.TRANG_THAI === null || item.TRANG_THAI === "CHUA_KHAM")
              : !(item.TRANG_THAI === "CHO_XAC_NHAN" || item.TRANG_THAI === "XAC_NHAN" || item.TRANG_THAI === "CHO_KHAM")
            ) {
              return item
            }
          }
        );

        setDistrictService(districtList.map((item) => ({ ...item, key: item.ID })));
        setCheckRefund(checklist.map((item) => ({ ...item, key: item.ID })));

        // let tong_tien = refundList.reduce((acc, curr) => {
        //   return acc + Number(curr.GIA_DICH_VU) * Number(curr.SO_LUONG) - curr.TONG_TIEN_GIAM;
        // }, 0);
        // setTongTien(tong_tien);
        if (patientInfo.LOAI === 'THANH_TOAN_MUA_GOI') {
          infoGoiDv && formatDsHoanGoi(refundList)
        } else {
          setRefundService(refundList.map((item) => ({ ...item, key: item.ID })));
          setSelectedService(checklist.map((item) => ({ ...item, key: item.ID })));
        }
        
        return () => {
          setRefundService([]);
        };
      }
    }, [serviceList, visible]);

    // const handleSubmit = (print) => {
    //   onPrint(print);
    //   form.submit();
    // };

    const formatDsHoanGoi = async (refundList) => {
      setLoading(true)
      try {
        const res = await layChaCon({ ID: infoGoiDv?.GOI_DV_ID })
        let arr = []
        res.forEach((dv) => {
          if (dv.ID) {
            let NoChildSv = refundList.find(i => i.ID === dv.ID)
            arr.push({ ...NoChildSv, key: dv.ID })
          } else {
            let childArr = []
            dv.DV_KEM_THEO.forEach((kt) => {
              if (kt.ID) {
                let childSv = refundList.find(i => i.ID === kt.ID)
                childArr.push({ ...childSv, key: kt.ID })
              }
            })
            arr.push({ ...dv, key: nanoid(), children: childArr })
          }
        })
        setRefundService(arr)
        setSelectedService(arr.filter(i => i.TRANG_THAI === null || i.TRANG_THAI === "CHUA_KHAM"))
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }

    const tongMienGiam = () => {
      let tong_giam = selectedService.reduce((acc, curr) => {
        return acc + curr.TONG_TIEN_GIAM;
      }, 0);
      return Math.round(tong_giam);
    };

    const daThucHien = () => {
      let tong_tt = districtService.reduce((acc, curr) => {
        return acc + Number(curr.GIA_DICH_VU) * Number(curr.SO_LUONG) - curr.TONG_TIEN_GIAM;
      }, 0);

      return Math.round(tong_tt);
    };

    const tongTienChuaThucHien = () => {
      let tong_chua_tt = checkRefund.reduce((acc, curr) => {
        return acc + Number(curr.GIA_DICH_VU) * Number(curr.SO_LUONG) - curr.TONG_TIEN_GIAM;
      }, 0);
      return tong_chua_tt;
    };

    const tongTienHoan =
      selectedService.reduce((acc, curr) => {
        let result = acc + (curr.SO_LUONG * curr.GIA_DICH_VU)
        return Math.round(result)
      }, 0)


    const thanhToan = async (print = false) => {
      setLoading(true)
      if (!selectedService.length) {
        notification.error({
          message: i18n.t(languageKeys.vienphi_dv_khong_thanh_toan),
          placement: "bottomLeft",
        });
      }
      await handleHoanTien(
        {
          TONG_CHI_PHI: tongTienHoan,
          MIEN_GIAM: tongMienGiam(),
          TONG_THANH_TOAN: hoanTien(),
          list_dv: selectedService,
          list_DS_DV: refundService,
        },
        print
      );
      setLoading(false)
    };

    const tongChiPhi = () => {
      const chi_phi = refundService.reduce((acc, curr) => {
        return acc + curr.SO_LUONG * curr.GIA_DICH_VU;
      }, 0);
      return Math.round(chi_phi);
    };

    const keydownEvent = (event) => {
      const stopDefaultEvent = (e) => {
        e.stopPropagation();
        e.preventDefault();
      };
      if (event.ctrlKey && event.key === "l") {
        stopDefaultEvent(event);
        thanhToan(true);
      } else if (event.ctrlKey && event.key === "s") {
        stopDefaultEvent(event);
        thanhToan(false);
      }
    };

    useEventListener("keydown", keydownEvent, window.document, visible);

    const configDisableCheckbox = (record) => {
      let disable = false;

      if (record?.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN") {
        disable = true;
      }
      if (patientInfo?.LOAI === "THANH_TOAN_MUA_GOI"
        ? !(record?.TRANG_THAI === null || record?.TRANG_THAI === "CHUA_KHAM")
        : !(record?.TRANG_THAI === "CHO_XAC_NHAN" || record?.TRANG_THAI === "XAC_NHAN" || record?.TRANG_THAI === "CHO_KHAM")
      ) {
        disable = true;
      }

      return disable
    }

    const hoanTien = () =>
      selectedService.reduce((acc, curr) => {
        return Math.round(acc + (curr.SO_LUONG * curr.GIA_DICH_VU - curr.TONG_TIEN_GIAM));
      }, 0);

    return (
      <FormDrawer
        visible={visible}
        title={i18n.t(languageKeys.vienphi_hoan_tien_dich_vu)}
        width={widthDrawerKeys.medium}
        onCancel={handleClose}
        cancelText={i18n.t(languageKeys.common_Thoat)}
        hiddenTopAction={false}
        buttonsAtBottomOfContent
        addBtn
        headerClass={style["form-header"]}
        onOk={() => {
          thanhToan(true);
        }}
        loading={loading}
        okText={<>{i18n.t(languageKeys.vien_phi_Hoan_tien_va_in)}</>}
        addBtnAction={() => thanhToan(false)}
        addBtnText={i18n.t(languageKeys.vien_phi_Hoan_tien)}
        disableOkBtn={selectedService.length === 0 || loading}
        disableAddBtn={selectedService.length === 0 || loading}
        tooltipWhenDisabled={''}
        footerClass={style["footer-drawer"]}
      >
        <Layout style={{ minHeight: "calc(100vh - 130px)" }}>
          <Layout.Content className={style["container"]}>
            <h2 style={{ marginBottom: 12, fontSize: 16 }}>{i18n.t(languageKeys.vienphi_ds_dv_can_hoan_tien)}</h2>

            <Table
              columns={columns}
              dataSource={refundService}
              className={style["table-ht"]}
              selectedRows={selectedService}
              onSelectRows={setSelectedService}
              // rowSelection={{
              //   ...rowSelection,
              // }}
              rowPropsConfig={(record) => ({
                disabled: configDisableCheckbox(record),
              })}
              scroll={{ y: 'calc(100vh - 240px)', x: true }}
            />
          </Layout.Content>
          <Layout.Sider theme="light" style={{ padding: "0 16px" }}>
            <Row className={style["title-tien"]}>
              <h3 style={{ fontSize: 16 }}>{i18n.t(languageKeys.field_Thanh_toan)}</h3>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row justify="space-between">
              <Col span={24}>
                <Row justify="space-between" className={style["title-tien"]}>
                  <Col>{i18n.t(languageKeys.tong_tien)}:</Col>
                  <Col>{formatCurrency(tongChiPhi(), "đ", true)}</Col>
                </Row>
                <Row justify="space-between" className={style["title-tien"]}>
                  <Col>{i18n.t(languageKeys.vienphi_tong_mien_giam)}:</Col>
                  <Col>{formatCurrency(tongMienGiam(), "đ", true)}</Col>
                </Row>
                <Row justify="space-between" className={style["title-tien"]}>
                  <Col>{i18n.t(languageKeys.vienphi_da_thanh_toan)}:</Col>
                  <Col>{formatCurrency(tongChiPhi() - tongMienGiam(), "đ", true)}</Col>
                </Row>
                <Row justify="space-between" className={style["title-tien"]}>
                  <Col>{i18n.t(languageKeys.vienphi_da_thuc_hien)}:</Col>
                  <Col>{formatCurrency(daThucHien(), "đ", true)}</Col>
                </Row>
                <Row justify="space-between" className={style["title-tien"]}>
                  <Col>{i18n.t(languageKeys.vienphi_chua_thuc_hien)}:</Col>
                  <Col>{formatCurrency(tongTienChuaThucHien(), "đ", true)}</Col>
                </Row>
                <Row justify="space-between" className={style["title-tien"]}>
                  <Col>{i18n.t(languageKeys.vienphi_hoan_tien)}:</Col>
                  <Col className="green-txt">{formatCurrency(hoanTien(), "đ", true)}</Col>
                </Row>
              </Col>
            </Row>
          </Layout.Sider>
        </Layout>
      </FormDrawer>
    );
  }
);

export default memo(ModalHoanTien);
