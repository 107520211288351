export const fieldsSoHoaDon = {
  loai_soHD: "LOAI_SO",
  ma_soHD: "MA_SO",
  ten_soHD: "TEN_SO",
  ky_hieu_soHD: "KY_HIEU",
  tong_so_phieu_HD: "TONG_SO_PHIEU",
  QUYEN_SU_DUNG: "QUYEN_SU_DUNG",
  NHAN_SU_ID: "NHANSU_ID",
  SO_BAT_DAU: "SO_BAT_DAU",
  SO_KET_THUC: "SO_KET_THUC",
};
