import { forwardRef, useImperativeHandle, useState } from "react";
import styles from "./toolbar.module.less";
import i18n, { languageKeys } from "../../../../../i18n";
import { Button, Col, Modal, Row } from "antd";
import { convertDateToValue } from "helpers";

const XacNhanTaiKham = ({ form, handleSave = () => {} }, ref) => {

  const [open, setOpen] = useState(false)
  const [data, setData] = useState({})

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }))

  const handleOpen = (data) => {
    setOpen(true)
    setData(data)
  }

  const handleConfigAction = (action) => {
    form.setFieldValue("ACTION_TAI_KHAM", action)
    setOpen(false)
    handleSave("KHAM_XONG", data.print)
    setData({})
  }

  return (
    <Modal
      className={styles.modal}
      open={open}
      width={450}
      maskClosable={false}
      closable={false}
      footer={null}
      zIndex={999}
      style={{ top: '20%' }}
    >
      <h1>{i18n.t(languageKeys.da_co_tai_kham)}</h1>
      <div className={styles.modalBox}>
        <p>{i18n.t(languageKeys.ds_tai_kham_bao_gom)}:</p>
        <div className={styles.modalChild}>
          {data.data?.map((i, idx) => (
            <span key={idx}>
              {idx + 1}. {i.TEN_DICHVU} - {convertDateToValue(i.NGAY)} - {i.GIO}
            </span>
          ))}
        </div>
      </div>
      <Row gutter={10} justify={"center"} className={styles.footer}>
        <Col span={8}>
          <Button block type="primary" ghost onClick={() => handleConfigAction("TAO_MOI")}>
            {i18n.t(languageKeys.tao_moi)}
          </Button>
        </Col>
        <Col span={8}>
          <Button block type="primary" onClick={() => handleConfigAction("CHINH_SUA")}>
            {i18n.t(languageKeys.cap_nhat)}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default forwardRef(XacNhanTaiKham);