import { Checkbox, Col, Divider, Form, Input, InputNumber, Layout, Row, Space } from "antd";
import style from "../../qlcn.module.less";
import i18n, { languageKeys } from "../../../../../i18n";
import { currencyParser, formatCurrency2, formatNumberToPrice } from "helpers";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doFetchBook, doFetchPaymentMethod, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { debtManagementState } from "ducks/slices/QlyVienPhi/QlyCongNo.slice";
import { loaiSo, paymentMethodProto } from "constants/keys";
import { PlusCircleFilled } from "@ant-design/icons";
import { Trash } from "assets/svg";
import { Select } from "components";

const Sider = forwardRef(({ isAdvance, setIsAdvance, valueBenhNhanDua }, ref) => {

    const [form] = Form.useForm()

    const dispatch = useDispatch()
    const { paymentMethod, debtBook } = useSelector(getSharedData)
    const { unpaidDebtList, advanceMoney, patientInfo } = useSelector(debtManagementState)
    const userProfile = useSelector((state) => state.auth.user);
    
    const tienCongNo = unpaidDebtList?.TONG_CHI_PHI - unpaidDebtList?.MIEN_GIAM - (unpaidDebtList?.TONG_DA_THANH_TOAN || 0)
    const tongDaThanhToan = unpaidDebtList?.TONG_CHI_PHI - unpaidDebtList?.MIEN_GIAM
    const changeMoney = valueBenhNhanDua - (tienCongNo < 0 ? 0 : tienCongNo)

    useEffect(() => {
        paymentMethod.length === 0 && dispatch(doFetchPaymentMethod())
        !debtBook.length && dispatch(doFetchBook({ ARR_LOAISO: [loaiSo.SO_CONG_NO, loaiSo.SO_THU_TIEN] }))
    }, [userProfile])

    useEffect(() => {
        form.resetFields()
        setIsAdvance(false)
    }, [patientInfo.billId])

    useImperativeHandle(ref, () => ({
        siderValues: () => {
            let list_dv_thanh_toan = form.getFieldValue('list_dv_thanh_toan')
            if (isAdvance) {
                const payloadPML = {
                    HINH_THUC_THANH_TOAN: paymentMethod.find(i => i?.PHAN_LOAI_HINH_THUC?.includes?.(paymentMethodProto.TAM_UNG))?.ID,
                    TEN_HINH_THUC_THANH_TOAN: "TAM_UNG",
                    BENH_NHAN_DUA: advanceMoney?.TOTAL_TAM_UNG
                }
                list_dv_thanh_toan = list_dv_thanh_toan
                    ? [...list_dv_thanh_toan, payloadPML].filter(i => i.BENH_NHAN_DUA && i.HINH_THUC_THANH_TOAN )
                    : [payloadPML]
            }
            return { ...form.getFieldsValue(), changeMoney, list_dv_thanh_toan }
        },
        form,
    }))

    const formatOptionInvoiceBook = (invoiceBookList = []) => {
        return invoiceBookList?.map(i => {
            return {
                label: i.TEN_SO,
                value: i.ID
            }
        })
    }

    const checkDuplicateMethod = (_, value) => {
        const CheckMethod = form.getFieldValue("list_dv_thanh_toan")
            .filter(i => i?.HINH_THUC_THANH_TOAN === value).length > 1
        if (CheckMethod) {
            return Promise.reject(i18n.t(languageKeys.httt_bi_trung));
        }
        return Promise.resolve();
    };

    return (
        <Layout.Sider
            width={220}
            theme="light"
            className={style['right_sider']}
        >
            <h4>{i18n.t(languageKeys.field_Thanh_toan)}</h4>
            <Divider />
            <Space direction="vertical" className={style['money_container']}>
                <Space>
                    <span>{i18n.t(languageKeys.tong_tien)}:</span>
                    <b>{formatCurrency2(unpaidDebtList?.TONG_CHI_PHI, 'đ', true)}</b>
                </Space>
                <Space>
                    <span>{i18n.t(languageKeys.vienphi_tong_mien_giam)}:</span>
                    <b>{formatCurrency2(unpaidDebtList?.MIEN_GIAM, 'đ', true)}</b>
                </Space>
                <Space>
                    <span>{i18n.t(languageKeys.field_Tong_thanh_toan)}:</span>
                    <b>{formatCurrency2(tongDaThanhToan, 'đ', true)}</b>
                </Space>
                <Space>
                    <span>{i18n.t(languageKeys.status_Da_thanh_toan)}:</span>
                    <b>{formatCurrency2(+unpaidDebtList?.TONG_DA_THANH_TOAN > tongDaThanhToan ? tongDaThanhToan : (unpaidDebtList?.TONG_DA_THANH_TOAN || 0), 'đ', true)}</b>
                </Space>
                <Space>
                    <span>{i18n.t(languageKeys.so_du_cong_no)}:</span>
                    <b style={{ color: '#2CB2A5' }}>{formatCurrency2(tienCongNo < 0 ? 0 : tienCongNo, 'đ', true)}</b>
                </Space>
            </Space>
            <Divider dashed />

            <Space direction="vertical" className={style['money_container']}>
                <Checkbox
                    checked={isAdvance}
                    disabled={!patientInfo || !advanceMoney?.TOTAL_TAM_UNG > 0}
                    onChange={(e) => setIsAdvance(e.target.checked)}
                >
                    {i18n.t(languageKeys.sd_tien_tam_ung)}
                </Checkbox>
                <Space>
                    <span>{i18n.t(languageKeys.tong_tam_ung)}:</span>
                    <b style={{ color: '#2CB2A5' }}>
                        {advanceMoney?.TOTAL_TAM_UNG > 0 ? formatCurrency2(advanceMoney?.TOTAL_TAM_UNG, "đ", true) : '- đ'}
                    </b>
                </Space>
            </Space>

            <Form layout="vertical" form={form}>
                <Form.List name="list_dv_thanh_toan" initialValue={[{}]}>
                    {(fields, { add, remove }) => (
                        <Col span={24}>
                            {fields.map(({ key, name, ...restField }) => (
                                <Row size={0} direction="vertical" className={style["payment-container"]} key={key}>
                                    {fields.length > 1 && (
                                        <div type="link" className={style["delete-btn"]} onClick={() => remove(name)}>
                                            <Trash />
                                        </div>
                                    )}
                                    <Col span={24}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, "HINH_THUC_THANH_TOAN"]}
                                            label={i18n.t(languageKeys.field_hinh_thuc_thanh_toan)}
                                            rules={[
                                                {
                                                    required: !(isAdvance && advanceMoney?.TOTAL_TAM_UNG),
                                                    message: i18n.t(languageKeys.vui_long_nhap)
                                                },
                                                {
                                                    validator: checkDuplicateMethod,
                                                }
                                            ]}
                                        >
                                            <Select
                                                valueKey="ID"
                                                titleKey="TEN_HINH_THUC_THANH_TOAN"
                                                dataSource={paymentMethod
                                                    .filter(i => ![paymentMethodProto.TRA_GOP,paymentMethodProto.CONG_NO,paymentMethodProto.TIEN_DU,paymentMethodProto.TAM_UNG]
                                                        .some(txt => i?.PHAN_LOAI_HINH_THUC?.includes?.(txt)))}
                                                onChange={(val) => {
                                                    let chosenMethod = paymentMethod.find(i => i.ID === val)?.TEN_HINH_THUC_THANH_TOAN
                                                    form.setFieldValue(["list_dv_thanh_toan", name, "TEN_HINH_THUC_THANH_TOAN"], chosenMethod)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Form.Item {...restField} name={[name, "TEN_HINH_THUC_THANH_TOAN"]} hidden><Input /></Form.Item>
                                    <Col span={24}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, "BENH_NHAN_DUA"]}
                                            label={i18n.t(languageKeys.vien_phi_tong_tien_benh_nhan_dua)}
                                            rules={[{
                                                required: !(isAdvance && advanceMoney?.TOTAL_TAM_UNG),
                                                message: i18n.t(languageKeys.vui_long_nhap),
                                            }]}
                                        >
                                            <InputNumber
                                                placeholder={i18n.t(languageKeys.common_Nhap)}
                                                min={0}
                                                parser={currencyParser}
                                                formatter={formatNumberToPrice}
                                                style={{ width: "100%" }}
                                                max={tienCongNo}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                            <Row className={style["btn-add"]} onClick={() => add()}>
                                <PlusCircleFilled className="blue-txt" style={{ fontSize: 16, marginRight: 7 }} />
                                <p className="blue-txt">{i18n.t(languageKeys.them_httt)}</p>
                            </Row>
                        </Col>
                    )}
                </Form.List>

                <Space className={style['change']}>
                    <span>{i18n.t(languageKeys.vien_phi_tien_thua)}:</span>
                    <b>{formatCurrency2((changeMoney < 0 ? 0 : changeMoney), 'đ', true)}</b>
                </Space>
                <Divider dashed />
                <Form.Item label={i18n.t(languageKeys.so_cong_no)} name='SO_HOADON_ID' rules={[{ required: true, message: '' }]}>
                    <Select
                        placeholder={i18n.t(languageKeys.common_Chon)}
                        options={formatOptionInvoiceBook(debtBook)}
                    />
                </Form.Item>

            </Form>
        </Layout.Sider>
    )
})

export default Sider