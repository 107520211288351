import { apis } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";

export const apiThemDmCachDung = async ({ data, BENH_VIEN_ID, partner_code, NHANSU_ID }) => {
    let dataRequest = { ...data, BENH_VIEN_ID, partner_code, NHANSU_ID };
    HLog("data request::::", dataRequest);
    try {
        let response = await common_post(apis.dm_them_cac_dung, dataRequest, false);
        return response;
    } catch (error) {
        HLog("Api them nguon khach error", error);
    }
};
export const apiSuaDmCachDung = async ({ data }) => {
    let dataRequest = { ...data };
    delete dataRequest['key'];
    // delete dataRequest['_THU_TU'];
    // delete dataRequest['DUNG_CHO_CIS'];
    HLog("sua doi tuong", dataRequest);
    try {
        let response = await common_post(apis.dm_sua_cach_dung, dataRequest, false);
        if (response && response.status === "OK") {
            return response;
        }
    } catch (error) {
        HLog("Api sua doi tuong error", error);
    }
};
export const apiXoaDmCachDung= async ({ data, BENH_VIEN_ID, partner_code,NHANSU_ID }) => {
    try {
        if (!Array.isArray(data)) data = [data];
        let body = {
            BENH_VIEN_ID,
            partner_code,
            NHANSU_ID,
            arr_ID: data.map((item) => item.ID),
        };

        HLog("xoa doi tuong::", body);
        let res = await common_post(apis.dm_xoa_cach_dung, body, false);

        return res;
    } catch (error) {
        HLog("======::", error);
    }
};
export const apiKhoaDmCachDung = async ({ lock, data, BENH_VIEN_ID, partner_code , NHANSU_ID}) => {
    try {
        if (!Array.isArray(data)) data = [data];

        let body = {
            partner_code,
            BENH_VIEN_ID,
            arr_ID: data.map(item => !!item.ID && item.ID),
            KHOA: Number(lock),
            NHANSU_ID : NHANSU_ID
        };
        let res = await common_post(apis.dm_khoa_cach_dung, body, false);
        return res;
    } catch (error) {
        HLog("=======================", error);
    }
};
export const apiLayDmCachDung = async ({ search_string, page, BENH_VIEN_ID, partner_code }) => {
    try {
        let req = {
            BENH_VIEN_ID,
            partner_code,
            search_string,
            page,
        };
        HLog("data request lay doi tuong::::", req);

        const res = await common_post(apis.dm_lay_ds_cach_dung, req);
        if (res.status === "OK") return res;
    } catch (error) {
        HLog(error);
    }
};
export const apiExportDmCachDung= async ({ BENH_VIEN_ID, partner_code }) => {
    try {
        let res = await common_post(apis.dm_export_cach_dung, { BENH_VIEN_ID, partner_code }, false);
        if (res && res.status === "OK") {
            let { result } = res;
            res.result = result.map((item) => {
              let { ID, TEN_CACH_DUNG,THU_TU, GHI_CHU, KHOA } = item;
              return {
                ID,
                TEN_CACH_DUNG,
                THU_TU,
                GHI_CHU,
                KHOA,
              };
            });
          }
        return res;
    } catch (error) {
        HLog("errorEXPortDoiTUong::", error);
    }
};
export const apiImportDmCachDung = async ({ NHANSU_ID, data, BENH_VIEN_ID, partner_code }) => {
    try {
        let body = {
            NHANSU_ID,
            BENH_VIEN_ID,
            partner_code,
            data: data.map((item) => ({ ...item, BENH_VIEN_ID })),
        };
        let res = await common_post(apis.dm_import_cach_dung, body, false);
        return res;
    } catch (error) {
        HLog("errorIMPortDoiTUong::", error);
    }
};
