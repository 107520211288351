import { Dropdown } from "antd";
import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import style from "./searchDrugPopup.module.less";

import { useSelector } from "react-redux";
import { debounce } from "lodash";

import { common_post, HLog, rid } from "../../../../../helpers";
import { apis } from "../../../../../constants";
import { Table } from "../../../../../components";
import i18n, { languageKeys } from "../../../../../i18n";

const columns = [
  {
    title: i18n.t(languageKeys.field_Ma_thuoc),
    dataIndex: "Thuoc_Code",
  },
  {
    title: i18n.t(languageKeys.field_Ten_thuoc),
    dataIndex: "Thuoc_Ten",
  },
  {
    title: i18n.t(languageKeys.field_Don_vi_tinh),
    dataIndex: "Thuoc_Dvt",
  },
  {
    title: i18n.t(languageKeys.field_Hoat_chat),
    dataIndex: "Thuoc_HoatChat",
  },
  {
    title: i18n.t(languageKeys.field_ton_cuoi),
    dataIndex: "TON_CUOI",
  },
];

const SearchDrugPopup = forwardRef(
  (
    {
      children,
      onAddDrug = () => {},
      focusInput = () => {},
      clearInput = () => {},
      kho_thuoc  = null,
      searchID = false,
      scrollTable = { y: "50vh" },
      urlSearch = apis.api_search_thuoc_phieu_nhap,
    },
    ref
  ) => {
    const userProfile = useSelector((state) => state.auth.user);
    const [visible, setVisible] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [loadingDataSource, setLoadingDataSource] = useState([]);
    const [total, setTotal] = useState(0);

    useImperativeHandle(ref, () => ({
      setVisible,
      searchData,
    }));
    // hàm lấy danh sách thuốc
    const getDataSource = async (search_string = "", khoThuoc = kho_thuoc) => {
      try {
        const url = urlSearch;
        const body = {
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          partner_code: userProfile.partner_code,
          search_string,
          //LOAI_THUOC : "NHA_THUOC"
          PHAN_LOAI_THUOC : layLoaiThuoc(khoThuoc),
          limit : 100,
        };

        let newBody = searchID ? {...body, KHO_THUOC_ID: khoThuoc.ID} : body

        setLoadingDataSource(true);
        const res = await common_post(url, newBody);
        if (res.status === "OK") {
          const list = res.result.map((item) => ({
            ...item,
            key: rid(5),
          }));
          setDataSource(list);
          setTotal(res.total);
        }
        setLoadingDataSource(false);
      } catch (error) {
        HLog(error);
        setLoadingDataSource(false)
        //setLoadingDataSource(true);
      }
    };

    // hàm tìm kiếm thuốc
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchData = useCallback(debounce(getDataSource, 1000), []);

    // hàm xử lý hiển thị popup
    const handleVisible = (bool) => {
      if (!kho_thuoc) return;
      setVisible(bool);
      if (bool && dataSource.length === 0 && kho_thuoc) getDataSource();

    };

    // hàm xử lý khi chọn 1 row thuốc
    const handleClickRow = (record) => {
      onAddDrug(record);
      setVisible(false);
      // clearInput();
      focusInput();
    };

    return (
      <Dropdown
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisible}
        overlayClassName={style["container"]}
        overlay={
          <>
            <h4 className={style["title"]} onClick={focusInput}>
              {i18n.t(languageKeys.field_Ket_qua_tim_kiem)}: {total}
            </h4>

            <div className={style["table-ctn"]}>
              <Table
                columns={columns}
                dataSource={dataSource}
                loading={loadingDataSource}
                scroll={scrollTable}
                onClickRow={handleClickRow}
                onHeaderRow={() => ({
                  onClick: focusInput,
                })}
              />
            </div>
          </>
        }
      >
        <div style={{ width: "100%" }}>{children}</div>
      </Dropdown>
    );
  }
);

export default memo(SearchDrugPopup);

const layLoaiThuoc  = (khoThuoc) => {
  if (khoThuoc) {
    switch (khoThuoc.LOAI_KHO) {
      case "KHO_TONG" :
        return  null;
      case "NHA_THUOC" :
        return  "NHA_THUOC";
      default :
        return  "THUOC_THUONG"
    }
  }else {
    return ""
  }
}
