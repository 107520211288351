import { Button, Col, Drawer, Form, Image, Input, Row, Spin, notification } from "antd";
import { useState } from "react";
import { QuestionBackGround } from "../../../../assets/img";
import style from "./style.module.less";
import { useSelector } from "react-redux";
import { apiTaoCauHoiHoTro } from "../../apisHoTro";
import i18n, { languageKeys } from "../../../../i18n";
import { HLog, getFullName } from "../../../../helpers";
import { useForm } from "antd/lib/form/Form";

const HoiDapDrawer = ({ visible = false, onClose, width = 800, handleAddSuccess = () => {} }) => {
  const userProfile = useSelector((state) => state.auth.user);
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const defaultValue = {
    SO_DIEN_THOAI: userProfile.SDT,
    EMAIL: userProfile.EMAIL,
  };

  const themCauHoi = async (values) => {
    console.log("Success:", values);
    setLoading(true);
    let request = {
      ...values,
      NHANSU_ID: userProfile.NHANSU_ID,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
      TEN_CSKCB: userProfile.benh_vien.TEN_CSKCB,
      TEN_NHAN_SU: getFullName(userProfile),
    };
    let response = await apiTaoCauHoiHoTro(request);
    setLoading(false);
    if (response && response.status === "OK") {
      notification.success({
        message: i18n.t(languageKeys.thao_tac_thanh_cong),
      });
      handleAddSuccess();
      form.resetFields();
    } else if (response && response.status === "KO") {
      notification.warning({
        message: i18n.t(languageKeys.thao_tac_that_bai),
      });
    } else {
      // console.log(response.message);
      HLog("Thêm mới thất bại", response);
    }
  };

  console.log("userProfile", userProfile);
  return (
    <Spin spinning={loading}>
      <Drawer footer={null} visible={visible} headerStyle={{ display: "none" }} onClose={onClose} width={width}>
        <div className={style["container"]}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Image src={QuestionBackGround} preview={false} />
          </div>
          <div style={{ fontSize: "30px", color: "#2C3782", fontWeight: "700", textAlign: "center" }}>{i18n.t(languageKeys.cham_soc_khach_hang)}</div>
          <div style={{ fontSize: "14px", margin: "6px 45px 40px 45px", color: "#2C3782", fontWeight: "500", textAlign: "center" }}>
            {i18n.t(languageKeys.cham_soc_khach_hang_context)}
          </div>
          <Form form={form} onFinish={themCauHoi} layout="vertical" initialValues={defaultValue}>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  label={i18n.t(languageKeys.field_So_dien_thoai)}
                  name="SO_DIEN_THOAI"
                  rules={[
                    {
                      required: true,
                      message: i18n.t(languageKeys.hay_nhap_so_dien_thoai),
                    },
                    {
                      pattern: /^\d+$/,
                      message: i18n.t(languageKeys.error_gia_tri_khong_hop_le),
                    },
                  ]}
                >
                  <Input placeholder="Nhập" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={i18n.t(languageKeys.field_Email)}
                  name="EMAIL"
                  rules={[
                    {
                      required: true,
                      message: i18n.t(languageKeys.hay_dien_email),
                    },
                    {
                      type: "email",

                      message: i18n.t(languageKeys.error_gia_tri_khong_hop_le),
                    },
                  ]}
                >
                  <Input placeholder="Nhập" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={i18n.t(languageKeys.noi_dung_phan_hoi)}
                  name="CAU_HOI"
                  rules={[{ required: true, message: i18n.t(languageKeys.nhap_noi_dung_phan_hoi) }]}
                >
                  <TextArea rows={4} placeholder={i18n.t(languageKeys.nhap_noi_dung_phan_hoi)} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                {i18n.t(languageKeys.gui)}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    </Spin>
  );
};

export default HoiDapDrawer;
