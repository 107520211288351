import { PlusOutlined } from "@ant-design/icons";
import { Button, Menu, Dropdown, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import React, { useState, useImperativeHandle } from "react";
import i18n, { languageKeys } from "../../../../i18n";
import { keyTabs } from "../NhapXuat";
import styles from "./style.module.less";
import { TooltipButton } from "components/Tooltip/CustomTooltip";

function FilterActions(
  {
    currentTab,
    onAddPhieu,
    handleChangeDate = () => {},
    handleChangeSearch = () => {},
    handleChangePhanLoai = () => {},
    handleChangePhanLoaiPhieuNhap = () => {},
    disableTaoPhieuXuat = false,
    disableTaoPhieuNhap = false,
  },
  ref
) {
  const [visible, setVisible] = useState(false);
  const [form] = useForm();

  useImperativeHandle(
    ref,
    () => ({
      reset() {
        form.resetFields();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const menu = () => {
    let dataItem = currentTab === keyTabs.PHIEU_NHAP ? item_phieu_nhap : item_phieu_xuat;
    return (
      <Menu defaultSelectedKeys={["mail"]} className={styles.menu}>
        {dataItem.map((item) => (
          <Menu.Item
            key={item.key}
            onClick={(e) => {
              onAddPhieu(item.key);
              setVisible(false);
            }}
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  function onChangeDate(values) {
    if (values) {
      let TU = moment(values[0]).format("YYYYMMDD");
      let DEN = moment(values[1]).format("YYYYMMDD");
      handleChangeDate({ TU, DEN });
    } else {
      let TU = "";
      let DEN = "";
      handleChangeDate({ TU, DEN });
    }
  }

  function onChangeSearch(txt) {
    handleChangeSearch({ search_string: txt });
  }

  function onFilterPhieu(phan_loai) {
    handleChangePhanLoai(phan_loai);
  }

  function onFilterPhieuNhap(phan_loai) {
    handleChangePhanLoaiPhieuNhap(phan_loai);
  }

  const checkDisableByTab = () => {
    if (currentTab === keyTabs.PHIEU_NHAP) {
      return disableTaoPhieuNhap ? true : false;
    } else {
      return disableTaoPhieuXuat ? true : false;
    }
  };

  return (
    <Space style={{ backgroundColor: "#FFF", marginInline: "20px", height: "100%" }} className={styles.container_filter}>
      {/* <Form form={form}>
        <Space>
         {currentTab === keyTabs.PHIEU_XUAT ?  
         <Form.Item name={"LOAI_PHIEU"}  style={{margin : 0, width:200}} initialValue="">
            <Select
              dataSource={[{key : "", label  : i18n.t(languageKeys.tat_ca)}].concat(item_phieu_xuat).concat({key : "BAN_BENH_NHAN", label: i18n.t(languageKeys.ban_benh_nhan)})}
              titleKey="label"
              valueKey="key"
              onSelect={onFilterPhieu}
            />
          </Form.Item> 
          :
          <Form.Item name={"LOAI_PHIEU"}  style={{margin : 0, width:200}} initialValue="">
            <Select
              dataSource={[{key : "", label  : i18n.t(languageKeys.tat_ca)}].concat(item_phieu_nhap)}
              titleKey="label"
              valueKey="key"
              onSelect={onFilterPhieuNhap}
            />
          </Form.Item>
          }
          <Form.Item name={"SEARCH_STRING"}  style={{margin : 0}}>
            <Input
              prefix={<SearchOutlined />}
              className={styles.input}
              placeholder={i18n.t(languageKeys.common_Tim_kiem)}
              onChange={useCallback(debounce((e) => onChangeSearch(e.target.value), 1000))}
            />
          </Form.Item>
          <Form.Item name={"FILTER_DATE"}  style={{margin : 0}}>
            <DatePicker.RangePicker className={styles.date_picker} format="DD/MM/YYYY" onChange={onChangeDate} />
          </Form.Item>
        </Space>
      </Form> */}
      <TooltipButton title={checkDisableByTab() ? i18n.t(languageKeys.khong_co_quyen) : ""}>
        <Dropdown
          overlay={menu}
          // title="Title"
          trigger="click"
          visible={visible}
          onVisibleChange={setVisible}
          placement={"bottomRight"}
          disabled={checkDisableByTab()}
        >
          <Button type="primary" icon={<PlusOutlined />} className={styles.button}>
            {i18n.t(languageKeys.tao_phieu)}
          </Button>
        </Dropdown>
      </TooltipButton>
    </Space>
  );
}

export default React.forwardRef(FilterActions);

const item_phieu_xuat = [
  {
    key: "XUAT_TRA_NCC",
    label: i18n.t(languageKeys.loai_phieu_xuat_tra_ncc),
  },
  {
    key: "XUAT_VO_HONG",
    label: i18n.t(languageKeys.xuat_vo_hong),
  },
  {
    key: "XUAT_HUY",
    label: i18n.t(languageKeys.title_phieu_xuat_huy),
  },
  {
    key: "XUAT_HET_HAN",
    label: i18n.t(languageKeys.xuat_het_han_su_dung),
  },
  // {
  //   key: 'BAN_BENH_NHAN',
  //   label: 'Bán bệnh nhân',
  // },
  {
    key: "XUAT_KHAC",
    label: i18n.t(languageKeys.xuat_khac),
  },
];
const item_phieu_nhap = [
  {
    key: "PHIEU_NHAP",
    label: i18n.t(languageKeys.nhap_tu_ncc),
  },
  {
    key: "CHUYEN_KHO",
    label: i18n.t(languageKeys.nhap_tu_kho_khac),
  },
  // {
  //   key: 'NHAP_TU_NGUON',
  //   label: 'Nhập khác',
  // },
];
