import { apis } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";

export async function layDsBenhNhan(dataRequest) {
  try {
    const response = await common_post(
      apis.vienphi_lay_ds_benh_nhan_chua_thanh_toan,
      dataRequest
    );
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    HLog("lay danh sach hang cho error", error);
  }
}

export async function luuTamHoanUng(dataRequest) {
  try {
    const response = await common_post(
      apis.vienphi_luu_tam_hoan_ung,
      dataRequest
    );
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    // return null
    HLog("lay danh sach hang cho error", error);
    return null;
  }
}

export async function layDsHoaDonKhaDung(dataRequest) {
  try {
    const response = await common_post(
      apis.lay_ds_so_hoa_don_co_phan_quyen,
      dataRequest
    );
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    HLog("lay danh sach so hoa don kha dung error", error);
  }
}
