import moment from "moment";
import { TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI } from "../../../constants/keys";

export const isRoomWorking = (record) => {
  if (!record?.GIO_BAT_DAU || !record?.GIO_KET_THUC) return true;
  const gioBatDau = moment(record?.GIO_BAT_DAU, "HH:mm");
  const gioKetThuc = moment(record?.GIO_KET_THUC, "HH:mm");
  const result = gioBatDau.isSameOrBefore(moment(), "minutes") && gioKetThuc.isSameOrAfter(moment(), "minutes");
  return result;
};

export const isRoomAfter = (record, reVal = true) => {
  if (!record?.GIO_BAT_DAU || !record?.GIO_KET_THUC) return reVal;
  const gioBatDau = moment(record?.GIO_BAT_DAU, "HH:mm");
  const gioKetThuc = moment(record?.GIO_KET_THUC, "HH:mm");
  const result = gioBatDau.isAfter(moment(), "minutes") && gioKetThuc.isAfter(moment(), "minutes");
  return result;
};

export const showDeleteBtn = (dichvu) => {
  if (dichvu?.THANH_TOAN === 1) return false;
  if (dichvu?.THANH_TOAN === 1 && dichvu?.THANH_TIEN === 0) return false;

  if (
    dichvu?.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI?.DANG_KHAM ||
    dichvu?.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI?.KHAM_XONG ||
    dichvu?.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI?.HOAN_THANH ||
    dichvu?.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN"
  ) {
    return false;
  }

  return true
};

export const showSaveBtns = (lichKhamInfo) => {
  if (!lichKhamInfo) return true;
  if (!!lichKhamInfo && (lichKhamInfo.TRANG_THAI_HIEN_TAI === "HUY" || lichKhamInfo.TRANG_THAI_HIEN_TAI === "HOAN_THANH")) {
    return false;
  }
  return true;
};

export const checkDangKhamHoacKhamXong = (lichKhamInfo) => {
  if (!lichKhamInfo) return true;
  if (!!lichKhamInfo && (lichKhamInfo.TRANG_THAI_HIEN_TAI === "DANG_KHAM" || lichKhamInfo.TRANG_THAI_HIEN_TAI === "KHAM_XONG")) {
    return false;
  }
  return true;
};

export const checkEditThongTinHanhChinh = (lichKhamInfo) => {
  return true;
};

export const checkGioLamViec = (dsDichVu) => {
  let arrayPhongError = [];
  for (const item of dsDichVu) {
    let phong = item.PHONG_THUC_HIEN || null;
    if (phong) {
      let GIO_KET_THUC = phong.GIO_KET_THUC;
      if (Date.parse(`01/01/2011 ${GIO_KET_THUC}`) < Date.parse(`01/01/2011 ${moment().format("HH:mm")}`)) {
        arrayPhongError.push(item.PHONG_THUC_HIEN.TEN_KHOA_PHONG)
      }
    }
  }
  return arrayPhongError
}

export const checkHasAvailableRoom = (record) => {
  if (!record) return false;
  // if (!record?.GIO_BAT_DAU || !record?.GIO_KET_THUC) return false;
  // else if (moment(record?.GIO_BAT_DAU) >= moment(record?.GIO_KET_THUC)) return false;

  if (!record?.GIO_BAT_DAU || !record?.GIO_KET_THUC) return true;
  const gioBatDau = moment(record?.GIO_BAT_DAU, "HH:mm");
  const gioKetThuc = moment(record?.GIO_KET_THUC, "HH:mm");
  const result = gioBatDau.isSameOrBefore(moment(), "minutes") && gioKetThuc.isSameOrAfter(moment(), "minutes");
  return result;
}

export const calcTotalExp = (data) => {
  if (data[0] && data.every(i => i.MIEN_GIAM_TIEP_DON === data[0].MIEN_GIAM_TIEP_DON && i.MIEN_GIAM_TIEP_DON <= 100)) {
    return data[0].MIEN_GIAM_TIEP_DON
  } else {
    return data.reduce((sum, item) => sum + (
      item.MIEN_GIAM_TIEP_DON
        ? (item.MIEN_GIAM_TIEP_DON > 100
          ? item.MIEN_GIAM_TIEP_DON
          : (item.GIA_DICH_VU * item.SO_LUONG / 100 * item.MIEN_GIAM_TIEP_DON))
        : 0
    ), 0)
  }
}

// Create time options
export const createHourArray = (startTime, endTime) => {
  if (!startTime || !endTime) return []
  const getHour = (time, isMnt = false) => {
      const [hour, mnt] = time.split(":");
      return parseInt(isMnt ? mnt : hour, 10);
  }
  const hoursArray = [];
  // for (let hour = getHour(startTime); hour < getHour(endTime); hour++) {
  //     if (hour === getHour(startTime)) {
  //         hoursArray.push({
  //             label: `${startTime} - ${hour >= 9 ? (hour + 1) : `0${hour + 1}`}:00`,
  //             value: `${startTime} - ${hour >= 9 ? (hour + 1) : `0${hour + 1}`}:00`
  //         });
  //     } else if (hour === getHour(endTime) - 1) {
  //         hoursArray.push({
  //             label: `${hour > 9 ? hour : `0${hour}`}:00 - ${endTime}`,
  //             value: `${hour > 9 ? hour : `0${hour}`}:00 - ${endTime}`,
  //         });
  //     } else {
  //         hoursArray.push({
  //             label: `${hour > 9 ? hour : `0${hour}`}:00 - ${hour >= 9 ? (hour + 1) : `0${hour + 1}`}:00`,
  //             value: `${hour > 9 ? hour : `0${hour}`}:00 - ${hour >= 9 ? (hour + 1) : `0${hour + 1}`}:00`,
  //         });
  //     }
  // }
  for (let hour = getHour(startTime); hour < getHour(endTime); hour++) {
      if (hour === getHour(startTime)) {
          if (getHour(startTime, true) < 30) {
              hoursArray.push(
                  {
                      label: `${startTime} - ${hour >= 9 ? hour : `0${hour}`}:30`,
                      value: `${startTime} - ${hour >= 9 ? hour : `0${hour}`}:30`
                  },
                  {
                      label: `${hour >= 9 ? hour : `0${hour}`}:30 - ${hour >= 9 ? (hour + 1) : `0${hour + 1}`}:00`,
                      value: `${hour >= 9 ? hour : `0${hour}`}:30 - ${hour >= 9 ? (hour + 1) : `0${hour + 1}`}:00`
                  },
              );
          } else {
              hoursArray.push({
                  label: `${startTime} - ${hour >= 9 ? (hour + 1) : `0${hour + 1}`}:00`,
                  value: `${startTime} - ${hour >= 9 ? (hour + 1) : `0${hour + 1}`}:00`
              });
          }
      } else if (hour === getHour(endTime)) {
          if (getHour(endTime, true) < 30) {
              hoursArray.push({
                  label: `${hour > 9 ? hour : `0${hour}`}:00 - ${endTime}`,
                  value: `${hour > 9 ? hour : `0${hour}`}:00 - ${endTime}`,
              });
          } else {
              hoursArray.push(
                  {
                      label: `${hour > 9 ? hour : `0${hour}`}:00 - ${hour > 9 ? hour : `0${hour}`}:30`,
                      value: `${hour > 9 ? hour : `0${hour}`}:00 - ${hour > 9 ? hour : `0${hour}`}:30`,
                  },
                  {
                      label: `${hour > 9 ? hour : `0${hour}`}:30 - ${endTime}`,
                      value: `${hour > 9 ? hour : `0${hour}`}:30 - ${endTime}`,
                  },
              );
          }
      } else {
          hoursArray.push(
              {
                  label: `${hour > 9 ? hour : `0${hour}`}:00 - ${hour > 9 ? hour : `0${hour}`}:30`,
                  value: `${hour > 9 ? hour : `0${hour}`}:00 - ${hour > 9 ? hour : `0${hour}`}:30`,
              },
              {
                  label: `${hour > 9 ? hour : `0${hour}`}:30 - ${hour >= 9 ? (hour + 1) : `0${hour + 1}`}:00`,
                  value: `${hour > 9 ? hour : `0${hour}`}:30 - ${hour >= 9 ? (hour + 1) : `0${hour + 1}`}:00`,
              },
          );
      }
  }
  return hoursArray
}