import { Col, Input, InputNumber, Row, Tooltip } from "antd";
import { useState } from "react";
import { Select } from "../../../../../components_v2";
import i18n, { languageKeys } from "../../../../../i18n";
import styles from "./sidebar.module.less";

export const InputMoiNgay = ({ readOnly = false, onChangeInputNumber = () => {}, record, dataSource, loading }) => {

  const [open, setOpen] = useState(false)

  // return readOnly ? (
  //   <Form.Item name="Lieuluong" label={i18n.t(languageKeys.new_moi_ngay)}
  //   //  rules={[{ required: true, message: ERROR_CHUA_NHAP_DU_THONG_TIN }]}
  //    >
  //     <Input readOnly />
  //   </Form.Item>
  // ) : (
  //   <div>
  //     <label className={styles["label-input-moi-ngay"]}>{i18n.t(languageKeys.new_moi_ngay)}</label>

  //     <Row align="middle" wrap={false} className={styles["input-moi-ngay"]}>
  //       <Col>
  //         <Form.Item
  //           name="Lieuluong"
  //           style={{ marginBottom: 0 }}
  //           rules={[
  //             // { required: true, message: ERROR_CHUA_NHAP_DU_THONG_TIN },
  //             // ({ getFieldValue }) => ({
  //             //   validator(_, value) {
  //             //     if (!value || (!!value && Number(value) <= 0)) {
  //             //       return Promise.reject(new Error(ERROR_GIA_TRI_KO_HOP_LE));
  //             //     }
  //             //     return Promise.resolve();
  //             //   },
  //             // }),
  //           ]}
  //         >
  //           <Input placeholder={i18n.t(languageKeys.common_Nhap)} name="Lieuluong" onChange={onChangeInputNumber} />
  //         </Form.Item>
  //       </Col>

  //       <Col flex="80px" style={{ paddingRight: 3 }}>
  //         <Form.Item name="Thuoc_Dvt" style={{ marginBottom: 0 }}>
  //           <Select
  //             dataSource={dataSource}
  //             className={styles["select-dvt"]}
  //             allowClear={false}
  //             style={{ width: "100%" }}
  //             loading={loading}
  //             labelKey="TEN_DVT"
  //             valueKey="TEN_DVT"
  //             filterOption={(item, option) => option.children.toLowerCase().includes(item.toLowerCase())}
  //           />
  //         </Form.Item>
  //       </Col>
  //     </Row>
  //   </div>
  // );
  return readOnly ? <Input readOnly value={record.Lieuluong} />
    : (
      <Row align="middle" wrap={false} className={styles["input-moi-ngay"]} gutter={5}>
        <Col span={13}>
          <Tooltip title={`${i18n.t(languageKeys.nhap_gia_tri_lon_hon)} 0`} placement="left" trigger="focus">
            <InputNumber
              placeholder={i18n.t(languageKeys.common_Nhap)}
              onBlur={(e) => onChangeInputNumber(e.target.value, record.STT - 1, "Lieuluong")}
              value={record.Lieuluong}
              min={1}
              controls={false}
            />
          </Tooltip>
        </Col>
        <Col span={11}>
          <Select
            dataSource={dataSource}
            className={styles["select-dvt"]}
            allowClear={false}
            loading={loading}
            labelKey="TEN_DVT"
            valueKey="TEN_DVT"
            filterOption={(item, option) => option.children.toLowerCase().includes(item.toLowerCase())}
            value={record.Thuoc_Dvt_Cach_Dung}
            onChange={(val) => {
              onChangeInputNumber(val.TEN_DVT, record.STT - 1, "Thuoc_Dvt_Cach_Dung")
              setOpen(false)
            }}
            open={open}
            onFocus={() => setTimeout(() => setOpen(true), 100)}
            onBlur={() => setOpen(false)}
            onClick={() => setOpen(!open)}
          />
        </Col>
      </Row>
    );
};
