import { Button, Col, Row, Tooltip, notification, Table as TableAnt } from "antd";
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Topbar, ConfirmModal, ActionButton, Table, Select } from "../../../../components";
import { HLog, common_post, formatNumberToPrice, getFullName, getTextThCLS, handleCheckPermissions, locdau, removeTags } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";
import ChiDinhCLS from "./ChiDinhCLS/ChiDinhCLS";
import style from "./dsDvCls.module.less";
import ModalPDF from "../../../../components/Modal/ModalPDF";
import { featureKeys, permissionActionKey } from "../../../../constants/keys";
import Icon, { CaretDownOutlined, CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Delete, Checked, UnCheck, Robot, ReloadV2 } from "../../../../assets/svg";
import DvDaTraKQ from "../../../RisNhapTay/TraKetQuaCdha/components/DvDaTraKQ";
import React from "react";
import moment from "moment";
import ActionRows from "./ActionRows";
import KetQuaXN from "./KetQuaXn/KetQuaXN";
// import FastReturnRIS from "./FastCDHA/FastReturnRIS";
import { useDsDvCls } from "./hooks/useDsDvCls";
import ChatAi from "features/ChatAi/ModalChatAi";
import useAsking from "features/ChatAi/hooks/useAsking";
import { suggestQuestionService, suggestQuestionSpecialist } from "features";
import { chatAiActions } from "ducks/slices/chatAiSlice";
import { setOpenFastRis } from "ducks/slices/khamBenhSlice";
import { ModalAddPackages } from "features";
import { apis } from "../../../../constants";
import { PackageSliceAction } from "ducks/slices/Features/packageSlice";
// import FormThongTinThuThuat from "../PhauThuatThuThuat/components/FormThongTinThuThuat";
import { trang_thai_th } from "pages/RisNhapTay";
import { getSharedData } from "ducks/slices/ListData/SharedData.slice";

const fitCenterCol = {
  width: "70px",
  align: "center",
};

const DsDichVuCLS = ({
  patientInfo,
  phongChiDinh,
  trang_thai,
  isVisible,
  isViewHistory = false,
  onChuyenSidebarTabSangDangKham = () => {},
  setDsDv,
}) => {

  const dispatch = useDispatch();
  const { useAi, user: userProfile } = useSelector((state) => state.auth);
  const { groupLoading = {}, thongTinKhamBenh } = useSelector((s) => s.khamBenh);
  const { personnel } = useSelector(getSharedData);

  const { makeAsk } = useAsking();

  const ref = useRef();
  const addPackRef = useRef();
  const deleteRef = useRef();
  const pdfRef = useRef();
  const daTraRef = useRef();
  const refKetQuaXN = useRef();
  const formRefPTTT = useRef();
  const tuongTrinhRef = useRef();

  // const trakqRef = useRef();
  const confirmRef = useRef();
  // const cdhaRef = useRef();

  const {
    apiXoaDsDvCLS,
    apiXoaDsDvCLSChildren,
    onClickRow,
    conditionCdhaRoom,
    checkDisabledDetail,
    checkDisabledDelete,
    checkDisabledEdit,
    checkShowButtonDetail,
    onPrint,
    inNhieuDIchVu,
    handleSuccess,
    handleThemCLS,
    handleReload,
    rowSelection,
    userIsAdminOrDoctor,
    isEdit,
    configPermissions,
    dsDvTheoLuotKham,
    setDsDvTheoLuotKham,
    expandedRowKeys,
    setExpandedRowKeys,
    selectedRowKeys,
    loading,
    selectedRows,
    selectedRecord,
    setSelectedRecord,
    conditionPTTTRoom,
    handleChuyenTrangThaiDichVu,
    handleHuyTra,
    handleInTuongTrinh,
    xoaNhieuDichVu,
    handleCheckDisableXoaNhieuDv
  } = useDsDvCls({
    patientInfo,
    phongChiDinh,
    trang_thai,
    isVisible,
    isViewHistory,
    onChuyenSidebarTabSangDangKham,
    ref,
    deleteRef,
    pdfRef,
    daTraRef,
    confirmRef,
    refKetQuaXN,
    userProfile,
    setDsDv,
    formRefPTTT,
    tuongTrinhRef
  });

  React.useEffect(() => () => dispatch(chatAiActions.setOpen(false)), []);

  const checkCDHAPermission = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.tra_ket_qua_cdha)?.THUC_HIEN_TRA_KQ;
  }, [userProfile]);

  const checkPTTTPermissions = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.phau_thuat_thu_thuat);
  }, [userProfile]);

  const columns = [
    {
      title: i18n.t(languageKeys.field_Ten_dich_vu),
      key: "TEN_DICHVU",
      dataIndex: "TEN_DICHVU",
      width: 300,
      render: (data, record) => {
        return (
          <span
            onClick={(e) => {
              e.stopPropagation();
              if ("children" in record) {
                let array = expandedRowKeys?.length > 0 ? [...expandedRowKeys] : [];
                const id = array.findIndex((value) => value == record.key);
                if (id == -1) {
                  array.push(record.key);
                } else {
                  array = array.filter((value) => value != record.key);
                }
                setExpandedRowKeys(array);
              }
            }}
          >
            {record && record.children ? (
              <Tooltip placement="topLeft" title={data} zIndex={1} className={style["boldTxt"]}>
                <span data-col-key="TEN_DICHVU">
                  {data === "null" || data === "undefined" || !data
                    ? ""
                    : `${data}`}
                </span>
              </Tooltip>
            ) : (
              <span>
                <Tooltip placement="topLeft" title={data} zIndex={1} className={style["boldTxt"]}>
                  <span data-col-key="TEN_DICHVU">{data}</span>
                </Tooltip>
              </span>
            )}
          </span>
        );
      },
    },
    {
      title: i18n.t(languageKeys.nguoi_chi_dinh),
      key: "NGUOI_CHI_DINH",
      width: 150,
      dataIndex: "NGUOI_CHI_DINH",
      render: (ten_bac_si, record) => {
        if (record && record.children) {
          return (
            <Row wrap={false} align="middle">
              <Col className={style["boldTxt"]}>{ten_bac_si || record?.TEN_BAC_SI}</Col>
            </Row>
          );
        } else {
          return (
            <Row wrap={false} align="middle">
              <Col>{ten_bac_si || record?.TEN_BAC_SI}</Col>
            </Row>
          );
        }
      },
    },

    {
      title: i18n.t(languageKeys.field_Phong_thuc_hien),
      key: "PHONG_THUC_HIEN",
      dataIndex: "PHONG_THUC_HIEN",
      width: 200,
      render: (data, record) => {
        if (record && !record?.children) {
          return <span>{data || record?.PHONG_CHI_DINH}</span>;
        }
      },
    },
    {
      title: i18n.t(languageKeys.implementer),
      key: "NGUOI_THUC_HIEN_ID",
      dataIndex: "NGUOI_THUC_HIEN_ID",
      width: 200,
      render: (id, record) => ["CDHA", "TDCN", "THU_THUAT"].includes(record.LOAI_PHONG) && record.TRANG_THAI_THUC_HIEN !== "DA_THUC_HIEN"
        ? (
          <Select
            value={id}
            dataSource={
              personnel
                .filter(i => i.list_phong?.some(p => p.PHONG_ID === record.PHONG_ID) && !!i.ACTIVE)
                .map(i => ({ ...i, NAME: getFullName(i) }))
            }
            titleKey="NAME"
            valueKey="NHANSU_ID"
            filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
            style={{ width: '100%' }}
            onChange={(val) => setDsDvTheoLuotKham(
              state => state.map(item =>
                item.key === record.key
                  ? { ...item, NGUOI_THUC_HIEN_ID: val }
                  : item
              ))}
            onClick={(e) => e.stopPropagation()}
          />
        ) : record.TEN_BAC_SI,
    },
    {
      title: i18n.t(languageKeys.mo_ta),
      key: "MO_TA",
      dataIndex: "MO_TA",
      width: 250,
      render: (data, record) => {
        if (record.LOAI_PHONG === "THU_THUAT") {
          return record?.MO_TA_CHI_TIET
        }
        if (data) {
          return data ? removeTags(data) : "";
        }
      },
      ellipsis: true,
    },

    {
      title: i18n.t(languageKeys.ket_luan),
      key: "KET_LUAN",
      dataIndex: "KET_LUAN",
      width: 250,
      ellipsis: true,
      render: (data) => (
        <Tooltip placement="topLeft" title={data ? removeTags(data) : ""} zIndex={1}>
          {data ? removeTags(data) : ""}
        </Tooltip>
      ),
    },

    {
      title: i18n.t(languageKeys.sl),
      key: "SO_LUONG",
      dataIndex: "SO_LUONG",
      ellipsis: true,
      // ...fitCenterCol,
      render: (SO_LUONG, record) => {
        if (record.children) {
          return (
            <Row wrap={false} align="middle" className={style["center"]}>
              <Col className={style["boldTxt"]}>{record?.children?.reduce((sum, item) => (sum += parseInt(item.SO_LUONG)), 0) || ""}</Col>
            </Row>
          );
        } else {
          return (
            <Row wrap={false} align="middle" className={style["center"]}>
              <Col>{SO_LUONG}</Col>
            </Row>
          );
        }
      },
      ...fitCenterCol,
      width: 50,
    },
    {
      title: i18n.t(languageKeys.field_don_gia),
      key: "GIA_DICH_VU",
      dataIndex: "GIA_DICH_VU",
      width: 120,
      render: (data) => (
        <span>
          {data ? (
            <div>
              {formatNumberToPrice(data)} {i18n.t(languageKeys.currency_viet_nam)}
            </div>
          ) : (
            <div>{data} {i18n.t(languageKeys.currency_viet_nam)}</div>
          )}
        </span>
      ),
    },
    {
      title: i18n.t(languageKeys.vien_phi_thanh_tien),
      key: "TONG_THANH_TOAN",
      dataIndex: "TONG_THANH_TOAN",
      width: 120,
      render: (data, record) => {
        if (record.TEN_KHOA_PHONG === undefined) {
          return (
            <span className="green-txt">
              {data > 0 ? formatNumberToPrice(data) : formatNumberToPrice(record.GIA_DICH_VU * record.SO_LUONG)}
              {i18n.t(languageKeys.currency_viet_nam)}
            </span>
          );
        } else {
          return (
            <span>
              {data > 0 ? formatNumberToPrice(data) : formatNumberToPrice(record.GIA_DICH_VU * record.SO_LUONG)}
              {i18n.t(languageKeys.currency_viet_nam)}
            </span>
          );
        }
      },
    },
    {
      title: i18n.t(languageKeys.cate_doi_tuong),
      key: "TEN_DOI_TUONG",
      dataIndex: "TEN_DOI_TUONG",
      ellipsis: true,
      width: 130,
    },
    {
      title: i18n.t(languageKeys.noi_thuc_hien),
      key: "TEN_NOI_TH",
      dataIndex: "TEN_NOI_TH",
      align: "center",
      ellipsis: true,
      width: 175,
    },
    {
      title: i18n.t(languageKeys.ghi_chu),
      key: "GHI_CHU",
      dataIndex: "GHI_CHU",
      ellipsis: true,
      width: 250,
      render: (data) => <Tooltip placement="topLeft" title={data} zIndex={1}>{data}</Tooltip>
    },
    {
      title: i18n.t(languageKeys.status_Da_thanh_toan),
      key: "THANH_TOAN",
      dataIndex: "THANH_TOAN",
      align: "center",
      width: 70,
      render: (data, record) => {
        return (
          <div align="middle" className={style["center"]}>
            {data !== "CHUA_THANH_TOAN" && data !== 0 ? (
              <Tooltip title={i18n.t(languageKeys.status_Da_thanh_toan)}>
                <Checked />
              </Tooltip>
            ) : (
              <Tooltip title={i18n.t(languageKeys.status_Chua_thanh_toan)}>
                <UnCheck />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: i18n.t(languageKeys.dang_TH),
      key: "TRANG_THAI_THUC_HIEN",
      dataIndex: "TRANG_THAI_THUC_HIEN",
      align: "center",
      width: 90,
      render: (data, record) => {
        return (
          <div align="middle" className={style["center"]}>
            {data && data !== 0 && (data === "DANG_THUC_HIEN" || data === "DA_THUC_HIEN") ? (
              <Tooltip title={i18n.t(languageKeys.status)}>
                <Checked />
              </Tooltip>
            ) : (
              <Tooltip title={i18n.t(languageKeys.chua_thuc_hien)}>
                <UnCheck />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: i18n.t(languageKeys.da_KQ),
      key: "TRANG_THAI_KET_QUA",
      dataIndex: "TRANG_THAI_KET_QUA",
      align: "center",
      ellipsis: true,
      width: 90,
      render: (data, record) => {
        return (
          <div align="middle" className={style["center"]}>
            {data && data !== "CHO_KET_QUA" && data !== 0 ? (
              <Tooltip title={i18n.t(languageKeys.khambenh_du_kq)}>
                <Checked />
              </Tooltip>
            ) : (
              <Tooltip title={i18n.t(languageKeys.khambenh_cho_kq)}>
                <UnCheck />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "",
      key: "action_btn",
      dataIndex: "action_btn",
      fixed: "right",
      // width: isEdit && userIsAdminOrDoctor ? "110px" : "90px",
      width: 340,
      render: (_, record, index) => {
        let TRANG_THAI;
        const checkChoTH = record.TRANG_THAI_TH === trang_thai_th.CHO_THUC_HIEN;
        const checkDangTH = record.TRANG_THAI_TH === trang_thai_th.DANG_THUC_HIEN;
        if (checkChoTH) {
          TRANG_THAI = trang_thai_th.DANG_THUC_HIEN;
        }
        if (checkDangTH) {
          TRANG_THAI = trang_thai_th.CHO_THUC_HIEN;
        }
        // check 
        let checkPermissionsHuy = (record) => {
          if (record.LOAI_PHONG === "THU_THUAT") {
            return checkPTTTPermissions.HUY
          } else if (record.LOAI_PHONG === "CDHA" || record.LOAI_PHONG === "TDCN") {
            return checkCDHAPermission
          }
          return false
        }
        return (
          <div >
            <ActionRows
              text_xem_th={getTextThCLS(record?.TRANG_THAI_THUC_HIEN || "")}
              showButtonDetail={checkShowButtonDetail(record)}
              editDisabled={!configPermissions[permissionActionKey.SUA] || checkDisabledEdit(record) /*|| !record?.CHO_PHEP_CAP_NHAT*/}
              deleteDisabled={
                !configPermissions[permissionActionKey.XOA] || checkDisabledDelete(record)
              }
              printDisabled={!configPermissions[permissionActionKey.IN_PDF]}
              detailDisabled={checkDisabledDetail(record)}
              tooltipEdit={
                configPermissions[permissionActionKey.SUA]
                  ? i18n.t(languageKeys.tip_sua_ban_ghi)
                  : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)
              }
              tooltipDel={
                configPermissions[permissionActionKey.XOA]
                  ? i18n.t(languageKeys.tip_xoa_ban_ghi)
                  : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)
              }
              // showEdit={!(record.is_combo)}
              showEdit={false}
              onEdit={() => configPermissions[permissionActionKey.SUA] && onClickRow(record)}
              onDelete={() => {
                if (configPermissions[permissionActionKey.XOA] && !(record.is_combo)) {
                  // if (!maCchnExist) {
                  //   return notification.error({
                  //     placement: "bottomLeft",
                  //     message: i18n.t(languageKeys.bac_si_chua_co_ma_cchn),
                  //   });
                  // }
                  setSelectedRecord(record);
                  deleteRef.current.open();
                }
              }}
              showDelete={(configPermissions[permissionActionKey.XOA] && !(record.is_combo))}
              onPrint={() => onPrint(record)}
              onShowDetail={() => {
                if (record.LOAI_PHONG === "THU_THUAT") {
                  handleInTuongTrinh(record)
                  return
                }
                if (record.LOAI_PHONG === "XET_NGHIEM" || record.PHAN_LOAI === "XET_NGHIEM") {
                  refKetQuaXN.current.onpenModal(record, patientInfo);
                } else {
                  setSelectedRecord({ ...record, TRANG_THAI_KQ: record.TRANG_THAI_KET_QUA });
                  daTraRef.current.open({ ...record, TRANG_THAI_KQ: record.TRANG_THAI_KET_QUA, PHIEU_TRA_ID: record.CHITIET_PHIEU_ID });
                }
              }}
              showPerformCDHA={(!record?.children || record?.children?.length === 0) && conditionCdhaRoom(record) && record.TRANG_THAI_THUC_HIEN !== "DA_THUC_HIEN"}
              disableShowPerformCDHA={checkDisableShowPerformCDHA(record, userProfile?.config)}
              onShowCDHA={() => {
                dispatch(setOpenFastRis({ record, patient: patientInfo, ticket: dsDvTheoLuotKham.find((c) => c.parentKey === record.parentKey) }));
              }}
              checkCDHAPermission={checkCDHAPermission}
              loadingThucHien={groupLoading?.loadingThucHien === record.key}
              // showBtnResult={record.TRANG_THAI_THUC_HIEN !== "CHO_THUC_HIEN" && record.TRANG_THAI_KET_QUA === "DA_TRA_KET_QUA"}
              showBtnResult={(record.TRANG_THAI_THUC_HIEN !== "CHO_THUC_HIEN" && record.TRANG_THAI_KET_QUA === "DA_TRA_KET_QUA") && (record.LOAI_PHONG === "CDHA" || record.LOAI_PHONG === "TDCN" || record.LOAI_PHONG === "XET_NGHIEM" || record.LOAI_PHONG === "THU_THUAT")}

              //Phẫu thuật thủ thuật
              showPerformPTTT={(!record?.children || record?.children?.length === 0) && conditionPTTTRoom(record) && record.TRANG_THAI_THUC_HIEN !== "DA_THUC_HIEN"}
              checkPTTTPermissionsTH={checkPTTTPermissionsTH(record, userProfile?.config)}

              checkPermissionsHuy={checkPermissionsHuy(record)}
              text_xem_kq={record.LOAI_PHONG === "THU_THUAT" ? i18n.t(languageKeys.xem_tuong_trinh) : undefined}
              onShowPTTT={() => handleChuyenTrangThaiDichVu(record, patientInfo, dsDvTheoLuotKham.find((c) => c.parentKey === record.parentKey, TRANG_THAI))}

              onHuyTra={() => {
                handleHuyTra(record)
              }}
            />
          </div>

        );
      },
    },
  ];

  let checkPTTTPermissionsTH = (record, config) => {

    if (checkPTTTPermissions.THUC_HIEN && (record.LOAI_PHONG === "THU_THUAT")) {
      if (!!record?.LICH_KHAM_ID_DAT_TU_CSKCB) {
        return true;
      }
      if (config?.THANH_TOAN_TRUOC_KHAM && record?.THANH_TOAN) {

        return true;
      }
      return false;
    }

    return false

  }

  const checkDisableShowPerformCDHA = (record, config) => {
    if (!!record?.LICH_KHAM_ID_DAT_TU_CSKCB) {
      return true;
    }
    if (config?.THANH_TOAN_TRUOC_KHAM && !record?.THANH_TOAN) {
      return true;
    }
    return false;
  };

  const handleAsk = () => {
    try {
      if (!patientInfo) return;
      // let check_arr = ["CHAN_DOAN_LAM_SANG", "HAM_DUOI_SO_BO", "HAM_TREN_SO_BO"];
      // if (check_arr.every((i) => !thongTinKhamBenh?.[i])) {
      //   notification.error({ message: i18n.t(languageKeys.vui_long_nhap_chan_doan_so_bo), placement: "bottomLeft" });
      //   return;
      // }
      let message = suggestQuestionService(
        {
          TEN: patientInfo?.TEN,
          NGAY_SINH:
            patientInfo?.NGAY_SINH && patientInfo?.NGAY_SINH?.length > 4 ? moment(patientInfo?.NGAY_SINH, "YYYYMMDD").format("DDMMYYYY") : null,
          GIOI_TINH: patientInfo?.GIOI_TINH,
        },
        {
          CHAN_DOAN_LAM_SANG: thongTinKhamBenh?.CHAN_DOAN_LAM_SANG,
          HAM_DUOI_SO_BO: thongTinKhamBenh?.HAM_DUOI_SO_BO,
          HAM_TREN_SO_BO: thongTinKhamBenh?.HAM_TREN_SO_BO,
        }
      );
      makeAsk(message);
    } catch (error) {
      console.log(error);
    }
  };


  const handleGetPackageWithPatientId = async (BENH_NHAN_ID) => {
    try {
      if (!BENH_NHAN_ID) throw new Error(i18n.t(languageKeys.vui_long_chon_benh_nhan));
      let body = {
        BENH_NHAN_ID,
        "exclude_phong": 1,
        ARR_TRANG_THAI: ['ALL']
      };

      let res = await common_post(apis.layDsGoiDvBenhNhan, body);
      if (res && res.status === 'OK') {
        dispatch(PackageSliceAction.setPatientPackageList({
          BENH_NHAN_ID: body.BENH_NHAN_ID,
          dataSource: res.result,
          patient: patientInfo
        }))
      } else {
        throw new Error(res);
      }
      return res;
    } catch (error) {
      notification.error({ message: error.message, placement: 'bottomLeft' });
    }
  }


  const onOpenRegisterPackage = async () => {
    try {
      handleGetPackageWithPatientId(patientInfo?.BENH_NHAN_ID).then(rs => {
        if (rs && rs.status === 'OK') {
          addPackRef.current.open({ LICH_KHAM_ID: patientInfo?.LICH_KHAM_ID });
        } else {
          throw new Error();
        }
      }).catch(err => console.log(err.message));
    } catch (error) {
      HLog(error);
    }
  }

  const render_sum = useMemo(() => {
    let SL = 0
    let TT = 0
    if (dsDvTheoLuotKham && dsDvTheoLuotKham?.length > 0) {
      for (let index = 0; index < dsDvTheoLuotKham.length; index++) {
        const element = dsDvTheoLuotKham[index];
        const thanhToan = element?.TONG_THANH_TOAN && element?.TONG_THANH_TOAN > 0 ?
          parseInt(element?.TONG_THANH_TOAN) :
          parseInt(element.GIA_DICH_VU) * parseInt(element.SO_LUONG);

        TT += thanhToan;
        if (element?.children?.length > 0) {
          SL = SL + element?.children?.reduce((sum, item) => (sum += parseInt(item.SO_LUONG)), 0)
        } else {
          SL = SL + parseInt(element?.SO_LUONG)
        }
      }
    }
    return (
      dsDvTheoLuotKham?.length > 0 && <TableAnt.Summary.Row>
        <TableAnt.Summary.Cell index={0} colSpan={7} align="center">
          {i18n.t(languageKeys.tong)}
        </TableAnt.Summary.Cell>
        <TableAnt.Summary.Cell index={3} align="center">
          <span type="danger">{SL}</span>
        </TableAnt.Summary.Cell>
        <TableAnt.Summary.Cell index={4} align="left">
        </TableAnt.Summary.Cell>
        <TableAnt.Summary.Cell index={5} align="left">
          <span>{formatNumberToPrice(TT)}{i18n.t(languageKeys.currency_viet_nam)}</span>
        </TableAnt.Summary.Cell>
        <TableAnt.Summary.Cell index={6} colSpan={7} fixed></TableAnt.Summary.Cell>
      </TableAnt.Summary.Row>
    );
  }, [dsDvTheoLuotKham]);


  return (
    <div className={style["Ds-wrapper"]}>
      {!isViewHistory && (
        <Topbar
          // title={i18n.t(languageKeys.Ds_dich_vu_cls)}
          title={i18n.t(languageKeys.danh_sach_dich_vu)}
          // onAdd={handleThemCLS}
          style={{ justifyContent: "space-between" }}
          totalNum={dsDvTheoLuotKham.length}
          // addBtnText={i18n.t(languageKeys.Chi_dinh_CLS)}
          // disabledAddBtn={!configPermissions[permissionActionKey.THEM_MOI] || !patientInfo}
          // onReload={handleReload}
          // disabledReloadBtn={!userIsAdminOrDoctor || !patientInfo}
          addOnActions={
            <Row gutter={[10, 10]} className={style["topbar-btn-row"]}>
              <Col className={style["topbar-btn"]}>
                <ActionButton
                  showPrint={true}
                  showDelete={true}
                  disabledPrint={!configPermissions[permissionActionKey.IN_PDF] || (selectedRows.length > 0 ? false : true)}
                  deleteTip={i18n.t(languageKeys.tip_xoa_ban_ghi)}
                  printTip={i18n.t(languageKeys.tip_in_ban_ghi)}
                  showEdit={false}
                  onPrint={inNhieuDIchVu}
                  tooltipInTheoQuyen={configPermissions[permissionActionKey.IN_PDF]}
                  tooltipXoaTheoQuyen={configPermissions[permissionActionKey.XOA]}
                  // deleteDisabled={!configPermissions[permissionActionKey.XOA] || (selectedRows.length > 0 ? false : true)}
                  // onDelete={xoaNhieuDichVu}
                  onDelete={() => deleteRef.current.open({ xoaNhieu: true })}
                  deleteDisabled={handleCheckDisableXoaNhieuDv}
                />
              </Col>

              <Col className={style["topbar-btn"]}>
                <Button
                  className={style["btn"]}
                  icon={<ReloadV2 />}
                  type="primary"
                  disabled={!userIsAdminOrDoctor || !patientInfo}
                  onClick={handleReload}
                >
                  {i18n.t(languageKeys.common_tai_lai)}
                </Button>
              </Col>

              <Col className={style["topbar-btn"]}>
                <Tooltip
                  title={
                    !configPermissions[permissionActionKey.THEM_MOI]
                      ? i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)
                      : i18n.t(languageKeys.chi_dinh_dich_vu)
                  }
                >
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    disabled={!configPermissions[permissionActionKey.THEM_MOI] || !patientInfo}
                    onClick={handleThemCLS}
                  >
                    {i18n.t(languageKeys.chi_dinh_dich_vu)}
                  </Button>
                </Tooltip>
              </Col>

              <Col className={style["topbar-btn"]}>
                <Tooltip title={i18n.t(languageKeys.dang_ky_goi_lieu_trinh)}>
                  <Button icon={<PlusOutlined />} type="primary" disabled={!patientInfo} onClick={() => { onOpenRegisterPackage() }}>
                    {i18n.t(languageKeys.dang_ky_goi_lieu_trinh)}
                  </Button>
                </Tooltip>
              </Col>

              <Col className={style["topbar-btn"]} hidden={!useAi}>
                <Tooltip placement="topLeft" title={i18n.t(languageKeys.tip_chat_ai)}>
                  <Button
                    icon={<Icon component={Robot} className={style["svg-fill-white"]} style={{ fontSize: 20 }} />}
                    type="primary"
                    // ghost
                    disabled={!patientInfo}
                    onClick={() => handleAsk()}
                  >
                    {i18n.t(languageKeys.tro_ly_ai)}
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          }
        />
      )}

      <ConfirmModal
        ref={confirmRef}
        content={i18n.t(languageKeys.khambenh_confirm_xoa)}
        danger={true}
        onOk={apiXoaDsDvCLS}
        onCancel={() => {
          confirmRef.current.close();
        }}
      />
      <ChatAi key={"dsDICHVUCLS"} />
      <div>
        <Table
          rowKey={(record) => record.key}
          className={style["table-container"]}
          style={isViewHistory ? { padding: 10, border: "1px solid #ebebeb", borderRadius: 8, marginTop: 20 } : { padding: 15 }}
          dataSource={dsDvTheoLuotKham}
          columns={columns}
          size="small"
          selectedRowKeys={selectedRowKeys}
          // onSelectRows={(rows) => setSelectedRowKeys(rows)}
          loading={loading}
          selectedRows={selectedRows}
          rowSelection={rowSelection}
          // expandIcon={<CaretRightOutlined />}
          onClickRow={onClickRow}
          defaultExpandAllRows={true}
          indentSize={40}
          scroll={{ x: dsDvTheoLuotKham.length > 0 && 1000, y: "calc(100vh - 280px)" }}
          expandable={{
            // rowExpandable: (record) => {console.log(record),
            expandedRowKeys: expandedRowKeys,
            expandIcon: ({ expanded, onExpand, record }) =>
              !!record.children &&
              (expanded ? (
                <CaretDownOutlined
                  style={{ marginRight: "8px" }}
                  onClick={(e) => {
                    let array = [...expandedRowKeys].filter((value) => value != record.key);
                    setExpandedRowKeys(array);

                    e.stopPropagation();
                  }}
                />
              ) : (
                <CaretRightOutlined
                  style={{ marginRight: "8px" }}
                  onClick={(e) => {
                    let array = [];
                    expandedRowKeys?.length > 0 ? (array = [...expandedRowKeys, record.key]) : (array = [record.key]);
                    setExpandedRowKeys(array);
                    e.stopPropagation();
                  }}
                />
              ))
          }}
          summary={() => (
            <TableAnt.Summary fixed>
              {render_sum}
            </TableAnt.Summary>
          )}
        />
      </div>

      <ChiDinhCLS
        ref={ref}
        patientInfo={patientInfo}
        onSuccess={handleSuccess}
        onPrint={inNhieuDIchVu}
        xoaPhieu={(item) => {
          apiXoaDsDvCLS(item);
        }}
        isEdit={isEdit}
        trang_thai={trang_thai}
        onChuyenSidebarTabSangDangKham={onChuyenSidebarTabSangDangKham}
        phongChiDinh={phongChiDinh}
        disabledPrint={!configPermissions[permissionActionKey.IN_PDF]}
        disabledDelete={!configPermissions[permissionActionKey.XOA]}
      />

      <ConfirmModal
        ref={deleteRef}
        danger={true}
        title={i18n.t(languageKeys.common_Xoa)}
        content={i18n.t(languageKeys.cf_Ban_co_chac_chan_xoa)}
        onCancel={() => setSelectedRecord({})}
        onOk={(value) => {
          if (value) {
            xoaNhieuDichVu()
          } else {
            return apiXoaDsDvCLSChildren(selectedRecord, () => setSelectedRecord({}));
          }
        }}
      />

      <ModalPDF ref={pdfRef} configCheck="NHIEU_PHIEU_CHIDINH_CLS" chiDinhCls />

      <DvDaTraKQ
        patientInfo={patientInfo}
        ticket={{ ...selectedRecord, PHONG_CHI_DINH: phongChiDinh.TEN_KHOA_PHONG }}
        ref={daTraRef}
        footerAction={false}
      // disabledPrint={!configPermissions[permissionActionKey.IN_PDF]}
      />

      <ModalAddPackages ref={addPackRef} />

      <KetQuaXN ref={refKetQuaXN} />

      <ModalPDF ref={tuongTrinhRef} />

    </div>
  );
};

export default DsDichVuCLS;
