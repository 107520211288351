import { Form, Layout } from "antd";
import SiderTamUng from "./SiderTamUng";
import { Topbar } from "components";
import style from "./qltu.module.less";
import i18n, { languageKeys } from "i18n";
import TableTamUng from "./TableTamUng";
import FormTamUng from "./Form/FormTamUng";
import { useRef, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDsPhTamUng } from "ducks/slices/QlyVienPhi/QlyTamUng.slice";
// import { resetTitle, updateTitle } from "ducks/slices/titlePageSlice";
import { handleCheckPermissions } from "helpers";
import { featureKeys } from "constants/keys";

const QuanLyTamUng = () => {

    const [form] = Form.useForm()
    const formRef = useRef()
    const dispatch = useDispatch()
    const userProfile = useSelector((state) => state.auth.user);

    const checkPermissions = useMemo(() => {
        return handleCheckPermissions(userProfile, featureKeys.quan_ly_tam_ung);
    }, [userProfile]);

    useEffect(() => {
        handleFetch()
        // dispatch(updateTitle(i18n.t(languageKeys.quan_ly_tam_hoan_ung)));
        return () => {
            // dispatch(resetTitle());
        };
    }, [])

    const handleFetch = () => {
        const filters = {
            TU_NGAY: form.getFieldValue('TIME')[0].format('YYYYMMDD'),
            DEN_NGAY: form.getFieldValue('TIME')[1].format('YYYYMMDD'),
            ARR_NGUOI_TAO: form.getFieldValue('ARR_NGUOI_TAO') || [],
            search_string: form.getFieldValue('search_string') || "",
            ARR_PHAN_LOAI: ['TAM_UNG_BENH_NHAN', 'TAT_TOAN_GOI'],
        }
        dispatch(getDsPhTamUng({
            filters,
            page: 1,
            limit: 15
        }));
    }

    const handleReset = () => {
        form.resetFields()
        handleFetch()
    }

    return (
        <Layout>
            <SiderTamUng form={form} handleFetch={handleFetch} handleReset={handleReset} />
            <Layout.Content>
                <Layout style={{ height: "100%" }}>
                    <Topbar
                        className={style["topbar"]}
                        onAdd={() => formRef.current?.open()}
                        addBtnText={i18n.t(languageKeys.vien_phi_Tao) + ' ' + i18n.t(languageKeys.phieu_thu_tam_ung)}
                        disabledAddBtn={!checkPermissions.THEM_MOI}
                    />
                    <TableTamUng formTURef={formRef} checkPermissions={checkPermissions} />
                </Layout>
            </Layout.Content>
            <FormTamUng ref={formRef} checkPermissions={checkPermissions} />
        </Layout>
    )
}

export default QuanLyTamUng