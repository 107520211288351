import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apis } from "../../../constants";
import { common_post, HLog } from "../../../helpers";
import { doFetchListSample, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { selectLoadingState } from "ducks/slices/loadingSlice";

/**
 * Custom hook Danh sách mẫu mô tả
 */
export const useListSample = () => {

  const dispatch = useDispatch()
  const { listSample } = useSelector(getSharedData);
  const loadingListSample = useSelector(selectLoadingState);

  useEffect(() => {
    dispatch(doFetchListSample())
  }, []);

  const getListSample = () => dispatch(doFetchListSample())
  // /**
  //  * Lấy danh sách mẫu mô tả
  //  * @param {string} search_string
  //  * @param {object} body_props
  //  */
  // const getListSample = async (search_string, body_props = {}) => {
  //   try {
  //     const url = apis.lay_ds_mau_mo_ta_cdha;
  //     const body = {
  //       search_string,
  //       BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
  //       partner_code: userProfile.partner_code,
  //       ...body_props,
  //     };

  //     setLoadingListSample(true);

  //     const res = await common_post(url, body);

  //     if (res.status === "OK") {
  //       setListSample(res.result);
  //     }
  //   } catch (error) {
  //     HLog("[ERROR] get list sample failed");
  //   } finally {
  //     setLoadingListSample(false);
  //   }
  // };

  return { listSample, loadingListSample, getListSample };
};

/**
 * Custom hook Lưu mẫu mô tả (Tạo mới / Chỉnh sửa)
 */
export const useSaveSample = () => {
  const userProfile = useSelector((state) => state.auth.user);
  const [loadingSave, setLoadingSave] = useState(false);

  const commonReq = {
    BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    partner_code: userProfile.partner_code,
  };

  /**
   * Xử lý thêm mới mẫu mô tả
   * @param {object} values
   */
  const createSample = async (values) => {
    let res;

    try {
      HLog("[INFO] create sample", values);

      const req = { ...commonReq, ...values };
      const url = apis.them_moi_mau_mo_ta_cdha;

      setLoadingSave(true);

      res = await common_post(url, req);
      return res
    } catch (error) {
      throw Error(error.message);
    } finally {
      setLoadingSave(false);
    }

    if (res.status !== "OK") {
      throw Error("Create sample failed");
    }
  };

  /**
   * Xử lý cập nhật mẫu mô tả
   * @param {object} values
   */
  const updateSample = async (values) => {
    let res;

    try {
      HLog("[INFO] Update sample", values);

      const req = { ...commonReq, ...values };
      const url = apis.cap_nhat_mau_mo_ta_cdha;

      setLoadingSave(true);

      res = await common_post(url, req);
    } catch (error) {
      throw Error(error.message);
    } finally {
      setLoadingSave(false);
    }

    if (res.status !== "OK") {
      throw Error("Update sample failed");
    }
  };

  /**
   * Xử lý cập nhật trạng thái khoá mẫu mô tả
   * @param {Object} values
   */
  const updateStatusSample = async (values) => {
    try {
      HLog("[INFO] Update sample", values);

      const req = { ...commonReq, ...values };
      const url = apis.khoa_nhieu_mau_mo_ta;

      setLoadingSave(true);

      const res = await common_post(url, req);

      if (res.status !== "OK") {
        throw Error("Update sample failed");
      }
    } catch (error) {
      throw Error(error.message);
    } finally {
      setLoadingSave(false);
    }
  };

  /**
   * Xử lý xoá mẫu mô tả
   * @param {Array<String>} values
   */
  const deleteSample = async (values) => {
    try {
      HLog("[INFO] Update sample", values);

      const req = { ...commonReq, NHANSU_ID: userProfile.NHANSU_ID, arr_ID: values };
      const url = apis.xoa_nhieu_mau_mo_ta;

      setLoadingSave(true);

      const res = await common_post(url, req);

      if (res.status !== "OK") {
        throw Error("Update sample failed");
      }
    } catch (error) {
      throw Error(error.message);
    } finally {
      setLoadingSave(false);
    }
  };

  return {
    loadingSave,
    createSample,
    updateSample,
    updateStatusSample,
    deleteSample
  };
};

/**
 * Custom hook lấy danh sách chọn Nhóm, Loại và Dịch vụ có hiệu lực (không bị KHÓA)
 */
export const useDichVuCoHieuLuc = () => {
  const userProfile = useSelector((state) => state.auth.user);
  const [loadingListNhomDV, setLoadingListNhomDV] = useState(false);
  const [loadingListLoaiDV, setLoadingListLoaiDV] = useState(false);
  const [loadingListDV, setLoadingListDV] = useState(false);
  const [listNhomDV, setListNhomDV] = useState([]);
  const [listLoaiDV, setListLoaiDV] = useState([]);
  const [listDV, setListDV] = useState([]);

  const commonReq = {
    BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    partner_code: userProfile.partner_code,
    page: 1,
    limit: 1000000,
  };

  /**
   * Lấy danh sách Nhóm DV có hiệu lực
   * @param {string} search_string
   * @param {object} body_props
   */
  const getListNhomDV = async (search_string = "", body_props = {}) => {
    try {
      const req = {
        search_string,
        ...commonReq,
        ...body_props,
        MA_NHOM_BHYT: ["CDHA", "TDCN"]
      };
      const url = apis.dm_nhom_dvkt_lay_ds_co_hieu_luc;

      setLoadingListNhomDV(true);

      const res = await common_post(url, req);

      if (res.status === "OK") {
        setListNhomDV(res.result);
      }
    } catch (error) {
      HLog("[ERROR] get list nhom dv", error);
    } finally {
      setLoadingListNhomDV(false);
    }
  };

  /**
   * Lấy danh sách Loại DV có hiệu lực
   * @param {string} search_string
   * @param {object} body_props
   */
  const getListLoaiDV = async (search_string = "", body_props = { NHOM_DV_ID: "" }) => {
    try {
      const req = {
        search_string,
        ...commonReq,
        ...body_props,
      };
      const url = apis.dm_loai_dvkt_lay_ds_co_hieu_luc;

      setLoadingListLoaiDV(true);

      const res = await common_post(url, req);

      if (res.status === "OK") {
        setListLoaiDV(res.result);
      }
    } catch (error) {
      HLog("[ERROR] get list loai dv", error);
    } finally {
      setLoadingListLoaiDV(false);
    }
  };

  /**
   * Lấy danh sách DV có hiệu lực
   * @param {string} search_string
   * @param {object} body_props
   */
  const getListDV = async (search_string = "", body_props = { LOAI_DV_ID: "" }) => {
    try {
      const req = {
        search_string,
        ...commonReq,
        ...body_props,
      };
      const url = apis.ris_lay_ds_dich_vu;

      setLoadingListDV(true);

      const res = await common_post(url, req);

      if (res.status === "OK") {
        setListDV(res.result);
      }
    } catch (error) {
      HLog("[ERROR] get list dv", error);
    } finally {
      setLoadingListDV(false);
    }
  };

  return {
    loadingListNhomDV,
    loadingListLoaiDV,
    loadingListDV,
    listNhomDV,
    listLoaiDV,
    listDV,
    setListNhomDV,
    setListLoaiDV,
    setListDV,
    getListNhomDV,
    getListLoaiDV,
    getListDV,
  };
};
