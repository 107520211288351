import { Col, Layout, Row, Input, Spin, Typography, Button, List, Divider } from "antd";
import React, { useState, useEffect, useRef, useImperativeHandle, memo, useMemo } from "react";
import cn from "classnames";
import style from "./sider.module.less";
import { SearchOutlined } from "@ant-design/icons";
import i18n, { languageKeys } from "../../../../i18n";
import { useSelector, useDispatch } from "react-redux";
import {
  convertDateToValue,
  convertMillisecondToAge,
  convertValueToMilisecond,
  getSexFromString,
  HLog,
  isEmptyObject,
} from "../../../../helpers";
import { layDsBenhNhan, luuTamHoanUng } from "../apisDvThanhToan/apisSiderBar";
import moment from "moment";
import Constants from "../../../../constants/Constants";
import { resetEvent } from "../../../../ducks/slices/eventSlice";
import keys from "../../../../constants/keys";
import classNames from "classnames";
import { Barcode_v2 } from "../../../../assets/svg";
import { useEventListener } from "../../../../hooks";
import { keyViews } from "../DichVuCanThanhToan";
import { ComplexDatePicker } from "components";
import { uniq } from "lodash";
import { paths } from "../../../../constants";

export const TRANG_THAI_THANH_TOAN = {
  CHUA_THANH_TOAN: "CHO_THANH_TOAN",
  DA_THANH_TOAN: "DA_THANH_TOAN",
};

const { Text } = Typography;

const SiderBar = React.forwardRef((props, ref) => {

  const userProfile = useSelector((state) => state.auth.user);
  const dataEvent = useSelector((state) => state.eventSlice.event);

  const searchRef = useRef();
  // const actionModal = useRef();

  // const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [dsBenhNhan, setDsBenhNhan] = useState([]); // danh sách bệnh nhân thanh toán
  const [selectedPatient, setSelectedPatient] = useState(); // bệnh nhân đã chọn
  // const [filterDate, setFilterDate] = useState(moment()); //
  const [filterStartDate, setFilterStartDate] = useState(moment()); //fiter ngày bắt đầu
  const [filterEndDate, setFilterEndDate] = useState(moment()); //filter ngày kết thúc
  const { filterType, setFilterType } = props;
  const [txtSearch, setTxtSearch] = useState(""); //
  // const [typeKham, setTypeKham] = useState("");
  const [dataRequest, setDataRequest] = useState({}); //lưu dữ liệu request lấy ds bệnh nhân
  // const [currPage, setCurrPage] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  // const [updateListpatient, setUpdateListpatient] = useState([]);

  const dispatch = useDispatch();

  const query = useMemo(() => new URLSearchParams(window.location.search), [window.location.search])
  const patientCode = query.get("code")

  useImperativeHandle(ref, () => ({
    reloadList() {
      //setSelectedPatient();
      layDsChoThanhToan(dataRequest);
    },
    onSetFilterType: handleChangeFilterType,
    onChangeListPatient: handleChangeListPatient,
  }));

  const handleChangeFilterType = (status) => {
    // setDsBenhNhan([])

    setFilterType(status);
    handleFilterType(status);
    // if (txtSearch) {
    //   layDsChoThanhToan({
    //     search_string: txtSearch,
    //     LOAI: status,
    //     TU: "",
    //     DEN: "",
    //     // ...dataRequest,
    //   });
    // } else {
    //   handleFilterType(status);
    // }
  }

  const keyEvent = (event) => {
    if (event.key === "F4") {
      event.preventDefault();
      event.stopPropagation();
      searchRef.current && searchRef.current.focus();
    }
  };
  useEventListener("keydown", keyEvent, window.document, searchRef.current);

  useEffect(() => {
    layDsChoThanhToan({ search_string: patientCode ?? "" });
    if (searchRef.current) searchRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reloadAppointment(dataEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEvent]);

  //hàm xử lí reload cuộc hẹn ở màn thanh toán
  const reloadAppointment = (event) => {
    HLog("SIDERBAR reloadAppointment event: ", event);
    if (event && !isEmptyObject(event)) {
      let { type } = event;
      switch (type) {
        case Constants.EVENT_THANH_TOAN_LUOT_DIEU_TRI: //khi tiếp tân nhấn nút thanh toán
        case Constants.EVENT_TIEP_DON_LUOT_DIEU_TRI:
        case Constants.THEM_LUOT_DIEU_TRI:
        case Constants.CUOC_KHAM_DA_THANH_TOAN:
        case Constants.TIEP_DON_CUOC_KHAM:
        case Constants.CAP_NHAT_THANH_TOAN:
        case Constants.XOA_LUOT_DIEU_TRI:
        case Constants.EVENT_LUOT_DIEU_TRI_CHI_DINH_CLS:
          setTimeout(function () {
            layDsChoThanhToan(dataRequest);
            dispatch(resetEvent());
          }, 1000);
          break;
        default:
          break;
      }
    }
  };

  const handleUpdateListPatient = async () => {
    HLog("LOAD_MỎE", nextPage);
    try {
      // setLoading(true);
      if (!!nextPage) {
        const res = await layDsBenhNhan({
          ...dataRequest,
          page: nextPage,
        });
        if (res && res.status === "OK") {
          let { result, current_page, next_page } = res;
          // setUpdateListpatient(result);
          // setCurrPage(current_page);
          setNextPage(next_page);
          if (dsBenhNhan.length && (dataRequest?.selectFirst || !selectedPatient)) onSelectPatient(dsBenhNhan[0]);
          if (!dsBenhNhan.length) onSelectPatient()
          setDsBenhNhan((prev) => {
            return [...prev, ...result];
          });
        }
      }
      // setLoading(false);
    } catch (e) {
      HLog("ERRORR_LOADMOER", e);
    }
  };

  const layDsChoThanhToan = async (
    {
      search_string = txtSearch,
      LOAI = filterType, // trạng thái thanh toán
      TU = filterStartDate ? moment(filterStartDate).format("YYYYMMDD") : null,
      DEN = filterEndDate ? moment(filterEndDate).format("YYYYMMDD") : null,
      idUpdateTamUng = null,
    },
    request = {},
    selectFirst = false, //
  ) => {
    setInitLoading(true);

    let dataRequest = {
      partner_code: userProfile.partner_code,
      search_string,
      PHONG_ID: "",
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      TU,
      DEN,
      LOAI,
      limit: keys.limit,
      page: 1,
    };
    if (request && !isEmptyObject(request)) {
      dataRequest = request;
    }
    HLog("DANH SACH BENH NHAN", dataRequest);
    setDataRequest(dataRequest);
    const response = await layDsBenhNhan(dataRequest);
    if (response) {
      let dsBenhNhan = response.result;
      // let { result, current_page, count_page, next_page } = response;
      // setCurrPage(current_page);
      setNextPage(response.next_page);
      setDsBenhNhan(dsBenhNhan);
      if (patientCode) {
        onSelectPatient(dsBenhNhan.find(i => i.LOAI === "THANH_TOAN_MUA_GOI"))
      } else {
        if (dsBenhNhan.length && (selectFirst || !selectedPatient)) onSelectPatient(dsBenhNhan[0]);
        if (!dsBenhNhan.length) onSelectPatient()

        if (!!idUpdateTamUng) {
          // nếu có id tạm ứng thì check xem có phải bệnh nhân đang chọn không để update tạm ứng
          let itemUpdate = dsBenhNhan.find((item) => item.BENH_NHAN_ID === idUpdateTamUng);
          if (itemUpdate && selectedPatient && itemUpdate.BENH_NHAN_ID === selectedPatient.BENH_NHAN_ID) {
            props.onSelectPatient(itemUpdate);
          }
        }
      }

    }
    setInitLoading(false);
    window.history.replaceState(null, "", paths.quan_ly_dich_vu_can_thanh_toan)
  }

  const onSelectPatient = (item) => {
    if (item && selectedPatient && (selectedPatient.LOAI === "THANH_TOAN_MUA_GOI"
      ? (selectedPatient.ID === item.ID) : (selectedPatient.LICH_KHAM_ID === item.LICH_KHAM_ID))) {
      return;
    }
    setSelectedPatient(item);
    props.onSelectPatient(item);
  };

  // async function onSaveTamHoanUng(value) {
  //   let BENH_NHAN_ID = value.BENH_NHAN_ID;

  //   let dataRequest = {
  //     ...value,
  //     partner_code: userProfile.partner_code,
  //     BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
  //     NHANSU_ID: userProfile.NHANSU_ID,
  //     //LICH_KHAM_ID: selectedPatient.LICH_KHAM_ID,
  //   };
  //   //return
  //   const respone = await luuTamHoanUng(dataRequest);
  //   if (respone) {
  //     actionModal.current.closeModal();
  //     layDsChoThanhToan({
  //       search_string: txtSearch,
  //       date: moment(filterDate).format("YYYYMMDD"),
  //       type: "",
  //       idUpdateTamUng: BENH_NHAN_ID,
  //     });
  //   }
  // }

  // hàm xử lý search
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debounceSearch = useCallback(
  //   debounce((value, typeKham, TU, DEN) => {
  //     layDsChoThanhToan({
  //       search_string: value,
  //       TU: "",
  //       DEN: "",
  //       LOAI: filterType,
  //       type: typeKham,
  //     });
  //   }, 1000),
  //   []
  // );

  const onFilterByDate = (data, isReset) => {
    if (isReset) {
      setTxtSearch("")
      setFilterStartDate(data[0])
      setFilterEndDate(data[1])
    }
    // setFilterType(typeStatus);
    let TU = moment(data[0]).format("YYYYMMDD");
    let DEN = moment(data[1]).format("YYYYMMDD");
    // setFilterDate(moment);
    // setTypeKham(typeFilter);
    layDsChoThanhToan({
      search_string: isReset ? "" : txtSearch,
      TU,
      DEN,
      // LOAI: typeStatus,
      type: filterType,
    });
  }

  const handleFilterType = (status, selectFirst = false) => {
    layDsChoThanhToan(
      {
        search_string: txtSearch,
        LOAI: status, // trạng thái thanh toán
        TU: moment(filterStartDate).format("YYYYMMDD"),
        DEN: moment(filterEndDate).format("YYYYMMDD"),
        idUpdateTamUng: null,
      },
      {},
      selectFirst
    );
  };


  const loadMore = !!nextPage ? (
    <>
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
          width: "100%",
          marginBottom: 12,
          paddingBottom: 16,
        }}
      >
        <Button style={{ width: "100%", background: "#6576FF", color: "#fff" }} onClick={handleUpdateListPatient}>
          {i18n.t(languageKeys.btn_xem_them)}
        </Button>
      </div>
      <div style={{ width: "100%", height: 6 }}> </div>
    </>
  ) : null;

  /**
   * 
   * @param {String} type CHUA_THANH_TOAN || DA_THANH_TOAN
   */
  const handleChangeListPatient = (type) => {
    // setDsBenhNhan([])
    setFilterType(TRANG_THAI_THANH_TOAN?.[type]);
    // if (txtSearch) {
    //   layDsChoThanhToan({
    //     search_string: txtSearch,
    //     LOAI: TRANG_THAI_THANH_TOAN?.[type],
    //     TU: "",
    //     DEN: "",
    //     // ...dataRequest,
    //   });
    // } else {
    //   handleFilterType(TRANG_THAI_THANH_TOAN?.[type], true);
    // }
    handleFilterType(TRANG_THAI_THANH_TOAN?.[type], true);
  };

  const onChangeListPatient = (type) => {
    props?.onSetTab(keyViews?.[type]);
    handleChangeListPatient(type);
  }

  return (
    <Layout.Sider className={style["container"]} width={240}>
      <div className={style["wrapper"]}>
        <Row wrap={false} gutter={5} className={style["input-search"]}>
          <Col>
            <Input
              value={txtSearch}
              allowClear
              prefix={<SearchOutlined style={{ color: "#6576FF" }} />}
              onChange={(e) => {
                let txt = e.target.value;

                setTxtSearch(txt);

                if (!txt && (e.target.value?.length >= keys.limit_min_length || e.target.value.length === 0)) {
                  layDsChoThanhToan({
                    search_string: e.target.value,
                    LOAI: filterType,
                  });
                }
              }}
              onPressEnter={(e) => {
                if (e.target.value?.length >= keys.limit_min_length || e.target.value.length === 0) {
                  layDsChoThanhToan({
                    search_string: e.target.value,
                    LOAI: filterType,
                  });
                }
              }}
              placeholder={i18n.t(languageKeys.common_Tim_kiem) + " (F4)"}
              ref={searchRef}
            />
          </Col>

          <Col>
            <Button type="ghost" icon={<Barcode_v2 />}></Button>
          </Col>
        </Row>
        <Divider style={{ margin: 0 }} />
        <Row style={{ margin: "6px 8px" }} wrap={false}>
          <ComplexDatePicker
            // ref={refTime}
            isShowAddition={true}
            showBtn={true}
            onApply={onFilterByDate}
            onReset={(data) => onFilterByDate(data, true)}
            onChange={(data) => {
              setFilterStartDate(data[0]);
              setFilterEndDate(data[1]);
            }}
          />
        </Row>
      </div>

      <div className={cn(style["wrapper"], style["padding-list"])}>
        <Row style={{ margin: "12px 0" }} justify="space-evenly">
          <Col>
            <Button
              onClick={() => onChangeListPatient("CHUA_THANH_TOAN")}
              type={filterType === TRANG_THAI_THANH_TOAN.CHUA_THANH_TOAN ? 'primary' : ''}
              className={classNames(style["filter-btn"], filterType === TRANG_THAI_THANH_TOAN.CHUA_THANH_TOAN && style["filter-btn-active"])}
              disabled={initLoading}
              block
            >
              {i18n.t(languageKeys.status_Chua_thanh_toan)}
            </Button>
          </Col>
          <Col>
            <Button
              disabled={initLoading}
              block
              type={filterType === TRANG_THAI_THANH_TOAN.DA_THANH_TOAN ? 'primary' : ''}
              onClick={() => onChangeListPatient("DA_THANH_TOAN")}
              className={classNames(style["filter-btn"], filterType === TRANG_THAI_THANH_TOAN.DA_THANH_TOAN && style["filter-btn-active"])}
            >
              {i18n.t(languageKeys.vienphi_da_thanh_toan)}
            </Button>
          </Col>
        </Row>
        <Spin spinning={initLoading}>
          <List
            className="demo-loadmore-list"
            loading={initLoading}
            itemLayout="horizontal"
            loadMore={loadMore}
            dataSource={uniq(dsBenhNhan)}
            renderItem={(item, index) => (
              <RenderPatient
                item={item}
                key={item.LICH_KHAM_ID || item.ID}
                onSelectPatient={onSelectPatient}
                selectedPatient={selectedPatient}
                filterType={filterType}
              />
            )}
          />
          <div style={{ height: 12 }}> </div>
        </Spin>
      </div>
      {/* <ModalAction ref={actionModal} onSaveTamHoanUng={onSaveTamHoanUng} /> */}
    </Layout.Sider>
  );
});

export default React.memo(SiderBar);

const RenderPatient = memo(({ item, selectedPatient, onSelectPatient = () => {}, index, filterDate, filterType }) => {
  return (
    <>
      <div
        key={item.LICH_KHAM_ID}
        className={cn(style["patient-card"],
          selectedPatient &&
          (selectedPatient.LOAI === "THANH_TOAN_MUA_GOI"
            ? (selectedPatient.ID === item.ID) : (selectedPatient.MA_CUOC_KHAM === item.MA_CUOC_KHAM)) &&
          style["active"]
        )}
        onClick={() => {
          onSelectPatient(item, filterDate);
        }}
      >
        <Row
          align="middle"
          justify="space-between"
          className={cn(style["inner"], style["head"])}
          wrap={false}
          style={{ minHeight: 38, margin: 0 }}
          gutter={5}
        >
          <Col className={style["name"]}>
            <div className={style["box-name"]}>
              <div
                className={cn(
                  style["dot-status"],
                  // item.TRANG_THAI_THANH_TOAN === "CHUA_THANH_TOAN"
                  //   ? style["red"]
                  //   : item.TRANG_THAI_THANH_TOAN === TRANG_THAI_THANH_TOAN.DA_THANH_TOAN
                  //     ? style["gray"]
                  //     : ""
                  filterType === TRANG_THAI_THANH_TOAN.CHUA_THANH_TOAN
                    ? style["red"]
                    : filterType === TRANG_THAI_THANH_TOAN.DA_THANH_TOAN
                      ? style["gray"]
                      : ""
                )}
              />
              <span style={{ flex: 1 }}>{item.TEN}</span>
            </div>
          </Col>
          <Col>
            <Text
              ellipsis={{
                tooltip: 11,
              }}
            >
              {convertMillisecondToAge(convertValueToMilisecond(item.NGAY_SINH))}
            </Text>
          </Col>
        </Row>

        <div className={cn(style["inner"], style["body"])}>
          <Row justify="space-between">
            <Col>
              <Text
                className={style["fw-500"]}
                s
                ellipsis={{
                  tooltip: getSexFromString(item.GIOI_TINH),
                }}
              >
                {i18n.t(languageKeys.field_Gioi_tinh)}:{" "}
              </Text>
              <div className={style["fw-700"]}>{getSexFromString(item.GIOI_TINH)}</div>
            </Col>

            <Col>
              <Text
                className={style["fw-500"]}
                ellipsis={{
                  tooltip: getSexFromString(item.GIOI_TINH),
                }}
              >
                {i18n.t(languageKeys.field_Ngay_kham)}:{" "}
              </Text>
              <div className={style["fw-700"]}>{convertDateToValue(item.NGAY)}</div>
            </Col>
          </Row>
        </div>

        <div className={cn(style["inner"], style["body"])}>
          <Row justify="space-between">
            <Col span={16}>
              <div>
                <Text className={style["fw-500"]}>
                  {i18n.t(languageKeys.field_Ma_benh_nhan)}:{"  "}
                </Text>
              </div>
              <div>
                <Text
                  ellipsis={{
                    tooltip: item.MA_BENH_NHAN,
                  }}
                  className={style["fw-700"]}
                >
                  {item.MA_BENH_NHAN}
                </Text>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <Text className={style["fw-500"]}>
                  {i18n.t(languageKeys.field_Ma_cuoc_kham)}:{"  "}
                </Text>
              </div>
              <div>
                <Text
                  ellipsis={{
                    tooltip: item.MA_CUOC_KHAM,
                  }}
                  className={style["fw-700"]}
                >
                  {item.MA_CUOC_KHAM}
                </Text>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
});

// const TRANG_THAI_KHAM = [
//   {
//     key: "CHO_KHAM",
//     name: i18n.t(languageKeys.Cho_kham),
//   },
//   {
//     key: "DANG_KHAM",
//     name: i18n.t(languageKeys.common_Dang_kham),
//   },
//   {
//     key: "KHAM_XONG",
//     name: i18n.t(languageKeys.common_Kham_xong),
//   },
// ];
