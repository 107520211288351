import { apis, keys } from "../../../../constants";
import { common_post, HLog, isJsonString } from "../../../../helpers";
import { fieldICD10, fieldXa, fieldTinh, fieldHuyen } from "../fieldsDanhMuc";

/* ===================================================================================================
 *                            API DANH MỤC TỈNH
 * ===================================================================================================
 */
export const apiLayDsTinh = async ({
  page = 1,
  search_string = "",
  limit = 10000,
}) => {
  let dataRequest = { page, limit, search_string };
  try {
    let response = await common_post(
      apis.dm_tinhthanh_lay_ds,
      dataRequest,
      false
    );
    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Lấy danh sách tỉnh lỗi ", error);
  }
};

export const apiNhapDsTinh = async ({ BENH_VIEN_ID = "", data, THAY_THE }) => {
  try {
    let response = await common_post(
      apis.dm_tinhthanh_nhap_ds,
      { THAY_THE, data: data },
      false
    );
    if (response) {
      return response;
    }
  } catch (error) {
    HLog("Lấy danh sách tỉnh lỗi ", error);
  }
};

export const apiThemTinh = async ({ data = {} }) => {
  try {
    let response = await common_post(apis.dm_tinhthanh_them, data, false);

    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Lấy danh sách tỉnh lỗi ", error);
  }
};

export const apiSuaTinh = async ({ data = {} }) => {
  let req = { ...data };

  try {
    let response = await common_post(apis.dm_tinhthanh_cap_nhat, req, false);

    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Lấy danh sách tỉnh lỗi ", error);
  }
};

/* ===================================================================================================
 *                            API DANH MỤC QUẬN HUYỆN
 * ===================================================================================================
 */

export const apiLayDsHuyen = async ({
  BENH_VIEN_ID = "",
  page = 1,
  search_string = "",
  limit = 10000,
  MA_TINH_THANH = "",
}) => {
  let dataRequest = { page, limit, search_string, MA_TINH_THANH };
  try {
    let response = await common_post(
      apis.dm_quanhuyen_lay_ds,
      dataRequest,
      false
    );
    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Lấy danh sách tỉnh lỗi ", error);
  }
};

export const apiNhapDsQuanHuyen = async ({
  BENH_VIEN_ID = "",
  data,
  THAY_THE,
}) => {
  try {
    let response = await common_post(
      apis.dm_quanhuyen_nhap_ds,
      { THAY_THE, data: data },
      false
    );
    if (response) {
      return response;
    }
  } catch (error) {
    HLog("Lấy danh sách tỉnh lỗi ", error);
  }
};

export const apiThemQuanHuyen = async ({ data = {} }) => {
  let TINH = JSON.parse(data.MA_TINH_THANH);

  let dataRequest = {
    ...data,
    MA_TINH_THANH: TINH.MA_TINH_THANH,
  };

  try {
    let response = await common_post(
      apis.dm_quanhuyen_them,
      dataRequest,
      false
    );

    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Lấy danh sách tỉnh lỗi ", error);
  }
};

export const apiSuaQuanHuyen = async ({ data }) => {
  if (isJsonString(data[fieldHuyen.ma_tinh])) {
    data[fieldHuyen.ma_tinh] = JSON.parse(data[fieldHuyen.ma_tinh])[
      fieldTinh.id_tinh
    ];
  }

  let dataRequest = {
    ...data,
  };

  try {
    let response = await common_post(
      apis.dm_quanhuyen_cap_nhat,
      dataRequest,
      false
    );

    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Lấy danh sách tỉnh lỗi ", error);
  }
};

/* ===================================================================================================
 *                            API DANH MỤC XÃ PHƯỜNG
 * ===================================================================================================
 */

export const apiLayDsXaPhuong = async ({
  BENH_VIEN_ID = "",
  page = 1,
  search_string = "",
  limit = 10000,
  MA_QUAN_HUYEN = "",
}) => {
  let dataRequest = { page, limit, search_string, MA_QUAN_HUYEN };
  try {
    let response = await common_post(
      apis.dm_xaphuong_lay_ds,
      dataRequest,
      false
    );
    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Lấy danh sách tỉnh lỗi ", error);
  }
};

export const apiNhapDsXaPhuong = async ({
  BENH_VIEN_ID = "",
  data,
  THAY_THE,
}) => {
  try {
    let response = await common_post(
      apis.dm_xaphuong_nhap_ds,
      { THAY_THE, data: data },
      false
    );
    if (response) {
      return response;
    }
  } catch (error) {
    HLog("Lấy danh sách tỉnh lỗi ", error);
  }
};

export const apiThemXaPhuong = async ({ data }) => {
  let tinh = JSON.parse(data.MA_TINH_THANH);
  let huyen = JSON.parse(data.MA_QUAN_HUYEN);

  let dataRequest = {
    ...data,
    MA_TINH_THANH: tinh.MA_TINH_THANH,
    MA_QUAN_HUYEN: huyen.MA_QUAN_HUYEN,
  };

  try {
    let response = await common_post(apis.dm_xaphuong_them, dataRequest, false);
    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Lấy danh sách tỉnh lỗi ", error);
  }
};

export const apiSuaXaPhuong = async ({ data }) => {
  if (isJsonString(data[fieldXa.ma_tinh])) {
    data[fieldXa.ma_tinh] = JSON.parse(data[fieldXa.ma_tinh])[
      fieldTinh.id_tinh
    ];
  }

  if (isJsonString(data[fieldXa.ma_huyen])) {
    data[fieldXa.ma_huyen] = JSON.parse(data[fieldXa.ma_huyen])[
      fieldHuyen.id_huyen
    ];
  }

  let dataRequest = {
    ...data,
  };

  try {
    let response = await common_post(
      apis.dm_xaphuong_cap_nhat,
      dataRequest,
      false
    );
    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Lấy danh sách tỉnh lỗi ", error);
  }
};

/* ===================================================================================================
 *                            API DANH MỤC NGHỀ NGHIỆP
 * ===================================================================================================
 */

export const apiLayDsNgheNghiep = async (payload) => {
  try {
    let response = await common_post(apis.dm_nghenghiep_lay_ds, {}, false);
    if (response && response.status === "OK") {
      let total = response.result.length;
      return { ...response, total: total };
    }
  } catch (error) {
    HLog(error);
  }
};

/* ===================================================================================================
 *                            API DANH MỤC DÂN TỘC
 * ===================================================================================================
 */

export const apiLayDsDanToc = async ({
  BENH_VIEN_ID = "",
  page = 1,
  search_string = "",
  limit = keys.limit,
}) => {
  let dataRequest = { page, limit, search_string };
  try {
    let response = await common_post(apis.dm_dantoc_lay_ds, dataRequest, false);
    if (response && response.status === "OK") {
      let result = response.result;
      return {
        ...response,
        total: result.length,
        total_page: 1,
        current_page: 1,
      };
    }
  } catch (error) {
    HLog("Lấy danh sách dân tộc lỗi ", error);
  }
};

/* ===================================================================================================
 *                            API DANH MỤC ICD
 * ===================================================================================================
 */

export const apiLayDsICD10 = async ({
  search_string = "",
  limit = keys.limit,
  page = 1,
}) => {
  let req = {
    ID_ICD: "",
    search_string: search_string,
    limit: limit,
    page: page,
  };

  try {
    let res = await common_post(apis.dm_ICD_lay_ds, req, false);

    if (res.status === "OK") {
      return res;
    }
  } catch (error) {
    HLog("Lấy danh sách ICD 10 lỗi");
  }
};

export const apiNhapDsICD10 = async ({ data = [] }) => {
  let req = { data };

  try {
    let response = await common_post(apis.dm_ICD_them, req, false);

    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Nhập ds ICD 10 lỗi");
  }
};

export const apiThemICD10 = async ({ data = {} }) => {
  try {
    if (isJsonString(data[fieldICD10.nhom_icd])) {
      const parseData = JSON.parse(data[fieldICD10.nhom_icd]);
      data[fieldICD10.nhom_icd] = parseData.ID_ICD;
    }

    data[fieldICD10.id_nhom_icd] = data[fieldICD10.nhom_icd];

    let req = { data: [data] };

    let res = await common_post(apis.dm_ICD_them, req, false);

    if (res.status === "OK") return true;
  } catch (error) {
    HLog("Thêm ICD 10 lỗi");
  }
};

export const apiSuaICD10 = async ({ data = {} }) => {
  if (isJsonString(data[fieldICD10.nhom_icd])) {
    const parseData = JSON.parse(data[fieldICD10.nhom_icd]);
    data[fieldICD10.id_nhom_icd] = parseData.ID_ICD;
  }

  let req = {
    ...data,
  };

  try {
    let res = await common_post(apis.dm_ICD_sua, req, false);

    if (res.status === "OK") return true;
  } catch (error) {
    HLog("Sửa ICD 10 lỗi");
  }
};

export const apiXoaICD10 = async ({ data = {} }) => {
  let req = {
    ...data,
    [fieldICD10.trang_thai]: 0,
  };

  try {
    let res = await common_post(apis.dm_ICD_sua, req, false);

    if (res.status === "OK") return true;
  } catch (error) {
    HLog("Xoá ICD 10 lỗi");
  }
};

/* ===================================================================================================
 *                            API DANH MỤC NHÓM ICD
 * ===================================================================================================
 */

export const apiLayDsNhomICD10 = async ({
  search_string,
  page,
  limit = keys.limit,
}) => {
  try {
    const req = { search_string, limit, page };

    let res = await common_post(apis.dm_ICD_lay_ds_nhom, req, false);

    if (res.status === "OK") {
      return res;
    }
  } catch (error) {
    HLog("Lấy danh sách nhóm ICD lỗi");
  }
};

export const apiNhapDsNhomICD10 = async ({ data = [] }) => {
  let req = { data };

  try {
    let response = await common_post(apis.dm_ICD_them_moi_nhom, req, false);

    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Nhập ds nhóm ICD 10 lỗi");
  }
};

export const apiThemNhomICD10 = async ({ data = {} }) => {
  try {
    let req = { data: [data] };

    let res = await common_post(apis.dm_ICD_them_moi_nhom, req, false);

    if (res.status === "OK") return true;
  } catch (error) {
    HLog("Thêm nhóm ICD 10 lỗi");
  }
};

export const apiSuaNhomICD10 = async ({ data = {} }) => {
  let req = {
    ...data,
  };

  try {
    let res = await common_post(apis.dm_ICD_sua_nhom, req, false);

    if (res.status === "OK") return true;
  } catch (error) {
    HLog("Sửa nhóm ICD 10 lỗi");
  }
};

export const apiXoaNhomICD10 = async ({ data = {} }) => {
  let req = {
    ...data,
    [fieldICD10.trang_thai]: 0,
  };

  try {
    let res = await common_post(apis.dm_ICD_sua_nhom, req, false);

    if (res.status === "OK") return true;
  } catch (error) {
    HLog("Xoá nhóm ICD 10 lỗi");
  }
};

/* ===================================================================================================
 *                            API DANH MỤC QUỐC GIA
 * ===================================================================================================
 */

export const apiLayDsQuocGia = async ({
  BENH_VIEN_ID = "",
  page = 1,
  search_string = "",
  limit = keys.limit,
}) => {
  let dataRequest = { page, limit, search_string };
  try {
    let response = await common_post(
      apis.dm_quocgia_lay_ds,
      dataRequest,
      false
    );
    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Api lay danh sach quoc gia error", error);
  }
};

export const apiLayDsQuocGiaCoHieuLuc = async ({
  BENH_VIEN_ID = "",
  page = 1,
  search_string = "",
  limit = keys.limit,
}) => {
  let dataRequest = { page, limit, search_string };
  try {
    let response = await common_post(
      apis.dm_quocgia_lay_ds_co_hieu_luc,
      dataRequest,
      false
    );
    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Api lay danh sach quoc gia co hieu luc error", error);
  }
};

export const apiThemQuocGia = async ({ BENH_VIEN_ID = "", data = {} }) => {
  let dataRequest = { ...data, BENH_VIEN_ID };
  try {
    let response = await common_post(apis.dm_quocgia_them, dataRequest, false);
    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Api lay danh sach quoc gia error", error);
  }
};

export const apiNhapDsQuocGia = async ({
  BENH_VIEN_ID = "",
  data,
  THAY_THE,
}) => {
  HLog("data exel", data);
  try {
    let response = await common_post(
      apis.dm_quocgia_nhap_ds,
      { THAY_THE, data: data },
      false
    );
    if (response) {
      return response;
    }
  } catch (error) {
    HLog("Api lay danh sach quoc gia error", error);
  }
};

export const apiSuaQuocGia = async ({ data = {} }) => {
  try {
    let response = await common_post(apis.dm_quocgia_cap_nhat, data, false);
    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Api lay danh sach quoc gia error", error);
  }
};
