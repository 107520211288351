import { Checkbox, Col, Form, InputNumber, Modal, Row } from "antd";
import styles from "./styles.module.less";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useRef } from "react";
import i18n, { languageKeys } from "../../../../../i18n";
import { useEventListener } from "hooks";


const ModalBarcodeOngMau = forwardRef(({ handlePrint, formSID, refDsDichVu, cuocKham }, ref) => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal(data) {
      setData(data);
      setVisible(true)
      let dataFill = data?.map(e=> ({name: e?.TEN_LOAI_BP || i18n.t(languageKeys.ong_mau_khong_ten) , value: e?.array?.length }))
      formSID.setFields(dataFill);
    },
    close(data) {
      setVisible(false)
    },
  }));

  const onPrint = () => {
    try {
      const values = formSID.getFieldsValue();
      let dataCv = [];
      dataCv = data?.map((obj) => {
        return { ...obj, SO_LUONG: values[obj?.TEN_LOAI_BP || i18n.t(languageKeys.ong_mau_khong_ten)] };
      });

      return handlePrint({ data: dataCv });
    } catch (error) {
      console.log(error);
    }
  };


  const handleKeyPress = (event) => {
    
      if (event.ctrlKey && event.key === "p")  {
        event.stopPropagation();
        event.preventDefault();
        onPrint()
      }
  };

  useEventListener("keydown", handleKeyPress, window.document, visible);

  return (
    <Modal
      open={visible}
      title={i18n.t(languageKeys.in_barcode)}
      onOk={() => {
        onPrint()
      }}
      onCancel={() => {
        setVisible(false)
      }}
      wrapClassName={styles["modal"]}
      classFooter={styles["classFooter"]}
      okText={i18n.t(languageKeys.in_barcode) + " (Ctrl + P)"}
      cancelText={`${i18n.t(languageKeys.common_Thoat)} (ESC)`}
    >
      <div className={styles["container"]}>
        <Form

          labelAlign="left"
          labelWrap
          form={formSID}
          labelCol={{
            span: 16,
          }}
          wrapperCol={{
            span: 8,
          }}
        >
          {data?.map((e) => (
            <Form.Item
              initialValue={e?.array?.length}
              name={e?.TEN_LOAI_BP || i18n.t(languageKeys.ong_mau_khong_ten)}
              label={<p style={{ textWrap: "wrap", textAlign: "left" }}>{e?.TEN_LOAI_BP || i18n.t(languageKeys.ong_mau_khong_ten)}</p>}
            >
              <InputNumber placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          ))}
        </Form>
      </div>
    </Modal>
  );
});

export default ModalBarcodeOngMau;
