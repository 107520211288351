import { Form, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { forwardRef, memo, useImperativeHandle, useState } from "react";
import { FormDrawer } from "../../../components";
import { getErrorMessage, HLog } from "../../../helpers";
import i18n, { languageKeys } from "../../../i18n";
import mapCateKey from "./mapCateKey";
import { keysDanhMuc, widthDrawerKeys } from "../../../constants/keys";
import { useSelector } from "react-redux";
import { useEventListener } from "../../../hooks";

const CaiDatDanhMuc = forwardRef(({ currentKey = "", handleAfterSubmitSuccess = () => {}, onAddSubmitSuccess, onHideParent, disableThemMoi }, ref) => {
  const [isCreateNew, setIsCreateNew] = useState(true); // Là Tạo mới (false thì là Chỉnh sửa)
  const [visible, setVisible] = useState(false); // Hiển thị modal
  const [loading, setLoading] = useState(false); // Loading nút Submit
  const [form] = useForm(); // ref Form của Ant Design
  const [initData, setInitData] = useState({});
  const userProfile = useSelector((state) => state.auth.user);

  useImperativeHandle(ref, () => ({
    open(data) {
      if (!!onHideParent) onHideParent(true);
      if (!!data) {
        setIsCreateNew(false);
        setInitData(data);
        if (currentKey !== keysDanhMuc.dm_phong) {
          form.setFields(
            Object.values(mapCateKey()[currentKey].fieldData).map((key) => {
              return {
                name: key,
                value: data[key],
              };
            })
          );
        }
      }

      setVisible(true);
    },
    close() {
      onClose();
    },
  }));

  const onClose = () => {
    if (!!onHideParent) onHideParent(false);
    setIsCreateNew(true);
    setLoading(false);
    setInitData({});
    form.resetFields();
    setVisible(false);
  };

  // Hàm xử lý tạo mới bản ghi
  const handleCreateRecord = async (values) => {
    try {
      const res = await mapCateKey()[currentKey].create({
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        data: values,
        partner_code: userProfile.partner_code,
        NHANSU_ID: userProfile.NHANSU_ID,
      });

      if (!!res) {
        if (res.status === "OK") {
          onClose();

          notification.success({
            message: i18n.t(languageKeys.them_moi_thanh_cong),
            placement: "bottomLeft",
          });
          !!onAddSubmitSuccess && onAddSubmitSuccess(res);
          handleAfterSubmitSuccess();
        } else {
          setLoading(false);

          const fallback = i18n.t(languageKeys.them_moi_that_bai);
          form.setFields([
            {
              name: res.field,
              errors: [""],
            },
          ]);

          notification.error({
            message: getErrorMessage(res.error_code, fallback),
            placement: "bottomLeft",
          });
        }
      } else {
        setLoading(false);
        notification.error({
          message: i18n.t(languageKeys.them_moi_that_bai),
          placement: "bottomLeft",
        });
      }
    } catch (err) {
      setLoading(false);
      notification.error({
        message: i18n.t(languageKeys.them_moi_that_bai),
        placement: "bottomLeft",
      });
      HLog(`Create ${i18n.t(mapCateKey()[currentKey].langKey)} error`, err);
    }
  };

  //hàm trả về thông tin thuốc sau khi gọi api lấy chi tiết ở màn chi tiết thuốc
  const callBackThuoc = (data) => {
    setInitData(data);
  };

  // Hàm xử lý chỉnh sửa bản ghi
  const handleEditRecord = async (values, currentData) => {
    try {
      const data = {
        ...currentData,
        ...values,
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        NHANSU_ID: userProfile.NHANSU_ID,
      };
      HLog("handleEditRecord...", data);
      const res = await mapCateKey()[currentKey].edit({ data, currentData, NHANSU_ID: userProfile.NHANSU_ID }, currentData);

      if (!!res) {
        if (res.status === "OK") {
          onClose();

          if (currentKey === keysDanhMuc.dm_phong) {
            if (res.isWarning) {
              notification.warning({ message: i18n.t(languageKeys.noti_warning_thay_doi_thoi_gian_phong) });
            }
          }

          notification.success({
            message: i18n.t(languageKeys.cap_nhat_thanh_cong),
            placement: "bottomLeft",
          });

          handleAfterSubmitSuccess();
        } else {
          setLoading(false);

          const fallback = i18n.t(languageKeys.cap_nhat_that_bai);

          form.setFields([
            {
              name: res.field,
              errors: [""],
            },
          ]);

          let error_msg = getErrorMessage(res.error_code, fallback);

          if (!!mapCateKey()[res.field]) {
            error_msg += `: ${i18n.t(languageKeys.cate_Danh_muc)} ${i18n.t(mapCateKey()[res.field].langKey)}`;
          }

          notification.error({
            message: error_msg,
            placement: "bottomLeft",
          });
        }
      } else {
        setLoading(false);
        notification.error({
          message: i18n.t(languageKeys.cap_nhat_that_bai),
          placement: "bottomLeft",
        });
      }
    } catch (err) {
      setLoading(false);
      notification.error({
        message: i18n.t(languageKeys.cap_nhat_that_bai),
        placement: "bottomLeft",
      });
      HLog(`Update ${i18n.t(mapCateKey()[currentKey].langKey)} error`, err);
    }
  };

  // Hàm xử lý submit form
  const onFinish = (values) => {
    setLoading(true);

    // convert key hien thi (bool) -> 0/1
    const key_hien_thi = mapCateKey()[currentKey].fieldData.hien_thi || mapCateKey()[currentKey].fieldData.KHOA;
    values[key_hien_thi] = !!values[key_hien_thi] ? 1 : 0;

    HLog("Submit form", values);

    if (currentKey === keysDanhMuc.dm_kho_thuoc) return;

    if (isCreateNew) handleCreateRecord(values);
    else {
      handleEditRecord(values, initData);
    }
  };

  // hàm xử lý sự kiện phím tắt
  const keydownEvent = (event) => {
    if (event.ctrlKey && event.key === "s") {
      event.stopPropagation();
      event.preventDefault();
      if (currentKey !== keysDanhMuc.dm_thuoc) {
        form.submit();
      }
    } else if (event.ctrlKey && event.key === "d") {
      event.stopPropagation();
      event.preventDefault();
      form.resetFields();
    }
  };
  useEventListener("keydown", keydownEvent, window.document, visible);

  const doAfterSubmitThuoc = () => {
    handleAfterSubmitSuccess();
  };

  return (
    <FormDrawer
      disableAddBtn={disableThemMoi}
      disableOkBtn={disableThemMoi}
      title={
        currentKey === keysDanhMuc.dm_thuoc
          ? `
          ${isCreateNew ? i18n.t(languageKeys.field_Them_moi_thuoc) : i18n.t(languageKeys.thong_tin_thuoc)}
        `
          : `${isCreateNew ? i18n.t(languageKeys.common_Them_moi) : i18n.t(languageKeys.common_Chinh_sua)} ${i18n.t(
              mapCateKey()[currentKey].langKey
            )}`
      }
      // showPlusIcon={isCreateNew}
      visible={visible}
      loading={loading}
      onCancel={onClose}
      hiddenTopAction={currentKey === keysDanhMuc.dm_thuoc || currentKey === keysDanhMuc.dm_kho_thuoc ? true : false}
      buttonsAtBottomOfContent={currentKey === keysDanhMuc.dm_thuoc || currentKey === keysDanhMuc.dm_kho_thuoc || currentKey.dm_dich_vu_ki_thuat ? false : true}
      onOk={() => form.submit()}
      width={currentKey === keysDanhMuc.dm_dich_vu_ki_thuat || currentKey === keysDanhMuc.dm_thuoc ? widthDrawerKeys.large : widthDrawerKeys.small}
    >
      {visible && (
        <Form form={form} layout="vertical" onFinish={onFinish}>
          {
            mapCateKey({
              initData,
              visible: visible,
              is_create_new: isCreateNew,
              form: form,
              callBackData: callBackThuoc,
              doAfterSubmitThuoc: doAfterSubmitThuoc,
              handleActionSuccess: handleAfterSubmitSuccess,
              closeModal: onClose,
            })[currentKey].formItem
          }
        </Form>
      )}
    </FormDrawer>
  );
});

export default memo(CaiDatDanhMuc);
