import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Space, Row, Col, Input, InputNumber } from "antd";
import i18n, { languageKeys } from "../../../../i18n";
import styles from "../vattu.module.less";
import TableDeXuat from "../ChiDinhDvGoi/TableDeXuat";
import ChiDinhVatTu from "../ChiDinhVatTu";
import { Select } from "components";
import { useSelector } from "react-redux";
import { getLieuTrinhData } from "ducks/slices/GoiDichVu/GoiVatTu.slice";
import { useState } from "react";

const PhacDoDeXuat = () => {

  const { dsDeXuat } = useSelector(getLieuTrinhData);
  const [BAC_SI_DE_XUAT_ID, setBAC_SI_DE_XUAT_ID] = useState()

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      defaultActiveKey={["DE_XUAT"]}
    >
      <Collapse.Panel
        header={i18n.t(languageKeys.chi_dinh_dv_trong_goi)}
        key="DE_XUAT"
        extra={(
          <Space className={styles.extra} onClick={(e) => e.stopPropagation()}>
            <span>{i18n.t(languageKeys.bac_si_de_xuat)}:</span>
            <Select
              dataSource={dsDeXuat}
              valueKey='ID'
              titleKey='BAC_SI_DE_XUAT'
              // onSearch={() => {}}
              // showSearch
              value={BAC_SI_DE_XUAT_ID}
              onChange={setBAC_SI_DE_XUAT_ID}
              allowClear
            />
          </Space>
        )}
      >
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className={styles.nestedCollapse}
          defaultActiveKey={[0]}
        >
          {dsDeXuat
            .filter(i => BAC_SI_DE_XUAT_ID ? i.ID === BAC_SI_DE_XUAT_ID : true)
            .map((item, index) => (
              <Collapse.Panel
                header={`${i18n.t(languageKeys.bac_si_de_xuat)}: ${item.BAC_SI_DE_XUAT}`}
                key={index}
                extra={
                  <Space>
                    <label>{i18n.t(languageKeys.so_buoi_sd)}</label>
                    <InputNumber value={item.SO_BUOI_SU_DUNG} readOnly style={{ background: '#fff' }} />
                  </Space>
                }
              >
                <TableDeXuat
                  dataSource={item.DANH_SACH_DICH_VU}
                  selectedTab={"PHAC_DO_DE_XUAT"}
                  readOnly
                  hideHeader
                />
                <Row className={styles.usageDay}>
                  <Col span={24}>
                    <div className={styles.flex}>
                      <label>{i18n.t(languageKeys.ghi_chu)}</label>
                      <Input value={item.GHI_CHU} readOnly />
                    </div>
                  </Col>
                </Row>
                <h4>{i18n.t(languageKeys.chi_dinh_thuoc_vat_tu_trong_goi)}</h4>
                <ChiDinhVatTu
                  supplyList={item.DANH_SACH_VAT_TU}
                  readOnly
                />
              </Collapse.Panel>
            ))}
        </Collapse>
      </Collapse.Panel>
    </Collapse>
  )
}

export default PhacDoDeXuat;