import { Row, Col, Form, Radio, Input as AntInput } from "antd";
import i18n, { languageKeys } from "../../../../../i18n";
import styles from "./style.module.less";
import { fieldKhamBenh } from "../constantsTTK";
import { KOCO } from "./data";

const KhamSanHB = ({ readOnly, isEditAllowed }) => {

  const Item = ({ children, ...props }) => (
    <Form.Item style={{ marginBottom: 6 }} {...props}>
      {children}
    </Form.Item>
  )

  const Input = (props) => (
    <AntInput
      placeholder={i18n.t(languageKeys.common_Nhap)}
      readOnly={readOnly}
      disabled={!isEditAllowed}
      {...props}
    />
  )

  const TextArea = (props) => (
    <AntInput.TextArea
      autoSize
      readOnly={readOnly}
      disabled={!isEditAllowed}
      placeholder={i18n.t(languageKeys.common_Nhap)}
      {...props}
    />
  )

  const RadioGroup = ({ children, ...props }) => (
    <Radio.Group disabled={readOnly || !isEditAllowed} {...props}>
      {children}
    </Radio.Group>
  )

  return (
    <Row gutter={10} className={styles["form-container"]}>

      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.field_Ly_do_kham)}</span>
        <Item name={fieldKhamBenh.LY_DO_VAO_VIEN}>
          <TextArea />
        </Item>
      </Col>

      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.field_Qua_trinh_benh_ly)}</span>
        <Item name={fieldKhamBenh.QUA_TRINH_BENH_LY}>
          <TextArea />
        </Item>
      </Col>

      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.tien_sua_benh_ban_than)}</span>
        <Item name={fieldKhamBenh.TIEN_SU_BENH}>
          <TextArea />
        </Item>
      </Col>
      <Row className={styles.wrapper}>
        <Col span={12}>
          <span className={styles.label}>{i18n.t(languageKeys.benh_huyet_ap)}</span>
        </Col>
        <Col span={12}>
          <Item name={fieldKhamBenh.BENH_HUYET_AP}>
            <RadioGroup options={KOCO} />
          </Item>
        </Col>
        <Col span={12}>
          <span className={styles.label}>{i18n.t(languageKeys.benh_tuyen_giap)}</span>
        </Col>
        <Col span={12}>
          <Item name={fieldKhamBenh.BENH_TUYEN_GIAP}>
            <RadioGroup options={KOCO} />
          </Item>
        </Col>
        <Col span={12}>
          <span className={styles.label}>{i18n.t(languageKeys.dai_thao_duong)}</span>
        </Col>
        <Col span={12}>
          <Item name={fieldKhamBenh.DAI_THAO_DUONG}>
            <RadioGroup options={KOCO} />
          </Item>
        </Col>
      </Row>


      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.tien_su_san_khoa)}</span>
        <Item name={fieldKhamBenh.TIEN_SU_SAN_KHOA}>
          <TextArea />
        </Item>
      </Col>

      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.tien_su_di_ung)}</span>
        <Item name={fieldKhamBenh.TIEN_SU_DI_UNG}>
          <TextArea />
        </Item>
      </Col>

      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.tai_nan_thuong_tich)}</span>
        <Item name={fieldKhamBenh.TAI_NAN_THUONG_TICH}>
          <TextArea />
        </Item>
      </Col>

      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.tien_su_benh_gia_dinh)}</span>
        <Item name={fieldKhamBenh.TIEN_SU_GIA_DINH}>
          <TextArea />
        </Item>
      </Col>

      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.tien_su_benh_khac)}</span>
        <Item name={fieldKhamBenh.TIEN_SU_BENH_KHAC}>
          <TextArea />
        </Item>
      </Col>

      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.thay_kinh_nguyet_bn_tuoi)}</span>
        <Item name={fieldKhamBenh.TUOI_CO_KINH_NGUYET}>
          <Input />
        </Item>
      </Col>

      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.tinh_chat_kinh_nguyet)}</span>
        <Item
          className={styles.noMinH}
          name={fieldKhamBenh.KINH_NGUYET_DEU}
        >
          <RadioGroup>
            <Radio value="DEU">{i18n.t(languageKeys.deu)}</Radio>
            <Radio value="KO_DEU">{i18n.t(languageKeys.ko_deu)}</Radio>
          </RadioGroup>
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.chu_ky_kinh)}</span>
        <Item name={fieldKhamBenh.CHU_KY_KINH}>
          <Input suffix={i18n.t(languageKeys.data_Ngay)} />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.luong_kinh)}</span>
        <Item name={fieldKhamBenh.LUONG_KINH}>
          <Input suffix={i18n.t(languageKeys.data_Ngay)} />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.co_dang_dung_bptt)}</span>
      </Col>
      <Col span={12}>
        <Item
          name={fieldKhamBenh.BIEN_PHAP_TRANH_THAI}
          style={{ marginBottom: 0 }}
        >
          <RadioGroup options={KOCO} />
        </Item>
      </Col>
      <Col span={24}>
        <Item name={fieldKhamBenh.DESC_BIEN_PHAP_TRANH_THAI}>
          <Input />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.dau_bung_kinh)}</span>
        <Item
          className={styles.noMinH}
          name={fieldKhamBenh.DAU_BUNG_KINH}
        >
          <RadioGroup options={KOCO} />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.da_lap_gia_dinh)}</span>
        <Item
          className={styles.noMinH}
          name={fieldKhamBenh.DA_LAP_GIA_DINH}
        >
          <RadioGroup options={KOCO} />
        </Item>
      </Col>

      {/* <Col span={24}>
        <span className={styles.label}>PARA</span>
        <Item name={fieldKhamBenh.PARA}>
          <Input />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.so_lan_mo_san)}</span>
      </Col>
      <Col span={12}>
        <Item name={fieldKhamBenh.MO_SAN_PHU_KHOA}>
          <RadioGroup options={KOCO} />
        </Item>
      </Col>
      <Col span={24}>
        <Item name={fieldKhamBenh.DESC_MO_SAN_PHU_KHOA}>
          <Input />
        </Item>
      </Col> */}
    </Row>
  )
}

export default KhamSanHB;