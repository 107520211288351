import { Spin, Tree } from "antd";
import { memo } from "react";
import { getFirstLetters } from "../../../../helpers";
import style from "./listDvKhamBenh.module.less";
import { showDeleteBtn } from "pages/QuanLyTiepDon/ModalTiepDon.new/helpers";

const ListDvKhamBenh = ({ dsDvKhamBenh = [], loading = false, dsDvDaChon = [], onTreeKBSelect = () => {}, searchVal = "", disabled = false }) => {  
  const checkDisable=  (sub_item) => {
    return dsDvDaChon.some((item) => item.DICH_VU_ID === sub_item.DICH_VU_ID && (item.disabled || !showDeleteBtn(item || {})))  || disabled
  }
  return (
    <div className={style["container"]}>
      <Spin spinning={loading}>
        <Tree
          checkable
          checkedKeys={dsDvDaChon.map((item) => item.DV_ID)}
          onClick={(event, e) => onTreeKBSelect(e)}
          onCheck={(key, event) => onTreeKBSelect(event.node)}
          disabled={disabled}
        >
          {dsDvKhamBenh
            .filter(
              (item) =>
                item.TEN_DICHVU.toLocaleLowerCase().includes(searchVal.toLocaleLowerCase()) ||
                getFirstLetters(item.TEN_DICHVU).toLocaleLowerCase().includes(searchVal.toLocaleLowerCase())
            )
            .map((sub_item) => (
              <Tree.TreeNode
                key={sub_item.DV_ID}
                value={sub_item}
                title={<span className="navy-txt">{sub_item.TEN_DICHVU}</span>}
                checkable
                selectable
                // disableCheckbox={dsDvDaChon.some((item) => item.DV_ID === sub_item.DV_ID && item.disabled) && disabled}
                // disabled={dsDvDaChon.some((item) => item.DV_ID === sub_item.DV_ID && item.disabled) && disabled}
                disableCheckbox={checkDisable(sub_item)}
                disabled={checkDisable(sub_item)}
                
              />
            ))}
        </Tree>
      </Spin>
    </div>
  );
};

export default memo(ListDvKhamBenh);
