/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState, useEffect } from "react";
import {
  Col,
  Form,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Switch,
} from "antd";
import moment from "moment";
import i18n, { languageKeys } from "../../../i18n";
import {
  ActionButton,
  Select,
  Table,
  Topbar,
} from "../../../components";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { common_post, convertMillisecondToDate, HLog, rid } from "../../../helpers";
import { apis, keys } from "../../../constants";
import kho from "./kho.module.less";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import { useEventListener } from "../../../hooks";

const { Item } = Form;
const KhoDuoc = ({}) => {
  const [visible, setVisible] = useState(false); // modal them moi kho thuoc
  const [dsKhoThuoc, setDsKhoThuoc] = useState([]); // ds kho thuoc
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [selectedRows, setSelectedRows] = useState([]); // kho thuoc duoc chon
  const [totalNumber, setTotalNumber] = useState(0);
  const [isEdit, setIsEdit] = useState(false)
  const [itemEdit, setItemEdit] = useState()

  const [dsPhong, setDsPhong] = useState()
  const [loadingPhong, setLoadingPhong] = useState(false)
  const [selectedPhong, setSelectedPhong] = useState([])
  const [selectLoaiKho, setSelectedLoaiKho] = useState() // loại kho

  const [dsKhoChuyen , setDsKhoChuyen] = useState()
  const [loadingKhoChuyen, setLoadingKhoChuyen] = useState(false)
  const [selectedKhoChuyen, setSelectedKhoChuyen] = useState([])

  const [loadingNhanSu, setLoadingNhanSu] = useState(false)
  const [dsNhanSu, setDsNhanSu] = useState([])
  const [selectedNhanSu, setSelectedNhanSu] = useState()


  const [form] = Form.useForm();
  const userProfile = useSelector((state) => state.auth.user);

  useEffect(() => {
    // khoi tao gia tri tim kiem
    layDsKhoThuoc()
    return () => {
      setDsKhoThuoc([]);
      setCurrentPage(1);
      setSearchString("");
      setSelectedRows([]);
      setTotalNumber(0);
      setLoading(false);
      form.resetFields()
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectLoaiKho) {
      layDanhKhoHieuLuc()
    }
  }, [selectLoaiKho])


  //api lấy danh sách kho thuốc hiện có
  const layDsKhoThuoc = async (search_string = searchString, page = currentPage) => {
    let body = {
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
      search_string,
      page : page,
      limit : keys.limit,
      LOAI_TRU :  []
    };

    try {
      let res = await common_post(apis.lay_ds_kho_thuoc, body, false);

      if (res && res.status === "OK") {
        let { total, current_page, result } = res;
        setDsKhoThuoc(result.map((item, index) => ({ ...item, key: rid() })));
        setTotalNumber(total);
        setCurrentPage(current_page);
      }
    } catch (error) {
      HLog("Lỗi lấy danh sách kho: ", error);
    }
  };


   // api lấy danh sách người tiếp đón
   const layDsNhanSu = async (search_string="") => {
    setLoadingNhanSu(true)
       let req ={
         partner_code:userProfile.partner_code,
         BENH_VIEN_ID:userProfile.BENH_VIEN_ID,
         search_string,
         limit:keys.limit,
        //  page,
       }
       try {
         let res = await common_post(apis.dm_lay_ds_user,req,false)
          if(!!res && res.status ==="OK"){
            let {result} = res
            setDsNhanSu(result.map(item => {
              return {
                NHANSU_ID : item.NHANSU_ID,
                THU_KHO : item.HO.trim() + " " + item.TEN.trim()
              }
            }))
          }
       } catch (error) {
         HLog("LAYDS NGUOI TIEP DON FAIL",error)
       }
      setLoadingNhanSu(false)
    }

  //api lấy danh sách kho thuốc hiện có
  const layDanhSachPhong = async (search_string = "", page = 1) => {
    let body = {
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
      search_string,
      page : page,
      limit : keys.limit,
      LOAI_PHONG : "KHAM_BENH"
    };
    setLoadingPhong(true)

    try {
      let res = await common_post(apis.khambenh_lay_dm_phong, body, false);
      if (res && res.status === "OK") {
        // let { total, current_page, result } = res;
        setLoadingPhong(false)
        setDsPhong(res.result.map(item => ({ ID : item.ID, TEN_KHOA_PHONG : item.TEN_KHOA_PHONG})))
      }
    } catch (error) {
      HLog("Lỗi lấy danh sách phòng: ", error);
    }
  };

   //api lấy danh sách kho thuốc hiện có
   const layDanhKhoHieuLuc = async (search_string = "", page = 1) => {
    const LOAI_THUOC = selectLoaiKho === "NHA_THUOC" ? "NHA_THUOC" : "THUOC_THUONG"
    let body = {
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
      search_string,
      page : page,
      limit : keys.limit,
      LOAI_THUOC,
      LOAI_TRU : isEdit ? [itemEdit.ID] :  []
    };
    setLoadingKhoChuyen(true)

    try {
      let res = await common_post(apis.lay_ds_kho_thuoc_hieu_luc, body, false);
      if (res && res.status === "OK") {
        // let { total, current_page, result } = res;
        setLoadingKhoChuyen(false)
        setDsKhoChuyen(res.result.map(item => ({
          ID : item.ID,
          TEN_KHO : item.TEN_KHO
        })))

      }
    } catch (error) {
      HLog("Lỗi lấy danh sách phòng: ", error);
    }
  }
  //hàm tìm kiếm theo string
  const handleSearchKho = useCallback(
    debounce(
      (search_string, page) => layDsKhoThuoc(search_string, page),
      1000
    ),
    []
  );
  // api thêm mới kho thuốc
  const handleTaoKho = async (value) => {
    let body = {
      ...value,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
      THOI_GIAN_TAO: moment().format("YYYYMMDD"),
      NHANSU_ID : !isEdit ?  value.NHANSU_ID : value.NHANSU_ID.includes(" ") ? itemEdit.NHANSU_ID : value.NHANSU_ID,
      LIST_PHONG : value.LIST_PHONG ? value.LIST_PHONG.map(item => JSON.parse(item).ID) : [],
      KHO_CHUYEN :value.KHO_CHUYEN ? value.KHO_CHUYEN.map(item => JSON.parse(item).ID) : [],
    };
    let dataRequest  = isEdit ? {...body, ID : itemEdit.ID} : body
    try {
      let url = isEdit ? apis.cap_nhat_thong_tin_kho_thuoc : apis.them_kho_thuoc
      const res = await common_post(url, dataRequest, false);
      if (res && res.status === "OK") {
        notification.success({
          message: i18n.t(languageKeys.them_moi_thanh_cong),
          placement: "bottomLeft",
        });
        layDsKhoThuoc()
        handleClose();
      } else {
        notification.error({
          message:isEdit ? i18n.t(languageKeys.thao_tac_that_bai) : i18n.t(languageKeys.them_moi_that_bai),
          placement: "bottomLeft",
        });
      }
    } catch (error) {
      HLog("Lỗi tạo::", error);
    }
  };

  const handleOpenEdit = async (record) => {
    let body = {
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
      ID :record.ID
    };
    try {
      const res = await common_post(apis.lay_chi_tiet_kho_thuoc, body)
      if (res && res.status === "OK") {
          let data = res.result
          setVisible(true)
          setIsEdit(true)
          setItemEdit(data)
          layDsNhanSu()
          setSelectedLoaiKho(data.LOAI_KHO)
         form.setFields(
          Object.values(fieldNames).map(item => {
            if (item === fieldNames.KHO_CHUYEN) {
              let list_kho_chuyen = data[item].map(_i => JSON.stringify({ID : _i.ID, TEN_KHO : _i.TEN_KHO}))
              setSelectedKhoChuyen(list_kho_chuyen)
              return {
                name : item,
                value : list_kho_chuyen
              }
            }
            if (item === fieldNames.PHONG_KE_DON) {
              let list_phong = data[item].map(_i => JSON.stringify({ID : _i.ID, TEN_KHOA_PHONG : _i.TEN_KHOA_PHONG}))
              setSelectedPhong(list_phong)
              return {
                name : item,
                value : list_phong
              }
            }
            if (item === fieldNames.NHANSU_ID) {
              return {
                name : item,
                value : data.THU_KHO
              }
            }
              return {
                name : item,
                value : data[item]
              }
          })
         )
      }
    } catch (error) {

    }
  }

  //hàm thêm mới kho thuốc
  const handleThemMoiKho = () => {
    setVisible(true);
  };

  const handleClose = () => {
    form.resetFields()
    isEdit && setIsEdit(false)
    itemEdit && setItemEdit()
    setSelectedLoaiKho()
    setSelectedKhoChuyen([])
    setSelectedPhong([])
    setVisible(false);
  };

  // hàm xử lý khóa kho
  const handleKhoaKho = async (ID,KHOA) => {
    let dataRequest = {
      partner_code : userProfile.partner_code,
      BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
      ID,
      KHOA
    }
    try {
        const response = await common_post(apis.cap_nhat_thong_tin_kho_thuoc, dataRequest)
        if (response  && response.status === "OK") {
          layDsKhoThuoc()
        }
    } catch (error) {
      HLog("errror", error)
    }
  }

  // hàm xóa kho thuốc
  const handleDeleteKho = (record) => {};
  // hàm sửa kho thuốc
  const handleEditWareHouse = (record) => {};

  // hàm xử lý sự kiện phím tắt
  const keydownEvent = (event) => {

    if(event.ctrlKey && event.key === 's'){
      event.stopPropagation();
      event.preventDefault();
      form.submit();
    }else if(event.ctrlKey && event.key === 'd'){
      event.stopPropagation();
      event.preventDefault();
      form.resetFields();
    }
  }
  useEventListener('keydown',keydownEvent,window.document,visible)

  return (
    <Layout>
      <Topbar
        title={i18n.t(languageKeys.menu_Kho_duoc_ngoai_tru)}
        addBtnText={i18n.t(languageKeys.txt_them_moi_kho)}
        onAdd={handleThemMoiKho}
        searchString={searchString}
        setSearchString={setSearchString}
        onSearch={(txt) => handleSearchKho(txt, currentPage)}
        totalNum={totalNumber}
      />
      <Layout.Content className={kho["container"]}>
        <Table
          selectedRows={selectedRows}
          // onSelectRows={setSelectedRows}
          columns={[
            ...columnsKho,
            {
              title: i18n.t(languageKeys.field_Hien_thi),
              dataIndex: "KHOA",
              width: "70px",
              render: (khoa, record) => (
                <div onClick={(e) => e.stopPropagation()}>
                  <Switch
                    defaultChecked={Boolean(khoa)}
                    onChange={(value) => handleKhoaKho(record.ID,Number(value))}
                  />
                </div>
              ),
            },
            {
              key: "action_btn",
              width: "100px",
              render: (_, record) => (
                <ActionButton
                  // onDelete={() => handleDeleteKho(record)}
                  showDelete= {false}
                  onEdit={() => handleOpenEdit(record)}
                />
              ),
            },
          ]}
          onClickRow = {(record) => handleOpenEdit(record)}
          dataSource={dsKhoThuoc}
          showPagination
          loading={loading}
          onNext={() => layDsKhoThuoc(searchString, currentPage + 1)}
          onPrev={() => layDsKhoThuoc(searchString, currentPage - 1)}
        />
      </Layout.Content>
      <Modal
        visible={visible}
        loading={loading}
        title={isEdit ? i18n.t(languageKeys.common_Chinh_sua) : i18n.t(languageKeys.txt_them_moi_kho_thuoc)}
        okText={
         isEdit ?  <>
              <EditFilled style={{ marginRight: 6 }} />
              {i18n.t(languageKeys.common_Chinh_sua)} (Ctrl + S)
           </> :  <>
            <PlusOutlined style={{ marginRight: 6 }} />
            {i18n.t(languageKeys.txt_them_moi_kho_thuoc)} (Ctrl + S)
          </>
        }
        onOk={() => form.submit()}
        cancelText = {i18n.t(languageKeys.common_Thoat) + " (ESC)"}
        width="50vw"
        onCancel={handleClose}
      >
        <Form
          form={form}
          onFinish={handleTaoKho}
          layout="vertical"
          className={kho["add-kho-container"]}
        >
          <Row gutter={10}>
            <Col span={8}>
              <Item label={i18n.t(languageKeys.field_Ma_kho)} name={fieldNames.MA_KHO}>
                <Input placeholder= {i18n.t(languageKeys.placeholder_Tao_tu_dong)} />
              </Item>
            </Col>

            <Col span={8}>
              <Item label={i18n.t(languageKeys.field_Ten_kho)} name={fieldNames.TEN_KHO} rules={[
                {
                  required : true,
                  whiteSpace: true,
                  message: i18n.t(languageKeys.vui_long_nhap),
                }
              ]}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)}  />
              </Item>
            </Col>

            <Col span={8}>
              <Item label={i18n.t(languageKeys.field_Loai_kho)} name={fieldNames.LOAI_KHO} rules={[
                {
                  required : true,
                  message: i18n.t(languageKeys.please_chon),
                }
              ]}>
                <Select
                  dataSource={DATA_LOAI_KHOA}
                  titleKey="name"
                  valueKey = "key"
                  onSelect={(value) => {
                    if (selectLoaiKho && selectLoaiKho != value) {
                      setSelectedKhoChuyen([])
                      form.setFields([
                        {
                          name : fieldNames.KHO_CHUYEN,
                          value : []
                        }
                      ])
                    }
                    setSelectedLoaiKho(value)
                  }}
                />
              </Item>
            </Col>


            <Col span={12}>
              <Item label={i18n.t(languageKeys.field_Doi_tuong_benh_nhan)} name={fieldNames.LOAI_BENH_NHAN_AP_DUNG}>
                  <Select
                    dataSource={LOAI_BENH_NHAN_AP_DUNG}
                    titleKey="name"
                    valueKey = "key"
                  />
              </Item>
            </Col>

            <Col span={12}>

                <Item label={i18n.t(languageKeys.kho_chuyen)} name={fieldNames.KHO_CHUYEN}>
                  <Select
                    onDropdownVisibleChange = {(value) => {
                      if (!selectLoaiKho) {
                        notification.warning({message : i18n.t(languageKeys.duoc_message_chua_chon_loai_kho)})

                      }
                    }}
                    dataSource={dsKhoChuyen ? dsKhoChuyen : []}
                    // valueKey = "ID"
                    titleKey= "TEN_KHO"
                    loading={loadingKhoChuyen}
                    multiple={true}
                    selectedList={selectedKhoChuyen}
                    onChange={setSelectedKhoChuyen}
                    setSelectedList = {(arr) => {
                      setSelectedKhoChuyen(arr)
                      form.setFields([
                        {
                          name : fieldNames.KHO_CHUYEN,
                          value : arr
                        }
                      ])
                    }}
                  />
                </Item>

            </Col>

            <Col span={12}>
              <Item
                label={i18n.t(languageKeys.field_Thu_kho)}
                name={fieldNames.NHANSU_ID}
                rules={[
                  {
                    required : true,
                    message: i18n.t(languageKeys.please_chon),
                  }
                ]}
              >
                <Select
                    onDropdownVisibleChange = {(value) => {
                      value && dsNhanSu.length < 1 && layDsNhanSu()
                    }}
                    dataSource={dsNhanSu}
                    titleKey= "THU_KHO"
                    valueKey= "NHANSU_ID"
                    loading= {loadingNhanSu}
                />
              </Item>
            </Col>

            <Col span={12}>
              <Item label={i18n.t(languageKeys.field_Ten_phong)} name={fieldNames.PHONG_KE_DON}>
                <Select
                  onDropdownVisibleChange = {(value) => {
                    value && !dsPhong && layDanhSachPhong()
                  }}
                  dataSource={dsPhong ? dsPhong : []}
                  // valueKey = "ID"
                  titleKey= "TEN_KHOA_PHONG"
                  loading={loadingPhong}
                  multiple={true}
                  selectedList={selectedPhong}
                  onChange={setSelectedPhong}
                  setSelectedList = {(arr) => {
                    setSelectedPhong(arr)
                    form.setFields([
                      {
                        name : fieldNames.PHONG_KE_DON,
                        value : arr
                      }
                    ])
                  }}
                />
              </Item>
            </Col>


            <Col span={8} >
              <Item label={i18n.t(languageKeys.duyet_khi_ke_thuoc)} name={fieldNames.DUYET_KHI_KE_THUOC} valuePropName="checked" initialValue={false}>
                  <Switch />
              </Item>
            </Col>
            <Col span={8} >
              <Item label={i18n.t(languageKeys.duyet_khi_chuyen_cac_kho)} name={fieldNames.DUYET_KHI_CHUYEN_CAC_KHO} valuePropName="checked" initialValue={false}>
                  <Switch />
              </Item>
            </Col>

            <Col span={8} >
              <Item label={i18n.t(languageKeys.field_Hien_thi)} name={fieldNames.KHOA} valuePropName="checked" initialValue={false}>
                  <Switch />
              </Item>
            </Col>

          </Row>

        </Form>
      </Modal>
    </Layout>
  );
};
export default KhoDuoc;

let columnsKho = [
  {
    title: (
      <span className="navy-txt">{i18n.t(languageKeys.field_Ma_kho)}</span>
    ),
    dataIndex: "MA_KHO",
    key: "MA_KHO",
  },
  {
    title: (
      <span className="navy-txt">{i18n.t(languageKeys.field_Ten_kho)}</span>
    ),
    dataIndex: "TEN_KHO",
    key: "TEN_KHO",
  },
  {
    title: (
      <span className="navy-txt">{i18n.t(languageKeys.field_Loai_kho)}</span>
    ),
    dataIndex: "LOAI_KHO",
    key: "LOAI_KHO",
    render : (LOAI) => TEN_LOAI_KHO[LOAI]
  },
  {
    title: <span className="navy-txt">{i18n.t(languageKeys.field_Thu_kho)}</span>,
    dataIndex: "THU_KHO",
    key: "THU_KHO",
  },
  {
    title: <span>{i18n.t(languageKeys.field_thoi_gian_tao)}</span>,
    dataIndex: "THOI_GIAN_TAO",
    key: "THOI_GIAN_TAO",
    render : (time,record) => record.GIO + " - " + convertMillisecondToDate(time)
  },
  {
    title: (
      <span className="navy-txt">{i18n.t(languageKeys.trang_thai)}</span>
    ),
    dataIndex: "KHOA",
    key: "KHOA",
    render: (_, record) => {
      return (
        <>{!record.KHOA ? <span style={{color : "#2cb2a5"}}>{i18n.t(languageKeys.dang_hoat_dong)}</span> :
        <span style={{color : "red"}}>{i18n.t(languageKeys.khong_hoat_dong)}</span>}</>
      );
    },
  },
];

const fieldNames = {
  MA_KHO : "MA_KHO",
  TEN_KHO : "TEN_KHO",
  LOAI_KHO : "LOAI_KHO",
  LOAI_BENH_NHAN_AP_DUNG:"LOAI_BENH_NHAN_AP_DUNG",
  KHO_CHUYEN : "KHO_CHUYEN",
  PHONG_KE_DON : "LIST_PHONG",
  DUYET_KHI_KE_THUOC : "DUYET_KHI_KE_THUOC",
  DUYET_KHI_CHUYEN_CAC_KHO : "DUYET_KHI_CHUYEN_CAC_KHO",
  KHOA: "KHOA",
  TRANG_THAI : "TRANG_THAI",
  NHANSU_ID: "NHANSU_ID"
}

export const TEN_LOAI_KHO = {
  KHO_TONG: i18n.t(languageKeys.kho_tong),
  KHO_LE : i18n.t(languageKeys.kho_le),
  KHO_CHAN : "Kho chẵn",
  TU_TRUC : i18n.t(languageKeys.data_Tu_truc),
  NHA_THUOC : i18n.t(languageKeys.nha_thuoc)
}

export const DATA_LOAI_KHOA = [
  {
    key : "KHO_TONG",
    name : i18n.t(languageKeys.kho_tong)
  },
  {
    key : "KHO_LE",
    name : i18n.t(languageKeys.kho_le)
  },
  {
    key : "NHA_THUOC",
    name : i18n.t(languageKeys.nha_thuoc)
  },
  {
    key : "TU_TRUC",
    name : i18n.t(languageKeys.data_Tu_truc),
  }
]

export const LOAI_BENH_NHAN_AP_DUNG = [
  {
    key : "BHYT",
    name :  i18n.t(languageKeys.benh_nhan_bhyt),
  },
  {
    key : "NOI_TRU",
    name : i18n.t(languageKeys.benh_nhan_noi_tru),
  },
  {
    key : "NGOAI_TRU",
    name : i18n.t(languageKeys.benh_nhan_ngoai_tru),
  },

]
