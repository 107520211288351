import React from "react";
import style from "./style.module.less";
import { Row, Col, Form, Radio, Checkbox, Input } from "antd";
import i18n, {languageKeys} from "../../../../i18n";
import {FieldKhaiThac} from "./fieldKhaiThac";
const { Item } = Form;
export const optionsNguonTin = [
    { label: "Google", value: 'GOOGLE' },
    { label: "Facebook", value: 'FACEBOOK' },
    { label: i18n.t(languageKeys.bao_chi), value: 'BAO_CHI' },
    { label: i18n.t(languageKeys.cong_ty_ban_be), value: 'BAN_BE' },
    { label: i18n.t(languageKeys.other), value: 'KHAC' },
]
export const ThongTinKhac = (
    {
        readOnlyInput
    }
) => {
    return(
        <div className={style["tien-su-ban-than"]}>
            <Row align={"middle"}>
                <Col flex={"40%"}>
                    <span className={style["text"]}>1. {i18n.t(languageKeys.question_info_1)}</span>
                </Col>
                <Col flex={"auto"}>
                </Col>
            </Row>
            <Row align={"middle"} wrap={false} style={{marginTop : 10}}>
                <Item name={FieldKhaiThac.NGUON_TIN}>
                    <Checkbox.Group options = {optionsNguonTin} disabled={readOnlyInput}/>
                </Item>
            </Row>
            <Row align={"middle"}>
                <Col flex={"40%"}>
                    <span className={style["text"]}>2. {i18n.t(languageKeys.nguyen_vong_khac)}</span>
                </Col>
                <Col flex={"auto"}>
                </Col>
            </Row>
            <Row align={"middle"} wrap={false} style={{marginTop : 10,}}>
                <Item style={{width : "100%"}} name={FieldKhaiThac.NGUYEN_VONG}>
                    <Input
                        placeholder={i18n.t(languageKeys.common_Nhap)}
                        style={{width : "100%"}}
                        disabled={readOnlyInput}
                    />
                </Item>
            </Row>
        </div>
    )
}
