import { Row, Col, Button, Spin } from "antd";
import React, { useState, useImperativeHandle } from "react";
import { FileCheck } from "../../../../../assets/svg";
import { FormDrawer } from "../../../../../components";
import { useEventListener } from "../../../../../hooks";
import i18n, { languageKeys } from "../../../../../i18n";
import styles from "./style.module.less";
import { base64ToBlob, common_post } from "../../../../../helpers";
import { apis } from "../../../../../constants";
import { useSelector } from "react-redux";
import axios from "axios";
import keys, { base64Mimetypes } from "constants/keys";

function ModalFileDinhKem({ setStopEvent = () => {} }, ref) {
  const [visible, setVisible] = useState(false);
  const [fileMota, setFileMota] = useState();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [listFile, setListFile] = useState([]);
  const userProfile = useSelector((state) => state.auth.user);
  useImperativeHandle(ref, () => ({
    onpenModal: handleOpenModal,
    closeModal: handleCloseModal,
    visibleModal: visible,
  }));

  const handleOpenModal = (PHIEU_ID) => {
    setVisible(true);
    setStopEvent(true);
    handleGetDsFile(PHIEU_ID);
  };

  const handleCloseModal = () => {
    setVisible(false);
    setStopEvent(false);
    setListFile([]);
    setId();
    setFileMota();
  };

  const handleGetDsFile = async (PHIEU_ID) => {
    try {
      let res = await common_post(apis.lay_ds_file_dinh_kem, {
        PHIEU_ID,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
      });

      if (res.status === "OK") {
        let { FILE_MA, FILE_MO_TA, file } = res.result;
        setFileMota(FILE_MO_TA);
        setId(FILE_MA);
        setListFile(file);
      }
    } catch (e) {}
  };

  const onPressPrint = () => {};
  const renderFooterDrawer = () => {
    return (
      <Row gutter={10} justify="end">
        <Col>
          <Button onClick={() => handleCloseModal()} type="primary" ghost>
            {i18n.t(languageKeys.common_Thoat)} (ESC)
          </Button>
        </Col>
      </Row>
    );
  };

  const ItemFile = ({ item }) => {
    return (
      <div className={styles["item-file"]} onClick={() => handleLoadFile(item)}>
        <FileCheck className={styles["icon"]} style={{marginRight:8}} />
        <span>{item.TEN_FILE}</span>
      </div>
    );
  };

  const handleOpenBlobPDFFile = async (ID) => {
    setLoading(true);
    try {
      const access_token = localStorage.getItem(keys.access_token);
      const { data: rs } = await axios.post( apis.layFile,{ ID },{
        headers: {
          authorization: 'Bearer ' + access_token
        }
      })
      console.log(rs.result)
      const blob = base64ToBlob(rs.result[0]?.BASE64, base64Mimetypes.pdf);
      const fileURL = URL.createObjectURL(blob); // Tạo URL từ blob
      window.open(fileURL, "_blank"); // Mở URL trong tab mới
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  };

  const handleLoadFile = async (file) => {
    let {FILE_ID} = file;
    handleOpenBlobPDFFile(FILE_ID);
  };

  const keydownEvent = (event) => {
    const stopDefaultAction = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
  };

  useEventListener("keydown", keydownEvent, window.document, visible);

  return (
    <FormDrawer
      visible={visible}
      width="60vw"
      hiddenTopAction
      onCancel={handleCloseModal}
      footer={renderFooterDrawer()}
      title={i18n.t(languageKeys.file_dinh_kem)}
    >
      <Spin spinning={loading}>
        <div className={styles["container-file"]}>
          <div className={styles["header"]}>
            <span style={{fontSize:16}} className="bold-txt navy-txt">{i18n.t(languageKeys. thong_tin_file_dinh_kem)}</span>
          </div>
          <div className={styles["list-file"]}>
            {/* <Row gutter={10} wrap={false}>
              <Col flex={"70%"}>
                <label>{i18n.t(languageKeys.chi_dinh)}</label>
                <Input readOnly value={fileMota} />
              </Col>
              <Col flex={"auto"}>
                <label>ID</label>
                <Input readOnly value={id} />
              </Col>
            </Row> */}

            <div className={styles["list-item"]}>
              <div style={{fontSize:16, marginBottom:8}} className="bold-txt navy-txt">{i18n.t  (languageKeys.tim_file)}</div>
                {listFile.map((item, index) => (
                    <ItemFile item={item} />
              ))}
            </div>
          </div>
        </div>
      </Spin>
      </FormDrawer>
  );
}

export default React.forwardRef(ModalFileDinhKem);
