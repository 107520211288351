export const FieldKhaiThac = {
    MACH: "MACH",
    NHIET_DO: "NHIET_DO",
    SP_O2: "SP_O2",
    NHIP_THO: "NHIP_THO",
    TAM_THU: "TAM_THU",
    TAM_TRUONG: "TAM_TRUONG",
    HUYET_AP: "HUYET_AP",
    CAN_NANG: "CAN_NANG",
    CHIEU_CAO: "CHIEU_CAO",
    BMI: "BMI",
    VONG_CO: "VONG_CO",
    VONG_BUNG: "VONG_BUNG",

    //
    DI_UNG : "DI_UNG",
    TUNG_NHAP_VIEN : "TUNG_NHAP_VIEN",
    TRUYEN_MAU : "TRUYEN_MAU",
    DANG_DT_DUNG_THUOC : "DANG_DT_DUNG_THUOC",
    BENH_SU : "BENH_SU",
    TIEN_SU_GIA_DINH : "TIEN_SU_GIA_DINH",
    NGUON_TIN : "NGUON_TIN",
    NGUYEN_VONG : "NGUYEN_VONG",
    SINH_CON : "SINH_CON",
    NHAP_VIEN_TAI_NAN: "NHAP_VIEN_TAI_NAN",
    NHAP_VIEN_GAY_XUONG: "NHAP_VIEN_GAY_XUONG",
    KHAM_LOANG_XUONG:"KHAM_LOANG_XUONG",
    
    SINH_CON_VALUE : "SINH_CON_VALUE",
    DI_UNG_VALUE : "DI_UNG_VALUE",
    TUNG_NHAP_VIEN_VALUE : "TUNG_NHAP_VIEN_VALUE",
    TRUYEN_MAU_VALUE : "TRUYEN_MAU_VALUE",
    DANG_DT_DUNG_THUOC_VALUE : "DANG_DT_DUNG_THUOC_VALUE",
    NHAP_VIEN_TAI_NAN_VALUE : "NHAP_VIEN_TAI_NAN_VALUE",
    NHAP_VIEN_GAY_XUONG_VALUE: "NHAP_VIEN_GAY_XUONG_VALUE",
    KHAM_LOANG_XUONG_VALUE:"KHAM_LOANG_XUONG_VALUE",

    TIEN_SU_BENH_KHAC: "TIEN_SU_BENH_KHAC",
    VONG_HONG_CHAU:"VONG_HONG_CHAU",
    TUOI_MAN_KINH:"TUOI_MAN_KINH",
}
// lưu ý không đổi thứ tự key
export const fieldVongBung = {
    VONG_BUNG_QUA_EO:"VONG_BUNG_QUA_EO",
    VONG_BUNG_QUA_RON:"VONG_BUNG_QUA_RON",
    VONG_BUNG_QUA_GAI_CHAU_TRUOC_TREN:"VONG_BUNG_QUA_GAI_CHAU_TRUOC_TREN",
}