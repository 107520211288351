import React, { useState, useEffect, useImperativeHandle, useCallback, useRef } from "react";
import { Form, Modal, Row, Col, DatePicker, Input, Select as SelectAntd, Button, Divider, Tooltip, InputNumber, Spin, notification, AutoComplete } from "antd";
import styles from "./style.module.less";
import { useForm } from "antd/lib/form/Form";
import { DateInput, Table, Select, FormDrawer, ConfirmModal } from "../../../../../components";
import { PrinterFilled, SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import moment from "moment";
import i18n, { languageKeys, languages } from "../../../../../i18n";
import {
  common_post,
  convertDateToValue,
  convertValueToDate,
  currencyParserNotAlowNegativeNum,
  formatNumberToPrice,
  genIDOnlyNumber,
  handleErrorHuyPhieu,
  HLog,
  rid,
} from "../../../../../helpers";
import { Delete, DeleteGray } from "../../../../../assets/svg";
import { apis } from "../../../../../constants";
import SearchThuocXuatKho from "./SearchThuocXuatKho/SearchThuocXuat";
import { hooksPhieuXuat } from "./hooksPhieuXuat";
import ModalPDF from "../../../../../components/Modal/ModalPDF";
import { useEventListener } from "../../../../../hooks";
import { widthDrawerKeys } from "../../../../../constants/keys";
import { TooltipButton } from "components/Tooltip/CustomTooltip";
const { Item } = Form;
const TYPE_MIEN_GIAM = {
  TIEN: "TIEN",
  PHAN_TRAM: "PHAN_TRAM",
};

function ModalPhieuXuat({ doAfterSuccess = () => {}, disableInPhieu = false }, ref) {
  const drugRef = useRef();
  const searchDrugRef = useRef();
  const [formPhieu] = useForm();
  const [formThuoc] = useForm();
  const pdfRef = useRef();
  const confirmRef = useRef();
  const userProfile = useSelector((state) => state.auth.user);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedThuoc, setSelectedThuoc] = useState();
  const [drugSearchString, setDrugSearchString] = useState("");
  const [idLocal, setIdLocal] = useState("");
  const [khoThuoc, setKhoThuoc] = useState();
  const [soLuong, setSoLuong] = useState(0);
  const [loadingButtonAdd, setLoadingButtonAdd] = useState(false);
  const [dsThuoc, setDsThuoc] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [ghiChu, setGhiChu] = useState("");
  const [typeGiam, setTypeGiam] = useState(TYPE_MIEN_GIAM.TIEN);
  const [valueGiam, setValueGiam] = useState(0);
  const [tienTraNCC, setTienTraNCC] = useState(0);
  const [typePhieu, setTypePhieu] = useState("XUAT_TRA_NCC");
  const [readOnly, setReadOnly] = useState(true);
  const [ncc, setNcc] = useState();
  const [phieuID, setPhieuId] = useState();
  const [print, setPrint] = useState(false);
  const [isEdit, setIsEdit] = useState(false); //
  const [isDaHuy, setIsDaHuy] = useState(false);
  const [trangThaiPhieu, setTrangThaiPhieu] = useState("");
  const [errorSL, setErrorSL] = useState(false);
  const { dsKhoThuoc, dsNCC, dsLyDo, handleSearchKhoThuoc, handleSearchNCC } = hooksPhieuXuat({ visible, typePhieu, readOnly });

  useImperativeHandle(
    ref,
    () => ({
      openModal(item, type) {
        type && setTypePhieu(type);
        if (item) {
          //setReadOnly(true)
          setTypePhieu(item.PHAN_LOAI);
          getChiTietPhieu(item);
        } else {
          // nếu là tạo mới thì gen id đẩy lên server
          let id = genIDOnlyNumber(30, "");
          setIdLocal(id);
          setReadOnly(false);
        }
        setVisible(true);
      },
      closeModal() {
        handleCloseModal();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (ncc && khoThuoc) {
      searchDrugRef.current?.searchData("", khoThuoc, ncc);
    }
  }, [ncc]);

  function handleCloseModal() {
    // searchDrugRef.current?.setDataSource([])
    setTimeout(() => {
      searchDrugRef.current?.setDataSource([]);
    }, 1000);
    formPhieu.resetFields();
    formThuoc.resetFields();
    setDsThuoc([]);
    setGhiChu("");
    setValueGiam(0);
    setTypeGiam(TYPE_MIEN_GIAM.TIEN);
    setTienTraNCC(0);
    handleClearDrugInput();
    setSelectedThuoc();
    setSoLuong(0);
    ncc && setNcc();
    loading && setLoading(false);
    khoThuoc && setKhoThuoc();
    isDaHuy && setIsDaHuy(false);
    setTrangThaiPhieu("");
    errorSL && setErrorSL(false);
    setReadOnly(true);
    if (idLocal && dsThuoc.length > 0) {
      handleHuySlotThuoc();
    } else {
      setVisible(false);
    }
  }

  // điền data vào form khi xem
  async function getChiTietPhieu(item) {
    setLoading(true);
    setPhieuId(item.ID);
    try {
      const response = await common_post(apis.lay_chi_tiet_phieu, {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        ID: item.ID,
      });
      if (response && response.status === "OK") {
        let { chi_tiet, data_thuoc } = response.result;
        setLoading(false);
        formPhieu.setFields([
          {
            name: "KHO_THUOC_ID",
            value: chi_tiet.TEN_KHO,
          },
          {
            name: "NCC_THUOC_ID",
            value: chi_tiet.TEN_NCC,
          },
          {
            name: "NGAY",
            value: moment(chi_tiet.NGAY + " " + chi_tiet.GIO, "YYYYMMDD HH:mm"),
          },
          {
            name: "NGUOI_TAO",
            value: chi_tiet.NGUOI_TAO,
          },
          {
            name: "MA_PHIEU",
            value: chi_tiet.MA_PHIEU,
          },
          {
            name: "LY_DO",
            value: chi_tiet.LY_DO,
          },
        ]);
        if (chi_tiet.GIAM_PHAN_TRAM) {
          setTypeGiam(TYPE_MIEN_GIAM.PHAN_TRAM);
          setValueGiam(chi_tiet.GIAM_PHAN_TRAM);
        }
        if (chi_tiet.GIAM_TIEN) {
          setTypeGiam(TYPE_MIEN_GIAM.TIEN);
          setValueGiam(chi_tiet.GIAM_TIEN);
        }
        if (chi_tiet.TRANG_THAI_PHIEU === "HUY") {
          setIsDaHuy(true);
        }
        setTrangThaiPhieu(chi_tiet.TRANG_THAI_PHIEU);

        setTienTraNCC(chi_tiet.TIEN_TRA_NCC);
        setGhiChu(chi_tiet.GHI_CHU);
        setDsThuoc(
          data_thuoc.map((item, index) => {
            return {
              ...item,
              MA_LO: item.data_LO_THUOC[0].MA_LO,
              arr_LO_THUOC: item.data_LO_THUOC,
              STT: index + 1,
              key: rid(),
            };
          })
        );
      }
    } catch (error) {}
  }

  const handleFocusDrugInput = () => {
    setTimeout(() => {
      drugRef.current?.focus();
    }, 100);
  };

  // hàm clear input tìm kiếm thuốc
  const handleClearDrugInput = () => {
    setDrugSearchString("");
  };

  // hàm xử lý tìm kiếm thuốc
  const handleSearchDrug = (e) => {
    if (!khoThuoc) {
      return;
    }
    const { value } = e.target;
    selectedThuoc && setSelectedThuoc();
    setDrugSearchString(value);
    searchDrugRef.current?.searchData(value, khoThuoc, ncc);
  };

  // hàm xử lý tìm kiếm thuốc
  const handleChangeSearch = (e) => {
    if (!khoThuoc) {
      return;
    }
    const { value } = e.target;
    selectedThuoc && setSelectedThuoc();
    setDrugSearchString(value);
    //searchDrugRef.current?.searchData(value, khoThuoc, ncc);
  };

  function handleSelectThuoc(item) {
    if (selectedThuoc && (selectedThuoc.ID !== item.ID || selectedThuoc.LO_THUOC_ID !== item.LO_THUOC_ID)) {
      formThuoc.resetFields();
    }
    setSelectedThuoc(item);
    formThuoc.setFields([
      {
        name: fieldNames.Thuoc_Code,
        value: item.Thuoc_Code,
      },
      {
        name: fieldNames.Thuoc_Dvt,
        value: item.Thuoc_Dvt,
      },
      {
        name: fieldNames.GIA_BAN,
        value: item.GIA_BAN,
      },
      {
        name: fieldNames.MA_LO,
        value: item.MA_LO,
      },
      {
        name: fieldNames.HAN_SU_DUNG,
        value: convertDateToValue(item.HAN_SU_DUNG),
      },
      {
        name: fieldNames.GIA_NHAP,
        value: item.GIA_NHAP,
      },
    ]);
  }

  // thêm thuốc vào bảng và giữ chỗ trên server
  async function submitFormThuoc(value) {
    console.log("value", value);

    if (!selectedThuoc) {
      notification.warning({ message: i18n.t(languageKeys.duoc_chua_chon_thuoc) });
      return;
    }
    if (!soLuong) {
      setErrorSL(true);
      notification.warning({ message: "Nhập số lượng" });
      return;
    }
    // if (soLuong > selectedThuoc.TON_CUOI){
    //     setErrorSL(true)
    // }
    if (soLuong > selectedThuoc.TON_CUOI) {
      setErrorSL(true);
      notification.warning({ message: `${i18n.t(languageKeys.duoc_so_luong_toi_da)} ${selectedThuoc.TON_CUOI}` });
      formThuoc.setFields([
        {
          name: fieldNames.SO_LUONG,
          errors: [i18n.t(languageKeys.duoc_so_luong_lon_hon_ton_kha_dung)],
        },
      ]);
      return;
    }
    let checkTonTai = dsThuoc.some((item) => item.SMART_KEY === selectedThuoc.SMART_KEY);

    if (!isEdit && checkTonTai) {
      notification.warning({ message: i18n.t(languageKeys.duoc_thuoc_da_ton_tai) });
      return;
    }
    setLoadingButtonAdd(true);
    let urlApi = checkTonTai ? apis.cap_nhat_slot_thuoc_tam_thoi : apis.them_slot_thuoc_tam_thoi;
    try {
      const req = {
        partner_code: userProfile.partner_code,
        PHIEU_TAM_THOI: idLocal,
        KHO_THUOC_ID: khoThuoc.ID,
        LO_THUOC_ID: selectedThuoc.LO_THUOC_ID,
        THUOC_ID: selectedThuoc.Thuoc_ID,
        SO_LUONG: value.SO_LUONG,
        NHANSU_ID: userProfile.NHANSU_ID,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        THUOC_TRONG_KHO_ID: selectedThuoc.THUOC_TRONG_KHO_ID,
        SMART_KEY: selectedThuoc.SMART_KEY,
      };
      let response = await common_post(urlApi, req);
      if (response && response.status === "OK") {
        setLoadingButtonAdd(false);
        if (checkTonTai) {
          let array = dsThuoc.map((item) => {
            if (item.SMART_KEY === selectedThuoc.SMART_KEY) {
              return {
                ...item,
                SO_LUONG: value.SO_LUONG,
                THANH_TIEN: value.SO_LUONG * selectedThuoc.GIA_NHAP,
              };
            }
            return item;
          });
          setDsThuoc(array);
        } else {
          setDsThuoc(
            dsThuoc.concat({
              ...selectedThuoc,
              SO_LUONG: value.SO_LUONG,
              THANH_TIEN: value.SO_LUONG * selectedThuoc.GIA_NHAP,
              STT: dsThuoc.length + 1,
              THU_TU: dsThuoc.length + 1,
            })
          );
        }
        setSelectedThuoc();
        setDrugSearchString("");
        searchDrugRef.current?.getDataSource();
        formThuoc.resetFields();
        isEdit && setIsEdit(false);
        setSoLuong(0);
      } else if (response && response.status === "KO" && response.error_code === "003") {
        setLoadingButtonAdd(false);
        notification.warning({ message: i18n.t(languageKeys.duoc_so_luong_lon_hon_ton_kha_dung) });
      }
    } catch (error) {}
  }
  // xóa thuốc khỏi  bảng và xóa giữ chỗ
  async function handleRemoveThuoc(record) {
    setLoadingTable(true);
    let req = {
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
      PHIEU_TAM_THOI: idLocal,
      LO_THUOC_ID: record.LO_THUOC_ID,
      THUOC_ID: record.Thuoc_ID,
      THUOC_TRONG_KHO_ID: record.THUOC_TRONG_KHO_ID,
      SMART_KEY: record.SMART_KEY,
    };
    try {
      const response = await common_post(apis.xoa_slot_thuoc_tam_thoi, req);
      if (response && response.status === "OK") {
        let newArr = dsThuoc.filter((item) => item.SMART_KEY !== record.SMART_KEY);
        setDsThuoc(newArr);
        searchDrugRef.current?.getDataSource();
      }
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
    }
  }

  //
  async function handleTaoPhieu(data) {
    if (dsThuoc.length === 0) {
      notification.warning({ message: i18n.t(languageKeys.duoc_chua_nhap_thuoc) });
      return;
    }
    setLoading(true);
    let time = data.NGAY;
    let NGAY = moment(time).format("YYYYMMDD");
    let GIO = moment(time).format("HH:mm");
    let dataRequest = {
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
      NHANSU_ID: userProfile.NHANSU_ID,
      ...data,
      KHO_THUOC_ID: JSON.parse(data.KHO_THUOC_ID).ID,
      PHIEU_TAM_THOI: idLocal,
      PHAN_LOAI: typePhieu,
      GHI_CHU: ghiChu,
      NGAY: NGAY,
      GIO: GIO,
      LY_DO: data.LY_DO ? data.LY_DO : null,
      TONG_CHI_PHI: tinhTongTien(),
      TONG_MIEN_GIAM: tinhTienGiam(),
      TONG_THANH_TOAN: tinhTienTraNCC(),
      GIAM_TIEN: typeGiam === TYPE_MIEN_GIAM.TIEN ? valueGiam : null,
      GIAM_PHAN_TRAM: typeGiam === TYPE_MIEN_GIAM.PHAN_TRAM ? valueGiam : null,
      TIEN_TRA_NCC: tienTraNCC,
      TIEN_CONG_NO: typePhieu === "XUAT_TRA_NCC" ? tinhCongNo() : null,
      arr_THUOC: dsThuoc.map((item) => ({
        ...item,
        arr_LO_THUOC: [{ LO_THUOC_ID: item.LO_THUOC_ID, SO_LUONG: item.SO_LUONG, THANH_TIEN: item.THANH_TIEN, SMART_KEY: item.SMART_KEY }],
      })),
    };
    try {
      const response = await common_post(apis.luu_phieu_xuat_thuoc, dataRequest);
      if (response && response.status === "OK") {
        setLoading(false);
        if (print) {
          handlePrint(response.ID);
        }
        setIdLocal("");
        doAfterSuccess();
      } else if (response && response.status === "KO" && response.error_code === "001" && response.field === "MA_PHIEU") {
        notification.error({
          message: i18n.t(languageKeys.ma_phieu_bi_trung),
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {}
  }

  function onEditItem(item) {
    setDrugSearchString(item.Thuoc_Ten);
    setSelectedThuoc(item);
    setIsEdit(true);
    formThuoc.setFields([
      {
        name: fieldNames.Thuoc_Code,
        value: item.Thuoc_Code,
      },
      {
        name: fieldNames.Thuoc_Dvt,
        value: item.Thuoc_Dvt,
      },
      {
        name: fieldNames.GIA_BAN,
        value: item.GIA_BAN,
      },
      {
        name: fieldNames.MA_LO,
        value: item.MA_LO,
      },
      {
        name: fieldNames.HAN_SU_DUNG,
        value: convertDateToValue(item.HAN_SU_DUNG),
      },
      {
        name: fieldNames.SO_LUONG,
        value: item.SO_LUONG,
      },
      {
        name: fieldNames.GIA_NHAP,
        value: item.GIA_NHAP,
      },
      {
        name: fieldNames.THANH_TIEN,
        value: item.THANH_TIEN,
      },
    ]);
  }

  const columns = [
    {
      title: i18n.t(languageKeys.field_Stt),
      dataIndex: "STT",
      key: "STT",
      width: 70,
    },
    {
      title: i18n.t(languageKeys.field_Ma_thuoc),
      dataIndex: "Thuoc_Code",
      key: "Thuoc_Code",
      width: 100,
    },
    {
      title: i18n.t(languageKeys.field_Ten_thuoc),
      dataIndex: "Thuoc_Ten",
      key: "Thuoc_Ten",
      width: 220,
    },
    {
      title: i18n.t(languageKeys.field_Don_vi),
      dataIndex: "Thuoc_Dvt",
      key: "Thuoc_Dvt",
      //width : 100
    },
    {
      title: i18n.t(languageKeys.field_ma_lo),
      dataIndex: "MA_LO",
      key: "MA_LO",
      //width : 100
    },
    {
      title: i18n.t(languageKeys.sl),
      dataIndex: "SO_LUONG",
      key: "SO_LUONG",
      //width : 100
    },

    {
      title: typePhieu === "BAN_BENH_NHAN" ? i18n.t(languageKeys.field_Gia_ban) : i18n.t(languageKeys.field_Gia_nhap),
      dataIndex: "GIA_NHAP",
      key: "GIA_NHAP",
      render: (GIA_NHAP, record) => (typePhieu === "BAN_BENH_NHAN" ? formatNumberToPrice(record.GIA_BAN) : formatNumberToPrice(record.GIA_NHAP)),
      //width : 120
    },

    {
      title: i18n.t(languageKeys.vien_phi_thanh_tien),
      dataIndex: "THANH_TIEN",
      key: "THANH_TIEN",
      render: (THANH_TIEN) => formatNumberToPrice(THANH_TIEN),
      //width : 120
    },
  ];

  function handleClearFormThuoc() {
    formThuoc.resetFields();
    setSelectedThuoc();
  }

  function tinhTongTien() {
    return dsThuoc.reduce((acc, item) => acc + item.THANH_TIEN, 0);
  }

  /// tiến số tiền được miễn giảm
  function tinhTienGiam() {
    let tong_tien = tinhTongTien();

    if (!valueGiam) {
      return 0;
    }
    if (typeGiam === TYPE_MIEN_GIAM.TIEN) {
      return valueGiam;
    } else {
      return Math.round((tong_tien / 100) * valueGiam);
    }
  }

  // tính tổng tiền trả chho nhà cung cấp
  function tinhTienTraNCC() {
    let tong_tien = tinhTongTien();
    return tong_tien - tinhTienGiam();
  }

  function tinhCongNo() {
    let tien_phai_tra = tinhTienTraNCC();
    if (!tienTraNCC) {
      return tien_phai_tra;
    } else {
      return tien_phai_tra - tienTraNCC;
    }
  }

  function getTitleTaoPhieu(type) {
    let titlePhieu = "";
    switch (type) {
      case "XUAT_TRA_NCC":
        titlePhieu = i18n.t(languageKeys.duoc_phieu_xuat_tra_ncc);
        break;
      case "XUAT_VO_HONG":
        titlePhieu = i18n.t(languageKeys.duoc_phieu_xuat_vo_hong);
        break;
      case "XUAT_HUY":
        titlePhieu = i18n.t(languageKeys.duoc_phieu_xuat_huy);
        break;
      case "XUAT_HET_HAN":
        titlePhieu = i18n.t(languageKeys.duoc_phieu_xuat_het_han);
        break;
      case "XUAT_KHAC":
        titlePhieu = i18n.t(languageKeys.duoc_phieu_xuat_khac);
        break;
      case "BAN_BENH_NHAN":
        titlePhieu = i18n.t(languageKeys.duoc_phieu_ban_benh_nhan);
        break;
      default:
    }
    return `${readOnly ? i18n.t(languageKeys.chi_tiet) : i18n.t(languageKeys.common_Them_moi)} ` + titlePhieu;
  }
  async function handlePrint(PHIEU_THUOC_ID) {
    const dataRequest = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_THUOC_ID: PHIEU_THUOC_ID,
    };
    const api = typePhieu === "XUAT_TRA_NCC" ? apis.xuat_tra_pdf : apis.xuat_huy_pdf;
    pdfRef.current.openModal(dataRequest, api);
  }

  // hủy slot giữ chỗ khi tắt phiếu
  async function handleHuySlotThuoc() {
    setLoading(true);
    let dataRequest = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_TAM_THOI: idLocal,
    };
    let response = await common_post(apis.huy_phieu_giu_cho, dataRequest);
    if (response && response.status === "OK") {
      setLoading(false);
      setIdLocal();
      setVisible(false);
    }
  }
  async function handleTaoIdPhieuMoi() {
    if (dsThuoc.length > 0) {
      let dataRequest = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        PHIEU_TAM_THOI: idLocal,
      };
      let response = await common_post(apis.huy_phieu_giu_cho, dataRequest);
      if (response && response.status === "OK") {
        setIdLocal(genIDOnlyNumber(30, ""));
      }
    }
  }

  async function handleHuyPhieu() {
    let req = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_THUOC_ID: phieuID,
      NGUOI_HUY_ID: userProfile.NHANSU_ID,
      PHAN_LOAI: typePhieu,
    };
    try {
      confirmRef.current.loading();
      const res = await common_post(apis.huy_phieu_nhap_xuat, req);
      if (res && res.status === "OK") {
        confirmRef.current.close();
        doAfterSuccess();
      } else if (res && res.status === "KO") {
        confirmRef.current.close();
        notification.warning({ message: handleErrorHuyPhieu(res.error_code || null) });
      }
    } catch (error) {}
  }

  // xử lý event phím tắt
  const keydownEvent = useCallback(
    (event) => {
      const stopDefaultAction = (e) => {
        e.stopPropagation();
        e.preventDefault();
      };
      // event thêm thuốc
      if (!readOnly) {
        if ((event.ctrlKey || event.metaKey) && (event.keyCode === 78 || event.key === "a")) {
          stopDefaultAction(event);
          formThuoc.submit();
        } else if ((event.ctrlKey || event.metaKey) && event.key === "d") {
          // clear thông tin đang nhập
          stopDefaultAction(event);
          handleClearFormThuoc();
        } else if ((event.ctrlKey || event.metaKey) && event.key === "s") {
          // clear thông tin đang nhập
          stopDefaultAction(event);
          formPhieu.submit();
        } else if ((event.ctrlKey || event.metaKey) && event.key === "l") {
          setTimeout(async () => setPrint(true), 500);
          stopDefaultAction(event);
          formPhieu.submit();
        }
      } else {
        if (event.ctrlKey && event.key === "i") {
          handlePrint(phieuID);
        }
      }
    },
    [readOnly, phieuID]
  );

  useEventListener("keydown", keydownEvent, window.document, visible);
  return (
    <FormDrawer
      visible={visible}
      title={getTitleTaoPhieu(typePhieu)}
      centered={true}
      onCancel={handleCloseModal}
      onOk={() => formPhieu.submit()}
      hiddenTopAction={true}
      // showPlusIcon={!readOnly}
      width={widthDrawerKeys.large}
      footer={
        <Row justify="end" style={{ backgroundColor: "#FFF", zIndex: 999 }}>
          <Col style={{ marginRight: "10px" }}>
            <Button onClick={handleCloseModal} type="primary" ghost>
              {i18n.t(languageKeys.common_Thoat)}
              {" (ESC)"}
            </Button>
          </Col>
          {readOnly && (
            <Col style={{ marginRight: "10px" }}>
              <Button onClick={() => confirmRef.current.open()} type="primary" ghost danger disabled={typePhieu === "BAN_BENH_NHAN" ? true : isDaHuy}>
                {i18n.t(languageKeys.huy_phieu)}
              </Button>
            </Col>
          )}
          <Row justify="end">
            {readOnly ? (
              <>
                {/* {typePhieu === "BAN_BENH_NHAN" && trangThaiPhieu === "HOAN_THANH" && <Col style={{paddingRight : "10px"}}> */}

                {
                  <Col style={{ paddingRight: "10px" }}>
                    <TooltipButton title={disableInPhieu ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                      <Button
                        icon={<PrinterFilled />}
                        type="primary"
                        onClick={() => handlePrint(phieuID)}
                        disabled={
                          disableInPhieu || trangThaiPhieu === "HUY" || (typePhieu === "BAN_BENH_NHAN" && trangThaiPhieu !== "HOAN_THANH") || isDaHuy
                        }
                      >
                        <span>
                          {i18n.t(languageKeys.field_In_phieu)}
                          {" (Ctrl + I)"}
                        </span>
                      </Button>
                    </TooltipButton>
                  </Col>
                }
              </>
            ) : (
              <Col style={{ paddingRight: "10px" }}>
                <Button
                  icon={<PrinterFilled />}
                  type="primary"
                  onClick={() => {
                    setPrint(true);
                    formPhieu.submit();
                  }}
                  loading={loading}
                >
                  <span>
                    {i18n.t(languageKeys.common_Luu_va_In)} {" (Ctrl + L)"}
                  </span>
                </Button>
              </Col>
            )}

            {!readOnly && (
              <Col style={{ paddingRight: "10px" }}>
                <Button onClick={() => formPhieu.submit()} type="primary" loading={loading}>
                  {i18n.t(languageKeys.common_Luu)}
                  {" (Ctrl + S)"}
                </Button>
              </Col>
            )}
          </Row>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <div className={styles.container}>
          <Form form={formPhieu} layout="vertical" onFinish={handleTaoPhieu}>
            <Row style={{ paddingInline: 10 }}>
              <Col span={5}>
                <Item
                  label={i18n.t(languageKeys.field_Kho_thuoc)}
                  name="KHO_THUOC_ID"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    className={styles.input}
                    dataSource={dsKhoThuoc}
                    titleKey="TEN_KHO"
                    // valueKey="ID"
                    onSelect={(item) => {
                      let paserItem = JSON.parse(item);
                      if (khoThuoc && paserItem.ID === khoThuoc.ID) {
                        return;
                      } else {
                        setKhoThuoc(JSON.parse(item));
                        formThuoc.resetFields();
                        selectedThuoc && setSelectedThuoc();
                        dsThuoc.length > 0 && setDsThuoc([]);
                        setDrugSearchString("");
                        handleTaoIdPhieuMoi();
                        if (typePhieu === "XUAT_TRA_NCC") {
                          if (ncc) {
                            searchDrugRef.current?.searchData("", JSON.parse(item), ncc);
                          }
                        } else {
                          searchDrugRef.current?.searchData("", JSON.parse(item), "");
                        }
                      }
                    }}
                    disabled={readOnly}
                    showSearch
                    onSearch={handleSearchKhoThuoc}
                  />
                </Item>
              </Col>

              <Col span={5}>
                <Item
                  label={i18n.t(languageKeys.field_Ngay_tao)}
                  name="NGAY"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  initialValue={moment()}
                >
                  <DatePicker
                    className={styles.input}
                    style={{ width: "100%" }}
                    defaultValue={moment()}
                    showTime={{ format: "HH:mm" }}
                    format="DD/MM/YYYY - HH:mm"
                    disabled
                  />
                </Item>
              </Col>

              {typePhieu === "XUAT_TRA_NCC" && (
                <Col span={5}>
                  <Item label={i18n.t(languageKeys.ten_nha_cung_cap)} name="NCC_THUOC_ID" rules={rulesItem}>
                    <Select
                      className={styles.input}
                      dataSource={dsNCC}
                      titleKey="TEN_NCC"
                      valueKey="ID"
                      disabled={readOnly}
                      onSelect={setNcc}
                      showSearch
                      onSearch={handleSearchNCC}
                    />
                  </Item>
                </Col>
              )}

              <Col span={5}>
                <Item
                  label={i18n.t(languageKeys.nguoi_tao)}
                  name={"NGUOI_TAO"}
                  initialValue={userProfile.HO + " " + userProfile.TEN}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input className={styles.input} readOnly value={userProfile.HO + " " + userProfile.TEN} />
                </Item>
              </Col>

              <Col span={4}>
                <Item label={i18n.t(languageKeys.ma_phieu)} name="MA_PHIEU">
                  <Input className={styles.input} placeholder={i18n.t(languageKeys.placeholder_Tao_tu_dong)} readOnly={readOnly} />
                </Item>
              </Col>

              {typePhieu === "XUAT_KHAC" && (
                <Col span={5}>
                  <Item label={i18n.t(languageKeys.duoc_ly_do_xuat)} name={fieldNames.LY_DO}>
                    {/* <Select dataSource={dsLyDo} titleKey="LY_DO" valueKey={"LY_DO"} disabled={readOnly} /> */}
                    <AutoComplete
                      options={dsLyDo.map(i => ({ value: i.LY_DO }))}
                      disabled={readOnly}
                      placeholder={i18n.t(languageKeys.common_Nhap)}
                      allowClear
                      filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />
                  </Item>
                </Col>
              )}
            </Row>
          </Form>
          <Divider style={{ marginBlock: "5px" }} />
          {!readOnly && (
            <Form form={formThuoc} onFinish={submitFormThuoc} layout="vertical" style={{ marginTop: "0px" }}>
              <Row className={styles.infoDrug} align="bottom">
                <Col span={10} style={{ marginBottom: "5px" }}>
                  <SearchThuocXuatKho
                    ref={searchDrugRef}
                    onAddDrug={handleSelectThuoc}
                    focusInput={handleFocusDrugInput}
                    clearInput={handleClearDrugInput}
                    kho_thuoc={khoThuoc}
                    NCC_THUOC_ID={ncc}
                    typePhieu={typePhieu}
                  >
                    <Input
                      value={selectedThuoc ? selectedThuoc.Thuoc_Ten : drugSearchString}
                      onChange={handleChangeSearch}
                      onPressEnter={handleSearchDrug}
                      prefix={<SearchOutlined className="blue-txt" />}
                      placeholder={i18n.t(languageKeys.common_Tim_kiem)}
                      ref={drugRef}
                      onFocus={() => {
                        if (!khoThuoc) {
                          formPhieu.setFields([
                            {
                              name: "KHO_THUOC_ID",
                              errors: [i18n.t(languageKeys.txt_chon_kho)],
                            },
                          ]);
                          notification.warning({ message: i18n.t(languageKeys.duoc_chua_chon_kho_thuoc) });
                        }
                        if (typePhieu === "XUAT_TRA_NCC" && !ncc) {
                          formPhieu.setFields([
                            {
                              name: "NCC_THUOC_ID",
                              errors: [i18n.t(languageKeys.txt_chon_kho)],
                            },
                          ]);
                          notification.warning({ message: i18n.t(languageKeys.duoc_chon_ncc) });
                        }
                      }}
                      allowClear
                    />
                  </SearchThuocXuatKho>
                </Col>

                <Col span={2}>
                  <Item label={i18n.t(languageKeys.field_Ma_thuoc)} name={fieldNames.Thuoc_Code}>
                    <Input className={styles.input} readOnly />
                  </Item>
                </Col>
                <Col span={3}>
                  <Item label={i18n.t(languageKeys.field_Don_vi)} name={fieldNames.Thuoc_Dvt}>
                    <Input className={styles.input} readOnly />
                  </Item>
                </Col>
                <Col span={3}>
                  <Item label={i18n.t(languageKeys.field_Gia_ban)} name={fieldNames.GIA_BAN}>
                    <Input className={styles.input} readOnly />
                  </Item>
                </Col>
                <Col span={3}>
                  <Item label={i18n.t(languageKeys.field_ma_lo)} name={fieldNames.MA_LO}>
                    <Input className={styles.input} readOnly placeholder={i18n.t(languageKeys.common_Nhap)} />
                  </Item>
                </Col>

                <Col span={3}>
                  <DateInput name={fieldNames.HAN_SU_DUNG} label={i18n.t(languageKeys.field_han_su_dung)} form={formThuoc} readOnly />
                </Col>
                <Col span={5}>
                  <Item
                    label={
                      <p style={{ fontWeight: "bold" }}>
                        {i18n.t(languageKeys.field_So_luong)} <span style={{ color: "red" }}>*</span>
                      </p>
                    }
                    name={fieldNames.SO_LUONG}
                  >
                    <InputNumber
                      className={styles.input}
                      min={0}
                      style={{ width: "100%", borderColor: errorSL ? "#ff4d4f" : "transparent" }}
                      onChange={(value) => {
                        errorSL && setErrorSL(false);
                        setSoLuong(value);
                        if (selectedThuoc) {
                          // setThanhTien(value * selectedThuoc.GIA_NHAP)
                          formThuoc.setFields([
                            {
                              name: fieldNames.THANH_TIEN,
                              value: value * selectedThuoc.GIA_NHAP,
                            },
                          ]);
                        }
                      }}
                    />
                  </Item>
                </Col>
                <Col span={5}>
                  <Item label={i18n.t(languageKeys.field_Gia_nhap)} name={fieldNames.GIA_NHAP}>
                    <InputNumber
                      style={{ width: "100%" }}
                      readOnly
                      formatter={formatNumberToPrice}
                      parser={currencyParserNotAlowNegativeNum}
                      className={styles.input}
                    />
                  </Item>
                </Col>

                <Col span={5}>
                  <Item label={i18n.t(languageKeys.vien_phi_thanh_tien)} name={fieldNames.THANH_TIEN}>
                    <InputNumber
                      style={{ width: "100%" }}
                      readOnly
                      formatter={formatNumberToPrice}
                      parser={currencyParserNotAlowNegativeNum}
                      className={styles.input}
                    />
                  </Item>
                </Col>
                <Col span={5}>
                  <Item label={i18n.t(languageKeys.field_Ghi_chu)} name="GHI_CHU" style={{ marginBottom: 0 }}>
                    <Input className={styles.input} placeholder={i18n.t(languageKeys.common_Nhap)} />
                  </Item>
                </Col>
                <Col span={4} style={{ marginBottom: "5px" }}>
                  <Button type="primary" style={{ width: "100%" }} onClick={() => formThuoc.submit()} loading={loadingButtonAdd}>
                    {i18n.t(languageKeys.them)} {`(Ctrl + A)`}
                  </Button>
                </Col>
                {/* <Col span={4}>
                                <Button type="ghost" danger style={{ width: "100%" }} onClick={handleClearFormThuoc}>
                                    Xóa {`(Ctrl + D)`}
                                </Button>
                            </Col> */}
              </Row>
            </Form>
          )}
          <Row style={{ marginTop: "10px", flex: 1 }} wrap={false}>
            <Col flex={"auto"} style={{ backgroundColor: "#F5F7FB", padding: "10px 15px 0px 20px" }}>
              <h4>{i18n.t(languageKeys.field_Danh_sach_thuoc)}</h4>
              <Table
                dataSource={dsThuoc}
                loading={loadingTable}
                columns={
                  readOnly
                    ? columns
                    : columns.concat([
                      {
                        title: "",
                        key: "",
                        dataIndex: "",
                        width: 60,
                        render: (_, record) => (
                          <Delete
                            className={styles.del_icon}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveThuoc(record);
                            }}
                          />
                        ),
                      },
                    ])
                }
                onClickRow={(item) => !readOnly && onEditItem(item)}
                scroll={{ y: !readOnly ? "calc(100vh - 440px)" : "calc(100vh - 280px)" }}
              />
            </Col>

            {typePhieu === "XUAT_TRA_NCC" ? (
              // THÔNG TIN THANH TOÁN PHIẾU TRẢ NHÀ CUNG CẤP
              <Col flex={"300px"} style={{ marginRight: 5 }}>
                <div className={readOnly ? styles.payment_readOnly : styles.payment}>
                  <h4>{i18n.t(languageKeys.field_Thanh_toan)}</h4>
                  <Divider style={{ marginBlock: 10 }} />

                  <Row justify="space-between" style={{ marginTop: "10px" }}>
                    <Col>
                      <h5>{i18n.t(languageKeys.field_Tong_chi_phi)}:</h5>
                    </Col>
                    <Col>
                      <h5 className={styles.money}>{formatNumberToPrice(tinhTongTien())}</h5>
                    </Col>
                  </Row>

                  <Row justify="space-between" style={{ marginTop: 10 }} align="middle">
                    <Col span={10}>
                      <h5>{i18n.t(languageKeys.field_Mien_giam)}:</h5>
                    </Col>
                    <Col span={14}>
                      <InputNumber
                        min={0}
                        //max={typeGiam === TYPE_MIEN_GIAM.PHAN_TRAM ? 100 : Number.MAX_SAFE_INTEGER}
                        formatter={typeGiam === TYPE_MIEN_GIAM.TIEN ? formatNumberToPrice : (value) => `${value}`}
                        parser={typeGiam === TYPE_MIEN_GIAM.TIEN ? currencyParserNotAlowNegativeNum : (value) => value && value.replace("%", "")}
                        addonAfter={
                          <SelectAntd
                            defaultValue={typeGiam}
                            value={typeGiam}
                            style={{ width: 60 }}
                            onChange={(value) => {
                              setTypeGiam(value);
                              setValueGiam(0);
                            }}
                          >
                            <SelectAntd.Option value={TYPE_MIEN_GIAM.TIEN}>đ</SelectAntd.Option>
                            <SelectAntd.Option value={TYPE_MIEN_GIAM.PHAN_TRAM}>%</SelectAntd.Option>
                          </SelectAntd>
                        }
                        value={valueGiam}
                        onChange={(val) => {
                          if (valueGiam > 100) {
                            typeGiam === TYPE_MIEN_GIAM.PHAN_TRAM && setTypeGiam(TYPE_MIEN_GIAM.TIEN);
                          } else {
                            typeGiam === TYPE_MIEN_GIAM.TIEN && setTypeGiam(TYPE_MIEN_GIAM.PHAN_TRAM);
                          }
                          setValueGiam(val);
                        }}
                        style={{ width: "100%" }}
                        readOnly={readOnly}
                        controls={false}
                      />
                    </Col>
                  </Row>

                  <Row justify="space-between" style={{ marginTop: "10px" }}>
                    <Col>
                      <h5>{i18n.t(languageKeys.txt_ncc_phai_tra)}</h5>
                    </Col>
                    <Col>
                      <h5 className={styles.money_special}>{formatNumberToPrice(tinhTienTraNCC())}</h5>
                    </Col>
                  </Row>
                  <Divider style={{ marginBlock: 5 }} />
                  <h5 style={{ marginTop: "10px" }}>{i18n.t(languageKeys.txt_tien_ncc_tra)}</h5>
                  <InputNumber
                    width={"100%"}
                    style={{ marginTop: "10px", width: "100%" }}
                    formatter={formatNumberToPrice}
                    parser={currencyParserNotAlowNegativeNum}
                    onChange={setTienTraNCC}
                    value={tienTraNCC}
                    readOnly={readOnly}
                  />

                  <Row justify="space-between" style={{ marginTop: "10px" }}>
                    <Col>
                      <h5>{i18n.t(languageKeys.txt_tinh_vao_cong_no)}</h5>
                    </Col>
                    <Col span={24}>
                      <h5 className={tinhCongNo() >= 0 ? styles.money_special : styles.money_special_red}>
                        {formatNumberToPrice(Math.abs(tinhCongNo()))}
                      </h5>
                    </Col>
                  </Row>
                  <Divider style={{ marginBlock: 10 }} />
                  <h5>{i18n.t(languageKeys.field_Ghi_chu)}</h5>
                  <Input.TextArea rows={3} width={"100%"} onChange={(e) => setGhiChu(e.target.value)} value={ghiChu} readOnly={readOnly} />
                </div>
              </Col>
            ) : (
              // THÔNG TIN CÁC LOẠI PHIẾU KHÁC PHIẾU TRẢ NHÀ CUNG CẤP
              <Col flex={"220px"} style={{ marginRight: 5 }}>
                <div className={readOnly ? styles.payment_readOnly : styles.payment}>
                  <h4>{i18n.t(languageKeys.field_Thanh_toan)}</h4>
                  <Divider style={{ marginTop: "-5px" }} />

                  <Row justify="space-between" style={{ marginTop: "10px" }}>
                    <Col>
                      <h5>{i18n.t(languageKeys.field_Tong_chi_phi)}</h5>
                    </Col>

                    <Col>
                      <h5 className={styles.money_special}>{formatNumberToPrice(tinhTienTraNCC())}</h5>
                    </Col>
                  </Row>

                  <Divider />
                  <h5>{i18n.t(languageKeys.field_Ghi_chu)}</h5>
                  <Input.TextArea rows={5} width={"100%"} onChange={(e) => setGhiChu(e.target.value)} value={ghiChu} readOnly={readOnly} />
                </div>
              </Col>
            )}
          </Row>
        </div>
      </Spin>
      <ModalPDF ref={pdfRef} />
      <ConfirmModal
        danger={true}
        ref={confirmRef}
        content={i18n.t(languageKeys.confirm_cancel_ticket)}
        onOk={() => handleHuyPhieu()}
        onCancel={() => {
          confirmRef.current.close();
        }}
        okText={i18n.t(languageKeys.title_Xac_nhan)}
      />
    </FormDrawer>
  );
}

export default React.memo(React.forwardRef(ModalPhieuXuat));
const fieldNames = {
  MA_PHIEU: "MA_PHIEU",
  NGAY: "NGAY",
  GIO: "GIO",
  NCC_THUOC_ID: "",
  GHI_CHU: "GHI_CHU",
  Thuoc_Code: "Thuoc_Code",
  Thuoc_Dvt: "Thuoc_Dvt",
  GIA_BAN: "GIA_BAN",
  SO_LUONG: "SO_LUONG",
  GIA_NHAP: "GIA_NHAP",
  THANH_TIEN: "THANH_TIEN",
  MA_LO: "MA_LO",
  HAN_SU_DUNG: "HAN_SU_DUNG",
  VAT_PHAN_TRAM: "VAT_PHAN_TRAM",
  VAT_TIEN: "VAT_TIEN",
  VAT_THANH_TIEN: "VAT_THANH_TIEN",
  GIA_CO_VAT: "GIA_CO_VAT",
  LY_DO: "LY_DO",
};

const rulesItem = [
  {
    required: true,
    message: i18n.t(languageKeys.vui_long_nhap),
  },
];
