import { apiLayDSKhoThuoc } from "pages/QuanLyDuocNgoaiTru/KhoThuoc/apiKhoThuoc/apisKhoThuoc";
import { useEffect, useState } from "react";
import i18n, { languageKeys } from "../../../../../i18n";
import { useSelector } from "react-redux";
import { formPattern } from "helpers";
import { getSharedData } from "ducks/slices/ListData/SharedData.slice";

const useKeDonThuoc = ({ readOnlyKho, isVisibleKeDonThuoc, patientInfo, setDsKho, dataSource, setDataSource, disableAdd, importantKeys }) => {

    const { prescriptionForm } = useSelector(getSharedData)

    const [loadingKho, setLoadingKho] = useState(false);

    const [arrFixedUsageDay, setArrFixedUsageDay] = useState([]);
    const [arrFixedManual, setArrFixedManual] = useState([]);

    useEffect(() => {
        if (!readOnlyKho && !!isVisibleKeDonThuoc) getDsKhoThuoc();
    }, [isVisibleKeDonThuoc, readOnlyKho]);


    //
    const getDsKhoThuoc = async () => {
        setLoadingKho(true);
        try {
            const req = {
                limit: 100,
                page: 1,
                PHONG_ID: patientInfo?.PHONG_ID || "",
                LOAI_THUOC: "NHA_THUOC",
            };
            let res = await apiLayDSKhoThuoc(req);
            if (res.status === "OK") {
                const listKho = res.result;
                setDsKho(listKho);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingKho(false);
        }
    };


    //
    const generateChiDan = (values) => {

        let result = "";

        if (values.TAN_SUAT_NGAY === 1 || !values.TAN_SUAT_NGAY) {
            result = `${values.Duongdung ? `${values.Duongdung},` : ""}
            ${values.Lieuluong ? `${i18n.t(languageKeys.field_Moi_ngay).toLowerCase()} ${values.Lieuluong} ${values.Thuoc_Dvt_Cach_Dung?.toLowerCase()},` : ""}
            ${values.Tansuat ? `${i18n.t(languageKeys.field_Chia_lam).toLowerCase()} ${values.Tansuat} ${i18n.t(languageKeys.field_Lan).toLowerCase()},` : ""}
            ${values.ThoiDiemDung ? `${values.ThoiDiemDung.toLowerCase()},` : ""}
            ${values.SoNgaySD ? `${i18n.t(languageKeys.su_dung)} ${values.SoNgaySD} ${i18n.t(languageKeys.common_ngay).toLowerCase()}` : ""}
          `;
        } else {
            result = `
            ${values.Duongdung ? `${values.Duongdung},` : ""}
            ${values.TAN_SUAT_NGAY} ${i18n.t(languageKeys.common_ngay).toLowerCase()}/${i18n.t(languageKeys.field_Lan).toLowerCase()},
            ${values.Lieuluong ? `${i18n.t(languageKeys.field_Moi_ngay).toLowerCase()} ${values.Lieuluong} ${values.Thuoc_Dvt_Cach_Dung?.toLowerCase()},` : ""}
            ${values.Tansuat ? `${i18n.t(languageKeys.field_Chia_lam).toLowerCase()} ${values.Tansuat} ${i18n.t(languageKeys.field_Lan).toLowerCase()},` : ""}
            ${values.ThoiDiemDung ? `${values.ThoiDiemDung.toLowerCase()},` : ""}
            ${values.SoNgaySD ? `${i18n.t(languageKeys.su_dung)} ${values.SoNgaySD} ${i18n.t(languageKeys.common_ngay).toLowerCase()}` : ""}
          `;
        }

        result = result.replace(/\s+/g, ' ').trim()
        if (result.slice(-1) === ",") result = result.slice(0, -1)

        return result;
    };


    //
    const calcSoNgaySD = (values) => {
        if (values.Thuoc_SL && values.Lieuluong) {
            if (Number(values.Thuoc_SL) <= Number(values.Lieuluong)) return 1;
            if (values.TAN_SUAT_NGAY) {
                return Math.ceil(values.Thuoc_SL / values.Lieuluong * values.TAN_SUAT_NGAY)
            } else {
                return Math.ceil(values.Thuoc_SL / values.Lieuluong)
            }
        } else {
            return;
        }
    }


    //
    const handleChangeInput = (value, rowIndex, keyVal) => {

        // Check isNumber
        if (keyVal === "SoNgaySD" || keyVal === "Lieuluong" || keyVal === "Tansuat") {
            if (!formPattern.numberOnly.test(value)) return;
            if (Number(value) < 1 && value !== "") return
        }

        // Change source
        setDataSource((currentArr) => {
            let newArr = []
            newArr = currentArr.map((item, index) =>
                index === rowIndex
                    ? { ...item, [keyVal]: value }
                    : item
            )

            // Default form only
            if (prescriptionForm === 1) {

                // Check manually edit usage day
                if (keyVal === "SoNgaySD") {
                    if (value !== calcSoNgaySD(newArr[rowIndex])) {
                        setArrFixedUsageDay([...arrFixedUsageDay, newArr[rowIndex].Thuoc_ID])
                    }
                } else {
                    if (!arrFixedUsageDay.includes(newArr[rowIndex].Thuoc_ID)) {
                        newArr[rowIndex].SoNgaySD = calcSoNgaySD(newArr[rowIndex])
                    }
                }

                // Check manually edit manual
                if (keyVal === "Chidan") {
                    if (value !== generateChiDan(newArr[rowIndex])) {
                        setArrFixedManual([...arrFixedManual, newArr[rowIndex].Thuoc_ID])
                    }
                } else {
                    if (!arrFixedManual.includes(newArr[rowIndex].Thuoc_ID)) {
                        newArr[rowIndex].Chidan = generateChiDan(newArr[rowIndex])
                    }
                }
            }

            // Push new empty record
            if (
                importantKeys[prescriptionForm]?.some(i => i === keyVal) &&
                !newArr.some(record => !importantKeys[prescriptionForm]?.every(i => record.hasOwnProperty(i))) &&
                rowIndex + 1 === dataSource.length &&
                !disableAdd
            ) {
                newArr.push({ KHO_THUOC_ID: newArr[rowIndex]?.KHO_THUOC_ID })
            }

            return newArr
        });
    }


    return {
        handleChangeInput, loadingKho, setArrFixedUsageDay, setArrFixedManual
    }
}

export default useKeDonThuoc;