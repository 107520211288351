import { apis, keys } from "../../../../constants";
import { common_post } from "../../../../helpers";

//api lấy danh sách kho thuốc hiện có
export const layDsKhoThuoc = async ({
  BENH_VIEN_ID,
  search_string,
  page,
  partner_code,
  limit = keys.limit,
}) => {
  let body = {
    BENH_VIEN_ID,
    partner_code,
    search_string,
    page,
    limit: keys.limit,
    LOAI_TRU: []
  };

  try {
    let res = await common_post(apis.lay_ds_kho_thuoc, body, false);
    if (res && res.status === "OK") {
      return res;
    } else {
      return null
    }

  } catch (error) {
    return null
  }
};

export const apiSuaKho = async (data) => {
  let dataRequest = {
    partner_code: data.data.partner_code,
    BENH_VIEN_ID: data.data.BENH_VIEN_ID,
    arr_ID: [data.data.ID]
  }
  try {
    let res = await common_post(apis.khoa_kho_thuoc_theo_ds_id, dataRequest, false);
    if (res && res.status === "OK") {
      return res;
    } else {
      return null
    }

  } catch (error) {
    return null
  }
}