import { forwardRef, useImperativeHandle, useState } from "react";
import { Col, Row, Divider, Modal, Button } from "antd";
import { useEventListener } from "hooks";
import i18n, { languageKeys } from "../../../../../../i18n"
const ModalCheckDuplicate = ({ onClose = () => {}, onOk = () => {}, loading },ref) => {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');
    const [tempData, setTempData] = useState({});

    useImperativeHandle(ref,() => {
        return {
            open: handleOpen,
            close: handleClose,
        }
    })

    const handleOpen = (props) => {

        if(props.content) {
            setContent(props.content);
        }
        setTempData(props)
        setOpen(!!1);
    }

    const handleClose = () => {
        setOpen(!!0);
        onClose(tempData);
        setTempData({});
    }
    
    // hàm xử lý phím tắt
    function keydownEvent(event) {
      if (!open) {
        return;
      }

      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        onOk(tempData);
      }
    }


    useEventListener("keydown", keydownEvent, window, open, 100);

  return (
    <Modal width={400} open={open} footer={null} onCancel={handleClose} closeIcon={<></>}>
      <h1 style={{textAlign:"center",marginTop:12}}>{i18n.t(languageKeys.common_Canh_bao)}</h1>
      <div style={{textAlign:"center",overflow:"auto"}}>{content}</div>
      <Divider />
      <Row justify={"center"} gutter={20}>
        <Col>
          <Button type="primary" ghost danger onClick={handleClose}>
            {i18n.t(languageKeys.common_Thoat)} (ESC)
          </Button>
        </Col>
        <Col>
          <Button type="primary" loading={loading} onClick={() => onOk(tempData)}>{i18n.t(languageKeys.title_Xac_nhan)} (Enter)</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(ModalCheckDuplicate);
