export const fieldDonThuoc = {
    ID:"ID",
    Thuoc_ID: "Thuoc_ID",
    Thuoc_Code: "Thuoc_Code",
    Thuoc_Ten: "Thuoc_Ten",
    Thuoc_Dvt: "Thuoc_Dvt",
    Thuoc_HoatChat: "Thuoc_HoatChat",
    MA_VACH: "MA_VACH",
    Duongdung_Code: "Duongdung_Code",
    Duongdung: "Duongdung",
    TON_CUOI: "TON_CUOI",
    GIA_NHAP: "GIA_NHAP",
    GIA_BAN: "GIA_BAN",
    NOI_BAT: "NOI_BAT",
    IS_CHO_PHEP_MIEN_GIAM:"IS_CHO_PHEP_MIEN_GIAM",
    HAM_LUONG: "HAM_LUONG",
    NONG_DO: "NONG_DO",
    IS_TAM_DUNG_NHAP_NCC: "IS_TAM_DUNG_NHAP_NCC",
    SO_LUONG: "SO_LUONG",
    MOI_NGAY: "MOI_NGAY",
    CHIA_LAM: "CHIA_LAM",
    CACH_DUNG: "CACH_DUNG",
    GHI_CHU: "GHI_CHU",
    LOI_DAN: "LOI_DAN",

};


export const fieldKhoThuoc = {
    ID:"ID",
    MA_KHO:"MA_KHO",
    TEN_KHO:"TEN_KHO",
    LOAI_KHO:"LOAI_KHO",
    LOAI_BENH_NHAN_AP_DUNG:"LOAI_BENH_NHAN_AP_DUNG",
    NGAY:"NGAY",
    GIO:"GIO",
    THOI_GIAN_TAO:"THOI_GIAN_TAO",
    KHOA:"KHOA",
    DUYET_KHI_KE_THUOC:"DUYET_KHI_KE_THUOC",
    DUYET_KHI_CHUYEN_CAC_KHO:"DUYET_KHI_CHUYEN_CAC_KHO",
    THU_KHO:"THU_KHO"
}

export const fieldThuocDVT = {
    ID_DVT: "ID_DVT",
    MA_DVT:"MA_DVT",
    TEN_DVT:"TEN_DVT",
    STT:"STT",
    GHI_CHU: "GHI_CHU",
    KHOA: "KHOA"
}