import { apis, keys } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import { fieldBenhVien } from "../fieldsDanhMuc";

/**
 * API lấy danh sách bệnh viện
 */
export const apiLayDsBenhVien = async ({
  BENH_VIEN_ID = "",
  page = 1,
  search_string = "",
  limit = keys.limit,
}) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      page,
      limit,
    };

    const response = await common_post(apis.dm_bv_lay_ds, requestData);

    return response;
  } catch (error) {
    HLog("Api lay danh sach benh vien error", error);
  }
};

/**
 * API nhập danh sách bệnh viện
 */
export const apiNhapDsBenhVien = async ({ BENH_VIEN_ID = "", data = [] }) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      data,
    };

    const response = await common_post(apis.dm_bv_them_moi, requestData);

    return response;
  } catch (error) {
    HLog("Api nhap ds benh vien error", error);
  }
};

/**
 * API thêm mới bệnh viện
 */
export const apiThemBenhVien = async ({ BENH_VIEN_ID = "", data = {} }) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      data: [data],
    };

    return await common_post(apis.dm_bv_them_moi, requestData);
  } catch (error) {
    HLog("Api them moi benh vien error", error);
  }
};

/**
 * API sửa bệnh viện
 */
export const apiSuaBenhVien = async ({ data = {} }) => {
  try {
    let requestData = { ...data };

    const response = await common_post(apis.dm_bv_cap_nhat, requestData);

    return response;
  } catch (error) {
    HLog("Api sua benh vien error", error);
  }
};

/**
 * API xóa bệnh viện
 */
export const apiXoaBenhVien = async ({ data = {} }) => {
  try {
    let requestData = {
      ...data,
      [fieldBenhVien.trang_thai]: 0,
    };

    HLog(requestData);

    const response = await common_post(apis.dm_bv_cap_nhat, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa benh vien error", error);
  }
};

/**
 * API xóa ds bệnh viện
 */
export const apiXoaDsBenhVien = async ({ data = [], partner_code = "" }) => {
  try {
    const listId = data.map((item) => item[fieldBenhVien.id]);

    let req = {
      listId,
      partner_code,
    };

    const response = await common_post(apis.dm_bv_xoa_ds, req);

    return response;
  } catch (error) {
    HLog("Api xoa danh sach benh vien error", error);
  }
};

/**
 * API khoá ds bệnh viện
 */
export const apiKhoaDsBenhVien = async ({
  data = [],
  partner_code = "",
  lock = false,
}) => {
  try {
    const listId = data.map((item) => item[fieldBenhVien.id]);

    let req = {
      listId,
      partner_code,
      isKhoa: !!lock ? 1 : 0,
    };

    const response = await common_post(apis.dm_bv_khoa_ds, req);

    return response;
  } catch (error) {
    HLog("Api khoa danh sach benh vien error", error);
  }
};
