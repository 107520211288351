import Icon, { HomeOutlined } from "@ant-design/icons";
import { Button, Col, Image, Modal, Row, Segmented, Space } from "antd";
import React, { useEffect, useState } from "react";
import { BangDieuKhien } from "../../../assets/HDSD";
import { PdfFile } from "../../../assets/img";
import { Download } from "../../../assets/svg";
import i18n, { languageKeys } from "../../../i18n";
import style from "./style.module.less";

const HDSDBangDieuKhien = (data) => {
  const [srcPdf, setSrcPdf] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (src) => {
    setIsModalOpen(true);
    console.log(src);
    console.log(isModalOpen);
    setSrcPdf(src);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  console.log(data, "okee");

  // const HeartIcon = (props) => <Icon component={HeartSvg} {...props} />;
  return (
    <div className={style["container"]}>
      <div className={style["content"]}>
        <div className={style["topBar"]}>
          <div className={style["leftTop"]}>
            <div className={style["title"]}>
              {i18n.t(languageKeys.huong_dan_su_dung_he_thong)} {data.data.name}
            </div>
            {/* <Segmented onChange={(e) => setSegment(e)} options={["Video", "PDF"]} /> */}
          </div>
          {/* <Button onClick={onButtonClick} type="primary" icon={<HeartIcon />}>
            {i18n.t(languageKeys.tai_pdf)}
          </Button> */}
        </div>
        <div className={style["line"]} />
        <div className={style["title"]} style={{ marginBlock: "15px", paddingInline: "18px" }}>
          PDF
        </div>
        <Row style={{ paddingInline: "18px" }} gutter={[15, 24]}>
          {data.data.pdf.map((item) => (
            <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={6}>
              <div className={style["PdfBox"]} onClick={() => showModal(item.src)}>
                {" "}
                <span>
                  {" "}
                  <Image src={PdfFile} preview={false} style={{ width: "24px", height: "24px", marginRight: "10px" }} />
                  {item.name}
                </span>
              </div>
            </Col>
          ))}
        </Row>
        <div style={{ border: " 1px dashed #D5D5D5", width: "100%", marginBottom: "26px", marginTop: "17px" }} />
        <div className={style["title"]} style={{ paddingInline: "18px", marginBottom: "20px" }}>
          VIDEO
        </div>
        <Row gutter={[15, 24]} style={{ paddingInline: "18px" }}>
          {" "}
          {data.data.youtube.map((item) => (
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={12}>
              <iframe
                src={item.src}
                // width="100"
                // height="100"
                title="YouTube video player"
                frameBorder="0"
                allowFullScreen
              />
            </Col>
          ))}
          {/* <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={12}>
            <iframe
              src={"https://www.youtube.com/embed/SvDt-iXogfo"}
              // width="100"
              // height="100"
              title="YouTube video player"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </Col> */}
        </Row>
        <Modal
          footer={false}
          closable={false}
          bodyStyle={{ backgroundColor: "#323639", padding: "0" }}
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          width={"80%"}
        >
          <div className={style["pdf"]} style={{ height: "calc(100vh - 10px)" }}>
            <embed src={srcPdf} width="100%" height="100%" />
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default HDSDBangDieuKhien;
