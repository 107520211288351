import React from "react";
import style from "./style.module.less";
import { Row, Col, Form, Radio, Checkbox } from "antd";
import {FieldKhaiThac} from "./fieldKhaiThac";
import i18n, { languageKeys } from "../../../../i18n";
const { Item } = Form;
export const optionsTienSu = [
    { label: i18n.t(languageKeys.huyet_ap_cao), value: 'HUYET_AP_CAO' },
    { label: i18n.t(languageKeys.ung_thu), value: 'UNG_THU' },
    { label: i18n.t(languageKeys.benh_lao), value: 'LAO' },
    { label: i18n.t(languageKeys.tai_bien_nao), value: 'TAI_BIEN_NAO' },
    { label: "Cholesterol", value: 'CHOLESTEROL' },
    { label: i18n.t(languageKeys.tieu_duong), value: 'TIEU_DUONG' },
    { label: i18n.t(languageKeys.benh_tim_mach), value: 'BENH_TIM_MACH' },
    { label: i18n.t(languageKeys.dau_nua_dau), value: 'DAU_NUA_DAU' },
    { label: i18n.t(languageKeys.loang_xuong), value: 'LOANG_XUONG' },
    { label: i18n.t(languageKeys.gan), value: 'GAN' },
    { label: i18n.t(languageKeys.than), value: 'THAN' },
    { label: i18n.t(languageKeys.roi_loan_lipit), value: 'ROI_LOAN_LIPIT' },
    { label: i18n.t(languageKeys.gay_xuong), value: 'GAY_XUONG' },
    { label: i18n.t(languageKeys.other), value: 'KHAC' },
]
export const TienSuGiaDinh = (
    {
        readOnlyInput
    }
) => {
    return(
        <div className={style["tien-su-ban-than"]}>
            <Row align={"middle"}>
                <Col flex={"40%"}>
                    <span className={style["text"]}>{i18n.t(languageKeys.question_family)}</span>
                </Col>
                <Col flex={"auto"}>
                </Col>
            </Row>
            <Row align={"middle"} wrap={false} style={{marginTop : 10}}>
                <Item name={FieldKhaiThac.TIEN_SU_GIA_DINH}>
                    <Checkbox.Group options = {optionsTienSu} disabled={readOnlyInput}/>
                </Item>
            </Row>
        </div>
    )
}
