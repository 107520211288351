import { PrinterOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Layout, Popover, Row, Space, Spin, Tooltip, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checked, UnCheck } from "../../../../assets/svg";
import { ConfirmModal, ModalPDF, Table } from "../../../../components";
import { apis } from "../../../../constants";
import keys, { featureKeys } from "../../../../constants/keys";
import { HLog, common_post, convertDateToValue, handleCheckPermissions, removeTags, rid } from "../../../../helpers";
import { useEventListener } from "../../../../hooks";
import i18n, { languageKeys } from "../../../../i18n";
import FormNhapKetQua from "../FormNhapKetQua";
import { TraKQContext, trang_thai_th, trang_thai_tra } from "../TraKetQuaCdha";
import { apiCapNhatTrangThaiDichVu, apiLayChiTietPhieu, apiPhieuTra, capNhatTrangThaiPhieu, guiLaiChiDinhVin } from "../services";
import { tabItemsKey } from "./Sider";
import styles from "./dichvu.module.less";
import { TooltipButton } from "components/Tooltip/CustomTooltip";
import { resetRisMessage } from "ducks/slices/risReloadSilce";
import { columns } from "pages/QuanLyKhamBenh/views/PhauThuatThuThuat/utils";

const { Item } = Form;

function DanhSachDv({ showBtnCancel = true }) {
  const { selectedPhieu, tabActive, setTabActive, setReloadAfterThucHien } = useContext(TraKQContext);
  const userProfile = useSelector((state) => state.auth.user);

  const nhapRef = useRef();
  // const daTraRef = useRef();
  const pdfRef = useRef();
  const refConfirm = useRef();
  const refConfirmHuyThucHien = useRef();
  const { reloadKqDv, message } = useSelector((state) => state.risReloadSilce);

  const [dsDichVu, setDsDichVu] = useState([]);
  const [loading, setLoading] = useState(false);
  const [thongTinPhieu, setThongTinPhieu] = useState(null);
  const [form] = useForm();
  const dataLocal = useSelector((state) => state.data);
  // const [openInfo, setOpenInfo] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedPhieu) {
      layChiTietPhieu(selectedPhieu, tabActive);
    } else {
      form.resetFields();
      setDsDichVu([]);
      setThongTinPhieu(null);
    }
  }, [selectedPhieu]);

  useEffect(() => {
    if (reloadKqDv === "RELOAD") {
      dispatch(resetRisMessage());
      layChiTietPhieu(selectedPhieu, tabActive);
    }
  }, [reloadKqDv]);

  const quyen = handleCheckPermissions(userProfile, featureKeys.tra_ket_qua_cdha);

  // const isAllowQuyenRis = useMemo(() => {
  //   let isAllowHuyTra = false;
  //   let isAllowTraPhieu = false;

  //   if (userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.quan_tri) {
  //     isAllowHuyTra = true;
  //     isAllowTraPhieu = true;
  //   } else if (!!userProfile.QUYEN) {
  //     let dsQuyen = [];
  //     if (isJsonString(userProfile.QUYEN)) {
  //       dsQuyen = JSON.parse(userProfile.QUYEN);
  //     } else {
  //       dsQuyen = userProfile.QUYEN;
  //     }
  //     isAllowHuyTra = dsQuyen.some((quyen) => quyen.includes(`${featureKeys.tra_ket_qua_cdha}.${featureKeys.huy_tra_phieu_cdha}`));
  //     isAllowTraPhieu = dsQuyen.some((quyen) => quyen.includes(`${featureKeys.tra_ket_qua_cdha}.${featureKeys.tra_ket_qua}`));
  //   }
  //   return { isAllowHuyTra, isAllowTraPhieu };
  // }, [userProfile]);

  // lấy chi tiết phêis
  async function layChiTietPhieu(phieu, TRANG_THAI) {
    HLog("lấy chi tiết dịch vụ trong phiếu: ", TRANG_THAI);
    try {
      setLoading(true);
      let req = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        PHIEU_ID: phieu.PHIEU_ID,
        LUOT_DIEU_TRI_ID: phieu.LUOT_DIEU_TRI_ID,
        TRANG_THAI: TRANG_THAI,
        PHONG_ID: phieu.PHONG_ID,
      };
      const response = await apiLayChiTietPhieu(req);
      if (response) {
        setLoading(false);
        let dsDv = response.result.ds_dich_vu.map((item, index) => ({ ...item, STT: index + 1 }))
        setDsDichVu(dsDv);
        if (response.result.thong_tin_phieu) {
          setThongTinPhieu(response.result.thong_tin_phieu);
          let thong_tin_phieu = { ...response.result.thong_tin_phieu }
          let NGUOI_TRA_KQ = dsDv?.filter(objFt => objFt?.NGUOI_TRA_KQ?.length > 0)?.map(detail => detail?.NGUOI_TRA_KQ)
          let NGUOI_THUC_HIEN = dsDv?.filter(objFt => objFt?.NGUOI_THUC_HIEN?.length > 0)?.map(detail => detail?.NGUOI_THUC_HIEN)

          setDataToForm({
            ...response.result.thong_tin_phieu,
            NGUOI_TRA_KQ: [...new Set(NGUOI_TRA_KQ)]?.join(", "),
            NGUOI_THUC_HIEN: [...new Set(NGUOI_THUC_HIEN)]?.join(", "),
          });

          if (message == keys.openForm) {
            if (response.result.thong_tin_phieu && dsDv.some((item) => item.TRANG_THAI_KQ === trang_thai_tra.CHO_KET_QUA)) {
              handleTraKQAll();
            }
            dispatch(resetRisMessage());
          }
        } else {
          form.resetFields();
        }
      }
    } catch (error) {
      HLog(error);
    } finally {
      setLoading(false);
    }
  }

  // điền data vào forrm thông tin
  function setDataToForm(data) {
    form.setFields(
      Object.values(fieldNames).map((item) => {
        if (item === fieldNames.THOI_GIAN_CHI_DINH) {
          return {
            name: item,
            value: `${data?.GIO_CHI_DINH || ''} ${convertDateToValue(data[fieldNames.NGAY_CHI_DINH])}`,
          };
        }
        if (item === fieldNames.THOI_GIAN_TRA_KQ) {
          return {
            name: item,
            value: data[fieldNames.THOI_GIAN_TRA_KQ] ? moment(Number(data[fieldNames.THOI_GIAN_TRA_KQ])).format("DD/MM/YYYY") : null,
          };
        }
        return {
          name: item,
          value: data[`${item}`],
        };
      })
    );
  }

  // callback khi trả kết quả 1 dịch vụ thành công thì lưu state lại để check khi đóng form
  async function handleTraKetQuaDvSuccess(dichvu) {
    await layChiTietPhieu(selectedPhieu, trang_thai_tra.DA_TRA_KET_QUA);
    setReloadAfterThucHien(true);
    setTabActive(tabItemsKey.DA_TRA_KET_QUA); // chuyển tab sider sang đang thực hiện
  }

  useEffect(() => {
    if (dsDichVu.length > 0) {
      let checkDvChuaTra = dsDichVu.some((item) => item.TRANG_THAI_KQ !== trang_thai_tra.DA_TRA_KET_QUA);
      if (!checkDvChuaTra) {
        //handleHoanThanh()
      }
    }
  }, [dsDichVu]);

  // handle khi ấn thực hiện phiếu
  async function handleThucHienPhieu(dv) {
    // try {
    //   let req = {
    //     partner_code: userProfile.partner_code,
    //     BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    //     PHIEU_ID: thongTinPhieu.PHIEU_ID,
    //     TRANG_THAI: trang_thai_th.DA_THUC_HIEN,
    //     NHANSU_ID: userProfile.NHANSU_ID,
    //     PHONG_ID: selectedPhieu.PHONG_ID,
    //     LICH_KHAM_ID: thongTinPhieu?.LICH_KHAM_ID,
    //     LUOT_DIEU_TRI_ID: thongTinPhieu?.LUOT_DIEU_TRI_ID,
    //   };
    //   let response = await capNhatTrangThaiPhieu(req);
    //   if (response) {
    //     setTabActive(tabItemsKey.DANG_THUC_HIEN)
    //     await layChiTietPhieu(selectedPhieu, trang_thai_th.DANG_THUC_HIEN );
    //     setReloadAfterThucHien(true);
    //    ; // chuyển tab sider sang đang thực hiện
    //     if (dv) {
    //       onOpenForm(dv);
    //     } else {
    //       setTimeout(() => {
    //         handleTraKQAll();
    //       }, 800);
    //     }
    //   }
    // } catch (error) {
    //   notification.error({
    //     message : i18n.t(languageKeys.common_co_loi_xay_ra),
    //     placement : "bottomLeft"
    //   })
    // }
  }

  // Thực hiện 1 dịch vụ của phiếu

  const handleChuyenTrangThaiDichVu = async (dichvu, TRANG_THAI) => {
    const payload = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_ID: thongTinPhieu.PHIEU_ID,
      NHANSU_ID: userProfile.NHANSU_ID,
      PHONG_ID: selectedPhieu.PHONG_ID,
      LICH_KHAM_ID: thongTinPhieu?.LICH_KHAM_ID,
      LUOT_DIEU_TRI_ID: thongTinPhieu?.LUOT_DIEU_TRI_ID,
      CT_PHIEU_ID: dichvu?.CT_PHIEU_ID,
      PDT_ID: dichvu?.PDT_ID,
      TRANG_THAI: TRANG_THAI,
      NGUOI_THUC_HIEN_ID: dichvu.NGUOI_THUC_HIEN_ID,
    };

    try {
      const response = await apiCapNhatTrangThaiDichVu(payload);
      if (response) {
        const tabKey = TRANG_THAI === trang_thai_th.DA_THUC_HIEN ? trang_thai_tra.DA_TRA_KET_QUA : TRANG_THAI;
        await layChiTietPhieu(selectedPhieu, tabKey);
        if (TRANG_THAI === trang_thai_th.CHO_THUC_HIEN) {
          refConfirmHuyThucHien.current.close();
          setReloadAfterThucHien(true);
          setTabActive(tabItemsKey.CHO_THUC_HIEN); // chuyển tab sider sang đang thực hiện
        }
        if (TRANG_THAI === trang_thai_th.DANG_THUC_HIEN) {
          setReloadAfterThucHien(true);
          setTabActive(tabItemsKey.DANG_THUC_HIEN); // chuyển tab sider sang đang thực hiện
        }
      }
      return response;
    } catch (e) {}
  };

  // trả kết quả tất cả các dịch vụ
  async function handleTraKQAll() {
    onOpenForm();
  }

  // handle khi bấm hoàn thành phiếu
  async function handleHoanThanh() {
    try {
      let req = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        PHIEU_ID: thongTinPhieu.PHIEU_ID,
        TRANG_THAI: trang_thai_tra.DA_TRA_KET_QUA,
        NHANSU_ID: userProfile.NHANSU_ID,
        PHONG_ID: selectedPhieu.PHONG_ID,
        LICH_KHAM_ID: thongTinPhieu.LICH_KHAM_ID,
        LUOT_DIEU_TRI_ID: thongTinPhieu.LUOT_DIEU_TRI_ID,
      };
      let response = await capNhatTrangThaiPhieu(req);
      if (response) {
        // setDsDichVu(current => current.map(item => ({...item,  TRANG_THAI_KQ : trang_thai_tra.DA_TRA_KET_QUA}))) //
        // setThongTinPhieu(current => ({...current ,  TRANG_THAI_KQ : trang_thai_tra.DA_TRA_KET_QUA}))
        layChiTietPhieu(selectedPhieu);
        setTabActive(tabItemsKey.DA_TRA_KET_QUA); //
      }
    } catch (error) {
      notification.error({
        message: i18n.t(languageKeys.common_co_loi_xay_ra),
        placement: "bottomLeft",
      });
    }
  }

  // hủy trả
  // async function handleHuyTra() {
  //   refConfirm.current.loading(true);
  //   let req = {
  //     partner_code: userProfile.partner_code,
  //     BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
  //     PHIEU_ID: thongTinPhieu.PHIEU_ID,
  //     NHANSU_ID: userProfile.NHANSU_ID,
  //     LICH_KHAM_ID: thongTinPhieu.LICH_KHAM_ID,
  //     LUOT_DIEU_TRI_ID: thongTinPhieu.LUOT_DIEU_TRI_ID,
  //   };
  //   let response = await huyTraKetQua(req);
  //   if (response) {
  //     refConfirm.current.close();
  //     ///setDsDichVu(current => current.map(item => ({...item, TRANG_THAI_TH : trang_thai_th.DA_THUC_HIEN, TRANG_THAI_KQ : trang_thai_tra.CHO_KET_QUA}))) //
  //     //setThongTinPhieu(current => ({...current , TRANG_THAI_TH : trang_thai_th.DA_THUC_HIEN, TRANG_THAI_KQ : trang_thai_tra.CHO_KET_QUA}))
  //     layChiTietPhieu(selectedPhieu);
  //     setTabActive(tabItemsKey.DANG_THUC_HIEN); //
  //   }
  // }

  function handleHuyTraDichVuSuccess(ID, dichvu) {
    setTabActive(tabItemsKey.DANG_THUC_HIEN);
    layChiTietPhieu(selectedPhieu, trang_thai_th.DANG_THUC_HIEN);
  }

  // in cả phiếu
  async function handleInPhieu(array_id, pacs = false) {
    let req = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_ID: array_id,
    };
    let response = await apiPhieuTra(req, pacs);
    if (response) {
      let array = response.result;
      let data_print = array.map((item) => {
        return {
          ...item,
          maumota: item.MO_TA,
          ket_luan: item.KET_LUAN,
          arr_anh_ID: item.ANH_MO_TA,
          MA_PHIEU: thongTinPhieu?.MA_PHIEU,
        };
      });

      const template = !pacs ? dataLocal?.template_pdf?.PHIEU_TRA_KQ_RIS || "PhieuTraKq" : "PhieuTraKqPACS";
      if (!template) {
        notification.error({
          message: i18n.t(languageKeys.msg_khong_co_mau_in),
          placement: "bottomLeft",
        });
        return;
      }
      await pdfRef.current.openModalWithData(data_print, template);
    }
  }

  async function handleGuiLaiSangVin(record) {
    try {
      setLoading(true)
      const response = await guiLaiChiDinhVin({
        CT_PHIEU_ID: record.CT_PHIEU_ID,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID
      });
      if (response?.status === "OK") {
        layChiTietPhieu(selectedPhieu, tabActive);
      }
    } catch (error) {
      console.log('handleGuiLaiSangVin', error)
    } finally {
      setLoading(false)
    }
  }

  const checkDisplayReSend = (item) => {
    if (item?.MA_LOAI_DV &&
      ['CDHA_MAMO', 'CDHA_MRI', 'CDHA_CT', 'CDHA_XQUANG'].includes(item.MA_LOAI_DV) &&
      userProfile.config?.PACS_VINBRAIN
    ) {
      return true
    }
    return false;
  }

  const getColumns = () => {
    let col = [...columnsFixed];
    if (userProfile.config?.PACS_VINBRAIN) {
      col.push(
        {
          title: i18n.t(languageKeys.tt_gui_PACS),
          width: 90,
          // align:'right',
          dataIndex: "TRANG_THAI_VIN",
          render: (TRANG_THAI_VIN, record) => {
            if (record?.MA_LOAI_DV && !['CDHA_MAMO', 'CDHA_MRI', 'CDHA_CT', 'CDHA_XQUANG'].includes(record.MA_LOAI_DV)) {
              return '-'
            }
            switch (TRANG_THAI_VIN) {
              case 'THANH_CONG':
                return <span style={{ color: "#2CB3A5" }}>{i18n.t(languageKeys.da_gui)}</span>
              case 'THAT_BAI':
                return <span style={{ color: "red" }}>{i18n.t(languageKeys.gui_loi)}</span>
              case 'CHUA_DAY':
                return <span style={{ color: "red" }}>{i18n.t(languageKeys.chua_gui)}</span>
              default:
                return <span style={{ color: "red" }}>{i18n.t(languageKeys.gui_loi)}</span>
            }
          }
        },
      )
    }

    col.push(
      {
        title: "",
        width: tabActive !== trang_thai_th.DANG_THUC_HIEN ? 220 : 260,
        align: "right",
        dataIndex: "TRANG_THAI",
        fixed: "right",
        render: (TRANG_THAI, record) => {
          if (record.TRANG_THAI_TH === trang_thai_th.CHO_THUC_HIEN) {
            return (
              <Space>
                {checkDisplayReSend(record) && <Button
                  className={styles.button_table}
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleGuiLaiSangVin(record)
                  }}
                >
                  {i18n.t(languageKeys.gui_PACS)}
                </Button>}
                <TooltipButton title={!quyen.THUC_HIEN_TRA_KQ ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                  <Button
                    className={styles.button_table}
                    type="primary"
                    onClick={(e) => {
                      // handleChuyenTrangThaiAndTraKq(record);
                      e.stopPropagation();
                      handleChuyenTrangThaiDichVu(record, trang_thai_th.DANG_THUC_HIEN);
                    }}
                  >
                    {i18n.t(languageKeys.thuc_hien)}
                  </Button>
                </TooltipButton>
              </Space>
            );
          }
          if (
            [trang_thai_th.DA_THUC_HIEN, trang_thai_th.DANG_THUC_HIEN].includes(record.TRANG_THAI_TH) &&
            record.TRANG_THAI_KQ === trang_thai_tra.CHO_KET_QUA
          ) {
            return (
              <Space>
                {checkDisplayReSend(record) && <Button
                  className={styles.button_table}
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleGuiLaiSangVin(record)
                  }}
                >
                  {i18n.t(languageKeys.gui_PACS)}
                </Button>}
                {showBtnCancel && (
                  <TooltipButton title={!quyen.HUY_THUC_HIEN ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                    <Button
                      // className={styles.button_table}
                      type="primary"
                      ghost
                      danger
                      onClick={(e) => {
                        e.stopPropagation();
                        // handleChuyenTrangThaiDichVu(record, trang_thai_th.CHO_THUC_HIEN);
                        refConfirmHuyThucHien.current.open(record);
                      }}
                      // disabled={!isAllowQuyenRis.isAllowTraPhieu}
                      disabled={!quyen?.HUY_THUC_HIEN}
                    >
                      {i18n.t(languageKeys.huy_thuc_hien)}
                    </Button>
                  </TooltipButton>
                )}
                <TooltipButton title={!quyen.THUC_HIEN_TRA_KQ ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                  <Button
                    className={styles.button_table}
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      onOpenForm(record);
                    }}
                    // disabled={!isAllowQuyenRis.isAllowTraPhieu}
                    disabled={!quyen.THUC_HIEN_TRA_KQ}
                  >
                    {i18n.t(languageKeys.tra_ket_qua)}
                  </Button>
                </TooltipButton>
              </Space>
            );
          }
          if (
            record.TRANG_THAI_TH === trang_thai_th.DA_THUC_HIEN &&
            (record.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA || record.TRANG_THAI_KQ === trang_thai_tra.HOAN_THANH)
          ) {
            return (
              <Space>
                {checkDisplayReSend(record) && <Button
                  className={styles.button_table}
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleGuiLaiSangVin(record)
                  }}
                >
                  {i18n.t(languageKeys.gui_PACS)}
                </Button>}
                <TooltipButton title={!quyen?.HUY ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                  <Button
                    ghost
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      // handleCancelOneSevice(record)
                      refConfirm.current.open(record);
                    }}
                    danger
                    disabled={selectedPhieu?.IS_TIEP_TAN ? !quyen.HUY : !quyen?.HUY}
                  >
                    {i18n.t(languageKeys.huy_tra)}
                  </Button>
                </TooltipButton>
                {/* <TooltipButton title={!quyen?.IN_KQ ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                  <Button
                    disabled={!quyen?.IN_KQ}
                    className={styles.button_table}
                    icon={<PrinterOutlined />}
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleInPhieu([record.PHIEU_TRA_ID]);
                    }}
                  >
                    {i18n.t(languageKeys.in_kq)}
                  </Button>
                </TooltipButton> */}
                {checkDisplayReSend(record) ? (
                  <Popover placement="right" content={(
                    <div className="flex flex-row">
                    <Button
                      disabled={!quyen?.IN_KQ}
                      className={styles.button_table}
                      style={{maxWidth: '100%', width: '120px', marginBottom: '10px'}}
                      icon={<PrinterOutlined />}
                      type="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleInPhieu([record.PHIEU_TRA_ID]);
                      }}
                    >
                      {i18n.t(languageKeys.in_kq)}
                    </Button>
                    <br />
                    <Button
                      disabled={!quyen?.IN_KQ}
                      className={styles.button_table}
                      style={{maxWidth: '100%', width: '120px'}}
                      icon={<PrinterOutlined />}
                      type="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleInPhieu([record.PHIEU_TRA_ID], true);
                      }}
                    >
                      In KQ PACS
                    </Button>
                  </div>
                )}>
                  <Button
                    disabled={!quyen?.IN_KQ}
                    className={styles.button_table}
                    icon={<PrinterOutlined />}
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleInPhieu([record.PHIEU_TRA_ID]);
                    }}
                  >
                    In
                  </Button>
                </Popover>
                ) : (
                  <Button
                    disabled={!quyen?.IN_KQ}
                    className={styles.button_table}
                    icon={<PrinterOutlined />}
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleInPhieu([record.PHIEU_TRA_ID]);
                    }}
                  >
                    In KQ
                  </Button>
                )}
              </Space>
            );
          }
        },
      }
    );

    return col;
  };

  const onOpenForm = (selectedService) => {
    nhapRef.current.onOpen(selectedService);
  };


  const keyDownEvent = (event) => {
    console.log("event", event)
    if (event.ctrlKey && event.key === "h") {
      event.stopPropagation();
      event.preventDefault();
      if (thongTinPhieu && thongTinPhieu.TRANG_THAI_TH === trang_thai_th.CHO_THUC_HIEN) {
        handleThucHienPhieu();
      }
      if (thongTinPhieu && thongTinPhieu.TRANG_THAI_TH === trang_thai_th.DA_THUC_HIEN) {
        refConfirmHuyThucHien.current.open();
      }
    }
    if (event.ctrlKey && event.key === "k") {
      if (tabActive === tabItemsKey.DANG_THUC_HIEN) {
        if (
          thongTinPhieu &&
          thongTinPhieu.TRANG_THAI_TH === trang_thai_th.DA_THUC_HIEN &&
          dsDichVu.some((item) => item.TRANG_THAI_KQ === trang_thai_tra.CHO_KET_QUA)
        ) {
          // if (isAllowQuyenRis.isAllowTraPhieu) {
          //   event.stopPropagation();
          //   event.preventDefault();
          //   if (nhapRef.current.visible) {
          //     return;
          //   } else {
          //     handleTraKQAll();
          //   }
          // }
        }
      } else {
        return;
      }
    }

    if (event.ctrlKey && event.key === "e") {
      if (
        thongTinPhieu &&
        thongTinPhieu.TRANG_THAI_TH === trang_thai_th.DA_THUC_HIEN &&
        thongTinPhieu.TRANG_THAI_KQ !== trang_thai_tra.DA_TRA_KET_QUA &&
        !dsDichVu.some((item) => item.TRANG_THAI_KQ !== trang_thai_tra.DA_TRA_KET_QUA)
      ) {
        event.stopPropagation();
        event.preventDefault();
        handleHoanThanh();
      }
    }

    if (event.ctrlKey && event.key === "d") {
      if (tabActive === tabItemsKey.DA_TRA_KET_QUA) {
        // if (isAllowQuyenRis.isAllowHuyTra) {
        //   event.stopPropagation();
        //   event.preventDefault();
        //   if (nhapRef.current.visible) {
        //     return;
        //   } else {
        //     refConfirm.current.open();
        //   }
        // }
      }
    }

    if (event.ctrlKey && event.key === "i") {
      event.stopPropagation();
      event.preventDefault();
      if (nhapRef.current.visible) {
        return;
      } else {
        console.log(thongTinPhieu)
        if (thongTinPhieu) {/*&& thongTinPhieu.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA*/
          let arr_id = dsDichVu.map((item) => item.PHIEU_TRA_ID);
          handleInPhieu(arr_id);
        }
      }
    }
    if (event.ctrlKey && event.key === "y") {
      event.stopPropagation();
      event.preventDefault();
      if (nhapRef.current.visible) {
        return;
      } else {
        console.log(thongTinPhieu)
        if (thongTinPhieu) {/*&& thongTinPhieu.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA*/
          let arr_id = dsDichVu.map((item) => item.PHIEU_TRA_ID);
          handleInPhieu(arr_id, true);
        }
      }
    }
  };

  useEventListener("keydown", keyDownEvent, window.document);

  const handleCancelOneSevice = async (item) => {
    try {
      let body = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        CHITIET_PHIEU_TRA_ID: [item.PHIEU_TRA_ID],
        PHIEU_ID: selectedPhieu.PHIEU_ID,
        NHANSU_ID: userProfile.NHANSU_ID,
        CT_PHIEU_ID: item.CT_PHIEU_ID,
        PHONG_DIEU_TRI_ID: item?.PDT_ID,
        DICH_VU_ID: item?.DICH_VU_ID,
      };
      let res = await common_post(apis.ris_huy_dich_vu_phieu_tra_kq, body, false);

      if (res && res.status === "OK") {
        await handleHuyTraDichVuSuccess(item.DICH_VU_ID, item);
        refConfirm.current.close();
        notification.success({
          message: i18n.t(languageKeys.thao_tac_thanh_cong),
          placement: "bottomLeft",
        });
      } else {
        notification.error({
          message: i18n.t(languageKeys.noti_tra_kq_that_bai),
          placement: "bottomLeft",
        });
      }
    } catch (error) {}
  };

  return (
    <Layout className={styles["container"]}>
      {/* <Spin spinning={loading}> */}
      <Layout.Content className={styles["content-container"]}>
        <Spin spinning={loading}>
          <div /*style={openInfo ? { visibility: "hidden", opacity: 0, position: "fixed" } : {}}*/>
            <Form form={form} className={styles["form-container"]} layout="vertical">
              <Row gutter={10}>
                <Col flex={"20%"}>
                  <Item label={i18n.t(languageKeys.ma_phieu)} name={fieldNames.MA_PHIEU}>
                    <Input readOnly />
                  </Item>
                </Col>
                <Col flex={"20%"}>
                  <Item label={i18n.t(languageKeys.thoi_gian_chi_dinh)} name={fieldNames.THOI_GIAN_CHI_DINH}>
                    <Input readOnly />
                  </Item>
                </Col>
                <Col flex={"20%"}>
                  <Item label={i18n.t(languageKeys.noi_chi_dinh)} name={fieldNames.NOI_CHI_DINH}>
                    <Input readOnly />
                  </Item>
                </Col>
                <Col flex={"40%"}>
                  <Item label={i18n.t(languageKeys.chan_doan)} name={fieldNames.CHAN_DOAN}>
                    <Input readOnly />
                  </Item>
                </Col>
              </Row>

              <Row gutter={10} style={{ marginTop: 5 }}>

                <Col flex={"20%"}>
                  <Item label={i18n.t(languageKeys.noi_thuc_hien)} name={fieldNames.NOI_THUC_HIEN}>
                    <Input readOnly />
                  </Item>
                </Col>
                <Col flex={"20%"}>
                  <Item label={i18n.t(languageKeys.nguoi_thuc_hien)} name={fieldNames.NGUOI_THUC_HIEN}>
                    <Input readOnly />
                  </Item>
                </Col>
                <Col flex={"20%"}>
                  <Item label={i18n.t(languageKeys.nguoi_tra_kq)} name={fieldNames.NGUOI_TRA_KQ}>
                    <Input readOnly />
                  </Item>
                </Col>
                <Col flex={"20%"}>
                  <Item label={i18n.t(languageKeys.ngay_tra_kq)} name={fieldNames.THOI_GIAN_TRA_KQ}>
                    <Input readOnly />
                  </Item>
                </Col>
              </Row>
            </Form>
          </div>

          <Table
            dataSource={!!selectedPhieu
              ? (selectedPhieu?.ID ? dsDichVu.filter(i => i.PDT_ID === selectedPhieu?.ID) : dsDichVu)
              : []
            }
            className={styles["table-custom"]}
            style={{ marginTop: 20 }}
            columns={getColumns()}
            scroll={{
              x: columns.reduce((sum, item) => sum + (item.width || 120), 150) + 300,
              // x : true,
              y: "calc(100vh - 200px)",
            }}
            rowKey={"STT"}
            onClickRow={(row) => row.TRANG_THAI_TH !== trang_thai_th.CHO_THUC_HIEN && onOpenForm(row)}
          // sizeTable="small"
          />
        </Spin>
      </Layout.Content>
      <Layout.Footer className={styles["footer-container"]}>
        {/* {thongTinPhieu && thongTinPhieu.TRANG_THAI_TH === trang_thai_th.CHO_THUC_HIEN && (
          <Row justify="end">
            <Button className={styles["button"]} type="primary" onClick={() => handleThucHienPhieu()}>
              {i18n.t(languageKeys.thuc_hien) + ` (Ctrl + H)`}
            </Button>
          </Row>
        )} */}

        {/* {thongTinPhieu &&
          thongTinPhieu.TRANG_THAI_TH === trang_thai_th.DA_THUC_HIEN &&
          dsDichVu.some((item) => item.TRANG_THAI_KQ === trang_thai_tra.CHO_KET_QUA) && (
            <Row justify="end">
               <Button className={styles["button"]} type="danger" ghost onClick={() => {
                refConfirmHuyThucHien.current.open()
               }} disabled={!isAllowQuyenRis.isAllowTraPhieu}>
                {"Huỷ thực hiện" + ` (Ctrl + H)`}
              </Button>
              <Button className={styles["button"]} type="primary" onClick={handleTraKQAll} disabled={!isAllowQuyenRis.isAllowTraPhieu}>
                {i18n.t(languageKeys.tra_ket_qua) + ` (Ctrl + K)`}
              </Button>
            </Row>
          )} */}

        {/* {thongTinPhieu && thongTinPhieu.TRANG_THAI_TH === trang_thai_th.DA_THUC_HIEN &&
                thongTinPhieu.TRANG_THAI_KQ !== trang_thai_tra.DA_TRA_KET_QUA
                && !dsDichVu.some(item => item.TRANG_THAI_KQ !== trang_thai_tra.DA_TRA_KET_QUA) && */}
        {/* {tabActive === tabItemsKey.DA_TRA_KET_QUA &&
          !dsDichVu.some((item) => item.TRANG_THAI_KQ !== trang_thai_tra.DA_TRA_KET_QUA) && (
            <Row justify="end">
              <Button className={styles["button"]} type="primary" onClick={handleHoanThanh}>
                {i18n.t(languageKeys.common_Hoan_thanh) + ` (Ctrl + E)`}
              </Button>
            </Row>
          )} */}

        {tabActive === tabItemsKey.DA_TRA_KET_QUA && dsDichVu.length > 0 && (
          <Row justify="end">
            {/* <Button
              className={styles["button"]}
              type="primary"
              danger
              ghost
              onClick={() => refConfirm.current.open()}
              disabled={
                !isAllowQuyenRis.isAllowHuyTra ||
                selectedPhieu?.TRANG_THAI_LICH_KHAM === "HOAN_THANH" ||
                selectedPhieu?.TRANG_THAI_LICH_KHAM === "KHAM_XONG"
              }
            >
              {i18n.t(languageKeys.huy_tra) + ` (Ctrl + D)`}
            </Button> */}
            <div>
            <TooltipButton widthTip={170} title={!quyen?.IN_KQ ? i18n.t(languageKeys.khong_co_quyen) : ""}>
              <Button
                disabled={!quyen?.IN_KQ}
                className={styles["button"]}
                type="primary"
                icon={<PrinterOutlined />}
                onClick={() => {
                  let arr_id = dsDichVu.map((item) => item.PHIEU_TRA_ID);
                  handleInPhieu(arr_id);
                }}
              >
                {i18n.t(languageKeys.field_In_phieu) + ` (Ctrl + I)`}
              </Button>
            </TooltipButton>
            </div>
            <div>
            <TooltipButton widthTip={170} title={!quyen?.IN_KQ ? i18n.t(languageKeys.khong_co_quyen) : ""}>
              <Button
                disabled={!quyen?.IN_KQ}
                className={styles["button"]}
                type="primary"
                icon={<PrinterOutlined />}
                onClick={() => {
                  let arr_id = dsDichVu.map((item) => item.PHIEU_TRA_ID);
                  handleInPhieu(arr_id, true);
                }}
              >
                {`In KQ PACS (Ctrl + Y)`}
              </Button>
            </TooltipButton>
            </div>
          </Row>
        )}
      </Layout.Footer>
      {/* </Spin> */}

      <FormNhapKetQua
        patientInfo={selectedPhieu}
        phieu={thongTinPhieu}
        ref={nhapRef}
        dsDichvu={dsDichVu}
        doAfterSubmit={(dichvu) => handleTraKetQuaDvSuccess(dichvu)}
        doAfterDestroy={handleHuyTraDichVuSuccess}
        handleChuyenTrangThaiDichVu={(phieu, trang_thai) => handleChuyenTrangThaiDichVu(phieu, trang_thai)}
        disableTraKQ={!quyen?.THUC_HIEN_TRA_KQ}
        disabledHuyTH={!quyen?.HUY_THUC_HIEN}
        disableHuyKQ={!quyen?.HUY}
        disableIn={!quyen?.IN_KQ}
      />

      <ModalPDF ref={pdfRef} />
      {/* <DvDaTraKQ
                ref={daTraRef}
                ticket={thongTinPhieu}
                patientInfo={selectedPhieu}
                doAfterDestroy={handleHuyTraDichVuSuccess}
            /> */}
      <ConfirmModal
        danger={true}
        ref={refConfirm}
        content={i18n.t(languageKeys.content_huy_tra_phieu)}
        onOk={(data) => handleCancelOneSevice(data)}
        onCancel={() => {
          refConfirm.current.close();
        }}
        okText={i18n.t(languageKeys.title_Xac_nhan)}
      />

      <ConfirmModal
        danger={true}
        ref={refConfirmHuyThucHien}
        content={
          dsDichVu.some((item) => item.TRANG_THAI_KQ === trang_thai_tra.DA_TRA_KET_QUA)
            ? i18n.t(languageKeys.confirm_huy_thuc_hien_khi_co_dv_da_tra)
            : i18n.t(languageKeys.confirm_huy_thuc_hien)
        }
        onOk={(data) => handleChuyenTrangThaiDichVu(data, trang_thai_th.CHO_THUC_HIEN)}
        onCancel={() => {
          refConfirmHuyThucHien.current.close();
        }}
        okText={i18n.t(languageKeys.title_Xac_nhan)}
      />
    </Layout>
  );
}

export default DanhSachDv;

const fieldNames = {
  MA_PHIEU: "MA_PHIEU",
  NGAY_CHI_DINH: "NGAY_CHI_DINH",
  THOI_GIAN_TRA_KQ: "THOI_GIAN_TRA_KQ",
  THOI_GIAN_Y_LENH: "THOI_GIAN_Y_LENH",
  GIO_Y_LENH: "GIO_Y_LENH",
  NGUOI_THUC_HIEN: "NGUOI_THUC_HIEN",
  NOI_CHI_DINH: "NOI_CHI_DINH",
  NOI_THUC_HIEN: "NOI_THUC_HIEN",
  NGUOI_TRA_KQ: "NGUOI_TRA_KQ",
  CHAN_DOAN: "CHAN_DOAN",
  THOI_GIAN_CHI_DINH: 'THOI_GIAN_CHI_DINH'
};

// ellipsis chữ (độ ... đằng sau nếu quá dài)
const ellipsisProps = {
  ellipsis: true,
  render: (data) => (
    <Tooltip placement="topLeft" title={data} zIndex={1}>
      {data === "null" || data === "undefined" || !data ? "" : data}
    </Tooltip>
  ),
};

const columnsFixed = [
  {
    title: i18n.t(languageKeys.stt),
    width: 40,
    dataIndex: "STT",
    align: "center",
  },
  {
    title: i18n.t(languageKeys.field_Ten_dich_vu),
    width: 130,
    dataIndex: "TEN_DICHVU",
    ...ellipsisProps,
  },
  {
    title: i18n.t(languageKeys.sl),
    width: 60,
    ...ellipsisProps,
    dataIndex: "SO_LUONG",
  },
  {
    title: i18n.t(languageKeys.mo_ta),
    width: 100,
    dataIndex: "MO_TA",
    ...ellipsisProps,
    render: (MO_TA) => removeTags(MO_TA),
  },
  {
    title: i18n.t(languageKeys.ket_luan),
    width: 80,
    dataIndex: "KET_LUAN",
    ...ellipsisProps,
    render: (KET_LUAN) => removeTags(KET_LUAN),
  },
  {
    title: i18n.t(languageKeys.nguoi_tra_kq),
    width: 100,
    dataIndex: "NGUOI_TRA_KQ",
    ...ellipsisProps,
  },
  {
    title: i18n.t(languageKeys.thoi_gian_tra),
    width: 100,
    dataIndex: "NGAY_TRA_KET_QUA",
    render: (NGAY_TRA_KET_QUA, record) => (NGAY_TRA_KET_QUA ? record.GIO_TRA_KET_QUA + " - " + convertDateToValue(NGAY_TRA_KET_QUA) : "-"),
  },
  {
    title: i18n.t(languageKeys.da_TH),
    width: 80,
    // align:'right',
    ellipsis: true,
    dataIndex: "TRANG_THAI_TH",
    render: (TRANG_THAI_TH) =>
      TRANG_THAI_TH === trang_thai_th.DA_THUC_HIEN ? (
        <Tooltip title={i18n.t(languageKeys.da_thuc_hien)}>
          <Checked />
        </Tooltip>
      ) : (
        <Tooltip title={i18n.t(languageKeys.chua_thuc_hien)}>
          <UnCheck />
        </Tooltip>
      ),
  }

];
