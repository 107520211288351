import { Button, Form, Layout, Space, Tooltip } from "antd";
import { ModalPDF } from "components";
import { featureKeys } from "constants/keys";
import { debtManagementState, getRefundServiceList } from "ducks/slices/QlyVienPhi/QlyCongNo.slice";
import { handleCheckPermissions } from "helpers";
import { useEventListener } from "hooks";
import { createRef, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apis } from "../../../../../constants";
import i18n, { languageKeys } from "../../../../../i18n";
import style from "../../qlcn.module.less";
import Content from "./Content";
import DrawerHT from "./DrawerHT";
import Sider from "./Sider";


const pdfRefDaTT = createRef();

export const pdfRefDaTTControl = {
    show: ({ arr_HOA_DON_ID, keyPhieu }, endPoint) => pdfRefDaTT.current?.openModal({ arr_HOA_DON_ID, keyPhieu }, endPoint)
}


const CongNoDaTT = () => {
    const drawerRef = useRef()
    // const pdfRef = useRef()
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const { paidDebtList, patientInfo } = useSelector(debtManagementState)

    const userProfile = useSelector((state) => state.auth.user);
    const permission = handleCheckPermissions(userProfile, featureKeys.quan_ly_cong_no)

    const handleRefund = async () => {
        await form.validateFields()
        dispatch(getRefundServiceList(patientInfo.billId))
        drawerRef.current?.open()
    }

    const handlePrintAll = () => {
        if (paidDebtList?.length > 0) {
            const arr_HOA_DON_ID = paidDebtList?.filter(i => i.NHOM === "THANH_TOAN")?.map(item => item.HOA_DON_ID)
            pdfRefDaTT.current.openModal(
                { arr_HOA_DON_ID, keyPhieu: 'phieuCongNo' },
                patientInfo.LICH_KHAM_ID ? apis.in_cong_no_dv : apis.pdf_quan_ly_cong_no
            )
        }
    }

    const handlePrintBangKe = async() => {
        // console.log(`patientInfo`, patientInfo);
        // return 
        try {
            let dataRequest = {
              partner_code: userProfile.partner_code,
              BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
              BENH_NHAN_ID: patientInfo?.BENH_NHAN_ID,
              HOA_DON_ID: patientInfo.billId,
              GOI_DV_ID: patientInfo.GOI_DV_ID ? Number(patientInfo.GOI_DV_ID) : null, 
            }
            const api = apis.bangKeGoiLieuTrinh;
            pdfRefDaTT.current.openModal(dataRequest, api);
          } catch (error) {
           
          }
    }

    const keydownEvent = (event) => {
        if (!paidDebtList?.length > 0) return
        if (event.ctrlKey && event.key === "i") {
            event.stopPropagation();
            event.preventDefault();
            handlePrintAll();
        }
    }
    useEventListener("keydown", keydownEvent, window.document);


    const renderTitle = () => {
        if (!permission?.IN_PDF) {
            return i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)
        }
        if (!permission?.TAT_TOAN) {
            return i18n.t(languageKeys.chua_tat_toan_het)
        }

        return ""

    }

    return (
        <>
            <Layout>
                <Content />
                <Sider form={form} />
            </Layout>
            <Layout.Footer className={style['footer']}>
                <Space>
                    {
                        patientInfo?.IS_GOI ? (  <Tooltip title={renderTitle()}>
                        <Button disabled={!(permission?.IN_PDF && patientInfo?.TAT_TOAN ) } onClick={handlePrintBangKe} type="primary">{i18n.t(languageKeys.field_In_phieu)}</Button>
                    </Tooltip>) : ""
                    }
                    <Tooltip title={permission?.IN_PDF ? '' : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                        <Button disabled={!permission?.IN_PDF || !paidDebtList?.length > 0} onClick={handlePrintAll} type="primary">{i18n.t(languageKeys.common_In)} (Ctrl+I)</Button>
                    </Tooltip>
                    <Tooltip title={permission?.HOAN_TIEN ? '' : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                        <Button disabled={!permission?.HOAN_TIEN || !paidDebtList?.length > 0} type="primary" onClick={handleRefund}>
                            {i18n.t(languageKeys.vien_phi_Hoan_tien)}
                        </Button>
                    </Tooltip>
                </Space>
            </Layout.Footer>

            <DrawerHT ref={drawerRef} form={form} />
            <ModalPDF ref={pdfRefDaTT} />
        </>
    )
}

export default CongNoDaTT