import React, { useEffect, useMemo, useRef, useState } from "react";
import i18n, { languageKeys } from "../../../../../i18n";
import { Form, Row, Col, Input, Select, Tooltip, Tabs, Checkbox, Radio } from "antd";
import style from "./style.module.less";
import { FileCheckAlt } from "assets/svg";
import { InfoCircleOutlined } from "@ant-design/icons";
import { featureKeys, widthDrawerKeys } from "constants/keys";
import { FormDrawer } from "components";
import { useDispatch, useSelector } from "react-redux";
import { setDataDetail } from "ducks/slices/khamBenhSlice";
import { useEventListener } from "hooks";
import { handleCheckPermissions } from "helpers";
import moment from "moment";
import Constants from "constants/Constants";
import { keyFormKham } from "constants/data";
import KhamSanKTQ from "../KhamSan/KhamSanKTQ";
import { fieldKhamBenh } from "../constantsTTK";

const { Item } = Form;

const KhamTongQuan = ({ containerStyle = {}, readOnly, isEditAllowed = false, phongChiDinh, form }) => {
  const optionMallampati = useMemo(
    () => [
      { label: i18n.t(languageKeys.mallampati_1), value: "LOP1" },
      { label: i18n.t(languageKeys.mallampati_2), value: "LOP2" },
      { label: i18n.t(languageKeys.mallampati_3), value: "LOP3" },
      { label: i18n.t(languageKeys.mallampati_4), value: "LOP4" },
    ],
    []
  );

  const optionStopBang = useMemo(
    () => [
      { label: i18n.t(languageKeys.stop_bang_ngay_to), value: "STOP_BANG_NGAYTO" },
      { label: i18n.t(languageKeys.stop_bang_thuong_met), value: "STOP_BANG_THUONGMET" },
      { label: i18n.t(languageKeys.stop_bang_quan_sat), value: "STOP_BANG_QUANSAT" },
      { label: i18n.t(languageKeys.stop_bang_huyet_ap), value: "STOP_BANG_HUYETAP" },
      { label: i18n.t(languageKeys.stop_bang_bmi), value: "STOP_BANG_MBI" },
      { label: i18n.t(languageKeys.stop_bang_tuoi), value: "STOP_BANG_TUOI" },
      { label: i18n.t(languageKeys.stop_bang_chu_vi), value: "STOP_BANG_CHUVI" },
      { label: i18n.t(languageKeys.stop_bang_gt_nam), value: "STOP_BANG_GTNAM" },
    ],
    []
  );

  const KHAM_NOI_GIAC_NGU_TEXT = Form.useWatch("KHAM_NOI_GIAC_NGU_TEXT", form);
  const KHAM_NOI_GIAC_NGU = Form.useWatch("KHAM_NOI_GIAC_NGU", form);
  const TONG_DIEM_BUON_NGU = Form.useWatch("TONG_DIEM_BUON_NGU", form);
  const DIEM_STOP_BANG = Form.useWatch("DIEM_STOP_BANG", form);

  const BMI = Form.useWatch("BMI", form);
  const VONG_CO = Form.useWatch("VONG_CO", form);


  const [visibleStopBang, setVisibleStopBang] = useState(false);
  const [visibleEpworth, setVisibleEpworth] = useState(false);
  const [totalDraw, setTotalDraw] = useState(0);
  const first = useRef()

  const dispatch = useDispatch();
  const dataLichKham = useSelector((state) => state.khamBenh.dataDetail);
  const userProfile = useSelector((state) => state.auth.user);
  const { thongTinSelect } = useSelector((state) => state.khamBenh);

  const optionsAns = [
    { label: i18n.t(languageKeys.danh_gia_tt_buon_ngu_qs1), value: 0 },
    { label: i18n.t(languageKeys.danh_gia_tt_buon_ngu_qs2), value: 1 },
    { label: i18n.t(languageKeys.danh_gia_tt_buon_ngu_qs3), value: 2 },
    { label: i18n.t(languageKeys.danh_gia_tt_buon_ngu_qs4), value: 3 },
  ]

  const optionsQs = [
    { label: `${i18n.t(languageKeys.ngoi_va_doc)}`, name: "NGOI_VA_DOC" },
    { label: `${i18n.t(languageKeys.xem_truyen_hinh)}`, name: "XEM_TRUYEN_HINH" },
    { label: `${i18n.t(languageKeys.ngoi_yen_cong_cong)}`, name: "NGOI_YEN_O_NOI_CC" },
    { label: `${i18n.t(languageKeys.ngoi_im_mot_tieng)}`, name: "NGOI_TRONG_MOT_TIENG_VOI_TU_CACH_LA_KH_TREN_OTO" },
    { label: `${i18n.t(languageKeys.nam_nghi_buoi_chieu)}`, name: "NAM_NGHI_VAO_BUOI_CHIEU" },
    { label: `${i18n.t(languageKeys.ngoi_va_noi_chuyen)}`, name: "NGOI_VA_NOI_CHUYEN_VOI_NGUOI_KHAC" },
    { label: `${i18n.t(languageKeys.ngoi_im_lang_sau_an_chua)}`, name: "NGOI_IM_LANG_SAU_AN_TRUA" },
    { label: `${i18n.t(languageKeys.ngoi_trong_xe_oto)}`, name: "NGOI_TRONG_XE_OTO" },
  ]


  useEffect(() => {
    if (!!!form) return;
    setTotalDraw(TONG_DIEM_BUON_NGU || 0)
    if (1 <= TONG_DIEM_BUON_NGU && TONG_DIEM_BUON_NGU <= 6) {
      form.setFieldsValue({
        TONG_DIEM_BUON_NGU_SHOW: `${TONG_DIEM_BUON_NGU} - ${i18n.t(languageKeys.epworth_1_6)}`,
      });
      return;
    }

    if (7 <= TONG_DIEM_BUON_NGU && TONG_DIEM_BUON_NGU <= 8) {
      form.setFieldsValue({
        TONG_DIEM_BUON_NGU_SHOW: `${TONG_DIEM_BUON_NGU} - ${i18n.t(languageKeys.epworth_7_8)}`,
      });
      return;
    }

    if (9 <= TONG_DIEM_BUON_NGU && TONG_DIEM_BUON_NGU <= 24) {
      form.setFieldsValue({
        TONG_DIEM_BUON_NGU_SHOW: `${TONG_DIEM_BUON_NGU} - ${i18n.t(languageKeys.epworth_9_24)}`,
      });
      return;
    }

    form.setFieldsValue({
      TONG_DIEM_BUON_NGU_SHOW: ``,
    });
  }, [TONG_DIEM_BUON_NGU]);


  useEffect(() => {
    if (!!!form) return;

    form.setFieldsValue({ KHAM_NOI_GIAC_NGU_TEXT: KHAM_NOI_GIAC_NGU ? "KHAM_NOI_GIAC_NGU" : "KHAM_TONG_QUAT" })
  }, [KHAM_NOI_GIAC_NGU, form])


  useEffect(() => {
    if (!!!form) return;

    if (3 <= DIEM_STOP_BANG) {
      form.setFieldsValue({
        DIEM_STOP_BANG_SHOW: `${DIEM_STOP_BANG} - ${i18n.t(languageKeys.stop_bang_cao)}`,
      });
      return;
    }

    if (DIEM_STOP_BANG > 0 && DIEM_STOP_BANG < 3) {
      form.setFieldsValue({
        DIEM_STOP_BANG_SHOW: `${DIEM_STOP_BANG} - ${i18n.t(languageKeys.stop_bang_thap)}`,
      });
      return;
    }

    form.setFieldsValue({
      DIEM_STOP_BANG_SHOW: ``,
    });
  }, [DIEM_STOP_BANG]);


  useEffect(() => {
    if (thongTinSelect?.TRANG_THAI == "CHO_KHAM" && KHAM_NOI_GIAC_NGU) {
      const arrayInitStopBAng = []
      let DO_TUOI = null
      if (BMI > Constants.PROPOSE_BMI) {
        arrayInitStopBAng.push("STOP_BANG_MBI")
      }
      if (VONG_CO > Constants.PROPOSE_VONG_CO) {
        arrayInitStopBAng.push("STOP_BANG_CHUVI")
      }
      if (thongTinSelect?.NGAY_SINH) {
        if (thongTinSelect?.NGAY_SINH?.length == 4) {
          const ngaySinh = moment(thongTinSelect?.NGAY_SINH).format('YYYY')
          DO_TUOI = moment().diff(ngaySinh, 'years');
        }

        if (thongTinSelect?.NGAY_SINH?.length == 8) {
          const ngaySinh = moment(thongTinSelect?.NGAY_SINH).format('YYYYMMDD')
          DO_TUOI = moment().diff(ngaySinh, 'years');
        }

        if (DO_TUOI > Constants.PROPOSE_AGE) {
          arrayInitStopBAng.push("STOP_BANG_TUOI")
        }
      }
      if (thongTinSelect?.GIOI_TINH == Constants.PROPOSE_SEX) {
        arrayInitStopBAng.push("STOP_BANG_GTNAM")
      }

      form.setFieldsValue({ DIEM_STOP_BANG: arrayInitStopBAng?.length || 0, THANG_DIEM_STOP_BANG: arrayInitStopBAng })

      const objNew = { ...dataLichKham, DIEM_STOP_BANG: arrayInitStopBAng?.length || 0, THANG_DIEM_STOP_BANG: arrayInitStopBAng, isTheFirst: false }
      dispatch(setDataDetail(objNew))
    }
  }, [thongTinSelect?.LICH_KHAM_ID, KHAM_NOI_GIAC_NGU])


  useEffect(() => {
    if (dataLichKham?.isTheSecond && KHAM_NOI_GIAC_NGU) {
      const arrayInitStopBAng = []
      let DO_TUOI = null
      if (BMI > Constants.PROPOSE_BMI) {
        arrayInitStopBAng.push("STOP_BANG_MBI")
      }
      if (VONG_CO > Constants.PROPOSE_VONG_CO) {
        arrayInitStopBAng.push("STOP_BANG_CHUVI")
      }
      if (thongTinSelect?.NGAY_SINH) {
        if (thongTinSelect?.NGAY_SINH?.length == 4) {
          const ngaySinh = moment(thongTinSelect?.NGAY_SINH).format('YYYY')
          DO_TUOI = moment().diff(ngaySinh, 'years');
        }

        if (thongTinSelect?.NGAY_SINH?.length == 8) {
          const ngaySinh = moment(thongTinSelect?.NGAY_SINH).format('YYYYMMDD')
          DO_TUOI = moment().diff(ngaySinh, 'years');
        }

        if (DO_TUOI > Constants.PROPOSE_AGE) {
          arrayInitStopBAng.push("STOP_BANG_TUOI")
        }
      }
      if (thongTinSelect?.GIOI_TINH == Constants.PROPOSE_SEX) {
        arrayInitStopBAng.push("STOP_BANG_GTNAM")
      }

      form.setFieldsValue({ DIEM_STOP_BANG: arrayInitStopBAng?.length || 0, THANG_DIEM_STOP_BANG: arrayInitStopBAng })

      const objNew = { ...dataLichKham, DIEM_STOP_BANG: arrayInitStopBAng?.length || 0, THANG_DIEM_STOP_BANG: arrayInitStopBAng }
      dispatch(setDataDetail(objNew))
    }
  }, [thongTinSelect?.LICH_KHAM_ID, BMI, VONG_CO])


  const onCloseStopBang = () => {
    setVisibleStopBang(false);
  }

  const onOpenStopBang = () => {
    setVisibleStopBang(true);
    form && form.setFieldsValue({ THANG_DIEM_STOP_BANG: dataLichKham["THANG_DIEM_STOP_BANG"] })
  }

  const onCloseEpworth = () => {
    setVisibleEpworth(false);
  }

  const onOpenEpworth = () => {

    const outputObject = {};
    optionsQs.forEach((item) => {
      outputObject[item.name] = item.name;
    });

    const arrayObjEp = Object.values(outputObject).map((key) => {
      return {
        name: key,
        value: dataLichKham?.[key] || 0,
      };
    });

    setVisibleEpworth(true);
    form && form.setFields(arrayObjEp)
    setTimeout(() => {
      onChangeQs()
    },);
  };

  const onOkStopbang = () => {
    if (!!!form) return;

    const value = form.getFieldValue("THANG_DIEM_STOP_BANG")
    form.setFieldsValue({ DIEM_STOP_BANG: value?.length || 0 })

    const objNew = { ...dataLichKham, DIEM_STOP_BANG: value?.length, THANG_DIEM_STOP_BANG: value }
    dispatch(setDataDetail(objNew))
    onCloseStopBang()
  }

  const onOkEpworth = () => {
    if (!!!form) return;

    const arrayName = optionsQs.map(obj => obj.name)
    let valueEP = form.getFieldsValue(arrayName)
    for (const key in valueEP) {
      if (valueEP.hasOwnProperty(key)) {
        // Kiểm tra nếu giá trị của khóa là null hoặc undefined
        if (valueEP[key] === null || valueEP[key] === undefined) {
          // Đặt giá trị của khóa thành 0
          valueEP[key] = 0;
        }
      }
    }

    const sumValues = Object.values(valueEP).reduce((a, b) => a + b, 0);

    form.setFieldsValue({ TONG_DIEM_BUON_NGU: sumValues || 0 })
    const objNew = { ...dataLichKham, ...valueEP, TONG_DIEM_BUON_NGU: sumValues }
    dispatch(setDataDetail(objNew))
    onCloseEpworth()
  }

  const onChangeQs = () => {
    if (!!!form) return;

    const arrayName = optionsQs.map(obj => obj.name)
    const valueEP = form.getFieldsValue(arrayName)
    const sumValues = Object.values(valueEP).reduce((a, b) => (a || 0) + (b || 0), 0);
    setTotalDraw(sumValues)
  }

  const checkRoleArr = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.thong_tin_kham)
  }, [userProfile])

  const keydownEvent = (event) => {
    const stopDefault = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    if (event.ctrlKey && event.key === "s") {
      if (!isEditAllowed || !(checkRoleArr?.SUA)) return
      if (visibleEpworth) {
        stopDefault(event);
        onOkEpworth()
      }
      if (visibleStopBang) {
        stopDefault(event);
        onOkStopbang()
      }
    }
    if (event.key === "Escape") {
      if (visibleEpworth) {
        stopDefault(event);
        onCloseEpworth();
      }
      if (visibleStopBang) {
        stopDefault(event);
        onCloseStopBang();
      }

    }
  };

  useEventListener("keydown", keydownEvent, window.document);


  const renderForm = () => {

    if (phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_SAN)) {
      return (
        <KhamSanKTQ
          readOnly={readOnly}
          disabled={!isEditAllowed}
        />
      )
    }

    return (
      <>
        <Form.Item label={i18n.t(languageKeys.toan_than)} name={fieldKhamBenh.TOAN_THAN}>
          <Input.TextArea
            autoSize={{ minRows: 2 }}
            readOnly={readOnly}
            disabled={!isEditAllowed}
            placeholder={i18n.t(languageKeys.common_Nhap)}
          />
        </Form.Item>

        <Form.Item label={i18n.t(languageKeys.cac_bo_phan)} name={fieldKhamBenh.CAC_BO_PHAN}>
          <Input.TextArea
            autoSize={{ minRows: 2 }}
            readOnly={readOnly}
            disabled={!isEditAllowed}
            placeholder={i18n.t(languageKeys.common_Nhap)}
          />
        </Form.Item>

        <Row wrap={false} justify="space-between">
          <Col flex={"49%"}>
            <Form.Item label={i18n.t(languageKeys.tuan_hoan)} name={fieldKhamBenh.TUAN_HOAN}>
              <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col flex={"49%"}>
            <Form.Item label={i18n.t(languageKeys.ho_hap)} name={fieldKhamBenh.HO_HAP}>
              <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>

        <Row wrap={false} justify="space-between">
          <Col flex={"49%"}>
            <Form.Item label={i18n.t(languageKeys.tieu_hoa)} name={fieldKhamBenh.TIEU_HOA}>
              <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
          <Col flex={"49%"}>
            <Form.Item label={i18n.t(languageKeys.than_tiet_nieu)} name={fieldKhamBenh.THAN_TIET_NIEU}>
              <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>

        <Row wrap={false} justify="space-between">
          <Col flex={"49%"}>
            <Form.Item label={i18n.t(languageKeys.noi_tiet)} name={fieldKhamBenh.NOI_TIET}>
              <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col flex={"49%"} style={{ marginLeft: 10 }}>
            <Form.Item label={i18n.t(languageKeys.co_xuong_khop)} name={fieldKhamBenh.CO_XUONG_KHOP}>
              <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>

        <Row wrap={false} justify="space-between">
          <Col flex={"49%"}>
            <Form.Item label={i18n.t(languageKeys.than_kinh)} name={fieldKhamBenh.THAN_KINH}>
              <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          {(phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_NOI_GIAC_NGU) || phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_NOI_TONG_QUAT)) && (
            <Col flex={"49%"} style={{ marginLeft: 10 }}>
              <Form.Item label={i18n.t(languageKeys.mental)} name={fieldKhamBenh.TAM_THAN}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>
          )}
        </Row>
      </>
    )
  }


  return (
    <div style={containerStyle} className={style["form-container"]} >
      <Form.Item style={{ display: "none" }} name="KHAM_NOI_GIAC_NGU" >
        <Radio.Group>
          <Radio value={true}>true</Radio>
          <Radio value={false}>false</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item style={{ display: "none" }} name={"KHAM_NOI_GIAC_NGU_TEXT"}>
        <Input />
      </Form.Item>
      <Form.Item style={{ display: "none" }} name={"LICH_KHAM_ID"}>
        <Input />
      </Form.Item>

      <Tabs defaultActiveKey={"KHAM_TONG_QUAT"} className={style["tab-ctn"]} activeKey={KHAM_NOI_GIAC_NGU_TEXT}>

        <Tabs.TabPane key={"KHAM_TONG_QUAT"}>
          {renderForm()}
        </Tabs.TabPane>

        <Tabs.TabPane key={"KHAM_NOI_GIAC_NGU"} style={{ height: "100%" }}>
          <Row justify="space-between">
            <Col span={24}>
              <Form.Item label={i18n.t(languageKeys.kham_hong)} name={fieldKhamBenh.KHAM_HONG}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={i18n.t(languageKeys.diem_mallampati)} name={fieldKhamBenh.DIEM_MALLAMPATI}>
                <Select options={optionMallampati} readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Chon)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={i18n.t(languageKeys.cac_bo_phan_khac)} name={fieldKhamBenh.BO_PHAN_KHAC}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div>
                <Form.Item style={{ display: "none" }} label={i18n.t(languageKeys.diem_epworth)} name={fieldKhamBenh.TONG_DIEM_BUON_NGU}>
                  <Input />
                </Form.Item>
                <Form.Item label={i18n.t(languageKeys.diem_epworth)} name={"TONG_DIEM_BUON_NGU_SHOW"} onClick={onOpenEpworth}>
                  <Input
                    readOnly={true}
                    suffix={
                      <Tooltip
                        placement="topLeft"
                        title={
                          <span>
                            <span>1-6 {i18n.t(languageKeys.epworth_1_6)}</span>
                            <br />
                            <span>7-8 {i18n.t(languageKeys.epworth_7_8)}</span>
                            <br />
                            <span>9-24 {i18n.t(languageKeys.epworth_9_24)}</span>
                          </span>
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    }
                  />
                </Form.Item>
                <span className={style["info"]} onClick={onOpenEpworth}>
                  <FileCheckAlt />
                  {i18n.t(languageKeys.danh_gia_tt_lq_giac_ngu)}
                </span>
              </div>
            </Col>

            <Col span={24}>
              <div>
                <Form.Item style={{ display: "none" }} label={i18n.t(languageKeys.diem_epworth)} name={fieldKhamBenh.DIEM_STOP_BANG}>
                  <Input />
                </Form.Item>
                <Form.Item label={i18n.t(languageKeys.diem_stop_bang)} name={"DIEM_STOP_BANG_SHOW"} onClick={onOpenStopBang}>
                  <Input
                    readOnly={true}
                    suffix={
                      <Tooltip
                        placement="topLeft"
                        title={
                          <span>
                            <span>
                              {i18n.t(languageKeys.stop_bang_min3)} {i18n.t(languageKeys.stop_bang_cao)}
                            </span>
                            <br />
                            <span>
                              {i18n.t(languageKeys.stop_bang_max3)} {i18n.t(languageKeys.stop_bang_thap)}
                            </span>
                          </span>
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    }
                  />
                </Form.Item>
                <span className={style["info"]} onClick={onOpenStopBang}>
                  <FileCheckAlt />
                  {i18n.t(languageKeys.danh_gia_stop_bang)}
                </span>
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>

      {/* <Form.Item label={i18n.t(languageKeys.than_kinh)}  name={fieldKhamBenh.THAN_KINH}>
                <Input.TextArea autoSize readOnly={readOnly} disabled={!isEditAllowed} placeholder={i18n.t(languageKeys.common_Nhap)}/>
            </Form.Item> */}

      <FormDrawer
        title={i18n.t(languageKeys.thang_diem_stop_bang)}
        placement="right"
        width={widthDrawerKeys.small}
        onCancel={onCloseStopBang}
        onOk={onOkStopbang}
        visible={visibleStopBang}
        closable={false}
        maskClosable={false}
        back={true}
        newBackIcon={true}
        // hiddenTopAction={true}
        buttonsAtBottomOfContent={true}
        forceRender={true}
        disableAddBtn={false}
        disableOkBtn={false}
      >
        <div>
          <div className={style["form-stop-bang"]}>
            <Form.Item name={"THANG_DIEM_STOP_BANG"} label={false}>
              <Checkbox.Group>
                <Row>
                  {optionStopBang.map((objValue) => (
                    <Col span={24}>
                      <Checkbox
                        value={objValue.value}
                        style={{
                          lineHeight: "32px",
                        }}


                      >
                        <span className={style["value-option"]}>{objValue.label}</span>
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <span className={style["form-stop-bang_noti_wrap"]}>
            <span className={style["form-stop-bang_dot"]}>*</span>
            <span className={style["form-stop-bang_noti"]}>
              <span>
                {i18n.t(languageKeys.stop_bang_min3)}
                {i18n.t(languageKeys.stop_bang_cao)}
              </span>
              <span>
                {i18n.t(languageKeys.stop_bang_max3)}
                {i18n.t(languageKeys.stop_bang_thap)}
              </span>
            </span>
          </span>
        </div>
      </FormDrawer>

      <FormDrawer
        title={i18n.t(languageKeys.danh_gia_tt_lq_giac_ngu)}
        placement="right"
        width={widthDrawerKeys.small}
        onCancel={onCloseEpworth}
        onOk={onOkEpworth}
        visible={visibleEpworth}
        closable={false}
        maskClosable={false}
        back={true}
        newBackIcon={true}
        // hiddenTopAction={true}
        buttonsAtBottomOfContent={true}
        forceRender={true}
        disableOkBtn={false}
        disableAddBtn={false}
      >
        <Form form={form}>
          <div style={{ height: "calc(100vh - 130px)", overflow: "auto" }} ref={first}>
            <div className={style["form-stop-bang"]}>
              <Row align={"middle"}>
                {optionsQs.map((objQs) => (
                  <Col span={24}>
                    <p style={{ height: 10 }}></p>
                    <span className={style["text"]} style={{ fontSize: 16, fontWeight: 700, color: "#2C3782" }}>
                      {objQs.label}
                    </span>
                    <Item name={objQs.name}>
                      <Radio.Group style={{ display: "flex", flexDirection: "column" }} onChange={() => onChangeQs()}  >
                        {optionsAns.map((objValue) => (
                          <Radio value={objValue.value} >
                            <span className={style["value-option"]}>{objValue.label}</span>
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Item>
                  </Col>
                ))}
              </Row>
            </div>
            <div className={style["form-stop-bang_noti_wrap"]} style={{ width: "100%" }}>
              <Row gutter={[10, 10]} style={{ width: "100%" }}>
                <Col span={24}>
                  <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                    <div className={style["so_diem_tc_wrap"]}>
                      <h2 className={style["so_diem_tc"]}>{i18n.t(languageKeys.so_diem_tc)}</h2>
                      <p>{totalDraw}</p>
                    </div>
                    <span>
                      <span className={style["value-option"]}>1-6 {i18n.t(languageKeys.epworth_1_6)}</span>
                      <br />
                      <span className={style["value-option"]}>7-8 {i18n.t(languageKeys.epworth_7_8)}</span>
                      <br />
                      <span className={style["value-option"]}>9-24 {i18n.t(languageKeys.epworth_9_24)}</span>
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </FormDrawer>
    </div>
  );
};
export default React.memo(KhamTongQuan);
