import { Checkbox, Image, Form, Row, Radio } from "antd";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import style from "./style.module.less";
import { languageKeys } from "i18n";
import i18n from "i18n";
import { arrayToothAdultBottom, arrayToothAdultTop, arrayToothChildBottom, arrayToothChildTop, value_SO_DO_RANG } from "./constToothDiafram";
import { isArray } from "lodash";
import { useSelector } from "react-redux";
import { fieldKhamBenh } from "../constantsTTK";

const ToothDiagram = ({ form, isPopup }) => {
  
  const [brokenTeeth, setBrokenTeeth] = useState();
  const [toothDiagram, setToothDiagram] = useState({ top: [], bottom: [] });
  const RANG_TON_THUONG = Form.useWatch(fieldKhamBenh.RANG_TON_THUONG, form);
  const { dataDetail, dataDetailPopup } = useSelector((state) => state.khamBenh);

  const SO_DO_RANG = Form.useWatch("SO_DO_RANG", form);

  // useEffect(() => {
  //   form.setFieldsValue({ brokenTeeth: ["R26", "R25"], targetDiagram: "adult_jaw" });
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      const value = form.getFieldsValue([fieldKhamBenh.RANG_TON_THUONG]);
      setBrokenTeeth(value[fieldKhamBenh.RANG_TON_THUONG]);
    });
  }, [RANG_TON_THUONG]);

  useEffect(() => {
    const value = form.getFieldsValue(["SO_DO_RANG"]);

    if (value.SO_DO_RANG === value_SO_DO_RANG.child_jaw) {
      setToothDiagram({ top: arrayToothChildTop, bottom: arrayToothChildBottom });
    } else {
      setToothDiagram({ top: arrayToothAdultTop, bottom: arrayToothAdultBottom });
    }
  }, [SO_DO_RANG]);

  const onChangeDiagram = (value) => {
    if (!isPopup && dataDetail) {
      if (value == dataDetail?.SO_DO_RANG) {
        form.setFieldsValue({
          RANG_TON_THUONG: isArray(dataDetail?.RANG_TON_THUONG) ? dataDetail?.RANG_TON_THUONG : dataDetail?.RANG_TON_THUONG.split(","),
        });
      } else {
        form.setFieldsValue({ RANG_TON_THUONG: [] });
      }
      return;
    }

    if (isPopup && dataDetailPopup) {
      if (value == dataDetailPopup?.SO_DO_RANG) {
        form.setFieldsValue({
          RANG_TON_THUONG: isArray(dataDetailPopup?.RANG_TON_THUONG) ? dataDetailPopup?.RANG_TON_THUONG : dataDetailPopup?.RANG_TON_THUONG.split(","),
        });
      } else {
        form.setFieldsValue({ RANG_TON_THUONG: [] });
      }
      return;
    }
    setBrokenTeeth([]);
    form.setFieldsValue({ RANG_TON_THUONG: null });
  };

  return (
    <div className={style["mask-group"]}>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 27,
          }}
        >
          <label>{i18n.t(languageKeys.tooth_diagram)}</label>
          <Form.Item name="SO_DO_RANG" label={false}>
            <Radio.Group
              onChange={(e) => {
                onChangeDiagram(e.target.value);
              }}
              style={{
                display: "flex",
                gap: 12,
              }}
              buttonStyle="solid"
              className={cn({ [style.groupButton]: true })}
              defaultValue={value_SO_DO_RANG.adult_jaw}
            >
              <Radio.Button value={value_SO_DO_RANG.child_jaw}>{i18n.t(languageKeys.child_jaw)}</Radio.Button>
              <Radio.Button value={value_SO_DO_RANG.adult_jaw}>{i18n.t(languageKeys.adult_jaw)}</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      <Form.Item name={fieldKhamBenh.RANG_TON_THUONG} label={false}>
        <Checkbox.Group style={{ width: "100%" }}>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            {toothDiagram.top.map((value) => {
              return (
                <>
                  <Checkbox
                    value={value?.value}
                    className={cn({ [style.marginLeft]: value?.value === "R21" || value?.value === "R31" || value?.value === "R61" })}
                  >
                    <Image height={40} src={value?.image} preview={false} className={cn({ [style.mask]: brokenTeeth?.includes(value?.value) })} />
                  </Checkbox>
                </>
              );
            })}
          </Row>
          <Row style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>
            {toothDiagram.bottom.map((value) => {
              return (
                <>
                  <Checkbox value={value?.value} className={cn({ [style.marginLeft31]: value?.value === "R31" || value?.value === "R71" })}>
                    <Image height={40} src={value?.image} preview={false} className={cn({ [style.mask]: brokenTeeth?.includes(value?.value) })} />
                  </Checkbox>
                </>
              );
            })}
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <p style={{ paddingBottom: 8, marginTop: 17 }}>
        <label style={{ fontSize: 13, fontWeight: 500 }}>
          {i18n.t(languageKeys.damaged_teeth)}: {isArray(brokenTeeth) ? brokenTeeth?.slice()?.sort()?.join(", ") : brokenTeeth}
        </label>
      </p>
    </div>
  );
};

export default ToothDiagram;
