import { useCallback, useEffect, useState } from "react";
import { apiLayDsHuyen, apiLayDsTinh } from "../apisDanhMuc/apisDanhMuc";
import { debounce } from "lodash";
import formDanhMuc from "./formDanhMuc.module.less";
import i18n, { languageKeys } from "../../../../i18n";
import { Col, Form, Input, Row } from "antd";
import { fieldHuyen, fieldTinh, fieldXa } from "../fieldsDanhMuc";
import { Select } from "../../../../components";
import { keys } from "../../../../constants";

const FormXa = ({
  initData = {},
  visible = false,
  isCreateNew = false,
  form,
}) => {
  const [tinhTp, setTinhTp] = useState([]);
  const [quanHuyen, setQuanHuyen] = useState([]);
  const [selectedTinh, setSelectedTinh] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchTinh = useCallback(
    debounce((value) => searchTinh(value), 1000),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchHuyen = useCallback(
    debounce((value, tinh) => searchHuyen(value, tinh), 1000),
    []
  );
  useEffect(() => {
    if (visible) {
      layDsTinhTP();
      layDsQuanHuyen();
      if (!isCreateNew) {
        form.setFields([
          {
            name: fieldXa.ma_tinh,
            value: initData[fieldTinh.ten_tinh],
          },
          {
            name: fieldXa.ma_huyen,
            value: initData[fieldHuyen.ten_huyen],
          },
        ]);
      }
    } else {
      setSelectedTinh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (selectedTinh) {
      layDsQuanHuyen("", selectedTinh.MA_TINH_THANH);
    }
  }, [selectedTinh]);

  const layDsTinhTP = async (search_string) => {
    try {
      const data = await apiLayDsTinh({
        page: 1,
        search_string: search_string,
      });
      if (data.status === "OK") {
        setTinhTp(data.result);
      }
    } catch (error) {}
  };
  const layDsQuanHuyen = async (search_string, MA_TINH_THANH = "") => {
    try {
      const data = await apiLayDsHuyen({
        page: 1,
        search_string: search_string,
        MA_TINH_THANH: MA_TINH_THANH,
        limit: keys.limit,
      });
      if (data.status === "OK") {
        setQuanHuyen(data.result);
      }
    } catch (error) {}
  };

  const searchTinh = (value) => {
    layDsTinhTP(value);
  };

  const searchHuyen = (value, tinh) => {
    layDsQuanHuyen(value, tinh.MA_TINH_THANH);
  };

  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={fieldXa.id_xa}
                label={i18n.t(languageKeys.field_Ma_xa)}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldXa.ma_xa_byt}
                label={i18n.t(languageKeys.field_Ma_xa_byt)}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name={fieldXa.ten_xa}
                label={i18n.t(languageKeys.field_Ten_xa)}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldXa.ma_tinh}
                label={i18n.t(languageKeys.field_Tinh_thanh)}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  onSearch={debounceSearchTinh}
                  dataSource={tinhTp}
                  titleKey={fieldTinh.ten_tinh}
                  showSearch
                  onSelect={(value) => {
                    setSelectedTinh(JSON.parse(value));
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldXa.ma_huyen}
                label={i18n.t(languageKeys.field_Quan_huyen)}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  onSearch={(value) => debounceSearchHuyen(value, selectedTinh)}
                  dataSource={quanHuyen}
                  titleKey={fieldHuyen.ten_huyen}
                  showSearch
                  disabled={selectedTinh ? false : true}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default FormXa;
