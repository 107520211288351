import { ConfirmModal, FormDrawer } from "components"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import i18n, { languageKeys } from "../../../../../i18n";
import styles from "./hoanTien.module.less";
import { Button, Col, Form, Input, Layout, Row, Space } from "antd";
import { keyViews } from "../../DichVuCanThanhToan";
import DichVuChuaThanhToan from "../DichVuChuaThanhToan";
import { common_post, formatDateOfBirth, formatDay, getFullDiaChi, getSexFromString, rid } from "helpers";
import { apis } from "../../../../../constants";
import { useEventListener } from "hooks";

const ModalSuaPhieu = ({ patientInfo, onPrint, layDsDichDaThanhToan }, ref) => {

  const paidRef = useRef()
  const okRef = useRef()

  const [open, setOpen] = useState(false)
  const [skipEffect, setSkipEffect] = useState(true)
  const [ID, setID] = useState()

  const [form] = Form.useForm()

  useImperativeHandle(ref, () => ({
    open: (data) => setTimeout(() => handleOpen(data), 100)
  }))

  const handleOpen = async (data) => {
    setOpen(true)
    setID(data.HOA_DON_ID)
    paidRef.current.setLoadingDsDv(true)
    try {
      const res = await common_post(apis.layChiTietThanhToanTheoHoaDon, {
        HOA_DON_ID: data.HOA_DON_ID,
        HOA_DON_GOI: patientInfo.LOAI === "THANH_TOAN_MUA_GOI" ? 1 : 0,
        BENH_NHAN_ID: patientInfo.BENH_NHAN_ID,
      })
      if (res.status === "OK") {
        const { dichVu, hoaDon, hinhThucThanhToan, benhNhan, thongTinGoi } = res.result
        form.setFields([
          {
            name: "TEN",
            value: benhNhan.TEN
          },
          {
            name: "GIOI_TINH",
            value: getSexFromString(benhNhan.GIOI_TINH)
          },
          {
            name: "NGAY_SINH",
            value: formatDateOfBirth(benhNhan.NGAY_SINH)
          },
          {
            name: "NGAY_KHAM",
            value: formatDay(benhNhan.NGAY_TIEP_DON)
          },
          {
            name: "DIA_CHI",
            value: getFullDiaChi(benhNhan)
          },
        ])
        paidRef.current.setFields([
          {
            name: "MIEN_GIAM",
            value: hoaDon.MIEN_GIAM_TONG_HOA_DON
          },
          {
            name: "LY_DO_MIEN_GIAM",
            value: hoaDon.LY_DO_MIEN_GIAM
          },
          {
            name: "list_dv_thanh_toan",
            value: hinhThucThanhToan
          },
          {
            name: "SO_HOADON_ID",
            value: hoaDon.SO_HOADON_ID
          },
          {
            name: "TIEN_KHACH_DUA",
            value: hoaDon.TIEN_TT_THUC_TE
          },
          {
            name: "GHI_CHU",
            value: hoaDon.GHI_CHU
          },
        ])
        //
        let dsdv = []
        if (patientInfo.LOAI === "THANH_TOAN_MUA_GOI") {
          dsdv = [{
            ...thongTinGoi,
            key: thongTinGoi.GOI_ID,
            DON_GIA: thongTinGoi.TONG_TIEN_PHAN_BO,
            SO_LUONG: 1,
            MIEN_GIAM_DICH_VU: hoaDon.MIEN_GIAM || 0,
            GIAM_TIEN: hoaDon.MIEN_GIAM || 0,
            TONG_TIEN_GIAM: hoaDon.MIEN_GIAM || 0,
            GIAM_PHAN_TRAM: 0,
            DS_DV: dichVu.map(item => ({ ...item, ID: item.RLS_BN_GOI_DV_ID }))
          }]
          paidRef.current.setExpandedRow([thongTinGoi.GOI_ID])
        } else {
          dsdv = dichVu.map((item) => ({
            ...item,
            key: rid(),
            ID: item.PDT_ID,
            //
            MIEN_GIAM_DICH_VU: item.IS_GOI_DV
              ? item.DON_GIA * item.SO_LUONG
              : item.MIEN_GIAM_TIEP_DON
                ? item.MIEN_GIAM_TIEP_DON > 100 ? item.MIEN_GIAM_TIEP_DON : (item.SO_LUONG * item.DON_GIA / 100 * item.MIEN_GIAM_TIEP_DON)
                : 0,
            //
            GIAM_TIEN: item.IS_GOI_DV
              ? item.DON_GIA * item.SO_LUONG
              : item.MIEN_GIAM_TIEP_DON
                ? item.MIEN_GIAM_TIEP_DON > 100 ? item.MIEN_GIAM_TIEP_DON : (item.SO_LUONG * item.DON_GIA / 100 * item.MIEN_GIAM_TIEP_DON)
                : 0,
            //
            TONG_TIEN_GIAM: item.IS_GOI_DV
              ? item.DON_GIA * item.SO_LUONG
              : item.MIEN_GIAM_TIEP_DON
                ? item.MIEN_GIAM_TIEP_DON > 100 ? item.MIEN_GIAM_TIEP_DON : (item.SO_LUONG * item.DON_GIA / 100 * item.MIEN_GIAM_TIEP_DON)
                : 0,
            //
            GIAM_PHAN_TRAM: item.IS_GOI_DV
              ? item.DON_GIA * item.SO_LUONG
              : item.MIEN_GIAM_TIEP_DON
                ? item.MIEN_GIAM_TIEP_DON <= 100 ? item.MIEN_GIAM_TIEP_DON : 0
                : 0,
          }));
          paidRef.current.setExpandedRow(['KHAM_DICH_VU', 'KHAM_GOI_DICH_VU'])
        }
        paidRef.current.setDataSource(dsdv)
        paidRef.current.setSelectedService(dsdv)
      }
    } catch (e) {
      console.log(e)
    } finally {
      paidRef.current.setLoadingDsDv(false)
      setSkipEffect(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
    form.resetFields()
    setSkipEffect(true)
    setID()
  }

  const keydownEvent = (event) => {
    const preventDefault = () => {
      event.stopPropagation();
      event.preventDefault();
    }
    if (event.ctrlKey && event.key === "t") {
      preventDefault()
      okRef.current.open(true)
    }
    if (event.ctrlKey && event.key === "l") {
      preventDefault()
      okRef.current.open()
    }
  };
  useEventListener("keydown", keydownEvent, window.document, open);

  return (
    <FormDrawer
      className={styles["drawer"]}
      open={open}
      width={'calc(100vw - 300px)'}
      title={i18n.t(languageKeys.chinh_sua_phieu_thu_thanh_toan)}
      onCancel={handleClose}
      back
      hiddenTopAction={true}
      footer={
        <Row justify="end">
          <Space>
            <Button type="primary" ghost onClick={handleClose}>
              {i18n.t(languageKeys.common_Thoat)} (ESC)
            </Button>
            <Button type="primary" onClick={() => okRef.current.open(true)}>
              {i18n.t(languageKeys.vien_phi_thanh_toan_va_in)} (Ctrl+T)
            </Button>
            <Button type="primary" onClick={() => okRef.current.open()}>
              {i18n.t(languageKeys.thanh_toan)} (Ctrl+L)
            </Button>
          </Space>
        </Row>
      }
    >
      <Layout>
        <Layout.Header className={styles.header}>
          <Form form={form} layout="vertical">
            <Row gutter={10}>
              <Col span={5}>
                <Form.Item label={i18n.t(languageKeys.ho_va_ten)} name="TEN">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label={i18n.t(languageKeys.gioi_tinh)} name="GIOI_TINH">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label={i18n.t(languageKeys.field_Nam_sinh)} name="NGAY_SINH">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label={i18n.t(languageKeys.field_Ngay_kham)} name="NGAY_KHAM">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={i18n.t(languageKeys.dia_chi)} name="DIA_CHI">
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Layout.Header>

        <Layout key={"CR7"} style={{ height: `calc(100vh - 207px)` }}>
          <DichVuChuaThanhToan
            tabView={keyViews.CHUA_THANH_TOAN}
            currentPatient={patientInfo}
            ref={paidRef}
            onAfterSubmit={() => {
              handleClose();
              layDsDichDaThanhToan(patientInfo);
            }}
            onPrint={onPrint}
            hiddenHeader
            styleMode={"TIEP_DON"}
            scrollY={223}
            editMode
            skipEffect={skipEffect}
            editID={ID}
          />
        </Layout>

        <ConfirmModal
          ref={okRef}
          content={i18n.t(languageKeys.warning_chinh_sua_hoa_don)}
          danger={true}
          onOk={(print) => {
            paidRef.current.onSubmit(print)
            okRef.current.close()
          }}
        />

      </Layout>
    </FormDrawer>
  )
}

export default forwardRef(ModalSuaPhieu);