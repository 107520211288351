import i18n, { languageKeys } from "../../../../../i18n";
import styles from "./content.module.less";
import { InputNumber, Space, Tooltip } from "antd";
import { SelectKho } from "../Sidebar/SelectKho";
import { InputSoLuong } from "../Sidebar/InputSoLuong";
import { InputCachDung } from "../Sidebar/InputCachDung";
import { SelectDuongDung } from "../Sidebar/SelectDuongDung";
import { SelectTanSuat } from "../Sidebar/SelectTanSuat";
import { InputMoiNgay } from "../Sidebar/InputMoiNgay";
// import SelectMed from "../Sidebar/SelectMed";
import InputHDSD from "../Sidebar/InputHDSD";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { doFetchManual, doFetchRouteOfUse, doFetchUnit, doFetchUsage, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { selectLoadingState } from "ducks/slices/loadingSlice";
import SelectHDSD from "../Sidebar/SelectHDSD";
import SelectMedV2 from "../Sidebar/SelectMedV2";

const usePrescriptionForm = ({
  readOnlyKho, readOnlyThuoc, dataSource, handleChangeInput, dsKho, loadingKho, disableEdit, handleSelectKho, handleSelectThuoc
}) => {

  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state.auth.user);
  const { unit: dsDvt, routeOfUse: dsDuongDung, usage: dsCachDung, manual, prescriptionForm } = useSelector(getSharedData);
  const loading = useSelector(selectLoadingState)

  const readOnlyFromSoLuongToTheRest = readOnlyThuoc;
  const checkDonThuocCongDuoc = userProfile?.config?.DON_THUOC_CONG_DUOC;

  useEffect(() => {
    if (prescriptionForm) {
      if (prescriptionForm === 1) {
        !dsCachDung.length && dispatch(doFetchUsage())
        !dsDvt.length && dispatch(doFetchUnit())
      }
      if (prescriptionForm === 2) {
        !manual.length && dispatch(doFetchManual())
      }
      !dsDuongDung.length && dispatch(doFetchRouteOfUse())
    }
  }, [prescriptionForm])


  // Mặc định
  const DefaultForm = [
    {
      title: (
        <label className={styles.important}>
          {i18n.t(languageKeys.field_Ten_kho)}/{i18n.t(languageKeys.cate_Danh_muc)}<span> *</span>
        </label>
      ),
      dataIndex: "KHO_THUOC_ID",
      width: 170,
      render: (KHO_THUOC_ID, record) => (
        <SelectKho
          onSelect={handleSelectKho}
          readOnly={readOnlyKho || disableEdit}
          dsKho={dsKho}
          value={KHO_THUOC_ID}
          loading={loadingKho}
          record={record}
        />
      ),
    },
    {
      title: <label className={styles.important}>{i18n.t(languageKeys.field_Ten_thuoc)}<span> *</span></label>,
      dataIndex: "Thuoc_ID",
      width: 240,
      render: (_, record) => record.KHO_THUOC_ID && (
        <SelectMedV2
          medList={dataSource}
          record={record}
          onSelect={handleSelectThuoc}
          readOnly={readOnlyThuoc}
          initData={[{
            Thuoc_ID: record.Thuoc_ID,
            Thuoc_Ten: record.Thuoc_Ten,
          }]}
        />
      ),
      fixed: "left",
    },
    {
      title: `${i18n.t(languageKeys.field_ham_luong)}, ${i18n.t(languageKeys.field_nong_do).toLowerCase()}`,
      ellipsis: true,
      dataIndex: "HAM_LUONG",
      width: 165,
      align: 'center',
      render: (HAM_LUONG, record) => HAM_LUONG ? HAM_LUONG : record.NONG_DO
    },
    {
      title: <label className={styles.important}>{i18n.t(languageKeys.txt_So_luong)}<span> *</span></label>,
      dataIndex: "Thuoc_SL",
      width: 140,
      render: (Thuoc_SL, record) => record.Thuoc_ID && (
        <InputSoLuong
          readOnly={readOnlyFromSoLuongToTheRest}
          choPhepChonKho={record.KHO_THUOC_ID !== "DANH_MUC"}
          selectedThuoc={record}
          onChangeInputNumber={handleChangeInput}
          rowIndex={record.STT - 1}
          value={Thuoc_SL}
          field={{
            name: "Thuoc_Ten",
            quantity: "Thuoc_SL",
            old: "old",
          }}
        />
      ),
    },
    {
      title: <label className={styles.important}>{i18n.t(languageKeys.field_Cach_dung)}<span> {checkDonThuocCongDuoc ? '*' : ''}</span></label>,
      dataIndex: "ThoiDiemDung",
      ellipsis: true,
      width: 240,
      render: (ThoiDiemDung, record) => record.Thuoc_ID && (
        <InputCachDung
          required={checkDonThuocCongDuoc}
          readOnly={readOnlyFromSoLuongToTheRest}
          rowIndex={record.STT - 1}
          value={ThoiDiemDung}
          onChange={handleChangeInput}
          dataSource={dsCachDung}
          loading={loading}
        />
      )
    },
    {
      title: i18n.t(languageKeys.duong_dung),
      dataIndex: "Duongdung",
      width: 200,
      render: (Duongdung, record) => record.Thuoc_ID && (
        <SelectDuongDung
          readOnly={readOnlyFromSoLuongToTheRest}
          rowIndex={record.STT - 1}
          value={Duongdung}
          onSelect={handleChangeInput}
          dataSource={dsDuongDung}
          loading={loading}
        />
      )
    },
    {
      title: i18n.t(languageKeys.tan_suat_sd),
      dataIndex: "TAN_SUAT_NGAY",
      ellipsis: true,
      width: 140,
      render: (TAN_SUAT_NGAY, record) => record.Thuoc_ID && (
        <SelectTanSuat
          readOnly={readOnlyFromSoLuongToTheRest}
          rowIndex={record.STT - 1}
          value={TAN_SUAT_NGAY}
          onSelect={handleChangeInput}
        />
      )
    },
    {
      title: i18n.t(languageKeys.new_moi_ngay),
      dataIndex: "Lieuluong",
      width: 180,
      render: (_, record) => record.Thuoc_ID && (
        <InputMoiNgay
          readOnly={readOnlyFromSoLuongToTheRest}
          record={record}
          onChangeInputNumber={handleChangeInput}
          dataSource={dsDvt}
          loading={loading}
        />
      )
    },
    {
      title: i18n.t(languageKeys.field_Chia_lam),
      dataIndex: "Tansuat",
      ellipsis: true,
      width: 120,
      render: (Tansuat, record) => record.Thuoc_ID && (
        <Tooltip
          title={!readOnlyFromSoLuongToTheRest && `${i18n.t(languageKeys.nhap_gia_tri_lon_hon)} 0`}
          placement="left"
          trigger="focus"
        >
          <InputNumber
            className={styles["input-chia-lam"]}
            placeholder={!readOnlyFromSoLuongToTheRest && i18n.t(languageKeys.common_Nhap)}
            readOnly={readOnlyFromSoLuongToTheRest}
            addonAfter={<div className={styles["suffix-lan"]}>{i18n.t(languageKeys.field_Lan).toLowerCase()}</div>}
            onBlur={(e) => handleChangeInput(e.target.value, record.STT - 1, "Tansuat")}
            value={Tansuat}
            controls={false}
            min={1}
          />
        </Tooltip>
      )
    },
    {
      title: i18n.t(languageKeys.so_ngay_sd),
      dataIndex: "SoNgaySD",
      ellipsis: true,
      width: 125,
      render: (SoNgaySD, record) => record.Thuoc_ID && (
        <Tooltip
          title={!readOnlyFromSoLuongToTheRest && `${i18n.t(languageKeys.nhap_gia_tri_lon_hon)} 0`}
          placement="left"
          trigger="focus"
        >
          <InputNumber
            placeholder={!readOnlyFromSoLuongToTheRest && i18n.t(languageKeys.common_Nhap)}
            value={SoNgaySD}
            readOnly={readOnlyFromSoLuongToTheRest}
            onBlur={(e) => handleChangeInput(e.target.value, record.STT - 1, "SoNgaySD")}
            controls={false}
            min={1}
          />
        </Tooltip>
      )
    },
    {
      title: i18n.t(languageKeys.huong_dan_su_dung),
      dataIndex: "Chidan",
      width: 250,
      render: (_, record) => record.Thuoc_Ten && (
        <InputHDSD record={record} handleChangeInput={handleChangeInput} />
      )
    },
  ];


  // Aivi
  const AiviForm = [
    {
      title: (
        <label className={styles.important}>
          {i18n.t(languageKeys.field_Ten_kho)}/{i18n.t(languageKeys.cate_Danh_muc)}<span> *</span>
        </label>
      ),
      dataIndex: "KHO_THUOC_ID",
      width: 170,
      render: (KHO_THUOC_ID, record) => (
        <SelectKho
          onSelect={handleSelectKho}
          readOnly={readOnlyKho || disableEdit}
          dsKho={dsKho}
          value={KHO_THUOC_ID}
          loading={loadingKho}
          record={record}
        />
      ),
    },
    {
      title: <label className={styles.important}>{i18n.t(languageKeys.field_Ten_thuoc)}<span> *</span></label>,
      dataIndex: "Thuoc_ID",
      width: 240,
      render: (_, record) => record.KHO_THUOC_ID && (
        <div className={styles.med}>
          <SelectMedV2
            medList={dataSource}
            record={record}
            onSelect={handleSelectThuoc}
            readOnly={readOnlyThuoc}
            initData={[{
              Thuoc_ID: record.Thuoc_ID,
              Thuoc_Ten: record.Thuoc_Ten,
            }]}
          />
          <span className={styles.txt}>
            {record.Thuoc_HoatChat}
            {record.Thuoc_HoatChat && (record.HAM_LUONG || record.NONG_DO) ? ", " : ""}
            {record.HAM_LUONG || record.NONG_DO}
          </span>
        </div>
      ),
      fixed: "left",
    },
    {
      title: i18n.t(languageKeys.unit),
      dataIndex: "Thuoc_Dvt",
      width: 100,
      render: (Thuoc_Dvt, record) => (
        <Space style={{ height: (record.Thuoc_HoatChat || record.HAM_LUONG || record.NONG_DO) ? 60 : 40 }}>{Thuoc_Dvt}</Space>
      ),
    },
    {
      title: i18n.t(languageKeys.so_ngay_sd),
      dataIndex: "SoNgaySD",
      ellipsis: true,
      width: 135,
      render: (SoNgaySD, record) => record.Thuoc_ID && (
        <Tooltip title={!readOnlyFromSoLuongToTheRest && `${i18n.t(languageKeys.nhap_gia_tri_lon_hon)} 0`} placement="left" trigger="focus">
          <InputNumber
            placeholder={!readOnlyFromSoLuongToTheRest && i18n.t(languageKeys.common_Nhap)}
            value={SoNgaySD}
            readOnly={readOnlyFromSoLuongToTheRest}
            onBlur={(e) => handleChangeInput(e.target.value, record.STT - 1, "SoNgaySD")}
            controls={false}
            min={1}
            addonAfter={i18n.t(languageKeys.txt_ngay)}
          // status={SoNgaySD ? "" : "error"}
          />
        </Tooltip>
      )
    },
    {
      title: <label className={styles.important}>{i18n.t(languageKeys.txt_So_luong)}<span> *</span></label>,
      dataIndex: "Thuoc_SL",
      width: 130,
      render: (Thuoc_SL, record) => record.Thuoc_ID && (
        <InputSoLuong
          readOnly={readOnlyFromSoLuongToTheRest}
          choPhepChonKho={record.KHO_THUOC_ID !== "DANH_MUC"}
          selectedThuoc={record}
          onChangeInputNumber={handleChangeInput}
          rowIndex={record.STT - 1}
          value={Thuoc_SL}
          field={{
            name: "Thuoc_Ten",
            quantity: "Thuoc_SL",
            old: "old",
          }}
          showUnit={false}
        />
      ),
    },
    {
      title: i18n.t(languageKeys.huong_dan_su_dung),
      dataIndex: "Chidan",
      width: 250,
      render: (Chidan, record) => record.Thuoc_ID && (
        <SelectHDSD
          readOnly={readOnlyFromSoLuongToTheRest}
          rowIndex={record.STT - 1}
          value={Chidan}
          onSelect={handleChangeInput}
          dataSource={manual}
          loading={loading}
        />
      )
    },
    {
      title: i18n.t(languageKeys.duong_dung),
      dataIndex: "Duongdung",
      width: 200,
      render: (Duongdung, record) => record.Thuoc_ID && (
        <SelectDuongDung
          readOnly={readOnlyFromSoLuongToTheRest}
          rowIndex={record.STT - 1}
          value={Duongdung}
          onSelect={handleChangeInput}
          dataSource={dsDuongDung}
          loading={loading}
        />
      )
    },
  ];


  return { DefaultForm, AiviForm }
}

export default usePrescriptionForm;