import { Button, Checkbox, Col, Form, Input, InputNumber, notification, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { FormDrawer, Select } from "../../../../components";
import { HLog, isEmptyObject } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";
import style from "./modalSoHoaDon.module.less";
import { fieldsSoHoaDon } from "../fieldsSoHoaDon";
import { useDispatch, useSelector } from "react-redux";
import { apiCapNhatSoHoaDon, apiChiTietSoHoaDon, apiLuuDsSoHoaDon } from "../apisSoHoaDon";
import { useThongTinKhamBenh } from "./hooksSoHoaDon";
import { widthDrawerKeys } from "../../../../constants/keys";
import { useEventListener } from "../../../../hooks";
import _ from 'lodash';
import { doFetchPersonnel } from "ducks/slices/ListData/SharedData.slice";

const ModalSoHoaDon = forwardRef(({ doAfterSubmit = () => { }, setVisibleDrawerSoHoaDon = () => { } }, ref) => {

  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state.auth.user);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const { dsNhanSu, loadingNhanSu } = useThongTinKhamBenh();
  const [initData, setInitData] = useState({});

  useImperativeHandle(ref, () => ({
    open(data, loai_so) {
      setVisible(true);
      dsNhanSu.length < 1 && dispatch(doFetchPersonnel());

      if (!!data) {
        setInitData(data);
        layChiTietSo(data.ID);
        HLog(data);
      }

      if (loai_so) {
        form.setFields([
          {
            name: fieldsSoHoaDon.loai_soHD,
            value: loai_so,
          },
        ]);
      }
    },
  }));

  // useEffect(() => {
  //   const close = (e) => {
  //     let charCode = String.fromCharCode(e.which).toLowerCase();

  //     if (visible) {
  //       if (charCode === "esc") {
  //         e.preventDefault();
  //         handleClose();
  //       } else if ((e.ctrlKey || e.metaKey) && charCode === "s") {
  //         e.preventDefault();
  //         handleOk();
  //       } else if ((e.ctrlKey || e.metaKey) && charCode === "p") {
  //         e.preventDefault();
  //         handlePrint();
  //       }
  //     }
  //   };
  //   window.addEventListener("keydown", close);
  //   return () => window.removeEventListener("keydown", close);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [visible]);

  useEffect(() => {
    isEmptyObject(initData) && visible && form.setFieldValue(fieldsSoHoaDon.QUYEN_SU_DUNG, dsNhanSu.flatMap(i => i.id))
  }, [dsNhanSu, visible])

  const keyDownHandler = (event) => {
    const stopDefaultAction = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };

    if (event.key === "Escape") {
      stopDefaultAction(event);
      handleClose();
    } else if (event.ctrlKey && event.key === "s") {
      stopDefaultAction(event);
      handleOk();
    }
    // else if (event.ctrlKey && event.key === "p") {
    //   stopDefaultAction(event);
    //   handlePrint();
    // }
  };

  useEventListener("keydown", keyDownHandler, window, visible);

  //Lấy chi tiết sổ
  const layChiTietSo = async (ID) => {
    const res = await apiChiTietSoHoaDon({
      ID,
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    });

    if (!!res) {
      if (!!res.result[0]) {
        const data = res.result[0];

        form.setFields(
          Object.values(fieldsSoHoaDon).map((name) => {
            if (name === fieldsSoHoaDon.QUYEN_SU_DUNG) {
              // const value = data[name].map((item) =>
              //   JSON.stringify({
              //     key: item.NHANSU_ID,
              //     id: item.NHANSU_ID,
              //     name: item.HO + " " + item.TEN,
              //   })
              // );
              // setSelectedUsers(value);
              return {
                name,
                value: data[name]?.flatMap(i => i.NHANSU_ID),
              };
            }
            return {
              name,
              value: data[name],
            };
          })
        );
      }
    }

    setLoading(false);
  };

  // Hàm xử lý bấm nút ok (kích hoạt submit form)
  const handleOk = () => {
    form.submit();
  };

  // Hàm xử lý đóng modal
  const handleClose = () => {
    setVisible(false);
    setInitData({});
    // setSelectedUsers([]);
    form.resetFields();
    setVisibleDrawerSoHoaDon(false);
  };

  // Hàm xử lý submit form
  const handleSubmitForm = (values) => {
    const data = {
      ...initData,
      ...values,
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      // QUYEN_SU_DUNG: values[`${fieldsSoHoaDon.QUYEN_SU_DUNG}`].map((jsonItem) => JSON.parse(jsonItem).id),
    };
    if (Number(data.SO_KET_THUC) - Number(data.SO_BAT_DAU) < Number(data.SO_HD_DA_TAO)) {
      notification.error({
        placement: "bottomLeft",
        message: i18n.t(languageKeys.error_vien_phi_thieu_phieu) + data.SO_HD_DA_TAO + " )",
      });
      return;
    }
    HLog("data submit", data);

    if (!!initData.ID) {
      apiCapNhatSoHoaDon(initData ? _.omit(data, ['LOAI_SO', 'MA_SO']) : data).then((res) => {
        if (!!res) {
          notification.success({
            placement: "bottomLeft",
            message: i18n.t(languageKeys.cap_nhat_thanh_cong),
          });

          handleClose();
          doAfterSubmit();
        } else {
          setLoading(false);
          notification.error({
            placement: "bottomLeft",
            message: i18n.t(languageKeys.cap_nhat_that_bai),
          });
        }

        setLoading(false);
      });
    } else {
      apiLuuDsSoHoaDon(data).then((res) => {
        if (!!res && res.status === "OK") {
          notification.success({
            placement: "bottomLeft",
            message: i18n.t(languageKeys.them_moi_thanh_cong),
          });

          handleClose();
          doAfterSubmit();
        } else {
          setLoading(false);

          if (!!res && res.status === 'KO') {

            if (res.field === fieldsSoHoaDon.ma_soHD && res.message === 'exist') {
              form.setFields([{ name: fieldsSoHoaDon.ma_soHD, errors: " ", }])
              notification.error({
                placement: "bottomLeft",
                message: i18n.t(languageKeys.noti_ma_so_da_ton_tai),
              })
              return
            }
          }

          notification.error({
            placement: "bottomLeft",
            message: i18n.t(languageKeys.them_moi_that_bai),
          });
        }

        setLoading(false);
      });
    }
  };

  return (
    <FormDrawer
      width={widthDrawerKeys.small}
      visible={visible}
      hiddenTopAction={true}
      onOk={handleOk}
      onCancel={handleClose}
      loading={loading}
      title={initData.ID ? i18n.t(languageKeys.label_sua_so_hoa_don) : i18n.t(languageKeys.label_them_so_hoa_don)}
      footer={
        <Row justify="end" align="middle">
          {/*=========RIGHT=============*/}
          <Col>
            <Button type="primary" onClick={handleClose} ghost className={style["button"]}>
              {i18n.t(languageKeys.common_Thoat) + " (ESC)"}
            </Button>

            <Button type="primary" onClick={handleOk} className={style["button"]}>
              {i18n.t(languageKeys.common_Luu) + " (Ctrl+S)"}
            </Button>
          </Col>
        </Row>
      }
    >
      <Form layout="vertical" form={form} onFinish={(value) => handleSubmitForm(value)}>
        <div className={style["wrapper"]}>
          <h4>{i18n.t(languageKeys.field_Thong_tin_co_ban)}</h4>

          <div className={style["inner"]}>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  name={fieldsSoHoaDon.ma_soHD}
                  label={i18n.t(languageKeys.field_Ma_so)}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={fieldsSoHoaDon.ten_soHD}
                  label={i18n.t(languageKeys.field_Ten_so)}
                  rules={[
                    {
                      required: true,
                      message: i18n.t(languageKeys.vui_long_nhap),
                    },
                  ]}
                >
                  <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={fieldsSoHoaDon.loai_soHD}
                  label={i18n.t(languageKeys.field_Loai_so)}
                  rules={[
                    {
                      required: true,
                      message: i18n.t(languageKeys.vui_long_nhap),
                    },
                  ]}
                //initialValue="SO_THU_TIEN"
                >
                  <Select
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    titleKey="name"
                    //disabled
                    valueKey="type"
                    dataSource={dsLoaiSo}
                    readOnly={!!initData.ID}
                  />
                </Form.Item>
              </Col>


              <Col span={12}>
                <Form.Item
                  name={fieldsSoHoaDon.tong_so_phieu_HD}
                  label={i18n.t(languageKeys.field_shd_tong_so)}
                  rules={[
                    {
                      required: true,
                      message: i18n.t(languageKeys.vui_long_nhap),
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={1} placeholder={i18n.t(languageKeys.common_Nhap)} className={style["input-num"]} />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <h4>{i18n.t(languageKeys.field_Phan_quyen_nguoi_su_dung_so)}</h4>

          <Spin spinning={loadingNhanSu}>
            <div className={style["inner"]}>
              <Form.Item
                label={i18n.t(languageKeys.field_Nguoi_su_dung)}
                name={fieldsSoHoaDon.QUYEN_SU_DUNG}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.please_chon),
                  },
                ]}
                // initialValue={dsNhanSu.flatMap(i => i.id)}
              >
                {/* <Select
                placeholder={i18n.t(languageKeys.common_Chon)}
                dataSource={dsNhanSu.filter(user => user?.QUYEN_MODULE?.includes('CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.THANH_TOAN'))}
                titleKey="name"
                onDropdownVisibleChange={(bool) => bool && layDanhSachNhanSu("", 'THU_NGAN')}
                showSearch
                onSearch={(value) => layDanhSachNhanSu(value)}
                multiple
                onChange={(list) => {
                  setSelectedUsers(list);
                }}
                selectedList={selectedUsers}
                setSelectedList={(newArr) => {
                  const key_user = fieldsSoHoaDon.QUYEN_SU_DUNG;

                  setSelectedUsers(newArr);

                  form.setFields([
                    {
                      name: key_user,
                      value: newArr,
                    },
                  ]);
                }}
                loading={loadingNhanSu}
              /> */}

                <Checkbox.Group
                  options={dsNhanSu
                    .filter(user => user?.QUYEN_MODULE?.includes('CIS.QL_VIEN_PHI.DICH_VU_CAN_THANH_TOAN.THANH_TOAN'))
                    .map(i => ({ label: i.name, value: i.id }))}
                  className={style.checkBox}
                />
              </Form.Item>
            </div>
          </Spin>

        </div>
      </Form>
    </FormDrawer>
  );
});

export default ModalSoHoaDon;

const dsLoaiSo = [
  {
    type: "SO_THU_TIEN",
    name: i18n.t(languageKeys.vien_phi_so_hoa_don),
  },
  {
    type: "SO_HOAN_TIEN",
    name: i18n.t(languageKeys.so_hoan_tien),
  },
  {
    type: "SO_TAM_UNG",
    name: i18n.t(languageKeys.so_tam_ung),
  },
  {
    type: "SO_CONG_NO",
    name: i18n.t(languageKeys.so_cong_no),
  },
];
