import { Col, Form, Input, Row, Space, Switch } from "antd";
import cn from "classnames";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select } from "../../../../components";
import { apis, keys } from "../../../../constants";
import Constants from "../../../../constants/Constants";
import { HLog, common_post, formatNumberToPrice } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";
import PriceInput from "../components/PriceInput/PriceInput";
import {
  fieldDichVuKiThuat,
  fieldDonViTinh,
  fieldLoaiDvKiThuat,
  fieldNhomBhyt,
  fieldNhomDvKiThuat,
  fieldNhomHoaDon,
  fieldPhong,
} from "../fieldsDanhMuc";
import formDanhMuc from "./formDanhMuc.module.less";

const key_nhom_dvkt_id = fieldNhomDvKiThuat.id;
const key_nhom_dvkt_ten = fieldNhomDvKiThuat.ten_nhom;
const key_loai_dvkt_id = fieldLoaiDvKiThuat.id;
const key_loai_dvkt_ten = fieldLoaiDvKiThuat.ten_loai_dv_ki_thuat;
const key_nhom_hoa_don_id = fieldNhomHoaDon.id;
const key_nhom_hoa_don_ten = fieldNhomHoaDon.ten_nhom;

const FormDvkt = ({ visible = false, initData = {}, is_create_new = false, form }) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [dsNhomDvkt, setDsNhomDvkt] = useState([]);
  const [dsLoaiDvkt, setDsLoaiDvkt] = useState([]);
  const [dsNhomHoaDon, setDsNhomHoaDon] = useState([]);
  const [dsLoaiThuThuat, setDsLoaiThuThuat] = useState([]);
  const [dsNhomBhyt, setDsNhomBhyt] = useState([]);
  const [dsDonViTinh, setDsDonViTinh] = useState([]);
  const [dsPhong, setDsPhong] = useState([]);
  const [selectedPhongThucHien, setSelectedPhongThucHien] = useState([]);
  const [selectedPhongLayMau, setSelectedPhongLayMau] = useState([]);
  const [selectedNhomBhyt, setSelectedNhomBhyt] = useState({});
  const [loadingNhomDvkt, setLoadingNhomDvkt] = useState(false);
  const [loadingLoaiDvkt, setLoadingLoaiDvkt] = useState(false);
  const [loadingNhomHoa, setLoadingNhomHoa] = useState(false);
  const [loadingPhong, setLoadingPhong] = useState(false);
  const [currentNhomDvktId, setCurrentNhomDvktId] = useState(); // id nhom dvkt, dành cho trường hợp chọn nhóm dvkt trước (default)
  const [currentLoaiDvktId, setCurrentLoaiDvktId] = useState(); // id loai dvkt, dành cho trường hợp chọn loại dvkt trước
  const [chonNhomDvktTruoc, setChonNhomDvktTruoc] = useState(true); // (boolean) - flow: chọn nhóm dv -> chọn loại dv (default)
  const [dsNhomCLS, setDsNhomCLS] = useState([]);
  const [isRequiredPThucHien, setIsRequiredPThucHien] = useState(false);
  useEffect(() => {
    if (visible) {
      apiLayDsDonViTinh();
      apiLayDsNhomBhyt("", initData[fieldDichVuKiThuat.nhom_bhyt]);

      if (!is_create_new) {
        const key_gia_thu_phi = fieldDichVuKiThuat.gia_thu_phi;
        const key_gia_bhyt = fieldDichVuKiThuat.gia_bhyt;
        const key_gia_dich_vu = fieldDichVuKiThuat.gia_dich_vu;
        const key_gia_nuoc_ngoai = fieldDichVuKiThuat.gia_nuoc_ngoai;

        let formArr = [];
        let nhomDvktId = "";

        apiLayChiTietDvkt(initData[fieldDichVuKiThuat.id]);

        if (!!initData[fieldDichVuKiThuat.nhom_dich_vu]) {
          nhomDvktId = initData[fieldDichVuKiThuat.nhom_dich_vu];
          const tenNhomDvkt = initData[fieldDichVuKiThuat.ten_nhom_dvkt];

          // setCurrentNhomDvktId(nhomDvktId);

          // const nhomDvktStringify = JSON.stringify({
          //   [key_nhom_dvkt_id]: nhomDvktId,
          //   [key_nhom_dvkt_ten]: tenNhomDvkt,
          // });

          // apiLayDsNhomDvkt(tenNhomDvkt);

          formArr.push({
            name: fieldDichVuKiThuat.nhom_dich_vu,
            // value: nhomDvktStringify,
            value: tenNhomDvkt,
          });
        }

        if (!!initData[fieldDichVuKiThuat.loai_dich_vu]) {
          const idLoaiDvkt = initData[fieldDichVuKiThuat.loai_dich_vu];
          const tenLoaiDvkt = initData[fieldDichVuKiThuat.ten_loai_dvkt];

          // const loaiDvktStringify = JSON.stringify({
          //   [key_loai_dvkt_id]: idLoaiDvkt,
          //   [key_loai_dvkt_ten]: tenLoaiDvkt,
          // });

          // apiLayDsLoaiDvkt(
          //   nhomDvktId,
          //   initData[fieldDichVuKiThuat.ten_loai_dvkt]
          // );

          formArr.push({
            name: fieldDichVuKiThuat.loai_dich_vu,
            // value: loaiDvktStringify,
            value: tenLoaiDvkt,
          });
        }

        if (!!initData[fieldDichVuKiThuat.nhom_hoa_don]) {
          const idNhomHoaDon = initData[fieldDichVuKiThuat.nhom_hoa_don];
          const tenNhomHoaDon = initData[fieldDichVuKiThuat.ten_nhom_hoa_don];

          // const nhomHoaDonStringify = JSON.stringify({
          //   [key_nhom_hoa_don_id]: idNhomHoaDon,
          //   [key_nhom_hoa_don_ten]: tenNhomHoaDon,
          // });

          // apiLayDsNhomHoaDon(initData[fieldDichVuKiThuat.ten_nhom_hoa_don]);

          formArr.push({
            name: fieldDichVuKiThuat.nhom_hoa_don,
            // value: nhomHoaDonStringify,
            value: tenNhomHoaDon,
          });
        }
        if (!!initData[fieldDichVuKiThuat.nhom_cls]) {
          formArr.push({
            name: fieldDichVuKiThuat.nhom_cls,
            value: initData[fieldDichVuKiThuat.nhom_cls],
          });
        }

        if (!!initData[fieldDichVuKiThuat.don_vi_tinh]) {
          formArr.push({
            name: fieldDichVuKiThuat.don_vi_tinh,
            // value: nhomHoaDonStringify,
            value: initData[fieldDichVuKiThuat.ten_don_vi_tinh],
          });
        }

        if (!!initData[key_gia_thu_phi]) {
          formArr.push({
            name: key_gia_thu_phi,
            value: formatNumberToPrice(initData[key_gia_thu_phi]),
          });
        }

        if (!!initData[key_gia_dich_vu]) {
          formArr.push({
            name: key_gia_dich_vu,
            value: formatNumberToPrice(initData[key_gia_dich_vu]),
          });
        }

        if (!!initData[key_gia_nuoc_ngoai]) {
          formArr.push({
            name: key_gia_nuoc_ngoai,
            value: formatNumberToPrice(initData[key_gia_nuoc_ngoai]),
          });
        }

        if (!!initData[key_gia_bhyt]) {
          formArr.push({
            name: key_gia_bhyt,
            value: formatNumberToPrice(initData[key_gia_bhyt]),
          });
        }
        if (!!initData[fieldDichVuKiThuat.loai_thu_thuat]) {
          formArr.push({
            name: fieldDichVuKiThuat.loai_thu_thuat,
            value: initData[fieldDichVuKiThuat.ten_loai_thu_thuat],
          });
        }
        if (formArr.length > 0) form.setFields(formArr);
      }
    } else {
      setDsNhomDvkt([]);
      setDsLoaiDvkt([]);
      setDsNhomHoaDon([]);
      setDsNhomBhyt([]);
      setDsDonViTinh([]);
      setDsPhong([]);
      setSelectedPhongThucHien([]);
      setSelectedPhongLayMau([]);
      setLoadingNhomDvkt(false);
      setLoadingNhomHoa(false);
      setLoadingPhong(false);
      setSelectedNhomBhyt({});
      setIsRequiredPThucHien(false);
      setCurrentNhomDvktId();
      setCurrentLoaiDvktId();
      setChonNhomDvktTruoc(true);
      setDsNhomCLS([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const apiLayChiTietDvkt = async (DV_ID = "") => {
    try {
      const requestData = {
        partner_code: userProfile.partner_code,
        DV_ID,
      };

      const res = await common_post(apis.dm_dvkt_lay_chi_tiet, requestData);

      if (res.status === "OK") {
        const key_id_phong = fieldPhong.id;
        const key_ten_phong = fieldPhong.ten_phong;

        let listPhongThucHien = res.result.list_thuc_hien.map((item) =>
          JSON.stringify({
            [key_id_phong]: item[key_id_phong],
            [key_ten_phong]: item[key_ten_phong],
          })
        );
        let listPhongLayMau = res.result.list_lay_mau.map((item) =>
          JSON.stringify({
            [key_id_phong]: item[key_id_phong],
            [key_ten_phong]: item[key_ten_phong],
          })
        );

        setSelectedPhongThucHien(listPhongThucHien);
        setSelectedPhongLayMau(listPhongLayMau);

        form.setFields([
          {
            name: fieldDichVuKiThuat.phong_thuc_hien,
            value: listPhongThucHien,
          },
          { name: fieldDichVuKiThuat.phong_lay_mau, value: listPhongLayMau },
        ]);
      }
    } catch (error) {
      HLog("Api lay chi tiet dvkt error", error);
    }
  };

  const apiLayDsNhomDvkt = async (LOAI_DV_ID = "", search_string = "", callback = () => {}) => {
    setLoadingNhomDvkt(true);

    try {
      const requestData = {
        search_string,
        limit: keys.limit,
        LOAI_DV_ID,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
      };

      const res = await common_post(apis.dm_nhom_dvkt_lay_ds_co_hieu_luc, requestData);

      if (res.status === "OK") {
        const data = res.result.map((item) => ({
          [key_nhom_dvkt_id]: item[key_nhom_dvkt_id],
          [key_nhom_dvkt_ten]: item[key_nhom_dvkt_ten],
        }));

        setDsNhomDvkt(data);

        callback(data);
      }
    } catch (error) {
      HLog("Api lay ds nhom dvkt error", error);
    }

    setLoadingNhomDvkt(false);
  };

  const apiLayDsLoaiDvkt = async (NHOM_DV_ID = "", search_string = "") => {
    setLoadingLoaiDvkt(true);

    try {
      const requestData = {
        search_string,
        NHOM_DV_ID,
        limit: keys.limit,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
      };

      const res = await common_post(apis.dm_loai_dvkt_lay_ds_co_hieu_luc, requestData);

      if (res.status === "OK") {
        const data = res.result.map((item) => ({
          [key_loai_dvkt_id]: item[key_loai_dvkt_id],
          [key_loai_dvkt_ten]: item[key_loai_dvkt_ten],
        }));

        setDsLoaiDvkt(data);
      }
    } catch (error) {
      HLog("Api lay ds loai dvkt error", error);
    }

    setLoadingLoaiDvkt(false);
  };

  const apiLayDsNhomHoaDon = async (search_string = "") => {
    setLoadingNhomHoa(true);

    try {
      const requestData = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        search_string,
        limit: keys.limit,
        partner_code: userProfile.partner_code,
      };

      const res = await common_post(apis.dm_hoa_don_lay_ds_co_hieu_luc, requestData);

      if (res.status === "OK") {
        const data = res.result.map((item) => ({
          [key_nhom_hoa_don_id]: item[key_nhom_hoa_don_id],
          [key_nhom_hoa_don_ten]: item[key_nhom_hoa_don_ten],
        }));

        setDsNhomHoaDon(data);
      }
    } catch (error) {
      HLog("Api lay ds nhom hoa don error", error);
    }

    setLoadingNhomHoa(false);
  };

  const apiLayDsNhomBhyt = async (search_string = "", ID_NHOM_BHYT) => {

    try {
      const requestData = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        search_string,
        partner_code: userProfile.partner_code,
        limit: 1000,
      };

      const res = await common_post(apis.dm_nhom_bhyt_lay_ds, requestData);

      if (res.status === "OK") {
        setDsNhomBhyt(res.result);
        if (!!ID_NHOM_BHYT) {
          setSelectedNhomBhyt(
            res.result[
              res.result
                .map(function (e) {
                  return e.ID;
                })
                .indexOf(ID_NHOM_BHYT)
            ]
          );
        }
      }
    } catch (error) {
      HLog("Api lay ds nhom bhyt error", error);
    }
  };

  const apiLayDsDonViTinh = async (search_string = "") => {
    try {
      const requestData = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        search_string,
        partner_code: userProfile.partner_code,
        limit: 1000,
      };

      const res = await common_post(apis.dm_don_vi_tinh_lay_ds_co_hieu_luc, requestData);

      if (res.status === "OK") setDsDonViTinh(res.result);
    } catch (error) {
      HLog("Api lay ds don vi tinh error", error);
    }
  };

  const apiLayDsPhong = async (search_string = "", isPhongThucHien = false) => {
    setLoadingPhong(true);

    try {
      let requestData = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        search_string,
        KHOA: false,
        limit: keys.limit,
        partner_code: userProfile.partner_code,
      };

      if (!!isPhongThucHien) {
        const isKhamBenh = form.getFieldValue(fieldDichVuKiThuat.nhom_bhyt) === 1;
        if (isKhamBenh) requestData.LOAI_PHONG = "KHAM_BENH";
        else requestData.LOAI_TRU = ["KHAM_BENH"];
      }

      const res = await common_post(apis.lay_danh_sach_phong, requestData);

      if (res.status === "OK") {
        const key_id_phong = fieldPhong.id;
        const key_ten_phong = fieldPhong.ten_phong;

        const listPhong = res.result.map((item) => ({
          [key_id_phong]: item[key_id_phong],
          [key_ten_phong]: item[key_ten_phong],
        }));

        setDsPhong(listPhong);
      }
    } catch (error) {
      HLog("Api lay ds phong error", error);
    }

    setLoadingPhong(false);
  };

  const handleSelectNhom = async (id) => {
    let nhom = dsNhomBhyt.filter((item) => {
      return item.ID === id;
    });

    let body = {
      NHOM_BHYT: nhom[0].MA_NHOM,
    };
    // try {
    //   const res = await common_post(apis.lay_nhom_cls, body, false);
    //   if (!!res && res.status === "OK") {
    //     setDsNhomCLS(res.result);
    //   }
    // } catch (error) {
    //   HLog("CLS FORM DVKT : 415", error);
    // }
  };
  const apiLayDsLoaiThuThuat = async () => {
    if (dsLoaiThuThuat.length) return;
    setLoadingLoaiDvkt(true);
    try {
      const requestData = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
      };

      const res = await common_post(apis.dm_loai_thu_thuat, requestData);

      if (res.status === "OK") {
        const data = res.result.map((item) => ({ LOAI_TT_ID: item.ID, LOAI_THU_THUAT: item.LOAI_THU_THUAT }));
        setDsLoaiThuThuat(data);
      }
    } catch (error) {
      HLog("Api lay ds loai dvkt error", error);
    }

    setLoadingLoaiDvkt(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchNhomDvkt = useCallback(
    debounce((value, loai_dvkt_id) => apiLayDsNhomDvkt(loai_dvkt_id, value), 1000),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchLoaiDvkt = useCallback(
    debounce((value, nhom_dvkt_id) => apiLayDsLoaiDvkt(nhom_dvkt_id, value), 1000),
    []
  );
  const searchLoaiThuThuat = useCallback(
    debounce((value) => apiLayDsLoaiThuThuat(value), 1000),
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchNhomHoaDon = useCallback(
    debounce((value) => apiLayDsNhomHoaDon(value), 1000),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchPhongThucHien = useCallback(
    debounce((value, bool) => apiLayDsPhong(value, bool), 1000),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchPhongLayMau = useCallback(
    debounce((value) => apiLayDsPhong(value), 1000),
    []
  );

  const isFieldVisible = (field = "") => {
    const config = userProfile.config;

    if (!!config.DM_DICHVU_KYTHUAT && !!config.DM_DICHVU_KYTHUAT.isCo && Array.isArray(config.DM_DICHVU_KYTHUAT.TRUONG_BO)) {
      return !config.DM_DICHVU_KYTHUAT.TRUONG_BO.some((item) => item === field);
    }

    return true;
  };

  const chonNhomDvkt = (val) => {
    // nếu flow hiện tại là chọn nhóm dvkt trước, chọn loại dvkt sau (theo nhóm dvkt đã chọn)
    if (chonNhomDvktTruoc) {
      const nhom_dvkt_id = JSON.parse(val)[fieldNhomDvKiThuat.id];

      setCurrentNhomDvktId(nhom_dvkt_id);

      form.setFields([
        {
          name: fieldDichVuKiThuat.loai_dich_vu,
          value: undefined,
        },
      ]);
    }
  };

  const chonLoaiDvkt = (val) => {
    let chon_nhom_truoc = chonNhomDvktTruoc;

    // nếu chưa chọn nhóm dvkt mà đã chọn loại dvkt rồi thì ĐỔI FLOW NGƯỢC LẠI !!!
    if (!currentNhomDvktId) {
      setChonNhomDvktTruoc(false);
      chon_nhom_truoc = false;
    }

    // nếu flow hiện tại là chọn loại dvkt trước, chọn nhóm dvkt sau (theo loại dvkt đã chọn)
    if (!chon_nhom_truoc) {
      const loai_dvkt_id = JSON.parse(val)[fieldLoaiDvKiThuat.id];

      setCurrentLoaiDvktId(loai_dvkt_id);

      form.setFields([
        {
          name: fieldDichVuKiThuat.nhom_dich_vu,
          value: undefined,
        },
      ]);

      apiLayDsNhomDvkt(loai_dvkt_id, "", (list = []) => {
        list.length === 1 &&
          form.setFields([
            {
              name: fieldDichVuKiThuat.nhom_dich_vu,
              value: JSON.stringify(list[0]),
            },
          ]);
      });
    }
  };

  function onSelectNhomBHYT(id) {
    setSelectedNhomBhyt(
      dsNhomBhyt[
        dsNhomBhyt
          .map(function (e) {
            return e.ID;
          })
          .indexOf(id)
      ]
    );
  }
  const chonLoaiThuThuat = (item) => {
    form.setFields([
      {
        name: fieldDichVuKiThuat.loai_thu_thuat,
        value: item,
      },
    ]);
  };
  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            {/* <Col span={6}>
              <Form.Item name={fieldDichVuKiThuat.stt} label={i18n.t(languageKeys.field_Stt)}>
                <InputNumber min={1} placeholder={i18n.t(languageKeys.common_Nhap)} style={{ width: "100%" }} />
              </Form.Item>
            </Col> */}

            <Col span={6}>
              <Form.Item
                name={fieldDichVuKiThuat.ma_dich_vu}
                label={i18n.t(languageKeys.field_Ma_dich_vu)}
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: i18n.t(languageKeys.vui_long_nhap),
                    // },
                  ]
                }
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                name={fieldDichVuKiThuat.ten_dich_vu}
                label={i18n.t(languageKeys.field_Ten_dich_vu)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name={fieldDichVuKiThuat.ten_dich_vu_theo_byt} label={i18n.t(languageKeys.field_Ten_dich_vu_theo_byt)}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name={fieldDichVuKiThuat.don_vi_tinh} label={i18n.t(languageKeys.field_Don_vi_tinh)}>
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={dsDonViTinh}
                  titleKey={fieldDonViTinh.ten_dvt}
                  // valueKey={fieldDonViTinh.id}
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                name={fieldDichVuKiThuat.nhom_dich_vu}
                label={i18n.t(languageKeys.field_Nhom_dich_vu)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={dsNhomDvkt}
                  onDropdownVisibleChange={(bool) => bool && apiLayDsNhomDvkt(currentLoaiDvktId)}
                  titleKey={fieldNhomDvKiThuat.ten_nhom}
                  showSearch
                  onSearch={(value) => searchNhomDvkt(value, currentLoaiDvktId)}
                  loading={loadingNhomDvkt}
                  onSelect={chonNhomDvkt}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                name={fieldDichVuKiThuat.loai_dich_vu}
                label={i18n.t(languageKeys.field_Loai_dich_vu)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={dsLoaiDvkt}
                  onDropdownVisibleChange={(bool) => bool && apiLayDsLoaiDvkt(currentNhomDvktId)}
                  titleKey={fieldLoaiDvKiThuat.ten_loai_dv_ki_thuat}
                  showSearch
                  onSearch={(value) => searchLoaiDvkt(value, currentNhomDvktId)}
                  loading={loadingLoaiDvkt}
                  onSelect={chonLoaiDvkt}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                name={fieldDichVuKiThuat.nhom_bhyt}
                label={i18n.t(languageKeys.field_Nhom_bhyt)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={dsNhomBhyt}
                  titleKey={fieldNhomBhyt.ten_nhom}
                  valueKey={fieldNhomBhyt.id}
                  showSearch
                  onSelect={(event) => {
                    handleSelectNhom(event);
                    onSelectNhomBHYT(event);
                  }}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>

      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Gia)}</h4>

        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            <Col span={6}>
              <PriceInput form={form} name={fieldDichVuKiThuat.gia_thu_phi} label={i18n.t(languageKeys.field_Gia_thu_phi)} />
            </Col>
          </Row>
        </div>
      </div>

      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_khac)}</h4>

        <div className={cn(formDanhMuc["inner"])} style={{ paddingBottom: 15 }}>
          <Row gutter={10}>
            <Col span={12}>
              <Col span={24}>
                <Form.Item
                  name={fieldDichVuKiThuat.phong_thuc_hien}
                  label={i18n.t(languageKeys.field_Phong_thuc_hien)}
                  rules={[
                    {
                      required: selectedNhomBhyt.MA_NHOM !== Constants.XET_NGHIEM,
                      message: i18n.t(languageKeys.vui_long_chon),
                    },
                  ]}
                >
                  <Select
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    dataSource={dsPhong}
                    titleKey={fieldPhong.ten_phong}
                    onDropdownVisibleChange={(bool) => bool && apiLayDsPhong("", true)}
                    showSearch
                    onSearch={(value) => searchPhongThucHien(value, true)}
                    multiple
                    onChange={setSelectedPhongThucHien}
                    selectedList={selectedPhongThucHien}
                    setSelectedList={(newArr) => {
                      const key_pth = fieldDichVuKiThuat.phong_thuc_hien;
                      setSelectedPhongThucHien(newArr);

                      form.setFields([
                        {
                          name: key_pth,
                          value: newArr,
                        },
                      ]);
                    }}
                    loading={loadingPhong}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Space>
                  <label className={formDanhMuc["label-custom"]}>{i18n.t(languageKeys.field_Hien_thi)}</label>
                  <Form.Item name={fieldDichVuKiThuat.hien_thi} valuePropName="checked" initialValue={false} style={{ marginBottom: 0 }}>
                    <Switch />
                  </Form.Item>
                </Space>
              </Col>
            </Col>
            <Col span={12}>
              <Col span={24}>
                <Form.Item
                  name={fieldDichVuKiThuat.phong_lay_mau}
                  label={i18n.t(languageKeys.field_Phong_lay_mau)}
                  rules={[
                    {
                      required: selectedNhomBhyt.MA_NHOM === Constants.XET_NGHIEM,
                      message: i18n.t(languageKeys.vui_long_chon),
                    },
                  ]}
                >
                  <Select
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    dataSource={dsPhong}
                    titleKey={fieldPhong.ten_phong}
                    onDropdownVisibleChange={(bool) => bool && apiLayDsPhong()}
                    showSearch
                    onSearch={searchPhongLayMau}
                    multiple
                    onChange={setSelectedPhongLayMau}
                    selectedList={selectedPhongLayMau}
                    setSelectedList={(newArr) => {
                      const key_plm = fieldDichVuKiThuat.phong_lay_mau;

                      setSelectedPhongLayMau(newArr);

                      form.setFields([
                        {
                          name: key_plm,
                          value: newArr,
                        },
                      ]);
                    }}
                    loading={loadingPhong}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name={fieldDichVuKiThuat.loai_thu_thuat} label={i18n.t(languageKeys.field_chon_loai_thu_thuat)}>
                  <Select
                    allowClear
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    dataSource={dsLoaiThuThuat}
                    titleKey={fieldDichVuKiThuat.ten_loai_thu_thuat}
                    valueKey={fieldDichVuKiThuat.loai_thu_thuat}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={chonLoaiThuThuat}
                    onDropdownVisibleChange={(bool) => bool && apiLayDsLoaiThuThuat()}
                    loading={loadingLoaiDvkt}
                  />
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </div>
      </div>

      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Ghi_chu)}</h4>

        <Form.Item name={fieldDichVuKiThuat.ghi_chu}>
          <Input.TextArea rows={2} placeholder={i18n.t(languageKeys.common_Nhap)} />
        </Form.Item>
      </div>
    </>
  );
};

export default FormDvkt;
