import { apis, keys } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import { fieldDanhMucMay, fieldKhoa } from "../fieldsDanhMuc";

//api lấy ds máy thực hiện
export const apiLayDsMayThucHIen = async ({ BENH_VIEN_ID = "", page = 1, search_string = "", limit = keys.limit, partner_code = "" }) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      page,
      limit,
      partner_code,
    };

    const response = await common_post(apis.dm_layds_may, requestData);
    // const response = await common_post(
    //   "https://e1da5a94-3d0d-467f-920d-be961ee27552.mock.pstmn.io/api/partner/public/ris/dmMayThucHienCLS",
    //   requestData
    // );
    console.log("hey", requestData);

    return response;
  } catch (error) {
    HLog("Api lay danh sach  error", error);
  }
};

export const apisThemMayThucHien = async ({ BENH_VIEN_ID = "", data = {}, partner_code = "" }) => {
  try {
    let requestData = {
      ...data,
      BENH_VIEN_ID,
      partner_code,
    };

    return await common_post(apis.dm_them_may_thuc_hien, requestData);
  } catch (error) {
    HLog("Api them may error", error);
  }
};
export const apiSuaMayThucHien = async ({ data = {} }) => {
  try {
    let requestData = { ...data };

    return await common_post(apis.dm_cap_nhat_may_thuc_hien, requestData);
  } catch (error) {
    HLog("Api sua khoa error", error);
  }
};

export const apiXoaMayThucHien = async ({ BENH_VIEN_ID = "", partner_code = "", data = {} }) => {
  try {
    let requestData = { partner_code, arr_ID: [data.ID], BENH_VIEN_ID };

    const response = await common_post(apis.xoa_dm_may, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa khoa error", error);
  }
};

export const apiXoaDsMayThucHien = async ({ partner_code = "", data = [], BENH_VIEN_ID = "" }) => {
  try {
    const arr_ID = data.map((item) => item[fieldKhoa.id]);
    let requestData = { partner_code, arr_ID, BENH_VIEN_ID };

    const response = await common_post(apis.xoa_dm_may, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa danh sach may error", error);
  }
};

export const apiKhoaDsMayThucHien = async ({ partner_code = "", data = [], lock = false, BENH_VIEN_ID = "" }) => {
  try {
    let arr_ID = [];
    arr_ID = data.map((item) => item[fieldDanhMucMay.id]);

    let requestData = { BENH_VIEN_ID, partner_code, arr_ID, KHOA: !!lock ? 1 : 0 };

    const response = await common_post(apis.khoa_dm_may, requestData);

    return response;
  } catch (error) {
    HLog("Api khoa danh sach may error", error);
  }
};
