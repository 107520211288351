import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.less";
import { Row, Col, Tabs, Button, Layout } from "antd";
import FilterActions from "./components/FilterActions";
import cn from "classnames";
import ModalPhieuNhap from "./components/PhieuNhap/ModalPhieuNhap";
import DanhSachPhieuNhap from "./components/DanhSachPhieuNhap/DanhSachPhieuNhap";
import ModalPhieuXuat from "./components/PhieuXuat/ModalPhieuXuat";
import DanhSachPhieuXuat from "./components/DanhSachPhieuXuat/DanhSachPhieuNhap/DanhSachPhieuXuat";
import ModalChuyenKho from "./components/PhieuNhap/ModalChuyenKho";
import i18n, { languageKeys } from "../../../i18n";
import ModalPDF from "../../../components/Modal/ModalPDF";
import { useDispatch, useSelector } from "react-redux";
// import { updateTitle, resetTitle } from "../../../ducks//slices/titlePageSlice";
import SiderFilter from "./components/SiderFilter";
import { handleCheckPermissions } from "helpers";
import { featureKeys } from "constants/keys";
const { TabPane } = Tabs;
export const keyTabs = {
  PHIEU_NHAP: "PHIEU_NHAP",
  PHIEU_XUAT: "PHIEU_XUAT",
};
export const initDataFilter = {
  TU: "",
  DEN: "",
  search_string: "",
  PHAN_LOAI: "",
};
function NhapXuat() {
  const dispatch = useDispatch();
  const refPhieuNhap = useRef();
  const refDsPhieuNhap = useRef();
  const refPhieuXuat = useRef();
  const refDsPhieuXuat = useRef();
  const pdfRef = useRef();

  const refChuyenKho = useRef();
  const refFilter = useRef();
  const [tabView, setTabView] = useState(keyTabs.PHIEU_NHAP);
  const [dataFilter, setDataFilter] = useState(initDataFilter);
  const userProfile = useSelector((state) => state.auth.user);

  const [phanLoai, setPhanLoai] = useState("");
  const [phanLoaiPhieuNhap, setPhanLoaiPhieuNhap] = useState("");

  const [dataFilterNhap, setDataFilterNhap] = useState(initDataFilter);
  const [dataFilterXuat, setDataFilterXuat] = useState(initDataFilter);

  // useEffect(() => {
  //   dispatch(updateTitle(i18n.t(languageKeys.menu_Phieu_xuat_nhap)));
  //   return () => {
  //     dispatch(resetTitle());
  //   };
  // }, []);

  // xử lý khi chọn add phiếu
  function handleAddPhieu(key) {
    switch (key) {
      case "PHIEU_NHAP":
        refPhieuNhap.current.openModal();
        break;
      case "CHUYEN_KHO":
        refChuyenKho.current.openModal();
        break;
      default:
        refPhieuXuat.current.openModal(null, key);
        break;
    }
  }

  function handleChangeDataFilter(value) {
    // setDataFilter(
    //     {
    //         ...dataFilter,
    //         ...value
    //     }
    // )
  }

  async function handlePrint(api, PHIEU_THUOC_ID) {
    const dataRequest = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_THUOC_ID: PHIEU_THUOC_ID,
    };
    pdfRef.current.openModal(dataRequest, api);
  }

  const quyen = handleCheckPermissions(userProfile, featureKeys.phieu_xuat_nhap);

  return (
    <div className={styles["container"]}>
      <Tabs
        tabBarExtraContent={
          <FilterActions
            disableTaoPhieuNhap={!quyen?.TAO_PHIEU_NHAP}
            disableTaoPhieuXuat={!quyen?.TAO_PHIEU_XUAT}
            ref={refFilter}
            currentTab={tabView}
            onAddPhieu={handleAddPhieu}
            // handleChangeDate={handleChangeDataFilter}
            // handleChangeSearch={handleChangeDataFilter}
            // handleChangePhanLoai={setPhanLoai}
            // handleChangePhanLoaiPhieuNhap={setPhanLoaiPhieuNhap}
          />
        }
        onChange={(key) => {
          setTabView(key);
          // refFilter.current.reset()
          // setDataFilter(initDataFilter)
          // setPhanLoai("")
        }}
      >
        <TabPane
          tab={
            <Button className={cn(tabView === keyTabs.PHIEU_NHAP ? styles["button-active"] : styles["button"])}>
              <p className={cn(tabView === keyTabs.PHIEU_NHAP ? styles["title-active"] : styles["title"])}>{i18n.t(languageKeys.loai_phieu_nhap)}</p>
            </Button>
          }
          key={keyTabs.PHIEU_NHAP}
        >
          <Layout>
            <SiderFilter
              currentTab={keyTabs.PHIEU_NHAP}
              handleApply={(value) => {
                setDataFilterNhap(value);
              }}
            />
            <Layout>
              <Layout.Content>
                <DanhSachPhieuNhap
                  ref={refDsPhieuNhap}
                  onShowDetail={(item, isDuyetPhieu = false) => {
                    item.PHAN_LOAI === "CHUYEN_KHO" ? refChuyenKho.current.openModal(item, isDuyetPhieu) : refPhieuNhap.current.openModal(item);
                  }}
                  dataFilter={dataFilterNhap}
                  onPrint={(url, id) => handlePrint(url, id)}
                  disableDelete={!quyen?.XOA}
                  disablePrint={!quyen?.IN_PHIEU}
                  tooltipInTheoQuyen={quyen?.IN_PHIEU}
                  tooltipXoaTheoQuyen={quyen?.XOA}
                  tooltipDuyetTheoQuyen={quyen?.DUYET_PHIEU}
                  // phanLoai={phanLoaiPhieuNhap}
                />
              </Layout.Content>
            </Layout>
          </Layout>
        </TabPane>
        <TabPane
          tab={
            <Button className={cn(tabView === keyTabs.PHIEU_XUAT ? styles["button-active"] : styles["button"])}>
              <p className={cn(tabView === keyTabs.PHIEU_XUAT ? styles["title-active"] : styles["title"])}>{i18n.t(languageKeys.loai_phieu_xuat)}</p>
            </Button>
          }
          key={keyTabs.PHIEU_XUAT}
        >
          <Layout>
            <SiderFilter
              currentTab={keyTabs.PHIEU_XUAT}
              handleApply={(value) => {
                setDataFilterXuat(value);
              }}
            />
            <Layout.Content>
              <DanhSachPhieuXuat
                ref={refDsPhieuXuat}
                onShowDetail={(item) => refPhieuXuat.current.openModal(item)}
                dataFilter={dataFilterXuat}
                onPrint={(url, id) => handlePrint(url, id)}
                disableDelete={!quyen?.XOA}
                  disablePrint={!quyen?.IN_PHIEU}
                  tooltipInTheoQuyen={quyen?.IN_PHIEU}
                  tooltipXoaTheoQuyen={quyen?.XOA}
                  tooltipDuyetTheoQuyen={quyen?.DUYET_PHIEU}
              />
            </Layout.Content>
          </Layout>
        </TabPane>
      </Tabs>
      <ModalPhieuNhap
        ref={refPhieuNhap}
        doAfterSuccess={() => {
          refPhieuNhap.current.closeModal();
          refDsPhieuNhap.current.reload();
        }}
        disableHuyPhieu={!quyen?.HUY_DUYET}
        disableInPhieu={!quyen?.IN_PHIEU}
      />
      <ModalPhieuXuat
        ref={refPhieuXuat}
        doAfterSuccess={() => {
          refPhieuXuat.current.closeModal();
          refDsPhieuXuat.current.reload();
        }}
        disableInPhieu={!quyen?.IN_PHIEU}
        disableHuyPhieu={!quyen?.HUY}
      />
      <ModalChuyenKho
        ref={refChuyenKho}
        doAfterSuccess={(close = true) => {
          if (close) {
            refChuyenKho.current.closeModal();
          }
          refDsPhieuNhap.current.reload();
        }}
        disableHuyPhieu={!quyen?.HUY_DUYET}
        disableDuyetPhieu={!quyen?.DUYET_PHIEU}
        disableInPhieu={!quyen?.IN_PHIEU}
      />
      <ModalPDF ref={pdfRef} />
    </div>
  );
}

export default NhapXuat;
