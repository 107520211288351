import { Button, Col, Dropdown, Row } from "antd";
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import style from "./timKiemThuocPopup.module.less";
import i18n, { languageKeys } from "../../../../../i18n";
import { HLog, isEmptyObject, rid } from "../../../../../helpers";
import { Table } from "../../../../../components";
import { keys } from "../../../../../constants";
import { useSelector } from "react-redux";
import { apiLayChiTietDonThuocMau, apiLayDsDonThuocMau, apiXoaDonThuocMau } from "./apisKeDonThuoc";
import { useEventListener } from "../../../../../hooks";
import { Delete } from "../../../../../assets/svg";

const columns_data = [
  {
    title: i18n.t(languageKeys.txt_ma_mau_don_thuoc),
    dataIndex: "MA_DON_THUOC_MAU",
    width: '100px'
  },
  {
    title: i18n.t(languageKeys.txt_ten_mau_don_thuoc),
    dataIndex: "TEN_DON_THUOC_MAU",
    width: '200px'
  },
];

const DonThuocMauPopup = forwardRef(
  (
    {
      children,
      handleThemDonThuocMau = (data ) => {},
      handleThayTheDonThuocMau = (data ) => {},
      focusInput = () => {},
      clearInput = () => {},
    },
    ref
  ) => {
    const userProfile = useSelector((state) => state.auth.user);
    const [visible, setVisible] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [loadingDataSource, setLoadingDataSource] = useState([]);
    const [total, setTotal] = useState(0);
    const [selectingIndex, setSelectingIndex] = useState(-1);
    const [currentSearch, setCurrentSearch] = useState(undefined)
    const [mauDonDaLoad, setMauDonDaLoad] = useState({})
    let isKhoThuoc =
    userProfile.config && userProfile.config?.KHO_THUOC
    ? userProfile.config.KHO_THUOC
    : false;
    const [columns, setColumns] = useState(columns_data);
    useEffect(() => {
      if(dataSource.length > 0){
        setSelectingIndex(0)
      }else{
        setSelectingIndex(-1)
      }},[dataSource])
    useImperativeHandle(ref, () => ({
      visible,
      setVisible,
      searchData:(data) => getDataSource(data) ,
      resetData:() => { 
        setDataSource([])
        setCurrentSearch(undefined)
      },
    }));


    // hàm lấy danh sách đơn thuốc mẫu
    const   getDataSource = async (data) => {
      try {
        if(!visible) {
          setVisible(true);
        }
        if(data === currentSearch){
          return
        }
        console.log(userProfile);

        const dataRequest = {
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          partner_code: userProfile.partner_code,
          limit: keys.limit,
          page: 1, 
          BAC_SI_ID:  userProfile.NHANSU_ID , 
          search_string: data
        };
        
        setLoadingDataSource(true);
        let response = await apiLayDsDonThuocMau(dataRequest);
        setCurrentSearch(data)
        if(response && !isEmptyObject(response)) {
          const list = response.result.map((item) => ({
            ...item,
            key: rid(5),
          }));
          setDataSource(list);
          setTotal(response.total);
        }
        setLoadingDataSource(false);
      } catch (error) {
        HLog(error);
        setLoadingDataSource(false);
      }
    };

    // hàm xóa đơn thuốc mẫu
    const xoaDonThuocMau = async (data) => {
      try {

        const dataRequest = {
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          partner_code: userProfile.partner_code,
          DON_THUOC_ID: data.DON_THUOC_ID,
        };
        
        setLoadingDataSource(true);
        let response = await apiXoaDonThuocMau(dataRequest);
        if(response && !isEmptyObject(response)) {
          setDataSource(dataSource.filter(item => item.DON_THUOC_ID !== data.DON_THUOC_ID))
          setLoadingDataSource(false);
        }
      } catch (error) {
        HLog(error);
        setLoadingDataSource(false);
      }
    };


    // hàm tìm kiếm thuốc
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const searchData = useCallback(debounce(getDataSource, 1000), []);

    // hàm xử lý hiển thị popup
    const handleVisible = (bool) => {
      setVisible(bool);
      if (bool) getDataSource();
      else {
        // setTimeout(() => setDataSource([]), 200);
        // clearInput();
        setSelectingIndex(-1)
      }
    };

    // hàm xử lý khi chọn 1 row thuốc
    const handleClickRow = async (record) => {};

    // hàm xử lý khi chọn thêm hoặc thay thế 
    const handleActionClickRow = async (record, type = "THEM") => {
      console.log(record, mauDonDaLoad, record.DON_THUOC_ID === mauDonDaLoad.DON_THUOC_ID);
      if(record.DON_THUOC_ID === mauDonDaLoad.DON_THUOC_ID){
        if(type === "THEM"){
          handleThemDonThuocMau(mauDonDaLoad.data)
        }else{
          handleThayTheDonThuocMau(mauDonDaLoad.data)
        }
        return
      }
      try {

        const dataRequest = {
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          partner_code: userProfile.partner_code,
          DON_THUOC_ID : record.DON_THUOC_ID
        };
        
        let response = await apiLayChiTietDonThuocMau(dataRequest);
        if(response && !isEmptyObject(response)) {
         console.log(response);
         if(response.status === "OK"){
          setMauDonDaLoad({DON_THUOC_ID:record.DON_THUOC_ID, data: response.result})
          if(type === "THEM"){
            handleThemDonThuocMau(response.result)
          }else{
            handleThayTheDonThuocMau(response.result)
          }
         }
        }
      } catch (error) {
        HLog(error);
      }
    };

    // hàm xử lý phím tắt
  function keydownEvent(event) {

    if(!visible){return}
    if (event.key === "ArrowUp" ) {
      event.preventDefault();
      setSelectingIndex((currentIndex) => {
        if(currentIndex <= 0 ){
          return dataSource.length - 1
        }else{
          return currentIndex - 1
        }
      })
    }else if (event.key === "ArrowDown" ) {
      event.preventDefault();
      setSelectingIndex((currentIndex) => {
        if(currentIndex >= dataSource.length - 1 ){
          return 0
        }else{
          return currentIndex + 1
        }
      })
    }else if (event.key === "Enter" ) {
      event.preventDefault();
      if(!!dataSource[selectingIndex]){
        handleClickRow(dataSource[selectingIndex])
      }
    }
    else if (event.key === "Backspace" && visible ) {
      event.preventDefault();
      setVisible(false)
    }
  }

  useEventListener("keydown", keydownEvent, window, visible);

    return (
      <Dropdown
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisible}
        overlayClassName={style["template-container"]}
        overlay={
          <>
            <h4 className={style["title"]} onClick={focusInput}>
              {i18n.t(languageKeys.field_Ket_qua_tim_kiem)}: {total}
            </h4>

            <div className={style["table-ctn"]}>
              <Table
                columns={[...columns, {
                    title: "",
                    key: "action_btn",
                    dataIndex: "action_btn",
                    width: "190px",
                    render: (_, record) => (
                      <Row
                        align = 'middle'
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                      <Col><Button size='small' type="primary" onClick={() => handleActionClickRow(record, "THEM")} >{i18n.t(languageKeys.common_Them)}</Button></Col>
                      <Col><Button size='small' className={style["replace-button"]} type="primary" color = {'#2CB2A5'} onClick={() => handleActionClickRow(record, "THAY_THE")}>{i18n.t(languageKeys.common_Thay_the)}</Button></Col>
                      <Col className={style["delele-container"]}><Delete className={style["delete-icon"]} onClick={() => xoaDonThuocMau(record)}/></Col>
                      </Row>
                    ),
                  },]}
                dataSource={dataSource}
                loading={loadingDataSource}
                scroll={{ y: "50vh" }}
                onClickRow={handleClickRow}
                pointingIndex = {selectingIndex}
                pointingRow = {true}
                onHeaderRow={() => ({
                  onClick: focusInput,
                })}
              />
            </div>
          </>
        }
      >
        <div style={{ width: "100%" }}>{children}</div>
      </Dropdown>
    );
  }
);

export default memo(DonThuocMauPopup);
