import { AutoComplete, Input, Spin } from "antd";
import { useEffect, useState } from "react";
import i18n, { languageKeys } from "../../../../../i18n";

export const InputCachDung = ({ readOnly = false, rowIndex, value, onChange, dataSource, loading, required }) => {
  
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false);
  
  useEffect(() => {
    if (required) {
      if (!value && !error) {
        setError(true);
      } else {
        error && setError(false);
      }
    }
  }, [value]);

  const handleChange = (val) => {
    onChange(val, rowIndex, "ThoiDiemDung")
    setOpen(false)
  }

  // return (
  //   <Form.Item name="ThoiDiemDung" label={i18n.t(languageKeys.field_Cach_dung)} rules={[{ required: true, message: ERROR_CHUA_NHAP_DU_THONG_TIN }]}>
  //     {readOnly ? (
  //       <Input readOnly />
  //     ) : (
  //       // <AutoComplete
  //       //   allowClear
  //       //   options={dataSource}
  //       //   placeholder={!readOnly && i18n.t(languageKeys.common_Nhap)}
  //       //   filterOption={(input, option) => option.value.toLowerCase().includes(input.toLowerCase())}
  //       //   popupClassName={styles["popup-cach-dung"]}
  //       //   className={styles["input-cach-dung"]}
  //       // />
  //         <Select
  //             placeholder={i18n.t(languageKeys.common_Chon)}
  //             dataSource={dataSource}
  //             valueKey='value'
  //             titleKey='label'
  //             showSearch
  //             onDropdownVisibleChange={(bool) => bool && layDsCoHieuLuc()}
  //             className={styles["input-cach-dung"]}
  //             loading={loading}
  //             onSearch={searchDsCoHieuLuc}
  //         />
  //     )}
  //   </Form.Item>
  // );
  return readOnly ? <Input readOnly value={value} />
    : (
      // <Select
      //   placeholder={i18n.t(languageKeys.common_Chon)}
      //   dataSource={dataSource}
      //   valueKey='value'
      //   titleKey='label'
      //   showSearch
      //   className={styles["input-cach-dung"]}
      //   loading={loading}
      //   value={value}
      //   onChange={(val) => {
      //     onChange(val, rowIndex, "ThoiDiemDung")
      //     setOpen(false)
      //   }}
      //   // status={value ? "" : "error"}
      //   filterOption={(item, option) => option.children.toLowerCase().includes(item.toLowerCase())}
      //   open={open}
      //   onFocus={() => setTimeout(() => setOpen(true), 100)}
      //   onBlur={() => setOpen(false)}
      //   onClick={() => setOpen(!open)}
      //   allowClear
      //   onClear={(val) => onChange(val, rowIndex, "ThoiDiemDung")}
      // />
      <Spin spinning={loading}>
        <AutoComplete
          open={open}
          options={dataSource.map(i => ({ value: i.TEN_CACH_DUNG }))}
          disabled={readOnly}
          defaultValue={value}
          placeholder={i18n.t(languageKeys.common_Nhap)}
          allowClear
          status={error ? 'error' : undefined}
          filterOption={(inputValue, option) =>
            option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
          }
          onSelect={handleChange}
          onBlur={(e) => handleChange(e.target.value)}
          onFocus={() => setTimeout(() => setOpen(true), 100)}
          onClick={() => setOpen(!open)}
        />
      </Spin>
    )
};
