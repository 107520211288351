import React from "react"
import { Button, Space, Tooltip } from "antd"
import i18n, { languageKeys } from "../../../../i18n";
import style from "./actionRow.module.less";
import { Delete, EditAlt } from "../../../../assets/svg";
import { PrinterOutlined } from "@ant-design/icons";
import classNames from "classnames";

const ActionRows = ({
    onEdit = () => { },
    onPrint = () => { },
    onDelete = () => { },
    onShowDetail = () => { },
    onHuyTra = () => { },
    printDisabled = false,
    deleteDisabled = false,
    editDisabled = false,
    detailDisabled = false,
    showButtonDetail = true,
    tooltipEdit = i18n.t(languageKeys.tip_sua_ban_ghi),
    tooltipDel = i18n.t(languageKeys.tip_xoa_ban_ghi),
    isChild,
    showPerformCDHA = false,
    onShowCDHA = () => { },
    disableShowPerformCDHA = false,
    checkCDHAPermission,
    loadingThucHien = false,
    showBtnResult = false,
    showEdit =true,
    showDelete = true,
    onShowPTTT = () => { },
    checkPermissionsHuy = false,
    checkPTTTPermissionsTH = false,
    showPerformPTTT = false,
    text_xem_kq = i18n.t(languageKeys.xem_KQ),
    text_xem_th = i18n.t(languageKeys.thuc_hien)

}) => {
    return (
        <Space style={{ margin: 0, padding: 0 }} onClick={(e) => e.stopPropagation()}>

            {showButtonDetail && (
                (showPerformCDHA && detailDisabled)
                    ? (
                        <Tooltip title={checkCDHAPermission ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                            <Button
                                type="primary"
                                className={style["button"]}
                                onClick={onShowCDHA}
                                disabled={disableShowPerformCDHA || !checkCDHAPermission}
                                loading={loadingThucHien}
                            >
                                {text_xem_th}
                            </Button>
                        </Tooltip>
                    ) : (
                        <></>
                    )
            )}
            {showButtonDetail && (
                (showPerformPTTT && detailDisabled )
                    ? (
                        // <Tooltip title={checkPTTTPermissionsTH ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                        <Tooltip>
                            <Button
                                type="primary"
                                className={style["button"]}
                                onClick={onShowPTTT}
                                disabled={!checkPTTTPermissionsTH}
                                loading={loadingThucHien}
                            >
                                {text_xem_th}
                            </Button>
                         </Tooltip>
                    ) : (
                        <></>
                    )
            )}
            
            {
                showButtonDetail && showBtnResult  ? <>
                        <Tooltip title={checkCDHAPermission ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                        <Button
                            type="primary"
                            className={style["button"]}
                            onClick={onShowDetail}
                            disabled={detailDisabled}
                        >
                            {text_xem_kq}
                        </Button>
                        </Tooltip>
                        </> : null
            }
             {
                showButtonDetail && showBtnResult && checkPermissionsHuy  ? <>
                        <Tooltip title={(checkPermissionsHuy) ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                        <Button
                            type="dangerous"
                            className={`${style["button"]} ant-btn-dangerous`}
                            onClick={onHuyTra}
                            disabled={!checkPermissionsHuy}
                        >
                            {i18n.t(languageKeys.huy_tra)}
                        </Button>
                        </Tooltip>
                        </> : null
            }
            
            <Tooltip placement="topLeft" title={printDisabled ? i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay) : i18n.t(languageKeys.tip_in_ban_ghi)}>
                <Button
                    type="link"
                    icon={<PrinterOutlined className={style["icon"]} />}
                    onClick={() => onPrint()}
                    disabled={printDisabled}
                    className={classNames(style["print-btn-icon"], printDisabled && style["disabled-print"])}
                >
                    {/* {i18n.t(languageKeys.field_In_phieu)} */}
                </Button>
            </Tooltip>

            <Tooltip placement="topLeft" title={tooltipEdit}>
                {showEdit ?                 <Button type="link" onClick={() => onEdit()} style={{ padding: 0, display: "flex", alignItems: "center" }} disabled={editDisabled}>
                    {/* {i18n.t(languageKeys.common_Chinh_sua)} */}
                    <EditAlt className={style[editDisabled ? "icon-edit-btn-dis" : "icon-edit-btn"]} style={{ transform: "scale(1.2)" }} />
                </Button> : (<></>)}
            </Tooltip>

            <Tooltip placement="topLeft" title={tooltipDel} >
                {
                    showDelete ? (<Button
                        type="link"
                        onClick={() => onDelete()}
                        disabled={deleteDisabled}
                        style={{ padding: 0, display: "flex", alignItems: "center" }}
                    >
                        <Delete style={{ width: 20 }} className={style[deleteDisabled ? "disable-icon-delete-btn" : "icon-delete-btn"]} />
                    </Button>) : (<></>)
                }
            </Tooltip>

        </Space>
    )
}

export default ActionRows;
