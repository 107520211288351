import React, { useMemo, useRef, useState } from "react";
// import SiderGoiDichVu from "../SiderDvTiepDon/SiderGoiDichVu";
import TableGoiDichVu from "../components/TableGoiDichVu/TableGoiDichVu";
import { UarrowCircle } from "assets/svg";
import i18n, { languageKeys } from "../../../../i18n";
import { Button, Checkbox, Col, Form, Input, Layout, Row, Space, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { tiepDonState } from "ducks/slices/QlTiepdon/TiepDon.slice";
import { showSaveBtns } from "../helpers";
import style from "./style.module.less";
import { PrinterOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import { useEventListener } from "hooks";
import SiderGoiDichVu from "pages/QuanLyTiepDon/CuocHenSapToi/components/CuocHenGoiDichVu/SiderGoiDichVu";
import _ from "lodash";
import XemTienTrinhGoi from "./Components/XemTienTrinhGoi";

export const GoiDichVu = ({
  form,
  visible,
  readOnly,
  openTTBN,
  disableIn,
  hideDrawer,
  // currentTab,
  openKhaiThac,
  disableDichVu,
  disableThemMoi,
  disableBenhNhan,
  selectedPatient,
  allowAddNewServices,
  // selectedDoiTuong,
  // selectedNguonKhach,
  checkedContinueAdding,
  selectedService,
  setSelectedService,
  txtNote,
  hightlighter,
  // dsNguonKhach = [],

  onPrint = () => {},
  onClose = () => {},
  onFinish = () => {},
  setOpenTTBN = () => {},
  setOpenKhaiThac = () => {},
  layDsIdDvDaChon = () => {},
  // doUpdatePacketAppointment = () => { },
  // doCreatePacketAppointment = () => { },
  setCheckedContinueAdding = () => {},
  onSelectPatient = () => {},
  setTxtNote = () => {},
  onClearPatient = () => {},
  setBirthPatient = () => {},
  dsDvKhamBenhDaChon,
  dsDvClsDaChon,
  //
  disabledClick,
  duplicateInfoRef,
  //
  bundleReceipts,
  setBundleReceipts,
  checkShowModal,
  setLichKhamInfo = () => {},
  checkPermisson,
  statusDuyetSID,

  setBarcodeQuanLy,
  barcodeQuanLy,
  onHuyBarcode,
  maxlengthSID,
  getSIDApi,

  sid
}) => {

  const { lichKhamInfo, visibleSubActions, chiTietDsGoiDv, dsGoiDvBenhNhan } = useSelector(tiepDonState);

  const [exemption, setExemption] = useState();
  const [expandedRow, setExpandedRow] = useState([]);

  const cancelRef = useRef()
  const progressRef = useRef()

  const checkShowProgress = !!dsGoiDvBenhNhan?.find(i => i.NHOM_GOI === "LIEU_TRINH" && !!i.IS_ACTIVE)

  // const createRef = useRef();
  
  // const initDsDv = (dsDichvu = []) => {
  //   setSelectedService(
  //     dsDichvu.map((item) => ({
  //       ...item,
  //       DV_ID: item.DV_ID,
  //       key: rid(),
  //       PHONG_THUC_HIEN: {
  //         ...item,
  //         ID: item.PHONG_ID,
  //         GIO_BAT_DAU: item.GIO_BAT_DAU_LAM_VIEC_CUA_PHONG,
  //         GIO_KET_THUC: item.GIO_KET_THUC_LAM_VIEC_CUA_PHONG,
  //       },
  //       checked: true,
  //       old: true,
  //       disabled: !showDeleteBtn(item),
  //       BAC_SI: { NHANSU_ID: item.BAC_SI_ID, TEN_BAC_SI: item.BAC_SI },
  //       _id: `${item.ID}${item.RLS_BN_GOI_DV_ID}`,
  //     }))
  //   );
  // };

  // useEffect(() => {
  //   // if (chiTietDsGoiDv) {
  //   //   initDsDv(chiTietDsGoiDv);
  //   // }
  //   if (!!chiTietDsGoiDv && !!lichKhamInfo && !!dsGoiDvBenhNhan) {
  //     // let dvThuocGoi = chiTietDsGoiDv.map((i) => i.GOI_DV_ID);
  //     // let findPack = dsGoiDvBenhNhan.find((g) => dvThuocGoi.includes(`${g.GOI_DV_ID}`));
  //     // dispatch(TiepDonActions.setSelectedPacket(JSON.stringify(findPack)));
  //     // if (findPack) {
  //     //   initDsDv(chiTietDsGoiDv, findPack?.DS_DV);
  //     // }
  //     initDsDv(chiTietDsGoiDv);
  //   }
  // }, [lichKhamInfo, chiTietDsGoiDv, dsGoiDvBenhNhan]);

  // const onSubmit = async () => {
  //   setDisabledClick(true)
  //   try {
  //     let parsedValue;
  //     if (typeof selectedPacket === "string") {
  //       parsedValue = JSON.parse(selectedPacket);
  //     } else {
  //       parsedValue = selectedPacket;
  //     }

  //     let res;
  //     if (isRemainDup()) return;
  //     // if (!parsedValue) throw new Error("Không có gói được chọn");
  //     let values = await form.validateFields();
  //     // values.DOI_TUONG_BENH_NHAN = selectedDoiTuong;
  //     values.DM_NGUON_KHACH_ID = selectedNguonKhach;

  //     if (!lichKhamInfo) {
  //       if (selectedPatient) {
  //         delete selectedPatient.LICH_KHAM_ID;
  //       }

  //       // let checkGioKhamBenh = checkGioLamViec(dsDvKhamBenhDaChon);
  //       // let checkGioCLS = checkGioLamViec(dsDvClsDaChon);
  //       // if (checkGioKhamBenh.length > 0 || checkGioCLS.length > 0) {
  //       //   let arrPhong = checkGioKhamBenh.concat(checkGioCLS);
  //       //   let message = `Phòng ${arrPhong.join("; ")} đã hết giờ làm việc`;
  //       //   notification.error({
  //       //     placement: "bottomLeft",
  //       //     message: message,
  //       //   });
  //       //   return;
  //       // }
  //       res = await doCreatePacketAppointment(
  //         {
  //           ...selectedPatient,
  //           ...values,
  //           GHI_CHU: txtNote,
  //         },
  //         dsDvKhamBenhDaChon,
  //         dsDvClsDaChon,
  //         parsedValue?.GOI_DV_ID,
  //         selectedService
  //       );
  //     } else {
  //       res = await doUpdatePacketAppointment(
  //         {
  //           ...lichKhamInfo,
  //           ...selectedPatient,
  //           ...values,
  //           GHI_CHU: txtNote,
  //         },
  //         dsDvKhamBenhDaChon,
  //         dsDvClsDaChon,
  //         parsedValue?.GOI_DV_ID,
  //         selectedService
  //       );
  //     }

  //     return res;
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setDisabledClick(false)
  //   }
  // };



  const disableSaveCondition =
    (lichKhamInfo ? disableDichVu && disableBenhNhan : disableThemMoi) ||
    (dsDvKhamBenhDaChon.length === 0 && dsDvClsDaChon.length === 0 && selectedService.length === 0) ||
    !allowAddNewServices;


  // hàm xử lý phím tắt
  function keydownEvent(event) {
    const stopDefaultAction = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    if (showSaveBtns(lichKhamInfo) && !hideDrawer) {
      if (event.ctrlKey && event.key === "i" && !!lichKhamInfo) {
        stopDefaultAction(event);
        onPrint(lichKhamInfo.LICH_KHAM_ID, layDsIdDvDaChon());
      } else if (event.ctrlKey && event.key === "s" && !disableSaveCondition) {
        stopDefaultAction(event);
        !disabledClick && onFinish(false, checkShowModal);
      } else if (event.ctrlKey && event.key === "l" && !disableSaveCondition) {
        stopDefaultAction(event);
        !disabledClick && onFinish(true, checkShowModal);
      }
    }
  }
  useEventListener("keydown", keydownEvent, window, visible && !visibleSubActions);

  const arrServiceExamTabs = useMemo(() => {
    try {
      return [...dsDvClsDaChon, ...dsDvKhamBenhDaChon]
    } catch (error) {
      return []
    }
  }, [dsDvClsDaChon, dsDvKhamBenhDaChon])

  return (
    <React.Fragment>
      <SiderGoiDichVu
        // ref={siderRef}
        setSelectedService={setSelectedService}
        selectedService={selectedService}
        selectedPatient={selectedPatient}
        //
        setExpandedRow={setExpandedRow}
        formBn={form}
        onSelectPatient={onSelectPatient}
        duplicateInfoRef={duplicateInfoRef}
        //
        bundleReceipts={bundleReceipts}
        setBundleReceipts={setBundleReceipts}
      />
      <Layout>
        <Layout.Header>
          <Row gutter={8} justify="space-between">
            <Col>
              <Space>
                <h2
                  onClick={() => {
                    setOpenTTBN(!openTTBN);
                    openKhaiThac && setOpenKhaiThac(!openKhaiThac);
                  }}
                  style={{ paddingLeft: 16 }}
                >
                  {i18n.t(languageKeys.chi_dinh_goi_dich_vu)}
                  <Icon component={UarrowCircle} style={{ fontSize: 16, paddingInline: 8 }} />
                </h2>
              </Space>
              {selectedService.some(i => !i.THANH_TOAN) && bundleReceipts.length < selectedService.filter(i => !i.THANH_TOAN).length && (
                <Button
                  type="primary"
                  onClick={() => setBundleReceipts(selectedService.filter(i => !i.THANH_TOAN))}
                  disabled={!checkPermisson?.TAO_PHIEU_THU}
                >
                  {i18n.t(languageKeys.tao_phieu_thu)}
                </Button>
              )}
              {bundleReceipts.length > 0 && (
                <Button
                  type="primary" ghost
                  onClick={() => setBundleReceipts([])}
                  disabled={!checkPermisson?.TAO_PHIEU_THU}
                >
                  {i18n.t(languageKeys.huy_phieu_thu)}
                </Button>
              )}
              {selectedService.some(i => i.THANH_TOAN) && (
                <Button
                  type="primary" ghost danger
                  onClick={() => cancelRef.current?.open(_.uniq(chiTietDsGoiDv.flatMap(i => i.GOI_ID)), lichKhamInfo?.LICH_KHAM_ID)}
                  disabled={!checkPermisson?.HUY_PHIEU_THU}
                >
                  {i18n.t(languageKeys.huy_phieu_thu_da_tao)}
                </Button>
              )}
            </Col>

            {selectedPatient && checkShowProgress && (
              <Col>
                <Button
                  type="primary" ghost
                  onClick={() => progressRef.current?.open()}
                // disabled={!checkPermisson?.TAO_PHIEU_THU}
                >
                  {i18n.t(languageKeys.tien_trinh_sd_goi)}
                </Button>
              </Col>
            )}

          </Row>
        </Layout.Header>
        <Layout.Content style={{ padding: 12 }}>
          <TableGoiDichVu
            hightlighter={hightlighter}
            dataSource={selectedService}
            setDataSource={setSelectedService}
            readOnly={!showSaveBtns(lichKhamInfo) || readOnly}
            isCreateNew={!lichKhamInfo}
            onPrint={onPrint}
            scheduleInfo={lichKhamInfo}
            // openTTBN={openTTBN}
            disableDichVu={disableDichVu}
            disableIn={disableIn}
            exemption={exemption}
            setExemption={setExemption}
            //
            expandedRow={expandedRow}
            setExpandedRow={setExpandedRow}
            //
            bundleReceipts={bundleReceipts}
            setBundleReceipts={setBundleReceipts}
            setLichKhamInfo={setLichKhamInfo}
            cancelRef={cancelRef}
            checkPermisson={checkPermisson}
            arrServiceExamTabs={arrServiceExamTabs}
          />

          <div className={style["note-container"]}>
            <span>{i18n.t(languageKeys.field_Ghi_chu)}</span>
            <Input.TextArea
              autoSize
              className={style["note"]}
              placeholder={i18n.t(languageKeys.common_Nhap)}
              value={txtNote}
              onChange={(e) => setTxtNote(e.target.value)}
              readOnly={readOnly}
            />
          </div>
        </Layout.Content>

        <Layout.Footer className={style["footer"]}>
          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          {!lichKhamInfo ? (
            <Checkbox checked={checkedContinueAdding} onChange={(e) => setCheckedContinueAdding(e.target.checked)}>
              <span className="navy-txt">{i18n.t(languageKeys.tiepdon_nhap_lien_tuc)}</span>
            </Checkbox>
          ) : (
            <div></div>
          )}
           {/* <div style={{ display: "flex", gap: 10, alignItems:"center", color:"#2c3782" }}><span>SID</span><Form.Item
                style={{ marginBottom: 0 }}
                name={"BARCODE_XET_NGHIEM"}
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: i18n.t(languageKeys.nhap_dung_dinh_dang_so),
                  },
                ]}
              >
                <Input
                  readOnly={statusDuyetSID || barcodeQuanLy}
                  maxLength={maxlengthSID}
                  // suffix={renderPrintBarcodeButton()}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0, display:"none" }}
                name={"BARCODE_QUAN_LY"}
              >
                <Input
                />
              </Form.Item>
              { !barcodeQuanLy && userProfile?.config?.BARCODE_XET_NGHIEM == "BARCODE_VINH_VIEN" && (
                  <Space align="center" direction="vertical">
                    <Button type="primary" 
                    onClick={() => getSIDApi()}
                     >
                      {i18n.t(languageKeys.cap)} SID
                    </Button>
                  </Space>
                )}
              {barcodeQuanLy && (
                <Space align="center" direction="vertical">
                  <Button
                    type="primary"
                    onClick={() => onHuyBarcode()}
                  >
                    {i18n.t(languageKeys.common_Huy)} SID
                  </Button>
                </Space>
              )}
              
              </div> */}
          {sid && sid()}

          </div>

          <Space>
            <Button type="primary" ghost onClick={onClose}>
              {i18n.t(languageKeys.common_Thoat)} (ESC)
            </Button>

            {showSaveBtns(lichKhamInfo) && (
              <>
                {lichKhamInfo && (
                  <Button
                    type="primary"
                    icon={<PrinterOutlined />}
                    onClick={() => onPrint(undefined, layDsIdDvDaChon())}
                    disabled={disableIn}
                  >
                    {i18n.t(languageKeys.field_In_phieu)} (Ctrl + I)
                  </Button>
                )}
                <Tooltip title={(lichKhamInfo ? disableDichVu && disableBenhNhan : disableThemMoi) ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                  <Button
                    type="primary"
                    onClick={() => onFinish(true, checkShowModal)}
                    disabled={disableSaveCondition || disabledClick}
                  >
                    {i18n.t(languageKeys.luu_va_in)} (Ctrl + L)
                  </Button>
                </Tooltip>
                <Tooltip title={(lichKhamInfo ? disableDichVu && disableBenhNhan : disableThemMoi) ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                  <Button
                    type="primary"
                    onClick={() => onFinish(false, checkShowModal)}
                    disabled={disableSaveCondition || disabledClick}
                  >
                    {i18n.t(languageKeys.common_Luu)} (Ctrl + S)
                  </Button>
                </Tooltip>
              </>
            )}
          </Space>
        </Layout.Footer>
      </Layout>

      <XemTienTrinhGoi ref={progressRef} selectedPatient={selectedPatient} />

    </React.Fragment>
  );
};
