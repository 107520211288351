/* eslint-disable react-hooks/rules-of-hooks */
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apis, keys } from "../../../../../constants";
import { common_post, HLog, rid } from "../../../../../helpers";

export function hooksPhieuNhap({visible, readOnly}){
    const userProfile = useSelector(state => state.auth.user)
    const [dsKhoThuoc, setDsKhoThuoc] = useState([])
    const [dsNCC, setDsNCC] = useState([])
    const [dsThuoc, setDsThuoc] = useState([])
    const [loadingThuoc, setLoadingThuoc] = useState(false)
    const [dsNguon, setDsNguon] = useState([])

    useEffect(() => {
       if (visible && !readOnly) {
        layDsKhoThuoc()
        layDsNCC()
        layDsNguonNhap()
       }
        // layDsThuoc()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, readOnly])
    
    async function layDsKhoThuoc(search_string = "" , page = 1){
        try {
            let req = {
                partner_code : userProfile.partner_code,
                BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
                limit : keys.limit,
                page ,
                search_string,
                LOAI_KHO : "",
                LOAI_TRU : [],

            }
            const res = await common_post(apis.lay_ds_kho_thuoc_hieu_luc, req)
            console.log("ds kho thuốc", res)
            if (res && res.status === "OK") {
                setDsKhoThuoc(res.result)
            }
        } catch (error) {
            HLog("error ds kho thuốc", error)
        }
    }

    async function layDsNCC(search_string = "" , page = 1){
        try {
            let req = {
                partner_code : userProfile.partner_code,
                BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
                limit : keys.limit,
                page ,
                search_string,
            }
            const res = await common_post(apis.lay_ds_ncc_hieu_luc, req)
            if (res && res.status === "OK") {
                setDsNCC(res.result.map(item => ({TEN_NCC : item.TEN_NCC, ID : item.ID,})))
            }
        } catch (error) {
            HLog("error ds nhà cung cấp", error)
        }
    }

    async function layDsThuoc (search_string=""){
        setLoadingThuoc(false)
        let body={
          partner_code:userProfile.partner_code,
          BENH_VIEN_ID:userProfile.BENH_VIEN_ID,
          search_string,
            limit :keys.limit,
            page:1,
        }
    
        try {
          let res = await common_post(apis.search_dm_thuoc,body,false)
          if(!!res && res.status === "OK"){
            setDsThuoc(res.result.map((item) => { return {
              ...item,
              key:rid(),
            } }))
          }else{
            // notification.error({
            //   message:"lay ds thuoc that bai",
            //   placement:"bottomLeft",
            // })
            return
          }
          setLoadingThuoc(false)
        } catch (error) {
          HLog("TopACtionPhieu:",error)
        }
      };

      async function layDsNguonNhap (search_string=""){
        let body={
          partner_code:userProfile.partner_code,
          BENH_VIEN_ID:userProfile.BENH_VIEN_ID,
          search_string,
          limit :keys.limit,
          page:1,
        }
    
        try {
          let res = await common_post(apis.dm_lay_danh_sach_nguon_nhap_thuoc_co_hieu_luc,body,false)
    
          if(!!res && res.status === "OK"){
              setDsNguon(res.result.map(item => ({ID : item.ID, TEN_NGUON : item.TEN_NGUON, MA_NGUON : item.MA_NGUON})))
          }else{
            // notification.error({
            //   message:"lay ds thuoc that bai",
            //   placement:"bottomLeft",
            // })
            return
          }
        } catch (error) {
          HLog("TopACtionPhieu:",error)
        }
      };

      const handleSearchThuoc = useCallback(
        debounce((value) => layDsThuoc(value),1000)
        ,[]
      )

      const handleSearchKhoThuoc = useCallback(
        debounce((value) => layDsKhoThuoc(value),1000)
        ,[]
      )


      const handleSearchNCC = useCallback(
        debounce((value) => layDsNCC(value),1000)
        ,[]
      )



    
    return {
        dsKhoThuoc,
        dsNCC,
        dsThuoc,
        loadingThuoc,
        handleSearchThuoc,
        handleSearchKhoThuoc,
        handleSearchNCC, dsNguon
    }
    
}