import { useState, forwardRef, useImperativeHandle } from "react";
import style from "./customModal.module.less";
import { useForm } from "antd/lib/form/Form";
import { PlusCircleFilled } from "@ant-design/icons";
import { Form, Row, Col, Modal, Button, Input, notification } from "antd";
import { Select } from "../../../../../components";
import i18n, { languageKeys } from "../../../../../i18n";
import { Trash } from "../../../../../assets/svg";
import cn from "classnames";
import { useSelector } from "react-redux";
import { HLog } from "../../../../../helpers";
import {
  apiThemMoiNhomThuoc,
  apiSuaNhomThuoc,
} from "../../apiKhoThuoc/apisKhoThuoc";
import SelectTree from "../SelectTree/SelectTree";
import { fieldThuoc } from "../../../../QuanLyHeThong/QuanLyDanhMuc/fieldsDanhMuc";

const CustomModal = forwardRef(
  (
    {
      width = 341,
      showDelete,
      showSave = true,
      onSave = () => {},
      onDelete = () => {},
      clickOutsideDisabled = false,
      doAfterSubmit = () => {},
      dsNhom = [],
    },
    ref
  ) => {
    const userProfile = useSelector((state) => state.auth.user);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = useForm();
    const [isCreateNew, setIsCreateNew] = useState(true);
    const [initData, setInitData] = useState();
    const [loadingNhomCha, setLoadingNhomCha] = useState(false);
    const [dsNhomCha, setDsNhomCha] = useState([]);

    useImperativeHandle(ref, () => ({
      open(data) {
        setVisible(true);
        setInitData(data);
        if (!!data) {
          setInitData(JSON.parse(data));
          let passedData = JSON.parse(data);
          HLog(passedData);
          form.setFields(
            Object.values(fieldModalKhoThuoc).map((key) => {
              return {
                name: key,
                value: passedData[key],
              };
            })
          );
          setIsCreateNew(false);
        }
        setVisible(true);
      },
      close() {
        onClose();
      },
      visible,
      loading(bool) {
        setLoading(bool);
      },
    }));

    // Xử lý submit data khi thêm mới hoặc chỉnh sửa
    const handleSubmit = (values) => {
      HLog("Data Submit", values);
      let dataRequest = {
        ...initData,
        TEN_NHOM: values.TEN_NHOM,
        LEVEL: 0,
        NHOM_THUOC_ID: null,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
      };
      if (values.NHOM_CHA) {
        dataRequest = {
          ...dataRequest,
          LEVEL: JSON.parse(values.NHOM_CHA).LEVEL + 1,
          NHOM_THUOC_ID: JSON.parse(values.NHOM_CHA).ID,
        };
      }
      HLog("Data Request", dataRequest);
      if (isCreateNew) {
        apiThemMoiNhomThuoc(dataRequest).then((res) => {
          if (!!res) {
            notification.success({
              placement: "bottomLeft",
              message: i18n.t(languageKeys.them_moi_thanh_cong),
            });

            onClose();
            doAfterSubmit();
          } else {
            setLoading(false);
            notification.error({
              placement: "bottomLeft",
              message: i18n.t(languageKeys.them_moi_that_bai),
            });
          }

          setLoading(false);
        });
      } else {
        apiSuaNhomThuoc(dataRequest).then((res) => {
          if (!!res) {
            notification.success({
              placement: "bottomLeft",
              message: i18n.t(languageKeys.cap_nhat_thanh_cong),
            });

            onClose();
            doAfterSubmit();
          } else {
            setLoading(false);
            notification.error({
              placement: "bottomLeft",
              message: i18n.t(languageKeys.cap_nhat_that_bai),
            });
          }

          setLoading(false);
        });
      }
    };

    const onClose = () => {
      if (loading) setLoading(false);
      setVisible(false);
      setIsCreateNew(true);
      setInitData();
      form.resetFields();
    };

    const fieldModalKhoThuoc = {
      ten_nhom: "TEN_NHOM",
      nhom_cha: "NHOM_CHA",
    };

    return (
      <Modal
        className={style["modal"]}
        title={
          <Row align="middle" className={style["modal-header"]}>
            {isCreateNew && (
              <Col>
                <PlusCircleFilled className={style["modal-icon"]} />
              </Col>
            )}
            <Col>
              {isCreateNew
                ? i18n.t(languageKeys.title_them_moi_nhom_thuoc)
                : i18n.t(languageKeys.title_chinh_sua_nhom_thuoc)}
            </Col>
          </Row>
        }
        visible={visible}
        width={width}
        footer={null}
        onCancel={onClose}
      >
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Form.Item
            name={fieldModalKhoThuoc.ten_nhom}
            label={i18n.t(languageKeys.field_ten_nhom_thuoc)}
            rules={[
              {
                required: true,
                message: i18n.t(languageKeys.vui_long_nhap),
              },
            ]}
          >
            <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
          </Form.Item>
          <Form.Item
            name={fieldModalKhoThuoc.nhom_cha}
            label={i18n.t(languageKeys.field_nhom_cha)}
            // rules={[
            //   {
            //     required: true,
            //     message: i18n.t(languageKeys.vui_long_nhap),
            //   },
            // ]}
          >
            <SelectTree
              style={{ width: "100%" }}
              dataSource={dsNhom}
              childrenKey="NHOM_CON"
              titleKey= {fieldThuoc.ten_nhom_thuoc}
            ></SelectTree>
          </Form.Item>
          <Row align="end">
            {showDelete && !!onDelete && (
              <Col>
                <Button
                  type="primary"
                  className={cn(style["red-btn"], style["btn"])}
                  icon={<Trash style={{ marginRight: "8px" }} />}
                >
                  {i18n.t(languageKeys.common_Xoa)}
                </Button>
              </Col>
            )}
            {showSave && !!onSave && (
              <Col>
                <Button
                  type="primary"
                  className={style["btn"]}
                  onClick={() => form.submit()}
                  //htmlType="submit"
                >
                  {i18n.t(languageKeys.common_Luu)}
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    );
  }
);

export default CustomModal;
