import { notification } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apis } from "../../../../../constants";
import Constants from "../../../../../constants/Constants";
import { keyLoaiNhanVien, permissionActionKey } from "../../../../../constants/keys";
import { resetEventUpdate, updateReload } from "../../../../../ducks/slices/eventSlice";
import { loadThongTinThanhToan, setOpenFastPTTT, setReloadDataPTTT } from "../../../../../ducks/slices/khamBenhSlice";
import { common_post, getErrorMessage, handleCheckPermissions, HLog, isEmptyObject, rid } from "../../../../../helpers";
import i18n, { languageKeys } from "../../../../../i18n";
import _ from "lodash";
import { huyDichVu } from "pages/QuanLyVienPhi/DichVuCanThanhToan/apisDvThanhToan/apisDvThanhToan";
import { apiCapNhatTrangThaiDichVu, apiHuyTraKetQua } from "pages/RisNhapTay/TraKetQuaCdha/services";
var _eventSource = null;

export const useDsDvCls = ({
  patientInfo,
  phongChiDinh,
  trang_thai,
  isVisible,
  isViewHistory = false,
  setDsDv,
  userProfile,
  ref,
  deleteRef,
  pdfRef,
  confirmRef,
  formRefPTTT,
  tuongTrinhRef
}) => {
  const userIsAdminOrDoctor = userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.quan_tri || userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.bac_si; // nếu user là admin hoặc bác sĩ, user được phép khám bệnh

  const [dsDvTheoLuotKham, setDsDvTheoLuotKham] = useState([]);
  const [dsDvTheoLuotKhamOrigin, setDsDvTheoLuotKhamOrigin] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});

  const dataEvent = useSelector((state) => state.eventSlice.event);
  const updateEvent = useSelector((state) => state.eventSlice.eventUpdate);
  const reloadDataPTTT = useSelector((s) => s.khamBenh.reloadDataPTTT);
  const isEdit = trang_thai !== "KHAM_XONG" ? true : false;
  const dispatch = useDispatch();

  const configPermissions = useMemo(() => {
    return handleCheckPermissions(userProfile, "CHI_DINH_CLS");
  }, [userProfile]);

  const maCchnExist = !!userProfile.MA_CCHN; // user có MÃ CCHN

  useEffect(() => {
    if (!!patientInfo && !!isVisible) {
      apiLayDsDvTheoLuotKham();
    } else {
      selectedRows.length > 0 && setSelectedRows([]);
      !!selectedRecord && !isEmptyObject(selectedRecord) && setSelectedRecord({});
    }
    if (!patientInfo && isVisible && dsDvTheoLuotKham.length > 0) {
      setDsDvTheoLuotKhamOrigin([])
      setDsDvTheoLuotKham([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientInfo, isVisible]);

  useEffect(() => {
    const newExpandedRowKeys = dsDvTheoLuotKham.map((o) => o.key);
    setExpandedRowKeys(newExpandedRowKeys);
  }, [dsDvTheoLuotKham]);

  useEffect(() => {
    if (reloadDataPTTT) {
      apiLayDsDvTheoLuotKham();
    }

  }, [reloadDataPTTT]);

  // Cập nhật trạng thái thanh toán cls
  useEffect(() => {
    if (patientInfo) {
      if (updateEvent?.type === Constants.CAP_NHAT_THANH_TOAN && updateEvent?.LUOT_DIEU_TRI_ID === patientInfo.LUOT_DIEU_TRI_ID) {
        apiLayDsDvTheoLuotKham();
        dispatch(resetEventUpdate());
      }

      switch (updateEvent?.type) {
        case Constants.LUOT_DIEU_TRI_DA_TRA_KET_QUA:
        case Constants.LUOT_DIEU_TRI_DA_THUC_HIEN:
        case Constants.TRA_KET_QUA_XET_NGHIEM:
        case Constants.HUY_TRA_KET_QUA_XET_NGHIEM:
        case Constants.THUC_HIEN_XET_NGHIEM:
        case Constants.HUY_THUC_HIEN_XET_NGHIEM:
          if (updateEvent?.LUOT_DIEU_TRI_ID === patientInfo.LUOT_DIEU_TRI_ID) {
            apiLayDsDvTheoLuotKham();
            dispatch(resetEventUpdate());
          }
          break;
        default:
          break;
      }
    }
  }, [updateEvent, patientInfo]);

  useEffect(() => {
    if (dataEvent?.type === Constants.EVENT_THANH_TOAN_LUOT_DIEU_TRI) {
      apiLayDsDvTheoLuotKham();
    } else if (
      patientInfo &&
      dataEvent.type === Constants.EVENT_LUOT_DIEU_TRI_CHI_DINH_CLS &&
      dataEvent.LUOT_DIEU_TRI_ID === patientInfo.LUOT_DIEU_TRI_ID
    ) {
      apiLayDsDvTheoLuotKham();
    } else if (patientInfo && dataEvent.type === Constants.CUOC_KHAM_DA_THANH_TOAN && dataEvent.LICH_KHAM_ID === patientInfo.LICH_KHAM_ID) {
      apiLayDsDvTheoLuotKham();
    } else if (patientInfo && dataEvent.type === Constants.LUOT_DIEU_TRI_DA_TRA_KET_QUA && patientInfo.LUOT_DIEU_TRI_ID) {
      apiLayDsDvTheoLuotKham();
    }
  }, [dataEvent, patientInfo]);


  const formattingDvCombo = (item, index, arr, parentKey) => {
    try {
      if (Array.isArray(item.DV_KEM_THEO) && !item.DV_KEM_THEO?.length) throw new Error("Không có chi tiết phiếu");
      let { DV_KEM_THEO } = item;
      let result = [];
      DV_KEM_THEO.forEach((i) => {
        result.push({
          ...i,
          // TEN_KHOA_PHONG: item.PHONG_THUC_HIEN,
          PHONG_THUC_HIEN: i.PHONG_THUC_HIEN,
          is_combo: true,
          parentKey,
          key: rid(),
        });
      });
      return result;
    } catch (error) {
      return null;
    }
  };

  const formatingExams = (item, index, arrData, parentKey) => {
    try {
      if (!item.CHITIET_PHIEU) throw new Error("Không có chi tiết phiếu");
      let result = {};
      let arr_ctp = [...item.CHITIET_PHIEU].map((i) => ({ ...i, parentKey, is_exam: true, DV_ID: i.DICH_VU_ID, TEN_KHOA_PHONG: i.PHONG_THUC_HIEN, key: rid() }));

      result = {
        ...item,
        TONG_THANH_TOAN: arr_ctp.reduce((acc, curr) => acc + (curr.GIA_DICH_VU * curr.SO_LUONG), 0) || 0,
        TEN_DICHVU: i18n.t(languageKeys.khambenh_phieu_so) + " " + (arrData.length - index),
        key: parentKey,
        children: arr_ctp,
      };

      return result;
    } catch (error) {
      console.log(error, error.message);
      return item;
    }
  };

  const formatingSubClicnical = (data = {}, index, arrData, parentKey) => {
    try {
      if (!data.CHITIET_PHIEU) throw new Error("Không có chi tiết phiếu");
      let result = {};
      let arr_ctp = [...data.CHITIET_PHIEU].map((i, index, arr) => {
        if (i.DV_KEM_THEO) {
          return { ...i, parentKey, is_subClinic: true, DV_ID: i.DICH_VU_ID, PHONG_THUC_HIEN: i?.PHONG_THUC_HIEN, TEN_KHOA_PHONG: i?.TEN_KHOA_PHONG, key: rid(), is_paprent_combo: i?.DV_KEM_THEO?.length > 0, children: formattingDvCombo(i, index, arr, parentKey) };
        } else {
          return { ...i, parentKey, is_subClinic: true, TEN_KHOA_PHONG: i?.TEN_KHOA_PHONG, PHONG_THUC_HIEN: i?.PHONG_THUC_HIEN, key: rid() };
        }
      });

      result = {
        ...data,
        TONG_THANH_TOAN: arr_ctp.reduce((acc, curr) => acc + (curr.GIA_DICH_VU * curr.SO_LUONG), 0) || 0,
        TEN_DICHVU: i18n.t(languageKeys.khambenh_phieu_so) + " " + (arrData.length - index),
        key: parentKey,
        children: arr_ctp,
      };

      return result;
    } catch (error) {
      console.log(error, error.message);
      return data;
    }
  };

  const dataFactory = async (resData = []) => {
    try {
      let data = [];

      resData.forEach((item, index, arrData) => {
        let parentKey = rid();
        if (item.CHITIET_PHIEU && Array.isArray(item.CHITIET_PHIEU) && item?.CHITIET_PHIEU?.length > 0) {
          // if (item.CHITIET_PHIEU && Array.isArray(item.CHITIET_PHIEU) && item.LOAI_PHONG !== "KHAM_BENH") {
          data.push({ ...formatingSubClicnical(item, index, arrData, parentKey) });
        } else {
          data.push(formatingExams(item, index, arrData, parentKey));
        }
      });

      return data;
    } catch (error) {
      console.log(error);
      return resData;
    }
  };

  const apiLayDsDvTheoLuotKham = async (reMap) => {
    try {
      setLoading(true);

      const req = {
        LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
        partner_code: userProfile.partner_code,
        TYPE: "CLS",
      };

      const res = await common_post(apis.khambenh_lay_ds_phieu_cls, req);

      if (res.status === "OK") {
        let { result } = res;
        let newData = await dataFactory(result);
        // let dataFormat = []
        // newData.forEach((element) => {
        //   if (element?.children && element?.children?.length > 0) {
        //     element.children.forEach((element2) => {
        //       dataFormat.push(element2);
        //     });
        //   }
        // });
        let dataFormat = newData.flatMap((element) => element.children ?? [])
        if (reMap) {
          dataFormat = dataFormat.map(item => ({
            ...item,
            NGUOI_THUC_HIEN_ID: dsDvTheoLuotKham.find(i => i.PDT_ID === item.PDT_ID)?.NGUOI_THUC_HIEN_ID ?? item.NGUOI_THUC_HIEN_ID
          }));
        }
        // let dataFormat = newData;
        setDsDvTheoLuotKham(dataFormat);
        setDsDvTheoLuotKhamOrigin(newData);

        setDsDv(dataFormat);
        dispatch(setReloadDataPTTT(false))

        // setDsDv(newData);

        // const newData = res.result.map((item, index, arrData) => {
        //   item.children = [];
        //   if (item.CHITIET_PHIEU) item.children = item.CHITIET_PHIEU;
        //   item.TEN_DICHVU = i18n.t(languageKeys.khambenh_phieu_so) + " " + (arrData.length - index);

        //   item.TONG_THANH_TOAN = item.children.reduce((sum, item) => (sum += parseInt(item.SO_LUONG * item.GIA_DICH_VU)), 0);

        //   const parentKey = rid();
        //   item.children.map((e) => {
        //     e.MA_PHIEU = item.MA_PHIEU;
        //     e.TRANG_THAI_THANH_TOAN = e.THANH_TOAN;
        //     e.LOAI_PHONG = item.LOAI_PHONG;
        //     e.BARCODE_QUAN_LY = item.BARCODE_QUAN_LY || null;
        //     //  item.TONG_THANH_TOAN = item.SO_LUONG * item.GIA_DICH_VU;
        //     e.PHONG_ID = item.PHONG_ID;
        //     e.LUOT_DIEU_TRI_ID = item.LUOT_DIEU_TRI_ID;
        //     e.PHIEU_ID = item.PHIEU_IN_CLS_ID;
        //     e.key = rid();

        //     e.parentKey = parentKey;
        //     return e;
        //   });

        //   return {
        //     ...item,
        //     key: parentKey,
        //   };
        // });
        // setDsDvTheoLuotKham(newData);
        // setDsDv(newData);
      }
    } catch (error) {
      HLog(error);
    }

    setLoading(false);
  };

  const apiXoaDsDvCLS = async (data, callback = () => {}) => {
    // console.log("selected row keyss", selectedRows);
    let arr_PHIEU_ID = []; // phiếu cha
    let arr_CHITIET_PHIEU_ID = []; // dịch vụ con

    let array_parent = selectedRows.filter((item) => item.CHITIET_PHIEU);
    if (array_parent.length > 0) {
      arr_PHIEU_ID = array_parent.map((item) => item.PHIEU_IN_CLS_ID);
    }
    let array_children = selectedRows.filter((item) => !item.CHITIET_PHIEU);
    arr_CHITIET_PHIEU_ID = array_children.map((item) => {
      return item.CHITIET_PHIEU_ID;
    });

    let req = {
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
      PHIEU_ID: arr_PHIEU_ID,
      CHITIET_PHIEU_ID: arr_CHITIET_PHIEU_ID,
    };

    if (isAllowedToDeleteListCls(selectedRows)) {
      return notification.error({
        placement: "bottomLeft",
        message: i18n.t(languageKeys.khambenh_err_xoa_dich_vu),
      });
    }

    try {
      deleteRef.current.loading(true);

      const res = await common_post(apis.huy_dich_vu_cls_moi, req);
      if (res.status === "OK") {
        dispatch(
          loadThongTinThanhToan({
            partner_code: userProfile.partner_code,
            BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
            LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
            LICH_KHAM_ID: patientInfo.LICH_KHAM_ID,
          })
        );
        deleteRef.current.close();
        confirmRef.current.close();
        notification.success({
          placement: "bottomLeft",
          message: i18n.t(languageKeys.noti_Xoa_thanh_cong),
        });
        callback();
        return apiLayDsDvTheoLuotKham();
      } else {
        console.log("runnig else");
        notification.warn({
          placement: "bottomLeft",
          message: getErrorMessage(res.error),
        });
        console.log(getErrorMessage(res.error_code));
      }
    } catch (error) {
      HLog(error);
    }

    deleteRef.current.loading(false);
    return notification.error({
      placement: "bottomLeft",
      message: i18n.t(languageKeys.noti_Xoa_that_bai),
    });
  };

  const apiXoaDsDvCLSChildren = async (data, callback = () => {}) => {
    try {
      deleteRef.current.loading(true);

      if (!data.CHITIET_PHIEU_ID) {
        let dataRequest = {
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          data: [
            {
              LUOT_DIEU_TRI_ID: data.LUOT_DIEU_TRI_ID,
              PDT_ID: data.PDT_ID,
            },
          ],
        };
        const rest = await huyDichVu(dataRequest)

        if (rest.status === "OK") {
          dispatch(
            loadThongTinThanhToan({
              partner_code: userProfile.partner_code,
              BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
              LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
              LICH_KHAM_ID: patientInfo.LICH_KHAM_ID,
            })
          );
          deleteRef.current.close();
          notification.success({
            placement: "bottomLeft",
            message: i18n.t(languageKeys.noti_Xoa_thanh_cong),
          });
          callback();
          return apiLayDsDvTheoLuotKham();
        }
      }
      let arr_CHITIET_PHIEU_ID = [data.CHITIET_PHIEU_ID]; // dịch vụ con
      let arr_PHIEU_ID = []; // phiếu cha

      let parent = dsDvTheoLuotKhamOrigin.find((item) => item.PHIEU_IN_CLS_ID === data.PHIEU_IN_CLS_ID);
      if (parent && parent.CHITIET_PHIEU.length === 1) {
        arr_PHIEU_ID = [parent.PHIEU_IN_CLS_ID];
      }

      let req = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
        PHIEU_ID: arr_PHIEU_ID,
        CHITIET_PHIEU_ID: arr_CHITIET_PHIEU_ID,
      };

      const res = await common_post(apis.huy_dich_vu_cls_moi, req);
      if (res.status === "OK") {
        dispatch(
          loadThongTinThanhToan({
            partner_code: userProfile.partner_code,
            BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
            LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
            LICH_KHAM_ID: patientInfo.LICH_KHAM_ID,
          })
        );
        deleteRef.current.close();
        notification.success({
          placement: "bottomLeft",
          message: i18n.t(languageKeys.noti_Xoa_thanh_cong),
        });
        callback();
        return apiLayDsDvTheoLuotKham();
      } else {
        console.log("runnig else");
        notification.warn({
          placement: "bottomLeft",
          message: getErrorMessage(res.error_code),
        });
        console.log(getErrorMessage(res.error_code));
      }
    } catch (error) {
      HLog(error);
    }

    deleteRef.current.loading(false);
    return notification.error({
      placement: "bottomLeft",
      message: i18n.t(languageKeys.noti_Xoa_that_bai),
    });
  };

  const onClickRow = (record) => {
    if (isViewHistory) return;
    ref.current.openModal(record);
  };

  const conditionCdhaRoom = (record) => {
    try {
      return record.LOAI_PHONG === "CDHA" || record.LOAI_PHONG === "TDCN" || record.PHAN_LOAI === "TDCN" || record.PHAN_LOAI === "CDHA";
    } catch (error) {
      return false;
    }
  };

  const checkDisabledDetail = (record) => {
    return record.TRANG_THAI_KET_QUA === "DA_TRA_KET_QUA" || record.TRANG_THAI_KET_QUA === 1 ? false : true;
  };

  const checkDisabledPrint = (record) => {};
  const checkDisabledDelete = (record) => {
    /// dịch vụ chẩn đoán hình ảnh
    if (
      record.TRANG_THAI_THANH_TOAN === "DA_THANH_TOAN" ||
      record.TRANG_THAI_THANH_TOAN === 1 ||
      record.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN" ||
      record.TRANG_THAI_THUC_HIEN === 1 ||
      record.TRANG_THAI_KET_QUA === "DA_TRA_KET_QUA" ||
      record.TRANG_THAI_KET_QUA === 1 ||
      record.THANH_TOAN === 1 ||
      record.BARCODE_QUAN_LY
    )
      return true;
  };

  const checkDisabledEdit = (record) => {
    if (
      !userIsAdminOrDoctor ||
      record.TRANG_THAI_THANH_TOAN === "DA_THANH_TOAN" ||
      record.TRANG_THAI_THANH_TOAN === 1 ||
      record.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN" ||
      record.TRANG_THAI_THUC_HIEN === 1 ||
      record.TRANG_THAI_KET_QUA === "DA_TRA_KET_QUA" ||
      record.TRANG_THAI_KET_QUA === 1
    )
      return true;
  };

  const checkShowButtonDetail = (record) => {

    if (record.LOAI_PHONG === "XET_NGHIEM" || record.PHAN_LOAI === "XET_NGHIEM" ||
      record.LOAI_PHONG === "CDHA" || record.PHAN_LOAI === "CDHA" ||
      record.LOAI_PHONG === "TDCN" || record.PHAN_LOAI === "TDCN" ||
      record.LOAI_PHONG === "THU_THUAT" || record.PHAN_LOAI === "THU_THUAT") {
      return true
      return record.CHITIET_PHIEU ? true : false;
    } else {
      return record.TEN_KHOA_PHONG !== undefined || record.PHONG_THUC_HIEN !== undefined;
    }
  };

  const getApiKhamBenh = record => {

    try {
      const dataRequest = {
        LICH_KHAM_ID: record.LICH_KHAM_ID,
        list_DV_ID: [],
      };

      if (record.children === undefined) {
        dataRequest.list_DV_ID = [record.DICH_VU_ID];
      } else {
        let list_DV_ID = [];
        list_DV_ID = record.children.map((item) => item.DICH_VU_ID);
        dataRequest.list_DV_ID = [record.DICH_VU_ID, ...list_DV_ID];
      }


      const api = apis.lay_data_in_phieu_tiep_don_v2;

      return { dataRequest, api }

    } catch (error) {
      console.log(error)
      return {}
    }

  };
  const getApiCls = record => {
    try {
      const dataRequest = {
        LUOT_DIEU_TRI_ID: record.LUOT_DIEU_TRI_ID,
        partner_code: record.BENH_VIEN_BRAND || userProfile.partner_code,
        BENH_VIEN_ID: record?.BENH_VIEN_ID || userProfile.BENH_VIEN_ID,
      };

      if (record?.DICH_VU_GOC || record?.DICH_VU_ID_GOC) {
        dataRequest.list_DV_ID_CON = [record.DV_ID || record.DICH_VU_ID]
      } else {
        dataRequest.list_DV_ID = [record.DV_ID || record.DICH_VU_ID]

      }

      // console.log(`record----------------`, record);

      // if (!!record.list_DV_ID) {
      //   dataRequest.list_DV_ID = record.list_DV_ID;
      // } else {
      //   if (record.children === undefined || !record.children) {
      //     dataRequest.list_DV_ID = [record.DICH_VU_ID];
      //   } else {
      //     if (record.is_paprent_combo) {
      //       dataRequest.list_DV_ID = [record.DICH_VU_ID];
      //     }else{
      //       list_DV_ID = record.children.map((item) => item.DICH_VU_ID);
      //       dataRequest.list_DV_ID = [...list_DV_ID] ;
      //     }
      //   }
      // }
      const api = apis.lay_data_in_phieu_dv_cls;

      return { dataRequest, api }
    } catch (error) {
      console.log(error)
      return {}
    }
  };

  const getApiType = (record) => {
    try {

      let { LOAI_PHONG = "", PHAN_LOAI = "" } = record;

      // if(record?.DV_KEM_THEO?.length || LOAI_PHONG === 'KHAM_BENH'|| PHAN_LOAI === 'KHAM_BENH') {
      //   return getApiCls(record);               
      // } else {
      return getApiCls(record);
      // }

    } catch (error) {
      return {}
    }
  };

  async function onPrint(record) {
    HLog(record);
    let { dataRequest, api } = getApiType(record);
    if (!dataRequest || !api) {
      notification.error({ message: i18n.t(languageKeys.thao_tac_that_bai), placement: "bottomLeft" })
      return
    }
    await pdfRef.current.openModal(dataRequest, api);


    return
    // let list_DV_ID = [];

    // const dataRequest = {
    //   LUOT_DIEU_TRI_ID: record.LUOT_DIEU_TRI_ID,
    //   partner_code: record.BENH_VIEN_BRAND || userProfile.partner_code,
    //   BENH_VIEN_ID: record?.BENH_VIEN_ID || userProfile.BENH_VIEN_ID,
    //   list_DV_ID: list_DV_ID,
    // };
    // if (!!record.list_DV_ID) {
    //   dataRequest.list_DV_ID = record.list_DV_ID;
    // } else {
    //   if (record.children === undefined) {
    //     dataRequest.list_DV_ID = [record.DICH_VU_ID];
    //   } else {
    //     list_DV_ID = record.children.map((item) => item.DICH_VU_ID);
    //     dataRequest.list_DV_ID = list_DV_ID;
    //   }
    // }

    // // const api = apis.khambenh_in_phieu_cls;
    // const api = apis.lay_data_in_phieu_dv_cls;
    // await pdfRef.current.openModal(dataRequest, api);
  }


  const format_data_in = (payload) => {

    let objPrintf = []
    // Lấy dữ liệu đầu vào 
    payload.forEach(obj => {
      objPrintf.push({
        LUOT_DIEU_TRI_ID: obj.LUOT_DIEU_TRI_ID,
        DV_ID: obj.DV_ID || obj.DICH_VU_ID,
        DICH_VU_ID_GOC: obj?.DICH_VU_GOC || obj?.DICH_VU_ID_GOC || null
      })
    });
    // Loại bỏ Obj có DICH_VU_ID_GOC trùng với Obj khác có trong mảng
    const filteredArr = objPrintf.filter(obj =>
      !objPrintf.some(otherObj =>
        obj !== otherObj && obj.DICH_VU_ID_GOC === otherObj.DV_ID
      )
    );
    // Gộp các obj có chung LUOT_DIEU_TRI_ID list_DV_ID_CON
    const groupedData = filteredArr.reduce((acc, current) => {
      const existing = acc.find(item => item.LUOT_DIEU_TRI_ID == current.LUOT_DIEU_TRI_ID);
      if (existing) {
        if (current?.DICH_VU_ID_GOC && current?.DICH_VU_ID_GOC?.length > 0) {
          existing.list_DV_ID_CON.push(current.DV_ID);

        } else {
          existing.list_DV_ID.push(current.DV_ID);

        }
      } else {
        if (current?.DICH_VU_ID_GOC && current?.DICH_VU_ID_GOC?.length > 0) {
          acc.push({
            LUOT_DIEU_TRI_ID: current.LUOT_DIEU_TRI_ID,
            list_DV_ID_CON: [current.DV_ID]
          });
        } else {
          acc.push({
            LUOT_DIEU_TRI_ID: current.LUOT_DIEU_TRI_ID,
            list_DV_ID: [current.DV_ID]
          });
        }

      }
      return acc;
    }, []);

    return groupedData
  }

  const in_dv_tao_moi = async (res_create, payload) => {
    const req = {
      LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
      partner_code: userProfile.partner_code,
      TYPE: "CLS",
    };
    const res = await common_post(apis.khambenh_lay_ds_phieu_cls, req);

    if (res.status === "OK" && res.result?.length) {
      let { result } = res;
      let { DANG_KY_KHAM, DANH_SACH_DICH_VU } = payload;
      const data = [];
      const arr_dv_id = [].concat(DANG_KY_KHAM, DANH_SACH_DICH_VU).map((i) => i.DICH_VU_ID);
      result.forEach((i) => {
        if (i.CHITIET_PHIEU.length) {
          i.CHITIET_PHIEU.forEach((i) => {
            const item = {
              DICH_VU_ID: i.DICH_VU_ID,
              LUOT_DIEU_TRI_KHAM_BENH: i?.LUOT_DIEU_TRI_KHAM_BENH,
              LUOT_DIEU_TRI_ID: i?.LUOT_DIEU_TRI_ID,
            };
            data.push(item);
          });
        }
      });
      const inPhieu = data.filter((i) => arr_dv_id.includes(i.DICH_VU_ID));
      const s = _.uniqBy(inPhieu, "DICH_VU_ID");
      const dataRequest2 = {
        data: format_data_in(s),
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      };
      const api2 = apis.lay_data_in_nhieu_phieu_dv_cls;
      await pdfRef.current.openModal(dataRequest2, api2);
    }

  }

  async function inNhieuDIchVu(dich_vu, payload, is_call_api) {

    if (is_call_api) {
      in_dv_tao_moi(dich_vu, payload)
      return
    }

    let selectedRowsAfter = selectedRows || []
    if (dich_vu && (JSON.stringify(dich_vu) != "{}")) {
      if (_.isArray(dich_vu)) {
        selectedRowsAfter = dich_vu
      } else {
        selectedRowsAfter = [dich_vu]
      }
    }

    const dataRequest2 = {
      data: format_data_in(selectedRowsAfter),
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    };
    const api2 = apis.lay_data_in_nhieu_phieu_dv_cls;
    await pdfRef.current.openModal(dataRequest2, api2);


    return
    let children = selectedRows.filter((e) => e.children === undefined);
    let data = [];
    let arr2 = [];

    if (selectedRows.length > 0) {
      data = children.map((item) => {
        return {
          LUOT_DIEU_TRI_ID: item.LUOT_DIEU_TRI_ID,
          list_DV_ID: [item.DICH_VU_ID],
        };
      });
    }
    data.forEach((item) => {
      if (!arr2[item.LUOT_DIEU_TRI_ID]) {
        // init
        arr2[item.LUOT_DIEU_TRI_ID] = item.list_DV_ID; //arr2=[{LUOT_DIEU_TRI_iD : list_dv_id ]
      } else {
        arr2[item.LUOT_DIEU_TRI_ID] = [...arr2[item.LUOT_DIEU_TRI_ID], ...item.list_DV_ID];
      }
    });

    data = Object.keys(arr2).map((key) => ({
      LUOT_DIEU_TRI_ID: key,
      list_DV_ID: arr2[key],
    }));

    const dataRequest = {
      data,
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    };
    // console.log("đây", dataReq);
    const api = apis.lay_data_in_nhieu_phieu_dv_cls;
    await pdfRef.current.openModal(dataRequest, api);
  }

  async function xoaNhieuDichVu() {
    let filerDsDichVu = selectedRows.filter((obj) => !checkDisabledDelete(obj));

    let KHAM_BENH = [];
    let CLS = [];

    filerDsDichVu.forEach((element) => {
      if (element.LOAI_PHONG == "KHAM_BENH") {
        KHAM_BENH.push({ LUOT_DIEU_TRI_ID: element.LUOT_DIEU_TRI_ID, PDT_ID: element.PDT_ID });
      } else {
        CLS.push({ PHIEU_IN_CLS_ID: element.PHIEU_IN_CLS_ID, CHITIET_PHIEU_ID: element.CHITIET_PHIEU_ID });
      }
    });
    const rq2 = {
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      KHAM_BENH,
      CLS,
    };
    try {
      const res = await common_post(apis.xoaNhieuDvKbCls, rq2);
      if (res && res.status === "OK") {
        apiLayDsDvTheoLuotKham();
        deleteRef.current.close();
        deleteRef.current.loading(false);
        notification.success({
          placement: "bottomLeft",
          message: i18n.t(languageKeys.noti_Xoa_thanh_cong),
        });
      } else {
        deleteRef.current.close();
        deleteRef.current.loading(false);
        notification.error({
          placement: "bottomLeft",
          message: i18n.t(languageKeys.noti_Xoa_that_bai),
        });
      }
    } catch (error) {
      deleteRef.current.close();
      deleteRef.current.loading(false);
      notification.error({
        placement: "bottomLeft",
        message: i18n.t(languageKeys.noti_Xoa_that_bai),
      });
    }
  }

  const handleCheckDisableXoaNhieuDv = useMemo(() => {
    if (configPermissions[permissionActionKey.XOA] && selectedRows?.length > 0) {
      let filerDsDichVu = selectedRows.some(record =>
        record.TRANG_THAI_THANH_TOAN === "DA_THANH_TOAN" ||
        record.TRANG_THAI_THANH_TOAN === 1 ||
        record.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN" ||
        record.TRANG_THAI_THUC_HIEN === 1 ||
        record.TRANG_THAI_KET_QUA === "DA_TRA_KET_QUA" ||
        record.TRANG_THAI_KET_QUA === 1 ||
        record.THANH_TOAN === 1 ||
        record.BARCODE_QUAN_LY
      );
      return filerDsDichVu

    } else {
      return true
    }
  }, [configPermissions, selectedRows])

  const handleSuccess = () => {
    apiLayDsDvTheoLuotKham();

    // TODO
  };

  // thêm mới chỉ định cls
  const handleThemCLS = () => {
    // if (!maCchnExist) {
    //   return notification.error({
    //     placement: "bottomLeft",
    //     message: i18n.t(languageKeys.bac_si_chua_co_ma_cchn),
    //   });
    // }

    ref.current.openModal();
  };

  const handleReload = () => {
    apiLayDsDvTheoLuotKham();
  };

  // * list bao gồm cả phiếu và các dịch vụ cls trong phiếu
  const isAllowedToDeleteListCls = (listCls = []) => {
    const isAllowed = listCls.some((item) => {
      const isParent = !!item.children;

      const isChildDoneSth = (child) => {
        return !(!!child.TRANG_THAI_THANH_TOAN || child.TRANG_THAI_KET_QUA === "DA_TRA_KET_QUA" || child.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN");
      };

      if ((isParent && item.children.some(isChildDoneSth)) || (!isParent && isChildDoneSth(item))) {
        return false;
      }

      return true;
    });

    return isAllowed;
  };

  // eventSource
  useEffect(() => {
    // initEventSource(phongChiDinh.ID);
    return () => {
      // _eventSource?.close();
    };
  }, [phongChiDinh]);

  const initEventSource = (room_id) => {
    let { BENH_VIEN_ID: hospital_id, partner_code } = userProfile;

    let urlEvent = apis.event_reload_kham_benh + "?BENH_VIEN_ID=" + hospital_id + "&&partner_code=" + partner_code + "&&PHONG_ID=" + room_id;

    HLog("CLS initEventSource urlEvent: " + urlEvent);

    _eventSource = new EventSource(urlEvent);
    _eventSource.onmessage = (e) => {
      HLog("CLS initEventSource onmessage: ", JSON.parse(e.data));
    };
    // _eventSource.addEventListener("connect", (event) => { console.log(event) })

    _eventSource.addEventListener("responseReloadCIS", (event) => {
      HLog("CLS initEventSource addEventListener update:", JSON.parse(event.data));
      if (event.data && event.data !== "") {
        let dataEvent = JSON.parse(event.data);

        if (dataEvent && !isEmptyObject(dataEvent)) {
          dispatch(updateReload(dataEvent));
        }
      }
    });
  };

  const editableItem = useMemo(() => {
    const editableParent = dsDvTheoLuotKham.filter((item) => item?.CHO_PHEP_CAP_NHAT);
    const res = [...editableParent];
    if (editableParent.length) {
      editableParent.forEach((item) => res.push(...item.CHITIET_PHIEU));
      return res;
    }
    return [];
  }, [dsDvTheoLuotKham]);

  const toggleSelectAll = () => {
    const length = editableItem.length;
    setSelectedRowKeys((prev) => (prev.length === length ? [] : editableItem.map((item) => item.key)));
    setSelectedRows((prev) => (prev.length === length ? [] : editableItem));
  };

  const headerCheckbox = (
    // <Checkbox
    //   checked={selectedRows.length && selectedRows.length === editableItem.length}
    //   indeterminate={selectedRows.length > 0 && selectedRows.length < editableItem.length}
    //   onClick={toggleSelectAll}
    //   disabled={!editableItem}
    // />\
    ""
  );

  const rowSelection = {
    checkStrictly: false,
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record?.is_combo,
        style: {
          display: record?.is_combo && "none",
          opacity: record.CHO_PHEP_CAP_NHAT ? 1 : 0.4,
        },
      };
    },
    columnTitle: headerCheckbox,
  };

  const deleteDisabled = !configPermissions[permissionActionKey.XOA] || (selectedRows.length > 0 ? false : true);
  const conditionPTTTRoom = (record) => {
    try {
      return (record.LOAI_PHONG === "THU_THUAT") && record?.LOAI_DV !== "COMBO_DV"
    } catch (error) {
      return false;
    }
  };


  const checkDisableShowPerformPTTT = (record, config) => {
    if (!!record?.LICH_KHAM_ID_DAT_TU_CSKCB) {
      return true;

    }
    if (config?.THANH_TOAN_TRUOC_KHAM && !record?.THANH_TOAN) {

      return true;
    }
    return false;
  };

  const handleChuyenTrangThaiDichVu = async (data, info, demo, TRANG_THAI = "DANG_THUC_HIEN") => {
    const payload = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_ID: data?.PHIEU_ID || data?.PHIEU_IN_CLS_ID,
      NHANSU_ID: userProfile.NHANSU_ID,
      PHONG_ID: data.PHONG_ID,
      LICH_KHAM_ID: data?.PHONG_ID,
      LUOT_DIEU_TRI_ID: data?.LUOT_DIEU_TRI_ID,
      CT_PHIEU_ID: data?.CT_PHIEU_ID || data?.CHITIET_PHIEU_ID,
      PDT_ID: data?.PDT_ID,
      TRANG_THAI: "DANG_THUC_HIEN",
      NGUOI_THUC_HIEN_ID: data.NGUOI_THUC_HIEN_ID,
    };

    // const checkDangTH = data.TRANG_THAI_TH === trang_thai_th.DANG_THUC_HIEN;
    // if (!checkDangTH) return;
    // formRefPTTT.current.onOpen(data);
    dispatch(setOpenFastPTTT(data))

    try {
      const response = await apiCapNhatTrangThaiDichVu(payload);
      if (response) {
        apiLayDsDvTheoLuotKham(true)
      }
    } catch (e) {}
  };

  const handleHuyTra = async (record) => {
    const payload = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      CHITIET_PHIEU_TRA_ID: [record.CHITIET_PHIEU_ID],
      PHIEU_ID: record?.PHIEU_ID || record?.PHIEU_IN_CLS_ID,
      NHANSU_ID: userProfile.NHANSU_ID,
      CT_PHIEU_ID: record.CT_PHIEU_ID || record?.CHITIET_PHIEU_ID,
      TTTT_ID: record?.TTTT_ID || null,
      PHONG_DIEU_TRI_ID: record?.PDT_ID,
      DICH_VU_ID: record?.DICH_VU_ID,
    };

    // return 
    const response = await apiHuyTraKetQua(payload);
    if (response && response?.status == "OK") {
      handleReload()
    }
  }

  const handleInTuongTrinh = async (data) => {

    if (userProfile?.config?.LOAI_FORM_THU_THUAT) {
      tuongTrinhRef.current.openModal(
        {
          PHIEU_ID: data?.PHIEU_IN_CLS_ID,
          LUOT_DIEU_TRI_ID: data?.LUOT_DIEU_TRI_ID,
          TRANG_THAI: "DA_TRA_KET_QUA",
          PHONG_ID: data?.PHONG_ID,
          PHONG_DIEU_TRI_ID: data?.PDT_ID,
          LICH_KHAM_ID: data?.LICH_KHAM_ID,
          DICH_VU_ID: data?.DICH_VU_ID,
        },
        apis.layDataPhieuTheoDoiTruyenDich
      )
    } else {
      const payload = {
        ...data,
        patientInfo: {
          ...patientInfo,
        }
      }
      await tuongTrinhRef.current.openModalWithData(payload, "tuongTrinhThuThuat");
    }


    // const payload = {
    //   ...data,
    //   patientInfo: {
    //     ...patientInfo,
    //   },
    // };
    // // const template = dataLocal?.template_pdf?.tuongTrinhThuThuat || "tuongTrinhThuThuat";
    // const template = "tuongTrinhThuThuat";
    // if (!template) {
    //   notification.error({
    //     message: "Không có mẫu in!",
    //     placement: "bottomLeft",
    //   });
    //   return;
    // }
    // await tuongTrinhRef.current.openModalWithData(payload, template);
  };

  return {
    handleChuyenTrangThaiDichVu,
    checkDisableShowPerformPTTT,
    conditionPTTTRoom,
    handleHuyTra,
    handleInTuongTrinh,
    apiLayDsDvTheoLuotKham,
    apiXoaDsDvCLS,
    apiXoaDsDvCLSChildren,
    onClickRow,
    conditionCdhaRoom,
    checkDisabledDetail,
    checkDisabledPrint,
    checkDisabledDelete,
    checkDisabledEdit,
    checkShowButtonDetail,
    onPrint,
    inNhieuDIchVu,
    handleSuccess,
    handleThemCLS,
    handleReload,
    isAllowedToDeleteListCls,
    initEventSource,
    toggleSelectAll,
    headerCheckbox,
    rowSelection,
    deleteDisabled,
    userIsAdminOrDoctor,
    isEdit,
    configPermissions,
    dsDvTheoLuotKham,
    setDsDvTheoLuotKham,
    expandedRowKeys,
    setExpandedRowKeys,
    selectedRowKeys,
    setSelectedRowKeys,
    loading,
    setLoading,
    selectedRows,
    setSelectedRows,
    selectedRecord,
    setSelectedRecord,
    xoaNhieuDichVu,
    handleCheckDisableXoaNhieuDv
  };
};
