import { Form, notification } from "antd";
import i18n, { languageKeys } from "../../../../../i18n";
import moment from "moment";

export const useAntForm = ({ phongChiDinh }) => {
  const [form] = Form.useForm();

  const submitForm = async () => {
    try {
      const values = await form.validateFields();
      // console.log("ANTD FORM - SUBMIT", values);
      return {
        ...values,
        NGAY_KINH_CUOI: values.NGAY_KINH_CUOI ? moment(values.NGAY_KINH_CUOI).format("YYYYMMDD") : undefined,
        DU_KIEN_SINH: values.DU_KIEN_SINH ? moment(values.DU_KIEN_SINH).format("YYYYMMDD") : undefined,
        DAU_OI_TIME: values.DAU_OI_TIME ? moment(values.DAU_OI_TIME).format("HH:mm") : undefined,
        LOAI_FORM_KHAM: JSON.parse(phongChiDinh?.FORM_KHAM_BENH)
      };

    } catch (error) {
      // console.log("ANTD FORM - ERROR FIELDS", error);

      if (error?.errorFields?.[0]?.name?.toString() === "BAC_SI_ID") {
        notification.error({
          message: i18n.t(languageKeys.vui_long_chon_bs_kham),
          placement: "bottomLeft"
        });
      }
      else if (error?.errorFields?.some(i => i.name?.toString() === "TAI_KHAM_LOAI" || i.name?.toString() === "TAI_KHAM_NGAY")) {
        notification.error({
          message: i18n.t(languageKeys.noti_Chua_chon_ngay_tai_kham),
          placement: "bottomLeft"
        });
      }
      else {
        error?.errorFields?.forEach((error, index) => {
          if (index === 0) form.scrollToField(error?.name[0]);
          notification.error({ message: error.errors.join(","), placement: "bottomLeft", });
        });
      }

      throw { message: Error("ANTD FORM - ERROR FIELDS"), error: error }
    }
  };

  return { form, submitForm };
};
