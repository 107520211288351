import React, { useState, useImperativeHandle } from "react";
import { Button, Divider, InputNumber, Modal, notification } from "antd";
import { Logo } from "../../../../../assets/svg";
import { Table } from "../../../../../components";
import i18n, { languageKeys } from "../../../../../i18n";
function ModalWarning({ ten_kho = "", handleDuyet = () => {} }, ref) {
  const [visible, setVisible] = useState(false);
  const [arrThuoc, setArrThuoc] = useState([]);

  useImperativeHandle(
    ref,
    () => ({
      openModal(arr) {
        setArrThuoc(arr.map((item, index) => ({ ...item, so_thu_tu: index + 1, TON_KHA_DUNG: Number(item.TON_KHA_DUNG) })));
        setVisible(true);
      },
      closeModal() {
        hanleClose();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function hanleClose() {
    setVisible(false);
    setArrThuoc([]);
  }

  const columns = [
    {
      title: i18n.t(languageKeys.stt),
      dataIndex: "so_thu_tu",
      key: "so_thu_tu",
    },
    {
      title: i18n.t(languageKeys.field_Ten_thuoc),
      dataIndex: "TEN_THUOC",
      key: "TEN_THUOC",
    },
    {
      title: i18n.t(languageKeys.field_ma_lo),
      dataIndex: "MA_LO",
      key: "MA_LO",
    },
    {
      title: i18n.t(languageKeys.duoc_ton_kha_dung),
      dataIndex: "TON_KHA_DUNG",
      key: "TON_KHA_DUNG",
    },
    {
      title: i18n.t(languageKeys.sl_chuyen_kho),
      dataIndex: "SO_LUONG",
      key: "SO_LUONG",
      render: (sl, record) => {
        return (
          <InputNumber
            value={sl}
            // max = {record.TON_KHA_DUNG}
            min={0}
            style={{ color: sl > record.TON_KHA_DUNG ? "red" : "#2c3782" }}
            onChange={(number) => {
              let newArr = arrThuoc.map((item) => {
                if (item.so_thu_tu === record.so_thu_tu) {
                  return { ...item, SO_LUONG: number };
                }
                return item;
              });
              setArrThuoc(newArr);
            }}
          />
        );
      },
    },
  ];
  return (
    <Modal
      footer={null}
      visible={visible}
      onCancel={hanleClose}
      title={null}
      //centered
      width={"545px"}
    >
      <div style={{ textAlign: "center" }}>
        <Logo />
        <Divider style={{ marginBottom: "10px" }} />
        <h3 style={{ color: "#F34946" }}>{i18n.t(languageKeys.title_thong_bao_chuyen_kho)}</h3>
        <p style={{ color: "#2C3782" }}>
          {i18n.t(languageKeys.prefix_so_luong_thuoc_trong_kho_hien_khong_du)}{" "}
          {<span style={{ color: "#6576FF", fontWeight: "bold" }}>{ten_kho}</span>}{" "}
          {i18n.t(languageKeys.suffix_so_luong_thuoc_trong_kho_hien_khong_du)}
        </p>
        <div style={{ backgroundColor: "#F5F7FB", padding: "20px", marginTop: "15px" }}>
          <Table dataSource={arrThuoc} columns={columns} />
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button type="primary" ghost style={{ width: "120px" }} onClick={hanleClose}>
            {i18n.t(languageKeys.common_Thoat)}
          </Button>
          <Button
            style={{ marginLeft: "10px", width: "120px" }}
            type="primary"
            onClick={() => {
              let check = arrThuoc.some((item) => item.SO_LUONG > item.TON_KHA_DUNG);
              if (check) {
                notification.warning({ message: i18n.t(languageKeys.noti_so_luong_duyet_khong_lon_hon_ton_kha_dung) });
              } else {
                handleDuyet(arrThuoc);
              }
            }}
          >
            {i18n.t(languageKeys.luu_va_duyet)}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
export default React.forwardRef(ModalWarning);
