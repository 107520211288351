import { Button, Collapse, Space, Spin, Row, Col, Input, Form, InputNumber } from "antd";
import { FormDrawer, ModalPDF, Select } from "components";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChiDinhDvGoi from "../ChiDinhDvGoi";
import ChiDinhVatTu from "../ChiDinhVatTu";
import { CopyIcon } from "assets/svg";
import i18n, { languageKeys } from "../../../../i18n";
import styles from "../vattu.module.less";
import { CaretRightOutlined, PrinterOutlined } from "@ant-design/icons";
import { calcTotal, locdau } from "helpers";
import { pick } from "lodash";
import { getChiTietGoiLieuTrinh, getDsGoiLieuTrinh, getLieuTrinhData, getPhacDoDieuTri, resetGoiVatTuSlice, savePhacDoLieuTrinh } from "ducks/slices/GoiDichVu/GoiVatTu.slice";
import TableDeXuat from "../ChiDinhDvGoi/TableDeXuat";
import { selectLoadingState } from "ducks/slices/loadingSlice";
import { useEventListener } from "hooks";
import moment from "moment";
import { apis } from "../../../../constants";

const LieuTrinhDeXuat = forwardRef(({ readOnly }, ref) => {

  const pdfRef = useRef()

  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state.auth.user);
  const { loading, dsDeXuat, dsGoiLieuTrinh } = useSelector(getLieuTrinhData)
  const loadingSelect = useSelector(selectLoadingState)

  const [form] = Form.useForm()

  const [open, setOpen] = useState(false);
  const [patientInfo, setPatientInfo] = useState();
  const [serviceList, setServiceList] = useState([]);
  const [supplyList, setSupplyList] = useState([{}]);
  const [ID, setID] = useState();
  const [idCopyBundle, setIdCopyBundle] = useState();

  useEffect(() => {
    return () => dispatch(resetGoiVatTuSlice())
  }, [])


  useImperativeHandle(ref, () => ({
    open: (patientInfo) => {
      dispatch(getPhacDoDieuTri({
        data: {
          BENH_NHAN_ID: patientInfo.BENH_NHAN_ID,
          PHAN_LOAI: 'PHAC_DO_DE_XUAT',
          // LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID
        },
        afterSuccess: (info) => {
          const plan = info?.find(i => i.BAC_SI_DE_XUAT_ID === (patientInfo.BAC_SI_ID || userProfile.NHANSU_ID))
          if (plan) {
            setID(plan.ID)
            setServiceList(plan.DANH_SACH_DICH_VU)
            setSupplyList([...plan.DANH_SACH_VAT_TU, {}])
            form.setFields([
              { name: "SO_BUOI_SU_DUNG", value: plan.SO_BUOI_SU_DUNG },
              { name: "GHI_CHU", value: plan.GHI_CHU }
            ])
          }
        }
      }))
      setPatientInfo(patientInfo)
      setOpen(true)
      !dsGoiLieuTrinh[0] && dispatch(getDsGoiLieuTrinh())
    }
  }));


  const handleClose = () => {
    setOpen(false)
    setPatientInfo()
    setServiceList([])
    setSupplyList([{}])
    setID()
    dispatch(resetGoiVatTuSlice())
    form.resetFields()
  }


  const handleCopy = () => {
    if (!idCopyBundle) return;
    dispatch(getChiTietGoiLieuTrinh({
      ID: idCopyBundle,
      afterSuccess: (service, supply) => {
        const SBSD = form.getFieldValue("SO_BUOI_SU_DUNG") ?? 1
        setServiceList(
          service?.[0]?.DS_DV
            ? service[0].DS_DV?.map(i => (
              {
                ...i,
                HO_TEN_NGUOI_TAO: `${userProfile?.HO} ${userProfile?.TEN}`,
                NGAY_TAO: moment().format('YYYYMMDD'),
                GIO_TAO: moment().format('H:mm'),
                HO_TEN_NGUOI_CAP_NHAT: null,
                NGAY_CAP_NHAT: null,
                GIO_CAP_NHAT: null,
                //
                SO_LUONG: i.SO_LUONG * SBSD,
                THANH_TIEN: i.THANH_TIEN * SBSD,
                THANH_TIEN_PHAN_BO: i.THANH_TIEN_PHAN_BO * SBSD,
                MIEN_GIAM: (
                  i.MIEN_GIAM_PHAN_TRAM
                    ? i.THANH_TIEN_PHAN_BO * SBSD / 100 * i.MIEN_GIAM_PHAN_TRAM
                    : i.MIEN_GIAM
                ) || 0,
                TONG_TIEN: (
                  (i.MIEN_GIAM_PHAN_TRAM
                    ? i.TONG_TIEN / 100 * i.MIEN_GIAM_PHAN_TRAM
                    : i.TONG_TIEN
                  ) || i.THANH_TIEN_PHAN_BO
                ) * SBSD,
              }
            )) : []
        )
        setSupplyList(supply ? [...supply, {}] : [{}])
      }
    }))
  }


  const handleSubmit = (print) => {
    dispatch(savePhacDoLieuTrinh({
      data: {
        ID,
        BAC_SI_DE_XUAT_ID: userProfile.NHANSU_ID,
        BENH_NHAN_ID: patientInfo.BENH_NHAN_ID,
        PHAN_LOAI: "PHAC_DO_DE_XUAT",
        TONG_THANH_TIEN: calcTotal(serviceList, "THANH_TIEN"),
        TONG_TIEN_PHAN_BO: calcTotal(serviceList, "THANH_TIEN_PHAN_BO"),
        THUE_VAT: 0,
        TONG_TIEN: calcTotal(serviceList, "THANH_TIEN_PHAN_BO"),
        ...form.getFieldsValue(),
        LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
        //
        DANH_SACH_DICH_VU: serviceList.map(item => ({
          ...pick(item, [
            "ID",
            "DICH_VU_ID",
            "DON_GIA_PHAN_BO",
            "PHONG_THUC_HIEN_ID",
            "SO_LUONG",
            "THANH_TIEN_PHAN_BO",
            "TY_LE",
            "DON_GIA",
            "THANH_TIEN",
            "BAC_SI_DE_XUAT_ID",
            "MIEN_GIAM",
            "MIEN_GIAM_PHAN_TRAM",
            "TONG_TIEN",
          ]),
          MA_DV: item.MA_DV || item.MA_DICHVU,
          TEN_DV: item.TEN_DV || item.TEN_DICHVU,
          DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
            ...dvkt,
            TEN_DV: item.TEN_DV || item.TEN_DICH_VU_KEM_THEO,
          }))
        })),
        //
        DANH_SACH_VAT_TU: supplyList.filter(i => i.SO_LUONG).map(item => pick(item, [
          "ID",
          "VAT_TU_TRONG_KHO_ID",
          "SO_LUONG",
          "DV_KEM_THEO",
          "DON_GIA",
          "SO_LUONG",
          "DUONG_DUNG",
          "HUONG_DAN_SU_DUNG",
          "GHI_CHU",
          "TOC_DO_TRUYEN",
        ])),
      },
      afterSuccess: () => {
        print && handlePrint()
        handleClose()
      },
      isNew: !ID
    }))
  }


  const handlePrint = () => {
    pdfRef.current.openModal(
      {
        // LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
        BENH_NHAN_ID: patientInfo?.BENH_NHAN_ID,
        PHAN_LOAI: "PHAC_DO_DE_XUAT",
        PHONG_ID: patientInfo?.PHONG_ID,
      },
      apis.layDataPhacDo
    )
  }


  const keydownEvent = (event) => {
    const preventDefault = () => {
      event.stopPropagation();
      event.preventDefault();
    }
    if (event.ctrlKey && event.key === "p" && ID) {
      preventDefault()
      handlePrint()
    }
    if (event.ctrlKey && event.key === "l") {
      preventDefault()
      handleSubmit(true)
    }
    if (event.ctrlKey && event.key === "s") {
      preventDefault()
      handleSubmit(false)
    }
  };
  useEventListener("keydown", keydownEvent, window.document, open);


  const FormFooter = (
    <Space style={{ float: 'right' }}>
      <Button type="primary" ghost loading={loading} onClick={handleClose}>
        {i18n.t(languageKeys.common_Thoat)} (ESC)
      </Button>
      {ID && (
        <Button type="primary" loading={loading} onClick={handlePrint}>
          {i18n.t(languageKeys.field_In_phieu)} (Ctrl + P)
        </Button>
      )}
      <Button type="primary" loading={loading} onClick={() => handleSubmit(true)}>
        {i18n.t(languageKeys.luu_va_in)} (Ctrl+L)
      </Button>
      <Button type="primary" loading={loading} onClick={() => handleSubmit(false)}>
        {i18n.t(languageKeys.common_Luu)} (Ctrl+S)
      </Button>
    </Space>
  )

  return (
    <FormDrawer
      className={styles["drawer"]}
      open={open}
      width={'calc(100vw - 60px)'}
      title={i18n.t(languageKeys.lieu_trinh_de_xuat)}
      onCancel={handleClose}
      back
      hiddenTopAction={true}
      footer={FormFooter}
    >
      <Spin spinning={loading}>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          defaultActiveKey={["CHI_DINH", "VAT_TU", "DE_XUAT"]}
        >

          <Collapse.Panel
            header={i18n.t(languageKeys.chi_dinh_dv_trong_goi)}
            key="CHI_DINH"
            extra={!ID && !readOnly && (
              <Space className={styles.extra} onClick={(e) => e.stopPropagation()}>
                <span>{i18n.t(languageKeys.goi_co_san)}:</span>
                <Select
                  dataSource={dsGoiLieuTrinh}
                  valueKey='ID'
                  titleKey='TEN_GOI_DV'
                  filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                  showSearch
                  loading={loadingSelect}
                  value={idCopyBundle}
                  onChange={setIdCopyBundle}
                />
                <Button
                  type="primary"
                  icon={<CopyIcon className={styles.copy} />}
                  onClick={handleCopy}
                >
                  {i18n.t(languageKeys.common_Ap_dung)}
                </Button>
              </Space>
            )}
          >
            <ChiDinhDvGoi
              dataSource={serviceList}
              setDataSource={setServiceList}
              selectedTab={"PHAC_DO_DE_XUAT"}
              readOnly={readOnly}
              formBundle={form}
            />

            <Form form={form} className={styles.usageDay}>
              <Row>
                <Col span={24}>
                  <Form.Item label={i18n.t(languageKeys.ghi_chu)} name={"GHI_CHU"}>
                    <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>

          </Collapse.Panel>

          <Collapse.Panel header={i18n.t(languageKeys.chi_dinh_thuoc_vat_tu_trong_goi)} key="VAT_TU">
            <ChiDinhVatTu
              supplyList={supplyList}
              setSupplyList={setSupplyList}
              readOnly={readOnly}
            />
          </Collapse.Panel>

          <Collapse.Panel header={i18n.t(languageKeys.goi_de_xuat_khac)?.toUpperCase()} key="DE_XUAT">
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className={styles.nestedCollapse}
            >
              {dsDeXuat
                .filter(i => i.BAC_SI_DE_XUAT_ID !== (patientInfo?.BAC_SI_ID || userProfile.NHANSU_ID))
                .map((item, index) => (
                  <Collapse.Panel
                    header={`${i18n.t(languageKeys.bac_si_de_xuat)}: ${item.BAC_SI_DE_XUAT}`}
                    key={index}
                    extra={
                      <Space>
                        <label>{i18n.t(languageKeys.so_buoi_sd)}</label>
                        <InputNumber value={item.SO_BUOI_SU_DUNG} readOnly style={{ background: '#fff' }} />
                      </Space>
                    }
                  >
                    <TableDeXuat
                      dataSource={item.DANH_SACH_DICH_VU}
                      selectedTab={"PHAC_DO_DE_XUAT"}
                      readOnly
                      hideHeader
                    />
                    <Row className={styles.usageDay}>
                      <Col span={24}>
                        <div className={styles.flex}>
                          <label>{i18n.t(languageKeys.ghi_chu)}</label>
                          <Input value={item.GHI_CHU} readOnly />
                        </div>
                      </Col>
                    </Row>
                    <h4>{i18n.t(languageKeys.chi_dinh_thuoc_vat_tu_trong_goi)}</h4>
                    <ChiDinhVatTu
                      supplyList={item.DANH_SACH_VAT_TU}
                      readOnly
                    />
                  </Collapse.Panel>
                ))}
            </Collapse>
          </Collapse.Panel>

        </Collapse>
      </Spin>

      <ModalPDF ref={pdfRef} />

    </FormDrawer>
  )
})

export default LieuTrinhDeXuat;