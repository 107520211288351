export const fieldNames = {
    ma_benh_nhan: "MA_BENH_NHAN",
    ten_benh_nhan: "TEN",
    ho_benh_nhan: "HO",
    cccd: "CCCD",
    so_dien_thoai: "SO_DIEN_THOAI",
    email: "EMAIL",
    gioi_tinh: "GIOI_TINH",
    ngay_sinh: "NGAY_SINH",
    tinh_thanh: "TEN_TINH_THANH",
    quan_huyen: "TEN_QUAN_HUYEN",
    xa_phuong: "TEN_PHUONG_XA",
    dia_chi_chi_tiet: "DIA_CHI_CHI_TIET",
    nghe_nghiep: "NGHE_NGHIEP",
    dan_toc: "DAN_TOC",
    quoc_tich: "QUOC_TICH",
    t_h_x: "MA_KHU_VUC",
    bac_si: "TEN_BAC_SI",
    ghi_chu: "GHI_CHU",
    KHO_ID: "KHO_ID",
    Thuoc_ID: "Thuoc_ID"
  
  };
  