import { CloseCircleFilled, ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Layout, notification, Row, Select, Tree, Spin, Empty, Collapse, Checkbox, Space } from "antd";
import _, { debounce } from "lodash";
import { TriangleBold } from "../../../../../assets/svg";
import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PrinterOutlined, SearchOutlined } from "@ant-design/icons";
import { ConfirmModal, FormDrawer, Select as SelectCustom } from "../../../../../components";
import { layDanhSachBenh } from "../../../apisKhamBenh/apisKhamBenh";
import { apis } from "../../../../../constants";
import { common_post, getFirstLetters, getFullName, hasDuplicateValues, HLog, isEmptyObject, locdau, rid } from "../../../../../helpers";
import i18n, { languageKeys } from "../../../../../i18n";
import style from "./chiDinhCLS.module.less";
import cn from "classnames";
import CustomTitleTable from "./components/TitleTable";
import RowTable, { RowTableExam } from "./components/RowTable";
import { useEventListener } from "../../../../../hooks";
import keys, { widthDrawerKeys } from "../../../../../constants/keys";
import { loadThongTinKhamNhanhCLS, loadThongTinThanhToan } from "../../../../../ducks/slices/khamBenhSlice";
import FooterTable from "./components/FooterTable";
import { doFetchCustomerTarget, doFetchPersonnel, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import ListDvKhamBenh from "pages/QuanLyTiepDon/components/ListDvKhamBenh/ListDvKhamBenh";
import { updateIndexedDbWithSaga } from "ducks/slices/dataSlice";
import { IndexedDbLoadingState } from "ducks/slices/loadingSlice";
import DanhsachMauDichvu from "pages/QuanLyTiepDon/components/DsMauDichvu";
import ModalCheckDuplicate from "./components/ModalCheckDuplicate";
import { keyFormKham } from "constants/data";
import { fieldKhamBenh } from "../../ThongTinKham/constantsTTK";

const { TreeNode } = Tree;

const keyCheckDuplicate = {
  duplicateItems: "duplicateItems",
  content: "content",
  print: "print",
}

const tabs = {
  KHAM_BENH: "KHAM_BENH",
  DICH_VU: "DICH_VU",
};

const ChiDinhCLS = forwardRef(
  (
    {
      patientInfo = {},
      onSuccess = () => {},
      onPrint = async () => {},
      xoaPhieu = () => {},
      trang_thai,
      onChuyenSidebarTabSangDangKham = () => {},
      phongChiDinh,
      disabledPrint = false,
      disabledDelete = false,
      isUseCurrent = false,
    },
    ref
  ) => {

    const deleteRef = useRef();
    const rebornRef = useRef();
    const changeCSKCBRef = useRef();
    const duplicateRef = useRef();
    const [form] = Form.useForm();
    const [formSample] = Form.useForm();
    const saveForm = Form.useWatch("LUU_MAU", formSample)

    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.auth.user);
    const { thongTinKhamBenh } = useSelector((state) => state.khamBenh);
    const indexedDb = useSelector((state) => state.data);
    const { customerTarget, personnel } = useSelector(getSharedData)
    const loadingSider = useSelector(IndexedDbLoadingState)

    const [visible, setVisible] = useState(false);
    const [subclinicalList, setSubclinicalList] = useState([]);
    const [currentSubclinicalList, setCurrentSubclinicalList] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [printChecked, setPrintChecked] = useState(true);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [search_string, setSearch_string] = useState("");
    const [cancel_list, setCancel_list] = useState([]);
    const [filterItem, setFilterItem] = useState({});
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [expandSearchList, setExpandSearchList] = useState([]);
    const [loadingDsDvCls, setLoadingDsDvCls] = useState(false);
    const [initData, setInitData] = useState(null);
    const [editable, setEditable] = useState(true);
    const [dsICD10Chinh, setDsICD10Chinh] = useState([]); // tìm kiếm danh sách bệnh chính
    const [dsICD10Phu, setDsICD10Phu] = useState([]); // tìm kiếm danh sách bệnh phụ
    const [dsBenhPhu, setDsBenhPhu] = useState([]);
    const [loadingDsBenh, setLoadingDsBenh] = useState(false);
    const [collapse, setCollapse] = useState(["0"]);
    const [createNew, setCreateNew] = useState(false);
    const [dataTtKhamNhanh, setDataTtKhamNhanh] = useState({});
    // const [dsCoSoKCB, setDsCoSoKCB] = useState([]);
    const [selectedCSKCB, setSelectedCSKCB] = useState(null);
    const [checkedShareInfo, setCheckedShareInfo] = useState(false);
    const [serviceTab, setServiceTab] = useState(tabs.KHAM_BENH);
    const [selectedExamServices, setSelectedExamServices] = useState([]);
    const [examService, setExamService] = useState([]);
    const [dsDichVuTheoLichKham, setDsDichVuTheoLichKham] = useState([]);
    const [duplicateList, setDuplicateList] = useState([]);
    const [ngChiDinh, setNgChiDinh] = useState(userProfile.NHANSU_ID);

    const fieldCLS = {
      benh_chinh: "benh_chinh",
      benh_phu: "benh_phu",
      CHAN_DOAN_LAM_SANG: "CHAN_DOAN_LAM_SANG",
      LY_DO_VAO_VIEN: "LY_DO_VAO_VIEN",
      GHI_CHU: "GHI_CHU",
      HAM_TREN_SO_BO: 'HAM_TREN_SO_BO',
      HAM_DUOI_SO_BO: "HAM_DUOI_SO_BO"
    };

    useImperativeHandle(ref, () => ({
      async openModal(data) {
        handleGetServiceListWithAppt();

        if (data?.LOAI_PHONG === "KHAM_BENH") {
          setServiceTab(tabs.KHAM_BENH);
        } else {
          setServiceTab(tabs.DICH_VU);
        }
        setVisible(true);
        if (!!data) {
          setCreateNew(false);
          if (
            data.TRANG_THAI_THANH_TOAN === "DA_THANH_TOAN" ||
            data.TRANG_THAI_THANH_TOAN === 1 ||
            data.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN" ||
            data.TRANG_THAI_THUC_HIEN === 1 ||
            data.TRANG_THAI_KET_QUA === "DA_TRA_KET_QUA" ||
            data.TRANG_THAI_KET_QUA === 1 ||
            // data.CHITIET_PHIEU_ID ||
            trang_thai === "KHAM_XONG"
          ) {
            setEditable(false);
          } else {
            setEditable(true);
          }
          setNgChiDinh(data.NGUOI_CHI_DINH_ID)
          setInitData(data);
          apiLayDsDv(data);
        } else {
          apiLayDsDv(patientInfo, true);
          setCreateNew(true);
        }
      },
    }));

    const getExamServiceList = async (PHONG_ID) => {
      try {
        let body = {
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          partner_code: userProfile.partner_code,
          PHAN_LOAI: "KHAM_BENH",
          page: 1,
          limit: 200,
        };

        let res = await common_post(apis.layDvClsCoPhong, body);
        if (res && res.status === "OK") {
          let { result } = res;
          setExamService(result.map((e) => ({ ...e, key: rid() })));
        }
      } catch (error) {}
    };

    const initDataPhieuKhamBenh = (data, isCreateNew = false) => {
      try {
        let dsDichVu = data.CHITIET_PHIEU;
        if (!dsDichVu) throw new Error("Không có danh sách dịch vụ");

        if ((!isCreateNew && !!data && data?.CHO_PHEP_CAP_NHAT) || !!isCreateNew) {
          getExamServiceList();
        }
        setSelectedExamServices(dsDichVu.map((e) => ({ ...e, DV_ID: e.DICH_VU_ID, key: rid() })));
      } catch (error) {
        console.log(error);
      }
    };

    const apiLayDsDv = async (data, isCreateNew = false) => {
      try {
        if (data.LOAI_PHONG === "KHAM_BENH") {
          initDataPhieuKhamBenh(data, isCreateNew);
        } else {
          setLoading(true);
          const req = {
            LUOT_DIEU_TRI_KHAM_BENH: patientInfo.LUOT_DIEU_TRI_ID || data.LUOT_DIEU_TRI_KHAM_BENH,
            LUOT_DIEU_TRI_ID: data.CHITIET_PHIEU ? data.LUOT_DIEU_TRI_ID : null,
            partner_code: isCreateNew ? selectedCSKCB?.BRAND || userProfile.partner_code : data?.BENH_VIEN_BRAND || userProfile.partner_code,
            BENH_VIEN_ID: isCreateNew ? selectedCSKCB?.BENH_VIEN_ID || userProfile.BENH_VIEN_ID : data?.BENH_VIEN_ID || userProfile.BENH_VIEN_ID,
            TYPE: "CLS",
          };
          let res = await common_post(apis.khambenh_lay_thong_tin_lich_kham_moi, req);
          res = {
            ...res,
            rs: data.CHITIET_PHIEU ? data.CHITIET_PHIEU : data.CHITIET_PHIEU_ID ? [data] : [],
          };

          if (res.status === "OK") {
            // if (res.rs.some((item) => item.THANH_TOAN === 0)) {
            //   setEditable(true);
            //   apiLayDsCLS();
            // }
            if (!isCreateNew && (!!data && data?.CHO_PHEP_CAP_NHAT) || !!isCreateNew) {
              // apiLayDsCLS(PHONG_ID);
              if (data.CHITIET_PHIEU) {
                getExamServiceList(data.CHITIET_PHIEU[0].PHONG_ID)
                apiLayDsCLS(data.CHITIET_PHIEU[0].PHONG_ID)
              } else {
                setExamService(indexedDb.kham_benh)
                console.log(`indexedDb.cls`, indexedDb.cls);
                setSubclinicalList(indexedDb.cls)
              }
            }

            let resultDv = res.rs.map((item) => ({
              ...item,
              checked: true,
              disabled: true,
              DV_ID: item.DICH_VU_ID,
              key: rid(),
              isOld: true,
              LIST_PHONG_ID: indexedDb?.cls?.flatMap(i => i.DICH_VU).find(dv => dv.DV_ID === item.DICH_VU_ID)?.LIST_PHONG_ID

            }));
            let data_kham = thongTinKhamBenh ? thongTinKhamBenh : res.THONG_TIN_KHAM_BENH;
            let resultField = Object.values(fieldCLS).map((name) => {
              if (name === "GHI_CHU") {
                return {
                  name,
                  value: data_kham.GHI_CHU || "",
                };
              }

              if (name === fieldCLS.CHAN_DOAN_LAM_SANG || name === fieldCLS.LY_DO_VAO_VIEN) {
                setDataTtKhamNhanh((curr) => ({ ...curr, [name]: data_kham[name] }));
                if (isUseCurrent) {
                  return {
                    name,
                    value: data_kham[name]
                  }
                }
              }

              return {
                name,
                value: data_kham[name],
              };
            });
            if (!!data_kham["data_benh"] && data_kham["data_benh"].length > 0) {
              let benh_chinh = undefined;
              let benh_phu = [];
              let data_benh = data_kham["data_benh"] || [];
              benh_chinh = data_benh.find((item) => item?.PHAN_LOAI === "CHINH");
              benh_phu = data_benh.filter((item) => item?.PHAN_LOAI === "PHU");

              if (benh_chinh && !isEmptyObject(benh_chinh)) {
                let data = {
                  ID_ICD: benh_chinh.ID_ICD,
                  MA_ICD: benh_chinh.MA_ICD,
                  TEN_ICD: benh_chinh.TEN_ICD,
                };
                setDsICD10Chinh([data]);
                benh_chinh = JSON.stringify(data);
              }

              if (benh_phu && benh_phu.length > 0) {
                benh_phu = benh_phu.map((item) =>
                  JSON.stringify({
                    ID_ICD: item.ID_ICD,
                    MA_ICD: item.MA_ICD,
                    TEN_ICD: item.TEN_ICD,
                  })
                );
                setDsBenhPhu(benh_phu);
              }
              if (!!benh_chinh) {
                resultField.push({
                  name: fieldCLS.benh_chinh,
                  value: benh_chinh,
                });
                setDataTtKhamNhanh((curr) => ({ ...curr, [fieldCLS.benh_chinh]: benh_chinh }));
              }

              if (benh_phu.length > 0) {
                resultField.push({
                  name: fieldCLS.benh_phu,
                  value: benh_phu,
                });
                setDataTtKhamNhanh((curr) => ({ ...curr, [fieldCLS.benh_phu]: benh_phu }));
              }
            }
            form.setFields(resultField);
            setCurrentSubclinicalList(resultDv);
          }
        }
      } catch (error) {
        HLog(error);
      }
      setLoading(false);
    };

    const timKiemICD10 = async (search_string = "", LOAI = "") => {
      setLoadingDsBenh(true);
      const response = await layDanhSachBenh(search_string);

      if (response) {
        const data = response.result.map((item) => ({
          ID_ICD: item.ID_ICD,
          MA_ICD: item.MA_ICD,
          TEN_ICD: item.TEN_ICD,
        }));

        if (LOAI === "CHINH") setDsICD10Chinh(data);
        if (LOAI === "PHU") setDsICD10Phu(data);
        else {
          setDsICD10Chinh(data);
          setDsICD10Phu(data);
        }
        setLoadingDsBenh(false);
      }
    };

    const handleThemMaBenhKemTheo = (value) => {
      const data = {
        ID_ICD: null,
        MA_ICD: null,
        TEN_ICD: value,
        PHAN_LOAI: "PHU",
      };
      const newList = [...dsBenhPhu, JSON.stringify(data)];
      form.setFields([{ name: fieldCLS.benh_phu, value: newList }]);
      setDsBenhPhu(newList);
    };

    const onChangeCheck = (e) => {
      if (e.target.checked) {
        setCurrentSubclinicalList((currentArr) =>
          currentArr.map((item) => (item.DV_ID === e.target.value.DV_ID ? { ...item, checked: true } : item))
        );
      } else {
        setCurrentSubclinicalList((currentArr) =>
          currentArr.map((item) => (item.DV_ID === e.target.value.DV_ID ? { ...item, checked: false } : item))
        );
      }
    };
    const onCheckAllChange = (e) => {
      if (e.target.checked) {
        setCurrentSubclinicalList((currentArr) =>
          currentArr.map((item) => {
            return { ...item, checked: true };
          })
        );
      } else {
        setCurrentSubclinicalList((currentArr) =>
          currentArr.map((item) => {
            return { ...item, checked: false };
          })
        );
      }
    };

    const handleClose = () => {
      setVisible(false);
      form.resetFields();
      setSubclinicalList([]);
      setCurrentSubclinicalList([]);
      setDsICD10Chinh([]);
      setDsICD10Phu([]);
      setDsBenhPhu([]);
      setLoading(false);
      // setPrintChecked(true);
      setInitData(null);
      setCancel_list([]);
      setSearch_string("");
      setFilterItem({});
      setExpandedKeys([]);
      setExpandSearchList([]);
      setEditable(true);
      setCollapse(["0"]);
      setDataTtKhamNhanh({});
      // setDsCoSoKCB([]);
      setSelectedCSKCB(null);
      setCheckedShareInfo(false);
      setSelectedExamServices([]);
      setDuplicateList([]);
      formSample.resetFields();
      setNgChiDinh(userProfile.NHANSU_ID)
    };

    const handleGetServiceListWithAppt = async () => {
      try {
        let body = {
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          LICH_KHAM_ID: patientInfo.LICH_KHAM_ID,
          IS_GOI: 1,
        }
        let res = await common_post(apis.layDanhSachDichVuTheoLichKham, body);
        if (res && res.status === 'OK') {

          const getFlat = (listDv = []) => {
            if (!Array.isArray(listDv)) {
              return [];
            }
            let rs = []
            for (const item of listDv) {
              rs.push({ ...item, DV_ID: item.DICH_VU_ID });
              if (item.DV_KEM_THEO?.[0]) {
                let childrens = item.DV_KEM_THEO.map(i => ({ ...i, DV_ID: i.DV_KEM_THEO_ID }));
                rs.push(...childrens);
              }
            }

            return rs
          }

          let { result } = res;

          result = getFlat(result);
          // setDsDichVuTheoLichKham(res.result);
          setDsDichVuTheoLichKham([...res.result.DANG_KY_KHAM, ...res.result.DANH_SACH_DICH_VU, ...res.result.DS_DV_GOI]);
        } else {
          throw new Error(res.message);
        }
      } catch (error) {
        HLog(error);
        notification.error({
          message: i18n.t(languageKeys.thao_tac_that_bai),
          placement: "bottomLeft"
        })
      }
    }

    const apiLayDsCLS = async (PHONG_ID) => {
      setLoadingDsDvCls(true);
      try {
        const req = {
          BENH_VIEN_ID: selectedCSKCB?.BENH_VIEN_ID || userProfile.BENH_VIEN_ID,
          partner_code: selectedCSKCB?.BRAND || userProfile.partner_code,
          from_BENH_VIEN_ID: userProfile?.BENH_VIEN_ID,
          from_partner_code: userProfile?.partner_code,
          PHONG_ID,
        };
        const res = await common_post(apis.khambenh_lay_ds_dv_cls, req);
        if (res.status === "OK") {
          setSubclinicalList(
            res.result.map((item, index) => ({
              ...item,
              index: index.toString(),
            }))
          );
        }
      } catch (error) {
        HLog(error);
      }
      setLoadingDsDvCls(false);
    };


    const checkDuplicateService = (listDv) => {

      listDv = listDv.map(i => ({ ...i, DV_ID: i.DICH_VU_ID }));
      try {
        const getFlat = () => {
          let rs = []
          for (const item of listDv) {
            rs.push(item);
            if (item.DV_KEM_THEO?.[0]) {
              let childrens = item.DV_KEM_THEO.map(i => ({ ...i, DV_ID: i.DV_KEM_THEO_ID }));
              rs.push(...childrens);
            }
          }

          return rs
        }
        let cp_list = getFlat();
        let check1 = hasDuplicateValues(cp_list, 'DV_ID');
        return check1
      } catch (error) {
        console.log(error);
        throw new Error('Lỗi code');
      }
    }

    const kiemTraLoi = async (print = false, checkDuplicate = true) => {
      setDuplicateList([]);
      // if (!maCchnExist) {
      //   return notification.error({
      //     placement: "bottomLeft",
      //     message: i18n.t(languageKeys.bac_si_chua_co_ma_cchn),
      //   });
      // }
      let arr_dv_all = []
      if (selectedExamServices && _.isArray(selectedExamServices) && selectedExamServices?.length > 0) {
        arr_dv_all = [...arr_dv_all, ...selectedExamServices]
      }
      if (currentSubclinicalList && _.isArray(currentSubclinicalList) && currentSubclinicalList?.length > 0) {
        arr_dv_all = [...arr_dv_all, ...currentSubclinicalList]
      }


      if (arr_dv_all.length === 0) {
        notification.error({
          message: i18n.t(languageKeys.noti_Chua_chon_dich_vu_kham_benh),
        });
        return;
      }

      let error = false;

      let benh_chinh = null;
      let formSubmit = {};

      formSubmit = form.getFieldsValue();

      if (Object.keys(formSubmit).length <= 1) {
        formSubmit = { ...dataTtKhamNhanh, ...formSubmit };
      }

      if (!!formSubmit.benh_chinh) {
        formSubmit.benh_chinh = JSON.parse(formSubmit.benh_chinh);
      }

      if (!!formSubmit.benh_phu) {
        formSubmit.benh_phu = formSubmit.benh_phu.map((item) => ({
          ...JSON.parse(item),
          PHAN_LOAI: "PHU",
        }));
      }

      if (!!formSubmit.benh_chinh) {
        benh_chinh = {
          ...formSubmit.benh_chinh,
          PHAN_LOAI: "CHINH",
        };
      }

      let edit = Object.fromEntries(Object.entries(formSubmit).filter(([key]) => key !== "GHI_CHU"));

      //Hủy các dịch vụ bị xóa
      if (cancel_list.length > 0) {
        await Promise.all(cancel_list.map((item) => HuyDvCLS(item)));
        await onSuccess();
        notification.success({
          message: i18n.t(languageKeys.cap_nhat_thanh_cong),
          placement: "bottomLeft",
        });
      }

      console.log("currentSubclinicalList------", currentSubclinicalList);
      const DANH_SACH_DICH_VU = currentSubclinicalList.map((item) => {
        // console.log("xxxxxxx", item);

        if (!item.PHONG_ID) error = true;

        return {
          DICH_VU_ID: item.DV_ID,
          GIA_DICH_VU: item.GIA_THU_PHI,
          GIA_GOC_DICH_VU: item.GIA_GOC_DICH_VU,
          UU_TIEN: item.UU_TIEN,
          SO_LUONG: item.SO_LUONG,
          GHI_CHU: item.GHI_CHU || "",
          PHONG_ID: item.PHONG_ID,
          PHAN_LOAI: item.PHAN_LOAI,
          KHOA_ID: item.KHOA_ID,
          PHONG_CHI_DINH: phongChiDinh.TEN_KHOA_PHONG || "",
          DM_DOI_TUONG_ID: item.DM_DOI_TUONG_ID,
          DV_XN_NOI_THUC_HIEN_ID: item?.NOI_THUC_HIEN_XN?.DV_XN_NOI_THUC_HIEN_ID || undefined,
          dv_con:
            item?.DV_KEM_THEO?.map((values) => {
              return ({
                DICH_VU_ID: values.DV_KEM_THEO_ID,
                DV_XN_NOI_THUC_HIEN_ID: values?.NOI_THUC_HIEN_XN?.DV_XN_NOI_THUC_HIEN_ID,
                DV_CHA_ID: item.DV_ID,
              })
            }) || undefined,
        };
      });

      const DANG_KY_KHAM = selectedExamServices.map((item) => {
        return {
          // ID: item?.old ? item?.ID : null,
          PHONG_ID: item.PHONG_ID,
          DICH_VU_ID: item.DV_ID,
          SO_LUONG: item.SO_LUONG,
          GIA_DICH_VU: Number(item.GIA_THU_PHI),
          GIA_GOC_DICH_VU: Number(item.GIA_GOC_DICH_VU),
          PHAN_LOAI: "KHAM_BENH",
          KHOA_ID: item.KHOA_ID,
          UU_TIEN: 0,
          // BAC_SI_ID: item?.BAC_SI?.NHANSU_ID,
          PHONG_CHI_DINH: "Phòng khám bệnh",
          PHONG_THUC_HIEN_ID: item.PHONG_ID,
          GHI_CHU: item.GHI_CHU || "",
          // MIEN_GIAM_TIEP_DON: item.MIEN_GIAM_TIEP_DON || 0,
          DM_DOI_TUONG_ID: item.DM_DOI_TUONG_ID
        };
      });

      // return;
      //  check duplicate
      if (checkDuplicate) {

        // console.log("dsDichVuTheoLichKham", dsDichVuTheoLichKham)
        // console.log("selectedExamServices", selectedExamServices)
        // console.log("currentSubclinicalList", currentSubclinicalList)
        let checkDouble = checkDuplicateService([...dsDichVuTheoLichKham, ...selectedExamServices, ...currentSubclinicalList]);

        if (checkDouble.hasDuplicated) {
          duplicateRef.current.open({
            [keyCheckDuplicate.content]: (
              <div>
                <div>{i18n.t(languageKeys.warn_khach_hang_co_dich_vu_da_duoc_chi_dinh)}:</div>
                <ul style={{ listStyle: "none", paddingLeft: 50, textAlign: "left" }} className="red-txt">
                  {checkDouble.duplicatedItems.filter(o => o.MA_NHOM_BHYT !== keys.COMBO_DV).map((i) => (
                    <li>{i?.TEN_DICHVU || i.TEN_DICH_VU_KEM_THEO}</li>
                  ))}
                </ul>
                <div>{i18n.t(languageKeys.warn_ban_co_muon_tiep_tuc_chi_dinh_dich_vu).toLowerCase()} ?</div>
              </div>
            ),
            [keyCheckDuplicate.print]: print,
            [keyCheckDuplicate.duplicateItems]: checkDouble.duplicatedItems
          });
          return;
        }
      }
      let formData = {
        THONG_TIN_KHAM_BENH: { ...edit, benh_chinh: benh_chinh },
        DANH_SACH_DICH_VU,
        DANG_KY_KHAM,
        GHI_CHU: formSubmit.GHI_CHU || "",
      };

      if (error) {
        setLoading(false);
        notification.error({
          message: i18n.t(languageKeys.noti_Chua_chon_phong_thuc_hien),
        });
        return;
      }

      // return console.log("dasdaasdasdasdadadad", formData, print);

      if (trang_thai === "KHAM_XONG") {
        rebornRef.current?.open({ formData, print });
      } else {
        apiLuuChiDinhCLS(formData, print);
      }
    };
    const apiLuuChiDinhCLS = async (formData = {}, print) => {

      const valuesForm = form.getFieldsValue();

      await formSample.validateFields()
      const sampleData = formSample.getFieldsValue();
      // if (phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongRHM)) {
      //   if (!(valuesForm.HAM_TREN_SO_BO || valuesForm.HAM_DUOI_SO_BO)) {
      //     notification.error({
      //       placement: "bottomLeft",
      //       message: i18n.t(languageKeys.error_maxillary_mandibular),
      //     });
      //     form.setFields([{ name: fieldKhamBenh.HAM_TREN_SO_BO, errors: ' ' }, { name: fieldKhamBenh.HAM_DUOI_SO_BO, errors: ' ' }])
      //     setCollapse(["1"]);
      //     return;
      //   }

      // } else {
      //   if (!dataTtKhamNhanh.CHAN_DOAN_LAM_SANG && !valuesForm.CHAN_DOAN_LAM_SANG) {
      //     notification.error({ message: i18n.t(languageKeys.thong_tin_kham_nhanh) + " - " + i18n.t(languageKeys.vui_long_nhap_chan_doan_so_bo) });
      //     form.setFields([
      //       {
      //         name: fieldKhamBenh.CHAN_DOAN_LAM_SANG,
      //         errors: [i18n.t(languageKeys.vui_long_nhap_chan_doan_so_bo)],
      //       },
      //     ]);
      //     setCollapse(["1"]);
      //     return;
      //   }

      // }
      try {
        setLoading(true);

        const dsDichvuKhamBenh = formData.DANG_KY_KHAM.map((ob) => {
          return {
            ...ob,
            BAC_SI_ID: ngChiDinh || userProfile.NHANSU_ID,
            SO_LUONG: ob.SO_LUONG || 1,
          };
        });

        const submitData = {
          ...formData,
          DANG_KY_KHAM: dsDichvuKhamBenh,
          DANH_SACH_DICH_VU: formData.DANH_SACH_DICH_VU.map((obj) => ({
            ...obj,
            BAC_SI_ID: ngChiDinh || userProfile.NHANSU_ID,
            SO_LUONG: obj.SO_LUONG || 1,
          })),
        };

        let req = {
          ...submitData,
          partner_code: selectedCSKCB?.BRAND || userProfile.partner_code,
          LUOT_DIEU_TRI_ID: !initData ? undefined : initData.LUOT_DIEU_TRI_ID,
          LUOT_DIEU_TRI_KHAM_BENH: patientInfo.LUOT_DIEU_TRI_ID,
          BENH_VIEN_ID: selectedCSKCB?.BENH_VIEN_ID || userProfile.BENH_VIEN_ID,
          BAC_SI_ID: ngChiDinh || userProfile.NHANSU_ID,
          LICH_KHAM_ID: patientInfo.LICH_KHAM_ID,
          PHIEU_ID: initData?.PHIEU_IN_CLS_ID,
          BENH_NHAN_ID: createNew ? patientInfo?.BENH_NHAN_ID : null,
          DAT_TU_CSKCB: createNew ? userProfile?.BENH_VIEN_ID : null,
          CHIA_SE_HO_SO: createNew ? checkedShareInfo : null,
          DAT_TU_CSKCB_BRAND: userProfile?.partner_code,
          TEN_BAC_SI: `${userProfile?.HO} ${userProfile?.TEN}`,
          IS_IN: print ? true : false
        };
        // if (phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongRHM)) {
        if (phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_RANG_HAM_MAT)) {
          let text = []
          if (valuesForm?.HAM_TREN_SO_BO && valuesForm?.HAM_TREN_SO_BO?.length > 0) {
            text.push(`${i18n.t(languageKeys.upper_jaw)}: ${valuesForm?.HAM_TREN_SO_BO}`)
          }
          if (valuesForm?.HAM_DUOI_SO_BO && valuesForm?.HAM_DUOI_SO_BO?.length > 0) {
            text.push(`${i18n.t(languageKeys.lower_jaw)}: ${valuesForm?.HAM_DUOI_SO_BO}`)
          }

          req.THONG_TIN_KHAM_BENH.CHAN_DOAN_LAM_SANG = text.join(', ');
        }

        const res = await common_post(apis.tiep_don_luu_dang_ky_can_lam_sang, req);

        if (res.status === "OK") {
          let dispatchData = {
            ...req.THONG_TIN_KHAM_BENH,
            //data_benh : req.THONG_TIN_KHAM_BENH.benh_phu.map(item => JSON.stringify(item))
          };
          if (!isUseCurrent) {
            dispatch(loadThongTinKhamNhanhCLS(dispatchData));
            dispatch(
              loadThongTinThanhToan({
                partner_code: userProfile.partner_code,
                BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
                LUOT_DIEU_TRI_ID: patientInfo.LUOT_DIEU_TRI_ID,
                LICH_KHAM_ID: patientInfo.LICH_KHAM_ID,
              })
            );


            if (trang_thai === "KHAM_XONG") {
              rebornRef.current?.close();
              onChuyenSidebarTabSangDangKham();
            }

            if (trang_thai === "CHO_KHAM") {
              onChuyenSidebarTabSangDangKham();
            }
          }

          if (print) await onPressPrint(res || [], req, true);

          if (sampleData.LUU_MAU) {
            const res = await common_post(apis.luuMauDichVuTheoLuotKham, {
              ARR_DV: [
                ...submitData.DANG_KY_KHAM?.map(i => ({ DV_ID: i.DICH_VU_ID, SO_LUONG: i.SO_LUONG })),
                ...submitData.DANH_SACH_DICH_VU?.map(i => ({ DV_ID: i.DICH_VU_ID, SO_LUONG: i.SO_LUONG })),
              ],
              TEN_MAU: sampleData.TEN_MAU,
              TIEP_TAN_ID: userProfile.NHANSU_ID,
            })
            if (res.status === 'OK') {
              notification.success({ message: i18n.t(languageKeys.msg_luu_mau_thanh_cong), placement: "bottomLeft" })
              formSample.resetFields()
            }
          }

          onSuccess();

          HLog("req", req);

          handleClose();

          return notification.success({
            placement: "bottomLeft",
            message: i18n.t(languageKeys.cap_nhat_thanh_cong),
          });
        } else {
          if (res && res.error_code === "999")
            notification.error({ message: i18n.t(languageKeys.warn_khach_hang_co_dich_vu_da_duoc_chi_dinh), placement: "bottomLeft" });
        }
      } catch (error) {
        HLog(error);
        setLoading(false);
      }
      setLoading(false);

      if (!isUseCurrent) {
        notification.error({
          placement: "bottomLeft",
          message: i18n.t(languageKeys.cap_nhat_that_bai),
        });
      }
    };

    //Hủy dịch vụ cận lâm sàng
    const HuyDvCLS = async (ID) => {
      try {
        const req = { ID, partner_code: userProfile.partner_code };
        const res = await common_post(apis.khambenh_huy_ds_dv_cls, req);
        if (res.status === "OK") {
        } else {
          notification.error({
            message: i18n.t(languageKeys.thao_tac_that_bai),
          });
        }
      } catch (error) {
        HLog(error);
        setLoading(false);
        notification.error({
          message: i18n.t(languageKeys.thao_tac_that_bai),
        });
      }
    };

    // const handleDelete = (record) => {
    //   if (!!record.THANH_TOAN) {
    //     notification.error({
    //       message: i18n.t(languageKeys.noti_Khong_the_huy_dich_vu_da_thanh_toan),
    //     });
    //   } else {
    //     if (!!initData) {
    //       if (record.disabled) {
    //         setCancel_list((currentArr) => [...currentArr, record.ID]);
    //       }
    //       setCurrentSubclinicalList((currentArray) => currentArray.filter((item) => item.DV_ID !== record.DV_ID));
    //     } else {
    //       setCurrentSubclinicalList((currentArray) => currentArray.filter((item) => item.DV_ID !== record.DV_ID));
    //     }
    //   }
    // };

    const handleDelete = (record, type = "cls") => {
      if (type === "kb") {
        if (!!record.THANH_TOAN) {
          notification.error({
            message: i18n.t(languageKeys.noti_Khong_the_huy_dich_vu_da_thanh_toan),
          });
        } else {
          if (initData) {
            setSelectedExamServices((currentArray) => currentArray.filter((item) => item.DV_ID !== record.DV_ID));
          } else {
            setSelectedExamServices((currentArray) => currentArray.filter((item) => item.DV_ID !== record.DV_ID));
          }
        }
      } else {
        if (!!record.THANH_TOAN) {
          notification.error({
            message: i18n.t(languageKeys.noti_Khong_the_huy_dich_vu_da_thanh_toan),
          });
        } else {
          if (!!initData) {
            if (record.disabled) {
              setCancel_list((currentArr) => [...currentArr, record.ID]);
            }
            setCurrentSubclinicalList((currentArray) => currentArray.filter((item) => item.DV_ID !== record.DV_ID));
          } else {
            setCurrentSubclinicalList((currentArray) => currentArray.filter((item) => item.DV_ID !== record.DV_ID));
          }
        }
      }
    };

    //khi nhấn in phiếu
    async function onPressPrint(data = initData, payload, is_call_api) {
      await onPrint(data, payload, is_call_api);
      handleClose();
    }

    const keydownEvent = (event) => {
      const stopDefaultAction = () => {
        event.preventDefault();
        event.stopPropagation();
      };
      if (event.ctrlKey && event.key === "s") {
        event.preventDefault();
        event.stopPropagation();
        if ((!!initData && !initData?.CHO_PHEP_CAP_NHAT) || loading) return;
        kiemTraLoi(false);
      } else if (event.ctrlKey && event.key === "l") {
        stopDefaultAction();
        if ((!!initData && !initData?.CHO_PHEP_CAP_NHAT) || loading) return;
        kiemTraLoi(true);
      } else if (event.ctrlKey && event.key === "i") {
        stopDefaultAction();
        if (createNew) return;
        onPressPrint(initData);
      }
    };
    useEventListener("keydown", keydownEvent, window.document, visible);

    //function chọn filter loại dịch vụ
    function onChangeFilter(event) {
      if (!!event) {
        setFilterItem(JSON.parse(event));
      } else {
        setFilterItem({});
      }
    }

    function onTreeSelect(data) {

      // const newObj = {
      //   ...dichvu,
      //   NOI_THUC_HIEN_XN: record,
      // };

      // let index;
      // let childIndex;

      // if (dichvu.DV_KEM_THEO_ID) {
      //   index = dsDvClsDaChon.findIndex((item) => item.DV_ID === dichvu.DV_ID || item.DICH_VU_ID === dichvu.DV_ID);

      //   childIndex = dsDvClsDaChon[index]?.children?.findIndex((item) => item.key === dichvu.key);

      // } else {
      //   index = dsDvClsDaChon.findIndex((item) => item.key === dichvu.key);
      // }

      // setDsDvClsDaChon((currentArr) => {
      //   let newArr = [...currentArr];
      //   if (dichvu.DV_KEM_THEO_ID) {
      //       let updatedChildren = [...newArr[index].children];
      //       updatedChildren[childIndex] = newObj; 
      //       newArr[index] = { ...newArr[index], children: updatedChildren };
      //     } else {
      //       newArr[index] = newObj; 
      //     }
      //   return newArr;
      // });
      const GIA_DT = data.value?.LIST_GIA_THEO_DICH_VU?.[0] || {}
      let result = {
        ...data.value,
        checked: true,
        GIA_THU_PHI: GIA_DT.GIA_DICH_VU,
        GIA_GOC_DICH_VU: GIA_DT.GIA_DICH_VU,
        DM_DOI_TUONG_ID: GIA_DT.DM_DOI_TUONG_ID,
      };

      if (data.disabled) {
        return;
      }
      if (data.selectable) {
        if (data.checked) {
          if (data.value.LOAI_PHONG === "KHAM_BENH") {
            setSelectedExamServices((currentArray) => currentArray.filter((item) => item.DV_ID !== result.DV_ID));
          } else {
            setCurrentSubclinicalList((currentArray) => currentArray.filter((item) => item.DV_ID !== result.DV_ID));
          }
        } else {
          if (data.value.LOAI_PHONG === "KHAM_BENH") {
            setSelectedExamServices((currentArr) => [...currentArr, result]);
          } else {
            if (data.value.MA_NHOM_BHYT === "COMBO_DV") {
              const children = (item) => {
                return item.DV_KEM_THEO?.map((item) => {
                  let newItem = {
                    ...item
                  };

                  if (item.LIST_NOI_THUC_HIEN_XN && item.LIST_NOI_THUC_HIEN_XN.length > 0) {
                    const element = item?.LIST_NOI_THUC_HIEN_XN.find((obj) => obj?.IS_DEFAULT == 1);
                    newItem.NOI_THUC_HIEN_XN = element;
                  }

                  return newItem;
                });
              };

              const obj = {
                ...result,
                DV_KEM_THEO: children(result),
              };

              setCurrentSubclinicalList((currentArr) => [...currentArr, obj]);
            } else {
              const obj = {
                ...result
              };

              if (obj?.LIST_NOI_THUC_HIEN_XN && obj.LIST_NOI_THUC_HIEN_XN.length > 0) {
                const element = obj?.LIST_NOI_THUC_HIEN_XN.find((obj) => obj?.IS_DEFAULT == 1);
                obj.NOI_THUC_HIEN_XN = element;
              }

              setCurrentSubclinicalList((currentArr) => [...currentArr, obj]);
            }

            // setCurrentSubclinicalList((currentArr) =>{
            //   console.log("result",result);
            //   const obj = {
            //     ...result
            //   };

            //   if (obj?.LIST_NOI_THUC_HIEN_XN && obj.LIST_NOI_THUC_HIEN_XN.length > 0) {
            //     const element = obj?.LIST_NOI_THUC_HIEN_XN.find((obj) => obj?.IS_DEFAULT == 1);
            //     obj.NOI_THUC_HIEN_XN = element;
            //   }
            //   return [...currentArr, obj]
            // } );
          }
        }
      } else {
        if (data.expanded) {
          setExpandedKeys((currentArray) => currentArray.filter((item) => item !== data.key));
        } else {
          setExpandedKeys((currentArr) => [...currentArr, data.key]);
        }
      }
    }

    function onTreeKBSelect(data) {
      // return
      try {
        const GIA_DT = data.value?.LIST_GIA_THEO_DICH_VU?.[0] || {}
        let result = {
          ...data.value,
          checked: true,
          GIA_THU_PHI: GIA_DT.GIA_DICH_VU,
          GIA_GOC_DICH_VU: GIA_DT.GIA_DICH_VU,
          DM_DOI_TUONG_ID: GIA_DT.DM_DOI_TUONG_ID,
        };
        if (data.disabled) {
          return;
        }
        if (data.selectable) {
          if (data.checked) {
            setSelectedExamServices((currentArray) => currentArray.filter((item) => item.DV_ID !== result.DV_ID));
          } else {
            setSelectedExamServices((currentArr) => [...currentArr, result]);
          }
        } else {
          if (data.expanded) {
            setExpandedKeys((currentArray) => currentArray.filter((item) => item !== data.key));
          } else {
            setExpandedKeys((currentArr) => [...currentArr, data.key]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    function onExpandItem(data) {
      if (data.expanded) {
        setExpandedKeys((currentArr) => [...currentArr, data.node.key]);
      } else {
        setExpandedKeys((currentArray) => currentArray.filter((item) => item !== data.node.key));
        setExpandSearchList((currentArray) => currentArray.filter((item) => item !== data.node.key));
      }
    }

    //nhập giá trị search
    function onChangeSearch(value) {
      setSearch_string(value);
      onSearch(value, subclinicalList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSearch = useCallback(
      debounce((value, subclinicalList) => {
        if (!!value) {
          let searchExpand = subclinicalList
            .filter(
              (item) =>
                !!value &&
                item?.DICH_VU?.some(
                  (sub_item) =>
                    sub_item?.TEN_DICHVU?.toLocaleLowerCase()?.includes(value?.toLocaleLowerCase()) ||
                    getFirstLetters(sub_item?.TEN_DICHVU)?.toLocaleLowerCase()?.includes(value?.toLocaleLowerCase())
                )
            )
            .map((item) => item.index);

          setExpandSearchList(searchExpand);
        } else {
          setExpandSearchList([]);
        }
      }, 1000),
      []
    );

    // khi chọn phòng
    const onChangePhong = (phong, dv_id, type = "cls") => {
      let callback = (item) => {
        if (item.DV_ID === dv_id) {
          return {
            ...item,
            TEN_KHOA_PHONG: phong?.TEN_KHOA_PHONG,
            PHONG_ID: phong?.ID,
            // PHAN_LOAI: phong?.LOAI_PHONG,
            PHAN_LOAI: phong?.LOAI_PHONG == "LAY_MAU" ? "XET_NGHIEM" : phong?.LOAI_PHONG,
            KHOA_ID: phong?.MA_KHOA,
          };
        } else {
          return item;
        }
      };

      if (type === "kb") {
        setSelectedExamServices((currentArray) => currentArray.map(callback));
      } else {
        setCurrentSubclinicalList((currentArray) => currentArray.map(callback));
      }
    };

    const onChangeNoiThucHien = (noiThucHien, dichvu) => {

      const newObj = {
        ...dichvu,
        NOI_THUC_HIEN_XN: noiThucHien,
      };

      let index;
      let childIndex;


      if (dichvu.DV_KEM_THEO_ID) {

        index = currentSubclinicalList.findIndex((item) => item.DV_ID === dichvu.DV_ID || item.DICH_VU_ID === dichvu.DV_ID);

        childIndex = currentSubclinicalList[index]?.DV_KEM_THEO?.findIndex((item) => item.DV_KEM_THEO_ID === dichvu.DV_KEM_THEO_ID);

      } else {
        index = currentSubclinicalList.findIndex((item) => item.DICH_VU_ID === dichvu.DICH_VU_ID);
      }

      setCurrentSubclinicalList((currentArr) => {
        let newArr = [...currentArr];
        if (dichvu.DV_KEM_THEO_ID) {
          let updatedChildren = [...newArr[index].DV_KEM_THEO];
          updatedChildren[childIndex] = newObj;
          newArr[index] = { ...newArr[index], DV_KEM_THEO: updatedChildren };
        } else {
          newArr[index] = newObj;
        }
        return newArr;
      });
    };

    // khi nhập ghi chú
    const onChangeGhiChu = (ghi_chu, dv_id, type = "cls") => {
      let arr_change = type === "kb" ? selectedExamServices : currentSubclinicalList;
      let newArr = arr_change.map((item) => {
        if (item.DV_ID === dv_id) {
          return {
            ...item,
            GHI_CHU: ghi_chu || "",
          };
        } else {
          return item;
        }
      });
      if (type === "kb") setSelectedExamServices(newArr);
      else setCurrentSubclinicalList(newArr);
    };

    const onChangeSoLuong = (so_luong, dv_id, type = "cls") => {
      let arr_change = type === "kb" ? selectedExamServices : currentSubclinicalList;
      let newArr = arr_change.map((item) => {
        if (item.DV_ID === dv_id) {
          return {
            ...item,
            SO_LUONG: !!so_luong && Number.isInteger(so_luong) ? so_luong : 1,
          };
        } else {
          return item;
        }
      });

      if (type === "kb") setSelectedExamServices(newArr);
      else setCurrentSubclinicalList(newArr);
    };

    //
    const onChangeCollapse = (key) => {
      setCollapse(key);
    };

    // const getCSKCB = async () => {
    //   try {
    //     setLoading(true);
    //     const res = await common_post(apis.layDsCSKCB, {
    //       BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    //     });
    //     setDsCoSoKCB(res?.result);
    //     setSelectedCSKCB(res?.result[0]);
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    // const handleChangeCSKCB = (id) => {
    //   if (currentSubclinicalList.length) {
    //     changeCSKCBRef.current.open(id);
    //   } else {
    //     setSelectedCSKCB(dsCoSoKCB.find((item) => item.MA_CSKCB === id));
    //     setFilterItem({});
    //     setCurrentSubclinicalList([]);
    //     setExpandedKeys([]);
    //     // setSelectedLoaiDvCls({});
    //     // setDsDvClsDaChon([]);
    //     // setDsKhamBenhDaChon([]);
    //   }
    // };

    useEffect(() => {
      !customerTarget.length && dispatch(doFetchCustomerTarget())
      !personnel.length && dispatch(doFetchPersonnel())
    }, [])

    // useEffect(() => {
    //   if (
    //     visible &&
    //     createNew &&
    //     (!!userProfile?.benh_vien?.LOAI_HINH_CSKCB ||
    //       userProfile?.benh_vien?.MO_HINH_CSKCB === "CHUOI" ||
    //       userProfile?.benh_vien?.MO_HINH_CSKCB === "HUB")
    //   )
    //     getCSKCB();
    // }, [visible, createNew]);

    useEffect(() => {
      if (selectedCSKCB) apiLayDsDv(patientInfo, true);
    }, [selectedCSKCB, patientInfo]);

    const checkDuplicateIds = useCallback((id) => {
      try {
        return duplicateList.filter(o => o.MA_NHOM_BHYT !== keys.COMBO_DV).map(i => i.DV_ID || i.DICH_VU_ID || i.DV_KEM_THEO_ID).includes(id);
      } catch (error) {
        return false;
      }
    }, [duplicateList])
    const genHighlightClass = (id) => {
      if (checkDuplicateIds(id)) return style['row-hightlight']
      else return '';
    }

    const SubClinicalServiceList = () => {
      return (
        <Spin spinning={loadingSider}>
          <Tree
            checkable
            onClick={(event, e) => onTreeSelect(e)}
            onCheck={(key, event) => onTreeSelect(event.node)}
            onExpand={(key, event) => onExpandItem(event)}
            checkedKeys={
              subclinicalList
                .flatMap(i => i.DICH_VU)
                .filter(cls => currentSubclinicalList.some(i => i.DV_ID === cls.DV_ID) || selectedExamServices.some(i => i.DV_ID === cls.DV_ID))
                .map((item) => item.DV_ID)
            }
            expandedKeys={[...expandedKeys, filterItem.index, ...expandSearchList]}
          >
            {subclinicalList
              .filter(
                (item) =>
                  (item.TEN_LOAI_DICH_VU === filterItem.TEN_LOAI_DICH_VU || !filterItem.TEN_LOAI_DICH_VU) &&
                  (item?.TEN_LOAI_DICH_VU?.toLocaleLowerCase()?.includes(search_string?.toLocaleLowerCase()) ||
                    item?.DICH_VU?.some(
                      (sub_item) =>
                        sub_item?.TEN_DICHVU?.toLocaleLowerCase()?.includes(search_string?.toLocaleLowerCase()) ||
                        getFirstLetters(sub_item?.TEN_DICHVU)?.toLocaleLowerCase()?.includes(search_string?.toLocaleLowerCase())
                    ))
              )
              .map((item, index) => (
                <TreeNode
                  key={item.index}
                  value={item}
                  title={item.TEN_LOAI_DICH_VU}
                  selectable={false}
                  checkable={false}
                  switcherIcon={null}
                  className={cn(style["block-container"])}
                >
                  {item.DICH_VU.filter(
                    (item) =>
                      item?.TEN_DICHVU?.toLocaleLowerCase()?.includes(search_string?.toLocaleLowerCase()) ||
                      (!!item?.DICH_VU_CHA && item?.DICH_VU_CHA?.toLocaleLowerCase()?.includes(search_string?.toLocaleLowerCase())) ||
                      getFirstLetters(item?.TEN_DICHVU)?.toLocaleLowerCase()?.includes(search_string?.toLocaleLowerCase()) ||
                      item?.TEN_LOAI_DICH_VU?.toLocaleLowerCase()?.includes(search_string?.toLocaleLowerCase()) ||
                      getFirstLetters(item?.TEN_LOAI_DICH_VU)?.toLocaleLowerCase()?.includes(search_string?.toLocaleLowerCase())
                  ).map((sub_item) => (
                    <TreeNode
                      key={sub_item.DV_ID}
                      value={sub_item}
                      title={sub_item.TEN_DICHVU}
                      selectable={true}
                      checkable={true}
                      disableCheckbox={currentSubclinicalList.some((item) => item.DV_ID === sub_item.DV_ID && item.disabled)}
                      disabled={currentSubclinicalList.some((item) => item.DV_ID === sub_item.DV_ID && item.disabled)}
                      className={cn(style["subclinical-item"])}
                    ></TreeNode>
                  ))}
                </TreeNode>
              ))}
          </Tree>
        </Spin>
      );
    };

    const MedicalExamServiceList = () => {
      return (
        <ListDvKhamBenh dsDvKhamBenh={examService} onTreeKBSelect={onTreeKBSelect} dsDvDaChon={selectedExamServices} searchVal={search_string} loading={loadingSider} />
      );
    };

    return (
      <FormDrawer
        visible={visible}
        width={widthDrawerKeys.large}
        onCancel={handleClose}
        // onClickBack={handleClose}
        // showPlusIcon={createNew}
        title={createNew ? i18n.t(languageKeys.tao_phieu_chi_dinh_can_lam_sang) : i18n.t(languageKeys.chi_tiet_phieu_chi_dinh_can_lam_sang)}
        hiddenTopAction
        footer={
          <Row justify="space-between" align="middle">
            <Col span={24} className={cn(style["action-button"])}>
              {createNew ? (
                <>
                  <Checkbox style={{ flexShrink: 0 }} checked={checkedShareInfo} onChange={(e) => setCheckedShareInfo(e.target.checked)}>
                    <span className="navy-txt">{i18n.t(languageKeys.tiepdon_chia_se_thong_tin)}</span>
                  </Checkbox>
                  <Form form={formSample} className={style.form}>
                    <Form.Item name="LUU_MAU" valuePropName="checked">
                      <Checkbox style={{ flexShrink: 0 }}>
                        <span className="navy-txt">{i18n.t(languageKeys.luu_mau_chi_dinh_dich_vu)}</span>
                      </Checkbox>
                    </Form.Item>
                    {saveForm && (
                      <Form.Item name="TEN_MAU" rules={[{ required: true, message: "" }]}>
                        <Input placeholder={i18n.t(languageKeys.common_Nhap)} style={{ maxWidth: 200 }} />
                      </Form.Item>
                    )}
                  </Form>
                </>
              ) : (
                <div></div>
              )}
              <Row gutter={10} justify="end" style={{ width: "100%" }}>
                <Col>
                  <Button type="primary" ghost onClick={handleClose}>
                    {i18n.t(languageKeys.common_Thoat)} (ESC)
                  </Button>
                </Col>
                {!!initData && (
                  <Col>
                    <Button onClick={() => onPressPrint(initData)} type="primary" icon={<PrinterOutlined />}>
                      {i18n.t(languageKeys.field_In_phieu)} (Ctrl + I)
                    </Button>
                  </Col>
                )}
                {editable && (
                  <Col>
                    <Button
                      disabled={(!!initData && !initData?.CHO_PHEP_CAP_NHAT)}
                      type="primary"
                      onClick={() => kiemTraLoi(true)}
                      loading={loading}
                    >
                      {i18n.t(languageKeys.luu_va_in)} (Ctrl + L)
                    </Button>
                  </Col>
                )}
                {editable && (
                  <Col>
                    <Button disabled={!!initData && !initData?.CHO_PHEP_CAP_NHAT} type="primary" onClick={() => kiemTraLoi(false)} loading={loading}>
                      {i18n.t(languageKeys.common_Luu)} (Ctrl + S)
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        }
      >
        <Form form={form} layout="vertical">
          <Layout className={cn(style["layout-container"])}>
            {editable && (
              <Layout.Sider className={cn(style["sider"])} width="300px">
                <Spin spinning={loadingDsDvCls}>
                  <div className={cn(style["input-container"])}>
                    {/* {createNew &&
                      (!!userProfile?.benh_vien?.LOAI_HINH_CSKCB ||
                        userProfile?.benh_vien?.MO_HINH_CSKCB === "CHUOI" ||
                        userProfile?.benh_vien?.MO_HINH_CSKCB === "HUB") ? (
                      <Select
                        style={{ width: "100%", marginBottom: "10px" }}
                        className={style["select"]}
                        options={dsCoSoKCB?.map((data, index) => ({
                          label: data?.TEN_CSKCB,
                          value: data?.MA_CSKCB,
                        }))}
                        value={selectedCSKCB ? selectedCSKCB?.MA_CSKCB : null}
                        onChange={handleChangeCSKCB}
                        placeholder={i18n.t(languageKeys.common_Chon)}
                      />
                    ) : (
                      <></>
                    )} */}
                    <Row gutter={5} justify="center" style={{ marginBottom: "10px" }}>
                      <Col span={12}>
                        <Button
                          // disabled={initData && initData?.LOAI_PHONG !== "KHAM_BENH"}
                          className={cn(style["btn"], serviceTab === tabs.KHAM_BENH && style["btn-active"])}
                          type={serviceTab === tabs.KHAM_BENH && "primary"}
                          onClick={() => setServiceTab(tabs.KHAM_BENH)}
                          block
                        >
                          {i18n.t(languageKeys.kham_benh_short)}
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button
                          // disabled={initData?.LOAI_PHONG === "KHAM_BENH"}
                          className={cn(style["btn"], serviceTab === tabs.DICH_VU && style["btn-active"])}
                          type={serviceTab === tabs.DICH_VU && "primary"}
                          onClick={() => setServiceTab(tabs.DICH_VU)}
                          block
                        >
                          {i18n.t(languageKeys.dich_vu)}
                        </Button>
                      </Col>
                    </Row>
                    <Select
                      showSearch
                      allowClear
                      className={cn(style["select-input"])}
                      value={!!filterItem.TEN_LOAI_DICH_VU ? JSON.stringify(filterItem) : undefined}
                      onChange={onChangeFilter}
                      placeholder={i18n.t(languageKeys.common_Tim_kiem_theo_loai)}
                      clearIcon={<CloseCircleFilled style={{ fontSize: "15px" }} />}
                    >
                      {subclinicalList.map((item) => (
                        <Select.Option key={item.id} value={JSON.stringify(item)}>
                          {item.TEN_LOAI_DICH_VU}
                        </Select.Option>
                      ))}
                    </Select>

                    <Space>
                      <Input
                        className={cn(style["search-input"])}
                        allowClear
                        value={search_string}
                        placeholder={i18n.t(languageKeys.common_Tim_kiem)}
                        prefix={<SearchOutlined style={{ color: "#6576ff" }} />}
                        onChange={({ target }) => {
                          onChangeSearch(target.value);
                        }}
                        style={{ width: 240 }}
                      />
                      <Button icon={<ReloadOutlined />} onClick={() => dispatch(updateIndexedDbWithSaga())} type="primary" loading={loadingSider} />
                    </Space>

                  </div>

                  <div className={style["service-container"]}>
                    {serviceTab === tabs.KHAM_BENH ? <MedicalExamServiceList /> : <SubClinicalServiceList />}
                  </div>

                </Spin>
              </Layout.Sider>
            )}

            <Layout className={cn(style["table-container"])}>
              <div className={cn(style["form-input-ctn"])}>
                <div className={cn(style["form-input"])}>
                  <Collapse
                    activeKey={collapse}
                    onChange={onChangeCollapse}
                    expandIcon={({ isActive }) => (isActive ? <TriangleBold /> : <TriangleBold />)}
                  >
                    <Collapse.Panel
                      header={i18n.t(languageKeys.thong_tin_kham_nhanh)}
                      key="1"
                      style={{ fontSize: 16, fontWeight: 600 }}
                      className={style["collapse-panel"]}
                    >
                      <Row>
                        <Col span={24}>
                          <Form.Item name={fieldCLS.benh_chinh} label={i18n.t(languageKeys.icd_benh_chinh)}>
                            <SelectCustom
                              dataSource={dsICD10Chinh}
                              disabled={!editable || (initData && !initData?.CHO_PHEP_CAP_NHAT)}
                              customTitle={(item) => item.MA_ICD + "-" + item.TEN_ICD}
                              onSelect={(value) => {
                                HLog("chon benh chinh", JSON.parse(value));
                                form.setFields([
                                  {
                                    name: fieldCLS.benh_chinh,
                                    errors: [],
                                  },
                                ]);
                              }}
                              showSearch
                              // eslint-disable-next-line react-hooks/exhaustive-deps
                              onSearch={useCallback(
                                debounce((value) => timKiemICD10(value, "CHINH"), 1000),
                                []
                              )}
                              loading={loadingDsBenh}
                              onDropdownVisibleChange={(bool) => bool && timKiemICD10("", "CHINH")}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item name={fieldCLS.benh_phu} label={i18n.t(languageKeys.icd_benh_kem_theo)}>
                            <SelectCustom
                              dataSource={dsICD10Phu}
                              disabled={!editable || (initData && !initData?.CHO_PHEP_CAP_NHAT)}
                              multiple
                              customTitle={(item) => (!!item.MA_ICD ? item.MA_ICD + "-" + item.TEN_ICD : "" + item.TEN_ICD)}
                              onChange={(value) => {
                                setDsBenhPhu(value);
                                HLog(
                                  "chon benh phu",
                                  value.map((item) => JSON.parse(item))
                                );
                                form.setFields([
                                  {
                                    name: fieldCLS.benh_phu,
                                    errors: [],
                                  },
                                ]);
                              }}
                              selectedList={dsBenhPhu}
                              setSelectedList={(value) => {
                                setDsBenhPhu(value);
                                form.setFields([{ name: fieldCLS.benh_phu, value: value }]);
                              }}
                              tagCount={0}
                              showSearch
                              // eslint-disable-next-line react-hooks/exhaustive-deps
                              onSearch={useCallback(
                                debounce((value, e) => {
                                  return timKiemICD10(value, "PHU");
                                }, 1000),
                                []
                              )}
                              loading={loadingDsBenh}
                              onDropdownVisibleChange={(bool) => bool && timKiemICD10("", "PHU")}
                              onPressEnter={handleThemMaBenhKemTheo}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        {/* {phongChiDinh?.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongRHM) ? ( */}
                        {phongChiDinh?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_RANG_HAM_MAT) ? (
                          <Col span={24}>
                            <div>
                              <label>
                                {i18n.t(languageKeys.chan_doan_so_bo)}
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <div style={{ display: "flex", gap: 12 }}>
                                <Form.Item
                                  name={fieldKhamBenh.HAM_TREN_SO_BO}
                                  style={{
                                    width: "50%",
                                  }}
                                  label={i18n.t(languageKeys.upper_jaw)}
                                >
                                  <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
                                </Form.Item>
                                <Form.Item
                                  name={fieldKhamBenh.HAM_DUOI_SO_BO}
                                  style={{
                                    width: "50%",
                                  }}
                                  label={i18n.t(languageKeys.lower_jaw)}
                                >
                                  <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
                                </Form.Item>
                              </div>
                            </div>
                          </Col>
                        ) : (
                          <Col span={24}>
                            <Form.Item
                              name={fieldCLS.CHAN_DOAN_LAM_SANG}
                              label={i18n.t(languageKeys.chan_doan_so_bo)}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: i18n.t(languageKeys.vui_long_nhap_chan_doan_so_bo),
                            //   },
                            // ]}
                            >
                              <Input.TextArea
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                disabled={!editable || (initData && !initData?.CHO_PHEP_CAP_NHAT)}
                                placeholder={i18n.t(languageKeys.common_Nhap)}
                              />
                            </Form.Item>
                          </Col>
                        )}

                        <Col span={24}>
                          <Form.Item name={fieldCLS.LY_DO_VAO_VIEN} label={i18n.t(languageKeys.field_Ly_do_kham)}>
                            <Input.TextArea
                              autoSize={{ minRows: 1, maxRows: 6 }}
                              disabled={!editable || (initData && !initData?.CHO_PHEP_CAP_NHAT)}
                              placeholder={i18n.t(languageKeys.common_Nhap)}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>

              {/* selectedExamServices */}
              <Row align="middle" justify="space-between" className={cn(style["title"])}>
                <h3>{i18n.t(languageKeys.danh_sach_dich_vu_da_chon)}</h3>
                <Space>
                  <label>{i18n.t(languageKeys.nguoi_chi_dinh)}</label>
                  <SelectCustom
                    value={ngChiDinh}
                    dataSource={personnel.map(i => ({ ...i, NAME: getFullName(i) }))}
                    titleKey="NAME"
                    valueKey="NHANSU_ID"
                    filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                    readOnly={!editable || (initData && !initData?.CHO_PHEP_CAP_NHAT)}
                    style={{ width: '100%' }}
                    onChange={setNgChiDinh}
                  />
                  {!(!editable || (initData && !initData?.CHO_PHEP_CAP_NHAT)) && (
                    <DanhsachMauDichvu
                      open={visible}
                      setDsDvKhamBenhDaChon={setSelectedExamServices}
                      setDsDvClsDaChon={setCurrentSubclinicalList}
                      setLoadingDv={() => {}}
                      disabled={false}
                    />
                  )}
                </Space>
              </Row>

              <div className={cn(style["table-ctn"], !!initData && style["table-ctn-no-adding"])}>
                <div className={cn(style["table-wrapper"])}>
                  <CustomTitleTable
                    key={currentSubclinicalList}
                    currentSubclinicalList={currentSubclinicalList}
                    onCheckAllChange={onCheckAllChange}
                    disabled={!editable || (initData && !initData?.CHO_PHEP_CAP_NHAT)}
                  />

                  {currentSubclinicalList.length > 0 || selectedExamServices?.length > 0 ? (
                    <React.Fragment>
                      {selectedExamServices.map((item) => {
                        return (
                          <RowTableExam
                            rowClassName={genHighlightClass(item.DV_ID || item.DICH_VU_ID)}
                            key={item.DV_ID}
                            dichvu={item}
                            onChangePhong={(_, __, type) => onChangePhong(_, __, "kb")}
                            onChangeGhiChu={(_, __, type) => onChangeGhiChu(_, __, "kb")}
                            onChangeSoLuong={(a, b) => onChangeSoLuong(a, b, "kb")}
                            onDelete={(i) => handleDelete(i, "kb")}
                            disabled={!editable || (initData && !initData?.CHO_PHEP_CAP_NHAT)}
                            onChangeCheck={onChangeCheck}
                            onPrint={onPrint}
                            // initData={initData}
                            cskcbBrand={createNew ? selectedCSKCB?.BRAND : initData?.BENH_VIEN_BRAND}
                            disabledPrint={disabledPrint}
                            disabledDelete={disabledDelete}
                            dataSource={selectedExamServices.concat(currentSubclinicalList)}
                            setCurrentSubclinicalList={setCurrentSubclinicalList}
                            setSelectedExamServices={setSelectedExamServices}
                          />
                        );
                      })}
                      {currentSubclinicalList.map((item) => {
                        return (
                          <React.Fragment key={item.DV_ID}>
                            <RowTable
                              rowClassName={genHighlightClass(item.DV_ID || item.DICH_VU_ID)}
                              key={item.DV_ID}
                              dichvu={item}
                              onChangePhong={onChangePhong}
                              onChangeNoiThucHien={onChangeNoiThucHien}
                              onChangeGhiChu={onChangeGhiChu}
                              onChangeSoLuong={onChangeSoLuong}
                              onDelete={handleDelete}
                              disabled={!editable || (initData && !initData?.CHO_PHEP_CAP_NHAT)}
                              onChangeCheck={onChangeCheck}
                              onPrint={onPrint}
                              initData={initData}
                              cskcbBrand={createNew ? selectedCSKCB?.BRAND : initData?.BENH_VIEN_BRAND}
                              disabledPrint={disabledPrint}
                              disabledDelete={disabledDelete}
                              currentSubclinicalList={selectedExamServices.concat(currentSubclinicalList)}
                              setCurrentSubclinicalList={setCurrentSubclinicalList}
                              setSelectedExamServices={setSelectedExamServices}
                            />
                            {item.DV_KEM_THEO?.length > 0 &&
                              item.DV_KEM_THEO?.map((dv) => (
                                <RowTable
                                  rowClassName={genHighlightClass(dv.DV_KEM_THEO_ID)}
                                  key={dv.DV_KEM_THEO_ID}
                                  dichvu={dv}
                                  onChangePhong={onChangePhong}
                                  onChangeGhiChu={onChangeGhiChu}
                                  onChangeSoLuong={onChangeSoLuong}
                                  onChangeNoiThucHien={onChangeNoiThucHien}
                                  onDelete={handleDelete}
                                  disabled={!editable || (initData && !initData?.CHO_PHEP_CAP_NHAT)}
                                  onChangeCheck={onChangeCheck}
                                  onPrint={onPrint}
                                  initData={initData}
                                  cskcbBrand={createNew ? selectedCSKCB?.BRAND : initData?.BENH_VIEN_BRAND}
                                  disabledPrint={disabledPrint}
                                  disabledDelete={disabledDelete}
                                />
                              ))}
                          </React.Fragment>
                        );
                      })}
                      <FooterTable
                        currentSubclinicalList={
                          Array.isArray(currentSubclinicalList) && Array.isArray(selectedExamServices)
                            ? [...currentSubclinicalList, ...selectedExamServices]
                            : []
                        }
                      />
                    </React.Fragment>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={style["empty"]} />
                  )}
                </div>
              </div>

              <div className={style["total-price-ctn"]}></div>

              {!initData?.CHITIET_PHIEU_ID && (
                <div className={cn(style["form-ctn"], style["form-note"])}>
                  <Form.Item name={fieldCLS.GHI_CHU} label={i18n.t(languageKeys.field_Ghi_chu)} initialValue="">
                    <Input.TextArea
                      rows={2}
                      disabled={!editable || (initData && !initData?.CHO_PHEP_CAP_NHAT)}
                      placeholder={i18n.t(languageKeys.nhap_loi_dan)}
                    />
                  </Form.Item>
                </div>
              )}
            </Layout>
          </Layout>
        </Form>

        <ConfirmModal
          ref={deleteRef}
          title={i18n.t(languageKeys.common_Xoa)}
          content={i18n.t(languageKeys.cf_Ban_co_chac_chan_xoa)}
          onCancel={() => {
            setSelectedRecord({});
          }}
          onOk={() => {
            HuyDvCLS(selectedRecord);
          }}
        />

        <ConfirmModal
          ref={rebornRef}
          title={i18n.t(languageKeys.title_Xac_nhan)}
          okText={i18n.t(languageKeys.common_Tiep_tuc)}
          content={i18n.t(languageKeys.confirm_Chuyen_cuoc_kham_tu_hoan_thanh_sang_dang_kham)}
          onOk={({ formData, print }) => {
            rebornRef.current.loading(true);
            console.log(`formData`, formData);
            apiLuuChiDinhCLS(formData, print);
          }}
        />

        <ModalCheckDuplicate ref={duplicateRef}
          onClose={(props) => {
            setDuplicateList(props.duplicateItems || []);
          }}
          onOk={({ print }) => { duplicateRef.current?.close(); kiemTraLoi(print, false); }}
        />

        <ConfirmModal
          ref={changeCSKCBRef}
          title={i18n.t(languageKeys.title_Xac_nhan)}
          cancelText={i18n.t(languageKeys.huy)}
          okText={i18n.t(languageKeys.title_Xac_nhan)}
          content={i18n.t(languageKeys.noti_chuyen_cskcb)}
          onOk={(data) => {
            // setSelectedCSKCB(dsCoSoKCB.find((item) => item.MA_CSKCB === data));
            // setSelectedLoaiDvCls({});
            // setDsDvClsDaChon([]);
            // setDsKhamBenhDaChon([]);
            setCurrentSubclinicalList([]);
            setFilterItem({});
            setExpandedKeys([]);
            changeCSKCBRef.current.close(true);
          }}
          onCancel={() => {
            changeCSKCBRef.current.close(true);
          }}
          danger
        />
      </FormDrawer>
    );
  }
);

export default memo(ChiDinhCLS);
