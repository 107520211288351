import { ActionButton, ConfirmModal, Table } from "../../../../../components";
import i18n, { languageKeys } from "../../../../../i18n";
import styles from "./content.module.less";
import { Layout, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import useKeDonThuoc from "./hooks";
import { handleGlobalKeyPressEnterToTab, isEmptyObject } from "helpers";
import { useEventListener } from "hooks";
import usePrescriptionForm from "./columns";
import { tableLoadingState } from "ducks/slices/loadingSlice";
import { doFetchPrescriptionForm, getSharedData } from "ducks/slices/ListData/SharedData.slice";

const MainContent = ({
    selectedKho,
    setSelectedKho = () => {},
    disabled = false,
    isVisibleKeDonThuoc = false,
    dsKho = [],
    setDsKho = () => {},
    patientInfo = {},
    disableAdd,
    disableEdit,
    infoDonThuoc,
    //
    dataSource = [{}],
    setDataSource = () => {},
    checkLuuMauDon = false,
    editable = false,
    disableDelete,
    //
    forceHideStorage = false,
    importantKeys = {},
}) => {

    const storageRef = useRef()

    const dispatch = useDispatch()
    // const userProfile = useSelector((state) => state.auth.user);
    const { prescriptionForm } = useSelector(getSharedData)
    const loading = useSelector(tableLoadingState)

    // const choPhepChonKho = (userProfile.config && userProfile.config?.KHO_THUOC) && !forceHideStorage;
    const readOnlyKho = disabled;
    const readOnlyThuoc = /*(!selectedKho && choPhepChonKho) ||*/ disabled || disableEdit;

    // Lấy id mẫu đơn
    useEffect(() => {
        !prescriptionForm && dispatch(doFetchPrescriptionForm())
    }, [])

    // Fill kho
    useEffect(() => {
        if (dsKho.length > 0) {
            if (!isEmptyObject(infoDonThuoc)) {
                setSelectedKho(dsKho.find(item => item.ID === infoDonThuoc?.KHO_THUOC_ID))
            }
            if (isEmptyObject(infoDonThuoc) && dataSource.length === 1 && dsKho.length !== 0) {
                handleSelectKho(dsKho[0], false, 0)
            }
        } else {
            if (isEmptyObject(infoDonThuoc) && dataSource.length === 1) {
                handleSelectKho({ ID: "DANH_MUC" }, false, 0)
            }
        }
    }, [infoDonThuoc, dsKho])


    //
    const { handleChangeInput, loadingKho, setArrFixedUsageDay, setArrFixedManual }
        = useKeDonThuoc({ readOnlyKho, isVisibleKeDonThuoc, patientInfo, setDsKho, dataSource, setDataSource, disableAdd, importantKeys })


    // Hàm chọn kho
    const handleSelectKho = (kho, isChangeStorage = false, rowIndex = undefined, record) => {
        //
        if (kho.ID === "DANH_MUC" && record?.KHO_THUOC_ID !== "DANH_MUC") {
            setDataSource((currentArr) =>
                currentArr.map((item, index) =>
                    index === rowIndex
                        ? { KHO_THUOC_ID: "DANH_MUC" }
                        : item
                )
            );
            return;
        }
        // Chọn kho
        if (kho && selectedKho && kho.ID === selectedKho.ID) {
            if (record?.KHO_THUOC_ID !== "DANH_MUC") {
                return;
            } else {
                return setDataSource((currentArr) => currentArr.map((item, index) =>
                    index === rowIndex
                        ? { KHO_THUOC_ID: kho.ID }
                        : item
                ));
            }
        }
        if (isChangeStorage && dataSource.filter(i => i.Thuoc_ID).length > 0) {
            return storageRef.current?.open({ kho, rowIndex });
        }
        if (rowIndex !== undefined) {
            setDataSource((currentArr) => currentArr.map((item, index) =>
                index === rowIndex
                    ? { KHO_THUOC_ID: kho.ID }
                    : item
            ));
        }
        setSelectedKho(kho);
    }


    // Hàm chọn thuốc
    const handleSelectThuoc = (thuoc, record) => {
        //
        if (/*!!choPhepChonKho &&*/ thuoc && !Number(thuoc.TON_CUOI) && record.KHO_THUOC_ID !== "DANH_MUC") {
            return notification.error({
                placement: "bottomLeft",
                message: i18n.t(languageKeys.duoc_so_luong_thuoc_khong_kha_dung),
            });
        }
        setDataSource((currentArr) => currentArr
            .map((item, index) => index === record.STT - 1
                ? {
                    KHO_THUOC_ID: item.KHO_THUOC_ID,
                    Thuoc_ID: thuoc.Thuoc_ID,
                    Thuoc_Code: thuoc.Thuoc_Code,
                    Thuoc_Ten: thuoc.Thuoc_Ten,
                    Thuoc_Dvt: thuoc.Thuoc_Dvt,
                    Thuoc_Dvt_Cach_Dung: thuoc.Thuoc_Dvt,
                    Thuoc_HoatChat: thuoc.Thuoc_HoatChat,
                    IS_THUC_PHAM_CHUC_NANG: thuoc?.IS_THUC_PHAM_CHUC_NANG,
                    TON_CUOI: thuoc.TON_CUOI,
                    HAM_LUONG: thuoc.HAM_LUONG,
                    NONG_DO: thuoc.NONG_DO
                }
                : item
            )
        );
    }

    useEventListener("keydown", handleGlobalKeyPressEnterToTab, window.document, true)


    // Ds form
    const { DefaultForm, AiviForm }
        = usePrescriptionForm({
            readOnlyKho, readOnlyThuoc, disableEdit,
            dataSource, dsKho, loadingKho,
            handleSelectKho, handleSelectThuoc, handleChangeInput,
        })

    const FormOptions = {
        1: DefaultForm,
        2: AiviForm
    }


    // HTML
    return (
        <Layout.Content
            className={styles["container"]}
            style={{ height: forceHideStorage ? undefined : `calc(100vh - ${checkLuuMauDon ? 379 : 330}px)` }}
        >
            <Table
                rowKey="STT"
                columns={
                    [
                        {
                            title: i18n.t(languageKeys.stt),
                            dataIndex: "STT",
                            width: 45,
                            align: 'center',
                            render: (STT, record) => record.Thuoc_Ten
                                ? STT
                                : <PlusCircleFilled style={{ fontSize: 20, color: 'rgba(101, 118, 255, 1)' }} />
                        },
                        ...(FormOptions[prescriptionForm] || []),
                        {
                            title: "",
                            fixed: "right",
                            width: 35,
                            render: (_, record) => (record.STT !== dataSource.length) && (
                                <ActionButton
                                    tooltipXoaTheoQuyen={!disableDelete}
                                    deleteDisabled={disableDelete}
                                    record={record}
                                    onDelete={() => {
                                        setDataSource((list) => list.filter((_, idx) => idx + 1 !== record?.STT))
                                        setArrFixedUsageDay((list) => list.filter((item) => item !== record?.Thuoc_ID))
                                        setArrFixedManual((list) => list.filter((item) => item !== record?.Thuoc_ID))
                                    }}
                                    showDelete={editable}
                                    showEdit={false}
                                />
                            ),
                        },
                    ]
                }
                dataSource={dataSource.map((item, index) => ({ STT: index + 1, ...item }))}
                scroll={{
                    x: (FormOptions[prescriptionForm] || []).reduce((sum, item) => sum + item.width, 0),
                    y: `calc(100vh - ${checkLuuMauDon ? 468 : 418}px)`
                }}
                className={styles.table}
                loading={loading}
            />

            <ConfirmModal
                ref={storageRef}
                title={i18n.t(languageKeys.title_Xac_nhan)}
                okText={i18n.t(languageKeys.common_Tiep_tuc)}
                content={i18n.t(languageKeys.confirm_Thay_doi_kho_thuoc)}
                danger={true}
                onOk={({ kho }) => {
                    storageRef.current?.setVisible(false);
                    setSelectedKho(kho);
                    setDataSource([
                        ...dataSource.filter(i => i.KHO_THUOC_ID === "DANH_MUC" && i.Thuoc_ID),
                        { KHO_THUOC_ID: kho.ID }
                    ]);
                }}
                onCancel={({ rowIndex }) =>
                    setDataSource((currentArr) => currentArr.map((item, index) =>
                        index === rowIndex
                            ? { ...item, KHO_THUOC_ID: selectedKho?.ID }
                            : item
                    ))
                }
                onPressCancelSuccess={({ rowIndex }) =>
                    setDataSource((currentArr) => currentArr.map((item, index) =>
                        index === rowIndex
                            ? { ...item, KHO_THUOC_ID: selectedKho?.ID }
                            : item
                    ))
                }
                zIndex={9999999}
            />
        </Layout.Content>
    )
}

export default MainContent;