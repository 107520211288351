import { useCallback, useEffect, useState } from "react";
import formDanhMuc from "./formDanhMuc.module.less";
import { Col, Form, Input, Row, Switch } from "antd";
import { fieldDuongDung, fieldHoatChat } from "../fieldsDanhMuc";
import i18n, { languageKeys } from "../../../../i18n";
import { apiLayDsDuongDung } from "../apisDanhMuc/apisDmThuoc";
import { useSelector } from "react-redux";
import { Select } from "../../../../components";
import { isEmptyObject } from "../../../../helpers";
import { debounce } from "lodash";
import { keys } from "../../../../constants";

const FormHoatChat = ({
  initData = {},
  visible = false,
  isCreateNew = false,
  form,
}) => {
  const [loadingDuongDung, setLoadingDuongDung] = useState(false);

  useEffect(() => {
    if (visible && !isEmptyObject(initData)) {
      form.setFields([
        {
          name: fieldHoatChat.duong_dung,
          // value: JSON.stringify({
          //   [fieldDuongDung.id]: Number(initData[fieldHoatChat.duong_dung]),
          //   [fieldDuongDung.ten_duong_dung]:
          //     initData[fieldHoatChat.ten_duong_dung],
          // }),
          value: initData[fieldHoatChat.ten_duong_dung],
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const userProfile = useSelector((state) => state.auth.user);

  const [dsDuongDung, setDsDuongDung] = useState([]);

  const layDsDuongDung = async (search_string = "") => {
    setLoadingDuongDung(true);
    let res = await apiLayDsDuongDung({
      search_string,
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      page: 1,
      limit: keys.limit,
    });
    if (res) {
      setDsDuongDung(
        res.result.map((item) => ({
          [fieldDuongDung.id]: item[fieldDuongDung.id],
          [fieldDuongDung.ten_duong_dung]: item[fieldDuongDung.ten_duong_dung],
        }))
      );
    }
    setLoadingDuongDung(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchDuongDung = useCallback(debounce(layDsDuongDung, 1000), []);

  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={fieldHoatChat.ma_hoat_chat}
                label={i18n.t(languageKeys.field_ma_hoat_chat)}
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: i18n.t(languageKeys.vui_long_nhap),
                    // },
                  ]
                }
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldHoatChat.ma_hoat_chat_bhyt}
                label={i18n.t(languageKeys.field_ma_hoat_chat_bhyt)}
                // rules={[
                //   {
                //     required: true,
                //     message: i18n.t(languageKeys.vui_long_nhap),
                //   },
                // ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldHoatChat.ten_hoat_chat}
                label={i18n.t(languageKeys.field_ten_hoat_chat)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldHoatChat.duong_dung}
                label={i18n.t(languageKeys.field_ten_duong_dung)}
                // rules={[
                //   {
                //     required: true,
                //     message: i18n.t(languageKeys.vui_long_nhap),
                //   },
                // ]}
              >
                <Select
                  dataSource={dsDuongDung}
                  titleKey={fieldDuongDung.ten_duong_dung}
                  showSearch
                  onSearch={searchDuongDung}
                  onDropdownVisibleChange={(bool) => !!bool && layDsDuongDung()}
                  loading={loadingDuongDung}
                />
              </Form.Item>
            </Col>

            {/*==============END ROWS 1*/}
          </Row>
        </div>

        {/*<Row style={{ marginTop: 30 }}>*/}
        {/*  <h4 style={{ marginTop: 5 }}>*/}
        {/*    {i18n.t(languageKeys.field_Yeu_cau_hoi_chan)}*/}
        {/*  </h4>*/}
        {/*  <Form.Item*/}
        {/*    name={fieldHoatChat.IS_YC_HOI_CHAN}*/}
        {/*    valuePropName="checked"*/}
        {/*  >*/}
        {/*    <Switch style={{ marginLeft: "20px" }} />*/}
        {/*  </Form.Item>*/}
        {/*</Row>*/}

        <Row>
          <h4 style={{ marginTop: 5 }}>
            {i18n.t(languageKeys.field_Hien_thi)}
          </h4>

          <Form.Item name={fieldHoatChat.hien_thi} valuePropName="checked">
            <Switch style={{ marginLeft: "20px" }} />
          </Form.Item>
        </Row>
      </div>
    </>
  );
};

export default FormHoatChat;
