import formDanhMuc from "./formDanhMuc.module.less";
import { Col, Form, Input, Row, Switch } from "antd";
import { fieldHangSanXuat } from "../fieldsDanhMuc";

import i18n, { languageKeys } from "../../../../i18n";

const FormHangSanXuat = ({
  initData = {},
  visible = false,
  isCreateNew = false,
  form,
}) => {
  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={fieldHangSanXuat.ma_hang_sx}
                label={i18n.t(languageKeys.field_Ma_hang_san_xuat)}
                rules={[
                  // {
                  //   required: true,
                  //   message: i18n.t(languageKeys.vui_long_nhap),
                  // },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldHangSanXuat.ten_hang_sx}
                label={i18n.t(languageKeys.field_Ten_hang_san_xuat)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            {/*==============END ROWS 1*/}
          </Row>
        </div>

        <h4>{i18n.t(languageKeys.field_Ghi_chu)}</h4>
        <Form.Item
          name={fieldHangSanXuat.ghi_chu}
          //label={i18n.t(languageKeys.field_Ten_hang_san_xuat)}
        >
          <Input.TextArea
            rows={3}
            placeholder={i18n.t(languageKeys.common_Nhap)}
          />
        </Form.Item>
        <Row>
          <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>
          <Form.Item name={fieldHangSanXuat.hien_thi} valuePropName="checked">
            <Switch style={{ marginLeft: "20px" }} />
          </Form.Item>
        </Row>
      </div>
    </>
  );
};

export default FormHangSanXuat;
