import { apis, keys } from "../../../constants";
import { common_post } from "../../../helpers";

export const apiLayDsSoHoaDon = async ({
  ID,
  search_string,
  BENH_VIEN_ID,
  partner_code,
  limit = 9999,
  page = 0,
  TU_NGAY,
  DEN_NGAY,
  NHANSU_ID
}) => {
  const req = {
    ID,
    search_string,
    BENH_VIEN_ID,
    partner_code,
    limit,
    page,
    TU_NGAY,
    DEN_NGAY,
    NHANSU_ID
  };

  try {
    const res = await common_post(apis.vien_phi_lay_ds_so_hoa_don, req);
    if (res.status === "OK") {
      return res;
    }
    return null;
  } catch (error) {
    // HLog("lay ds id phieu" , error)
    return null;
  }
};

export const apiLuuDsSoHoaDon = async (data = {}) => {
  const req = { ...data };

  try {
    const res = await common_post(apis.vien_phi_tao_so_hoa_don, req);
    if (res.status === "OK") {
      return res;
    }
    return res;
  } catch (error) {
    return null;
  }
};

export const apiChiTietSoHoaDon = async ({
  partner_code,
  ID,
  BENH_VIEN_ID,
}) => {
  const req = {
    partner_code,
    ID,
    BENH_VIEN_ID,
  };

  try {
    const res = await common_post(apis.vien_phi_lay_chi_tiet_so_hoa_don, req);
    if (res.status === "OK") {
      return res;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const apiCapNhatSoHoaDon = async (data) => {
  const req = { ...data };

  try {
    const res = await common_post(apis.vien_phi_cap_nhat_so_hoa_don, req);
    if (res.status === "OK") {
      return res;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const apiXoaSoHoaDon = async ({ partner_code, listId = [] }) => {
  const req = {
    partner_code,
    listId,
  };

  try {
    const res = await common_post(apis.vien_phi_xoa_so_hoa_don, req);
    if (res.status === "OK") {
      return res;
    }
    return null;
  } catch (error) {
    return null;
  }
};
