import { Tree } from "antd";
import styles from "../vattu.module.less";
import { locdau } from "helpers";
import { useSelector } from "react-redux";
import { getLieuTrinhData } from "ducks/slices/GoiDichVu/GoiVatTu.slice";
import moment from "moment";
import { getUser } from "ducks/slices/authSlice";

const ListKHSD = ({ disabled, setDataSource, searchVal, dataSource = [], index }) => {

  const userProfile = useSelector(getUser)
  const { planData, servicePlanList } = useSelector(getLieuTrinhData);

  //
  const formattedBundleList =
    servicePlanList
      .filter(dv => locdau(dv.TEN_DICHVU).includes(locdau(searchVal)))
      .map(item => ({
        ...item,
        _id: item.DV_ID,
        DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
          ...dvkt,
          _id: `${dvkt.DV_KEM_THEO_ID || dvkt.DICH_VU_ID}_${item.DV_ID}`,
        }))
      }))


  //
  const DSDV = planData.flatMap(i => i.DANH_SACH_DICH_VU).reduce((acc, cur) => {
    const found = acc.find(val => val._id === cur._id)
    if (found) {
      found.SO_LUONG += Number(cur?.SO_LUONG)
    }
    else {
      acc.push({ ...cur, SO_LUONG: Number(cur?.SO_LUONG) })
    }
    return acc
  }, [])
  //
  const checkDisabled = (dv) => {
    const total = DSDV.find(i => i._id === dv._id)?.SO_LUONG
    // return fullData.some((item, num) => {
    //   const total = DSDV.find(i => i._id === dv._id)?.SO_LUONG
    //   return item.DANH_SACH_DICH_VU.some(i => i._id === dv._id) && num !== index && total >= dv.SO_LUONG
    // })
    return !planData[index].DANH_SACH_DICH_VU?.some(i => i._id === dv._id) && total >= dv.SO_LUONG
  }


  const onTreeSelect = (data) => {
    if (data.disabled || disabled) {
      return;
    }
    if (data.selectable) {
      if (data.checked) {
        if (data.value.DV_KEM_THEO) {
          setDataSource(dataSource.filter((item) => item.DICH_VU_GOC !== data.value.DV_ID))
        } else {
          setDataSource(dataSource.filter((item) => item._id !== data.value._id))
        }
      } else {
        if (data.value.DV_KEM_THEO?.length) {
          setDataSource([
            ...dataSource,
            ...data.value.DV_KEM_THEO.map(item => ({
              ...item,
              SO_LUONG: 1,
              THANH_TIEN_PHAN_BO: item.DON_GIA_PHAN_BO,
              // MIEN_GIAM: item.MIEN_GIAM / item.SO_LUONG,
              // TONG_TIEN: item.DON_GIA_PHAN_BO - item.MIEN_GIAM / item.SO_LUONG,
              //
              HO_TEN_NGUOI_TAO: `${userProfile?.HO} ${userProfile?.TEN}`,
              NGAY_TAO: moment().format('YYYYMMDD'),
              GIO_TAO: moment().format('H:mm'),
              HO_TEN_NGUOI_CAP_NHAT: undefined,
              NGAY_CAP_NHAT: undefined,
              GIO_CAP_NHAT: undefined,
            }))
          ]);
        } else {
          setDataSource([
            ...dataSource,
            {
              ...data.value,
              //
              SO_LUONG: 1,
              THANH_TIEN_PHAN_BO: data.value.DON_GIA_PHAN_BO,
              // MIEN_GIAM: data.value.MIEN_GIAM / data.value.SO_LUONG,
              // TONG_TIEN: data.value.DON_GIA_PHAN_BO - data.value.MIEN_GIAM / data.value.SO_LUONG,
              PHONG_THUC_HIEN_ID: data.value.LIST_PHONG_ID?.[0] || data.value.PHONG_THUC_HIEN_ID,
              //
              HO_TEN_NGUOI_TAO: `${userProfile?.HO} ${userProfile?.TEN}`,
              NGAY_TAO: moment().format('YYYYMMDD'),
              GIO_TAO: moment().format('H:mm'),
              HO_TEN_NGUOI_CAP_NHAT: undefined,
              NGAY_CAP_NHAT: undefined,
              GIO_CAP_NHAT: undefined,
            },
          ]);
        }
      }
    }
  }

  return (
    <Tree
      defaultExpandAll
      checkable
      onClick={(_, e) => onTreeSelect(e)}
      onCheck={(_, event) => onTreeSelect(event.node)}
      checkedKeys={dataSource.map((item) => item._id)}
      disabled={disabled}
      rootClassName={styles.tree}
    // onExpand={() => false}
    >
      {formattedBundleList?.map((item) => {
        if (item.DV_KEM_THEO) {
          return (
            <Tree.TreeNode
              key={item._id}
              value={item}
              title={item.TEN_DICHVU}
              className={styles["tree-node-parent"]}
              selectable
              checkable
              disabled={item.DV_KEM_THEO.every(i => checkDisabled(i))}
            >
              {item.DV_KEM_THEO.map((sub_item) => (
                <Tree.TreeNode
                  key={sub_item._id}
                  value={sub_item}
                  title={sub_item.TEN_DICH_VU_KEM_THEO || sub_item.TEN_DICHVU || sub_item.TEN_DV}
                  selectable
                  checkable
                  disabled={checkDisabled(sub_item)}
                />
              ))}
            </Tree.TreeNode>
          );
        } else {
          return (
            <Tree.TreeNode
              key={item._id}
              className={styles["tree-node-item"]}
              value={item}
              title={item.TEN_DICHVU}
              selectable
              checkable
              disabled={checkDisabled(item)}
            />
          );
        }
      })}
    </Tree>
  )
}

export default ListKHSD;