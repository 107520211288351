import { Avatar, notification, Upload } from "antd";
import { useState } from "react";
import { IconAddImage, IconImage } from "../../../../../assets/svg";
import { getBase64, getImageFromSever } from "../../../../../helpers";
import i18n, { languageKeys } from "../../../../../i18n";
import style from "../caiDatNguoiDung.module.less";

export const AvatarUploader = ({
  setData = () => {},
  urlPreview,
  setUrlPreview = () => {},
  showAddIcon = true,
}) => {
  const [isChangeAvatar, setIsChangeAvatar] = useState(false);
  const [error, setError] = useState(false);

  async function onChangeAvatar(data) {
    if (data.file.status !== "uploading" && !error) {
      let urlPreview = await getBase64(data.file.originFileObj);
      setIsChangeAvatar(true);
      setUrlPreview(urlPreview);
      let array = urlPreview.split(",");
      setData(array[1]);
    }
  }
  console.log(urlPreview)
  const beforeUpload = (file) => {
    setError(false);
    const isImage = file.type.indexOf("image/") === 0;
    if (!isImage) {
      notification.error({
        message: i18n.t(languageKeys.noti_Chi_duoc_upload_anh),
        placement: "bottomLeft",
      });
      setError(true);
      return;
    }

    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      setError(true);
      notification.error({
        message: i18n.t(languageKeys.noti_Kich_thuoc_anh_be_hon_5mb),
        placement: "bottomLeft",
      });
    }
    return isImage && isLt5M;
  };

  return (
    <div style={{ cursor: "pointer" }}>
      <Upload
        name="avatar"
        showUploadList={false}
        onChange={onChangeAvatar}
        beforeUpload={beforeUpload}
        accept="image/*"
      >
        <Avatar
          size={100}
          src={isChangeAvatar ? urlPreview : getImageFromSever(urlPreview)}
        >
          <IconImage />
        </Avatar>
        {
          showAddIcon && (
            <IconAddImage className={style["iconAdd"]} />
          )
        }
      </Upload>
    </div>
  );
};
