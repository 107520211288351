import { useEffect, useCallback, useState } from "react";
import { apiLayDsSoHoaDon } from "./apisSoHoaDon";
import { rid } from "../../../helpers";
import { useSelector } from "react-redux";
import { PAGE_SIZE_OPTIONS } from "constants/keys";

export const useGetSoHoaDon = () => {
  const [dataSource, setDataSource] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);
  const [loading, setLoading] = useState(false);
  const userProfile = useSelector((state) => state.auth.user);
  const getDataSource = useCallback(
    async (search_string = "", page = 1, TU_NGAY = null, DEN_NGAY = null, limit = pageSize) => {
      setPageSize(limit);
      setLoading(true);
      let res = await apiLayDsSoHoaDon({
        search_string,
        page,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
        TU_NGAY,
        DEN_NGAY,
        limit,
        NHANSU_ID: userProfile?.NHANSU_ID
      });

      if (!!res) {
        const dataList = res.result.map((item) => ({
          ...item,
          key: rid(),
        }));

        setDataSource(dataList);
        setCurrentPage(res.current_page);
        setTotalResult(res.total);
      } else resetState();

      setLoading(false);
    },
    [userProfile.BENH_VIEN_ID, userProfile.partner_code, pageSize]
  );

  const resetState = () => {
    setDataSource([]);
    setTotalResult(0);
    setCurrentPage();
  };

  return {
    dataSource,
    totalResult,
    currentPage,
    resetState,
    getDataSource,
    loadingList: loading,
    pageSize
  };
};
