import { Button, Drawer, Space, Layout, Divider, Tooltip, notification } from "antd";
import { useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { forwardRef } from "react";
import style from "../../qlcn.module.less";
import i18n, { languageKeys } from "i18n";
import { ModalPDF, Table } from "components";
import { flatten } from "lodash";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { debtManagementState, refundMoney, refundMoneyAndPrint } from "ducks/slices/QlyVienPhi/QlyCongNo.slice";
import { formatCurrency2, handleCheckPermissions } from "helpers";
import { Check_box, NewBackIcon, Uncheckbox } from "assets/svg";
import { apis } from "../../../../../constants";
import { featureKeys } from "constants/keys";
import { getSharedData } from "ducks/slices/ListData/SharedData.slice";

const DrawerHT = forwardRef(({ form }, ref) => {
    const dispatch = useDispatch()
    const pdfRef = useRef()

    const [open, setOpen] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const { unpaidDebtList, refundServiceList, debtList, patientInfo } = useSelector(debtManagementState)

    const userProfile = useSelector((state) => state.auth.user);
    const permission = handleCheckPermissions(userProfile, featureKeys.quan_ly_cong_no)
    const { paymentMethod } = useSelector(getSharedData)
    useImperativeHandle(ref, () => ({
        open: () => setOpen(true)
    }))

    useEffect(() => {
        if (!open) {
            setSelectedRows([])
        }
    }, [open])

    const handleRefundMoney = (isPrint) => {
        const { MIEN_GIAM, TONG_CHI_PHI, TONG_THANH_TOAN } = unpaidDebtList;
        const selectedPatient = debtList?.find(item => item?.HOA_DON_ID === patientInfo?.billId);
        const { BENH_NHAN_ID, LICH_KHAM_ID, MIEN_GIAM_PHAN_TRAM, NHANSU_ID, HOA_DON_ID, GOI_DV_ID } = selectedPatient;
        const { SO_HOADON_ID, LY_DO_HOAN_TIEN, HINH_THUC_THANH_TOAN } = form.getFieldsValue()
        const listDVPayload = selectedRows.filter(i => !i.children)
        const payload = {
            SO_HOADON_ID,
            BENH_NHAN_ID,
            HINH_THUC_THANH_TOAN: JSON.parse(HINH_THUC_THANH_TOAN).value,
            TEN_HINH_THUC_THANH_TOAN: JSON.parse(HINH_THUC_THANH_TOAN).name,
            LICH_KHAM_ID,
            MIEN_GIAM,
            MIEN_GIAM_PHAN_TRAM,
            NHANSU_ID,
            NHOM: 'HOAN_TIEN',
            PHAN_LOAI: 'CONG_NO',
            TONG_CHI_PHI,
            TONG_THANH_TOAN,
            HOA_DON_CHA_ID: HOA_DON_ID,
            TIEN_THUA: 0,
            BENH_NHAN_DUA: calculateRefundMoney(),
            GOI_DV_ID,
            LY_DO_HOAN_TIEN,
            list_dv: listDVPayload.map(row => ({
                ID: row.ID,
                RLS_BN_GOI_ID: row.RLS_BN_GOI_ID,
                RLS_BN_GOI_DV_ID: row.RLS_BN_GOI_DV_ID,
                RLS_BN_GOI_DVCON_ID: row.RLS_BN_GOI_DVCON_ID || null,
                DV_ID: row.DV_ID,
                TEN_DV: row.TEN_DV,
                MA_DV: row.MA_DV,
                PHAN_LOAI: row.PHAN_LOAI,
                GIAM_TIEN: row.GIAM_TIEN || 0,
                GIAM_PHAN_TRAM: row.GIAM_PHAN_TRAM || 0,
                TONG_TIEN_GIAM: row.TONG_TIEN_GIAM || 0,
                DON_GIA: row.DON_GIA,
                SO_LUONG: row.SO_LUONG,
                THANH_TIEN: row.THANH_TIEN,
                PHONG_DIEU_TRI_ID: row.PHONG_DIEU_TRI_ID || null,
                NHOM_BHYT_ID: row.NHOM_BHYT_ID || null
            })),
            list_dv_thanh_toan: [{
                BENH_NHAN_DUA: calculateRefundMoney(),
                HINH_THUC_THANH_TOAN: JSON.parse(HINH_THUC_THANH_TOAN).value,
                TEN_HINH_THUC_THANH_TOAN: JSON.parse(HINH_THUC_THANH_TOAN).name
            }]
        }

        if (isPrint) {
            dispatch(refundMoneyAndPrint({
                keyPhieu: 'phieuHoanTien', payload, callback: (dataRequest) => {
                    pdfRef.current.openModal(dataRequest, LICH_KHAM_ID ? apis.in_cong_no_dv : apis.pdf_quan_ly_cong_no)
                    setOpen(false)
                }
            }))
        } else {
            dispatch(refundMoney({
                payload, callback: () => {
                    setOpen(false)
                }
            }))
        }
    }

    const calculateRefundMoney = useCallback(() => {
        const removeParentRow = selectedRows.filter(i => !i?.children)
        return removeParentRow.reduce((total, curr) => {
            if (!curr?.TRANG_THAI ? false : !(curr?.TRANG_THAI === 'CHUA_KHAM')) {
                return total
            } else {
                return total += curr.THANH_TIEN
            }
        }, 0)
    }, [selectedRows])

    const FormFooter = (
        <Space style={{ float: 'right' }}>
            <Button onClick={() => setOpen(false)} type="primary" ghost>{i18n.t(languageKeys.common_Thoat)} (ESC)</Button>
            <Tooltip title={(permission.HOAN_TIEN && permission.IN_PDF) ? "" : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                <Button
                    disabled={((permission.HOAN_TIEN && permission.IN_PDF) ? false : true) || calculateRefundMoney() > unpaidDebtList?.TONG_DA_THANH_TOAN || calculateRefundMoney() === 0}
                    type="primary" onClick={() => handleRefundMoney(true)}
                >
                    {i18n.t(languageKeys.vien_phi_Hoan_tien_va_in)}
                </Button>
            </Tooltip>
            <Tooltip title={permission.HOAN_TIEN ? "" : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                <Button
                    disabled={!permission.HOAN_TIEN || calculateRefundMoney() > unpaidDebtList?.TONG_DA_THANH_TOAN || calculateRefundMoney() === 0}
                    type="primary" onClick={() => handleRefundMoney(false)}
                >
                    {i18n.t(languageKeys.vien_phi_Hoan_tien)}
                </Button>
            </Tooltip>
        </Space>
    )

    const formatDataTable = (data = []) => {
        const removeIsHoanItem = data[0]?.DS_DV?.filter(item => item.IS_HOAN !== 1).map(i => {
            if (i?.DV_KEM_THEO) {
                return {
                    ...i,
                    DV_KEM_THEO: i.DV_KEM_THEO.filter(subItem => subItem.IS_HOAN !== 1)
                }
                // i.DV_KEM_THEO = i.DV_KEM_THEO.filter(subItem => subItem.IS_HOAN !== 1);
            }
            return i;
        })

        return removeIsHoanItem?.filter(item => !item.DV_KEM_THEO ? true : item.DV_KEM_THEO.length > 0 ? true : false).map(item => {
            if (item?.DV_KEM_THEO?.length > 0) {
                return {
                    ...item,
                    key: item.MA_DV,
                    TEN_DICHVU: item.TEN_DV,
                    DON_GIA: item.DON_GIA || 0,
                    SO_LUONG: item.SO_LUONG,
                    MIEN_GIAM_DICH_VU: item.MIEN_GIAM || 0,
                    THANH_TIEN: item.THANH_TIEN,
                    isChildren: false,
                    PHAN_LOAI: item.PHAN_LOAI || '',
                    TRANG_THAI: item.TRANG_THAI || null,
                    children: item?.DV_KEM_THEO?.map(i => {
                        return {
                            ...i,
                            key: i.ID,
                            TEN_DICHVU: i.TEN_DV,
                            DON_GIA: i.DON_GIA || 0,
                            SO_LUONG: i.SO_LUONG,
                            MIEN_GIAM_DICH_VU: i.MIEN_GIAM || 0,
                            THANH_TIEN: i.THANH_TIEN,
                            isChildren: true,
                            PHAN_LOAI: i.PHAN_LOAI,
                            TRANG_THAI: i.TRANG_THAI || null,
                        }
                    })
                }
            }

            return {
                ...item,
                key: item.ID,
                TEN_DICHVU: item.TEN_DV,
                DON_GIA: item.DON_GIA || 0,
                SO_LUONG: item.SO_LUONG,
                MIEN_GIAM_DICH_VU: item.MIEN_GIAM || 0,
                THANH_TIEN: item.THANH_TIEN,
                isChildren: false,
                PHAN_LOAI: item.PHAN_LOAI,
                TRANG_THAI: item.TRANG_THAI || null
            }
        })
    }

    // Title
    const FormTitle = (
        <Space className={style["spaceDiv"]}>
            <div className={style["title"]}>
                <NewBackIcon onClick={() => setOpen(false)} style={{ cursor: "pointer" }} />
                <h2>{i18n.t(languageKeys.vienphi_hoan_tien_dich_vu)}</h2>
            </div>
        </Space>
    );

    return (
        <>
            <Drawer
                width={1000}
                open={open}
                onClose={() => setOpen(false)}
                title={FormTitle}
                footer={FormFooter}
                className={style['drawer']}
                closeIcon={false}
            >
                <Layout className={style['main']} >
                    <Layout.Content style={{ padding: 15, height: "calc(100vh - 168px)" }}>
                        <h2 style={{ marginBottom: 15 }}>{i18n.t(languageKeys.danh_sach_dich_vu_can_hoan_tien)}</h2>
                        <Table
                            columns={columnsDichVu}
                            dataSource={flatten(formatDataTable(refundServiceList))}
                            expandable={{
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    record?.children?.length > 0 &&
                                    (expanded ? (
                                        <CaretDownOutlined
                                            style={{ marginRight: "8px" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                return onExpand(record, e);
                                            }}
                                        />
                                    ) : (
                                        <CaretRightOutlined
                                            style={{ marginRight: "8px" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                return onExpand(record, e);
                                            }}
                                        />
                                    )),
                                checkStrictly: false
                            }}
                            selectedRows={selectedRows}
                            onSelectRows={setSelectedRows}
                            rowPropsConfig={(record) => {
                                return {
                                    disabled: !record?.TRANG_THAI ? false : !(record?.TRANG_THAI === 'CHUA_KHAM'),
                                }
                            }}
                            scroll={{ y: 'calc(100vh - 240px)', x: false }}
                        />
                    </Layout.Content>
                    <Layout.Sider
                        width={200}
                        theme="light"
                        className={style['right_sider']}
                        style={{ height: "calc(100vh - 138px)" }}
                    >
                        <h4>{i18n.t(languageKeys.field_Thanh_toan)}</h4>
                        <Divider />
                        <Space direction="vertical" className={style['money_container']}>
                            <Space>
                                <span>{i18n.t(languageKeys.field_tong_tien)}:</span>
                                <b>{formatCurrency2(unpaidDebtList?.TONG_CHI_PHI, 'đ', true)}</b>
                            </Space>
                            <Space>
                                <span>{i18n.t(languageKeys.vienphi_tong_mien_giam)}:</span>
                                <b>{formatCurrency2(unpaidDebtList?.MIEN_GIAM, 'đ', true)}</b>
                            </Space>
                            <Space>
                                <span>{i18n.t(languageKeys.field_Tong_thanh_toan)}:</span>
                                <b>{formatCurrency2((unpaidDebtList?.TONG_CHI_PHI - unpaidDebtList?.MIEN_GIAM), 'đ', true)}</b>
                            </Space>
                            <Space>
                                <span>{i18n.t(languageKeys.field_Da_TT)}:</span>
                                <b>{formatCurrency2(unpaidDebtList?.TONG_DA_THANH_TOAN || 0, 'đ', true)}</b>
                            </Space>
                            <Space>
                                <span>{i18n.t(languageKeys.field_Da_TH)}:</span>
                                <b>{formatCurrency2(unpaidDebtList?.TONG_DA_THUC_HIEN || 0, 'đ', true)}</b>
                            </Space>
                            <Space>
                                <span>{i18n.t(languageKeys.chua_thuc_hien)}:</span>
                                <b>0 đ</b>
                            </Space>
                            <Space>
                                <span>{i18n.t(languageKeys.vien_phi_Hoan_tien)}:</span>
                                <b style={{ color: '#2CB2A5' }}>{formatCurrency2(calculateRefundMoney(), 'đ', true)}</b>
                            </Space>
                        </Space>
                    </Layout.Sider>
                </Layout>
            </Drawer>
            <ModalPDF ref={pdfRef} />
        </>
    )
})

export default DrawerHT

const columnsDichVu = [
    {
        title: i18n.t(languageKeys.field_Ten_dich_vu),
        key: "TEN_DICHVU",
        dataIndex: "TEN_DICHVU",
        width: '29%',
    },
    {
        title: i18n.t(languageKeys.vien_phi_Don_gia),
        key: "DON_GIA",
        dataIndex: "DON_GIA",
        width: '18%',
        render: (_, record) => formatCurrency2(record.DON_GIA, 'đ', true)
    },
    {
        title: i18n.t(languageKeys.field_SL),
        key: "SO_LUONG",
        dataIndex: "SO_LUONG",
        width: '8%',
    },
    {
        title: i18n.t(languageKeys.da_thuc_hien),
        key: "TRANG_THAI_KHAM",
        width: '9%',
        align: 'center',
        render: (_, record) => !record?.children ? (record.TRANG_THAI === 'CHUA_KHAM' || !record.TRANG_THAI) ? <Uncheckbox /> : <Check_box /> : <></>
    },
    {
        title: i18n.t(languageKeys.field_Mien_giam),
        key: "MIEN_GIAM_DICH_VU",
        width: '18%',
        render: (_, record) => <>{formatCurrency2(record.MIEN_GIAM_DICH_VU, 'đ', true)}</>
    },
    {
        title: i18n.t(languageKeys.vien_phi_thanh_tien),
        key: "THANH_TIEN",
        width: '18%',
        render: (_, record) => <>{formatCurrency2(record.THANH_TIEN, 'đ', true)}</>
    },
];