import { Divider, Form, Layout, Row, Col, Button, Input } from "antd";
import { ComplexDatePicker, Select } from "components";
import i18n, { languageKeys } from "i18n";
import style from "./qltu.module.less";
import { useDispatch, useSelector } from "react-redux";
import { doFetchPersonnel, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { useEffect, useRef } from "react";
import { locdau } from "helpers";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import { useEventListener } from "hooks";
import { featureKeys, permissionActionKey } from "constants/keys";

const SiderTamUng = ({ form, handleFetch, handleReset }) => {

    const searchRef = useRef()
    const dispatch = useDispatch()
    const { personnel } = useSelector(getSharedData)

    useEffect(() => {
        !personnel.length && dispatch(doFetchPersonnel({ search_string: "" }))
    }, [])

    const keydownEvent = (event) => {
        if (event.key === "F4") {
            event.stopPropagation();
            event.preventDefault();
            searchRef.current?.focus();
          }
      };
      useEventListener("keydown", keydownEvent, window.document, true);

    return (
        <Layout.Sider
            width={240}
            theme="light"
            className={style['sider']}
        >
            <Form form={form} layout="vertical">
                <Form.Item name={'search_string'} style={{ marginBottom: -15 }}>
                    <Input
                        ref={searchRef}
                        placeholder={i18n.t(languageKeys.field_Tim_kiem_benh_nhan) + " (F4)"}
                        prefix={<SearchOutlined style={{ color: '#6576ff' }} />}
                        onPressEnter={() => handleFetch()}
                        allowClear
                    />
                </Form.Item>
                <Form.Item label={i18n.t(languageKeys.thoi_gian)} name={'TIME'} initialValue={[moment(), moment()]}>
                    <ComplexDatePicker />
                </Form.Item>
                <Divider dashed />
                <Form.Item label={i18n.t(languageKeys.txt_nguoi_tao_phieu)} name={'ARR_NGUOI_TAO'}>
                    <Select
                        dataSource={personnel
                            .filter((item) => item.QUYEN_MODULE.includes(`${featureKeys.quan_ly_tam_ung}.${permissionActionKey.XEM}`))
                            .map((item) => ({
                                value: item.NHANSU_ID,
                                label: item.HO + ' ' + item.TEN
                            }))
                        }
                        titleKey="label"
                        valueKey="value"
                        allowClear
                        showSearch
                        filterTreeNode={(input, option) => {
                            return (
                                locdau(option.title?.toString()).indexOf(locdau(input)) >= 0 ||
                                locdau(option?.value?.toString())?.indexOf(locdau(input)) >= 0
                            )
                        }}
                        multiple
                    />
                </Form.Item>
            </Form>
            <Row justify={'center'} gutter={4} className={style['footerBtn']}>
                <Col>
                    <Button type="primary" ghost onClick={handleReset}>
                        {i18n.t(languageKeys.button_Cai_dat_lai)}
                    </Button>
                </Col>
                <Col>
                    <Button type="primary" onClick={() => handleFetch()}>
                        {i18n.t(languageKeys.common_Ap_dung)}
                    </Button>
                </Col>
            </Row>
        </Layout.Sider>
    )
}

export default SiderTamUng