import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apis } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import { doFetchCustomerTarget, doFetchSourceClient, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { selectLoadingState } from "ducks/slices/loadingSlice";

export const useThongTinBn = ({ visible = true }) => {

    const dispatch = useDispatch()
    const userProfile = useSelector(state => state.auth.user)
    const { customerTarget: dsDoiTuong, sourceClient: dsNguonKhach } = useSelector(getSharedData)
    const loadingNguonKhach = useSelector(selectLoadingState)

    // const [dsNguonKhach, setDsNguonKhach] = useState([]);
    // const [loadingNguonKhach, setLoadingNguonKhach] = useState(false)

    useEffect(() => {
        if (visible) {
            !dsDoiTuong.length && dispatch(doFetchCustomerTarget())
            !dsNguonKhach.length && dispatch(doFetchSourceClient())
        }
        // return () =>  {
        //     setDsDoiTuong([]);
        //     setDsNguonKhach([]);
        // }
    }, [visible])

    const getNguonKhachHieuLuc = () => dispatch(doFetchSourceClient())

    // const getDoiTuongHieuLuc = async (search_string = "") => {
    //     try {
    //         let req = {
    //             BENH_VIEN_ID:userProfile.BENH_VIEN_ID,
    //             partner_code: userProfile.partner_code,
    //             search_string,
    //             page : 1,
    //             limit:100,
    //           };

    //           let res = await common_post(apis.dm_lay_ds_doi_tuong_hieu_luc,req,false)
    //           if(res && res.status === "OK"){
    //             setDsDoiTuong(res.result)
    //           }
    //     } catch (error) {
    //         HLog("danh sach doi tuong :::",error)
    //     }
    // }

    // const getNguonKhachHieuLuc = async (search_string = "") => {
    //     try {
    //         setLoadingNguonKhach(true)
    //         let req = {
    //             BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    //             partner_code: userProfile.partner_code,
    //             search_string,
    //             page: 1,
    //             limit: 10000,
    //         };

    //         let res = await common_post(apis.dm_lay_ds_nguon_khach_hieu_luc, req, false)
    //         if (res && res.status === "OK") {
    //             setDsNguonKhach(res.result)
    //             return res.result
    //         }
    //     } catch (error) {
    //         HLog("danh sach doi tuong :::", error)
    //     } finally {
    //         setLoadingNguonKhach(false)
    //     }
    // }
    return {
        // getDoiTuongHieuLuc,
        getNguonKhachHieuLuc,
        dsDoiTuong,
        dsNguonKhach,
        loadingNguonKhach
    }
}

