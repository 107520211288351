import { apis, keys } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import { fieldKhoa } from "../fieldsDanhMuc";

/**
 * API lấy danh sách khoa
 */
export const apiLayDsKhoa = async ({
  BENH_VIEN_ID = "",
  page = 1,
  search_string = "",
  limit = keys.limit,
  LOAI_KHOA = "",
  LOAI_TRU = "",
  partner_code = "",
}) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      page,
      limit,
      LOAI_KHOA,
      LOAI_TRU,
      partner_code,
    };

    const response = await common_post(apis.dm_khoa_lay_ds, requestData);

    return response;
  } catch (error) {
    HLog("Api lay danh sach khoa error", error);
  }
};

/**
 * API import excel danh sách khoa
 */
export const apiNhapDsKhoa = async ({ BENH_VIEN_ID = "", data = [], partner_code = "" }) => {
  try {
    data = data.map((item) => {
      Object.keys(item).forEach((key) => {
        item[key] = item[key].toString();
      });

      return item;
    });

    let requestData = {
      BENH_VIEN_ID,
      data,
      partner_code,
    };

    const response = await common_post(apis.dm_khoa_nhap_ds, requestData);

    return response;
  } catch (error) {
    HLog("Api import danh sach khoa error", error);
  }
};

/**
 * API thêm mới khoa
 */
export const apiThemKhoa = async ({ BENH_VIEN_ID = "", data = {}, partner_code = "" }) => {
  try {
    if (!data[fieldKhoa.ma_khoa_theo_byt]) {
      data[fieldKhoa.ma_khoa_theo_byt] = null;
    }

    let requestData = {
      ...data,
      BENH_VIEN_ID,
      partner_code,
    };

    return await common_post(apis.dm_khoa_them_moi, requestData);
  } catch (error) {
    HLog("Api them moi khoa error", error);
  }
};

/**
 * API cập nhật thông tin khoa
 */
export const apiSuaKhoa = async ({ data = {} }) => {
  try {
    if (!data[fieldKhoa.ma_khoa_theo_byt]) {
      data[fieldKhoa.ma_khoa_theo_byt] = null;
    }

    let requestData = { ...data };

    return await common_post(apis.dm_khoa_cap_nhat, requestData);
  } catch (error) {
    HLog("Api sua khoa error", error);
  }
};

/**
 * API xóa khoa
 */
export const apiXoaKhoa = async ({ partner_code = "", data = {} }) => {
  try {
    let requestData = { partner_code, data: [data] };

    const response = await common_post(apis.dm_khoa_xoa, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa khoa error", error);
  }
};

/**
 * API lấy danh sách khoa của BYT
 */
export const apiLayDsKhoaByt = async () => {
  try {
    const res = await common_post(apis.dm_khoa_ds_byt);

    if (res.status === "OK") {
      return res.result;
    } else {
      HLog("Lay ds khoa theo BYT fail", res);
      return [];
    }
  } catch (error) {
    HLog("Lay ds khoa theo BYT error", error);
  }
};

/**
 * API khoá danh sách khoa
 */
export const apiKhoaDsKhoa = async ({ partner_code = "", data = [], lock = false }) => {
  try {
    const ID = data.map((item) => item[fieldKhoa.id]);

    let requestData = { partner_code, ID, KHOA: !!lock ? 1 : 0 };

    const response = await common_post(apis.dm_khoa_lock_ds, requestData);

    return response;
  } catch (error) {
    HLog("Api khoa danh sach khoa error", error);
  }
};

/**
 * API xóa danh sách khoa
 */
export const apiXoaDsKhoa = async ({ partner_code = "", data = [] }) => {
  try {
    let requestData = { partner_code, data };

    const response = await common_post(apis.dm_khoa_xoa, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa danh sach khoa error", error);
  }
};

/**
 * API lấy danh sách khoa có hiệu lực
 */
export const apiLayDsKhoaCoHieuLuc = async ({
  BENH_VIEN_ID = "",
  page = 1,
  search_string = "",
  limit = keys.limit,
  LOAI_KHOA = "",
  LOAI_TRU = "",
  partner_code = "",
}) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      page,
      limit,
      LOAI_KHOA,
      LOAI_TRU,
      partner_code,
      KHOA: true,
    };

    const response = await common_post(apis.dm_khoa_lay_ds, requestData);

    return response;
  } catch (error) {
    HLog("Api lay danh sach khoa co hieu luc error", error);
  }
};

export const apiExportKhoa = async ({ BENH_VIEN_ID = "", partner_code = "", template = 0 }) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_khoa_export, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(!!template ? "Tai template khoa error" : "Export ds khoa error");
  }
};
