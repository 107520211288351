import { PrinterFilled, SearchOutlined } from "@ant-design/icons";
import { Row, Col, DatePicker, Form, Input, Modal, Spin, Divider, InputNumber, Button, notification, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import React, { useState, useEffect, useImperativeHandle, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { Delete, DeleteGray } from "../../../../../assets/svg";
import { ConfirmModal, DateInput, FormDrawer, Select, Table } from "../../../../../components";
import ModalPDF from "../../../../../components/Modal/ModalPDF";
import { apis } from "../../../../../constants";
import {
  common_post,
  convertDateToValue,
  currencyParserNotAlowNegativeNum,
  formatNumberToPrice,
  genIDOnlyNumber,
  handleErrorHuyPhieu,
  HLog,
  rid,
} from "../../../../../helpers";
import { useEventListener } from "../../../../../hooks";
import i18n, { languageKeys, languages } from "../../../../../i18n";
import SearchThuocXuat from "../PhieuXuat/SearchThuocXuatKho/SearchThuocXuat";
import ModalWarning from "./ModalWarning";
import styles from "./phieuNhap.module.less";
import { hooksPhieuChuyenKho } from "./servicesPhieuChuyenKho";
import * as _ from "lodash";
import { widthDrawerKeys } from "../../../../../constants/keys";
import { TooltipButton } from "components/Tooltip/CustomTooltip";

const { Item } = Form;
function ModalChuyenKho({ doAfterSuccess = () => {}, disableHuyPhieu = false, disableDuyetPhieu = false, disableInPhieu = false }, ref) {
  const drugRef = useRef();
  const pdfRef = useRef();
  const warningRef = useRef();
  const searchDrugRef = useRef();
  const confirmRef = useRef();
  const [formPhieu] = useForm();
  const [formThuoc] = useForm();
  const userProfile = useSelector((state) => state.auth.user);
  const [visible, setVisible] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedThuoc, setSelectedThuoc] = useState();
  const [drugSearchString, setDrugSearchString] = useState("");
  const [loadingButtonAdd, setLoadingButtonAdd] = useState(false);
  const [idLocal, setIdLocal] = useState("");
  const [dsThuoc, setDsThuoc] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [phieuID, setPhieuId] = useState(); // phiếu id khi xem lại phiếu
  const [print, setPrint] = useState(false); // check có in phiếu không
  const [ghiChu, setGhiChu] = useState("");
  const [isEdit, setIsEdit] = useState(false); //

  const [currentData, setCurrentData] = useState(); // data phiếu chuyển kho
  const [isDuyet, setIsDuyet] = useState(false); //
  const [soLuong, setSoLuong] = useState(0);
  const [isDaHuy, setIsDaHuy] = useState(false);
  const [loadingButtonCancel, setLoadingButtonCancel] = useState(false);

  const { dsKhoNhap, dsKhoXuat, selectedKhoNhap, seletedKhoXuat, setSelectedKhoNhap, setSelectedKhoXuat, setDsKhoNhap, setDsKhoXuat } =
    hooksPhieuChuyenKho({ visible, readOnly });

  useImperativeHandle(
    ref,
    () => ({
      openModal(item) {
        if (item) {
          //setTypePhieu(item.PHAN_LOAI)
          getChiTietPhieu(item);
        } else {
          // nếu là tạo mới thì gen id đẩy lên server
          setReadOnly(false);
          let id = genIDOnlyNumber(30, "");
          setIdLocal(id);
        }
        setVisible(true);
      },
      closeModal() {
        handleCloseModal();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // điền data vào form khi xem
  async function getChiTietPhieu(item) {
    setLoading(true);
    setPhieuId(item.ID);
    try {
      const response = await common_post(apis.lay_chi_tiet_phieu, {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        ID: item.ID,
      });
      if (response && response.status === "OK") {
        let { chi_tiet, data_thuoc } = response.result;
        setCurrentData(response.result);
        if (chi_tiet.TRANG_THAI_PHIEU === "CHO_DUYET") {
          setIsDuyet(true);
        }
        if (chi_tiet.TRANG_THAI_PHIEU === "HUY") {
          setIsDaHuy(true);
        }
        setLoading(false);
        formPhieu.setFields([
          {
            name: "KHO_THUOC_XUAT_ID",
            value: chi_tiet.TU_KHO_TEN,
          },
          {
            name: "KHO_NHAP_THUOC_ID",
            value: chi_tiet.TEN_KHO,
          },
          {
            name: "NGAY",
            value: moment(chi_tiet.NGAY + " " + chi_tiet.GIO, "YYYYMMDD HH:mm"),
          },
          {
            name: "NGUOI_TAO",
            value: chi_tiet.NGUOI_TAO,
          },
          {
            name: "MA_PHIEU",
            value: chi_tiet.MA_PHIEU,
          },
          {
            name: "LY_DO",
            value: chi_tiet.LY_DO,
          },
        ]);

        setDsThuoc(
          data_thuoc.map((item, index) => {
            return {
              ...item,
              MA_LO: item.data_LO_THUOC[0].MA_LO,
              arr_LO_THUOC: item.data_LO_THUOC,
              STT: index + 1,
              key: rid(),
            };
          })
        );
      }
    } catch (error) {}
  }

  function handleCloseModal() {
    setTimeout(() => {
      searchDrugRef.current?.setDataSource([]);
    }, 1000);

    formPhieu.resetFields();
    formThuoc.resetFields();
    setSelectedKhoNhap();
    setSelectedKhoXuat();
    setDsKhoXuat([]);
    setDsKhoNhap([]);
    setDsThuoc([]);
    setDrugSearchString("");
    setSelectedThuoc();
    setSoLuong(0);
    setGhiChu("");
    currentData && setCurrentData();
    isDuyet && setIsDuyet(false);
    isDaHuy && setIsDaHuy(false);
    setReadOnly(true);
    loadingButtonCancel && setLoadingButtonCancel(false);
    if (idLocal && dsThuoc.length > 0) {
      handleHuySlotThuoc();
    } else {
      setVisible(false);
      loading && setLoading(false);
    }
  }

  async function handleTaoPhieu(data) {
    if (dsThuoc.length === 0) {
      return;
    }
    setLoading(true);
    let time = data.NGAY;
    let NGAY = moment(time).format("YYYYMMDD");
    let GIO = moment(time).format("HH:mm");
    let dataRequest = {
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
      NHANSU_ID: userProfile.NHANSU_ID,
      ...data,
      //KHO_THUOC_ID: JSON.parse(data.KHO_THUOC_ID).ID,
      PHIEU_TAM_THOI: idLocal,
      PHAN_LOAI: "CHUYEN_KHO",
      TU_KHO_ID: seletedKhoXuat,
      KHO_THUOC_ID: selectedKhoNhap,
      GHI_CHU: ghiChu,
      NGAY: NGAY,
      GIO: GIO,
      //LY_DO : data.LY_DO ? data.LY_DO  : null,
      TONG_CHI_PHI: dsThuoc.reduce((acc, item) => acc + item.THANH_TIEN, 0),
      TONG_MIEN_GIAM: 0,
      TONG_THANH_TOAN: dsThuoc.reduce((acc, item) => acc + item.THANH_TIEN, 0),
      GIAM_TIEN: null,
      GIAM_PHAN_TRAM: null,
      // TIEN_TRA_NCC: tienTraNCC,
      arr_THUOC: dsThuoc.map((item) => ({
        ...item,
        arr_LO_THUOC: [{ LO_THUOC_ID: item.LO_THUOC_ID, SO_LUONG: item.SO_LUONG, THANH_TIEN: item.THANH_TIEN, SMART_KEY: item.SMART_KEY }],
      })),
    };
    try {
      const response = await common_post(apis.luu_phieu_nhap_thuoc, dataRequest);
      if (response && response.status === "OK") {
        if (print) {
          handlePrint(response.ID);
        }
        setIdLocal("");
        setLoading(false);
        doAfterSuccess();
      }
    } catch (error) {}
  }

  // thêm thuốc vào bảng
  async function handleAddThuocTable(value) {
    if (!selectedThuoc) {
      notification.warning({ message: i18n.t(languageKeys.duoc_chua_chon_kho_thuoc) });
      return;
    }
    if (soLuong > selectedThuoc.TON_CUOI) {
      notification.warning({ message: `${i18n.t(languageKeys.duoc_so_luong_toi_da)} ${selectedThuoc.TON_CUOI}` });
      formThuoc.setFields([
        {
          name: fieldNames.SO_LUONG,
          errors: [i18n.t(languageKeys.duoc_so_luong_lon_hon_ton_kha_dung)],
        },
      ]);
      return;
    }
    let checkTonTai = dsThuoc.some((item) => item.ID === selectedThuoc.ID && item.SMART_KEY === selectedThuoc.SMART_KEY);
    if (!isEdit && checkTonTai) {
      notification.warning({ message: i18n.t(languageKeys.duoc_thuoc_da_ton_tai) });
      return;
    }
    setLoadingButtonAdd(true);
    let urlApi = checkTonTai ? apis.cap_nhat_slot_thuoc_tam_thoi : apis.them_slot_thuoc_tam_thoi;
    try {
      const req = {
        partner_code: userProfile.partner_code,
        PHIEU_TAM_THOI: idLocal,
        KHO_THUOC_ID: seletedKhoXuat,
        LO_THUOC_ID: selectedThuoc.LO_THUOC_ID,
        THUOC_ID: selectedThuoc.Thuoc_ID,
        SO_LUONG: value.SO_LUONG,
        NHANSU_ID: userProfile.NHANSU_ID,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        THUOC_TRONG_KHO_ID: selectedThuoc.THUOC_TRONG_KHO_ID,
        SMART_KEY: selectedThuoc.SMART_KEY,
      };
      let response = await common_post(urlApi, req);
      if (response && response.status === "OK") {
        setLoadingButtonAdd(false);
        if (checkTonTai) {
          let array = dsThuoc.map((item) => {
            if (item.SMART_KEY === selectedThuoc.SMART_KEY) {
              return {
                ...item,
                SO_LUONG: value.SO_LUONG,
                THANH_TIEN: value.SO_LUONG * selectedThuoc.GIA_NHAP,
              };
            }
            return item;
          });
          setDsThuoc(array);
        } else {
          setDsThuoc(
            dsThuoc.concat({
              ...selectedThuoc,
              SO_LUONG: value.SO_LUONG,
              THANH_TIEN: value.SO_LUONG * selectedThuoc.GIA_NHAP,
              STT: dsThuoc.length + 1,
              THU_TU: dsThuoc.length + 1,
            })
          );
        }
        setSelectedThuoc();
        setDrugSearchString("");
        searchDrugRef.current?.getDataSource();
        isEdit && setIsEdit(false);
        formThuoc.resetFields();
        setSoLuong(0);
      } else if (response && response.status === "KO" && response.error_code === "003") {
        setLoadingButtonAdd(false);
        notification.warning({ message: i18n.t(languageKeys.duoc_so_luong_thuoc_khong_kha_dung) });
      }
    } catch (error) {}
  }

  // click vào bảng đều sửa thuốc
  function onEditItem(item) {
    setDrugSearchString(item.Thuoc_Ten);
    setSelectedThuoc(item);
    setIsEdit(true);
    formThuoc.setFields([
      {
        name: fieldNames.Thuoc_Code,
        value: item.Thuoc_Code,
      },
      {
        name: fieldNames.Thuoc_Dvt,
        value: item.Thuoc_Dvt,
      },
      {
        name: fieldNames.GIA_BAN,
        value: item.GIA_BAN,
      },
      {
        name: fieldNames.MA_LO,
        value: item.MA_LO,
      },
      {
        name: fieldNames.HAN_SU_DUNG,
        value: convertDateToValue(item.HAN_SU_DUNG),
      },
      {
        name: fieldNames.SO_LUONG,
        value: item.SO_LUONG,
      },
      {
        name: fieldNames.GIA_NHAP,
        value: item.GIA_NHAP,
      },
      {
        name: fieldNames.THANH_TIEN,
        value: item.THANH_TIEN,
      },
    ]);
  }

  function handleSelectThuoc(item) {
    if (selectedThuoc && (selectedThuoc.ID !== item.ID || selectedThuoc.LO_THUOC_ID !== item.LO_THUOC_ID)) {
      formThuoc.resetFields();
    }
    setSelectedThuoc(item);
    setIsEdit(true);
    formThuoc.setFields([
      {
        name: fieldNames.Thuoc_Code,
        value: item.Thuoc_Code,
      },
      {
        name: fieldNames.Thuoc_Dvt,
        value: item.Thuoc_Dvt,
      },
      {
        name: fieldNames.GIA_BAN,
        value: item.GIA_BAN,
      },
      {
        name: fieldNames.MA_LO,
        value: item.MA_LO,
      },
      {
        name: fieldNames.HAN_SU_DUNG,
        value: convertDateToValue(item.HAN_SU_DUNG),
      },
      {
        name: fieldNames.GIA_NHAP,
        value: item.GIA_NHAP,
      },
    ]);
  }

  // xóa thuốc khỏi  bảng và xóa giữ chỗ
  async function handleRemoveThuoc(record) {
    setLoadingTable(true);
    let req = {
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
      PHIEU_TAM_THOI: idLocal,
      LO_THUOC_ID: record.LO_THUOC_ID,
      THUOC_ID: record.Thuoc_ID,
      THUOC_TRONG_KHO_ID: record.THUOC_TRONG_KHO_ID,
      SMART_KEY: record.SMART_KEY,
    };
    try {
      const response = await common_post(apis.xoa_slot_thuoc_tam_thoi, req);
      if (response && response.status === "OK") {
        let newArr = dsThuoc.filter((item) => item.SMART_KEY !== record.SMART_KEY);
        setDsThuoc(newArr);
        searchDrugRef.current?.getDataSource();
      }
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
    }
  }

  function handleClearFormThuoc() {
    formThuoc.resetFields();
    setDrugSearchString("");
    setSelectedThuoc();
  }

  // hủy slot giữ chỗ khi tắt phiếu
  async function handleHuySlotThuoc() {
    setLoading(true);
    let dataRequest = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_TAM_THOI: idLocal,
    };
    let response = await common_post(apis.huy_phieu_giu_cho, dataRequest);
    if (response && response.status === "OK") {
      setLoading(false);
      setIdLocal("");
      setVisible(false);
    }
  }
  async function handleTaoIdPhieuMoi() {
    if (dsThuoc.length > 0) {
      let dataRequest = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        PHIEU_TAM_THOI: idLocal,
      };
      let response = await common_post(apis.huy_phieu_giu_cho, dataRequest);
      if (response && response.status === "OK") {
        setIdLocal(genIDOnlyNumber(30, ""));
      }
    }
  }

  // xử lý event phím tắt
  const keydownEvent = (event) => {
    const stopDefaultAction = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    // event thêm thuốc
    if (!readOnly) {
      if ((event.ctrlKey || event.metaKey) && (event.keyCode === 78 || event.key === "a")) {
        stopDefaultAction(event);
        formThuoc.submit();
      } else if ((event.ctrlKey || event.metaKey) && event.key === "d") {
        stopDefaultAction(event);
        handleClearFormThuoc();
      } else if (event.ctrlKey && event.key === "s") {
        stopDefaultAction(event);
        formPhieu.submit();
      } else if (event.ctrlKey && event.key === "l") {
        stopDefaultAction(event);
        setPrint(true);
        formPhieu.submit();
      }
    } else {
      if (event.ctrlKey && event.key === "i" && !isDaHuy) {
        stopDefaultAction(event);
        handlePrint(phieuID);
      }
    }
  };

  useEventListener("keydown", keydownEvent, window.document, visible);

  const handleFocusDrugInput = () => {
    setTimeout(() => {
      drugRef.current?.focus();
    }, 100);
  };

  // hàm clear input tìm kiếm thuốc
  const handleClearDrugInput = () => {
    setDrugSearchString("");
  };

  // hàm xử lý tìm kiếm thuốc
  const handleChangeSearchDrug = (e) => {
    if (!seletedKhoXuat) {
      return;
    }
    const { value } = e.target;
    selectedThuoc && setSelectedThuoc();
    setDrugSearchString(value);
    //searchDrugRef.current?.getDataSource(value, { ID: seletedKhoXuat }, null);
  };

  // hàm xử lý tìm kiếm thuốc
  const handleSearchDrug = (e) => {
    if (!seletedKhoXuat) {
      return;
    }
    const { value } = e.target;
    selectedThuoc && setSelectedThuoc();
    setDrugSearchString(value);
    searchDrugRef.current?.getDataSource(value, { ID: seletedKhoXuat }, null);
  };

  // hàm xử lý in phiếu
  async function handlePrint(PHIEU_THUOC_ID) {
    const dataRequest = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_THUOC_ID: PHIEU_THUOC_ID,
    };
    const api = apis.phieu_nhap_pdf;
    pdfRef.current.openModal(dataRequest, api);
  }

  const columns = [
    {
      title: i18n.t(languageKeys.field_Stt),
      dataIndex: "STT",
      key: "STT",
      width: 70,
    },
    {
      title: i18n.t(languageKeys.field_Ma_thuoc),
      dataIndex: "Thuoc_Code",
      key: "Thuoc_Code",
      width: 120,
    },
    {
      title: i18n.t(languageKeys.field_Ten_thuoc),
      dataIndex: "Thuoc_Ten",
      key: "Thuoc_Ten",
      width: 200,
    },
    {
      title: i18n.t(languageKeys.field_Don_vi),
      dataIndex: "Thuoc_Dvt",
      key: "Thuoc_Dvt",
    },
    {
      title: i18n.t(languageKeys.field_ma_lo),
      dataIndex: "MA_LO",
      key: "MA_LO",
    },
    {
      title: i18n.t(languageKeys.sl),
      dataIndex: "SO_LUONG",
      key: "SO_LUONG",
      render: (sl, record) => {
        if (!isDuyet) {
          return <div>{sl}</div>;
        } else {
          return (
            <InputNumber
              min={0}
              value={sl}
              onChange={(number) => {
                let newArr = dsThuoc.map((item) => {
                  if (item.STT === record.STT) {
                    return {
                      ...item,
                      SO_LUONG: number,
                      THANH_TIEN: number * record.GIA_NHAP,
                    };
                  }
                  return item;
                });
                setDsThuoc(newArr);
              }}
              className={styles.input}
            />
          );
        }
      },
    },

    {
      title: i18n.t(languageKeys.field_Gia_nhap),
      dataIndex: "GIA_NHAP",
      key: "GIA_NHAP",
      render: (GIA_NHAP) => formatNumberToPrice(GIA_NHAP),
    },

    {
      title: i18n.t(languageKeys.vien_phi_thanh_tien),
      dataIndex: "THANH_TIEN",
      key: "THANH_TIEN",
      render: (THANH_TIEN) => formatNumberToPrice(THANH_TIEN),
    },
  ];

  // duyệt phiếu chuyển kho
  async function handleDuyetPhieu(arrThuoc) {
    let IS_EDIT = false;
    let curentDsThuoc = currentData.data_thuoc;
    for (let i = 0; i < arrThuoc.length; i++) {
      const element = arrThuoc[i];
      if (Number(element.SO_LUONG) !== Number(curentDsThuoc[i].SO_LUONG)) {
        IS_EDIT = true;
        break;
      }
    }
    let dataRequest = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      NHANSU_ID: userProfile.NHANSU_ID,
      PHIEU_THUOC_ID: phieuID,
      data_thuoc: arrThuoc,
      IS_EDIT,
    };
    setLoading(true);
    try {
      let response = await common_post(apis.duyet_phieu_chuyen_kho, dataRequest);
      if (response && response.status === "OK") {
        handleCloseModal();
        doAfterSuccess();
        setLoading(false);
        return;
      }
      if (response && response.status === "KO") {
        setLoading(false);
        if (response.error_data.length > 0) {
          warningRef.current.openModal(response.error_data);
        } else {
          notification.error({ message: i18n.t(languageKeys.cap_nhat_that_bai) });
        }
      }
    } catch (error) {}
  }

  function hanleEditSoLuong(arr) {
    let newArr = _.groupBy(arr, "RLS_THUOC_PHIEU_ID");
    let arrThuoc = [...dsThuoc];
    for (let i = 0; i < arrThuoc.length; i++) {
      if (newArr[`${arrThuoc[i].RLS_THUOC_PHIEU_ID}`]) {
        arrThuoc[i].SO_LUONG = newArr[`${arrThuoc[i].RLS_THUOC_PHIEU_ID}`][0].SO_LUONG;
        arrThuoc[i].THANH_TIEN = newArr[`${arrThuoc[i].RLS_THUOC_PHIEU_ID}`][0].SO_LUONG * arrThuoc[i].GIA_NHAP;
      }
    }
    setDsThuoc(arrThuoc);
    handleDuyetPhieu(arrThuoc);
    warningRef.current.closeModal();
  }

  // hủy phiếu
  async function handleHuyPhieu() {
    let req = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_THUOC_ID: phieuID,
      NGUOI_HUY_ID: userProfile.NHANSU_ID,
      PHAN_LOAI: "CHUYEN_KHO",
    };
    try {
      confirmRef.current.loading();
      const res = await common_post(apis.huy_phieu_nhap_xuat, req);
      if (res && res.status === "OK") {
        confirmRef.current.close();
        doAfterSuccess();
      } else if (res && res.status === "KO") {
        confirmRef.current.close();
        notification.warning({ message: res.message });
      }
    } catch (error) {}
  }
  /// hủy duyệt phiếu
  async function handleHuyDuyetPhieu() {
    setLoadingButtonCancel(true);
    let req = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_THUOC_ID: phieuID,
      NGUOI_HUY_ID: userProfile.NHANSU_ID,
    };
    try {
      let response = await common_post(apis.api_huy_duyet_phieu_chuyen_kho, req);
      if (response) {
        if (response.status === "OK") {
          setIsDuyet(true);
          doAfterSuccess(false);
        } else {
          notification.warning({ message: handleErrorHuyPhieu(response.error_code) });
        }
      }
      setLoadingButtonCancel(false);
    } catch (error) {
      HLog("error hủy duyệt", error);
    }
  }

  return (
    <FormDrawer
      visible={visible}
      onCancel={handleCloseModal}
      width={widthDrawerKeys.large}
      centered={true}
      title={readOnly ? i18n.t(languageKeys.duoc_chi_tiet_phieu_chuyen_kho) : i18n.t(languageKeys.duoc_them_phieu_chuyen_kho)}
      // showPlusIcon={!readOnly}
      hiddenTopAction={true}
      onOk={() => formPhieu.submit()}
      footer={
        <Row justify="end" gutter={10} style={{ backgroundColor: "#FFF", zIndex: 999 }}>
          <Col>
            <Button onClick={handleCloseModal} ghost type="primary">
              {i18n.t(languageKeys.common_Thoat)}
              {" (ESC)"}
            </Button>
          </Col>
          {readOnly ? (
            // nếu là xem chi tiết phiếu
            <>
              {!isDuyet ? (
                <>
                  <Col>
                    <TooltipButton title={isDaHuy ? "" : disableHuyPhieu ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                      <Button
                        type="primary"
                        onClick={() => handleHuyDuyetPhieu()}
                        danger
                        ghost
                        disabled={disableHuyPhieu || isDaHuy}
                        loading={loadingButtonCancel}
                      >
                        {i18n.t(languageKeys.huy_duyet_phieu)}
                      </Button>
                    </TooltipButton>
                  </Col>
                  <Col>
                    <TooltipButton title={isDaHuy ? "" : disableInPhieu ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                      <Button icon={<PrinterFilled />} type="primary" onClick={() => handlePrint(phieuID)} disabled={disableInPhieu || isDaHuy}>
                        <span>
                          {i18n.t(languageKeys.field_In_phieu)}
                          {" (CTRL + I)"}
                        </span>
                      </Button>
                    </TooltipButton>
                  </Col>
                </>
              ) : (
                <>
                  <Col>
                    <TooltipButton title={isDaHuy ? "" : disableHuyPhieu ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                      <Button type="primary" onClick={() => confirmRef.current.open()} ghost danger disabled={disableHuyPhieu || isDaHuy}>
                        <span>
                          {i18n.t(languageKeys.huy_phieu)}
                          {""}
                        </span>
                      </Button>
                    </TooltipButton>
                  </Col>
                  <Col>
                    <TooltipButton title={isDaHuy ? "" : disableDuyetPhieu ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                      <Button type="primary" onClick={() => handleDuyetPhieu(dsThuoc)} loading={loading} disabled={disableDuyetPhieu || isDaHuy}>
                        <span>
                          {i18n.t(languageKeys.luu_va_duyet)} {""}
                        </span>
                      </Button>
                    </TooltipButton>
                  </Col>
                </>
              )}
            </>
          ) : (
            // Nếu là tạo mới phiếu
            <>
              <Col>
                <Tooltip title={disableInPhieu ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                  <Button
                    icon={<PrinterFilled />}
                    type="primary"
                    onClick={() => {
                      setPrint(true);
                      formPhieu.submit();
                    }}
                    loading={loading}
                    disabled={disableInPhieu}
                  >
                    <span>
                      {i18n.t(languageKeys.common_Luu_va_In)} {" (CTRL + L)"}
                    </span>
                  </Button>
                </Tooltip>
              </Col>
              <Col>
                <Button onClick={() => formPhieu.submit()} type="primary" loading={loading}>
                  {i18n.t(languageKeys.common_Luu)}
                  {" (CTRL + S)"}
                </Button>
              </Col>
            </>
          )}
        </Row>
      }
    >
      <Spin spinning={loading}>
        <div className={styles.container}>
          <Form form={formPhieu} layout="vertical" className={styles.form_1} onFinish={handleTaoPhieu}>
            <Row style={{ paddingInline: 10, marginTop: "-10px" }}>
              <Col span={5}>
                <Item
                  label={i18n.t(languageKeys.duoc_kho_xuat_thuoc)}
                  name="KHO_THUOC_XUAT_ID"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    className={styles.input}
                    dataSource={dsKhoXuat}
                    titleKey="TEN_KHO"
                    valueKey="ID"
                    disabled={readOnly}
                    onSelect={(id) => {
                      if (id === seletedKhoXuat) {
                        return;
                      } else {
                        formPhieu.setFields([{ name: "KHO_NHAP_THUOC_ID", value: "" }]);
                        setSelectedKhoXuat(id);
                        setDrugSearchString("");
                        selectedThuoc && setSelectedThuoc();
                        formThuoc.resetFields();
                        dsThuoc.length > 0 && setDsThuoc([]);
                        handleTaoIdPhieuMoi();
                      }
                    }}
                  />
                </Item>
              </Col>

              <Col span={5}>
                <Item
                  label={i18n.t(languageKeys.duoc_kho_nhap_thuoc)}
                  name="KHO_NHAP_THUOC_ID"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    className={styles.input}
                    dataSource={dsKhoNhap}
                    titleKey="TEN_KHO"
                    valueKey="ID"
                    disabled={readOnly}
                    onSelect={setSelectedKhoNhap}
                    onFocus={(bool) => {
                      bool && !seletedKhoXuat && notification.warning({ message: i18n.t(languageKeys.duoc_chua_chon_kho_xuat) });
                    }}
                  />
                </Item>
              </Col>

              <Col span={5}>
                <Item
                  label={i18n.t(languageKeys.field_Ngay_tao)}
                  name="NGAY"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  initialValue={moment()}
                >
                  <DatePicker
                    className={styles.input}
                    style={{ width: "100%" }}
                    defaultValue={moment()}
                    showTime={{ format: "HH:mm" }}
                    format="DD/MM/YYYY - HH:mm"
                    disabled
                  />
                </Item>
              </Col>

              <Col span={5}>
                <Item label={i18n.t(languageKeys.nguoi_tao)} name={"NGUOI_TAO"} initialValue={userProfile.HO + " " + userProfile.TEN}>
                  <Input className={styles.input} readOnly value={userProfile.HO + " " + userProfile.TEN} />
                </Item>
              </Col>

              <Col span={4}>
                <Item label={i18n.t(languageKeys.ma_phieu)} name="MA_PHIEU">
                  <Input className={styles.input} placeholder={i18n.t(languageKeys.placeholder_Tao_tu_dong)} readOnly={readOnly} />
                </Item>
              </Col>
            </Row>
          </Form>
          <Divider style={{ marginTop: "15px", marginBottom: "10px" }} />

          {!readOnly && (
            <Form form={formThuoc} layout="vertical" onFinish={handleAddThuocTable} style={{ marginTop: "0px" }}>
              <Row style={{ marginTop: "15px" }} className={styles.infoDrug} align="bottom">
                <Col span={10} style={{ paddingBottom: "5px" }}>
                  <SearchThuocXuat
                    key={seletedKhoXuat}
                    ref={searchDrugRef}
                    onAddDrug={handleSelectThuoc}
                    focusInput={handleFocusDrugInput}
                    clearInput={handleClearDrugInput}
                    kho_thuoc={seletedKhoXuat && { ID: seletedKhoXuat }}
                    visibleModal={visible}
                    // NCC_THUOC_ID={ncc}
                  >
                    <Input
                      value={selectedThuoc ? selectedThuoc.Thuoc_Ten : drugSearchString}
                      onChange={handleChangeSearchDrug}
                      onPressEnter={handleSearchDrug}
                      prefix={<SearchOutlined className="blue-txt" />}
                      placeholder={i18n.t(languageKeys.common_Tim_kiem)}
                      ref={drugRef}
                      onFocus={() => {
                        if (!seletedKhoXuat) {
                          formPhieu.setFields([
                            {
                              name: "KHO_THUOC_ID",
                              errors: [i18n.t(languageKeys.txt_chon_kho)],
                            },
                          ]);
                          notification.warning({ message: i18n.t(languageKeys.txt_chon_kho) });
                        }
                      }}
                      allowClear
                    />
                  </SearchThuocXuat>
                </Col>
                <Col span={2}>
                  <Item label={i18n.t(languageKeys.field_Ma_thuoc)} name={fieldNames.Thuoc_Code}>
                    <Input className={styles.input} readOnly />
                  </Item>
                </Col>
                <Col span={2}>
                  <Item label={i18n.t(languageKeys.field_Don_vi)} name={fieldNames.Thuoc_Dvt}>
                    <Input className={styles.input} readOnly />
                  </Item>
                </Col>
                <Col span={3}>
                  <Item label={i18n.t(languageKeys.field_Gia_ban)} name={fieldNames.GIA_BAN}>
                    <Input className={styles.input} readOnly />
                  </Item>
                </Col>
                <Col span={2}>
                  <Item label={i18n.t(languageKeys.field_ma_lo)} name={fieldNames.MA_LO}>
                    <Input className={styles.input} readOnly />
                  </Item>
                </Col>

                <Col span={5}>
                  <DateInput name={fieldNames.HAN_SU_DUNG} label={i18n.t(languageKeys.field_han_su_dung)} form={formThuoc} readOnly />
                </Col>
                <Col span={5}>
                  <Item
                    label={i18n.t(languageKeys.field_So_luong)}
                    name={fieldNames.SO_LUONG}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      className={styles.input}
                      style={{ width: "100%", borderColor: selectedThuoc && soLuong > selectedThuoc.TON_CUOI ? "#ff4d4f" : "transparent" }}
                      min={0}
                      //max={selectedThuoc ? Number(selectedThuoc.TON_CUOI) : Number.MAX_SAFE_INTEGER}
                      onChange={(value) => {
                        setSoLuong(value);
                        if (selectedThuoc) {
                          // setThanhTien(value * selectedThuoc.GIA_NHAP)
                          formThuoc.setFields([
                            {
                              name: fieldNames.THANH_TIEN,
                              value: value * selectedThuoc.GIA_NHAP,
                            },
                          ]);
                        }
                      }}
                    />
                  </Item>
                </Col>
                <Col span={5}>
                  <Item label={i18n.t(languageKeys.field_Gia_nhap)} name={fieldNames.GIA_NHAP}>
                    <InputNumber
                      className={styles.input}
                      style={{ width: "100%" }}
                      readOnly
                      formatter={formatNumberToPrice}
                      parser={currencyParserNotAlowNegativeNum}
                    />
                  </Item>
                </Col>

                <Col span={5}>
                  <Item label={i18n.t(languageKeys.vien_phi_thanh_tien)} name={fieldNames.THANH_TIEN}>
                    <InputNumber
                      style={{ width: "100%" }}
                      readOnly
                      formatter={formatNumberToPrice}
                      parser={currencyParserNotAlowNegativeNum}
                      className={styles.input}
                    />
                  </Item>
                </Col>
                <Col span={5}>
                  <Item label={i18n.t(languageKeys.field_Ghi_chu)} name="GHI_CHU" style={{ marginBottom: 0 }}>
                    <Input className={styles.input} />
                  </Item>
                </Col>
                <Col span={4} style={{ paddingBottom: "5px" }}>
                  <Button type="primary" style={{ width: "100%" }} onClick={() => formThuoc.submit()} loading={loadingButtonAdd}>
                    {i18n.t(languageKeys.common_Them)} {`(Ctrl +A)`}
                  </Button>
                </Col>
                {/* <Col span={4}>
                                <Button type="ghost" danger style={{ width: "100%" }} onClick={handleClearFormThuoc}>
                                    Xóa {`(Ctrl + D)`}
                                </Button>
                            </Col> */}
              </Row>
            </Form>
          )}

          <Row style={{ flex: 1 }}>
            <Col span={24} style={{ backgroundColor: "#F5F7FB", padding: "10px 15px 0px 20px" }}>
              <h4>{i18n.t(languageKeys.field_Danh_sach_thuoc)}</h4>
              <Table
                dataSource={dsThuoc}
                loading={loadingTable}
                columns={
                  readOnly
                    ? columns
                    : columns.concat([
                        {
                          title: "",
                          key: "",
                          dataIndex: "",
                          // fixed: "right",
                          width: "60px",
                          render: (_, record) => (
                            <Tooltip placement="topLeft" title={i18n.t(languageKeys.duoc_xoa_thuoc)}>
                              <Delete
                                className={styles.del_icon}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveThuoc(record);
                                }}
                              />
                            </Tooltip>
                          ),
                        },
                      ])
                }
                onClickRow={(item) => !readOnly && onEditItem(item)}
                scroll={{ y: !readOnly ? "calc(100vh - 460px)" : "calc(100vh - 260px)" }}
              />
            </Col>

            {/* <Col span={5}>
                                    <div className={readOnly ? styles.payment_readOnly : styles.payment}>
                                        <h4>{i18n.t(languageKeys.field_Thanh_toan)}</h4>
                                        <Divider style={{ marginTop: "-5px" }} />

                                        <Row justify="space-between" style={{ marginTop: "10px" }}>
                                            <Col><h5>{i18n.t(languageKeys.field_Tong_chi_phi)}</h5></Col>
                                        </Row>
                                        <Row justify="space-between" style={{ marginTop: "10px" }}>

                                            <Col><h5 className={styles.money_special}>{formatNumberToPrice(tinhTienTraNCC())}</h5></Col>
                                        </Row>

                                        <Divider />
                                        <h5>{i18n.t(languageKeys.field_Ghi_chu)}</h5>
                                        <Input.TextArea rows={5} width={"100%"} onChange={e => setGhiChu(e.target.value)} value={ghiChu} readOnly={readOnly} />
                                    </div>
                                </Col> */}
          </Row>
        </div>
      </Spin>
      <ModalPDF ref={pdfRef} />
      <ModalWarning ref={warningRef} ten_kho={formPhieu.getFieldValue("KHO_THUOC_XUAT_ID")} handleDuyet={hanleEditSoLuong} />
      <ConfirmModal
        danger={true}
        ref={confirmRef}
        content={i18n.t(languageKeys.confirm_cancel_ticket)}
        onOk={handleHuyPhieu}
        okText={i18n.t(languageKeys.title_Xac_nhan)}
      />
    </FormDrawer>
  );
}

export default React.memo(React.forwardRef(ModalChuyenKho));

const fieldNames = {
  MA_PHIEU: "MA_PHIEU",
  NGAY: "NGAY",
  GIO: "GIO",
  NCC_THUOC_ID: "",
  GHI_CHU: "GHI_CHU",
  Thuoc_Code: "Thuoc_Code",
  Thuoc_Dvt: "Thuoc_Dvt",
  GIA_BAN: "GIA_BAN",
  SO_LUONG: "SO_LUONG",
  GIA_NHAP: "GIA_NHAP",
  THANH_TIEN: "THANH_TIEN",
  MA_LO: "MA_LO",
  HAN_SU_DUNG: "HAN_SU_DUNG",
  VAT_PHAN_TRAM: "VAT_PHAN_TRAM",
  VAT_TIEN: "VAT_TIEN",
  VAT_THANH_TIEN: "VAT_THANH_TIEN",
  GIA_CO_VAT: "GIA_CO_VAT",
  LY_DO: "LY_DO",
};
