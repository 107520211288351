import { CaretRightOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Col, Collapse, DatePicker, Input, InputNumber, Row, Space, TimePicker, notification } from "antd";
import i18n, { languageKeys } from "../../../../i18n";
import styles from "../vattu.module.less";
import ChiDinhVatTu from "../ChiDinhVatTu";
import { ConfirmModal, ModalPDF } from "components";
import ChiDinhDvGoi from "../ChiDinhDvGoi";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getLieuTrinhData, setPlanData } from "ducks/slices/GoiDichVu/GoiVatTu.slice";
import { useEffect, useRef, useState } from "react";
import { CopyIcon, Delete } from "assets/svg";
import { isNull, isString } from "lodash";
import { apis } from "../../../../constants";

const KeHoachSuDung = ({ readOnly, allowPrint, bundleName }) => {

  const dupRef = useRef()
  const pdfRef = useRef()

  const [dupNum, setDupNum] = useState(1)
  const [collapseKey, setCollapseKey] = useState([0])

  const dispatch = useDispatch()
  const { planData, servicePlanList, supplyPlanList } = useSelector(getLieuTrinhData);

  const hasCompleted = (item) => item.DANH_SACH_DICH_VU.some(i => i.TRANG_THAI === 'KHAM_XONG')
  const noEdit = (item) => readOnly || hasCompleted(item)


  useEffect(() => {
    (collapseKey.length !== planData.length) && setCollapseKey([...Array(planData.length).keys()])
  }, [planData])


  const handleAdd = () => {
    dispatch(setPlanData([...planData, {
      DANH_SACH_DICH_VU: [],
      DANH_SACH_VAT_TU: [{}]
    }]))
  }


  const handleChange = (newArr, index, key) => {
    dispatch(setPlanData(planData.map((item, num) =>
      num === index
        ? {
          ...item,
          [key]: isString(newArr) || isNull(newArr)
            ? newArr
            : Array.isArray(newArr) ? newArr : newArr(),
        }
        : item
    )))
  }


  const handleDuplicate = (data) => {
    //
    const calcPlanData = (type, key) => {
      return planData.flatMap(i => i[type]).filter(i => i.SO_LUONG).reduce((acc, cur) => {
        const found = acc.find(val => val[key] === cur[key])
        if (found) {
          found.SO_LUONG += Number(cur?.SO_LUONG)
        }
        else {
          acc.push({ ...cur, SO_LUONG: Number(cur?.SO_LUONG) })
        }
        return acc
      }, [])
    }
    //
    const getQuantity = (arr, key, item, specialKey) => {
      return arr.find(i => i[key] === item[specialKey || key])?.SO_LUONG
    }
    //
    const remainService = (item) =>
      getQuantity(servicePlanList, "DV_ID", item, item.DICH_VU_GOC ? "DICH_VU_GOC" : undefined)
      - getQuantity(calcPlanData("DANH_SACH_DICH_VU", "_id"), "DV_ID", item)
    //
    const remainSupply = (item) =>
      getQuantity(supplyPlanList, "VAT_TU_TRONG_KHO_ID", item)
      - getQuantity(calcPlanData("DANH_SACH_VAT_TU", "VAT_TU_TRONG_KHO_ID"), "VAT_TU_TRONG_KHO_ID", item)
    //
    let pushArr = []
    for (let i = 1; i <= dupNum; i++) {
      let arrService = []
      let arrSupply = []
      data.DANH_SACH_DICH_VU.forEach(item => {
        if ((item.SO_LUONG * i) <= remainService(item)) {
          arrService.push(item)
        } else {
          if (remainService(item) - (item.SO_LUONG * i) >= 0) {
            arrService.push({ ...item, SO_LUONG: remainService(item) - (item.SO_LUONG * i) })
          }
        }
      })
      data.DANH_SACH_VAT_TU.forEach(item => {
        if ((item.SO_LUONG * i) <= remainSupply(item)) {
          arrSupply.push(item)
        } else {
          if (remainSupply(item) - (item.SO_LUONG * i) >= 0) {
            arrSupply.push({ ...item, SO_LUONG: remainSupply(item) - (item.SO_LUONG * i) })
          }
        }
      })
      pushArr.push({
        DANH_SACH_DICH_VU: arrService,
        DANH_SACH_VAT_TU: [...arrSupply, {}]
      })
    }
    pushArr = pushArr.filter(i => i.DANH_SACH_DICH_VU[0])
    //
    dispatch(setPlanData([...planData, ...pushArr]))
    dupRef.current?.close()
    setDupNum(1)
  }


  const handlePrintMedRecord = (item) => {
    const dv = item?.DANH_SACH_DICH_VU?.find(i => i.PHAN_LOAI === "THU_THUAT")
    if (dv) {
      pdfRef.current.openModal(
        {
          PHIEU_ID: dv.PHIEU_ID,
          LUOT_DIEU_TRI_ID: dv.LUOT_DIEU_TRI_ID,
          TRANG_THAI: "DA_TRA_KET_QUA",
          PHONG_ID: dv.PHONG_ID,
          PHONG_DIEU_TRI_ID: dv.PHONG_THUC_HIEN_ID,
          LICH_KHAM_ID: dv.LICH_KHAM_ID,
          DICH_VU_ID: dv.DICH_VU_ID,
          VAT_TU_CHUA_SU_DUNG: item.DANH_SACH_VAT_TU,
          TEN_GOI_DV: bundleName,
        },
        apis.layDataPhieuTheoDoiTruyenDich
      )
    } else {
      notification.error({ message: i18n.t(languageKeys.ko_co_dv_thu_thuat), placement: "bottomLeft" })
    }
  }


  return (
    <>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        activeKey={collapseKey}
        onChange={setCollapseKey}
      >
        {planData.map((item, index) => (
          <Collapse.Panel
            header={`${i18n.t(languageKeys.common_ngay)} ${index + 1} :`}
            key={index}
            extra={(
              <Space className={styles.extra} onClick={(e) => e.stopPropagation()}>
                <span>{i18n.t(languageKeys.ngay_dat_hen)}:</span>
                <DatePicker
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  format={"DD/MM/YYYY"}
                  disabledDate={(current) => current && current < moment().startOf('day')} //endOf
                  inputReadOnly
                  value={item.NGAY_DAT_HEN ? moment(item.NGAY_DAT_HEN, "YYYYMMDD") : undefined}
                  onChange={(data) => handleChange(data ? moment(data).format("YYYYMMDD") : null, index, "NGAY_DAT_HEN")}
                  status={item.NGAY_DAT_HEN ? "" : "error"}
                  disabled={noEdit(item)}
                />
                <span>{i18n.t(languageKeys.gio_dat_hen)}:</span>
                <TimePicker.RangePicker
                  placeholder={[i18n.t(languageKeys.tu), i18n.t(languageKeys.field_Den)]}
                  format={"HH:mm"}
                  inputReadOnly
                  value={item.GIO_DAT_HEN ? item.GIO_DAT_HEN : undefined}
                  onChange={(data) => handleChange(data, index, "GIO_DAT_HEN")}
                  disabled={noEdit(item)}
                />
                {!readOnly && (
                  <>
                    <Button
                      type="primary"
                      icon={<CopyIcon className={styles.copy} />}
                      onClick={() => dupRef.current?.open(item)}
                    >
                      {i18n.t(languageKeys.nhan_ban)}
                    </Button>

                    <Button
                      className={styles.delete}
                      icon={<Delete />}
                      danger ghost
                      onClick={() => dispatch(setPlanData(planData.filter((_, num) => num !== index)))}
                      disabled={noEdit(item)}
                    >
                      {i18n.t(languageKeys.xoa)}
                    </Button>

                    <Button
                      type="primary"
                      onClick={() => handlePrintMedRecord(item)}
                      disabled={!allowPrint || !item.LICH_KHAM_ID}
                    >
                      {i18n.t(languageKeys.in_benh_trinh)}
                    </Button>
                  </>
                )}
              </Space>
            )}
          >

            <ChiDinhDvGoi
              dataSource={item.DANH_SACH_DICH_VU}
              setDataSource={(data) => handleChange(data, index, "DANH_SACH_DICH_VU")}
              planMode
              selectedTab={"KE_HOACH_SD"}
              index={index}
              readOnly={noEdit(item)}
              hideHeader
            />

            <Row className={styles.usageDay}>
              <Col span={24}>
                <div className={styles.flex}>
                  <label>{i18n.t(languageKeys.ghi_chu)}</label>
                  <Input
                    placeholder={i18n.t(languageKeys.common_Nhap)}
                    defaultValue={item.GHI_CHU}
                    readOnly={noEdit(item)}
                    onBlur={(e) => handleChange(e.target.value, index, "GHI_CHU")}
                  />
                </div>
              </Col>
            </Row>

            <h4>{i18n.t(languageKeys.chi_dinh_thuoc_vat_tu_trong_goi)}</h4>

            <ChiDinhVatTu
              supplyList={item.DANH_SACH_VAT_TU}
              setSupplyList={(data) => handleChange(data, index, "DANH_SACH_VAT_TU")}
              planMode
              index={index}
              readOnly={noEdit(item)}
            />

          </Collapse.Panel>
        ))}
      </Collapse>

      {!readOnly && (
        <Button type='link' className={styles.addBtn} icon={<PlusCircleFilled />} onClick={handleAdd}>
          {i18n.t(languageKeys.them)} {i18n.t(languageKeys.ke_hoach_sd)}
        </Button>
      )}

      <ConfirmModal
        ref={dupRef}
        title={i18n.t(languageKeys.nhan_ban_ke_hoach)}
        content={""}
        cancelText={i18n.t(languageKeys.huy)}
        width={332}
        onOk={handleDuplicate}
      >
        <div className={styles.modal}>
          <p>{i18n.t(languageKeys.vui_long_nhap_sl_nhan_ban)}</p>
          <div className={styles.modalChild}>
            <p>{i18n.t(languageKeys.sl_nhan_ban)}</p>
            <InputNumber
              placeholder={i18n.t(languageKeys.common_Nhap)}
              value={dupNum}
              onChange={setDupNum}
              min={1}
            />
          </div>
        </div>
      </ConfirmModal>

      <ModalPDF ref={pdfRef} />

    </>
  )
}

export default KeHoachSuDung;