import { CaretRightOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, DatePicker, Form, Input, InputNumber, Row, Space } from "antd";
import i18n, { languageKeys } from "../../../../i18n";
import styles from "../vattu.module.less";
import ChiDinhVatTu from "../ChiDinhVatTu";
import { Select } from "components";
import { CopyIcon } from "assets/svg";
import ChiDinhDvGoi from "../ChiDinhDvGoi";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { calcTotal, getFullName, locdau } from "helpers";
import { getChiTietGoiLieuTrinh, getDsGoiLieuTrinh, getLieuTrinhData, setServiceInitList, setSupplyInitList } from "ducks/slices/GoiDichVu/GoiVatTu.slice";
import { useEffect, useState } from "react";
import { selectLoadingState } from "ducks/slices/loadingSlice";

const PhacDoKhoiTao = ({ form, readOnly }) => {

  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state.auth.user);
  const { servicePackTypeList } = useSelector((state) => state.servicePacket.ServicePackType);
  const { dsDeXuat, serviceInitList, supplyInitList, dsGoiLieuTrinh } = useSelector(getLieuTrinhData);
  const loadingSelect = useSelector(selectLoadingState)

  const [arrPropose, setArrPropose] = useState([])

  useEffect(() => {
    !dsDeXuat[0] && !dsGoiLieuTrinh[0] && dispatch(getDsGoiLieuTrinh())
  }, [])

  const handleApplyPropose = () => {
    if (!arrPropose[0]) return;
    //
    let serviceList = []
    let supplyList = []
    //
    if (dsDeXuat[0]) {
      // Có đề xuất
      const ProposeList = dsDeXuat.filter(i => arrPropose.includes(i.ID))
      ProposeList.forEach(item => {
        serviceList = [
          ...serviceList,
          ...item.DANH_SACH_DICH_VU?.map(ds => ({
            ...ds,
            BAC_SI_DE_XUAT: item.BAC_SI_DE_XUAT,
            BAC_SI_DE_XUAT_ID: item.BAC_SI_DE_XUAT_ID,
            //
            HO_TEN_NGUOI_TAO: `${userProfile?.HO} ${userProfile?.TEN}`,
            NGAY_TAO: moment().format('YYYYMMDD'),
            GIO_TAO: moment().format('H:mm'),
            HO_TEN_NGUOI_CAP_NHAT: null,
            NGAY_CAP_NHAT: null,
            GIO_CAP_NHAT: null,
            //
            DV_KEM_THEO: ds.DV_KEM_THEO?.map(dvkt => ({
              ...dvkt,
              HO_TEN_NGUOI_TAO: `${userProfile?.HO} ${userProfile?.TEN}`,
              NGAY_TAO: moment().format('YYYYMMDD'),
              GIO_TAO: moment().format('H:mm'),
              HO_TEN_NGUOI_CAP_NHAT: null,
              NGAY_CAP_NHAT: null,
              GIO_CAP_NHAT: null,
            }))
          }))
        ]
        supplyList = [
          ...supplyList,
          ...item.DANH_SACH_VAT_TU?.map(ds => ({
            ...ds,
            BAC_SI_DE_XUAT: item.BAC_SI_DE_XUAT,
            BAC_SI_DE_XUAT_ID: item.BAC_SI_DE_XUAT_ID,
          })),
        ]
      })
      dispatch(setServiceInitList(serviceList))
      dispatch(setSupplyInitList([...supplyList, {}]))
      if (dsDeXuat.length === 1) {
        form.setFields([
          { name: "SO_BUOI_SU_DUNG", value: ProposeList[0]?.SO_BUOI_SU_DUNG },
          { name: "GHI_CHU_GOI", value: ProposeList[0]?.GHI_CHU }
        ])
      }
    } else {
      // Ko có đề xuất
      dispatch(getChiTietGoiLieuTrinh({
        ID: arrPropose[0],
        SBSD: form.getFieldValue("SO_BUOI_SU_DUNG") ?? 1,
      }))
    }
  }

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      defaultActiveKey={["DICH_VU", "THONG_TIN"]}
    >

      <Collapse.Panel
        header={i18n.t(languageKeys.dv_sd_trong_goi)}
        key="DICH_VU"
        extra={!readOnly && (
          <Space className={styles.extra} onClick={(e) => e.stopPropagation()}>
            {dsDeXuat[0]
              ? (
                <>
                  <span>{i18n.t(languageKeys.bac_si_de_xuat)}:</span>
                  <Select
                    dataSource={dsDeXuat}
                    valueKey='ID'
                    titleKey='BAC_SI_DE_XUAT'
                    showSearch
                    filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                    multiple
                    value={arrPropose}
                    onChange={setArrPropose}
                  />
                </>
              )
              : (
                <>
                  <span>{i18n.t(languageKeys.goi_co_san)}:</span>
                  <Select
                    dataSource={dsGoiLieuTrinh}
                    valueKey='ID'
                    titleKey='TEN_GOI_DV'
                    filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                    showSearch
                    loading={loadingSelect}
                    value={arrPropose[0]}
                    onChange={(val) => setArrPropose([val])}
                  />
                </>
              )
            }
            <Button
              type="primary"
              icon={<CopyIcon className={styles.copy} />}
              onClick={handleApplyPropose}
            // disabled={!persmission.SUA || ((actionType === ActionType.edit) && !!packetServiceDetail?.BENH_NHAN_ID)}
            >
              {i18n.t(languageKeys.common_Ap_dung)}
            </Button>
          </Space>
        )
        }
      >
        <ChiDinhDvGoi
          selectedTab={"PHAC_DO_KHOI_TAO"}
          dataSource={serviceInitList}
          setDataSource={(data) => dispatch(setServiceInitList(data))}
          readOnly={readOnly}
          formBundle={form}
        />
        <Form form={form} className={styles.usageDay}>
          <Row>
            <Col span={24}>
              <Form.Item label={i18n.t(languageKeys.ghi_chu)} name={"GHI_CHU_GOI"}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <h4>{i18n.t(languageKeys.chi_dinh_thuoc_vat_tu_trong_goi)}</h4>
        <ChiDinhVatTu
          supplyList={supplyInitList}
          setSupplyList={(data) => dispatch(setSupplyInitList(data))}
          readOnly={readOnly}
        />
      </Collapse.Panel >

      <Collapse.Panel header={i18n.t(languageKeys.thong_tin_goi_lieu_trinh)} key="THONG_TIN">
        <Form form={form} layout="vertical">
          <div className={styles["wrapper"]}>
            <Row gutter={[10, 0]}>

              <Col flex={"20%"}>
                <Form.Item label={i18n.t(languageKeys.ma_goi)} name={"MA_GOI_DV"}>
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col flex={"40%"}>
                <Form.Item
                  name={"TEN_GOI_DV"}
                  label={i18n.t(languageKeys.ten_goi_lieu_trinh)}
                  rules={[{
                    required: true,
                    whitespace: true,
                    message: "",
                  }]}
                >
                  <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} />
                </Form.Item>
              </Col>

              <Col flex={"20%"}>
                <Form.Item
                  name={"LOAI_GOI_DV_ID"}
                  label={i18n.t(languageKeys.loai_goi)}
                  rules={[{ required: true, message: "" }]}
                >
                  <Select
                    dataSource={servicePackTypeList.filter(i => !i.KHOA)}
                    valueKey="ID"
                    titleKey="TEN_LOAI_GOI"
                    disabled={readOnly}
                  />
                </Form.Item>
              </Col>

              <Col flex={"20%"}>
                <label style={{ color: "#2c3782" }}>{i18n.t(languageKeys.gia_goi_dich_vu)}</label>
                <InputNumber
                  value={calcTotal(serviceInitList, "TONG_TIEN")}
                  style={{ marginTop: 8 }}
                  readOnly
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Col>

              <Col flex={"20%"}>
                <Form.Item
                  name={"NGAY_HET_HAN"}
                  label={i18n.t(languageKeys.field_han_su_dung)}
                  rules={[{ required: true, message: "" }]}
                >
                  <DatePicker
                    className={styles["datepicker"]}
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    format={"DD/MM/YYYY"}
                    disabledDate={(current) => current && current < moment().endOf('day')}
                    inputReadOnly
                    disabled={readOnly}
                  />
                </Form.Item>
              </Col>

              <Col flex={"20%"}>
                <Form.Item label={i18n.t(languageKeys.so_ngay_su_dung)} name={"NGAY_SU_DUNG"}>
                  <InputNumber readOnly />
                </Form.Item>
              </Col>

              <Col flex={"20%"}>
                <Form.Item label={i18n.t(languageKeys.ngay_kich_hoat)} name={"NGAY_HIEU_LUC"}>
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col flex={"20%"}>
                <Form.Item name={"NGAY_TAO"} label={i18n.t(languageKeys.ngay_tao)} initialValue={moment().format("DD/MM/YYYY")}>
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col flex={"20%"}>
                <Form.Item name={"TEN_NGUOI_TAO"} label={i18n.t(languageKeys.nguoi_tao)} initialValue={getFullName(userProfile)}>
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name={"MO_TA"} label={i18n.t(languageKeys.mo_ta)}>
                  <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name={"GHI_CHU"} label={i18n.t(languageKeys.ghi_chu)}>
                  <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} />
                </Form.Item>
              </Col>

            </Row>
          </div>
        </Form>
      </Collapse.Panel>

    </Collapse >
  )
}

export default PhacDoKhoiTao;