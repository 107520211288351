import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Col, Dropdown, Input, Row } from "antd";
import style from "./isp.module.less";
import i18n, { languageKeys } from "../../../../i18n";
import { useRef, useState } from "react";
import { Table } from "../../../../components";
import { apiTimKiemBenhNhan } from "../../apisTiepDon";
import { useSelector } from "react-redux";
import { getSexFromString, rid } from "../../../../helpers";
import { columnsTimKiemBenhNhan } from "../../columnsTiepDon";
import { useEventListener } from "../../../../hooks";
import classNames from "classnames";
import _ from "lodash";
import { useCallback } from "react";
import moment from "moment";

const InputSearchPatient = ({ onSelect = () => {}, visibleConfig = false, onBlurInput = () => {}, isRecep = true, showClearBtn = false, onClearFunction = () => {} }) => {
  const contentTableRef = useRef();
  const [visible, setVisible] = useState(false);
  const userProfile = useSelector((state) => state.auth.user);
  const [dataSource, setDataSource] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [searchString, setSearchString] = useState("");
  const inputRef = useRef();
  const [pointingIndex, setPointingIndex] = useState(-1);

  const layDsBenhNhan = async (value = "", type = "SEARCH") => {
    setLoadingList(true);
    const res = await apiTimKiemBenhNhan({
      search_string: value,
      partner_code: userProfile.partner_code,
      limit: 20,
    });

    if (!!res) {
      const data = res.map((item) => ({
        ...item,
        key: rid(),
      }));
      setDataSource(data);
      if (!visible) setVisible(true);

      if (!!contentTableRef.current) {
        contentTableRef.current.scrollTop = 0;
      }

      if (data.length > 0 && type === "BARCODE") {
        onSelect(data[0]);
        setVisible(false);
        setSearchString("");
        setPointingIndex(-1);
        contentTableRef.current = undefined;
      }
    }

    setLoadingList(false);
  };

  const handleClickRow = (val) => {
    setVisible(false);
    setSearchString("");
    onSelect(val);
    setPointingIndex(-1);
    contentTableRef.current = undefined;
  };

  const keydownEvent = (event) => {
    if (event.key === "F4") {
      event.stopPropagation();
      event.preventDefault();
      inputRef.current?.focus();
      setVisible(true);
      layDsBenhNhan();
    }
  };
  useEventListener("keydown", keydownEvent, window.document, visibleConfig);

  if (!contentTableRef.current) {
    contentTableRef.current = document.querySelector(".input-search-patient-table .ant-table-body");
  }

  const handleClearInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClearFunction();
  }

  return (
    <Dropdown
      trigger="click"
      placement="bottom"
      visible={visible}
      overlayClassName={style["dropdown"]}
      onVisibleChange={(bool) => {
        setVisible(bool);
        if (!!bool) layDsBenhNhan();
        else {
          setSearchString("");
          setPointingIndex(-1);
        }
      }}
      overlay={
        <>
          <Row className={style["head"]}>
            <Col>
              <Row>
                <h3>{i18n.t(languageKeys.field_Ket_qua_tim_kiem)}</h3>
                <div className={style["total-num"]}>{dataSource.length}</div>
              </Row>
            </Col>

            <Col></Col>
          </Row>

          <Table
            className={classNames(style["table"], "input-search-patient-table")}
            columns={isRecep 
              ? columnsTimKiemBenhNhan 
              : columnsTimKiemBenhNhan.filter(i => !i.remove)
            }
            dataSource={dataSource}
            loading={loadingList}
            scroll={{ y: "35vh", scrollToFirstRowOnChange: false }}
            onClickRow={handleClickRow}
            pointingRow
            pointingIndex={pointingIndex}
            pointingRowClassName="input-search-patient-selected-row"
          />
        </>
      }
    >
      <div
        onClick={() => {
          if (!visible) {
            setVisible(true);

            if (!!contentTableRef.current) {
              contentTableRef.current.scrollTop = 0;
            }
          }
        }}
      >
        <Input
          placeholder={i18n.t(languageKeys.field_Tim_kiem_benh_nhan) + " (F4)"}
          className={`${style["input"]} ${style["input-search"]}`}
          prefix={<SearchOutlined className={style["icon"]} />}
          value={searchString}
          allowClear={true}
          onChange={(e) => {
            if (pointingIndex > -1) setPointingIndex(-1);
            const { value } = e.target;
            // searchBenhNhan(value);
            setSearchString(value);
            if (!visible) {
              setVisible(true);

              if (!!contentTableRef.current) {
                contentTableRef.current.scrollTop = 0;
              }
            }
          }}
          onPressEnter={(e) => {
            // e.preventDefault();
            if (pointingIndex === -1) {
              return layDsBenhNhan(e.target.value);
            } else {
              handleClickRow(dataSource[pointingIndex]);
              onBlurInput();
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "ArrowDown") {
              setPointingIndex((index) => {
                if (index < dataSource.length - 1) return index + 1;
                return index;
              });

              if (pointingIndex < dataSource.length - 1) {
                contentTableRef.current.scrollTop = 0;
                const selectedRow = document.querySelector(".input-search-patient-selected-row");
                if (!!selectedRow) contentTableRef.current.scrollTop = selectedRow.offsetTop - contentTableRef.current.clientHeight + 90;
              }
            }

            if (event.key === "ArrowUp") {
              setPointingIndex((index) => {
                if (index > 0) {
                  return index - 1;
                }
                return index;
              });

              if (pointingIndex > 0) {
                contentTableRef.current.scrollTop = 0;
                const selectedRow = document.querySelector(".input-search-patient-selected-row");
                if (!!selectedRow) contentTableRef.current.scrollTop = selectedRow.offsetTop - contentTableRef.current.clientHeight + 20;
              }
            }
          }}
          ref={inputRef}
          suffix={
            showClearBtn ? 
              <>
                <Button
                  type="primary"
                  className={style['clear-btn']}
                  onClick={handleClearInput}
                >Clear</Button>
              </> : 
              <></>
          }
        />
      </div>
    </Dropdown>
  );
};

const AutoCompleteSearch = ({
  placeholder,
  value,
  onClear = () => {},
  onChange = () => {},
  onSelectValue = () => {},
  onSelect = () => {},
  keyFinder = "key",
  subKeyLabel = "key",
  typeSearch = "TEN",
  readOnly,
  id,
  ...props
}) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const layDsBenhNhan = async (value = "", type = "SEARCH") => {
    if (!value.trim()) return;
    setLoading(true);
    let dataReq = {
      search_string: value,
      partner_code: userProfile.partner_code,
      isSuggest: true,
    };
    if (typeSearch === "SDT") {
      dataReq.SO_DIEN_THOAI = value;
      dataReq.search_string = undefined;
      dataReq.isSuggest = true;
    }
    const res = await apiTimKiemBenhNhan({ ...dataReq });

    if (!!res) {
      const data = res.map((item) => ({
        ...item,
        key: rid(),
        value: item?.[keyFinder],
        label:subKeyLabel ? `${item?.TEN} - ${item?.SO_DIEN_THOAI} - ${getSexFromString(item?.GIOI_TINH)} - ${moment(item?.NGAY_SINH).format('DD/MM/YYYY')}` : `${item?.[keyFinder]}`,
      }));
      setOptions(data);
    }

    setLoading(false);
  };

  const handleSearchPatient = useCallback(
    _.debounce((string) => layDsBenhNhan(string), 1000),
    []
  );

  return (
    <AutoComplete
      options={options}
      disabled={readOnly}
      value={value}
      onChange={(string) => {
        !!string?.length === 0 && onClear();
        onChange(string);
      }}
      placeholder={placeholder}
      onSearch={handleSearchPatient}
      onSelect={(_, fullVal) => onSelect(fullVal)}
      allowClear
      onClear={onClear && onClear}
      popupClassName={style['auto-complete']}
      {...props}
    />
  );
};

InputSearchPatient.AutoCompleteSearch = AutoCompleteSearch;

export default InputSearchPatient;
