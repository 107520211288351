import { AutoComplete, Form } from "antd";
import { useState } from "react";
import {
  formatNumberToPrice,
  formatPriceToNumber,
  validatePriceInput,
} from "../../../../../helpers";
import style from "./priceInput.module.less";

const PriceInput = ({ form, label = "", name = "" }) => {
  const [options, setOptions] = useState([]);

  const handleChange = (val) => {
    val = val.replace(/[^0-9.]/g, "");

    if (!val) {
      setOptions([]);
    } else {
      val = formatPriceToNumber(val);

      setOptions([
        { value: formatNumberToPrice(val + "000") },
        { value: formatNumberToPrice(val + "0000") },
        { value: formatNumberToPrice(val + "00000") },
      ]);
    }

    form.setFields([
      {
        name: name,
        value: validatePriceInput(formatNumberToPrice(val)),
      },
    ]);
  };

  const handleSelect = (val) => {
    form.setFields([
      {
        name: name,
        value: val,
      },
    ]);
  };

  return (
    <Form.Item label={label} name={name}>
      <AutoComplete
        options={options}
        style={{
          width: "100%",
        }}
        onSelect={handleSelect}
        onSearch={handleChange}
        placeholder="100.000"
        className={style["autocomplete"]}
      />
    </Form.Item>
  );
};

export default PriceInput;
