import { Button, Col, Form, Modal, Row, notification } from "antd";
import React, { forwardRef, useState, useEffect } from "react";
import i18n, { languageKeys } from "../../i18n";
import { Input } from "../../components_v2";
import { useImperativeHandle } from "react";
import { memo } from "react";
import { UnlockTheme } from "../../assets/svg";
import { common_post, encrypt256 } from "../../helpers";
import { apis } from "../../constants";
import { useSelector } from "react-redux";
import moment from "moment";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const ModalPassword = forwardRef((props, ref) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState();

  // NEW FEATURE
  const PWD_INCLUDE_NORMAL_REGEX = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/;
  const PWD_INCLUDE_SPECIAL_REGEX = /(?=.*[!@#$^*()_])/;
  const newPwd = Form.useWatch("RAW_PASSWORD", form)
  const [checkConditon1, setCheckConition1] = useState(false)
  const [checkConditon2, setCheckConition2] = useState(false)
  const [checkConditon3, setCheckConition3] = useState(false)
  useEffect(() => {
    setCheckConition1(newPwd?.length >= 8 && newPwd?.length <= 24)
    setCheckConition2(PWD_INCLUDE_NORMAL_REGEX.test(newPwd))
    setCheckConition3(PWD_INCLUDE_SPECIAL_REGEX.test(newPwd))
  }, [newPwd]);
  //

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  const handleOpen = () => {
    setVisible(true);
  };
  const handleClose = () => {
    setVisible(false);
    form.resetFields();
    setCheckConition1(false)
    setCheckConition2(false)
    setCheckConition3(false)
  };

  const onFinishSuccess = () => {
    handleClose();
    !!props.doReload && window.location.reload()
  }

  const onFinish = async (values) => {
    console.log(values.OLD_PASSWORD)

    if (!(checkConditon1 && checkConditon2 && checkConditon3)) {
      notification.error({placement:"bottomLeft", message: i18n.t(languageKeys.noti_mat_khau_ko_ok)})
      return;
    }

    try {

      let hashPasswordNew = await encrypt256(values.RAW_PASSWORD);
      let hashPasswordOld = await encrypt256(values.OLD_PASSWORD);

      let body = {
        partner_code: userProfile.partner_code,
        NHANSU_ID: userProfile.NHANSU_ID,
        USERNAME:userProfile.USERNAME,
        USER_ID:userProfile.NHANSU_ID,
        BENH_VIEN_ID:userProfile.BENH_VIEN_ID,
        RAW_PASSWORD:values.RAW_PASSWORD,
        PASSWORD:hashPasswordNew,
        OLD_PASSWORD:hashPasswordOld,
        EMAIL:userProfile.EMAIL,
        NGAY_SUA:moment().format('YYYYMMDD'),
        GIO_SUA:moment().format('HH:mm'),
        TYPE:"NHAN_SU",
      };

      let res = await common_post(apis.doi_mat_khau_ngoi_dung,body);

      if(res && res.status === 'OK') {
        notification.success({placement:"bottomLeft",message:i18n.t(languageKeys.cap_nhat_thanh_cong)})
        onFinishSuccess();
      } else {
        if(res.error_code == '004') {
        notification.error({placement:"bottomLeft",message:res.message})
      } else {
        notification.error({placement:"bottomLeft",message:i18n.t(languageKeys.thao_tac_that_bai)})
      }
      }

    } catch (error) {console.log(error)}
  };

  return (
    <Modal visible={!!props.visible || !!visible} footer={null} closable={false} onCancel={handleClose} centered width={445} zIndex={10069}>
        <div style={{margin:"10px 24px"}}>
        <div style={{textAlign:"center"}}>
            <UnlockTheme/>
            <h1>{i18n.t(languageKeys.doi_mat_khau)}</h1>
            <h5 style={{ color:"#2c378287"}}>{props.text || i18n.t(languageKeys.content_doi_mat_khau)}</h5>
        </div>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item label={i18n.t(languageKeys.mat_khau_cu)} name={"OLD_PASSWORD"}>
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={i18n.t(languageKeys.mat_khau_moi)} name={"RAW_PASSWORD"}>
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={i18n.t(languageKeys.nhap_lai_mat_khau_moi)} name={"confirm_password"}
              rules={[
                {
                  validator: (rule, value) => {
                    let pass = form.getFieldValue('RAW_PASSWORD');
                    if (value === "") {
                      return Promise.reject(
                        i18n.t(languageKeys.vui_long_xac_nhapn_mat_khau)
                      );
                    } else if (value !== pass) {
                      return Promise.reject(i18n.t(languageKeys.xac_nhan_mat_khau_khong_dung));
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row gutter={[0, 4]} style={{marginTop: 10}}>
        <Col span={24}>
          {checkConditon1 ? <CheckOutlined style={{color: "green"}}/> : <CloseOutlined style={{color: "red"}}/>}
          &nbsp;&nbsp;{i18n.t(languageKeys.dk_do_dai_mat_khau)}
        </Col>
        <Col span={24}>
          {checkConditon2 ? <CheckOutlined style={{color: "green"}}/> : <CloseOutlined style={{color: "red"}}/>}
          &nbsp;&nbsp;{i18n.t(languageKeys.dk_mat_khau_bao_gom)}
        </Col>
        <Col span={24}>
          {checkConditon3 ? <CheckOutlined style={{color: "green"}}/> : <CloseOutlined style={{color: "red"}}/>}
          &nbsp;&nbsp;{i18n.t(languageKeys.dk_mat_khau_ky_tu)}
        </Col>
      </Row>
      <Row justify={"center"} gutter={10} style={{marginTop:'24px'}}>
        {!props.hideEscape && (
            <Col span={9}><Button type="primary" block ghost onClick={()=>handleClose()}>{i18n.t(languageKeys.huy)}</Button></Col>
        )}
        <Col span={9}><Button type="primary" block onClick={() => form.submit()}>{i18n.t(languageKeys.doi_mat_khau)}</Button></Col>
      </Row>
        </div>
    </Modal>
  );
});

export default memo(ModalPassword);
