import { Col, Form, Input, Row, Switch } from "antd";
import { useEffect, useState } from "react";
import { Select } from "../../../../components";
import i18n, { languageKeys } from "../../../../i18n";
import { fieldDanhMucMay } from "../fieldsDanhMuc";
import formDanhMuc from "./formDanhMuc.module.less";
import { apiLayDsKhoaByt } from "../apisDanhMuc/apisDmKhoa";
import { danhSachLoaiKhoa, danhSachLoaiMay } from "../../../../constants/data";

const FormDanhMucMay = () => {
  const [dsKhoaByt, setDsKhoaByt] = useState([]);

  useEffect(() => {
    layDsKhoaCuaByt();
    return () => setDsKhoaByt([]);
  }, []);

  const layDsKhoaCuaByt = async () => {
    const data = await apiLayDsKhoaByt();
    setDsKhoaByt(data);
  };

  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={fieldDanhMucMay.ma_may}
                label={i18n.t(languageKeys.field_Ma_may)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldDanhMucMay.ten_may}
                label={i18n.t(languageKeys.field_Ten_may)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name={fieldDanhMucMay.ma_bhyt} label={i18n.t(languageKeys.field_Ma_BHYT)}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            {/* <Col span={12}>
              <Form.Item name={fieldDanhMucMay.truong_khoa} label="Khoa">
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col> */}

            <Col span={12}>
              <Form.Item
                name={fieldDanhMucMay.loai_may}
                label={i18n.t(languageKeys.field_Loai_may)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  showSearch
                  dataSource={danhSachLoaiMay}
                  titleKey="name"
                  valueKey="key"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
                {/* <Input placeholder={i18n.t(languageKeys.common_Nhap)} /> */}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>

      <div className={formDanhMuc["wrapper"]}>
        {/* <h4>{i18n.t(languageKeys.field_Ghi_chu)}</h4>

        <Form.Item name={fieldDanhMucMay.ghi_chu}>
          <Input.TextArea rows={3} placeholder={i18n.t(languageKeys.common_Nhap)} />
        </Form.Item> */}

        <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

        <Form.Item name={fieldDanhMucMay.hien_thi} valuePropName="checked" initialValue={false}>
          <Switch />
        </Form.Item>
      </div>
    </>
  );
};

export default FormDanhMucMay;
