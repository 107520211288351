import { Spin, Tree } from "antd";
import { useState } from "react";
import { memo } from "react";
import { getFirstLetters, rid } from "../../../../helpers";
import style from "./listDvCls.module.less";
import { showDeleteBtn } from "pages/QuanLyTiepDon/ModalTiepDon.new/helpers";
import { useSelector } from "react-redux";
import _ from "lodash";
import { getExemptionData } from "ducks/slices/ListData/Exemption.slice";

const ListDvCls = ({
  dsDvCls = [],
  onTreeClsSelect = () => {},
  dsDvDaChon = [],
  filterItem = {},
  search_string = "",
  loading = false,
  disabled = false,
  showFavourite = true,
  setDsDvClsDaChon = () => {},
  dsDvKhBenhDaChon = [],
  setDsDvKhBenhDaChon = () => {},
  setExpandedRowDsChon = () => {}
}) => {

  const userProfile = useSelector((state) => state.auth.user);
  const exp = useSelector(getExemptionData)

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [expandSearchList, setExpandSearchList] = useState([]);


  const indexDsDvCls = dsDvCls.map(item => ({
    ...item,
    DICH_VU: item?.DICH_VU?.map(i => ({ ...i, indexDV_ID: `${item.index}_${i.DV_ID}` }))
  }))


  function onExpandItem(data) {
    if (data.expanded) {
      setExpandedKeys((currentArr) => [...currentArr, data.node.key]);
    } else {
      setExpandedKeys((currentArray) => currentArray.filter((item) => item !== data.node.key));
      setExpandSearchList((currentArray) => currentArray.filter((item) => item !== data.node.key));
    }
  }

  const checkDisable = (sub_item) => {
    return (
      dsDvKhBenhDaChon.some((item) => item.DICH_VU_ID === sub_item.DICH_VU_ID && (item.disabled || !showDeleteBtn(item || {}))) ||
      dsDvDaChon.some((item) => item.DICH_VU_ID === sub_item.DICH_VU_ID && (item.disabled || !showDeleteBtn(item || {}))) ||
      disabled
    );
  }


  const onTreeSelect = (data, setExpandedKeys = () => {}) => {
    if (data.disabled) return

    if (data.selectable) {

      // Vừa disabled vừa tick
      const unchangeableEx = dsDvKhBenhDaChon?.filter((i) => !showDeleteBtn(i) && i.old)
      const unchangeableCls = dsDvDaChon?.filter((i) => !showDeleteBtn(i) && i.old)

      // Xử lý xóa
      if (data.checked) {
        if (data.children) {
          // Xóa all
          setDsDvKhBenhDaChon((currentArray) =>
            _.uniqBy(unchangeableEx.concat(currentArray.filter((item) => !data.value?.DICH_VU?.some((i) => i.DV_ID === item.DV_ID))), "DV_ID")
          );
          setDsDvClsDaChon((currentArray) =>
            _.uniqBy(unchangeableCls.concat(currentArray.filter((item) => !data.value?.DICH_VU?.some((i) => i.DV_ID === item.DV_ID))), "DV_ID")
          );
        } else {
          // Xóa lẻ
          if (data.value.LOAI_PHONG === "KHAM_BENH") {
            // Dv khám bệnh (Nhóm dv thường dùng)
            setDsDvKhBenhDaChon((currentArray) => currentArray.filter((item) => item.DV_ID !== data.value.DV_ID));
          } else {
            // Dv cls
            setDsDvClsDaChon((currentArray) => currentArray.filter((item) => item.DV_ID !== data.value.DV_ID));
          }
        }
      } else {
        // Tích chọn all
        if (data.children) {
          // if (data.value.DICH_VU.some(i => i.MA_NHOM_BHYT === "COMBO_DV")) {

          //   // Tích chọn all combo
          //   setDsDvClsDaChon((currentArr) => _.uniqBy(unchangeableCls.concat([
          //     ...currentArr,
          //     ...data.children.map(item => ({
          //       ...item.value,
          //       _id: rid(7),
          //       key: rid(),
          //       GIA_DICH_VU: item.value.GIA_THU_PHI,
          //       PHAN_LOAI: "KHAM_CLS",
          //       NGUOI_TAO: userProfile,
          //       SO_LUONG: 1,
          //       THANH_TIEN: item.value.GIA_THU_PHI,
          //       PHONG_THUC_HIEN: {},
          //       children: item.value.DV_KEM_THEO?.map(i => ({
          //         ...i,
          //         _id: rid(7),
          //         key: rid(),
          //         TEN_DICHVU: i.TEN_DICHVU || i?.TEN_DICH_VU_KEM_THEO
          //       })),
          //       order: new Date().getTime(),
          //       DM_DOI_TUONG_ID: customerTarget?.[0]?.ID
          //     }))
          //   ]), "DV_ID"));

          // } else {

          //   // Tích chọn all cls
          //   setDsDvClsDaChon((currentArr) => _.uniqBy(unchangeableCls.concat([
          //     ...currentArr,
          //     ...data.children.map(item => ({
          //       ...item.value,
          //       _id: rid(7),
          //       key: rid(),
          //       GIA_DICH_VU: item.value.GIA_THU_PHI,
          //       PHAN_LOAI: "KHAM_CLS",
          //       NGUOI_TAO: userProfile,
          //       SO_LUONG: 1,
          //       THANH_TIEN: item.value.GIA_THU_PHI,
          //       order: new Date().getTime(),
          //       DM_DOI_TUONG_ID: customerTarget?.[0]?.ID
          //     }))
          //   ]), "DV_ID"));
          // }
          let newArrKhB = [];
          let newArrCls = [];
          data.value.DICH_VU.forEach((item) => {
            const GIA_DT = (
              item.LIST_GIA_THEO_DICH_VU?.find(i => i.DM_DOI_TUONG_ID === item.DM_DOI_TUONG_ID) ||
              item.LIST_GIA_THEO_DICH_VU?.[0] || {}
            )
            if (item.LOAI_PHONG === "KHAM_BENH") {
              newArrKhB.push({
                ...item,
                _id: rid(7),
                key: rid(),
                GIA_DICH_VU: GIA_DT.GIA_DICH_VU,
                GIA_GOC_DICH_VU: GIA_DT.GIA_DICH_VU,
                PHAN_LOAI: "KHAM_BENH",
                NGUOI_TAO: userProfile,
                SO_LUONG: 1,
                // THANH_TIEN: item.GIA_THU_PHI,
                STT: undefined,
                ID: undefined,
                order: new Date().getTime(),
                DM_DOI_TUONG_ID: GIA_DT.DM_DOI_TUONG_ID,
                PHONG_THUC_HIEN_ID: item.LIST_PHONG_ID?.[0],
                MIEN_GIAM_TIEP_DON: exp.find(i => i.DV_ID === item.DV_ID)?.MIEN_GIAM || 0,
              });
            } else {
              newArrCls.push({
                ...item,
                _id: rid(7),
                key: rid(),
                GIA_DICH_VU: GIA_DT.GIA_DICH_VU,
                GIA_GOC_DICH_VU: GIA_DT.GIA_DICH_VU,
                // PHAN_LOAI: "KHAM_CLS",
                NGUOI_TAO: userProfile,
                SO_LUONG: 1,
                // THANH_TIEN: item.GIA_THU_PHI,
                order: new Date().getTime(),
                DM_DOI_TUONG_ID: GIA_DT.DM_DOI_TUONG_ID,
                PHONG_THUC_HIEN_ID: item.LIST_PHONG_ID?.[0],
                MIEN_GIAM_TIEP_DON: exp.find(i => i.DV_ID === item.DV_ID)?.MIEN_GIAM || 0,
              });
            }
          })
          setDsDvKhBenhDaChon(_.uniqBy(unchangeableEx.concat([...dsDvKhBenhDaChon, ...newArrKhB]), "DV_ID"))
          setDsDvClsDaChon(_.uniqBy(unchangeableCls.concat([...dsDvDaChon, ...newArrCls]), "DV_ID"))

          // Tích chọn dv lẻ
        } else {
          const GIA_DT = data.value.LIST_GIA_THEO_DICH_VU?.[0] || {}
          // Dv khám bệnh (Nhóm dv thường dùng)
          if (data.value.LOAI_PHONG === "KHAM_BENH") {
            setDsDvKhBenhDaChon((currentArr) => [
              ...currentArr,
              {
                ...data.value,
                _id: rid(7),
                key: rid(),
                GIA_DICH_VU: GIA_DT.GIA_DICH_VU,
                GIA_GOC_DICH_VU: GIA_DT.GIA_DICH_VU,
                PHAN_LOAI: "KHAM_BENH",
                NGUOI_TAO: userProfile,
                SO_LUONG: 1,
                // THANH_TIEN: data.value.GIA_THU_PHI,
                STT: undefined,
                ID: undefined,
                order: new Date().getTime(),
                DM_DOI_TUONG_ID: GIA_DT.DM_DOI_TUONG_ID,
                PHONG_THUC_HIEN_ID: data.value.LIST_PHONG_ID?.[0],
                MIEN_GIAM_TIEP_DON: exp.find(i => i.DV_ID === data.value.DV_ID)?.MIEN_GIAM || 0,
              },
            ]);
          } else {
            if (data.value.MA_NHOM_BHYT === "COMBO_DV") {
              const children = (item) => {
                return item.value.DV_KEM_THEO?.map((item) => {
                  let newItem = {
                    ...item,
                    _id: rid(7),
                    key: rid(),
                    TEN_DICHVU: item.TEN_DICHVU || item?.TEN_DICH_VU_KEM_THEO,
                    demo: undefined,
                  };
                  if (item.LIST_NOI_THUC_HIEN_XN && item.LIST_NOI_THUC_HIEN_XN.length > 0) {
                    const element = item?.LIST_NOI_THUC_HIEN_XN.find((obj) => obj?.IS_DEFAULT == 1);
                    newItem.NOI_THUC_HIEN_XN = element;
                  }
                  return newItem;
                });
              };

              const obj = {
                ...data.value,
                _id: rid(7),
                key: rid(),
                GIA_DICH_VU: GIA_DT.GIA_DICH_VU,
                GIA_GOC_DICH_VU: GIA_DT.GIA_DICH_VU,
                // PHAN_LOAI: "KHAM_CLS",
                NGUOI_TAO: userProfile,
                SO_LUONG: 1,
                // THANH_TIEN: data.value.GIA_THU_PHI,
                PHONG_THUC_HIEN: {},
                children: children(data),
                order: new Date().getTime(),
                DM_DOI_TUONG_ID: GIA_DT.DM_DOI_TUONG_ID,
                MIEN_GIAM_TIEP_DON: exp.find(i => i.DV_ID === data.value.DV_ID)?.MIEN_GIAM || 0,
              };

              setDsDvClsDaChon((currentArr) => [...currentArr, obj]);
            } else {
              const obj = {
                ...data.value,
                _id: rid(7),
                key: rid(),
                GIA_DICH_VU: GIA_DT.GIA_DICH_VU,
                GIA_GOC_DICH_VU: GIA_DT.GIA_DICH_VU,
                // PHAN_LOAI: "KHAM_CLS",
                NGUOI_TAO: userProfile,
                SO_LUONG: 1,
                THANH_TIEN: data.value.GIA_THU_PHI,
                order: new Date().getTime(),
                DM_DOI_TUONG_ID: GIA_DT.DM_DOI_TUONG_ID,
                PHONG_THUC_HIEN_ID: data.value.LIST_PHONG_ID?.[0],
                MIEN_GIAM_TIEP_DON: exp.find(i => i.DV_ID === data.value.DV_ID)?.MIEN_GIAM || 0,
              };

              if (obj?.LIST_NOI_THUC_HIEN_XN && obj.LIST_NOI_THUC_HIEN_XN.length > 0) {
                const element = obj?.LIST_NOI_THUC_HIEN_XN.find((obj) => obj?.IS_DEFAULT === 1);
                obj.NOI_THUC_HIEN_XN = element;
              }

              // Tích chọn lẻ
              setDsDvClsDaChon((currentArr) => [...currentArr, obj]);
            }
          }
        }
      }
    } else {
      if (data.expanded) {
        setExpandedKeys((currentArray) => currentArray.filter((item) => item !== data.key));
      } else {
        setExpandedKeys((currentArr) => [...currentArr, data.key]);
      }
    }
  }

  // return (
  //   <div className={style["container"]}>
  //     <Spin spinning={loading}>

  //       {showFavourite && (
  //         <Tree
  //           className={style.tree}
  //           checkable
  //           onClick={(event, e) => handleSelectTree(e, setExpandedKeys)}
  //           onCheck={(key, event) => handleSelectTree(event.node, setExpandedKeys)}
  //           onExpand={(key, event) => onExpandItem(event)}
  //           checkedKeys={dsDvDaChon.map((item) => item.DV_ID)}
  //           expandedKeys={[...expandedKeys, filterItem?.index, ...expandSearchList]}
  //           disabled={disabled}
  //         >
  //           {dsDvCls
  //             .filter(
  //               (item) =>
  //                 (item.TEN_LOAI_DICH_VU === filterItem?.TEN_LOAI_DICH_VU || !filterItem?.TEN_LOAI_DICH_VU) &&
  //                 (item.TEN_LOAI_DICH_VU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
  //                   item.DICH_VU.some(
  //                     (sub_item) =>
  //                       sub_item.TEN_DICHVU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
  //                       getFirstLetters(sub_item.TEN_DICHVU)?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase())
  //                   )
  //                 ) && item.TYPE === "DICH_VU_YEU_THICH"
  //             )
  //             .map((item, index) => (
  //               <Tree.TreeNode
  //                 key={item.index}
  //                 value={item}
  //                 title={item.TEN_LOAI_DICH_VU}
  //                 selectable
  //                 checkable
  //               >
  //                 {item.DICH_VU.filter(
  //                   (item) =>
  //                     item.TEN_DICHVU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
  //                     (!!item.DICH_VU_CHA && item.DICH_VU_CHA?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase())) ||
  //                     getFirstLetters(item.TEN_DICHVU)?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
  //                     item.TEN_LOAI_DICH_VU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
  //                     getFirstLetters(item.TEN_LOAI_DICH_VU)?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase())
  //                 ).map((sub_item) => (
  //                   <Tree.TreeNode
  //                     key={sub_item.DV_ID}
  //                     value={sub_item}
  //                     title={sub_item.TEN_DICHVU}
  //                     selectable={!checkDisable(sub_item)}
  //                     checkable={true}
  //                     className={checkDisable(sub_item) && style.disabled}
  //                   />
  //                 ))}
  //               </Tree.TreeNode>
  //             ))}
  //         </Tree>
  //       )}

  //       <Tree
  //         className={style.tree}
  //         checkable
  //         onClick={(event, e) => handleSelectTree(e, setExpandedKeys)}
  //         onCheck={(key, event) => handleSelectTree(event.node, setExpandedKeys)}
  //         onExpand={(key, event) => onExpandItem(event)}
  //         checkedKeys={dsDvDaChon.map((item) => item.DV_ID)}
  //         expandedKeys={[...expandedKeys, filterItem?.index, ...expandSearchList]}
  //         disabled={disabled}
  //       >
  //         {dsDvCls
  //           .filter(
  //             (item) =>
  //               (item.TEN_LOAI_DICH_VU === filterItem?.TEN_LOAI_DICH_VU || !filterItem?.TEN_LOAI_DICH_VU) &&
  //               (item.TEN_LOAI_DICH_VU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
  //                 item.DICH_VU.some(
  //                   (sub_item) =>
  //                     sub_item.TEN_DICHVU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
  //                     getFirstLetters(sub_item.TEN_DICHVU)?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase())
  //                 )
  //               ) && item.TYPE !== "DICH_VU_YEU_THICH"
  //           )
  //           .map((item, index) => (
  //             <Tree.TreeNode
  //               key={item.index}
  //               value={item}
  //               title={item.TEN_LOAI_DICH_VU}
  //               selectable={false}
  //               checkable={false}
  //             >
  //               {item.DICH_VU.filter(
  //                 (item) =>
  //                   item.TEN_DICHVU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
  //                   (!!item.DICH_VU_CHA && item.DICH_VU_CHA?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase())) ||
  //                   getFirstLetters(item.TEN_DICHVU)?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
  //                   item.TEN_LOAI_DICH_VU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
  //                   getFirstLetters(item.TEN_LOAI_DICH_VU)?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase())
  //               ).map((sub_item) => (
  //                 <Tree.TreeNode
  //                   key={sub_item.DV_ID}
  //                   value={sub_item}
  //                   title={sub_item.TEN_DICHVU}
  //                   selectable
  //                   checkable
  //                   disabled={checkDisable(sub_item)}
  //                   disableCheckbox={checkDisable(sub_item)}
  //                 />
  //               ))}
  //             </Tree.TreeNode>
  //           ))}
  //       </Tree>

  //     </Spin>
  //   </div>
  // );

  return (
    <div className={style["container"]}>
      <Spin spinning={loading}>
        {showFavourite ? (
          <Tree
            className={style.tree}
            checkable
            onClick={(event, e) => onTreeSelect(e, setExpandedKeys)}
            onCheck={(key, event) => onTreeSelect(event.node, setExpandedKeys)}
            onExpand={(key, event) => onExpandItem(event)}
            checkedKeys={indexDsDvCls
              .flatMap((i) => i.DICH_VU)
              .filter((cls) => dsDvDaChon.some((i) => i.DV_ID === cls.DV_ID) || dsDvKhBenhDaChon.some((i) => i.DV_ID === cls.DV_ID))
              .map((item) => item.indexDV_ID)}
            expandedKeys={[...expandedKeys, filterItem?.index, ...expandSearchList]}
            disabled={disabled}
          >
            {indexDsDvCls
              .filter(
                (item) =>
                  (item.TEN_LOAI_DICH_VU === filterItem?.TEN_LOAI_DICH_VU || !filterItem?.TEN_LOAI_DICH_VU) &&
                  (item.TEN_LOAI_DICH_VU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
                    item.DICH_VU.some(
                      (sub_item) =>
                        sub_item.TEN_DICHVU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
                        getFirstLetters(sub_item.TEN_DICHVU)?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase())
                    ))
              )
              .map((item, index) => (
                <Tree.TreeNode
                  key={item.index}
                  value={item}
                  title={item.TEN_LOAI_DICH_VU}
                  selectable={item.TYPE === "DICH_VU_YEU_THICH" && !item.DICH_VU.every((i) => checkDisable(i))}
                  checkable={item.TYPE === "DICH_VU_YEU_THICH"}
                  className={item.DICH_VU.every((i) => checkDisable(i)) && style.disabled}
                >
                  {item.DICH_VU.filter(
                    (item) =>
                      item.TEN_DICHVU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
                      (!!item.DICH_VU_CHA && item.DICH_VU_CHA?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase())) ||
                      getFirstLetters(item.TEN_DICHVU)?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
                      item.TEN_LOAI_DICH_VU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
                      getFirstLetters(item.TEN_LOAI_DICH_VU)?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase())
                  ).map((sub_item) => (
                    <Tree.TreeNode
                      key={sub_item.indexDV_ID}
                      value={sub_item}
                      title={sub_item.TEN_DICHVU}
                      selectable={!checkDisable(sub_item)}
                      checkable={true}
                      className={checkDisable(sub_item) && style.disabled}
                    />
                  ))}
                </Tree.TreeNode>
              ))}
          </Tree>
        ) : (
          <Tree
            className={style.tree}
            checkable
            onClick={(event, e) => onTreeClsSelect(e, setExpandedKeys)}
            onCheck={(key, event) => onTreeClsSelect(event.node, setExpandedKeys)}
            onExpand={(key, event) => onExpandItem(event)}
            checkedKeys={dsDvDaChon.map((item) => item.DV_ID)}
            expandedKeys={[...expandedKeys, filterItem?.index, ...expandSearchList]}
            disabled={disabled}
          >
            {dsDvCls
              .filter(
                (item) =>
                  (item.TEN_LOAI_DICH_VU === filterItem?.TEN_LOAI_DICH_VU || !filterItem?.TEN_LOAI_DICH_VU) &&
                  (item.TEN_LOAI_DICH_VU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
                    item.DICH_VU.some(
                      (sub_item) =>
                        sub_item.TEN_DICHVU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
                        getFirstLetters(sub_item.TEN_DICHVU)?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase())
                    )) &&
                  item.TYPE !== "DICH_VU_YEU_THICH"
              )
              .map((item, index) => (
                <Tree.TreeNode key={item.index} value={item} title={item.TEN_LOAI_DICH_VU} selectable={false} checkable={false}>
                  {item.DICH_VU.filter(
                    (item) =>
                      item.TEN_DICHVU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
                      (!!item.DICH_VU_CHA && item.DICH_VU_CHA?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase())) ||
                      getFirstLetters(item.TEN_DICHVU)?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
                      item.TEN_LOAI_DICH_VU?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase()) ||
                      getFirstLetters(item.TEN_LOAI_DICH_VU)?.toLocaleLowerCase().includes(search_string?.toLocaleLowerCase())
                  ).map((sub_item) => (
                    <Tree.TreeNode
                      key={sub_item.DV_ID}
                      value={sub_item}
                      title={sub_item.TEN_DICHVU}
                      selectable
                      checkable
                      disabled={checkDisable(sub_item)}
                      disableCheckbox={checkDisable(sub_item)}
                    />
                  ))}
                </Tree.TreeNode>
              ))}
          </Tree>
        )}
      </Spin>
    </div>
  );
};

export default memo(ListDvCls);
