import { common_post, getDataJson, getFullName, handleGetProperty, HLog, isObject, rid, validFragment } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { apis, keys } from "../../../constants";
import { useCallback, useEffect, useState } from "react";
import { notification } from "antd";
import i18n, { languageKeys } from "../../../i18n";
import { formatDanhSachDichVu, formatDanhSachDichVuGoi, formatPatientData } from "../hooksTiepDon";
import { optionsNguonTin } from "../components/KhaiThacLichSuBenh/ThongTinKhac";
import { optionsBenhSu } from "../components/KhaiThacLichSuBenh/TienSuBanThan";
import { optionsTienSu } from "../components/KhaiThacLichSuBenh/TienSuGiaDinh";
import { TAB_THONG_TIN_KB, tabs } from "./ModalTiepDon";
import { showDeleteBtn } from "./helpers";
import moment from "moment";
import { fieldTiepDon } from "../fieldsTiepDon";
import { dataPartnerCode, TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI } from "constants/keys";
import { TiepDonActions } from "ducks/slices/QlTiepdon/TiepDon.slice";
import { setTodayDoctors } from "ducks/slices/ListData/SharedData.slice";
import _ from "lodash";
import { getExemption } from "ducks/slices/ListData/Exemption.slice";
import { fieldVongBung } from "../components/KhaiThacLichSuBenh/fieldKhaiThac";

export const useConfigTiepDon = (props) => {

  const {
    duplicateInfoRef,
    form,
    totalExp,
  } = props;

  const userProfile = useSelector((state) => state.auth.user);
  const { PHONG } = useSelector((state) => state.data);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleXetNghiem = (appointment_data) => {
    let barcode = appointment_data?.BARCODE_XET_NGHIEM || null

    if (barcode && barcode?.toString()?.trim()?.length > 0) {
      barcode = barcode?.toString()?.trim();
      let lengthBarcode = barcode?.length || 0;
      if (userProfile?.config?.BARCODE_XET_NGHIEM !== "BARCODE_VINH_VIEN" && lengthBarcode < 5) {
        barcode = new Array(Math.max(0, 5 - lengthBarcode + 1)).join("0") + barcode?.toString()?.trim();
      }
      if (
        userProfile?.config?.BARCODE_XET_NGHIEM === "BARCODE_VINH_VIEN" &&
        userProfile?.config?.BARCODE_LENGTH &&
        lengthBarcode < userProfile?.config?.BARCODE_LENGTH
      ) {
        barcode =
          new Array(Math.max(0, userProfile?.config?.BARCODE_LENGTH - lengthBarcode + 1)).join("0") +
          barcode?.toString()?.trim();
      }
    }

    return {
      BARCODE_XET_NGHIEM: barcode?.toString() || null,
      BARCODE_QUAN_LY: appointment_data?.BARCODE_QUAN_LY?.toString() || null
    }
  }

  const handleSubmitFail = (message) => {
    setLoadingSubmit(false);
    if (message === "LICH_KHAM_CHUA_HOAN_THANH") {
      notification.error({ message: i18n.t(languageKeys.lich_kham_chua_hoan_thanh), placement: "bottomLeft" });
    } else if (message?.message == i18n.t(languageKeys.Ban_chua_chon_benh_nhan_nao)) {
      notification.error({ message: i18n.t(languageKeys.Ban_chua_chon_benh_nhan_nao), placement: "bottomLeft" });
    } else {
      notification.error({ message: i18n.t(languageKeys.thao_tac_that_bai), placement: "bottomLeft" });
    }
  };

  /**
   * Thêm mới cuộc hẹn tiếp đón
   * @param {!{}} data data bệnh nhân + cuộc hẹn của cuộc hẹn mới
   * @param {![]} dv_kham_benh data dịch vụ khám bệnh của cuộc hẹn mới
   * @param {![]} dv_cls data dịch vụ cận lâm sàng của cuộc hẹn mới
   */

  const handleCheckDuplicateInfo = async (payloadData, form, duplicateInfoRef) => {
    const checkResult = await common_post(apis.checkTrungTTKH, {
      HO_TEN: payloadData?.TEN,
      NGAY_SINH: payloadData?.NGAY_SINH?.slice(0, 4),
      SO_DIEN_THOAI: payloadData?.SO_DIEN_THOAI
    })
    if (checkResult?.status === 'OK') {
      if (checkResult?.data?.length) {
        const data = checkResult.data[0];
        let benh_nhan_id = payloadData.BENH_NHAN_ID || payloadData.ID;
        if (!benh_nhan_id) {
          setLoadingSubmit(false);
          duplicateInfoRef?.current?.open({ ...data, BENH_NHAN_ID: data?.ID })
          return true;
        }
        if (benh_nhan_id && data?.ID !== benh_nhan_id) {
          setLoadingSubmit(false);
          form.setFields([
            {
              name: fieldTiepDon.ten_benh_nhan,
              errors: [i18n.t(languageKeys.trung_thong_tin)]
            },
            {
              name: fieldTiepDon.so_dien_thoai,
              errors: [i18n.t(languageKeys.trung_thong_tin)]
            },
            {
              name: fieldTiepDon.ngay_sinh,
              errors: [i18n.t(languageKeys.trung_thong_tin)]
            },
          ])
          notification.error({
            message: i18n.t(languageKeys.trung_thong_tin),
            placement: 'bottomLeft'
          })
          return true;
        }
      }
    }
  }

  const doCreateAppointment = async (data, dv_kham_benh, dv_cls, DS_DV_GOI, LIST_DV_GOI_TT) => {
    try {
      const patient_data = await formatPatientData(data);
      const appointment_data = (await validFragment([data], appointmentColumnStandard))[0];
      let KHAI_THAC_BENH_SU = validFragmentBenhSu(data)
      // biến lỗi kiểm tra xem có dịch vụ nào chưa chọn phòng thực hiện không
      let error_ds_dv = false;
      let xetNghiem = handleXetNghiem(appointment_data)

      // kiểm tra xem đã chọn dịch vụ chưa
      if (dv_kham_benh.length === 0 && dv_cls.length === 0 && DS_DV_GOI.length === 0) {
        notification.error({
          placement: "bottomLeft",
          message: i18n.t(languageKeys.noti_Chua_chon_dich_vu_kham_benh),
        });
        return false;
      }

      appointment_data.DANG_KY_KHAM = formatDanhSachDichVu(dv_kham_benh, () => (error_ds_dv = true));
      appointment_data.DANH_SACH_DICH_VU = formatDanhSachDichVu(dv_cls, () => (error_ds_dv = true));
      appointment_data.DS_DV_GOI = formatDanhSachDichVuGoi(DS_DV_GOI, () => (error_ds_dv = true));

      const tenNguoiHienThi = await getDataJson(appointment_data.NGUOI_HIEN_THI, 'NGUOI_HIEN_THI')

      //update 27/10/2022
      // let doi_tuong = data?.DOI_TUONG_BENH_NHAN

      // appointment_data.DOI_TUONG_BENH_NHAN = JSON.parse(doi_tuong).LOAI_DOI_TUONG;
      // appointment_data.DM_DOI_TUONG_ID = JSON.parse(doi_tuong).ID;
      //kiểm tra nguồn khách
      if (appointment_data.DM_NGUON_KHACH_ID) {
        appointment_data.DM_NGUON_KHACH_ID = JSON.parse(appointment_data.DM_NGUON_KHACH_ID).ID;
      }

      if (appointment_data.NHAN_VIEN_TU_VAN_ID) {
        let jsonData = JSON.parse(appointment_data.NHAN_VIEN_TU_VAN_ID)
        appointment_data.NHAN_VIEN_TU_VAN_ID = jsonData.NHAN_VIEN_TU_VAN_ID;
        appointment_data.TEN_NHAN_VIEN_TU_VAN = jsonData.TEN_NHAN_VIEN_TU_VAN;
      }

      appointment_data.GHI_CHU = data.MO_TA;
      appointment_data.NGUOI_HIEN_THI = tenNguoiHienThi;

      // nếu chưa chọn phòng thực hiện
      if (error_ds_dv) {
        notification.error({
          placement: "bottomLeft",
          message: i18n.t(languageKeys.noti_Chua_chon_phong_thuc_hien),
        });
        return false;
      }

      let benh_nhan_id = patient_data.BENH_NHAN_ID || patient_data.ID;
      if (!!appointment_data.IS_MAU && !appointment_data.TEN_MAU) {
        notification.error({
          message: i18n.t(languageKeys.msg_ten_mau),
          placement: "bottomLeft",
        })
        return
      }

      setLoadingSubmit(true);

      // nếu là bệnh nhân mới
      if (!benh_nhan_id) {

        const isDup = await handleCheckDuplicateInfo(patient_data, form, duplicateInfoRef)
        if (isDup) return false;

        const res = await common_post(apis.tiep_don_tao_benh_nhan, {
          ...patient_data,
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        });

        if (res.status === "OK") {
          benh_nhan_id = res.ID;
        } else {
          handleSubmitFail(res?.message);
          return false;
        }
      }
      // nếu lại bệnh nhân cũ (có id bệnh nhân)
      else {
        const res = await common_post(apis.tiep_don_kiem_tra_thong_tin, {
          ...patient_data,
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        });

        if (res.status === "KO") {
          handleSubmitFail();
          return false;
        }
      }

      const res = await common_post(apis.tiep_don_luu_dang_ky_kham, {
        ...appointment_data,
        TIEP_TAN_ID: userProfile.NHANSU_ID,
        BENH_NHAN_ID: benh_nhan_id,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
        NGUON_DAT_KHAM: keys.PLATFORM.TAI_VIEN,
        KHAI_THAC_BENH_SU: KHAI_THAC_BENH_SU,
        GHI_CHU: data.GHI_CHU,
        ID: data?.ID,
        ...xetNghiem,
        LIST_DV_GOI_TT,
        MIEN_GIAM_TONG_HOA_DON: totalExp,
        LY_DO_VAO_VIEN: appointment_data?.MO_TA,
        NGUON_TAO: "TIEP_DON"
      });

      if (res.status === "OK") {
        notification.success({
          placement: "bottomLeft",
          message: i18n.t(languageKeys.noti_Luu_lich_kham_thanh_cong),
        });

        setLoadingSubmit(false);

        return { ...res, BENH_NHAN_ID: benh_nhan_id };
      } else {
        setLoadingSubmit(false);
        let error_code = res.error_code || ""
        notification.error({
          placement: "bottomLeft",
          message: error_code === "LICH_KHAM_CHUA_HOAN_THANH" ? i18n.t(languageKeys.lich_kham_chua_hoan_thanh) : i18n.t(languageKeys.noti_Luu_lich_kham_that_bai),
        });
      }
    } catch (error) {
      handleSubmitFail(error);

      HLog("[ERROR] Kiem tra thong tin benh nhan that bai", error?.message);
    }
  };

  /**
   * Chỉnh sửa cuộc hẹn tiếp đón
   * @param {!{}} data data của cuộc hẹn chỉnh sửa
   * @param {!Object[]} dv_kham_benh data dịch vụ khám bệnh của cuộc hẹn chỉnh sửa
   * @param {!Object[]} dv_cls data dịch vụ cận lâm sàng của cuộc hẹn chỉnh sửa
   */
  const doUpdateAppointment = async (data, dv_kham_benh, dv_cls, DS_DV_GOI, LIST_DV_GOI_TT) => {
    try {
      const patient_data = await formatPatientData(data);
      let KHAI_THAC_BENH_SU = validFragmentBenhSu(data)
      let _data = await validFragment([data], appointmentColumnStandard);

      let appointment_data = _data[0];
      let xetNghiem = handleXetNghiem(appointment_data)

      // biến lỗi kiểm tra xem có dịch vụ nào chưa chọn phòng thực hiện không
      let error_ds_dv = false;

      // kiểm tra xem đã chọn dịch vụ chưa
      if (dv_kham_benh.length === 0 && dv_cls.length === 0 && DS_DV_GOI.length === 0) {
        notification.error({
          placement: "bottomLeft",
          message: i18n.t(languageKeys.noti_Chua_chon_dich_vu_kham_benh),
        });
        return false;
      }
      appointment_data.DANG_KY_KHAM = formatDanhSachDichVu(dv_kham_benh, () => (error_ds_dv = true));
      appointment_data.DANH_SACH_DICH_VU = formatDanhSachDichVu(dv_cls, () => (error_ds_dv = true));
      appointment_data.DS_DV_GOI = formatDanhSachDichVuGoi(DS_DV_GOI, () => (error_ds_dv = true));
      //update 27/10/2022

      // let doi_tuong = data?.DOI_TUONG_BENH_NHAN

      // appointment_data.DOI_TUONG_BENH_NHAN = JSON.parse(doi_tuong).LOAI_DOI_TUONG;
      // appointment_data.DM_DOI_TUONG_ID = JSON.parse(doi_tuong).ID;

      // //kiểm tra nguồn khách
      if (appointment_data.DM_NGUON_KHACH_ID) {
        appointment_data.DM_NGUON_KHACH_ID = JSON.parse(appointment_data.DM_NGUON_KHACH_ID).ID;
      }

      if (appointment_data.NHAN_VIEN_TU_VAN_ID) {
        let jsonData = JSON.parse(appointment_data.NHAN_VIEN_TU_VAN_ID)
        appointment_data.NHAN_VIEN_TU_VAN_ID = jsonData.NHAN_VIEN_TU_VAN_ID;
        appointment_data.TEN_NHAN_VIEN_TU_VAN = jsonData.TEN_NHAN_VIEN_TU_VAN;
      }
      appointment_data.GHI_CHU = data.MO_TA;
      const tenNguoiHienThi = await getDataJson(data.NGUOI_HIEN_THI, 'NGUOI_HIEN_THI')
      appointment_data.NGUOI_HIEN_THI = tenNguoiHienThi;

      // nếu chưa chọn phòng thực hiện
      if (error_ds_dv) {
        notification.error({
          placement: "bottomLeft",
          message: i18n.t(languageKeys.noti_Chua_chon_phong_thuc_hien),
        });
        return false;
      }

      if (!!appointment_data.IS_MAU && !appointment_data.TEN_MAU) {
        notification.error({
          message: i18n.t(languageKeys.msg_ten_mau),
          placement: "bottomLeft",
        })
        return
      }

      // console.log("[INFO] update appointment data (after formatting): (*) patient:", patient_data, "(*) appointment", appointment_data);

      const patientReq = { ...patient_data, TIEP_TAN_ID: userProfile.NHANSU_ID };
      const editAppointmentReq = {
        ...appointment_data,
        HINH_THUC: "DAT_KHAM_NGAY",
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
        KHAI_THAC_BENH_SU: KHAI_THAC_BENH_SU,
        GHI_CHU: data.GHI_CHU,
        ...xetNghiem,
        LIST_DV_GOI_TT,
        MIEN_GIAM_TONG_HOA_DON: totalExp,
        TIEP_TAN_ID: userProfile.NHANSU_ID,
      };

      // console.log("------editAppointmentReq-------",editAppointmentReq)

      // return 
      setLoadingSubmit(true);

      // const isDup = await handleCheckDuplicateInfo(patient_data, form, duplicateInfoRef)
      // if (isDup) return false;
      const resEditBn = await common_post(apis.tiep_don_sua_thong_tin_benh_nhan, patientReq)
      if (resEditBn.status !== "OK") {
        notification.error({
          message: i18n.t(languageKeys.cap_nhat_that_bai),
          placement: "bottomLeft",
        });
        return;
      }

      // let response = await Promise.all([
      //   common_post(apis.chinh_su_dich_vu_cuoc_kham, editAppointmentReq),
      //   common_post(apis.tiep_don_sua_thong_tin_benh_nhan, patientReq),
      // ]);

      const res = await common_post(apis.chinh_su_dich_vu_cuoc_kham, editAppointmentReq)
      if (res && res.status === "OK") {
        notification.success({
          message: i18n.t(languageKeys.cap_nhat_thanh_cong),
          placement: "bottomLeft",
        });

        setLoadingSubmit(false);

        return true;
      } else {
        setLoadingSubmit(false);

        notification.error({
          placement: "bottomLeft",
          message: i18n.t(languageKeys.noti_Luu_lich_kham_that_bai),
        });
      }
    } catch (error) {
      handleSubmitFail();
      HLog("error update 216 modal tiepdon hook", error?.message);
    }
  };


  const formatTempDataPrint = (dataSource = [{}]) => {
    //
    const { benh_vien, config } = userProfile
    const customer = form.getFieldValue(undefined)
    //
    const totalAll = dataSource.reduce((sum, item) => sum + (
      item.SO_LUONG && item.GIA_DICH_VU
        ? item.SO_LUONG * item.GIA_DICH_VU
        : 0
    ), 0)
    const totalExemp = dataSource.reduce((sum, item) => sum + (
      item.MIEN_GIAM_TIEP_DON
        ? (item.MIEN_GIAM_TIEP_DON > 100
          ? item.MIEN_GIAM_TIEP_DON
          : (item.GIA_DICH_VU * item.SO_LUONG / 100 * item.MIEN_GIAM_TIEP_DON))
        : 0
    ), 0)
    //
    const commonInfo = {
      hospital: {
        avatar: benh_vien.ANH_DAI_DIEN,
        name: benh_vien.TEN_CSKCB,
        phone: benh_vien.SO_DIEN_THOAI,
        email: benh_vien.EMAIL,
        address: benh_vien.DIA_CHI_CHI_TIET
      },
      benh_nhan: {
        ma_benh_nhan: customer.MA_BENH_NHAN,
        gioi_tinh: customer.GIOI_TINH,
        sdt: customer.SO_DIEN_THOAI,
        ten_benh_nhan: customer.TEN,
        ngay_sinh: customer.NGAY_SINH,
        nam_sinh: customer.NGAY_SINH,
        dia_chi: customer.DIA_CHI_CHI_TIET
      },
      nguoi_hien_thi: userProfile.CHU_KY || getFullName(userProfile),
      mien_giam_tong_hoa_don: totalExp,
      tong_sau_mien_giam_dv: totalAll - totalExemp,
      template: "phieuTiepDonMoi"
    }
    //
    let dich_vu = []
    dataSource.forEach(item => {
      const index = dich_vu.findIndex(i => i.ID === item.PHONG_THUC_HIEN_ID)
      if (index >= 0) {
        dich_vu[index].data.push(item)
      } else {
        dich_vu.push({
          ID: item.PHONG_THUC_HIEN_ID,
          PHONG_THUC_HIEN: PHONG.find(i => i.ID === item.PHONG_THUC_HIEN_ID)?.TEN_KHOA_PHONG,
          data: [item],
        })
      }
    })
    dich_vu.forEach((dv, idx) => {
      let arr = []
      dv.data.forEach(item => {
        const index = arr.findIndex(i => i.TEN_LOAI_DICH_VU === item.TEN_LOAI_DICH_VU)
        if (index >= 0) {
          arr[index].data.push(item)
        } else {
          arr.push({
            TEN_LOAI_DV: item.TEN_LOAI_DICH_VU,
            data: [item],
          })
        }
      })
      dich_vu[idx].data = arr
    })
    //
    return config.NHIEU_PHIEU_CHIDINH_CLS
      ? dich_vu.map(item => ({ ...commonInfo, dich_vu: [item] }))
      : { ...commonInfo, dich_vu }
  }


  return { doCreateAppointment, doUpdateAppointment, loadingSubmit, setLoadingSubmit, formatTempDataPrint };
};


export const validFragmentBenhSu = (data) => {
  let {
    MACH = "", HUYET_AP = "", NHIP_THO = "", CAN_NANG = "", NHIET_DO = "", CHIEU_CAO = "",
    BMI = "", VONG_CO = "", VONG_BUNG = "", VONG_HONG_CHAU = "", SP_O2 = "",
    TUOI_MAN_KINH = "", DI_UNG = undefined,
    TRUYEN_MAU = undefined, TUNG_NHAP_VIEN = undefined, DANG_DT_DUNG_THUOC = undefined,
    SINH_CON = undefined, NHAP_VIEN_TAI_NAN = undefined, DI_UNG_VALUE = undefined,
    TUNG_NHAP_VIEN_VALUE = undefined, DANG_DT_DUNG_THUOC_VALUE = undefined,
    NHAP_VIEN_TAI_NAN_VALUE = undefined,
    // TRUYEN_MAU_VALUE = undefined,
    SINH_CON_VALUE = undefined,
    BENH_SU = [],
    TIEN_SU_GIA_DINH = [],
    NGUON_TIN = [],
    NGUYEN_VONG = "",
    NGOI_VA_DOC = 0,
    NGOI_YEN_O_NOI_CC = 0,
    NAM_NGHI_VAO_BUOI_CHIEU = 0,
    NGOI_IM_LANG_SAU_AN_TRUA = 0,
    XEM_TRUYEN_HINH = 0,
    NGOI_TRONG_MOT_TIENG_VOI_TU_CACH_LA_KH_TREN_OTO = 0,
    NGOI_VA_NOI_CHUYEN_VOI_NGUOI_KHAC = 0,
    NGOI_TRONG_XE_OTO = 0,
    TIEN_SU_BENH_KHAC = "",
    NHAP_VIEN_GAY_XUONG = undefined,
    KHAM_LOANG_XUONG = undefined,
    NHAP_VIEN_GAY_XUONG_VALUE = undefined,
    KHAM_LOANG_XUONG_VALUE = undefined
  } = data;
  BENH_SU = pickDataFromOptions(BENH_SU, optionsBenhSu);
  TIEN_SU_GIA_DINH = pickDataFromOptions(TIEN_SU_GIA_DINH, optionsTienSu);
  NGUON_TIN = pickDataFromOptions(NGUON_TIN, optionsNguonTin)
  let TAM_THU
  let TAM_TRUONG
  if (HUYET_AP) {
    TAM_THU = HUYET_AP.split("/")[0]
    TAM_TRUONG = HUYET_AP.split("/")[1]
  }
  return {
    CHI_SO_SINH_TON: { MACH, TAM_THU, TAM_TRUONG, NHIP_THO, CAN_NANG, NHIET_DO, CHIEU_CAO, BMI, VONG_CO, VONG_BUNG, VONG_HONG_CHAU, TUOI_MAN_KINH, SP_O2 },
    TIEN_SU_BAN_THAN: {
      DI_UNG,
      TRUYEN_MAU,
      TUNG_NHAP_VIEN,
      DANG_DT_DUNG_THUOC,
      BENH_SU,
      SINH_CON,
      NHAP_VIEN_TAI_NAN,
      DI_UNG_VALUE,
      //  TRUYEN_MAU_VALUE,
      SINH_CON_VALUE,
      TUNG_NHAP_VIEN_VALUE,
      DANG_DT_DUNG_THUOC_VALUE,
      NHAP_VIEN_TAI_NAN_VALUE,
      TIEN_SU_BENH_KHAC,
      NHAP_VIEN_GAY_XUONG,
      KHAM_LOANG_XUONG,
      NHAP_VIEN_GAY_XUONG_VALUE,
      KHAM_LOANG_XUONG_VALUE
    },
    TIEN_SU_GIA_DINH: TIEN_SU_GIA_DINH,
    THONG_TIN_KHAC: { NGUON_TIN, NGUYEN_VONG },
    TINH_TRANG_BUON_NGU: {
      NGOI_VA_DOC,
      NGOI_YEN_O_NOI_CC,
      NAM_NGHI_VAO_BUOI_CHIEU,
      NGOI_IM_LANG_SAU_AN_TRUA,
      XEM_TRUYEN_HINH,
      NGOI_TRONG_MOT_TIENG_VOI_TU_CACH_LA_KH_TREN_OTO,
      NGOI_VA_NOI_CHUYEN_VOI_NGUOI_KHAC,
      NGOI_TRONG_XE_OTO,
    },
  };
}

export const pickDataFromOptions = (data, options) => {
  let result = []
  for (const item of data) {
    let findValue = options.find(opt => opt.value === item)
    if (findValue) {
      result.push(findValue)
    }
  }
  return result
}

export const useModalTiepDon = (props) => {

  const {
    setLoadingSubmit,
    dsNguonKhach,
    userProfile,
    ttbnRef,
    form,
    initOffset,
    scrollToview,
    dsNhanSu,
    //================================================   
    lichKhamInfo,
    //================================================
    setLichKhamInfo,
    setExamType,
    setBundleReceipts,
    modalHuySID,
    setTotalExp,
  } = props;

  const dispatch = useDispatch();
  const data = useSelector((state) => state.data)

  const [selectedPatient, setSelectedPatient] = useState();
  // const [selectedDoiTuong, setSelectedDoiTuong] = useState();
  const [dsDvKhamBenhDaChon, setDsDvKhamBenhDaChon] = useState([]);
  const [barcodeQuanLy, setBarcodeQuanLy] = useState();
  const [statusDuyetSID, setStatusDuyetSID] = useState(false);
  const [dsDvClsDaChon, setDsDvClsDaChon] = useState([]);
  const [checkedContinueAdding, setCheckedContinueAdding] = useState(false);
  const [luuMau, setLuuMau] = useState(false);
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [checkPrint, setCheckPrint] = useState(false);
  const [editData, setEditData] = useState(false);
  const [widthTiepDon, setWidthTiepDon] = useState(false);
  const [currentTabView, setCurrentTabView] = useState(TAB_THONG_TIN_KB);
  const [hideDrawer, setHideDrawer] = useState(false);
  const [batBuoc, setBatBuoc] = useState(false);
  const [selectedNguonKhach, setSelectedNguonKhach] = useState();
  const [openTTBN, setOpenTTBN] = useState(true);
  const [openKhaiThac, setOpenKhaiThac] = useState(false);
  const [hiddenDetailInfo, setHiddenDetailInfo] = useState(false);
  const [offsetHeight, setOffsetHeight] = useState(initOffset);
  const [txtNote, setTxtNote] = useState("");
  const [selectedGoiService, setGoiSelectedService] = useState([]);
  const [loadingTT, setLoadingTT] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);

  const onHuyBarcode = async () => {
    modalHuySID.current.open();
  };

  const handleHuyBarcode = async () => {
    const { BARCODE_QUAN_LY } = form.getFieldValue(undefined)

    let LICH_KHAM_ID = lichKhamInfo?.LICH_KHAM_ID;
    let body = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      BARCODE_QUAN_LY: BARCODE_QUAN_LY,
      LICH_KHAM_ID: LICH_KHAM_ID
    };
    let res = await common_post(apis.huyGanBarcode, body);
    if (res && res?.status == "OK") {
      form.setFields([
        {
          name: "BARCODE_QUAN_LY",
          value: null
        },
        {
          name: "BARCODE_XET_NGHIEM",
          value: null
        }
      ])
      setBarcodeQuanLy(null)
      setStatusDuyetSID(false)
      modalHuySID.current?.setVisible(false)
    } else {
      notification.error({
        message: i18n.t(languageKeys.noti_thao_tac_that_bai),
        placement: "bottomLeft",
      });
      modalHuySID.current?.setVisible(false)
    }
  };

  useEffect(() => {
    if (currentTab == tabs[0] && openTTBN && hiddenDetailInfo) {
      setOffsetHeight(initOffset + 105);
    } else {
      setOffsetHeight(initOffset);
    }
  }, [currentTab, openTTBN, openKhaiThac]);

  const getPacketList = async (patient) => {
    try {
      let body = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        ARR_TRANG_THAI: ["HOAT_DONG"],
      };
      dispatch(TiepDonActions.doFetchDsGoiDvBenhNhan({ selectedPatient: !!patient ? patient : selectedPatient, lichKhamInfo, isGetAll: true }));
    } catch (error) {
      HLog(error);
    }
  };

  // useEffect(() => {
  //   if (selectedPatient && !selectedPatient.IS_GOI) {
  //     !visibleSubActions && getPacketList();
  //   }

  //   return () => { };
  // }, [selectedPatient,visibleSubActions]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSetRequire = useCallback((bool) => {
    setBatBuoc(bool);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleHideParent = useCallback((bool) => {
    setHideDrawer(bool);
  }, []);


  const getAppointmentInfo = async (data, isEdit, resetLichKhamInfo) => {

    setBarcodeQuanLy(null)
    setLoadingInit(true);

    try {
      const response = await common_post(apis.tiep_don_chi_tiet_lich_kham, { LICH_KHAM_ID: data.LICH_KHAM_ID });

      if (response && response.status === "OK") {
        let result = response.result;
        handleInitDataKhaiThac(result);

        if (!!result.DM_NGUON_KHACH_ID) {
          setSelectedNguonKhach(
            JSON.stringify({
              ID: result.DM_NGUON_KHACH_ID,
              TEN_NGUON_KHACH: result.TEN_NGUON_KHACH,
            })
          );
          dispatch(getExemption({ NGUON_KHACH_ID: result.DM_NGUON_KHACH_ID }))
        }

        initDsDv(
          result.DANG_KY_KHAM,
          result.DANH_SACH_DICH_VU
        );

        if (result.MIEN_GIAM_TONG_HOA_DON) setTotalExp(result.MIEN_GIAM_TONG_HOA_DON || 0)
        if (result.GHI_CHU) setTxtNote(result.GHI_CHU);
        if (result.BARCODE_XET_NGHIEM) setBarcodeQuanLy(result.BARCODE_XET_NGHIEM);
        if (result.IS_DA_DUYET) setStatusDuyetSID(true);

        if (result.DS_DV_GOI?.length) {
          if (result.DS_DV_GOI?.length > 0 && result.DANG_KY_KHAM?.length === 0 && result.DANH_SACH_DICH_VU?.length === 0) setExamType("TAB_GOI_DICH_VU")
          // let ds_goi = result.DS_DV_GOI.map(i => ({ ...i, GOI_DV_ID, old: true }));
          dispatch(TiepDonActions.setChiTietDsGoiDv(result.DS_DV_GOI));
          let data = result.DS_DV_GOI.map((item) => {
            const NOI_THUC_HIEN_XN = {
              ID: item.DV_XN_NOI_THUC_HIEN_ID,
              TEN_NOI_TH: item.TEN_NOI_TH,
              GIA_NHAP: item.GIA_NHAP,
              DV_XN_NOI_THUC_HIEN_ID: item.DV_XN_NOI_THUC_HIEN_ID,
            }
            const obj = ({
              ...item,
              DV_ID: item.DV_ID,
              key: rid(),
              NOI_THUC_HIEN_XN,
              checked: true,
              old: true,
              disabled: !showDeleteBtn(item),
              BAC_SI: { NHANSU_ID: item.BAC_SI_ID, TEN_BAC_SI: item.BAC_SI },
              _id: `${item.ID || item.ID_DV_IN_GOI}_GOI_${item.GOI_ID}`,
              ID: item.ID || item.ID_DV_IN_GOI,
            })
            return obj
          });
          setGoiSelectedService(data);
        }
        dispatch(TiepDonActions.doFetchDsGoiDvBenhNhan({ selectedPatient: result, lichKhamInfo: data.LICH_KHAM_ID, isGetAll: true }));

        if (resetLichKhamInfo) {
          // layTongTinThanhToan({ BENH_NHAN_ID: data?.BENH_NHAN_ID })
          let info = _.omit(result, ['DANG_KY_KHAM', 'DANH_SACH_DICH_VU', 'DS_DV_GOI'])
          setLichKhamInfo(c => ({
            ...info,
            runReload: true,
            GOI_TT: c?.GOI_TT ? c?.GOI_TT : 0,
            LICH_KHAM_ID: data.LICH_KHAM_ID,
            BENH_NHAN_ID: data.BENH_NHAN_ID
          }))
          dispatch(TiepDonActions.setSelectedLichKham(info))
          setEditData(true);
          setBundleReceipts([])
        }
        handleSelectPatient(result, isEdit, resetLichKhamInfo);
      }
    } catch (error) {
      HLog(error);
    } finally {
      setLoadingInit(false);
    }
  };

  const initDsDv = (dsKhamBenh = [], dsKhamCls = []) => {
    let dsDvKhamBenh = dsKhamBenh.map((item) => ({
      ...item,
      DV_ID: item.DICH_VU_ID,
      // dsGia: taoDanhSachGia(item, "KHAM_BENH").dsPrice,
      key: rid(),
      // PHONG_THUC_HIEN: {
      //   ...item,
      //   ID: item.PHONG_ID,
      //   GIO_BAT_DAU: item.GIO_BAT_DAU_LAM_VIEC_CUA_PHONG,
      //   GIO_KET_THUC: item.GIO_KET_THUC_LAM_VIEC_CUA_PHONG,
      // },
      THANH_TIEN: item.SO_LUONG * item.GIA_DICH_VU,
      checked: true,
      old: true,
      disabled: !showDeleteBtn(item),
      BAC_SI: { NHANSU_ID: item.BAC_SI_ID, TEN_BAC_SI: item.BAC_SI },
      LIST_PHONG_ID: data?.kham_benh?.find(i => i.DV_ID === item.DICH_VU_ID)?.LIST_PHONG_ID
    }))
    let dsCls = dsKhamCls.map((item) => {
      let obj = {
        ...item,
        DV_ID: item.DICH_VU_ID,
        // dsGia: taoDanhSachGia(item, "DICH_VU").dsPrice,
        key: rid(),
        // PHONG_THUC_HIEN: {
        //   ...item,
        //   ID: item.PHONG_ID,
        //   GIO_BAT_DAU: item.GIO_BAT_DAU_LAM_VIEC_CUA_PHONG,
        //   GIO_KET_THUC: item.GIO_KET_THUC_LAM_VIEC_CUA_PHONG,
        // },
        THANH_TIEN: item.SO_LUONG * item.GIA_DICH_VU,
        checked: true,
        old: true,
        disabled: !showDeleteBtn(item),
        BAC_SI: { NHANSU_ID: item.BAC_SI_ID, TEN_BAC_SI: item.BAC_SI },
        LIST_PHONG_ID: data?.cls?.flatMap(i => i.DICH_VU).find(i => i.DV_ID === item.DICH_VU_ID)?.LIST_PHONG_ID,
        NOI_THUC_HIEN_XN: {
          ...item,
          ID: item.DV_XN_NOI_THUC_HIEN_ID,
        },
        LIST_NOI_THUC_HIEN_XN: data?.cls?.flatMap(i => i.DICH_VU).find(i => i.DV_ID === item.DICH_VU_ID)?.LIST_NOI_THUC_HIEN_XN,
        children: item.DV_KEM_THEO?.map(i => ({
          ...i,
          _id: rid(7),
          key: rid(),
          // TEN_DICHVU: i.TEN_DICHVU || i.TEN_DICH_VU_KEM_THEO,
          PHONG_THUC_HIEN: {
            ...i,
            GIO_BAT_DAU: i.GIO_BAT_DAU_LAM_VIEC_CUA_PHONG,
            GIO_KET_THUC: i.GIO_KET_THUC_LAM_VIEC_CUA_PHONG,
          },
          LIST_NOI_THUC_HIEN_XN: data?.cls
            ?.flatMap((i) => i.DICH_VU)
            .find((i) => i.DV_ID === i.DV_KEM_THEO_ID)?.LIST_NOI_THUC_HIEN_XN,
          NOI_THUC_HIEN_XN: {
            GIA_NHAP: i?.GIA_NHAP_LAB || 0,
            TEN_NOI_TH: i?.TEN_NOI_TH || "",
            DV_XN_NOI_THUC_HIEN_ID: i?.DV_XN_NOI_THUC_HIEN_ID || "",
          },
        }))
      }
      //kiểm tra có dv con không
      // if (obj?.children && obj?.children?.length > 0) {
      //   obj = { ...obj };
      //   const x = obj.children.map((childrenObj) => {
      //     const children = { ...childrenObj };
      //     // Lấy LIST_NOI_THUC_HIEN_XN (với dịch vụ xét nghiệm và có lab )
      //     const LIST_NOI_THUC_HIEN_XN_CHILDREN = data?.cls
      //       ?.flatMap((i) => i.DICH_VU)
      //       .find((i) => i.DV_ID === childrenObj.DV_KEM_THEO_ID)?.LIST_NOI_THUC_HIEN_XN;
      //     if (LIST_NOI_THUC_HIEN_XN_CHILDREN && LIST_NOI_THUC_HIEN_XN_CHILDREN?.length > 0) {

      //       // Set list danh sách chọn nơi thực hiện
      //       // Set giá trị nơi thực hiện detail
      //       children.LIST_NOI_THUC_HIEN_XN = LIST_NOI_THUC_HIEN_XN_CHILDREN;
      //       children.NOI_THUC_HIEN_XN = {
      //         GIA_NHAP: children?.GIA_NHAP_LAB || 0,
      //         TEN_NOI_TH: children?.TEN_NOI_TH || "",
      //         DV_XN_NOI_THUC_HIEN_ID: children?.DV_XN_NOI_THUC_HIEN_ID || "",
      //       };
      //     }

      //     return children;
      //   });

      //   obj.children = x;
      // }
      return obj;
    })
    //============================================================================
    setDsDvKhamBenhDaChon(dsDvKhamBenh);
    setDsDvClsDaChon(dsCls);

    // dispatch(TiepDonActions.setChiTietLichKham({ DANG_KY_KHAM: dsDvKhamBenh, DANH_SACH_DICH_VU: dsCls }));
  };

  const taoDanhSachGia = (value, type = "KHAM_BENH") => {
    let gia_bhyt = {
      name: i18n.t(languageKeys.field_Gia_bhyt),
      price: handleGetProperty(value, "GIA_BHYT", 0),
      key: "GIA_BHYT",
    };

    let gia_yc = {
      name: i18n.t(languageKeys.field_Gia_dich_vu),
      price: handleGetProperty(value, "GIA_DICHVU", 0),
      key: "GIA_DICHVU",
    };

    let gia_nc_ngoai = {
      name: i18n.t(languageKeys.field_Gia_nuoc_ngoai),
      price: handleGetProperty(value, "GIA_NUOC_NGOAI", 0),
      key: "GIA_NUOC_NGOAI",
    };

    let gia_thuphi = {
      name: i18n.t(languageKeys.field_Gia_thu_phi),
      price: handleGetProperty(value, "GIA_THU_PHI", 0),
      key: "GIA_THU_PHI",
    };

    let dsPrice;

    let dsPriceVienPhi = [gia_thuphi, gia_yc];

    const config = userProfile.config;

    if (
      !!config.DM_DICHVU_KYTHUAT &&
      !!config.DM_DICHVU_KYTHUAT.isCo &&
      Array.isArray(config.DM_DICHVU_KYTHUAT.TRUONG_BO) &&
      !!config.DM_DICHVU_KYTHUAT.TRUONG_BO.some((item) => item === "GIA_YEU_CAU")
    ) {
      dsPriceVienPhi.shift();
    }

    // if (type === "KHAM_BENH") {
    //   switch (selectedDoiTuong) {
    //     case keys.BHYT:
    //       dsPrice = [gia_bhyt, gia_yc, gia_thuphi];
    //       break;
    //     case keys.VIEN_PHI:
    //       dsPrice = dsPriceVienPhi;
    //       break;
    //     case keys.YEU_CAU:
    //       dsPrice = [gia_yc, gia_thuphi];
    //       break;
    //     case keys.NUOC_NGOAI:
    //       dsPrice = [gia_nc_ngoai];
    //       break;
    //     default:
    //       dsPrice = [gia_bhyt, gia_yc, gia_thuphi];
    //       break;
    //   }
    // } else {
    //   switch (selectedDoiTuong) {
    //     case keys.NUOC_NGOAI:
    //       dsPrice = [gia_nc_ngoai];
    //       break;
    //     default:
    //       dsPrice = [gia_thuphi];
    //       break;
    //   }
    // }

    let GIA_DICH_VU = 0;
    // if (type === "KHAM_BENH") {
    //   switch (selectedDoiTuong) {
    //     case keys.BHYT:
    //       GIA_DICH_VU =
    //         handleGetProperty(value, "GIA_BHYT", 0) === 0 ? handleGetProperty(value, "GIA_THU_PHI", 0) : handleGetProperty(value, "GIA_BHYT", 0);
    //       break;
    //     case keys.VIEN_PHI:
    //       GIA_DICH_VU =
    //         handleGetProperty(value, "GIA_THU_PHI", 0) === 0 ? handleGetProperty(value, "GIA_DICHVU", 0) : handleGetProperty(value, "GIA_THU_PHI", 0);
    //       break;
    //     case keys.YEU_CAU:
    //       GIA_DICH_VU =
    //         handleGetProperty(value, "GIA_DICHVU", 0) === 0 ? handleGetProperty(value, "GIA_THU_PHI", 0) : handleGetProperty(value, "GIA_DICHVU", 0);
    //       break;
    //     case keys.NUOC_NGOAI:
    //       GIA_DICH_VU =
    //         handleGetProperty(value, "GIA_NUOC_NGOAI", 0) === 0
    //           ? handleGetProperty(value, "GIA_THU_PHI", 0)
    //           : handleGetProperty(value, "GIA_NUOC_NGOAI", 0);
    //       break;
    //     default:
    //       GIA_DICH_VU = handleGetProperty(value, "GIA_DICHVU", 0);
    //       break;
    //   }
    // } else {
    //   GIA_DICH_VU = handleGetProperty(value, "GIA_THU_PHI", 0);
    //   // GIA_DICH_VU =
    //   //   handleGetProperty(value, "GIA_THU_PHI", 0) === 0
    //   //     ? handleGetProperty(value, "GIA_DICHVU", 0)
    //   //     : handleGetProperty(value, "GIA_THU_PHI", 0);
    // }
    return { dsPrice, GIA_DICH_VU };
  };

  /**
   * 
   * @param {Object} patient Dữ liệu bệnh nhân được chọn
   * @param {boolean} isEdit nếu là xem cuộc khám
   */
  const handleSelectPatient = (patient, isEdit, clearBundle) => {
    try {
      if (!clearBundle) {
        dispatch(TiepDonActions.setDsGoiDvBenhNhan([]))
        if (patient.NGUON_KHACH_ID) {
          handleSelectNguonkhach(JSON.stringify({ ID: patient.NGUON_KHACH_ID, TEN_NGUON_KHACH: "Siuuuuuuu" }))
        } else {
          if (selectedNguonKhach) {
            setSelectedNguonKhach()
            setDsDvKhamBenhDaChon(dsDvKhamBenhDaChon.map(item => ({
              ...item,
              MIEN_GIAM_TIEP_DON: 0
            })));
            setDsDvClsDaChon(dsDvClsDaChon.map(item => ({
              ...item,
              MIEN_GIAM_TIEP_DON: 0
            })));
          }
        }
      }
      HLog("select patient", patient);
      setHiddenDetailInfo(true);
      scrollToview();
      setSelectedPatient(patient);
      if (patient[fieldTiepDon.ngay_sinh]) {
        const birthDate = patient[fieldTiepDon.ngay_sinh];
        if (!isNaN(Number(birthDate))) {
          let today = moment().format("YYYY");
          if (today - moment(birthDate, "YYYYMMDD").format("YYYY") < 16) {
            !batBuoc && handleSetRequire(true);
          } else {
            batBuoc && handleSetRequire(false);
          }
        }
      }
      // dvkbRef.current?.reset();
      // const thonTinTiepTan = await JSON.stringify({USERNAME:userProfile.USERNAME,NHANSU_ID:userProfile.NHANSU_ID,NGUOI_HIEN_THI:`${userProfile?.HO} ${userProfile?.TEN}`})

      form.setFields(
        Object.values(fieldTiepDon).map((fieldName) => {
          let name = fieldName;
          let value = patient[fieldName];

          if (fieldName === fieldTiepDon.ngay_sinh) {
            ttbnRef.current.setDate(value);
            value = value.length === 4 ? value : moment(value, "YYYYMMDD").format("DDMMYYYY");
          }
          if (fieldName === fieldTiepDon.uu_tien) {
            value = !!value;
          }

          if (fieldName === fieldTiepDon.tinh_thanh) {
            ttbnRef.current?.setProvinceId(patient[fieldTiepDon.ma_tinh_thanh]);
            value = patient[fieldTiepDon.tinh_thanh];
          }
          if (fieldName === fieldTiepDon.quan_huyen) {
            ttbnRef.current?.setDistrictId(patient[fieldTiepDon.ma_quan_huyen]);
            value = patient[fieldTiepDon.quan_huyen];
          }
          if (fieldName === fieldTiepDon.xa_phuong) {
            value = patient[fieldTiepDon.xa_phuong];
          }

          if ((fieldName === fieldTiepDon.DM_NGUON_KHACH_ID) || fieldName === fieldTiepDon.NGUON_KHACH_ID) {
            if ((!!patient.DM_NGUON_KHACH_ID && !!patient.TEN_NGUON_KHACH)) {
              try {
                value = JSON.stringify({
                  ID: patient.DM_NGUON_KHACH_ID,
                  TEN_NGUON_KHACH: patient.TEN_NGUON_KHACH,
                });
              } catch (error) {
                value = null;
              }
            } else if (patient && patient.NGUON_KHACH_ID) {
              try {
                let nguon_khach_id = patient.NGUON_KHACH_ID;
                let findNguonKhach = dsNguonKhach.find(c => c.ID === nguon_khach_id);
                let selectNguonkhach = { ID: findNguonKhach?.ID, TEN_NGUON_KHACH: findNguonKhach?.TEN_NGUON_KHACH };
                let jsonNguonKhach = JSON.stringify(selectNguonkhach);
                if (jsonNguonKhach === '{}') { jsonNguonKhach = undefined }
                setSelectedNguonKhach(() => {
                  return jsonNguonKhach;
                });
                value = jsonNguonKhach;
              } catch (error) {
                value = undefined;
              }
            } else {
              value = undefined;

              // if (!!dsNguonKhach.length && dsNguonKhach.length < 2) {
              //   let firstNguonKhach = dsNguonKhach[0];
              //   firstNguonKhach = { ID: firstNguonKhach.ID, TEN_NGUON_KHACH: firstNguonKhach.TEN_NGUON_KHACH };
              //   value = firstNguonKhach.TEN_NGUON_KHACH;

              //   setSelectedNguonKhach(() => {
              //     return JSON.stringify(firstNguonKhach);
              //   });
              // }
            }
            // return { name, value: JSON.stringify(dsDoiTuong.find((item) => item.ID === patient[fieldTiepDon.DM_DOI_TUONG_ID])) };
          }
          if ((fieldName === fieldTiepDon.NHAN_VIEN_TU_VAN_ID)) {
            if ((!!patient.NHAN_VIEN_TU_VAN_ID && !!patient.TEN_NHAN_VIEN_TU_VAN)) {
              try {
                value = JSON.stringify({
                  TEN_NHAN_VIEN_TU_VAN: patient.TEN_NHAN_VIEN_TU_VAN,
                  NHAN_VIEN_TU_VAN_ID: patient.NHAN_VIEN_TU_VAN_ID
                });
              } catch (error) {
                value = null;
              }
            } else if (patient && patient.NHAN_VIEN_TU_VAN_ID) {
              try {
                let nhan_vien_tu_van_id = patient.NHAN_VIEN_TU_VAN_ID;
                let findNhanVienTuVan = dsNhanSu.find(c => c.ID === nhan_vien_tu_van_id);
                let selectNhanVienTuVan = { NHAN_VIEN_TU_VAN_ID: findNhanVienTuVan?.ID, TEN_NHAN_VIEN_TU_VAN: `${findNhanVienTuVan?.HO} ${findNhanVienTuVan?.TEN}` };
                let jsonNhanVienTuVan = JSON.stringify(selectNhanVienTuVan);
                if (jsonNhanVienTuVan === '{}') { jsonNhanVienTuVan = undefined }
                value = jsonNhanVienTuVan;
              } catch (error) {
                value = undefined;
              }
            } else {
              value = undefined;
            }
          }

          if (fieldName === fieldTiepDon.NGUOI_HIEN_THI) {
            if (isEdit) {
              value = patient.NGUOI_HIEN_THI;
            } else value = `${userProfile?.HO} ${userProfile?.TEN}`;
          }

          ttbnRef.current?.setDisabledEthnic(!patient[fieldTiepDon.dan_toc]);

          return {
            name,
            value: !!value ? value : null,
            errors: [],
          };
        })
      );
    } catch (error) {
      HLog('ERORRRRERORRRRERORRRRERORRRRERORRRR', error);
    }
  };

  const handleClearPatient = (reset = false) => {
    setSelectedPatient();
    setGoiSelectedService([])
    form.resetFields(['MA_BENH_NHAN']);
    dispatch(TiepDonActions.setDsGoiDvBenhNhan([]));
    // dispatch(TiepDonActions.setSelectedPacket());
    if (reset) form.resetFields();

  }

  const handleInitDataKhaiThac = async (result) => {
    const benh_su = result?.THONG_TIN_BENH_SU?.[0]
    if (!benh_su) return;
    try {
      let { THONG_TIN_KHAC, TIEN_SU_BAN_THAN, TIEN_SU_GIA_DINH, CHI_SO_SINH_TON, BARCODE_XET_NGHIEM, BARCODE_QUAN_LY, ...TINH_TRANG_BUON_NGU } = benh_su;
      THONG_TIN_KHAC = THONG_TIN_KHAC ? JSON.parse(THONG_TIN_KHAC) : null;
      TIEN_SU_BAN_THAN = TIEN_SU_BAN_THAN ? JSON.parse(TIEN_SU_BAN_THAN) : null;
      CHI_SO_SINH_TON = CHI_SO_SINH_TON ? JSON.parse(CHI_SO_SINH_TON) : null;
      TIEN_SU_GIA_DINH = TIEN_SU_GIA_DINH ? JSON.parse(TIEN_SU_GIA_DINH) : [];
      // TINH_TRANG_BUON_NGU = TINH_TRANG_BUON_NGU ? JSON.parse(TINH_TRANG_BUON_NGU) : null;
      let { TAM_THU = "", TAM_TRUONG = "", VONG_BUNG } = CHI_SO_SINH_TON;

      let paser_data = {
        BARCODE_XET_NGHIEM: BARCODE_XET_NGHIEM?.toString() || null,
        BARCODE_QUAN_LY: BARCODE_QUAN_LY?.toString() || null,
        ...THONG_TIN_KHAC,
        TIEN_SU_GIA_DINH: TIEN_SU_GIA_DINH,
        ...CHI_SO_SINH_TON,
        ...TIEN_SU_BAN_THAN,
        HUYET_AP: TAM_THU && TAM_TRUONG ? `${TAM_THU}/${TAM_TRUONG}` : "",
        ...TINH_TRANG_BUON_NGU,
      };

      if ([dataPartnerCode.dth].includes(userProfile.partner_code)) {
        let VONG_BUNG = CHI_SO_SINH_TON?.VONG_BUNG;
        VONG_BUNG = VONG_BUNG?.split?.(",");
        let index = 0;
        for (const key in fieldVongBung) {
          paser_data[fieldVongBung[key]] = VONG_BUNG[index]?.trim?.();
          index++;
        }
      }

      let fieldValues = Object.keys(paser_data).map((item) => {
        if (["BENH_SU", "TIEN_SU_GIA_DINH", "NGUON_TIN"].includes(item)) {
          return {
            name: item,
            value: paser_data[`${item}`].map((item) => item.value),
          };
        }
        return {
          name: item,
          value: paser_data[`${item}`],
        };
      });
      form.setFields(fieldValues);
    } catch (e) {
    }
  };

  const layDsIdDvDaChon = () => {
    const dsKhamBenhIn = dsDvKhamBenhDaChon.filter((item) => !!item.checked).map((item) => item.DICH_VU_ID || item.DV_ID);
    const dsClsIn = dsDvClsDaChon.filter((item) => !!item.checked).map((item) => item.DICH_VU_ID || item.DV_ID);

    // console.log("xxx", dsKhamBenhIn, dsClsIn);

    return [...dsKhamBenhIn, ...dsClsIn];
  };

  const layBacSiThucHienTheoNgay = async () => {
    try {
      const res = await common_post("api/partner/public/lichkham/layBacSiThucHienTheoNgay", {
        NGAY: moment().format("YYYYMMDD"),
        PHONG_ID: null,
      })
      if (res.status === "OK") {
        dispatch(setTodayDoctors(res))
      } else {
        notification.error({ message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai) })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const layTongTinThanhToan = async (patient) => {
    setLoadingTT(true)
    try {
      const res = await common_post('api/partner/public/benhNhan/layThongTinThanhToanBenhNhan', {
        BENH_NHAN_ID: patient.BENH_NHAN_ID
      });
      if (res && res.status === "OK") {

        dispatch(TiepDonActions.setThongTinThanhToan(res))
      }
    } catch (error) {

    } finally {
      setLoadingTT(false)
    }
  }

  //============================================================================

  // const readOnly = !!lichKhamInfo
  //   ? lichKhamInfo?.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HOAN_THANH ||
  //   lichKhamInfo?.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HUY ||
  //   lichKhamInfo?.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.DANG_KHAM
  //   : false;

  const readOnly = !!lichKhamInfo
    ? lichKhamInfo?.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HUY
    : false;

  const allowAddNewServices = !!lichKhamInfo
    ? lichKhamInfo?.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.DANG_KHAM ||
    lichKhamInfo?.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.KHAM_XONG ||
    lichKhamInfo?.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.CHO_KHAM
    : true;


  const getSIDApi = async () => {
    try {
      let payload = { partner_code: userProfile.partner_code, BENH_VIEN_ID: userProfile.BENH_VIEN_ID };
      if (userProfile?.config?.BARCODE_XET_NGHIEM === "BARCODE_VINH_VIEN" && userProfile?.config?.BARCODE_LENGTH) {
        payload.BARCODE_LENGTH = userProfile?.config?.BARCODE_LENGTH;
      }
      const res = await common_post(apis.lay_SID, payload);
      if (res.status === "OK") {
        form.setFieldValue("BARCODE_XET_NGHIEM", res.BARCODE_LIST[0]["random_barcode"]);
      } else {
        notification.error({ message: i18n.t(languageKeys.thao_tac_that_bai), placement: "bottomLeft" });
      }
    } catch (error) {
      notification.error({ message: i18n.t(languageKeys.thao_tac_that_bai), placement: "bottomLeft" });
    }
  };

  const handleSelectNguonkhach = (value) => {
    setSelectedNguonKhach(value);
    value && dispatch(getExemption({
      NGUON_KHACH_ID: JSON.parse(value).ID,
      onSuccess: (exp) => {
        if (dsDvClsDaChon.length) {
          setDsDvClsDaChon(dsDvClsDaChon.map(item => ({
            ...item,
            MIEN_GIAM_TIEP_DON: exp.find(i => i.DV_ID === item.DV_ID)?.MIEN_GIAM || 0
          })))
        }
        if (dsDvKhamBenhDaChon.length) {
          setDsDvKhamBenhDaChon(dsDvKhamBenhDaChon.map(item => ({
            ...item,
            MIEN_GIAM_TIEP_DON: exp.find(i => i.DV_ID === item.DV_ID)?.MIEN_GIAM || 0
          })))
        }
      }
    }))
  }

  return {
    selectedPatient,
    dsDvKhamBenhDaChon,
    dsDvClsDaChon,
    checkedContinueAdding,
    currentTab,
    checkPrint,
    editData,
    widthTiepDon,
    currentTabView,
    hideDrawer,
    batBuoc,
    selectedNguonKhach,
    openTTBN,
    openKhaiThac,
    hiddenDetailInfo,
    offsetHeight,
    txtNote,
    // selectedDoiTuong,
    readOnly,
    allowAddNewServices,
    luuMau,
    //================================================
    setLuuMau,
    handleSetRequire,
    handleHideParent,
    getAppointmentInfo,
    setDsDvKhamBenhDaChon,
    setSelectedPatient,
    // setSelectedDoiTuong,
    setDsDvClsDaChon,
    setCheckedContinueAdding,
    setCurrentTab,
    setCheckPrint,
    setEditData,
    setWidthTiepDon,
    setCurrentTabView,
    setHideDrawer,
    setBatBuoc,
    setSelectedNguonKhach,
    setOpenTTBN,
    setOpenKhaiThac,
    setHiddenDetailInfo,
    setOffsetHeight,
    setTxtNote,
    initDsDv,
    taoDanhSachGia,
    handleClearPatient,
    handleSelectPatient,
    handleInitDataKhaiThac,
    layDsIdDvDaChon,
    getPacketList,
    layBacSiThucHienTheoNgay,
    //==================================================
    selectedGoiService,
    setGoiSelectedService,
    layTongTinThanhToan,
    loadingTT,

    //========
    setBarcodeQuanLy,
    barcodeQuanLy,
    setStatusDuyetSID,
    statusDuyetSID,
    modalHuySID,
    onHuyBarcode,
    handleHuyBarcode,
    getSIDApi,
    handleSelectNguonkhach,
    loadingInit
  };
};

const appointmentColumnStandard = {
  BENH_NHAN_ID: "",
  DOI_TUONG_BENH_NHAN: "",
  TIEP_TAN_ID: "",
  MO_TA: "",
  GHI_CHU: "",
  LICH_KHAM_ID: "",
  TEN_NGUOI_THAN: "",
  SDT_NGUOI_THAN: "",
  QUAN_HE_NGUOI_THAN: "",
  DIA_CHI_NGUOI_THAN: "",
  NGUOI_HIEN_THI: "",
  GOI_DV_ID: "",
  // DM_DOI_TUONG_ID:"",
  DM_NGUON_KHACH_ID: "",
  NHAN_VIEN_TU_VAN_ID: "",
  TEN_MAU: "",
  BARCODE_XET_NGHIEM: "",
  BARCODE_QUAN_LY: "",
};
