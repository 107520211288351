import { Dropdown, Form, Input, Tooltip } from "antd";
import { memo, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { VirtualTable } from "../../../../components";
import i18n, { languageKeys } from "../../../../i18n";
import { fieldXa } from "../../../QuanLyHeThong/QuanLyDanhMuc/fieldsDanhMuc";
import { columnsTHX } from "../../columnsTiepDon";
import { fieldTiepDon } from "../../fieldsTiepDon";
import style from "./searchThx.module.less";

const SearchTHX = forwardRef(({ form, name = fieldTiepDon.t_h_x, readOnly = false, onSelect = () => {}, disabled=false, tabIndex }, ref) => {
  const inputRef = useRef();
  const valInputRef = useRef();
  const [visible, setVisible] = useState(false);
  const [searchString, setSearchString] = useState("");
  const defaultListTHX = useSelector((state) => state.data.thx);
  const [searchList, setSearchList] = useState();

  useImperativeHandle(ref, () => ({
    reset: () => {
      setVisible(false);
    },
  }));

  const onClickRow = (val) => {
    setSearchString("");
    setSearchList();
    setVisible(false);
    onSelect(val);
    valInputRef.current.focus();

    console.log("select thx", val);

    form.setFields([
      {
        name: name,
        value: val[fieldXa.code_thx],
        errors: [],
      },
    ]);
  };

  if (readOnly) {
    return (
      <Tooltip title={form.getFieldValue(name)} placement="bottomLeft">
        <Form.Item name={name} label={i18n.t(languageKeys.field_THX)}>
          <Input readOnly disabled={disabled}/>
        </Form.Item>
      </Tooltip>
    );
  }

  const handleSearch = (e) => {
    // let value = e.target.value;
    let value = e;


    // xóa các khoảng trống ở đầu và cuối string
    value = value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");

    if (value.length === 0) {
      if (searchList?.length > 0) setSearchList();
      return console.log("input not found");
    }

    const first_letter = value.slice(0, 1);

    const list_by_first_letter = defaultListTHX[first_letter.toUpperCase()];
    const result = list_by_first_letter?.filter((item) => item.TEN_VIET_TAT.toLowerCase().includes(value.toLowerCase()));
    setSearchList(result || []);
  };

  return (
    <Dropdown
      // disabled
      trigger="click"
      visible={visible}
      onVisibleChange={(bool) => {
        setVisible(bool);

        if (bool) {
          // layTHX();
          setTimeout(() => {
            inputRef.current?.focus();
          }, 100);
        } else {
          setSearchString("");
          setSearchList();
        }
      }}
      disabled={disabled}
      placement="bottomLeft"
      overlay={
        <div className={style["popup-ctn"]}>
          <div className={style["input"]}>
            <Input
              placeholder={i18n.t(languageKeys.common_Tim_kiem)}
              ref={inputRef}
              value={searchString}
              onChange={(e) => {
                const { value } = e.target;
                setSearchString(value);
                handleSearch(value)
              }}
              // onPressEnter={handleSearch}
              onBlur={()=> {
                  setTimeout(() => {
                    setVisible(false)
                  }, 500);
                }}

            tabIndex={8}
            />
          </div>

          <VirtualTable
            columns={columnsTHX}
            dataSource={!!searchList ? searchList : defaultListTHX["A"]}
            scroll={{ y: 250 }}
            rowKey="ID"
            style={{ width: 700 }}
            onClickRow={onClickRow}
          />
        </div>
      }
      overlayClassName={style["popup-thx"]}
    >
      <Tooltip title={form.getFieldValue(name)} placement="bottomLeft">
        <Form.Item name={name} label={i18n.t(languageKeys.field_THX)}>
          <Input
            ref={valInputRef}
            readOnly
            placeholder={i18n.t(languageKeys.common_Nhap)}
            style={{ cursor: "pointer" }}
            onPressEnter={() => {
              if (!visible) {
                setVisible(true);
                // layTHX();
                setTimeout(() => {
                  inputRef.current?.focus();
                }, 100);
              }
            }}


            tabIndex={tabIndex}
            disabled={disabled}
          />
        </Form.Item>
      </Tooltip>
    </Dropdown>
  );
});

export default memo(SearchTHX);
