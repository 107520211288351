import React from "react";
import { Row , Col } from "antd";
import styles from "./style.module.less";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons"
import { ArrowDownCollapse, ArrowRightCollapse } from "assets/svg";
const HeaderElement = (
    {
        title = "",
        open = true,
        onOpen = () => {

        },
        border = false,
        bgColor
    }
) => {

    return (
        <Row className={styles['container']} align={"middle"} onClick={onOpen}>
            <Col>
                <div className={styles['box-icon']}>
                    {open ? <ArrowRightCollapse /> : <ArrowDownCollapse /> }
                </div>
            </Col>
            <Col className={styles["title"]}>
                {title}
            </Col>
        </Row>
    )
}
export default HeaderElement;
