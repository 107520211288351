import { Row, Col, Form, Input, DatePicker as DatePickerAnt } from "antd";
import i18n, { languageKeys } from "../../../../../i18n";
import styles from "./style.module.less";
import moment from "moment";
import { fieldKhamBenh, tinhTuoiThai } from "../constantsTTK";


const KhamSanCDXL = ({ form, readOnly, isEditAllowed }) => {

  const Item = ({ children, ...props }) => (
    <Form.Item style={{ marginBottom: 6 }} {...props}>
      {children}
    </Form.Item>
  )

  const DatePicker = (props) => (
    <DatePickerAnt
      placeholder={i18n.t(languageKeys.common_Chon)}
      format="DD/MM/YYYY"
      inputReadOnly
      style={{ width: '100%' }}
      disabled={readOnly || !isEditAllowed}
      {...props}
    />
  )

  return (
    <Row>
      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.tuoi_thai)}</span>
      </Col>
      <Col span={24}>
        <Row
          gutter={10}
          className={styles.wrapper}
          style={{ paddingBottom: 15, margin: 0 }}
        >
          <Col span={8}>
            <span className={styles.label}>{i18n.t(languageKeys.ngay_kinh_cuoi)}</span>
            <Item name={fieldKhamBenh.NGAY_KINH_CUOI}>
              <DatePicker
                disabledDate={(current) => current && current > moment().endOf('day')}
                onChange={(date) => {
                  form.setFields([
                    {
                      name: fieldKhamBenh.DU_KIEN_SINH,
                      value: date ? moment(date).startOf('day').add(280, 'days') : undefined,
                    },
                    {
                      name: fieldKhamBenh.TUOI_THAI_HIEN_TAI,
                      value: tinhTuoiThai(date),
                    }
                  ])
                }}
              />
            </Item>
          </Col>
          <Col span={8}>
            <span className={styles.label}>{i18n.t(languageKeys.du_kien_sinh)}</span>
            <Item name={fieldKhamBenh.DU_KIEN_SINH}>
              <DatePicker disabledDate={(current) => current && current < moment().startOf('day')} />
            </Item>
          </Col>
          <Col span={8}>
            <span className={styles.label}>{i18n.t(languageKeys.tuoi_thai_hien_tai)}</span>
            <Item name={fieldKhamBenh.TUOI_THAI_HIEN_TAI}>
              <Input
                style={{ background: 'white' }}
                readOnly
              />
            </Item>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default KhamSanCDXL;