import { AutoComplete, Input, Spin } from "antd";
import { useState } from "react";
import i18n, { languageKeys } from "../../../../../i18n";

export const SelectDuongDung = ({ readOnly = false, onSelect = () => {}, value, rowIndex, dataSource, loading }) => {

  const [open, setOpen] = useState(false)

  const handleChange = (val) => {
    onSelect(val, rowIndex, "Duongdung")
    setOpen(false)
  }

  // return (
  //   <Form.Item name="Duongdung" label={i18n.t(languageKeys.duong_dung)}
  //   //  rules={[{ required: true, message: i18n.t(languageKeys.vui_long_chon) }]}
  //    >
  //     {readOnly ? (
  //       <Input readOnly />
  //     ) : (
  //       <Select
  //         dataSource={dataSource}
  //         labelKey="TEN_DUONG_DUNG_BYT"
  //         valueKey="TEN_DUONG_DUNG_BYT"
  //         filterOption={(item, option) => option.children.toLowerCase().includes(item.toLowerCase())}
  //         allowClear={false}
  //         onSelect={onSelect}
  //       />
  //     )}
  //   </Form.Item>
  // );
  return readOnly ? <Input readOnly value={value} />
    : (
      // <Select
      //   dataSource={dataSource}
      //   labelKey="TEN_DUONG_DUNG_BYT"
      //   valueKey="TEN_DUONG_DUNG_BYT"
      //   filterOption={(item, option) => option.children.toLowerCase().includes(item.toLowerCase())}
      //   onSelect={(val) => {
      //     onSelect(val, rowIndex, "TEN_DUONG_DUNG_BYT")
      //     setOpen(false)
      //   }}
      //   value={value}
      //   loading={loading}
      //   open={open}
      //   onClick={() => setOpen(!open)}
      //   onFocus={() => setTimeout(() => setOpen(true), 100)}
      //   onBlur={() => setOpen(false)}
      //   onClear={(val) => onSelect(val, rowIndex, "TEN_DUONG_DUNG_BYT")}
      // />
      <Spin spinning={loading}>
        <AutoComplete
          open={open}
          options={dataSource.map(i => ({ value: i.TEN_DUONG_DUNG_BYT }))}
          disabled={readOnly}
          defaultValue={value}
          placeholder={i18n.t(languageKeys.common_Nhap)}
          allowClear
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onSelect={handleChange}
          onBlur={(e) => handleChange(e.target.value)}
          onFocus={() => setTimeout(() => setOpen(true), 100)}
          onClick={() => setOpen(!open)}
        />
      </Spin>
    )
};
