import { FormDrawer, Table } from "components"
import { QlyTamUngData, getDsChuaThanhToan } from "ducks/slices/QlyVienPhi/QlyTamUng.slice"
import { formLoadingState } from "ducks/slices/loadingSlice"
import i18n, { languageKeys } from "i18n"
import moment from "moment"
import { useImperativeHandle, useRef, useState, forwardRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormDvCanTT from "./FormDvCanTT"
import { formatCurrency } from "helpers"
import { Button } from "antd"

const FormDsChuaTT = forwardRef(({ }, ref) => {

    const dispatch = useDispatch()
    const { result: DsPhTamUng, dsChTT } = useSelector(QlyTamUngData)
    const loading = useSelector(formLoadingState)

    const formRef = useRef()
    const [open, setOpen] = useState(false);
    const [receiptInfo, setReceiptInfo] = useState()

    useImperativeHandle(ref, () => ({
        open: (data) => {
            dispatch(getDsChuaThanhToan({
                search_string: "",
                PHONG_ID: "",
                LOAI: "CHO_THANH_TOAN",
                limit: 99999,
                page: 1,
                BENH_NHAN_ID: data.BENH_NHAN_ID,
                exclude_goi: 1
            }))
            setOpen(true)
            setReceiptInfo(data)
        },
    }))

    useEffect(() => {
        setOpen(false)
    },[DsPhTamUng])

    const Footer = (
        <div style={{float: 'right'}}>
            <Button type="primary" ghost onClick={() => setOpen(false)}>
                {i18n.t(languageKeys.huy)} (ESC)
            </Button>
        </div>
    )

    return (
        <FormDrawer
            visible={open}
            width={700}
            onCancel={() => setOpen(false)}
            title={i18n.t(languageKeys.vien_phi_dich_vu_can_thanh_toan)}
            hiddenTopAction
            loading={loading}
            footer={Footer}
            maskClosable
        >
            <div style={{padding: 10, background: '#f5f7fb', height: '100%'}}>
                <Table
                    columns={columnsCTT}
                    dataSource={dsChTT.map((item, index) => ({ ...item, STT: index }))}
                    rowKey={'STT'}
                    scroll={{ x: "max-content" }}
                    onClickRow={(data) => formRef.current?.open(data, receiptInfo)}
                    loading={loading}
                />
            </div>
            <FormDvCanTT ref={formRef} />
        </FormDrawer>
    )
})

export default FormDsChuaTT

const columnsCTT = [
    {
        title: i18n.t(languageKeys.field_Ngay_kham),
        dataIndex: "NGAY",
        key: "NGAY",
        width: 140,
        render: (DATE) => moment(DATE, "YYYYMMDD").format("DD/MM/YYYY")
    },
    {
        title: i18n.t(languageKeys.ma_ca_kham),
        dataIndex: "MA_CUOC_KHAM",
        key: "MA_CUOC_KHAM",
        width: 140,
    },
    {
        title: i18n.t(languageKeys.field_tong_tien),
        dataIndex: "TONG_TIEN",
        key: "TONG_TIEN",
        width: 120,
        render: (MONEY) => formatCurrency(MONEY, "", true)
    },
    {
        title: i18n.t(languageKeys.field_doi_tuong_mien_giam),
        dataIndex: "MIEN_GIAM",
        key: "MIEN_GIAM",
        width: 120,
        render: (MONEY) => formatCurrency(MONEY, "", true)
    },
    {
        title: i18n.t(languageKeys.vien_phi_thanh_tien),
        dataIndex: "THANH_TIEN",
        key: "THANH_TIEN",
        width: 120,
        render: (MONEY) => formatCurrency(MONEY, "", true)
    },
]