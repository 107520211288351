import { Button, Col, Input, InputNumber, Layout, Row, Tooltip } from "antd";
import { Table } from "../../../../../components";
import { ArrowDownCollapse, ArrowRightCollapse, Check_box, Trash, Uncheckbox } from "assets/svg";
import classNames from "classnames";
import style from "../../style.module.less";
import i18n, { languageKeys } from "../../../../../i18n";
import { currencyParser, formatCurrency, formatNumberToPrice2 } from "../../../../../helpers";
import DiscountInput from "../../components/DiscountInput";
import { CheckRoleTooltip } from "components/CheckRoleTooltip/CheckRoleTooltip";

const TableChTT = ({
    loadingDsDv,
    hiddenHeader,
    styleMode,
    advanceMode,
    dataSource,
    selectedService,
    setSelectedService,
    scrollY,
    currentPatient,
    isDeleteAllowed,
    handleChangeDataSource,
    handleDelete,
    // configThanhToan,
    formatDataSource,
    expandedRow,
    setExpandedRow,
    footer
}) => {

    // const renderColumns = (config) => {
    //     let columns = [...columnsDichVu].filter(i => !i.remove);
    //     if (config !== "DICH_VU") {
    //         columns = columns.filter((col) => !(col.key === "MIEN_GIAM_DICH_VU"));
    //     }
    //     return [...columns, ...columnsCancel];
    // };

    // const columnsCancel = [];

    const shareOnCell = (record) => {
        if (record.key === "KHAM_DICH_VU" || record.key === 'KHAM_GOI_DICH_VU') {
            return { colSpan: 8 }
        } else if (record.BUNDLE_INFO) {
            return { colSpan: 2 }
        } else if (record.UNIQ_BUNDLE_ID || record.SUMMARY) {
            return { colSpan: 6 }
        } else {
            return { colSpan: 1 }
        }
    }

    const handleChangeValue = (data, value, key) => {
        let currentData = dataSource
        let index = currentData.findIndex((item) => item.ID === data.ID);
        currentData[index] = {
            ...data,
            [key]: value
        };
        handleChangeDataSource(currentData)
    }


    // Columns
    const columnsDichVu = [
        {
            title: i18n.t(languageKeys.field_Ten_dich_vu),
            key: "TEN_DICHVU",
            dataIndex: "TEN_DICHVU",
            width: 250,
            onCell: shareOnCell,
            render: (ten, record) => {
                if (record.SUMMARY) {
                    let length = selectedService?.length > 1
                    return (
                        <Row className={style.summary} >
                            <div className={style.wall} />
                            <Col span={length ? 5 : 8}>
                                {i18n.t(languageKeys.tong_tien)}:  {formatCurrency(record.TONG_TIEN, "đ", true)}
                            </Col>
                            <Col span={length ? 6 : 8}>
                                <span>{i18n.t(languageKeys.field_Mien_giam)}:  </span>
                                <DiscountInput
                                    init={record.TONG_TIEN_GIAM}
                                    data={record.data}
                                    onChangeDataSource={handleChangeDataSource}
                                    className={style.disInput}
                                />
                            </Col>
                            <Col span={length ? 5 : 8}>{i18n.t(languageKeys.vien_phi_thanh_tien)}:  {formatCurrency(record.THANH_TIEN, "đ", true)}</Col>
                            {length && (
                                <Col span={8}>
                                    <span>{i18n.t(languageKeys.field_Ly_do_mien_giam)}:  </span>
                                    <Input
                                        placeholder={i18n.t(languageKeys.common_Nhap)}
                                        onBlur={(e) => handleChangeValue(record.data, e.target.value, 'LY_DO_MIEN_GIAM')}
                                        style={{ width: "unset" }}
                                    />
                                </Col>
                            )}
                        </Row>
                    )
                }
                if (record.key === 'KHAM_DICH_VU' || record.key === 'KHAM_GOI_DICH_VU' || record.UNIQ_BUNDLE_ID) {
                    return record?.title?.toUpperCase()
                }
                if (record.BUNDLE_INFO) {
                    return <label style={{ fontSize: 14 }}>{i18n.t(languageKeys.goi_dich_vu)}: {record.GOI_DV}</label>
                }
                return (
                    <Tooltip title={ten}>
                        <div className={style["text-overflow"]}>{ten}</div>
                    </Tooltip>
                )
            },
        },
        {
            title: i18n.t(languageKeys.vien_phi_Don_gia),
            key: "DON_GIA",
            dataIndex: "DON_GIA",
            onCell: (record) => (
                {
                    colSpan: (record.key === "KHAM_DICH_VU" || record.key === 'KHAM_GOI_DICH_VU' || record.UNIQ_BUNDLE_ID)
                        ? 0
                        : record.BUNDLE_INFO ? 6 : 1
                }
            ),
            render: (DON_GIA, record) => record.BUNDLE_INFO
                ? <label style={{ fontSize: 14 }}>{i18n.t(languageKeys.loai_goi_dv)}: {record.LOAI_GOI_DV}</label>
                : (
                    record.IS_GOI_DV ||
                    currentPatient?.LOAI === "THANH_TOAN_MUA_GOI"
                )
                    ? formatCurrency(Number(DON_GIA), "đ", true)
                    : (
                        <InputNumber
                            onChange={(value) => handleChangeValue(record, value, 'DON_GIA')}
                            formatter={(value) => formatNumberToPrice2(value)}
                            parser={(value) => currencyParser(value)}
                            value={DON_GIA}
                            addonAfter={"đ"}
                            controls={false}
                            readOnly={!Boolean(record.CHO_PHEP_SUA_GIA_DICH_VU)}
                        />
                    ),
            width: 140,
        },
        {
            title: i18n.t(languageKeys.field_SL),
            key: "SO_LUONG",
            dataIndex: "SO_LUONG",
            onCell: (record) => (
                {
                    colSpan: (record.key === "KHAM_DICH_VU" || record.key === 'KHAM_GOI_DICH_VU' || record.BUNDLE_INFO || record.UNIQ_BUNDLE_ID) ? 0 : 1
                }
            ),
            width: 70,
            align: 'center',
        },
        {
            title: i18n.t(languageKeys.field_Mien_giam),
            key: "MIEN_GIAM_DICH_VU",
            dataIndex: "MIEN_GIAM_DICH_VU",
            onCell: (record) => (
                {
                    colSpan: (record.key === "KHAM_DICH_VU" || record.key === 'KHAM_GOI_DICH_VU' || record.BUNDLE_INFO || record.UNIQ_BUNDLE_ID) ? 0 : 1
                }
            ),
            render: (MGDV, record) => currentPatient?.LOAI !== "THANH_TOAN_MUA_GOI" ? (
                <DiscountInput
                    init={(currentPatient?.LOAI === "THANH_TOAN_MUA_GOI"
                        ? (record.MIEN_GIAM ? record.MIEN_GIAM : MGDV)
                        : (record.IS_GOI_DV ? 100 : (record.MIEN_GIAM_TIEP_DON ? record.MIEN_GIAM_TIEP_DON : MGDV) || 0))
                    }
                    data={record}
                    onChangeDataSource={handleChangeDataSource}
                />
            ) : formatCurrency(Number(MGDV), "đ", true),
            width: 140,
            // remove: currentPatient?.LOAI === "THANH_TOAN_MUA_GOI",
        },
        {
            title: i18n.t(languageKeys.vien_phi_thanh_tien),
            key: "",
            dataIndex: "",
            width: 120,
            onCell: (record) => (
                {
                    colSpan: (record.key === "KHAM_DICH_VU" || record.key === 'KHAM_GOI_DICH_VU' || record.BUNDLE_INFO || record.UNIQ_BUNDLE_ID) ? 0 : 1
                }
            ),
            render: (_, record) => formatCurrency(
                // Math.round(Number(record.SO_LUONG) * Number(record.DON_GIA) - Number(record["MIEN_GIAM_DICH_VU"])), "đ", true
                Math.round(Number(record?.SO_LUONG || 1) * Number((record.DON_GIA )) - Number(record["MIEN_GIAM_DICH_VU"] || 0)), "đ", true
            ),
        },
        {
            title: i18n.t(languageKeys.da_thuc_hien),
            key: "TRANG_THAI_KHAM",
            dataIndex: "TRANG_THAI",
            width: 85,
            align: 'center',
            onCell: (record) => (
                {
                    colSpan: (record.key === "KHAM_DICH_VU" || record.key === 'KHAM_GOI_DICH_VU' || record.BUNDLE_INFO || record.UNIQ_BUNDLE_ID) ? 0 : 1
                }
            ),
            render: (status, record) => {
                if (record.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN" || status === "KHAM_XONG" || status === "HOAN_THANH") {
                    return (
                        <Tooltip title={i18n.t(languageKeys.da_thuc_hien)}>
                            <Check_box />
                        </Tooltip>
                    );
                }
                if (status || record.TRANG_THAI_THUC_HIEN) {
                    if (currentPatient?.LOAI === "THANH_TOAN_MUA_GOI"
                        ? (status === null || status === "CHUA_KHAM")
                        : (record.TRANG_THAI_THUC_HIEN === "CHO_THUC_HIEN" || status === "CHO_XAC_NHAN" || status === "XAC_NHAN" || status === "CHO_KHAM" || status == null || record.TRANG_THAI_THUC_HIEN === null)
                    ) {
                        return (
                            <Tooltip title={i18n.t(languageKeys.chua_thuc_hien)}>
                                <Uncheckbox />
                            </Tooltip>
                        );
                    } else {
                        return (
                            <Tooltip title={i18n.t(languageKeys.da_thuc_hien)}>
                                <Check_box />
                            </Tooltip>
                        );
                    }
                } else {
                    return <></>;
                }
            },
        },
        {
            title: "",
            key: "action_btn",
            dataIndex: "THANH_TOAN",
            width: 60,
            fixed: 'right',
            onCell: (record) => (
                {
                    colSpan: (record.key === "KHAM_DICH_VU" || record.key === 'KHAM_GOI_DICH_VU' || record.BUNDLE_INFO || record.UNIQ_BUNDLE_ID) ? 0 : 1
                }
            ),
            render: (THANH_TOAN, record) =>
                record.TRANG_THAI === "CHO_KHAM" &&
                Number(THANH_TOAN) === 0 &&
                record.TRANG_THAI_THUC_HIEN !== "DA_THUC_HIEN" &&
                currentPatient?.LOAI === "THANH_TOAN_KHAM_BENH" && (
                    <div className={style["justify-end"]}>
                        <CheckRoleTooltip hasTooltip={true} isAllowed={isDeleteAllowed} oriTitle={i18n.t(languageKeys.vienphi_xoa_dich_vu)}>
                            <Button
                                type="text"
                                style={{ border: "none", pointerEvents: 'auto' }}
                                onClick={() => handleDelete(record)}
                                className={style["icon-delete"]}
                                disabled={!isDeleteAllowed}
                            >
                                <Trash />
                            </Button>
                        </CheckRoleTooltip>
                    </div>
                ),
            remove: currentPatient?.LOAI === "THANH_TOAN_MUA_GOI",
        },
    ];


    const handleSetSelectedService = (data) => {
        if (currentPatient?.LOAI === "THANH_TOAN_MUA_GOI") {
            const filtered = dataSource.filter(i => data.flatMap(i => i.key).includes(i.key))
            setSelectedService(filtered)
        } else {
            setSelectedService(data)
        }
    }


    return (
        <Layout.Content style={{ padding: "0 12px", height: styleMode === 'TIEP_DON' ? 'calc(100vh - 300px)' : '' }}>
            <Layout.Header style={{ backgroundColor: "transparent", display: (advanceMode || hiddenHeader) && "none" }}>
                <Row>
                    {!hiddenHeader ? (
                        <Col flex={"auto"}>
                            <div className={style["title"]} style={{ fontSize: 20, fontWeight: 600 }}>
                                {currentPatient?.LOAI === "THANH_TOAN_MUA_GOI"
                                    ? i18n.t(languageKeys.goi_dich_vu_lieu_trinh_can_thanh_toan)
                                    : i18n.t(languageKeys.vien_phi_dich_vu_can_thanh_toan)
                                }
                            </div>
                        </Col>
                    ) : (
                        <></>
                    )}
                </Row>
            </Layout.Header>
            <Table
                dataSource={dataSource?.length ? [...formatDataSource(dataSource, currentPatient?.LOAI === "THANH_TOAN_MUA_GOI")] : []}
                columns={[...columnsDichVu].filter(i => !i.remove)}
                selectedRows={selectedService}
                onSelectRows={handleSetSelectedService}
                scroll={!!dataSource?.length && { y: `calc(100vh - ${scrollY}px)`, x: "max-content" }}
                rowPropsConfig={(record) => {
                    if (record.TRANG_THAI === "HUY" || record.BUNDLE_INFO || record.SUMMARY) {
                        return {
                            disabled: true,
                        };
                    }
                }}
                loading={loadingDsDv}
                className={classNames(
                    style["table-custom"],
                    styleMode === 'TIEP_DON' && style['hidden-content'],
                    currentPatient?.LOAI === "THANH_TOAN_MUA_GOI" ? style.hideChild : style.hideFirst
                )}
                style={{ marginTop: hiddenHeader ? 10 : 0 }}
                expandable={{
                    expandRowByClick: true,
                    defaultExpandAllRows: true,
                    expandIcon: ({ expanded, onExpand, record }) =>
                        record.key === "KHAM_DICH_VU" || record.key === "KHAM_GOI_DICH_VU" || (record.UNIQ_BUNDLE_ID && !record.SUMMARY) ? (
                            expanded ? (
                                <ArrowDownCollapse style={{ marginLeft: 6, marginRight: 12 }} />
                            ) : (
                                <ArrowRightCollapse style={{ marginLeft: 6, marginRight: 12 }} />
                            )
                        ) : (
                            <></>
                        ),
                    expandedRowKeys: expandedRow,
                    onExpand: (expanded, record) => setExpandedRow(!expanded ? expandedRow.filter(i => i !== record.key) : [...expandedRow, record.key])
                }}
                footer={footer ? footer : undefined}
            />
        </Layout.Content>
    )
}

export default TableChTT