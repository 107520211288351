import { Button, notification, Row, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState, useImperativeHandle, memo, useRef } from "react";
import { useSelector } from "react-redux";
import { ActionButton, ConfirmModal, Table, WarningModal } from "../../../../../components";
import { apis, keys } from "../../../../../constants";
import { common_post, formatCurrency, handleErrorHuyPhieu, rid } from "../../../../../helpers";
import { StarCheck } from "../../../KhoThuoc/components";
import { initDataFilter } from "../../NhapXuat";
import style from "./style.module.less";
import cn from "classnames";
import i18n, { languageKeys } from "../../../../../i18n";
import { keyLoaiNhanVien } from "../../../../../constants/keys";
import { CheckRoleTooltip } from "components/CheckRoleTooltip/CheckRoleTooltip";

function DanhSachPhieuNhap(
  {
    onShowDetail = () => {},
    dataFilter = initDataFilter,
    onPrint = () => {},
    disableDelete = false,
    disablePrint = false,
    tooltipInTheoQuyen = false,
    tooltipXoaTheoQuyen = false,
    tooltipDuyetTheoQuyen = false,
  },
  ref
) {
  const userProfile = useSelector((state) => state.auth.user);
  const confirmRef = useRef();
  const warningRef = useRef();
  const [dsPhieu, setDsPhieu] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrrentPage] = useState(1);
  const [isFilterNoiBat, setIsFilterNoiBat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState();
  const [warningContent, setWarningContent] = useState("");
  const isAdmin = userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.quan_tri;
  useImperativeHandle(
    ref,
    () => ({
      reload() {
        getDanhSachPhieu({});
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    getDanhSachPhieu({ filter: dataFilter });
  }, [dataFilter]);

  async function getDanhSachPhieu({ page = currentPage, filter = dataFilter, SAP_XEP = { COT: "", KIEU: "ASC" } }) {
    setLoading(true);
    try {
      let dataRequest = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        LOAI_PHIEU: "PHIEU_NHAP",
        ...filter,
        limit: keys.limit,
        page,
        SAP_XEP,
      };
      let response = await common_post(apis.lay_ds_phieu_nhap_xuat, dataRequest);
      if (response && response.status === "OK") {
        setDsPhieu(response.result.map((item) => ({ ...item, key: rid() })));
        setTotal(response.total);
        setCurrrentPage(response.current_page);
        setLoading(false);
      }
    } catch (error) {}
  }

  async function updateStatus(dataRequest) {
    try {
      const response = await common_post(apis.cai_dat_noi_bat_phieu, dataRequest);
      if (response && response.status === "OK") {
        getDanhSachPhieu({});
      }
    } catch (error) {}
  }

  const onClickStar = async (record) => {
    let dataRequest = {
      arr_ID: [record.ID],
      NOI_BAT: !record.NOI_BAT,
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    };
    updateStatus(dataRequest);
  };

  const handleUpdateStatusPhieu = async (item) => {
    let req = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_THUOC_ID: item.ID,
      NGUOI_HUY_ID: userProfile.NHANSU_ID,
      PHAN_LOAI: item.PHAN_LOAI,
    };
    try {
      const res = await common_post(apis.huy_phieu_nhap_xuat, req);
      if (res && res.status === "OK") {
        notification.success({ message: i18n.t(languageKeys.cap_nhat_thanh_cong) });
        getDanhSachPhieu({});
        deleteItem && setDeleteItem();
        confirmRef.current.close();
      } else if (res && res.status === "KO") {
        setWarningContent(handleErrorHuyPhieu(res.error_code || null));
        deleteItem && setDeleteItem();
        confirmRef.current.close();
        warningRef.current.open();
      }
    } catch (error) {}
  };

  const columnsNhap = [
    {
      title: i18n.t(languageKeys.field_Ngay_tao),
      dataIndex: "",
      key: "NGAY_GIO",
      width: 150,
      render: (_, record) => {
        return (
          <>
            {record.GIO} - {moment(record.NGAY).format("DD/MM/YYYY")}
          </>
        );
      },
    },
    {
      title: i18n.t(languageKeys.ma_phieu),
      dataIndex: "MA_PHIEU",
      key: "MA_PHIEU",
      width: 100,
      render: (ma) => <div style={{ color: "#6576FF" }}>{ma}</div>,
    },
    {
      title: i18n.t(languageKeys.loai_phieu),
      dataIndex: "PHAN_LOAI",
      key: "PHAN_LOAI",
      width: 130,
      render: (record) => {
        return (
          <Tooltip placement="bottom" title={record === "PHIEU_NHAP" ? i18n.t(languageKeys.phieu_nhap) : i18n.t(languageKeys.phieu_chuyen_kho)}>
            <span className={style["text-ellipsis"]}>
              {record === "PHIEU_NHAP" ? i18n.t(languageKeys.phieu_nhap) : i18n.t(languageKeys.phieu_chuyen_kho)}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: i18n.t(languageKeys.field_Ten_kho),
      dataIndex: "TEN_KHO",
      key: "TEN_KHO",
      width: 120,
      render: (record) => {
        return (
          <Tooltip placement="bottom" title={record}>
            <span className={style["text-ellipsis"]}>{record}</span>
          </Tooltip>
        );
      },
    },
    {
      title: i18n.t(languageKeys.field_Nguoi_tao),
      dataIndex: "NGUOI_TAO",
      key: "NGUOI_TAO",
      width: 150,
      render: (record) => {
        return (
          <Tooltip placement="bottom" title={record}>
            <span className={style["text-ellipsis"]}>{record}</span>
          </Tooltip>
        );
      },
    },
    {
      title: i18n.t(languageKeys.nha_cung_cap),
      dataIndex: "TEN_NCC",
      key: "TEN_NCC",
      width: 130,
      render: (record) => {
        return (
          <Tooltip placement="bottom" title={record}>
            {record ? <span className={style["text-ellipsis"]}>{record}</span> : "-"}
          </Tooltip>
        );
      },
    },
    {
      title: i18n.t(languageKeys.tong_tien),
      dataIndex: "TONG_CHI_PHI",
      key: "TONG_CHI_PHI",
      width: 100,
      render: (val) => <div>{formatCurrency(val, "đ", true)}</div>,
    },
    {
      title: i18n.t(languageKeys.field_Mien_giam),
      dataIndex: "TONG_MIEN_GIAM",
      key: "TONG_MIEN_GIAM",
      width: 100,
      render: (val) => <div>{formatCurrency(val, "đ", true)}</div>,
    },
    {
      title: i18n.t(languageKeys.tong_tt),
      dataIndex: "TONG_THANH_TOAN",
      key: "TONG_THANH_TOAN",
      width: 100,
      render: (val) => <div style={{ color: "#2CB2A5" }}>{formatCurrency(val, "đ", true)}</div>,
    },
    {
      title: i18n.t(languageKeys.trang_thai),
      dataIndex: "TRANG_THAI_PHIEU",
      key: "TRANG_THAI_PHIEU",
      width: 80,
      render: (val, record) => (
        <CheckRoleTooltip hasTooltip={false} isAllowed={val !== "CHO_DUYET" || (tooltipDuyetTheoQuyen && val === "CHO_DUYET")}>
          <div
            className={cn(
              // !isAdmin && !isDuyetPhieu && val !== "HOAN_THANH" ? style["tag-disable"] :
              val === "CHO_DUYET"
                ? tooltipDuyetTheoQuyen
                  ? style["tag-yellow"]
                  : `${style["tag-yellow"]} ${style["disabled"]}`
                : val === "HUY"
                ? style["tag-red"]
                : style["tag-green"]
            )}
            onClick={(e) => {
              e.stopPropagation();
              if (!isAdmin && !tooltipDuyetTheoQuyen) {
                // nếu k có quyền duyệt thì k có action
                return;
              }
              //handleUpdateStatusPhieu(record, "HOAN_THANH")
              onShowDetail(record, true);
            }}
          >
            {val === "CHO_DUYET" ? i18n.t(languageKeys.duyet) : "val" === "HUY" ? i18n.t(languageKeys.status_Da_huy) : i18n.t(languageKeys.da_HT)}
          </div>
        </CheckRoleTooltip>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }} className={style.container}>
      <Table
        dataSource={dsPhieu}
        limit={keys.limit}
        totalResult={total}
        showPagination
        columns={[
          // {
          //     title: () => {
          //       return <StarCheck onClickStar = {filterNoiBat} isChecked = {isFilterNoiBat}/>;
          //     },
          //     key: "action_btn",
          //     dataIndex: "action_btn",
          //     width: "30px",
          //     render: (_, record) => <StarCheck onClickStar = {onClickStar} dataItem = {record} isChecked = {record.NOI_BAT}/>,
          //   },
          ...columnsNhap,
          {
            key: "action",
            dataIndex: "",
            fixed: "right",
            width: 80,
            render: (_, record) => (
              <ActionButton
                tooltipXoaTheoQuyen={tooltipXoaTheoQuyen}
                tooltipInTheoQuyen={tooltipInTheoQuyen}
                showDelete={true}
                showEdit={false}
                showPrint={true}
                deleteDisabled={disableDelete || (record.TRANG_THAI_PHIEU === "HUY")}
                disabledPrint={disablePrint || record.TRANG_THAI_PHIEU === "CHO_DUYET" || (record.TRANG_THAI_PHIEU === "HUY" ? true : false)}
                onPrint={() => onPrint(apis.phieu_nhap_pdf, record.ID)}
                onDelete={() => {
                  if (record.PHAN_LOAI === "CHUYEN_KHO" && record.TRANG_THAI_PHIEU === "HOAN_THANH") {
                    onShowDetail(record);
                  } else {
                    setDeleteItem(record);
                    confirmRef.current.open();
                  }
                }}
              />
            ),
          },
        ]}
        currentPage={currentPage}
        onClickRow={onShowDetail}
        onNext={() => getDanhSachPhieu({ page: currentPage + 1 })}
        onPrev={() => getDanhSachPhieu({ page: currentPage - 1 })}
        loading={loading}
        scroll={{
          x: columnsNhap.reduce((sum, item) => sum + (item.width || 100), 0) + 120,
          y: "calc(100vh - 250px)",
        }}
      />
      <ConfirmModal
        danger={true}
        ref={confirmRef}
        content={i18n.t(languageKeys.confirm_cancel_ticket)}
        onOk={() => handleUpdateStatusPhieu(deleteItem)}
        onCancel={() => {
          confirmRef.current.close();
          setDeleteItem();
        }}
        okText={i18n.t(languageKeys.title_Xac_nhan)}
      />
      <WarningModal
        ref={warningRef}
        title={i18n.t(languageKeys.common_Canh_bao)}
        content={warningContent}
        children={
          <Row justify="center" style={{ marginTop: "10px" }}>
            <Button type="primary" ghost onClick={() => warningRef.current.close()} style={{ width: "150px" }}>
              {i18n.t(languageKeys.common_Thoat)}
            </Button>
          </Row>
        }
      />
    </div>
  );
}

export default memo(React.forwardRef(DanhSachPhieuNhap));
