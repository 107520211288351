import { memo } from "react";
import ImagePreview from "./ImagePreview";
import Sider from "./Sider";
import style from "../formStyle.module.less";
import { Button, Layout, Row, Col, Input } from "antd";
import { trang_thai_tra } from "../TraKetQuaCdha";
import i18n, {languageKeys} from "../../../../i18n";
import { CopyIcon } from "assets/svg";
import ModalImageList from "./ModalImageList";
import { useRef } from "react";
// import { HLog } from "../../../../helpers";
// const init_arr = [null, null, null, null];

const SiderAnh = ({
    arrImgPrev=[],
    seletedImage=[],
    setSeletedImage= () => {},
    onRemoveFile = () => {},
    currentService,
    width=225,
    removeAll = () => {},
    onClickImg = () => {},
    onClickEditImage = () => {},
    setArrImgPrev = () => {},
    listImageUrl='',
    currServiceState,
    removeMultipleImageInDescription= () => {},
    updateEditedImagesInDescription=() => {},
    resultUrl=''
}) => {

  const imageListRef = useRef();
  const handleClick = (event, image) => {
    event.preventDefault(); // This prevents the default context menu from appearing
    onClickEditImage(image);
  };

  const handleCopyToClipboard = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(listImageUrl);
  }

  const viewImageList = () => {
    imageListRef?.current?.handleOpen(seletedImage.filter(item => !!item?.url));
  }

  const hanleOpenViewImageLink = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    if(!url) return;
    window.open(url.toString());
  }

  return(
       <>
         <Layout.Sider width={width} theme="light">
          <div className={style["form__sider--right"]}>
            
             <Row gutter={[10,10]}>
              
             {seletedImage?.map((img, index) => {
              return (
               <Col key={img.key} span={12} onContextMenu= {(e) => handleClick(e, img)}>
                 
                  <ImagePreview
                    isAdded = {img?.isAdded}
                    key={img.key}
                    source={img?.thumbnail}
                    uid={img.key}
                    onRemove={onRemoveFile}
                    onClickImg={onClickImg}
                    removeIcon={currentService ? currentService.TRANG_THAI_KQ !== trang_thai_tra.CHO_KET_QUA : true}
                  />
                 
                </Col>
              );
            })}
            { seletedImage.length < 8 && Array(8 - seletedImage.length).fill(0).map((img, index) => {
              return <Col span={12}>
               <ImagePreview key={index} source={img}  />
               </Col>
            })}
            </Row>
            {
              currServiceState === trang_thai_tra.CHO_KET_QUA ? (
                <Button 
                  type="primary" 
                  style={{ width : "100%", marginTop: 10 }} 
                  onClick={viewImageList} 
                >
                  {i18n.t(languageKeys.danh_sach_hinh_anh)}
                </Button>
              ) : <></>
            }
            <Button 
              ghost 
              type="primary" 
              style={{ width : "100%", marginTop: 10 }} 
              onClick={() => removeAll()} 
              disabled={!seletedImage || seletedImage.length == 0}>
              {i18n.t(languageKeys.xoa_tat_ca)}
            </Button>

            <div style={{marginTop: '20px'}}>
              <span style={{display: 'block', marginBottom: '10px'}}>{i18n.t(languageKeys.link_hinh_anh)}</span>
              <Input onClick={(e) => hanleOpenViewImageLink(e, listImageUrl)} readOnly value={listImageUrl} suffix={<CopyIcon onClick={handleCopyToClipboard}/>}/>
            </div>
            <div style={{marginTop: '20px'}}>
              <span style={{display: 'block', marginBottom: '10px'}}>{i18n.t(languageKeys.link_ket_qua)}</span>
              <Input onClick={(e) => hanleOpenViewImageLink(e, resultUrl)} readOnly value={resultUrl} suffix={<CopyIcon onClick={handleCopyToClipboard}/>}/>
            </div>
            <ModalImageList arrImgPrev={arrImgPrev} seletedImage={seletedImage} setSeletedImage={setSeletedImage} ref={imageListRef} setArrImgPrev={setArrImgPrev} removeMultipleImageInDescription={removeMultipleImageInDescription} updateEditedImagesInDescription={updateEditedImagesInDescription}/>
          </div>
          {/* <div className={style["sider__right--background"]}>
           
          </div>
          */}
      </Layout.Sider>
      
       </>
    )
}

export default memo(SiderAnh)