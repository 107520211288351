import { isNumber } from "lodash";
import moment from "moment/moment";
import { apis, keys } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import { fieldPhong } from "../fieldsDanhMuc";

/**
 * API lấy danh sách phòng
 */
export const apiLayDsPhong = async ({
  BENH_VIEN_ID = "",
  search_string = "",
  page = 1,
  limit = keys.limit,
  CHI_DINH = [],
  LOAI_PHONG = "",
  partner_code = "",
}) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      page,
      limit,
      CHI_DINH,
      LOAI_PHONG,
      partner_code,
    };

    const response = await common_post(apis.dm_phong_lay_ds, requestData);

    if (response.status === "OK") return response;
  } catch (error) {
    HLog("Api lay danh sach phong error", error);
  }
};

/**
 * API import excel danh sách phòng
 */
export const apiNhapDsPhong = async ({ BENH_VIEN_ID = "", data = [], partner_code = "" }) => {
  try {
    data = data.map((item) => {
      Object.keys(item).forEach((key) => {
        if (key === "GIO_BAT_DAU" || key === "GIO_KET_THUC") {
          if (!item[key]) item[key] = null;
          else if (isNumber(item[key])) {
            let value = Number(item[key]);
            value = value * 86400;

            item[key] = moment.utc(value * 1000).format("HH:mm");
          }
        } else item[key] = item[key].toString();
      });

      return item;
    });

    let requestData = {
      BENH_VIEN_ID,
      data,
      partner_code,
    };

    const response = await common_post(apis.dm_phong_nhap_ds, requestData);

    return response;
  } catch (error) {
    HLog("Api import danh sach phong error", error);
  }
};

/**
 * API thêm mới phòng
 */
export const apiThemPhong = async ({ BENH_VIEN_ID = "", data = {}, partner_code = "" }) => {
  try {
    const key_phong_chi_dinh = fieldPhong.phong_chi_dinh;
    const key_ma_khoa = fieldPhong.khoa;

    if (!!data[key_ma_khoa]) {
      data[key_ma_khoa] = JSON.parse(data[key_ma_khoa])[fieldPhong.id];
    } else {
      data[key_ma_khoa] = null;
    }

    if (!!data[key_phong_chi_dinh] && data[key_phong_chi_dinh].length > 0) {
      data[key_phong_chi_dinh] = data[key_phong_chi_dinh].map((item) => JSON.parse(item));
    }

    let requestData = {
      ...data,
      [`${fieldPhong.gio_bat_dau}`]: !!data[fieldPhong.gio_bat_dau] ? moment(data[fieldPhong.gio_bat_dau]).format("HH:mm") : null,
      [`${fieldPhong.gio_ket_thuc}`]: !!data[fieldPhong.gio_ket_thuc] ? moment(data[fieldPhong.gio_ket_thuc]).format("HH:mm") : null,
      BENH_VIEN_ID,
      partner_code,
    };

    return await common_post(apis.dm_phong_them_moi, requestData);
  } catch (error) {
    HLog("Api them moi phong error", error);
  }
};

/**
 * API sửa phòng
 */
export const apiSuaPhong = async ({ data = {}, currentData = {} }) => {
  try {
    const key_phong_chi_dinh = fieldPhong.phong_chi_dinh;
    const key_ma_khoa = fieldPhong.khoa;

    console.log(data, currentData);

    if (!!data[key_ma_khoa]) {
      // nếu là json string (chọn mới khoa)
      // eslint-disable-next-line no-useless-escape
      if (typeof data[key_ma_khoa] === "string" && data[key_ma_khoa].includes(`{\"`)) {
        data[key_ma_khoa] = JSON.parse(data[key_ma_khoa])[fieldPhong.id];
      }

      // nếu là string tên khoa phòng (không chọn mới khoa)
      else {
        data[key_ma_khoa] = currentData[key_ma_khoa];
      }
    } else {
      data[key_ma_khoa] = null;
    }

    if (!!data[key_phong_chi_dinh] && data[key_phong_chi_dinh].length > 0) {
      data[key_phong_chi_dinh] = data[key_phong_chi_dinh].map((item) => JSON.parse(item));
    }

    let requestData = { ...data };

    requestData[`${fieldPhong.gio_bat_dau}`] = formatGioCaiDat(data[fieldPhong.gio_bat_dau]);
    requestData[`${fieldPhong.gio_ket_thuc}`] = formatGioCaiDat(data[fieldPhong.gio_ket_thuc]);

    return await common_post(apis.dm_phong_cap_nhat, requestData);
  } catch (error) {
    HLog("Api cap nhat phong error", error);
  }
};

/**
 * API xóa phòng
 */
export const apiXoaPhong = async ({ partner_code = "", data = {} }) => {
  try {
    let requestData = { data: [data], partner_code };

    const response = await common_post(apis.dm_phong_xoa, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa phong error", error);
  }
};

/**
 * API lấy thông tin chi tiết phòng
 */
export const apiLayChiTietPhong = async ({ partner_code = "", ID = "" }) => {
  try {
    let requestData = { partner_code, ID };

    const response = await common_post(apis.dm_phong_chi_tiet, requestData);

    return response;
  } catch (error) {
    HLog("Api lay chi tiet phong error", error);
  }
};

/**
 * API xóa danh sách phòng
 */
export const apiXoaDsPhong = async ({ partner_code = "", data = [] }) => {
  try {
    let requestData = { partner_code, data };

    const response = await common_post(apis.dm_phong_xoa, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa danh sach phong error", error);
  }
};

/**
 * API khóa danh sách phòng
 */
export const apiKhoaDsPhong = async ({ partner_code = "", data = [], lock = false }) => {
  try {
    const ID = data.map((item) => item[fieldPhong.id]);
    const KHOA = !!lock ? 1 : 0;

    let requestData = { partner_code, ID, KHOA };

    const response = await common_post(apis.dm_phong_sua_trang_thai, requestData);

    return response;
  } catch (error) {
    HLog("Api khoa danh sach phong error", error);
  }
};

export const apiExportPhong = async ({ BENH_VIEN_ID = "", partner_code = "", template = 0 }) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_phong_export, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(!!template ? "Tai template phong error" : "Export ds phong error");
  }
};

const formatGioCaiDat = (data) => {
  if (!data) return null;

  if (typeof data === "string") return data;

  return moment(data).format("HH:mm");
};
