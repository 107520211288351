import { apis, keys } from "../../../../constants";
import {
  common_post,
  HLog,
  //isJsonString,
} from "../../../../helpers";


export async function layDsGiuong ({
  BENH_VIEN_ID = "",
  search_string = "",
  partner_code = "",
  page = 1,
  limit = keys.limit,
})  {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      partner_code,
      page,
      limit,
    };
    const response = await common_post(apis.dm_lay_ds_giuong_benh, requestData);
    return response;
  } catch (error) {
    HLog("Api lay ds loai dvkt error", error);
  }
};

export async function themGiuong(data) {
  let dataSumit = data.data;
  HLog("themGiuong dataSumit: ", dataSumit)
  let dv_giuong = dataSumit.DV_GIUONG.map(item => {
    return JSON.parse(item).ID
  });
  let dataGiuong = JSON.parse(dataSumit.MA_LOAI_GIUONG);

  let dataRequest = {
    ...data,
    ...dataSumit,
    DV_GIUONG : dv_giuong,
    MA_LOAI_GIUONG: dataGiuong.key,
    TEN_LOAI_GIUONG: dataGiuong.name,
  };
 
  try {
    return await common_post(apis.dm_them_giuong_benh, dataRequest);
  } catch (error) {
    return null;
  }
}

export async function suaGiuong({data, currentData , from = ""}) {

  try {
    if (from === "TABLE") {
      let dataRequest = {
        ID : [data.ID],
        KHOA : data.KHOA,
        partner_code : data.partner_code
      }
      return await common_post(apis.dm_update_status_giuong_benh, dataRequest)
    
    }else{
      let dataRequest = {
        ...data
      }
      if (dataRequest.PHONG_ID === currentData.TEN_PHONG) { // nếu k sửa phogf thì datasubmit = tên phong
          dataRequest.PHONG_ID = currentData.PHONG_ID
      }
      let list_id = dataRequest.DV_GIUONG.map(item => JSON.parse(item).ID)
      dataRequest.DV_GIUONG = list_id
      return await common_post(apis.dm_sua_giuong_benh, dataRequest)
    }
  } catch (error) {
    return null
  }
}


export async function xoaGiuong(data) {
  let dataRequest = {
    ID : [data.data.ID],
    TRANG_THAI : 0,
    partner_code : data.partner_code
  }
  try {
    return await common_post(apis.dm_update_status_giuong_benh, dataRequest)
  } catch (error) {
    return null
  }
}



export async function khoaNhieuGiuong(data) {

  let dataRequest = {
    partner_code : data.partner_code,
    ID : data.data.map(item => item.ID),
    KHOA : data.lock === false ? 0 : 1,
  }
  try {
    return await common_post(apis.dm_update_status_giuong_benh, dataRequest)
  } catch (error) {
    return null
  }

}

export async function xoaNhieuGiuong(data) {
  let dataRequest = {
    partner_code : data.partner_code,
    ID : data.data.map(item => item.ID),
    TRANG_THAI : 0,
  }
  try {
    return await common_post(apis.dm_update_status_giuong_benh, dataRequest)
  } catch (error) {
    return null
  }

}

export async function exportListGiuong ({
      BENH_VIEN_ID = "",
      partner_code ="",
      template = 0
}) {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_export_giuong_benh, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(
      !!template
        ? "Tai template don vi tinh error"
        : "Export ds don vi tinh error"
    );
  }
}

export const importGiuong = async ({
  BENH_VIEN_ID = "",
  data = [],
  partner_code = "",
}) => {
  try {
    const req = { data, partner_code, BENH_VIEN_ID };
    let response = await common_post(apis.dm_import_giuong_benh, req);
    return response;
  } catch (error) {
    HLog("Api nhap danh sach kho tu truc error", error);
  }
};
