import { Button, InputNumber, Space } from "antd";
import { ConfirmModal } from "components";
import { calcTotal } from "helpers";
import { formatRateNumber } from "pages/QuanLyGoiDichVu/DanhSachGoiDichVu/helpers";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import i18n, { languageKeys } from "../../../../i18n";
import styles from "../vattu.module.less";

const InputAutoFillPrice = ({ readOnly, dataSource, setDataSource }) => {

  const modalRef = useRef()

  const dispatch = useDispatch()

  const [tempPrice, setTempPrice] = useState()
  const [isChange, setIsChange] = useState(false)


  useEffect(() => {
    if (!dataSource[0] && tempPrice) {
      setTempPrice()
      setIsChange(false)
    }
  }, [dataSource])


  //
  const autoFillPrice = (value) => {
    //
    if (!dataSource[0]) return;
    //
    const price = Math.round(value / dataSource.length)
    //
    let newData = dataSource.map(item => (
      {
        ...item,
        // DON_GIA_PHAN_BO: Math.round(price / item.SO_LUONG),
        THANH_TIEN_PHAN_BO: price,
        MIEN_GIAM: 0,
        MIEN_GIAM_PHAN_TRAM: null,
        TONG_TIEN: price,
        TY_LE: formatRateNumber(100 / dataSource.length),
        DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
          ...dvkt,
          DON_GIA_PHAN_BO: price / item.DV_KEM_THEO.length / item.SO_LUONG,
          THANH_TIEN_PHAN_BO: price / item.DV_KEM_THEO.length,
          TY_LE: formatRateNumber(100 / dataSource.length / item.DV_KEM_THEO.length),
        }))
      }
    ))
    // Lẻ tổng
    const currTotal = calcTotal(newData, "THANH_TIEN_PHAN_BO")
    if (value !== currTotal) {
      newData[0] = {
        ...newData[0],
        THANH_TIEN_PHAN_BO: newData[0].THANH_TIEN_PHAN_BO + (value - currTotal),
        TONG_TIEN: newData[0].THANH_TIEN_PHAN_BO + (value - currTotal),
      }
    }
    // Lẻ tỷ lệ
    const currRate = calcTotal(newData, "TY_LE")
    if (currRate !== 100) {
      newData[0] = {
        ...newData[0],
        TY_LE: (Number(newData[0].TY_LE) + 100 - currRate).toFixed(2),
      }
    }
    // Set state
    newData = newData.map(item => ({
      ...item,
      DON_GIA_PHAN_BO: item.THANH_TIEN_PHAN_BO / item.SO_LUONG,
    }))
    dispatch(setDataSource(newData))
    modalRef.current.close()
  }


  const handleChange = (val) => {
    setTempPrice(val)
    setIsChange(true)
  }


  return (
    <Space>

      <InputNumber
        value={isChange ? tempPrice : calcTotal(dataSource, "TONG_TIEN")}
        onChange={handleChange}
        controls={false}
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        readOnly={readOnly}
        onBlur={() => !tempPrice && setIsChange(false)}
      />

      <Button
        type="primary"
        disabled={!dataSource[0] || readOnly}
        onClick={() => modalRef.current.open(tempPrice)}
      >
        {i18n.t(languageKeys.common_Ap_dung)}
      </Button>

      <ConfirmModal
        ref={modalRef}
        title={""}
        content={""}
        okText={i18n.t(languageKeys.answer_co)}
        cancelText={i18n.t(languageKeys.answer_khong)}
        onOk={(value) => autoFillPrice(value)}
        onCancel={() => setIsChange(false)}
        onPressCancelSuccess={() => setIsChange(false)}
      >
        <div className={styles.modal}>
          <p>{i18n.t(languageKeys.noti_sua_gia_lieu_trinh)}</p>
        </div>
      </ConfirmModal>

    </Space>

  )
}

export default InputAutoFillPrice;