import { Col, Form, Input, InputNumber, Row, Switch } from "antd";
import { Select } from "../../../../components";
import { loai_doi_tuong } from "../../../../constants/keys";
import i18n, { languageKeys } from "../../../../i18n";
import { fieldDanhMucDoiTuong } from "../fieldsDanhMuc";
import formDanhMuc from "./formDanhMuc.module.less";

const ds_loai_dt = Object.keys(loai_doi_tuong).map((item) => {
  return {
    key: item,
    name: loai_doi_tuong[item],
  };
});
export const FormDoiTuong = (props) => {
  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.thong_tin_doi_tuong)}</h4>
        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                  {
                    max: 30,
                    message: i18n.t(languageKeys.toi_da_ky_tu),
                  },
                ]}
                name={fieldDanhMucDoiTuong.ma_doi_tuong}
                label={i18n.t(languageKeys.field_ma_doi_tuong)}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[{ required: true, message: i18n.t(languageKeys.please_chon) }]}
                name={fieldDanhMucDoiTuong.loai_doi_tuong}
                label={i18n.t(languageKeys.field_loai_doi_tuong)}
              >
                <Select dataSource={ds_loai_dt} valueKey="key" titleKey={"name"} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={20}>
              <Form.Item
                rules={[{ required: true, whitespace: true, message: i18n.t(languageKeys.vui_long_nhap) }]}
                name={fieldDanhMucDoiTuong.ten_doi_tuong}
                label={i18n.t(languageKeys.ten_doi_tuong)}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={fieldDanhMucDoiTuong.thu_tu} label={i18n.t(languageKeys.thu_tu)}>
                <InputNumber style={{ width: "100%" }} placeholder={i18n.t(languageKeys.common_Nhap)} min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item name={fieldDanhMucDoiTuong.ghi_chu} label={i18n.t(languageKeys.field_Ghi_chu)}>
                <Input.TextArea placeholder={i18n.t(languageKeys.common_Nhap)} rows={5} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col>
              <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>
            </Col>
            <Col>
              <Form.Item name={fieldDanhMucDoiTuong.hien_thi} valuePropName="checked" initialValue={false}>
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
