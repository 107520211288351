import { Button, Form, Input, Layout, notification, Space, Tooltip } from "antd";
import Content from "./Content";
import Sider from "./Sider";
import i18n, { languageKeys } from "../../../../../i18n";
import style from "../../qlcn.module.less";
import { useDispatch, useSelector } from "react-redux";
import { debtManagementState, getUnpaidDebtSuccess, payDebt, payDebtAndPrint, updatePatientInfo } from "ducks/slices/QlyVienPhi/QlyCongNo.slice";
import { useRef, useState } from "react";
import { ConfirmModal, ModalPDF } from "components";
import { apis } from "../../../../../constants";
import { useEventListener } from "hooks";
import { handleCheckPermissions } from "helpers";
import { featureKeys } from "constants/keys";
import { pdfRefDaTTControl } from "../CongNoDaTT";
import { apiHuyCongNoDichVu, apiHuyCongNoGoiDV } from "ducks/sagas/QlyVienPhi/QlyCongNo/apiQlyCongNo";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";

const CongNoChuaTT = ({ setRender, setSelectedTab, onAfterSubmit, isUseKeyboard = true, siderMainRef }) => {

    const [form] = useForm();

    const siderRef = useRef()
    const pdfRef = useRef()
    const refWarning = useRef();

    const [isAdvance, setIsAdvance] = useState(false)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const { unpaidDebtList, patientInfo, advanceMoney } = useSelector(debtManagementState)

    const userProfile = useSelector((state) => state.auth.user);
    const permission = handleCheckPermissions(userProfile, featureKeys.quan_ly_cong_no)

    const valueBenhNhanDua = Form.useWatch('list_dv_thanh_toan', siderRef.current?.form)
        ?.reduce((acc, obj) => acc + (obj?.BENH_NHAN_DUA ? Number(obj.BENH_NHAN_DUA) : 0), 0)
        + (isAdvance ? advanceMoney?.TOTAL_TAM_UNG : 0)

    const huyCongNo = () => {
        form?.resetFields()
        refWarning.current.open();
    }

    const handleHuyCongNo = async () => {
        try {
            let { GHI_CHU } = await form.validateFields()

            const { LICH_KHAM_ID, GOI_DV_ID } = unpaidDebtList
            setLoading(true)

            if (LICH_KHAM_ID) {
                try {
                    const res = await apiHuyCongNoDichVu({ LICH_KHAM_ID, GHI_CHU })
                    if (res && res.status == "OK") {
                        notification.success({
                            message: i18n.t(languageKeys.thao_tac_thanh_cong),
                            placement: 'bottomLeft'
                        })
                        siderMainRef.current?.reload()
                        dispatch(updatePatientInfo({}))
                        dispatch(getUnpaidDebtSuccess({}))
                    } else {
                        notification.error({
                            message: i18n.t(languageKeys.thao_tac_that_bai),
                            placement: "bottomLeft",
                        })
                    }

                } catch (error) {

                    notification.error({
                        message: i18n.t(languageKeys.thao_tac_that_bai),
                        placement: "bottomLeft",
                    })

                }
            } else {
                try {
                    const res = await apiHuyCongNoGoiDV({ GOI_DV_ID, GHI_CHU })
                    if (res && res.status == "OK") {
                        notification.success({
                            message: i18n.t(languageKeys.thao_tac_thanh_cong),
                            placement: 'bottomLeft'
                        })

                        siderMainRef.current?.reload()
                        dispatch(updatePatientInfo({}))
                        dispatch(getUnpaidDebtSuccess({}))
                    } else {
                        notification.error({
                            message: i18n.t(languageKeys.thao_tac_that_bai),
                            placement: "bottomLeft",
                        })
                    }
                } catch (error) {
                    notification.error({
                        message: i18n.t(languageKeys.thao_tac_that_bai),
                        placement: "bottomLeft",
                    })
                }

            }
            setLoading(false)
            refWarning?.current?.close()
            return
        } catch (error) {
        }

    }

    const handlePay = async (isPrint = false) => {
        await siderRef.current?.form.validateFields()
        //
        const { DS_DV, BENH_NHAN_ID, LICH_KHAM_ID, MIEN_GIAM, MIEN_GIAM_PHAN_TRAM, NHANSU_ID, TONG_CHI_PHI, TONG_THANH_TOAN, HOA_DON_CHA_ID, PHAN_LOAI, GOI_DV_ID, TONG_DA_THANH_TOAN, HOA_DON_ID } = unpaidDebtList
        const { list_dv_thanh_toan, SO_HOADON_ID, changeMoney } = siderRef.current?.siderValues()
        const tienCongNo = TONG_CHI_PHI - MIEN_GIAM - (TONG_DA_THANH_TOAN || 0)
        //
        let list_dv = DS_DV?.map(item => ({
            ID: item.ID,
            RLS_BN_GOI_ID: item.RLS_BN_GOI_ID,
            RLS_BN_GOI_DV_ID: item.RLS_BN_GOI_DV_ID,
            RLS_BN_GOI_DVCON_ID: item.RLS_BN_GOI_DVCON_ID,
            DV_ID: item.DV_ID,
            TEN_DV: item.TEN_DV,
            MA_DV: item.MA_DV,
            PHAN_LOAI: PHAN_LOAI,
            GIAM_TIEN: item.GIAM_TIEN,
            GIAM_PHAN_TRAM: item.GIAM_PHAN_TRAM,
            TONG_TIEN_GIAM: item.TONG_TIEN_GIAM,
            DON_GIA: item.DON_GIA,
            SO_LUONG: item.SO_LUONG,
            THANH_TIEN: Math.max(0, item.THANH_TIEN),
            PHONG_DIEU_TRI_ID: item.PHONG_DIEU_TRI_ID,
            NHOM_BHYT_ID: item.NHOM_BHYT_ID,
            TRANG_THAI: item.TRANG_THAI,
            TONG_DA_THANH_TOAN: item.TONG_DA_THANH_TOAN,
        }))
        // Phân bổ TONG_DA_THANH_TOAN
        let tien_con_lai = valueBenhNhanDua
        const sort_list_dv = _.orderBy(list_dv, ['TONG_DA_THANH_TOAN', 'THANH_TIEN'], ['desc', 'asc'])
        sort_list_dv.forEach(item => {
            const idx = list_dv.findIndex(i => i.ID === item.ID)
            if (tien_con_lai < (item.THANH_TIEN - item.TONG_DA_THANH_TOAN)) {
                list_dv[idx].TONG_DA_THANH_TOAN = tien_con_lai + item.TONG_DA_THANH_TOAN
                tien_con_lai = 0
            } else {
                tien_con_lai = Math.max(0, tien_con_lai - (item.THANH_TIEN - item.TONG_DA_THANH_TOAN))
                list_dv[idx].TONG_DA_THANH_TOAN = item.THANH_TIEN
            }
        })
        //
        const payload = {
            TIEN_THUA: changeMoney,
            BENH_NHAN_ID,
            LICH_KHAM_ID,
            MIEN_GIAM,
            MIEN_GIAM_PHAN_TRAM,
            NHANSU_ID,
            NHOM: 'THANH_TOAN',
            PHAN_LOAI: 'CONG_NO',
            TONG_CHI_PHI,
            TONG_THANH_TOAN,
            HOA_DON_CHA_ID,
            BENH_NHAN_DUA: valueBenhNhanDua,
            SO_HOADON_ID,
            GOI_DV_ID: GOI_DV_ID || null,
            DA_THANH_TOAN: TONG_DA_THANH_TOAN,
            list_dv_thanh_toan,
            list_dv,
            SO_TAMUNG_ID: isAdvance ? advanceMoney?.SO_TAMUNG_ID : null,
            HOA_DON_ID,
        }
        //
        if (isPrint) {
            dispatch(payDebtAndPrint({
                keyPhieu: 'phieuCongNo', payload, callback: (dataRequest, isOK) => {
                    if (isOK) {
                        if (tienCongNo <= valueBenhNhanDua) {
                            setSelectedTab && setSelectedTab('DA_THANH_TOAN')
                        }
                        setRender && setRender('DA_THANH_TOAN')
                        !onAfterSubmit && pdfRefDaTTControl.show(dataRequest, LICH_KHAM_ID ? apis.in_cong_no_dv : apis.pdf_quan_ly_cong_no);
                        onAfterSubmit && onAfterSubmit({
                            isPrint,
                            dataRequest,
                            apis: LICH_KHAM_ID ? apis.in_cong_no_dv : apis.pdf_quan_ly_cong_no
                        })
                    } else {
                        siderMainRef.current?.reload()
                        dispatch(updatePatientInfo({}))
                        dispatch(getUnpaidDebtSuccess({}))
                    }
                }
            }))
        } else {
            dispatch(payDebt({
                payload, callback: (isOK) => {
                    if (isOK) {
                        if ((TONG_THANH_TOAN - TONG_DA_THANH_TOAN) <= valueBenhNhanDua) {
                            setSelectedTab && setSelectedTab('DA_THANH_TOAN')
                        }
                        setRender && setRender('DA_THANH_TOAN')
                        onAfterSubmit && onAfterSubmit()
                    } else {
                        siderMainRef.current?.reload()
                        dispatch(updatePatientInfo({}))
                        dispatch(getUnpaidDebtSuccess({}))
                    }
                }
            }))
        }
    }

    const keydownEvent = (event) => {
        if (!isUseKeyboard) return
        if (valueBenhNhanDua === 0) return
        if (event.ctrlKey && event.key === "s") {
            event.stopPropagation();
            event.preventDefault();
            handlePay(false);
        }
        if (event.ctrlKey && event.key === "l") {
            event.stopPropagation();
            event.preventDefault();
            handlePay(true);
        }
    }

    useEventListener("keydown", keydownEvent, window.document);
    // console.log(`permission`, permission);
    return (
        <>
            <Layout>
                <Content />
                <Sider ref={siderRef} isAdvance={isAdvance} setIsAdvance={setIsAdvance} valueBenhNhanDua={valueBenhNhanDua} />
            </Layout>
            <Layout.Footer className={style['footer']}>
                <Space>
                    {/* <Button disabled={!patientInfo.billId} type="primary" ghost onClick={() => handleSettlement()}>{i18n.t(languageKeys.settlement)} (Ctrl+E)</Button> */}
                    <Tooltip title={permission.HOAN_NO ? "" : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                        <Button loading={loading} disabled={!permission.HOAN_NO || !patientInfo?.ID} type="primary" onClick={() => huyCongNo()}>{i18n.t(languageKeys.huy_cong_no)}</Button>
                    </Tooltip>
                    <Tooltip title={(permission.THANH_TOAN && permission.IN_PDF) ? "" : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                        <Button disabled={((permission.THANH_TOAN && permission.IN_PDF) ? false : true) || !patientInfo.billId || !valueBenhNhanDua} type="primary" onClick={() => handlePay(true)}>{i18n.t(languageKeys.vien_phi_thanh_toan_va_in)}  {isUseKeyboard && `(Ctrl+L)`}</Button>
                    </Tooltip>
                    <Tooltip title={permission.THANH_TOAN ? "" : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                        <Button disabled={!permission.THANH_TOAN || !patientInfo.billId || !valueBenhNhanDua} type="primary" onClick={() => handlePay(false)}>{i18n.t(languageKeys.field_Thanh_toan)} {isUseKeyboard && `(Ctrl+S)`} </Button>
                    </Tooltip>
                </Space>
            </Layout.Footer>
            <ModalPDF ref={pdfRef} />

            <ConfirmModal
                isCloseWhenOk={false}
                ref={refWarning}
                // content=''
                content={i18n.t(languageKeys.huy_cong_no_warning)}
                className={style['modal-content']}
                danger
                onOk={handleHuyCongNo}
            >
                <div style={{ padding: 20 }}>
                    <Form form={form}>
                        <Form.Item name="GHI_CHU" style={{ margin: 0 }} label={i18n.t(languageKeys.ly_do)} rules={[
                            {
                                required: true,
                                message: i18n.t(languageKeys.vui_long_nhap),
                            },
                        ]}>
                            <Input
                                allowClear
                                placeholder={i18n.t(languageKeys.nhap_ly_do)}

                            />
                        </Form.Item>
                    </Form>
                </div>
            </ConfirmModal>
        </>
    )
}

export default CongNoChuaTT