/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apis, keys } from "../../../../../constants";
import { common_post, HLog } from "../../../../../helpers";

export function hooksPhieuChuyenKho({visible, readOnly}){
    const userProfile = useSelector(state => state.auth.user)
    const [dsKhoXuat, setDsKhoXuat] = useState([])
    const [dsKhoNhap, setDsKhoNhap] = useState([])
    const [seletedKhoXuat, setSelectedKhoXuat] = useState()
    const [selectedKhoNhap , setSelectedKhoNhap] = useState()
  

    useEffect(() => {
       if (visible && !readOnly) {
        initKho({})
       }
        // layDsThuoc()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, readOnly])

    useEffect(() => {
      if (seletedKhoXuat) {
        layChiTietKho(seletedKhoXuat)
      }
    }, [seletedKhoXuat])    
    
    
    async function initKho () {
        let result = await layDsKho({})
        //setDsKhoNhap(result)
        setDsKhoXuat(result)
    }

    async function layChiTietKho (ID) {
        try {
            let req = {
                partner_code : userProfile.partner_code,
                BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
                ID
            }
            const res = await common_post(apis.lay_chi_tiet_kho_thuoc, req)
            console.log(res)
            if (res && res.status === "OK") {
                setDsKhoNhap(res.result.KHO_CHUYEN)
            }
        } catch (error) {
            
        }
    }

    async function layDsKho({search_string = "" , page = 1,LOAI_TRU = [] }){
        try {
            let req = {
                partner_code : userProfile.partner_code,
                BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
                limit : keys.limit,
                page ,
                search_string,
                LOAI_KHO : "",
                LOAI_TRU ,

            }
            const res = await common_post(apis.lay_ds_kho_thuoc_hieu_luc, req)
            console.log("ds kho thuốc", res)
            if (res && res.status === "OK") {
                return res.result.map(item => ({TEN_KHO : item.TEN_KHO, ID : item.ID}))
            }
        } catch (error) {
            HLog("error ds kho thuốc", error)
        }
    }
    
    return {
        dsKhoNhap,
        dsKhoXuat,
        selectedKhoNhap,
        seletedKhoXuat,
        setSelectedKhoNhap,
        setSelectedKhoXuat,
        setDsKhoNhap,
        setDsKhoXuat
       
    }
    
}