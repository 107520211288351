import React, { useEffect } from "react";
import style from "./style.module.less";
import { Row, Col, Form, Radio, Checkbox } from "antd";
import {FieldKhaiThac} from "./fieldKhaiThac";
import i18n, { languageKeys } from "../../../../i18n";
const { Item } = Form;


export const TinhTrangGiacNgu = (
    {
        readOnlyInput,
    }
) => {

    const optionsAns = [
        { label: i18n.t(languageKeys.danh_gia_tt_buon_ngu_qs1), value: 0 },
        { label: i18n.t(languageKeys.danh_gia_tt_buon_ngu_qs2), value: 1 },
        { label: i18n.t(languageKeys.danh_gia_tt_buon_ngu_qs3), value: 2 },
        { label: i18n.t(languageKeys.danh_gia_tt_buon_ngu_qs4), value: 3 },
    ]

    const optionsQs = [
        { label: `${i18n.t(languageKeys.ngoi_va_doc)}`, name: "NGOI_VA_DOC" },
        { label: `${i18n.t(languageKeys.xem_truyen_hinh)}`, name: "XEM_TRUYEN_HINH" },
        { label: `${i18n.t(languageKeys.ngoi_yen_cong_cong)}`, name: "NGOI_YEN_O_NOI_CC" },
        { label: `${i18n.t(languageKeys.ngoi_im_mot_tieng)}`, name: "NGOI_TRONG_MOT_TIENG_VOI_TU_CACH_LA_KH_TREN_OTO" },
        { label: `${i18n.t(languageKeys.nam_nghi_buoi_chieu)}`, name: "NAM_NGHI_VAO_BUOI_CHIEU" },
        { label: `${i18n.t(languageKeys.ngoi_va_noi_chuyen)}`, name: "NGOI_VA_NOI_CHUYEN_VOI_NGUOI_KHAC" },
        { label: `${i18n.t(languageKeys.ngoi_im_lang_sau_an_chua)}`, name: "NGOI_IM_LANG_SAU_AN_TRUA" },
        { label: `${i18n.t(languageKeys.ngoi_trong_xe_oto)}`, name: "NGOI_TRONG_XE_OTO" },
    ]
    

    return(
        <div className={style["tien-su-ban-than"]}>
            <Row align={"middle"}>
                {optionsQs.map(objQs=><Col span={12}>
                    <span className={style["text"]} style={{fontSize:16, fontWeight:700}}>{objQs.label}</span>
                    <Item name={objQs.name}>
                        <Radio.Group disabled = {readOnlyInput} style={{display:"flex",flexDirection:"column"}} >
                            {optionsAns.map((objValue)=><Radio value={objValue.value}>{objValue.label}</Radio>)}
                        </Radio.Group>
                    </Item>
                </Col>)}
                

            </Row>
        </div>
    )
}
