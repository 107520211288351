import { Checkbox, Col, Form, InputNumber, Modal, Row } from "antd";
import styles from "./styles.module.less";
import { forwardRef, useImperativeHandle, useState } from "react";

import i18n, { languageKeys } from "../../../../../i18n";
import { useEventListener } from "hooks";

const ModalBarcode = forwardRef(
  (
    {
      optionsPrintSID,
      handlePrint,
      formSID,
      refDsDichVu,
      cuocKham
  },
    ref
  ) => {
    const [visible, setVisible] = useState(false);



    useImperativeHandle(ref, () => ({
      open(data) {
        setVisible(true);
      },
      close() {
        onDismiss();
      },
      visible,
      setVisible,
    }));

    const mappingSuffix = {
      NGHIEM_PHAP_DUONG : "N",
      HbA1c:"H",
      GLUCOSE:"G",
      THUONG:"",
    }
  
    const onPrint = () => {
      try {
        let arrKeys = ['GLUCOSE_NUMBER','HbA1c_NUMBER','NGHIEM_PHAP_DUONG_NUMBER','THUONG_NUMBER']
        const values = formSID.getFieldsValue();
        console.log(values)
        let data = [];
        for (const key of arrKeys) {
          let normalKey = (key) => { 
            let cpkey = key;
              let rmkey = cpkey?.slice(-7);
              return cpkey.replaceAll(rmkey,'');
          }
          if(values?.OPTION_IN?.includes(normalKey(key))) {
            const quality = values[key];
            data = data.concat(Array(quality).fill({
              TEN_LOAI_BP: undefined,
              TEN_KHACH_HANG:cuocKham?.TEN_BENH_NHAN,
              GIOI_TINH:cuocKham?.GIOI_TINH,
              NGAY_SINH: cuocKham?.NGAY_SINH,
              suffix: mappingSuffix[normalKey(key)]
            }))
          }
        }
        return handlePrint({data})
      } catch (error) {
        console.log(error);
      }
    }
  

    const onDismiss = () => {
      setVisible(false);
    };


    const handleKeyPress = (event) => {
    
      if (event.ctrlKey && event.key === "p")  {
        event.stopPropagation();
        event.preventDefault();
        onPrint()
      }
  };

  useEventListener("keydown", handleKeyPress, window.document, visible);

    return (
      <Modal
      open={visible}
      title={i18n.t(languageKeys.in_barcode)}
      onOk={()=>{
        onPrint()
      }}
      onCancel={()=>{
        setVisible(false)
      }}
      wrapClassName={styles["modal"]}
      classFooter={styles["classFooter"]}
      okText={i18n.t(languageKeys.in_barcode) + " (Ctrl + P)"}
      cancelText={`${i18n.t(languageKeys.common_Thoat)} (ESC)`}
    >
      <Form form={formSID} layout="horizontal">
        <div className={styles["container"]}>
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <Form.Item name="OPTION_IN" label={false}>
                <Checkbox.Group>
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Checkbox
                        value="THUONG"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        {i18n.t(languageKeys.barcode_thuong)}
                      </Checkbox>
                    </Col>
  
                    <Col span={24}>
                      <Checkbox
                        value="NGHIEM_PHAP_DUONG"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        {i18n.t(languageKeys.nghiem_phap_duong)}
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="HbA1c"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        HbA1c
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="GLUCOSE"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        {i18n.t(languageKeys.glucose_mau)}
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Form.Item noStyle dependencies={["OPTION_IN"]}>
                    {() => {
                      const valueIn = formSID?.getFieldValue("OPTION_IN") || [];
                      return (
                        <Form.Item
                          initialValue={1}
                          name={"THUONG_NUMBER"}
                          style={{ opacity: valueIn.includes("THUONG") ? 1 : 0 }}
                          label={<span>{i18n.t(languageKeys.txt_So_luong)}</span>}
                        >
                          <InputNumber min={1} placeholder={i18n.t(languageKeys.common_Nhap)} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item noStyle dependencies={["OPTION_IN"]}>
                    {() => {
                      const valueIn = formSID?.getFieldValue("OPTION_IN") || [];
                      return (
                        <Form.Item
                          initialValue={1}
                          name={"NGHIEM_PHAP_DUONG_NUMBER"}
                          style={{ opacity: valueIn.includes("NGHIEM_PHAP_DUONG") ? 1 : 0 }}
                          label={<span>{i18n.t(languageKeys.txt_So_luong)}</span>}
                        >
                          <InputNumber min={1} placeholder={i18n.t(languageKeys.common_Nhap)} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item noStyle dependencies={["OPTION_IN"]}>
                    {() => {
                      const valueIn = formSID?.getFieldValue("OPTION_IN") || [];
                      return (
                        <Form.Item
                          initialValue={1}
                          name={"HbA1c_NUMBER"}
                          style={{ opacity: valueIn.includes("HbA1c") ? 1 : 0 }}
                          label={<span>{i18n.t(languageKeys.txt_So_luong)}</span>}
                        >
                          <InputNumber min={1} placeholder={i18n.t(languageKeys.common_Nhap)} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item noStyle dependencies={["OPTION_IN"]}>
                    {() => {
                      const valueIn = formSID?.getFieldValue("OPTION_IN") || [];
                      return (
                        <Form.Item
                          initialValue={1}
                          name={"GLUCOSE_NUMBER"}
                          style={{ opacity: valueIn.includes("GLUCOSE") ? 1 : 0 }}
                          label={<span>{i18n.t(languageKeys.txt_So_luong)}</span>}
                        >
                          <InputNumber min={1} placeholder={i18n.t(languageKeys.common_Nhap)} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
    );
  }
);


export default ModalBarcode;