import { LoadingOutlined } from "@ant-design/icons";
import { Button, notification, Upload } from "antd";
import { Attachment } from "../../../../../assets/svg";
import i18n, { languageKeys } from "../../../../../i18n";

const TYPE_DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
const TYPE_DOC = "application/msword";
const NOTI_KEY = "upload_file_docx";

export const UploadFileWord = ({ setValue }) => {
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      notification.open({ key: NOTI_KEY, message: i18n.t(languageKeys.noti_dang_chuyen_doi_file), icon: <LoadingOutlined />, duration: 0 });
    }

    if (info.file.status === "done") {
      notification.success({ key: NOTI_KEY, message: i18n.t(languageKeys.noti_chuyen_doi_file_thanh_cong) });
      setValue(info.file.response);
    } else if (info.file.status === "error") {
      notification.error({ key: NOTI_KEY, message: i18n.t(languageKeys.noti_chuyen_doi_file_that_bai) });
    }
  };

  // Kiểm tra định dạng file trước khi upload
  const handleBeforeUpload = (file) => {
    const isDocx = file.type === TYPE_DOCX;
    const isDoc = file.type === TYPE_DOC;

    if (!isDocx && !isDoc) {
      notification.error({ message: i18n.t(languageKeys.noti_dinh_dang_file_word) });
    }

    return isDocx || isDoc || Upload.LIST_IGNORE;
  };

  return (
    <Upload
      showUploadList={false}
      onChange={handleChange}
      beforeUpload={handleBeforeUpload}
      action="https://api.cloudmersive.com/convert/docx/to/html"
      headers={{
        Apikey: "913285c7-2120-4c96-9ed0-79ddbc20c4c6",
      }}
      method="POST"
    >
      <Button type="primary" icon={<Attachment style={{ margin: "3px 3px -3px -3px" }} />}>
        {i18n.t(languageKeys.btn_mo_file_mo_ta)}
      </Button>
    </Upload>
  );
};
