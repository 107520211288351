import i18n, { languageKeys } from "../../../../../i18n";

export const KOCO = [
  {
    label: i18n.t(languageKeys.answer_khong),
    value: 'KO',
  },
  {
    label: i18n.t(languageKeys.answer_co),
    value: 'CO',
  },
]

export const KhamSan_TinhThan = [
  {
    label: i18n.t(languageKeys.tinh_tao),
    value: 'TINH_TAO',
  },
  {
    label: i18n.t(languageKeys.hon_me),
    value: 'HON_ME',
  },
  {
    label: i18n.t(languageKeys.other),
    value: 'KHAC',
  },
]

export const KhamSan_DauVetMo = [
  {
    label: i18n.t(languageKeys.answer_khong),
    value: 'KO',
  },
  {
    label: i18n.t(languageKeys.answer_co),
    value: 'CO',
  },
  {
    label: i18n.t(languageKeys.dau_vet_mo),
    value: 'DAU',
  },
]

export const NormalState = [
  {
    label: i18n.t(languageKeys.binh_thuong),
    value: 'BINH_THUONG',
  },
  {
    label: i18n.t(languageKeys.bat_thuong),
    value: 'BAT_THUONG',
  },
]

export const KhamSan_CoTuCung = [
  {
    label: i18n.t(languageKeys.common_dong),
    value: 'DONG',
  },
  {
    label: i18n.t(languageKeys.xoa),
    value: 'XOA',
  },
]

export const KhamSan_DauOi = [
  {
    label: i18n.t(languageKeys.phoong),
    value: 'PHONG',
  },
  {
    label: i18n.t(languageKeys.det),
    value: 'DET',
  },
  {
    label: i18n.t(languageKeys.qua_le),
    value: 'QUA_LE',
  },
  {
    label: i18n.t(languageKeys.ri),
    value: 'RI',
  },
  {
    label: i18n.t(languageKeys.broken),
    value: 'VO',
  },
]

export const KhamSan_NuocOi = [
  {
    label: i18n.t(languageKeys.clear),
    value: 'TRONG',
  },
  {
    label: i18n.t(languageKeys.xanh_ban),
    value: 'XANH_BAN',
  },
  {
    label: i18n.t(languageKeys.broken),
    value: 'LAN_MAU',
  },
]

export const AmDuongTinh = [
  {
    label: i18n.t(languageKeys.am_tinh),
    value: 'AM',
  },
  {
    label: i18n.t(languageKeys.duong_tinh),
    value: 'DUONG',
  },
]