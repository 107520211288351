import { Button, Col, DatePicker, Form, Input, Row, Space, Spin, TimePicker, notification } from "antd";
import { apis } from "../../../../../constants";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { FormDrawer, ModalPDF } from "components";
import i18n, { languageKeys } from "../../../../../i18n";
import { useEventListener } from "hooks";
import styles from "./toolbar.module.less";
import MainContent from "../../KeDonThuoc/Content";
import { common_post } from "helpers";
import moment from "moment";

const PhieuChuyenVien = forwardRef(({ readOnly }, ref) => {

  const pdfRef = useRef()

  const [form] = Form.useForm()

  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [patientInfo, setPatientInfo] = useState();
  const [medList, setMedList] = useState([{}]);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    open: (patientInfo) => {
      handleFetchData(patientInfo)
      setPatientInfo(patientInfo)
      setOpen(true)
    }
  }));


  const handleClose = () => {
    setOpen(false)
    setPatientInfo()
    setMedList([{}])
    form.resetFields()
    setIsNew(true)
  }


  const handleFetchData = async (patientInfo) => {
    setLoading(true)
    try {
      const res = await common_post(apis.LayChiTietPhieuChuyenVien, {
        LUOT_DIEU_TRI_ID: patientInfo?.LUOT_DIEU_TRI_ID,
        BENH_NHAN_ID: patientInfo?.BENH_NHAN_ID,
        LICH_KHAM_ID: patientInfo?.LICH_KHAM_ID,
      },)
      if (res && res.status === 'OK') {
        const record = res.result?.phieuChuyenVien?.[0]
        if (record) {
          form.setFields([
            ...Object.keys(record).map((item) => {
              if (item === "GIO_TAO") {
                return {
                  name: item,
                  value: record.GIO_TAO ? moment(record[item], "HH:mm") : undefined,
                }
              } else if (item === "NGAY_TAO") {
                return {
                  name: item,
                  value: record.NGAY_TAO ? moment(record[item], "YYYYMMDD") : undefined,
                }
              } else {
                return { name: item, value: record[item] }
              }
            }),
            { name: 'CAC_XET_NGHIEM', value: res.result?.dvuXetNghiem?.flatMap(i => i.TEN_DICHVU).join(", ") },
          ]);
          setMedList(res.result?.thuocDaDung
            ?.map(i => ({
              Thuoc_ID: i.ID,
              Thuoc_Ten: i.TEN_THUOC,
              Thuoc_Dvt: i.DON_VI_TINH,
              Thuoc_Dvt_Cach_Dung: i.DON_VI_TINH_CACH_DUNG,
              Thuoc_SL: i.SO_LUONG,
              SoNgaySD: i.SO_NGAY_SU_DUNG,
              Chidan: i.HUONG_DAN_SU_DUNG,
              ThoiDiemDung: i.CACH_DUNG_THUOC,
              Duongdung: i.DUONG_DUNG_BYT,
              TAN_SUAT_NGAY: i.TAN_SUAT_SU_DUNG,
              Lieuluong: i.MOI_NGAY_SU_DUNG,
              Tansuat: i.CHIA_LAM,
              DON_THUOC_CHUYEN_VIEN_ID: i.DON_THUOC_CHUYEN_VIEN_ID,
            }))
            .concat([{}])
          )
          setIsNew(false)
        } else {
          form.setFields([
            { name: 'DAU_HIEU_LAM_SANG', value: res.result?.thongtinChung?.treatmentInfo?.[0]?.QUA_TRINH_BENH_LY },
            { name: 'CAC_XET_NGHIEM', value: res.result?.dvuXetNghiem?.flatMap(i => i.TEN_DICHVU).join(", ") },
            { name: 'CHAN_DOAN', value: res.result?.thongtinChung?.icd?.[0]?.TEN_ICD },
          ]);
          setMedList(res.result?.thuocKeDon.concat([{}]))
        }

      } else {
        notification.error({ message: i18n.t(languageKeys.cap_nhat_that_bai), placement: 'bottomLeft' });
      }
    } catch (e) {
      console.log(e)
      notification.error({ message: i18n.t(languageKeys.common_co_loi_xay_ra), placement: 'bottomLeft' });
    } finally {
      setLoading(false)
    }
  }


  const handleSubmit = async (print) => {
    const data = await form.validateFields()
    //
    setLoading(true)
    const payload = {
      LUOT_DIEU_TRI_ID: patientInfo?.LUOT_DIEU_TRI_ID,
      BENH_NHAN_ID: patientInfo?.BENH_NHAN_ID,
      LICH_KHAM_ID: patientInfo?.LICH_KHAM_ID,
      ...data,
      GIO_TAO: data.GIO_TAO ? moment(data.GIO_TAO).format('HH:mm') : undefined,
      NGAY_TAO: data.NGAY_TAO ? moment(data.NGAY_TAO).format('YYYYMMDD') : undefined,
      DON_THUOC_CHUYEN_VIEN: medList
        .filter(i => i.Thuoc_SL)
        .map(i => {
          Object.keys(i).forEach(key => {
            if (i[key] === "") delete i[key];
          })
          return {
            THUOC_ID: i.Thuoc_ID,
            DON_VI_TINH: i.Thuoc_Dvt,
            DON_VI_TINH_CACH_DUNG: i.Thuoc_Dvt_Cach_Dung,
            SO_LUONG: i.Thuoc_SL,
            SO_NGAY_SU_DUNG: i.SoNgaySD,
            HUONG_DAN_SU_DUNG: i.Chidan,
            CACH_DUNG_THUOC: i.ThoiDiemDung,
            DUONG_DUNG_BYT: i.Duongdung,
            TAN_SUAT_SU_DUNG: i.TAN_SUAT_NGAY,
            MOI_NGAY_SU_DUNG: i.Lieuluong,
            CHIA_LAM: i.Tansuat,
            DON_THUOC_CHUYEN_VIEN_ID: i.DON_THUOC_CHUYEN_VIEN_ID || null,
          }
        })
    }
    try {
      const res = await common_post(apis.LuuPhieuChuyenVien, payload)
      if (res && res.status === 'OK') {
        notification.success({ message: i18n.t(languageKeys.cap_nhat_thanh_cong), placement: 'bottomLeft' });
        handleClose()
        print && handlePrint()
      } else {
        notification.error({ message: i18n.t(languageKeys.cap_nhat_that_bai), placement: 'bottomLeft' });
      }
    } catch (e) {
      console.log(e)
      notification.error({ message: i18n.t(languageKeys.common_co_loi_xay_ra), placement: 'bottomLeft' });
    } finally {
      setLoading(false)
    }
  }


  const handlePrint = () => {
    pdfRef.current.openModal(
      {
        LUOT_DIEU_TRI_ID: patientInfo?.LUOT_DIEU_TRI_ID,
        BENH_NHAN_ID: patientInfo?.BENH_NHAN_ID,
        LICH_KHAM_ID: patientInfo?.LICH_KHAM_ID,
      },
      apis.InPhieuChuyenVien
    )
  }


  const keydownEvent = (event) => {
    const preventDefault = () => {
      event.stopPropagation();
      event.preventDefault();
    }
    if (event.ctrlKey && event.key === "p") {
      preventDefault()
      handlePrint()
    }
    if (event.ctrlKey && event.key === "l") {
      preventDefault()
      handleSubmit(true)
    }
    if (event.ctrlKey && event.key === "s") {
      preventDefault()
      handleSubmit(false)
    }
  };
  useEventListener("keydown", keydownEvent, window.document, open);


  const FormFooter = (
    <Space style={{ float: 'right' }}>
      <Button type="primary" ghost loading={loading} onClick={handleClose}>
        {i18n.t(languageKeys.common_Thoat)} (ESC)
      </Button>
      {!isNew ? (
        <Button type="primary" loading={loading} onClick={handlePrint}>
          {i18n.t(languageKeys.field_In_phieu)} (Ctrl + P)
        </Button>
      ) : <></>}
      <Button type="primary" loading={loading} onClick={() => handleSubmit(true)}>
        {i18n.t(languageKeys.luu_va_in)} (Ctrl+L)
      </Button>
      <Button type="primary" loading={loading} onClick={() => handleSubmit(false)}>
        {i18n.t(languageKeys.common_Luu)} (Ctrl+S)
      </Button>
    </Space>
  )

  return (
    <FormDrawer
      open={open}
      width={'calc(100vw - 60px)'}
      title={i18n.t(languageKeys.phieu_chuyen_vien)}
      onCancel={handleClose}
      back
      hiddenTopAction={true}
      footer={FormFooter}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" className={styles.form}>

          <Form.Item label={i18n.t(languageKeys.dau_hieu_lam_sang)} name={"DAU_HIEU_LAM_SANG"}>
            <Input.TextArea placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} />
          </Form.Item>

          <Form.Item label={i18n.t(languageKeys.cac_xet_nghiem)} name={"CAC_XET_NGHIEM"}>
            <Input readOnly />
          </Form.Item>

          <Form.Item label={i18n.t(languageKeys.chan_doan)} name={"CHAN_DOAN"}>
            <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} />
          </Form.Item>

          <label>{i18n.t(languageKeys.thuoc_da_dung)}</label>
          <div className={styles.wrapper}>
            <MainContent
              disabled={readOnly}
              patientInfo={patientInfo}
              dataSource={medList}
              setDataSource={setMedList}
              editable
              forceHideStorage
            />
          </div>

          <Form.Item label={i18n.t(languageKeys.tinh_trang_luc_chuyen_vien)} name={"TINH_TRANG_NGUOI_BENH"}>
            <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} />
          </Form.Item>

          <Form.Item label={i18n.t(languageKeys.ly_do_chuyen_vien)} name={"LY_DO_CHUYEN_VIEN"}>
            <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} />
          </Form.Item>

          <Row gutter={10} align='bottom'>

            <Col flex={'20%'}>
              <Form.Item label={i18n.t(languageKeys.thoi_gian_chuyen_vien)} name={"GIO_TAO"}>
                <TimePicker placeholder='HH:mm' format='HH:mm' disabled={readOnly} inputReadOnly />
              </Form.Item>
            </Col>

            <Col flex={'20%'}>
              <Form.Item name={"NGAY_TAO"}>
                <DatePicker placeholder='DD/MM/YYYY' format='DD/MM/YYYY' disabled={readOnly} inputReadOnly />
              </Form.Item>
            </Col>

            <Col flex={'20%'}>
              <Form.Item label={i18n.t(languageKeys.phuong_tien_van_chuyen)} name={"PHUONG_TIEN_CHUYEN_VIEN"}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} />
              </Form.Item>
            </Col>

            <Col flex={'20%'}>
              <Form.Item label={i18n.t(languageKeys.ho_ten_ng_dua_di)} name={"HO_TEN_NGUOI_DUA_DI"}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} />
              </Form.Item>
            </Col>

            <Col flex={'20%'}>
              <Form.Item label={i18n.t(languageKeys.field_chuc_danh)} name={"CHUC_DANH"}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly} />
              </Form.Item>
            </Col>

          </Row>

        </Form>
      </Spin>

      <ModalPDF ref={pdfRef} />

    </FormDrawer>
  )
})

export default PhieuChuyenVien;