import { notification } from "antd";
import { apis, keys } from "../../../../constants";
import { loai_doi_tuong } from "../../../../constants/keys";
import { common_post, HLog } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";

export const apiThemDmDoiTuong = async ({ data, BENH_VIEN_ID, partner_code }) => {
  let dataRequest = { ...data, BENH_VIEN_ID, partner_code };
  HLog("data request::::", dataRequest);
  try {
    let response = await common_post(apis.dm_them_doi_tuong, dataRequest, false);
      return response;
  } catch (error) {
    HLog("Api them doi tuong error", error);
  }
};
export const apiSuaDmDoiTuong = async ({ data }) => {
  let dataRequest = { ...data };
  delete dataRequest['key'];
  delete dataRequest['_THU_TU'];
  delete dataRequest['DUNG_CHO_CIS'];
  HLog("sua doi tuong", dataRequest);
  try {
    let response = await common_post(apis.dm_sua_doi_tuong, dataRequest, false);
      return response;
  } catch (error) {
    HLog("Api sua doi tuong error", error);
  }
};
export const apiXoaDmDoiTuong = async ({ data, BENH_VIEN_ID, partner_code,NHANSU_ID }) => {
  try {
    if (!Array.isArray(data)) data = [data];
    let body = {
      BENH_VIEN_ID,
      partner_code,
      NHANSU_ID,
      arr_ID: data.map((item) => item.ID),
    };

    HLog("xoa doi tuong::", body);
    let res = await common_post(apis.dm_xoa_doi_tuong, body, false);
    return res;
  } catch (error) {
    HLog("======::", error);
  }
};
export const apiKhoaDmDoiTuong = async ({ data, lock,BENH_VIEN_ID,partner_code }) => {
  try {
    if (!Array.isArray(data)) data = [data];

    let body = {
      BENH_VIEN_ID,
      partner_code,
      arr_ID: data.map(item => !!item.ID && item.ID),
      KHOA: Number(lock),
    };
    HLog("apiKhoaDmDoiTuong",body);
    let res = await common_post(apis.dm_khoa_doi_tuong, body, false);
    return res;
  } catch (error) {
    HLog("=======================", error);
  }
};
export const apiLayDmDoiTuong = async ({ search_string, page, BENH_VIEN_ID, partner_code }) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      search_string,
      page,
    };
    HLog("data request lay doi tuong::::", req);

    const res = await common_post(apis.dm_lay_ds_doi_tuong, req);
    return res;
  } catch (error) {
    HLog(error);
  }
};
export const apiExportDmDoiTuong = async ({ BENH_VIEN_ID, partner_code }) => {
  try {
    let res = await common_post(apis.dm_export_doi_tuong, { BENH_VIEN_ID, partner_code }, false);
    if (res && res.status === "OK") {
      let { result } = res;
      res.result = result.map((item) => {
        let { ID, MA_DOI_TUONG, TEN_DOI_TUONG,LOAI_DOI_TUONG,THU_TU, KHOA,GHI_CHU } = item;
        return {
          ID,
          MA_DOI_TUONG,
          TEN_DOI_TUONG,
          LOAI_DOI_TUONG,
          GHI_CHU,
          THU_TU,
          KHOA,
        };
      });
    }
    return res;
  } catch (error) {
    HLog("errorEXPortDoiTUong::", error);
  }
};
export const apiImportDmDoiTuong = async ({ BENH_VIEN_ID, partner_code, data }) => {
  try {
    let error = false;
    let dsLoai = Object.keys(loai_doi_tuong)

    let _data =data.map(item => {
      if(!dsLoai.includes(item.LOAI_DOI_TUONG)){
        error = true;
      }
      return ({...item, BENH_VIEN_ID,KHOA:Number(!!item.KHOA)})
    });
    console.log(error);
    if(error){
      notification.error({
        message:`${i18n.t(languageKeys.error_gia_tri_khong_hop_le)}: ${i18n.t(languageKeys.field_loai_doi_tuong)}`,
        placement:"bottomLeft",
      })
      return 
    }

    let body = {
      BENH_VIEN_ID,
      partner_code,
      data:_data,
    };
    let res = await common_post(apis.dm_import_doi_tuong, body, false);
    return res;
  } catch (error) {
    HLog("errorIMPortDoiTUong::", error);
  }
};
