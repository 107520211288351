import { apis, keys } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import { fieldKhoa, fieldPhong, fieldPhongNoiTru } from "../fieldsDanhMuc";

/**
 * API lấy danh sách phòng
 */
export const apiLayDsPhongNoiTru = async ({
  BENH_VIEN_ID = "",
  search_string = "",
  page = 1,
  limit = keys.limit,
  CHI_DINH = [],
  LOAI_TRU = "",
  partner_code = "",
  MA_KHOA = "",
  BUONG_DIEU_TRI_ID = "",
}) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      page,
      limit,
      CHI_DINH,
      LOAI_TRU,
      partner_code,
      MA_KHOA,
      BUONG_DIEU_TRI_ID,
    };

    const response = await common_post(apis.lay_ds_phong_noi_tru, requestData);

    if (response.status === "OK") return response;
  } catch (error) {
    HLog("Api lay danh sach phong error", error);
  }
};

/**
 * API import excel danh sách phòng
 */
export const apiNhapDsPhongNoiTru = async ({
  BENH_VIEN_ID = "",
  data = [],
  partner_code = "",
}) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      data,
      partner_code,
    };

    const response = await common_post(
      apis.import_ds_phong_noi_tru,
      requestData
    );

    return response;
  } catch (error) {
    HLog("Api import danh sach phong error", error);
  }
};

/**
 * API thêm mới phòng
 */
export const apiThemPhongNoiTru = async ({
  BENH_VIEN_ID = "",
  data = {},
  partner_code = "",
}) => {
  try {
    const key_phong_chi_dinh = fieldPhongNoiTru.phong_chi_dinh;
    const key_ma_khoa = fieldPhongNoiTru.khoa;
    const key_buong_dieu_tri = fieldPhongNoiTru.buong_dieu_tri_id;
    const ma_khoa = fieldKhoa.ma_khoa;

    if (!!data[key_buong_dieu_tri] && data[key_buong_dieu_tri].length > 0) {
      data[key_buong_dieu_tri] = JSON.parse(data[key_buong_dieu_tri]).ID;
    }

    let requestData = {
      ...data,
      BENH_VIEN_ID,
      partner_code,
    };

    return await common_post(apis.tao_phong_noi_tru, requestData);
  } catch (error) {
    HLog("Api them moi phong error", error);
  }
};

/**
 * API sửa phòng nội trú
 */
export const apiSuaPhongNoiTru = async ({ data = {}, currentData = {} }) => {
  try {
    // const key_phong_chi_dinh = fieldPhong.phong_chi_dinh;
    const key_ma_khoa = fieldPhong.khoa;
    const key_buong_dieu_tri = fieldPhongNoiTru.buong_dieu_tri_id;

    console.log(data, currentData);

    if (!!data[key_ma_khoa]) {
      // nếu là json string (chọn mới khoa)
      // eslint-disable-next-line no-useless-escape
      if (
        typeof data[key_ma_khoa] === "string" &&
        data[key_ma_khoa].includes(`{\"`)
      ) {
        data[key_ma_khoa] = JSON.parse(data[key_ma_khoa])[fieldPhong.id];
      }
      // nếu là string tên khoa phòng (không chọn mới khoa)
      else {
        data[key_ma_khoa] = currentData[key_ma_khoa];
      }
    } else {
      data[key_ma_khoa] = null;
    }

    if (
      !!data[key_buong_dieu_tri] &&
      typeof data[key_buong_dieu_tri] === "string" &&
      data[key_buong_dieu_tri].includes(`{\"`)
    ) {
      data[key_buong_dieu_tri] = JSON.parse(data[key_buong_dieu_tri]).ID;
    } else {
      data[key_buong_dieu_tri] = currentData[key_buong_dieu_tri];
    }

    let requestData = { ...data };

    return await common_post(
      apis.cap_nhat_thong_tin_phong_noi_tru,
      requestData
    );
  } catch (error) {
    HLog("Api cap nhat phong error", error);
  }
};

/**
 * API xóa phòng
 */
export const apiXoaPhongNoiTru = async ({ partner_code = "", data = {} }) => {
  try {
    let requestData = { data: [data], partner_code };

    const response = await common_post(apis.xoa_phong_noi_tru, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa phong error", error);
  }
};

/**
 * API lấy thông tin chi tiết phòng
 */
export const apiLayChiTietPhongNoiTru = async ({
  partner_code = "",
  ID = "",
}) => {
  try {
    let requestData = { partner_code, ID };

    const response = await common_post(
      apis.lay_chi_tiet_phong_theo_id_noi_tru,
      requestData
    );

    return response;
  } catch (error) {
    HLog("Api lay chi tiet phong error", error);
  }
};

//  danh sách buồng điều trị
export const apiLayDsBuongDieuTri = async ({
  BENH_VIEN_ID = "",
  page = 1,
  search_string = "",
  limit = keys.limit,
  MA_KHOA = "",
  CHI_DINH = "",
  partner_code = "",
}) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      page,
      limit,
      CHI_DINH,

      MA_KHOA,
      partner_code,
      // KHOA: true,
    };

    const response = await common_post(apis.lay_ds_buong_dieu_tri, requestData);

    return response;
  } catch (error) {
    HLog("Api lay danh sach khoa co hieu luc error", error);
  }
};

/**
 * API xóa danh sách phòng
 */
export const apiXoaDsPhong = async ({ partner_code = "", data = [] }) => {
  try {
    let requestData = { partner_code, data };

    const response = await common_post(apis.dm_phong_xoa, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa danh sach phong error", error);
  }
};

/**
 * API khóa danh sách phòng
 */
export const apiKhoaDsPhongNoiTru = async ({
  partner_code = "",
  data = [],
  lock = false,
}) => {
  try {
    const ID = data.map((item) => item[fieldPhong.id]);
    const KHOA = !!lock ? 1 : 0;

    let requestData = { partner_code, ID, KHOA };

    const response = await common_post(
      apis.doi_trang_thai_khoa_phong_noi_tru,
      requestData
    );

    return response;
  } catch (error) {
    HLog("Api khoa danh sach phong error", error);
  }
};

export const apiExportPhongNoiTru = async ({
  BENH_VIEN_ID = "",
  partner_code = "",
  template = 0,
}) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.export_ds_noi_tru, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(!!template ? "Tai template phong error" : "Export ds phong error");
  }
};
