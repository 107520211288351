import { apis, keys } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import { fieldDonViTinh } from "../fieldsDanhMuc";

export const apiLayDsDonViTinh = async ({ BENH_VIEN_ID = "", page = 1, search_string = "", limit = keys.limit, partner_code = "" }) => {
  let req = { page, limit, search_string, partner_code, BENH_VIEN_ID };
  try {
    let response = await common_post(apis.dm_don_vi_tinh_lay_ds, req);
    if (response && response.status === "OK") {
      return response;
    }
  } catch (error) {
    HLog("Api lay danh sach don vi tinh error", error);
  }
};

export const apiNhapDsDonViTinh = async ({ BENH_VIEN_ID = "", data = [], partner_code = "" }) => {
  try {
    data = data.map((item) => {
      Object.keys(item).forEach((key) => {
        item[key] = item[key].toString();
      });
      return item;
    });
    const req = { data, partner_code, BENH_VIEN_ID };
    let response = await common_post(apis.dm_don_vi_tinh_them_moi, req);
    return response;
  } catch (error) {
    HLog("Api nhap danh sach don vi tinh error", error);
  }
};

export const apiThemDonViTinh = async ({ BENH_VIEN_ID = "", data = {}, partner_code = "" }) => {
  // data = data.map((item) => {
  //   Object.keys(item).forEach((key) => {
  //     item[key] = item[key].toString();
  //   });
  //   return item;
  // });

  let req = { data: [data], partner_code, BENH_VIEN_ID };

  try {
    return await common_post(apis.dm_don_vi_tinh_them_moi, req);
  } catch (error) {
    HLog("Api them don vi tinh error", error);
  }
};

export const apiSuaDonViTinh = async ({ data = {}, partner_code }) => {
  try {
    const req = {
      partner_code,
      ...data,
    };
    return await common_post(apis.dm_don_vi_tinh_cap_nhat, req);
  } catch (error) {
    HLog("Api sua don vi tinh error", error);
  }
};

export const apiXoaDonViTinh = async ({ data = {}, partner_code = "" }) => {
  try {
    const req = {
      partner_code,
      listId: [data[fieldDonViTinh.id]],
    };

    return await common_post(apis.dm_don_vi_tinh_xoa_ds, req);
  } catch (error) {
    HLog("Api xoa don vi tinh error", error);
  }
};

/**
 * API xoa danh sách don vi tinh
 */
export const apiXoaDsDonViTinh = async ({ partner_code = "", data = [] }) => {
  try {
    const listId = data.map((item) => item[fieldDonViTinh.id]);
    const req = { partner_code, listId };
    return await common_post(apis.dm_don_vi_tinh_xoa_ds, req);
  } catch (error) {
    HLog("Api xoa danh sach don vi tinh error", error);
  }
};

/**
 * API khoá danh sách don vi tinh
 */
export const apiKhoaDsDonViTinh = async ({ partner_code = "", data = [], lock = false }) => {
  try {
    const listId = data.map((item) => item[fieldDonViTinh.id]);
    const req = { partner_code, listId, isKhoa: lock ? 1 : 0 };
    return await common_post(apis.dm_don_vi_tinh_khoa_ds, req);
  } catch (error) {
    HLog("Api khoa danh sach don vi tinh error", error);
  }
};

export const apiExportDonViTinh = async ({ BENH_VIEN_ID = "", partner_code = "", template = 0 }) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_don_vi_tinh_export, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(!!template ? "Tai template don vi tinh error" : "Export ds don vi tinh error");
  }
};
