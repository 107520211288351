import { Button, Col, Form, Modal, Row } from "antd";
import React, { useImperativeHandle } from "react";
import style from "./style.module.less";
import i18n, { languageKeys } from "../../../../../i18n";
import { useState } from "react";
import cn from "classnames";
import { forwardRef } from "react";
import { useForm } from "antd/lib/form/Form";
import { Calendar } from "assets/svg";
import moment from "moment";
import { useRef } from "react";
import DateInput from "components/DateInput_v2/DateInput_v2";

const ModalKichHoat = ({ onOk, onCancel }, ref) => {

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tempData, setTempData] = useState();
  const [formPopup] = useForm();
  const inputRef = useRef();
  const [endDate, setEndDate] = useState(moment().format("DD/MM/YYYY"));

  const [isLieuTrinh, setIsLieuTrinh] = useState(false);
  const [numberDay, setNumberDay] = useState();

  useImperativeHandle(ref, () => ({
    open(data, isLieuTrinh) {
      setVisible(true);
      if (!!data) {
        setTempData(data);
        setIsLieuTrinh(isLieuTrinh)
        if (isLieuTrinh) {
          formPopup.setFieldValue("ngay_bat_dau", moment().format("DD/MM/YYYY"));
          setNumberDay(moment(data?.NGAY_HET_HAN).diff(moment(), 'days') + 2)
        } else {
          if (data?.NGAY_KICH_HOAT && !moment(data?.NGAY_KICH_HOAT, "YYYYMMDD").isBefore(new Date())) {
            formPopup.setFieldValue("ngay_bat_dau", moment(data?.NGAY_KICH_HOAT, "YYYYMMDD").format("DD/MM/YYYY"));
            setEndDate(moment(data?.NGAY_KICH_HOAT, "YYYYMMDD").add(data?.NGAY_SU_DUNG, "days").format("DD/MM/YYYY"));
          } else {
            formPopup.setFieldValue("ngay_bat_dau", moment().format("DD/MM/YYYY"));
            setEndDate(moment().add(data?.NGAY_SU_DUNG, "days").format("DD/MM/YYYY"));
          }
        }
      }
    },
    close() {
      onDismiss();
      inputRef?.current.reset();
    },
    visible,
    loading(bool) {
      setLoading(bool);
    },
  }));

  const onClose = () => {
    onCancel(tempData);
    setTempData();
    if (loading) setLoading(false);
    setVisible(false);
    setEndDate()
    setNumberDay()
  };

  const onDismiss = () => {
    setTempData();
    if (loading) setLoading(false);
    setVisible(false);
    setEndDate()
    setNumberDay()
  };

  const onClickOk = () => {
    if (!formPopup.getFieldError('ngay_bat_dau')?.length) {
      setVisible(false);
      onOk(
        !isLieuTrinh
          ? {
            ID: tempData?.ID,
            IS_ACTIVE: tempData?.IS_ACTIVE,
            NGAY_KICH_HOAT: moment(formPopup.getFieldValue('ngay_bat_dau'), 'DD/MM/YYYY').format('YYYYMMDD'),
            NGAY_KET_THUC: moment(endDate, 'DD/MM/YYYY').format('YYYYMMDD'),
          } : {
            ...tempData,
            NGAY_HIEU_LUC: moment(formPopup.getFieldValue('ngay_bat_dau'), 'DD/MM/YYYY').format('YYYYMMDD'),
            NGAY_KICH_HOAT: moment(formPopup.getFieldValue('ngay_bat_dau'), 'DD/MM/YYYY').format('YYYYMMDD'),
            NGAY_SU_DUNG: numberDay
          }
      )
    }
  };

  return (
    <Modal visible={visible} onOk={onClickOk} footer={null} onCancel={onDismiss} className={style["active-modal"]}>
      <h3 className={style["title"]}>{i18n.t(languageKeys.kich_hoat_goi_dv)}</h3>

      <Form form={formPopup} className={style["content"]}>
        <Row gutter={[24, 0]} className={style["content__row"]}>
          <Col className={style['text-fade']} span={8}>{i18n.t(languageKeys.ten_goi)}:</Col>
          <Col span={16}>{tempData?.TEN_GOI_DV}</Col>
        </Row>
        <Row gutter={[24, 0]} className={style["content__row"]}>
          <Col className={style['text-fade']} span={8}>{i18n.t(languageKeys.ngay_kich_hoat)}:</Col>
          <Col span={16}>
            <DateInput
              className={style["date-input"]}
              form={formPopup}
              name={"ngay_bat_dau"}
              placeholder="DD/MM/YYYY"
              readOnly={false}
              suffix={
                <div>
                  <Calendar className={style["calendar"]} />
                </div>
              }
              validateOldDay={true}
              onChangeInput={(value) => {
                formPopup.validateFields().then(() => {
                  isLieuTrinh
                    ? setNumberDay(moment(tempData?.NGAY_HET_HAN).diff(moment(value, "DD/MM/YYYY"), 'days') + 1)
                    : setEndDate(moment(value, "DD/MM/YYYY").add(tempData?.NGAY_SU_DUNG, "days").format("DD/MM/YYYY"));
                }, () => {
                  isLieuTrinh ? setNumberDay() : setEndDate();
                }).catch(() => {})
              }}
              additionalRules={
                [
                  {
                    validator: async (_, value) => {
                      if (value?.length < 10)
                        return Promise.reject(
                          new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le))
                        );
                      if (isLieuTrinh && moment(tempData?.NGAY_HET_HAN).isBefore(moment(value, "DD/MM/YYYY")))
                        return Promise.reject(
                          new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le))
                        );
                    },
                  },
                ]
              }
              ref={inputRef}
            />
          </Col>
        </Row>
        <Row gutter={[24, 0]} className={style["content__row"]}>
          <Col className={style['text-fade']} span={8}>{i18n.t(languageKeys.so_ngay_sd)}:</Col>
          <Col span={16}>{isLieuTrinh ? numberDay : tempData?.NGAY_SU_DUNG}</Col>
        </Row>
        <Row gutter={[24, 0]} className={style["content__row"]}>
          <Col className={style['text-fade']} span={8}>{i18n.t(languageKeys.field_Ngay_ket_thuc)}:</Col>
          <Col span={16}>
            {isLieuTrinh ? moment(tempData?.NGAY_HET_HAN, "YYYYMMDD").format("DD/MM/YYYY") : endDate}
          </Col>
        </Row>
      </Form>
      <div className={style["btns"]}>
        <Button type="primary" ghost onClick={onClose} className={style["btn"]}>
          {i18n.t(languageKeys.common_Thoat)} (Esc)
        </Button>
        <Button type="primary" onClick={onClickOk} loading={loading} className={cn(style["ok-btn"], style["btn"])}>
          {i18n.t(languageKeys.title_Xac_nhan)}
        </Button>
      </div>
    </Modal>
  );
};

export default forwardRef(ModalKichHoat);
