import { Input } from "antd";
import { useState } from "react";
import { Select } from "../../../../../components_v2";
import i18n, { languageKeys } from "../../../../../i18n";

export const SelectKho = ({
  onSelect = () => {},
  readOnly = false,
  // isVisibleKeDonThuoc = false,
  dsKho = [],
  // setDsKho = () => {},
  // patientInfo = {},
  // infoDonThuoc,
  //
  value,
  // setDsThuocDaKeDon = () => {},
  loading,
  record
}) => {

  const [open, setOpen] = useState(false)

  const dataSource = [
    ...dsKho,
    { ID: "DANH_MUC", TEN_KHO: i18n.t(languageKeys.cate_Danh_muc) }
  ]

  // return (
  //   <Form.Item name="TEN_KHO" label={i18n.t(languageKeys.txt_chon_kho)}>
  //     {readOnly ? (
  //       <Input readOnly />
  //     ) : (
  //       <Select
  //         loading={loading}
  //         dataSource={dsKho}
  //         onSelect={(val) => onSelect(val, true, true)}
  //         labelKey="TEN_KHO"
  //         valueKey="TEN_KHO"
  //         allowClear={false}
  //         disabled={dsKho.length === 1}
  //       />
  //     )}
  //   </Form.Item>
  // );
  return readOnly
    ? <Input readOnly value={dataSource.find(i => i.ID === value)?.TEN_KHO} />
    : (
      <Select
        value={value}
        loading={loading}
        dataSource={dataSource}
        onChange={(val) => {
          onSelect(val, true, record.STT - 1, record)
          setOpen(false)
        }}
        labelKey="TEN_KHO"
        valueKey="ID"
        allowClear={false}
        open={open}
        onFocus={() => setTimeout(() => setOpen(true), 100)}
        onClick={() => setOpen(!open)}
        onBlur={() => setOpen(false)}
      />
    )
};
