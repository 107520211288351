import { SearchOutlined } from '@ant-design/icons'
import { Input, Layout, Row, Space, Radio, Button } from 'antd'
import React, { useRef, useState } from 'react'
import i18n, { languageKeys } from '../../../../i18n'
import style from "./style.module.less"
import cn from "classnames"
import { ComplexDatePicker } from '../../../../components'
import { keyTabs } from '../NhapXuat'
import moment from 'moment'
function SiderFilter({
    currentTab,
    handleApply = () => {}
}, ref) {
    const [searchString, setSearchString] = useState("")
    const [loaiPhieu, setLoaiPhieu] = useState("")
    const [currentKhoangTgian, setCurrentKhoangTgian] = useState(["", ""]);
    const datePickerRef = useRef()

    function handleChangeKhoangTgian(values) {
        setCurrentKhoangTgian(values);
    }

    function handleResetAll() {
        datePickerRef.current?.reset();
        setCurrentKhoangTgian([null, null]);
        setSearchString("")
        setLoaiPhieu("")

        handleApply({
            TU: "",
            DEN: "",
            PHAN_LOAI : "",
            search_string : ""
          });
    }

    function onApply(e) {
        const { value: search_string } = e.target;

        handleApply({
            TU: !!currentKhoangTgian[0]
                ? currentKhoangTgian[0].format("YYYYMMDD")
                : "",
                    DEN: !!currentKhoangTgian[1]
                ? currentKhoangTgian[1].format("YYYYMMDD")
                : "",
            PHAN_LOAI : loaiPhieu,
            search_string
        })
    }
    return (
        <Layout.Sider className={style["sider-filter"]} width={240}>
            <Row gutter={[0, 5]} className={style["wrapper"]}>
                <Input
                    placeholder={i18n.t(languageKeys.common_Tim_kiem)}
                    prefix={<SearchOutlined className="blue-txt"/>}
                    onChange={(e) => {
                        const { value } = e.target;

                        setSearchString(value);

                        if(!value) onApply(e);
                    }}
                    value={searchString}
                    onPressEnter={onApply}
                    allowClear
                />
            </Row>

            <div className={style["wrapper"]}>
                <label className={cn("navy-txt bold-txt", style["label"])}>
                    {i18n.t(languageKeys.label_Thoi_gian)}
                </label>

                <ComplexDatePicker
                    ref={datePickerRef}
                    onChange={handleChangeKhoangTgian}
                    showAll
                    getFuture={false}
                />
            </div>

            <div className={style["wrapper"]}>
                <label className={cn("navy-txt bold-txt", style["label"])}>
                    {i18n.t(languageKeys.loai_phieu)}
                </label>
                {
                    currentTab === keyTabs.PHIEU_NHAP ?
                        <Radio.Group onChange={(e) => {
                            if (e.target.value !== loaiPhieu) {
                                setLoaiPhieu(e.target.value)
                            }
                        }} value={loaiPhieu}>
                            <Space direction="vertical">
                                {phieu_nhap.map((item) => (
                                    <Radio key={item.key} value={item.key} className={style["radio-item"]}>
                                        <span>{item.label}</span>
                                    </Radio>
                                ))}
                            </Space>
                        </Radio.Group> :

                        <Radio.Group onChange={(e) => {
                            if (e.target.value !== loaiPhieu) {
                                setLoaiPhieu(e.target.value)
                            }
                        }} value={loaiPhieu}>
                            <Space direction="vertical">
                                {phieu_xuat.map((item) => (
                                    <Radio key={item.key} value={item.key} className={style["radio-item"]}>
                                        <span>{item.label}</span>
                                    </Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                }
            </div>
            <div className={style["wrapper-last"]}>
                <Button
                    type="primary"
                    ghost
                    onClick={handleResetAll}
                    style={{ width: "45%" }}
                >
                    {i18n.t(languageKeys.button_Cai_dat_lai)}
                </Button>

                <Button type="primary" onClick={onApply} style={{ width: "45%" }}>
                    {i18n.t(languageKeys.common_Ap_dung)}
                </Button>
            </div>
        </Layout.Sider>
    )
}

export default SiderFilter

const phieu_xuat = [
    {
        key: "",
        label: i18n.t(languageKeys.tat_ca)
    },
    {
        key: 'XUAT_TRA_NCC',
        label: i18n.t(languageKeys.loai_phieu_xuat_tra_ncc),
    },
    {
        key: 'XUAT_VO_HONG',
        label: i18n.t(languageKeys.xuat_vo_hong),
    },
    {
        key: 'XUAT_HUY',
        label: i18n.t(languageKeys.title_phieu_xuat_huy),
    },
    {
        key: 'XUAT_HET_HAN',
        label: i18n.t(languageKeys.xuat_het_han_su_dung),
    },
    {
        key: 'BAN_BENH_NHAN',
        label: i18n.t(languageKeys.ban_benh_nhan),
    },
    {
        key: 'XUAT_KHAC',
        label: i18n.t(languageKeys.xuat_khac),
    },
]
const phieu_nhap = [
    {
        key: "",
        label: i18n.t(languageKeys.tat_ca)
    },
    {
        key: 'PHIEU_NHAP',
        label: i18n.t(languageKeys.nhap_tu_ncc),
    },
    {
        key: 'CHUYEN_KHO',
        label: i18n.t(languageKeys.nhap_tu_kho_khac),
    },
    // {
    //   key: 'NHAP_TU_NGUON',
    //   label: 'Nhập khác',
    // },
]