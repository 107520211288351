import { Button, Col, Row } from "antd";
import React from "react";
import i18n, { languageKeys } from "../../../../i18n";
import InputSearchPatient from "../components/InputSearchPatient";
import { QRcode } from "assets/svg";
import Icon from "@ant-design/icons/lib/components/Icon";
import { useDispatch } from "react-redux";
import { snapshotActions } from "ducks/slices/snapshotSlice";

const DrawerActions = ({
  onSelectPatient = () => {},
  showInsuranceBtns = true,
  disbleButtonDefault = false,
  visible = false,
  focusNameInput = () => {},
  clearInfo=() => {},
}) => {

  const dispatch = useDispatch()

  return (
    <Row gutter={10}>
      <Col>
        <InputSearchPatient
          onSelect={onSelectPatient}
          visibleConfig={visible}
          onBlurInput={focusNameInput}
          onClearFunction={clearInfo}
          showClearBtn={true}
        />
      </Col>

      <Col>
        <Button
          type="primary"
          icon={<Icon component={QRcode} style={{ color: "#fff", transform:"scale(1.2)" }} />}
          onClick={() => {
            dispatch(snapshotActions.setModeQR(true))
            document.getElementById('QR').focus()
          }}
          // disabled={disbleButtonDefault}
        >QR code</Button>
      </Col>

      {showInsuranceBtns && (
        <>
          <Col>
            <Button type="primary" onClick={() => {}} ghost disabled={disbleButtonDefault}>
              {i18n.t(languageKeys.button_nhap_BHYT)}
            </Button>
          </Col>

          <Col>
            <Button type="primary" onClick={() => {}} ghost disabled={disbleButtonDefault}>
              {i18n.t(languageKeys.button_kiem_tra_BHYT)}
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
};

export default DrawerActions;
