import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { paths } from "../../constants";
import i18n, { languageKeys } from "../../i18n";
import style from "./home.module.less";

export const LoginSuccess = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      window?.opener?.postMessage("LOGIN SUCCESS");
    }, 1000);

    setTimeout(() => {
      history.push(paths.main);
    }, 1100);
  }, [history]);

  return (
    <div className={style['login-success']}>


  <div className={`${style['text-block']} blue-txt`}>{i18n.t(languageKeys.da_xac_thuc)}. {i18n.t(languageKeys.chuyen_huong_toi_trang_chu)}...</div>;
    </div> )
};
