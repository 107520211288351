import { apis, keys } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import { fieldNhomHoaDon } from "../fieldsDanhMuc";

/**
 * API lấy danh sách nhóm hóa đơn
 */
export const apiLayDsNhomHoaDon = async ({
  BENH_VIEN_ID = "",
  search_string = "",
  partner_code = "",
  page = 1,
  limit = keys.limit,
}) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      partner_code,
      page,
      limit,
    };

    const response = await common_post(apis.dm_hoa_don_lay_ds, requestData);

    return response;
  } catch (error) {
    HLog("Api lay ds nhom hoa don error", error);
  }
};

/**
 * API lấy danh sách nhóm hóa đơn co hieu luc
 */
export const apiLayDsNhomHoaDonCoHieuLuc = async ({
  BENH_VIEN_ID = "",
  search_string = "",
  partner_code = "",
  page = 1,
  limit = keys.limit,
}) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      partner_code,
      page,
      limit,
    };

    return await common_post(apis.dm_hoa_don_lay_ds_co_hieu_luc, requestData);
  } catch (error) {
    HLog("Api lay ds nhom hoa don co hieu luc error", error);
  }
};

/**
 * API thêm nhóm hóa đơn
 */
export const apiThemNhomHoaDon = async ({
  BENH_VIEN_ID = "",
  data = {},
  partner_code = "",
}) => {
  try {
    let requestData = {
      data: [data],
      BENH_VIEN_ID,
      partner_code,
    };

    return await common_post(apis.dm_hoa_don_them_moi, requestData);
  } catch (error) {
    HLog("Api them moi nhom hoa don error", error);
  }
};

export const apiNhapDsNhomHoaDon = async ({
  BENH_VIEN_ID = "",
  data = [],
  partner_code = "",
}) => {
  try {
    let requestData = {
      data,
      BENH_VIEN_ID,
      partner_code,
    };

    return await common_post(apis.dm_hoa_don_them_moi, requestData);
  } catch (error) {
    HLog("Api nhap ds nhom hoa don error", error);
  }
};

/**
 * API sửa nhóm hóa đơn
 */
export const apiSuaNhomHoaDon = async ({ data = {} }) => {
  try {
    let requestData = { ...data };

    return await common_post(apis.dm_hoa_don_cap_nhat, requestData);
  } catch (error) {
    HLog("Api cap nhat nhom hoa don error", error);
  }
};

/**
 * API xoá nhóm hóa đơn
 */
export const apiXoaNhomHoaDon = async ({ partner_code = "", data = {} }) => {
  try {
    const listId = [data[fieldNhomHoaDon.id]];

    let requestData = {
      listId,
      partner_code,
    };

    const response = await common_post(apis.dm_hoa_don_xoa, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa nhom hoa don error", error);
  }
};

/**
 * API xoá nhóm hóa đơn
 */
export const apiXoaDsNhomHoaDon = async ({ partner_code = "", data = [] }) => {
  try {
    const listId = data.map((item) => item[fieldNhomHoaDon.id]);

    let requestData = {
      listId,
      partner_code,
    };

    const response = await common_post(apis.dm_hoa_don_xoa, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa nhom hoa don error", error);
  }
};

/**
 * API khoá danh sách nhom hoa don
 */
export const apiKhoaDsNhomHoadon = async ({
  partner_code = "",
  data = [],
  lock = false,
}) => {
  try {
    const listId = data.map((item) => item[fieldNhomHoaDon.id]);
    const req = { partner_code, listId };
    const api = lock ? apis.dm_hoa_don_lock_ds : apis.dm_hoa_don_unlock_ds;
    const response = await common_post(api, req);
    return response;
  } catch (error) {
    HLog("Api khoa danh sach nhom hoa don error", error);
  }
};

export const apiExportNhomHoaDon = async ({
  BENH_VIEN_ID = "",
  partner_code = "",
  template = 0,
}) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_hoa_don_export, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(
      !!template
        ? "Tai template nhom hoa don error"
        : "Export ds nhom hoa don error"
    );
  }
};
