import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Input } from "antd";
import { useState, useEffect } from "react";
import { Table } from "../../../components";
import { rid } from "../../../helpers";
import style from "./style.module.less";

const SelectRows = ({
  dataSource = [],
  columns,
  keyValueInput,
  onSelectRow = () => {},
  initTxt = "",
  notEdit = false,
  disabled = false,
  width = 400,
  showSelect = true,
  placeholder,
  onOpen = () => {},
  error
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Dropdown
      trigger="click"
      visible={visible}
      disabled={disabled}
      onVisibleChange={(bool) => {
        if (notEdit) {
          return;
        }
        setVisible(bool);
        if (bool) onOpen();
      }}
      placement="bottomLeft"
      overlay={
        showSelect ? (
          <div className={style["popup-ctn"]}>
            <Table
              columns={columns}
              dataSource={dataSource.map((item) => ({ ...item, key: rid() }))}
              className={style["popup-thx-table"]}
              scroll={{ y: 250, scrollToFirstRowOnChange: false }}
              style={{ width }}
              onClickRow={(value) => {
                onSelectRow(value);
                setVisible(false);
              }}
              showGap={false}
            />
          </div>
        ) : (
          <></>
        )
      }
      // overlayClassName={style["popup-thx"]}
    >
      <div className={style["input-ctn"]}>
        <Input
          placeholder={placeholder}
          readOnly
          disabled={disabled}
          value={initTxt}
          className={keyValueInput === "price" && style["text-price"]}
          suffix={showSelect && <DownOutlined />}
          style={error ? { borderColor: "red" } : {}}
        />
      </div>
    </Dropdown>
  );
};

export default SelectRows;
