import { Radio, Space, Spin } from "antd";
import { ConfirmModal } from "components";
import i18n, { languageKeys } from "i18n";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import style from "./style.module.less"
import { layDsHoaDonKhaDung } from "pages/QuanLyVienPhi/DichVuCanThanhToan/apisDvThanhToan/apisSiderBar";
import { useSelector } from "react-redux";

const ModalChonSo = ({ bookId, setBookId, danger, onFinish }, ref) => {

  const confirmRef = useRef()

  const [dsSoHoaDon, setDsSoHoaDon] = useState([]);
  const [loading, setLoading] = useState([]);

  const userProfile = useSelector((state) => state.auth.user);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      laySoHoaDon()
      confirmRef.current.open(data)
    }
  }))

  const laySoHoaDon = async () => {
    setLoading(true)
    try {
      let dataRequest = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        LOAI_SO: "SO_THU_TIEN",
        limit: 1000,
        page: 1,
        NHANSU_ID: userProfile.NHANSU_ID,
      };
      const response = await layDsHoaDonKhaDung(dataRequest);
      if (response) {
        const dataList = response.result;
        setDsSoHoaDon(dataList);
        setBookId(dataList[0].ID)
      }
    } catch (e) {

    } finally {
      setLoading(false)
    }
  }

  return (
    <ConfirmModal
      ref={confirmRef}
      title={danger ? i18n.t(languageKeys.common_Canh_bao) : i18n.t(languageKeys.title_Xac_nhan)}
      content={""}
      cancelText={i18n.t(languageKeys.huy)}
      danger={danger}
      onOk={({ print, afterSubmit }) => {
        onFinish(print, false, false, danger, afterSubmit)
        confirmRef.current.setVisible(false)
      }}
      showCancelBtn={danger}
      clickOutsideDisabled={!danger}
      onCancel={({ print, afterSubmit }) => onFinish(print, false, false, false, afterSubmit)}
      onPressCancelSuccess={({ print, afterSubmit }) => onFinish(print, false, false, false, afterSubmit)}
    >
      <div className={style.container}>
        <p className={style.title}>
          {danger ? i18n.t(languageKeys.chua_tao_phieu_thu_muon_tao_ko) : i18n.t(languageKeys.vui_long_chon_so_luu_phieu_thu)}
        </p>
        {danger ? <p className={style.note}>{i18n.t(languageKeys.vui_long_chon_so_luu_phieu_thu)}</p> : <></>}

        <Spin spinning={loading}>
          <Radio.Group onChange={(e) => setBookId(e.target.value)} value={bookId} className={style.group}>
            <Space direction="vertical" size={4}>
              {dsSoHoaDon.map(item => (
                <Radio value={item.ID}>{item.TEN_SO}</Radio>
              ))}
            </Space>
          </Radio.Group>
        </Spin>
      </div>
    </ConfirmModal>
  )
}

export default forwardRef(ModalChonSo);