import { FormDrawer, ModalPDF } from "components";
import { apis } from "../../../../../constants";
import { featureKeys } from "constants/keys";
import { handleCheckPermissions, isEmptyObject } from "helpers";
import i18n, { languageKeys } from "i18n";
import DichVuChuaThanhToan from "pages/QuanLyVienPhi/DichVuCanThanhToan/layouts/DichVuChuaThanhToan";
import { useRef } from "react";
import { useMemo } from "react";
import { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "../../../DichVuCanThanhToan/style.module.less";
import { Button, Layout, Space } from "antd";
import { QlyTamUngData, getDsPhTamUng } from "ducks/slices/QlyVienPhi/QlyTamUng.slice";
import { useEventListener } from "hooks";

const FormDvCanTT = forwardRef(({}, ref) => {

  const [open, setOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(); // bệnh nhân đã chọn
  const [cancelItem, setCancelItem] = useState();

  const chuaThanhToanRef = useRef();
  const pdfRef = useRef();

  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state.auth.user);
  const { result: DsPhTamUng } = useSelector(QlyTamUngData)

  const checkRoleArr = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.quan_ly_dich_vu_can_thanh_toan)
  }, [userProfile])

  const checkRoleSoTH = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.quan_ly_so_hoa_don);
  }, [userProfile])

  useImperativeHandle(ref, () => ({
    open: (data, receiptInfo) => {
      setOpen(true)
      setSelectedPatient({ ...data, SO_TIEN_THUC: receiptInfo.SO_TIEN_THUC, PHIEU_UNG_ID: receiptInfo.ID })
    },
  }));

  useEffect(() => {
    setOpen(false)
  }, [DsPhTamUng])

  const printReceipt = (HOA_DON_ID, isMUAGOI) => {
    const dataRequest = {
      HOA_DON_ID,
      partner_code: userProfile.partner_code,
      arr_HOA_DON_ID: isMUAGOI ? HOA_DON_ID : [HOA_DON_ID],
      BENH_NHAN_ID: selectedPatient.BENH_NHAN_ID,
      LICH_KHAM_ID: selectedPatient.LICH_KHAM_ID,
    };
    const api = isMUAGOI ? apis.in_phieu_dtt_mua_goi_dv : apis.in_cac_phieu_dich_vu_da_thanh_toan;
    pdfRef.current.openModal(dataRequest, api);
  };

  // in nhiều phiếu
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const manyPrints = (arrHoaDon = [], printAll = false) => {
    if (selectedPatient && !isEmptyObject(selectedPatient)) {
      const dataRequest = {
        arr_HOA_DON_ID: arrHoaDon,
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        BENH_NHAN_ID: selectedPatient.BENH_NHAN_ID,
        LICH_KHAM_ID: selectedPatient.LICH_KHAM_ID,
        printAll
      };
      const api = selectedPatient.LICH_KHAM_ID ? apis.in_cac_phieu_dich_vu_da_thanh_toan : apis.in_phieu_dtt_mua_goi_dv;
      pdfRef.current.openModal(dataRequest, api);
    }
  };

  const handleSubmit = (print = false) => {
    if (chuaThanhToanRef.current.onSubmit) chuaThanhToanRef.current.onSubmit(print);
  };

  const keydownEvent = (event) => {
    if (event.ctrlKey && event.key === "l") {
      event.stopPropagation();
      event.preventDefault();
      handleSubmit(true)
    }
    if (event.ctrlKey && event.key === "s") {
      event.stopPropagation();
      event.preventDefault();
      chuaThanhToanRef.current?.onSubmit()
    }
  };
  useEventListener("keydown", keydownEvent, window.document, open);

  const Footer = (
    <Space style={{ float: 'right' }}>
      <Button type="primary" ghost onClick={() => setOpen(false)}>
        {i18n.t(languageKeys.huy)} (ESC)
      </Button>
      <Button type="primary" onClick={() => handleSubmit(true)}>
        {i18n.t(languageKeys.vien_phi_thanh_toan_va_in)} (CTRL + L)
      </Button>
      <Button type="primary" onClick={() => chuaThanhToanRef.current?.onSubmit()}>
        {i18n.t(languageKeys.txt_thanh_toan)} (CTRL + S)
      </Button>
    </Space>
  )

  return (
    <FormDrawer
      visible={open}
      width={1060}
      onCancel={() => setOpen(false)}
      title={i18n.t(languageKeys.vien_phi_dich_vu_can_thanh_toan)}
      hiddenTopAction
      footer={Footer}
      back
      newBackIcon
    >
      <Layout className={style["container"]} style={{ overflow: 'hidden', height: 'calc(100vh - 120px)' }}>
        <DichVuChuaThanhToan
          // reloadSiderBar={reloadSider}
          // onThemSo={onThemSo}
          currentPatient={selectedPatient}
          setCancelItem={setCancelItem}
          cancelItem={cancelItem}
          ref={chuaThanhToanRef}
          onPrint={printReceipt}
          onManyPrints={manyPrints}
          tabView={"CHO_THANH_TOAN"}
          onAfterSubmit={() => dispatch(getDsPhTamUng({ page: 1, limit: 15 }))}
          setSelectedPatient={setSelectedPatient}
          // setTabView={setTabView}
          // visibleDrawerSoHoaDon={visibleDrawerSoHoaDon}
          isPayAllowed={checkRoleArr?.THANH_TOAN}
          isDeleteAllowed={checkRoleArr?.XOA_DICH_VU}
          isAddRevenueBookAllowed={checkRoleSoTH?.THEM}
          advanceMode
          scrollY={205}
        />
      </Layout>

      <ModalPDF ref={pdfRef} />
    </FormDrawer>
  )
})

export default FormDvCanTT