/* eslint-disable react-hooks/rules-of-hooks */
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apis, keys } from "../../../../../constants";
import { common_post, HLog, rid } from "../../../../../helpers";

export function hooksPhieuXuat({visible, typePhieu, readOnly}){
    const userProfile = useSelector(state => state.auth.user)
    const [dsKhoThuoc, setDsKhoThuoc] = useState([])
    const [dsNCC, setDsNCC] = useState([])
    const [dsThuoc, setDsThuoc] = useState([])
    const [loadingThuoc, setLoadingThuoc] = useState(false)
    const [dsLyDo, setDsLyDo] = useState([])
    useEffect(() => {
       if (visible && !readOnly) {
        layDsKhoThuoc()
        layDsNCC()
        if (typePhieu === "XUAT_KHAC") {
          layDsLyDoXuat()
        }
       }
        // layDsThuoc()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, typePhieu, readOnly])
    
    async function layDsKhoThuoc(search_string = "" , page = 1){
        try {
            let req = {
                partner_code : userProfile.partner_code,
                BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
                limit : keys.limit,
                page ,
                search_string,
                LOAI_KHO : "",
                LOAI_TRU : [],

            }
            const res = await common_post(apis.lay_ds_kho_thuoc_hieu_luc, req)
            if (res && res.status === "OK") {
                setDsKhoThuoc(res.result.map(item => ({TEN_KHO : item.TEN_KHO, ID : item.ID, LOAI_KHO : item.LOAI_KHO})))
            }
        } catch (error) {
            HLog("error ds kho thuốc", error)
        }
    }

    async function layDsNCC(search_string = "" , page = 1){
        try {
            let req = {
                partner_code : userProfile.partner_code,
                BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
                limit : keys.limit,
                page ,
                search_string,
            }
            const res = await common_post(apis.lay_ds_ncc_hieu_luc, req)
            if (res && res.status === "OK") {
                setDsNCC(res.result.map(item => ({TEN_NCC : item.TEN_NCC, ID : item.ID,})))
            }
        } catch (error) {
            HLog("error ds nhà cung cấp", error)
        }
    }

    async function layDsThuoc (search_string=""){
        setLoadingThuoc(false)
        let body={
          partner_code:userProfile.partner_code,
          BENH_VIEN_ID:userProfile.BENH_VIEN_ID,
          search_string,
            limit :keys.limit,
            page:1,
        }
    
        try {
          let res = await common_post(apis.search_dm_thuoc,body,false)
    
          if(!!res && res.status === "OK"){
            setDsThuoc(res.result.map((item) => { return {
              ...item,
              key:rid(),
            } }))
          }else{
            // notification.error({
            //   message:"lay ds thuoc that bai",
            //   placement:"bottomLeft",
            // })
            return
          }
          setLoadingThuoc(false)
        } catch (error) {
          HLog("TopACtionPhieu:",error)
        }
      };




      const handleSearchThuoc = useCallback(
        debounce((value) => layDsThuoc(value),1000)
        ,[]
      )

      async function layDsLyDoXuat(search_string = "" , page = 1){
        try {
            let req = {
                partner_code : userProfile.partner_code,
                BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
                limit : keys.limit,
                page ,
                search_string,

            }
            const res = await common_post(apis.dm_lay_danh_sach_ly_do_xuat_huy_phieu_thuoc_hieu_luc, req)
            if (res && res.status === "OK") {
                setDsLyDo(res.result.map(item => item))
            }
        } catch (error) {
            HLog("error ds kho thuốc", error)
        }
    }
    

    const handleSearchKhoThuoc = useCallback(
      debounce((value) => layDsKhoThuoc(value),1000)
      ,[]
    )

    const handleSearchNCC = useCallback(
      debounce((value) => layDsNCC(value),1000)
      ,[]
    )
      


    
    return {
        dsKhoThuoc,
        dsNCC,
        dsThuoc,
        loadingThuoc,
        handleSearchThuoc,
        dsLyDo,
        handleSearchKhoThuoc,
        handleSearchNCC
    }
    
}