import { SearchOutlined } from "@ant-design/icons";
import { Empty, Input, Layout, Spin, Tree } from "antd";
import style from "./layouts.module.less";
import i18n, { languageKeys } from "../../../../i18n";
import { memo, useMemo, useImperativeHandle, useState, forwardRef } from "react";
import { useListSample } from "../mauMoTaCdha.hook";
import { HLog, rid } from "../../../../helpers";

const SIDER_WIDTH = 240;

let SideBar = ({ onSelectSample, onDeselectSample, selectedSample }, ref) => {
  const [searchString, setSearchString] = useState();
  const [searchList, setSearchList] = useState([]); // danh sách tìm kiếm, dựa trên danh sách ban đầu
  const { getListSample, listSample, loadingListSample } = useListSample();

  HLog.render("Side Bar");

  useImperativeHandle(ref, () => ({
    setSearchString,
    reset: handleReset,
    refetch: getListSample,
    listSample,
  }));

  // Danh sách mẫu mô tả đang chọn (value của component Tree)
  const selectedKeys = !!selectedSample ? [selectedSample.ID] : [];

  // Danh sách mẫu mô tả được format lại theo chuẩn của Ant Design để truyền vào component TreeData
  const defaultList = useMemo(() => {
    return listSample.map((loai_dv) => ({
      title: loai_dv.TEN_LOAI,
      key: loai_dv.LOAI_DV_ID,
      selectable: false,
      children: loai_dv.DS_MAU.map((mau_mo_ta) => ({
        title: mau_mo_ta.TEN_MAU,
        key: mau_mo_ta.ID,
        value: { ...mau_mo_ta, LOAI_DV_ID: loai_dv.LOAI_DV_ID, TEN_LOAI: loai_dv.TEN_LOAI },
      })),
    }));
  }, [listSample]);

  const handleChangeSearch = (e) => {
    const { value } = e.target;

    setSearchString(value);

    const filteredList = filterData(value);
    setSearchList(filteredList);
  };

  /**
   * Xử lý filter danh sách mẫu mô tả theo 1 string nhập vào thanh tìm kiếm
   * @param {string} search_string từ khoá tìm kiếm mà list cần filter theo
   * @returns {Array<object>}
   */
  const filterData = (search_string) => {
    const callbackFn = (info_mau) => {
      const ten_mau_mo_ta = info_mau.value.TEN_MAU;
      const ma_mau_mo_ta = info_mau.value.MA_MAU;
      // const ds_dich_vu = info_mau.value.DS_DV;

      const filteredByTenMau = ten_mau_mo_ta.toLowerCase().includes(search_string.toLowerCase());
      const filteredByMaMau = ma_mau_mo_ta.toLowerCase().includes(search_string.toLowerCase());
      // const filteredByTenDV = ds_dich_vu.some((dich_vu) => dich_vu.TEN_DICHVU.toLowerCase().includes(search_string.toLowerCase()));
      // filter Chỉ theo tên mẫu và mã mẫu 26/04 theo yêu cầu


      // return filteredByTenMau || filteredByMaMau || filteredByTenDV;
      return filteredByTenMau || filteredByMaMau;
    };

    // filter các loại dịch vụ chứa dịch vụ có tên liên quan đến từ khoá tìm kiếm
    let fitleredList = defaultList.filter((loai_dv) => {
      const ds_mau_mo_ta = loai_dv.children || [];

      return ds_mau_mo_ta.some(callbackFn);
    });

    // filter các dịch vụ có tên liên quan đến từ khoá tìm kiếm
    fitleredList = fitleredList.map((loai_dv) => ({
      ...loai_dv,
      children: loai_dv.children.filter(callbackFn),
    }));

    return fitleredList;
  };

  /**
   * Xử lý khi chọn mẫu mô tả
   * @param {string} key key mẫu mô tả (ID)
   * @param {object} info
   */
  const handleSelect = (key, info) => {
    const { selected, node } = info;
    const sample = node.value;

    if (selected) {
      onSelectSample(sample);
    } else {
      onDeselectSample(sample);
    }
  };

  // Xử lý reset data trên giao diện SideBar về mặc định
  const handleReset = () => {
    setSearchString();
    setSearchList([]);
  };

  const treeData = useMemo(() => {
    return !!searchString ? searchList : defaultList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultList, searchString]);

  const titleRender = (node) => {
    if (!!node.children) return <span className={style["node-title"]}>{node.title}</span>;

    return node.title;
  };

  return (
    <Layout.Sider theme="light" className={style["side-bar"]} width={SIDER_WIDTH}>
      <h4>{i18n.t(languageKeys.title_Danh_sach_mau_mo_ta)}</h4>

      <Input
        prefix={<SearchOutlined className="blue-txt" />}
        placeholder={i18n.t(languageKeys.common_Tim_kiem)}
        value={searchString}
        onChange={handleChangeSearch}
        allowClear
      />

      <div style={{ marginTop: 10 }}  className={style["side-bar-mau-mo-ta"]} >
        <Spin spinning={loadingListSample}>
          {treeData.length > 0 ? (
            <Tree
              className={style["tree-data"]}
              treeData={treeData}
              onSelect={handleSelect}
              defaultExpandAll
              key={rid()}
              blockNode
              selectedKeys={selectedKeys}
              titleRender={titleRender}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Spin>
      </div>
    </Layout.Sider>
  );
};

SideBar = forwardRef(SideBar);
SideBar = memo(SideBar);
export { SideBar };
