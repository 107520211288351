import { Dropdown } from "antd";
import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import style from "./searchDrugPopup.module.less";

import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { apis } from "../../../../../../constants";
import i18n, { languageKeys } from "../../../../../../i18n";
import { Table } from "../../../../../../components";
import { common_post, HLog, rid } from "../../../../../../helpers";

const columns = [
  {
    title: i18n.t(languageKeys.field_Ma_thuoc),
    dataIndex: "Thuoc_Code",
  },
  {
    title: i18n.t(languageKeys.field_Ten_thuoc),
    dataIndex: "Thuoc_Ten",
  },
  {
    title: i18n.t(languageKeys.field_Don_vi_tinh),
    dataIndex: "Thuoc_Dvt",
  },
  {
    title: i18n.t(languageKeys.field_Hoat_chat),
    dataIndex: "Thuoc_HoatChat",
  },
  {
    title: "Tồn khả dụng",
    dataIndex: "TON_CUOI",
  },
  {
    title : "Lô thuốc",
    dataIndex :"MA_LO"
  }
];

const SearchDrugPopup = forwardRef(
  (
    {
      children,
      onAddDrug = () => {},
      focusInput = () => {},
      clearInput = () => {},
      kho_thuoc,
      NCC_THUOC_ID = "",
      typePhieu = ""
    },
    ref
  ) => {
    const userProfile = useSelector((state) => state.auth.user);
    const [visible, setVisible] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [loadingDataSource, setLoadingDataSource] = useState([]);
    const [total, setTotal] = useState(0);
   
    useImperativeHandle(ref, () => ({
      setVisible,
      searchData,
      setDataSource,
      getDataSource
    }));

    
    // hàm lấy danh sách thuốc
    const getDataSource = async (search_string = "", kho = kho_thuoc, NCC = NCC_THUOC_ID) => {
      try {
        const url = apis.search_thuoc_tao_phieu;
        const body = {
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          partner_code: userProfile.partner_code,
          search_string,
          KHO_THUOC_ID :  kho.ID,
          //LOAI_THUOC: kho.LOAI_KHO,
          LOAI_THUOC :null,
          NCC_THUOC_ID : NCC,
          LOAI_PHIEU : typePhieu
        };
        console.log("resqqq body", body)
      setLoadingDataSource(true);
        const res = await common_post(url, body);
        if (res.status === "OK") {
          const list = res.result.map((item) => ({
            ...item,
            key: rid(5),
          }));
          setDataSource(list);
          setTotal(res.total);
        }
        setLoadingDataSource(false);
      } catch (error) {
        HLog(error);
        setLoadingDataSource(true);
      }
    };

    // hàm tìm kiếm thuốc
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchData = useCallback(debounce((txt, khoThuoc, ncc) => getDataSource(txt, khoThuoc, ncc), 1000), []);

    // hàm xử lý hiển thị popup
    const handleVisible = (bool) => {
      setVisible(bool);
      if (typePhieu === "XUAT_TRA_NCC") {
        if (bool && kho_thuoc && NCC_THUOC_ID && dataSource.length === 0) getDataSource();
      }else{
        if (bool && kho_thuoc && dataSource.length === 0) getDataSource();
      }
     
      
    };

    // hàm xử lý khi chọn 1 row thuốc
    const handleClickRow = (record) => {
      onAddDrug(record);
      setVisible(false);
      // clearInput();
      focusInput();
    };

    return (
      <Dropdown
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisible}
        overlayClassName={style["container"]}
        overlay={
          <>
            <h4 className={style["title"]} onClick={focusInput}>
              {i18n.t(languageKeys.field_Ket_qua_tim_kiem)}: {total}
            </h4>

            <div className={style["table-ctn"]}>
              <Table
                columns={columns}
                dataSource={dataSource}
                loading={loadingDataSource}
                scroll={{ y: "50vh" }}
                onClickRow={handleClickRow}
                onHeaderRow={() => ({
                  onClick: focusInput,
                })}
              />
            </div>
          </>
        }
      >
        <div style={{ width: "100%" }}>{children}</div>
      </Dropdown>
    );
  }
);

export default memo(SearchDrugPopup);
