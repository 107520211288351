import Icon, { HomeOutlined, ToolFilled } from "@ant-design/icons";
import { Button, Col, Image, Row, Space } from "antd";
import { Excel, PDF, TeamViewer } from "../../../assets/img";
import { Download } from "../../../assets/svg";
import i18n, { languageKeys, languages } from "../../../i18n";
import style from "./style.module.less";

const CongCuHoTro = () => {
  const tool = [
    {
      src: TeamViewer,
      name: "TeamViewer",
      descrip: i18n.t(languageKeys.phan_mem_ho_tro),
      url: "https://drive.google.com/drive/folders/1NLtIxcfKcNzIbihaLBXOVMR7RC4xO-oI?usp=sharing",
    },
    // {
    //   src: Excel,
    //   name: "Excel",
    //   descrip: "Lorem ipsum dolor sit amet, consectetur ",
    //   url: "https://drive.google.com/file/d/1cP7SkvvKVnAk_U8jFdBzCIJ0x7wMsTDN/view?usp=sharing",
    // },
    // {
    //   src: TeamViewer,
    //   name: "TeamViewer",
    //   descrip: "Lorem ipsum dolor sit amet, consectetur ",
    //   url: "https://drive.google.com/file/d/1cP7SkvvKVnAk_U8jFdBzCIJ0x7wMsTDN/view?usp=sharing",
    // },
    // {
    //   src: PDF,
    //   name: "PDF",
    //   descrip: "Lorem ipsum dolor sit amet, consectetur ",
    //   url: "https://drive.google.com/file/d/1cP7SkvvKVnAk_U8jFdBzCIJ0x7wMsTDN/view?usp=sharing",
    // },
  ];

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className={style["container"]}>
      <Row gutter={[15, 24]}>
        {tool.map((item) => (
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={6}>
            <div className={style["box"]}>
              <Image src={item.src} preview={false} />
              <div style={{ fontSize: "16px", color: "#6576FF", fontWeight: "700", marginTop: "19px" }}>{item.name}</div>
              <div style={{ fontSize: "13px", margin: "6px 45px 19px 45px", color: "#2C3782", fontWeight: "500" }}>{item.descrip}</div>
              <div className={style["line"]}></div>
              <Button
                type="primary"
                style={{ width: "160px" }}
                onClick={(e) => {
                  e.preventDefault();
                  openInNewTab(item.url);
                }}
              >
                {i18n.t(languageKeys.cai_dat)}
              </Button>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default CongCuHoTro;
