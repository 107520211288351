import { Col, Form, Input, Row, Switch, InputNumber } from "antd";
import { Select } from "../../../../components";
import i18n, { languageKeys } from "../../../../i18n";
import {
  fieldBenhVien,
  fieldDuongDung,
  fieldHangSanXuat,
  fieldKhoTuTruc,
  fieldNhomDvKiThuat,
  fieldNhomHoaDon,
  fieldTinh,
  fieldNhomICD10,
  fieldNgheNghiep,
  fieldDantoc,
  fieldQuocGia,
  fieldDonViTinh,
  fieldDanhMucNguonKhach,
  fieldDanhMucCachDung,
  fieldDanhMucHinhThuc,
  fieldCTTT,
  fieldPPTT,
} from "../fieldsDanhMuc";
import formDanhMuc from "./formDanhMuc.module.less";
import FormPhongNoiTru from "./FormPhongNoiTru";
import FormKhoa from "./FormKhoa";
import FormLoaiDvKiThuat from "./FormLoaiDvkt";
import FormDvkt from "./FormDvkt";
import FormNhomDvkt from "./FormNhomDvkt";
import FormHuyen from "./FormHuyen";
import FormXa from "./FormXa";
import FormIcd10 from "./FormIcd10";
import { danhSachHangBv, danhSachTuyenBv } from "../../../../constants/data";

/* ===================================================================================================
 *                           Form danh mục BỆNH VIỆN
 * ===================================================================================================
 */
export const FormBenhVien = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              name={fieldBenhVien.ma_cskcb}
              label={i18n.t(languageKeys.field_Ma_cskcb)}
              rules={[
                {
                  required: true,
                  message: i18n.t(languageKeys.vui_long_nhap),
                },
                {
                  len: 5,
                  message: i18n.t(languageKeys.vui_long_nhap_du_ky_tu),
                },
              ]}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={fieldBenhVien.ten_cskcb}
              label={i18n.t(languageKeys.field_Ten_cskcb)}
              rules={[
                {
                  required: true,
                  message: i18n.t(languageKeys.vui_long_nhap),
                },
              ]}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={fieldBenhVien.tuyen}
              label={i18n.t(languageKeys.field_Tuyen)}
              rules={[
                {
                  required: true,
                  message: i18n.t(languageKeys.vui_long_nhap),
                },
              ]}
            >
              <Select
                placeholder={i18n.t(languageKeys.common_Chon)}
                dataSource={danhSachTuyenBv}
                showSearch
                titleKey="name"
                valueKey="key"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={fieldBenhVien.hang}
              label={i18n.t(languageKeys.field_Hang)}
              rules={[
                {
                  required: true,
                  message: i18n.t(languageKeys.vui_long_nhap),
                },
              ]}
            >
              <Select
                placeholder={i18n.t(languageKeys.common_Chon)}
                dataSource={danhSachHangBv}
                showSearch
                titleKey="name"
                valueKey="key"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>

    {/* <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Dia_chi)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              name={fieldBenhVien.tinh_thanh}
              label={i18n.t(languageKeys.field_Tinh_thanh)}
            >
              <Select placeholder={i18n.t(languageKeys.common_Chon)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={fieldBenhVien.quan_huyen}
              label={i18n.t(languageKeys.field_Quan_huyen)}
            >
              <Select placeholder={i18n.t(languageKeys.common_Chon)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={fieldBenhVien.xa_phuong}
              label={i18n.t(languageKeys.field_Xa_phuong)}
            >
              <Select placeholder={i18n.t(languageKeys.common_Chon)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={fieldBenhVien.dia_chi_chi_tiet}
              label={i18n.t(languageKeys.field_Dia_chi_chi_tiet)}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div> */}

    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Dia_chi)}</h4>

      <Form.Item name="DIA_CHI">
        <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
      </Form.Item>

      <h4>{i18n.t(languageKeys.field_Ghi_chu)}</h4>

      <Form.Item name={fieldBenhVien.ghi_chu}>
        <Input.TextArea rows={3} placeholder={i18n.t(languageKeys.common_Nhap)} />
      </Form.Item>

      <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

      <Form.Item name={fieldBenhVien.hien_thi} valuePropName="checked" initialValue={false}>
        <Switch />
      </Form.Item>
    </div>
  </>
);

/* ===================================================================================================
 *                           Form danh mục KHOA
 * ===================================================================================================
 */

export { FormKhoa };

/* ===================================================================================================
 *                           Form danh mục PHÒNG
 * ===================================================================================================
 */

export { FormPhongNoiTru };

/* ===================================================================================================
 *                           Form danh mục DỊCH VỤ KĨ THUẬT
 * ===================================================================================================
 */
export { FormDvkt };

/* ===================================================================================================
 *                           Form danh mục NHÓM DV KĨ THUẬT
 * ===================================================================================================
 */
export { FormNhomDvkt };

/* ===================================================================================================
 *                           Form danh mục LOẠI DV KĨ THUẬT
 * ===================================================================================================
 */
export { FormLoaiDvKiThuat };

/* ===================================================================================================
 *                           Form danh mục NHÓM HOÁ ĐƠN
 * ===================================================================================================
 */
export const FormNhomHoaDon = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          {/* <Col span={12}>
            <Form.Item
              name={fieldNhomHoaDon.id_nhom}
              label={i18n.t(languageKeys.field_Id_nhom)}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col> */}

          <Col span={12}>
            <Form.Item
              name={fieldNhomHoaDon.ma_nhom}
              label={i18n.t(languageKeys.field_Ma_nhom)}
              rules={[
                {
                  required: true,
                  message: i18n.t(languageKeys.vui_long_nhap),
                },
              ]}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={fieldNhomHoaDon.ten_nhom}
              label={i18n.t(languageKeys.field_Ten_nhom)}
              rules={[
                {
                  required: true,
                  message: i18n.t(languageKeys.vui_long_nhap),
                },
              ]}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>

    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

      <Form.Item name={fieldNhomHoaDon.hien_thi} valuePropName="checked" initialValue={false}>
        <Switch />
      </Form.Item>
    </div>
  </>
);

/* ===================================================================================================
 *                           Form danh mục HÃNG SẢN XUẤT
 * ===================================================================================================
 */
export const FormHangSanXuat = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name={fieldHangSanXuat.ma_hang_sx} label={i18n.t(languageKeys.field_Ma_hang_san_xuat)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldHangSanXuat.ten_hang_sx} label={i18n.t(languageKeys.field_Ten_hang_san_xuat)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>

    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Ghi_chu)}</h4>

      <Form.Item name={fieldHangSanXuat.ghi_chu}>
        <Input.TextArea rows={3} placeholder={i18n.t(languageKeys.common_Nhap)} />
      </Form.Item>
    </div>

    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

      <Form.Item name={fieldHangSanXuat.hien_thi} valuePropName="checked" initialValue={false}>
        <Switch />
      </Form.Item>
    </div>
  </>
);

/* ===================================================================================================
 *                           Form danh mục ĐƠN VỊ TÍNH
 * ===================================================================================================
 */
export const FormDonViTinh = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              name={fieldDonViTinh.ma_dvt}
              label={i18n.t(languageKeys.field_Ma_dvt)}
              rules={
                [
                  // {
                  //   required: true,
                  //   message: i18n.t(languageKeys.vui_long_nhap),
                  // },
                ]
              }
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={fieldDonViTinh.ten_dvt}
              label={i18n.t(languageKeys.field_Ten_dvt)}
              rules={[
                {
                  required: true,
                  message: i18n.t(languageKeys.vui_long_nhap),
                },
              ]}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>

    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Ghi_chu)}</h4>

      <Form.Item name={fieldDonViTinh.ghi_chu}>
        <Input.TextArea rows={3} placeholder={i18n.t(languageKeys.common_Nhap)} />
      </Form.Item>
    </div>

    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

      <Form.Item name={fieldDonViTinh.hien_thi} valuePropName="checked" initialValue={false}>
        <Switch />
      </Form.Item>
    </div>
  </>
);

/* ===================================================================================================
 *                           Form danh mục ĐƯỜNG DÙNG
 * ===================================================================================================
 */
export const FormDuongDung = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name={fieldDuongDung.ma_duong_dung} label={i18n.t(languageKeys.field_Ma_duong_dung)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldDuongDung.ma_duong_dung_theo_byt} label={i18n.t(languageKeys.field_Ma_duong_dung_theo_byt)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name={fieldDuongDung.ten_duong_dung} label={i18n.t(languageKeys.field_ten_duong_dung)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  </>
);

/* ===================================================================================================
 *                           Form danh mục KHO, TỦ TRỰC
 * ===================================================================================================
 */
export const FormKhoTuTruc = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name={fieldKhoTuTruc.ma_kho} label={i18n.t(languageKeys.field_Ma_kho)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldKhoTuTruc.ten_kho} label={i18n.t(languageKeys.field_Ten_kho)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldKhoTuTruc.thu_kho} label={i18n.t(languageKeys.field_Thu_kho)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldKhoTuTruc.loai_kho} label={i18n.t(languageKeys.field_Loai_kho)}>
              <Select placeholder={i18n.t(languageKeys.common_Chon)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldKhoTuTruc.kieu_kho} label={i18n.t(languageKeys.field_Kieu_kho)}>
              <Select placeholder={i18n.t(languageKeys.common_Chon)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>

    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Ghi_chu)}</h4>

      <Form.Item name={fieldKhoTuTruc.ghi_chu}>
        <Input.TextArea rows={3} placeholder={i18n.t(languageKeys.common_Nhap)} />
      </Form.Item>
    </div>
  </>
);

/* ===================================================================================================
 *                           Form danh mục TỈNH
 * ===================================================================================================
 */
export const FormTinh = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name={fieldTinh.id_tinh} label={i18n.t(languageKeys.field_Ma_tinh)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldTinh.ma_tinh_byt} label={i18n.t(languageKeys.field_Ma_tinh_byt)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name={fieldTinh.ten_tinh} label={i18n.t(languageKeys.field_Ten_tinh)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  </>
);

export { FormHuyen };

export { FormXa };

/* ===================================================================================================
 *                           Form danh mục ICD-10
 * ===================================================================================================
 */

export { FormIcd10 };

/* ===================================================================================================
 *                           Form danh mục NHÓM ICD-10
 * ===================================================================================================
 */
export const FormNhomIcd10 = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name={fieldNhomICD10.id_nhom_icd} label={i18n.t(languageKeys.field_Ma_nhom_icd)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldNhomICD10.ten_nhom_icd} label={i18n.t(languageKeys.field_Ten_nhom_icd)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  </>
);

/* ===================================================================================================
 *                           Form danh mục NGHỀ NGHIỆP
 * ===================================================================================================
 */
export const FormNgheNghiep = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name={fieldNgheNghiep.ma_nghe_nghiep} label={i18n.t(languageKeys.field_Ma_nghe_nghiep)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldNgheNghiep.ten_nghe_nghiep} label={i18n.t(languageKeys.field_Ten_nghe_nghiep)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  </>
);

/* ===================================================================================================
 *                           Form danh mục DÂN TỘC
 * ===================================================================================================
 */
export const FormDanToc = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name={fieldDantoc.ma_dan_toc} label={i18n.t(languageKeys.field_Ma_dan_toc)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldDantoc.ten_dan_toc} label={i18n.t(languageKeys.field_Ten_dan_toc)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  </>
);

/* ===================================================================================================
 *                           Form danh mục QUỐC GIA
 * ===================================================================================================
 */
export const FormQuocGia = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name={fieldQuocGia.ma_quoc_gia} label={i18n.t(languageKeys.field_Ma_quoc_gia)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldQuocGia.ten_quoc_gia} label={i18n.t(languageKeys.field_Ten_quoc_gia)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldQuocGia.ten_goi_khac} label={i18n.t(languageKeys.field_Ten_goi_khac)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldQuocGia.ten_goi_viet_tat} label={i18n.t(languageKeys.field_Ten_viet_tat)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>

    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

      <Form.Item name={fieldQuocGia.hien_thi} valuePropName="checked" initialValue={false}>
        <Switch />
      </Form.Item>
    </div>
  </>
);

/* ===================================================================================================
 *                           Form danh mục cách thức PTTT
 * ===================================================================================================
 */
export const FormCachThucPTTT = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_ban)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name={fieldCTTT.MA_CACHTHUC_THUTHUAT} label={i18n.t(languageKeys.field_thuthuat_ma_cttt)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={fieldCTTT.TEN_CACHTHUC_THUTHUAT}
              label={i18n.t(languageKeys.field_thuthuat_ten_cttt)}
              rules={[
                {
                  required: true,
                  message: i18n.t(languageKeys.vui_long_nhap),
                },
              ]}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>

    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Ghi_chu)}</h4>

      <Form.Item name={fieldCTTT.GHI_CHU}>
        <Input.TextArea rows={3} placeholder={i18n.t(languageKeys.common_Nhap)} />
      </Form.Item>
      <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

      <Form.Item name={fieldPPTT.KHOA} valuePropName="checked" initialValue={false}>
        <Switch />
      </Form.Item>
    </div>
  </>
);

/* ===================================================================================================
 *                           Form danh mục PP PTTT
 * ===================================================================================================
 */
export const FormPhuongPhapPTTT = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_ban)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name={fieldPPTT.MA_PHUONGPHAP_THUTHUAT} label={i18n.t(languageKeys.field_thuthuat_ma_pptt)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={fieldPPTT.TEN_PHUONGPHAP_THUTHUAT}
              label={i18n.t(languageKeys.field_thuthuat_ten_pptt)}
              rules={[
                {
                  required: true,
                  message: i18n.t(languageKeys.vui_long_nhap),
                },
              ]}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>

    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Ghi_chu)}</h4>

      <Form.Item name={fieldPPTT.GHI_CHU}>
        <Input.TextArea rows={3} placeholder={i18n.t(languageKeys.common_Nhap)} />
      </Form.Item>
      <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

      <Form.Item name={fieldPPTT.KHOA} valuePropName="checked" initialValue={false}>
        <Switch />
      </Form.Item>
    </div>
  </>
);

/* ===================================================================================================
 *                           Form danh mục PP vô cảm
 * ===================================================================================================
 */
export const FormPPVoCam = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name={fieldQuocGia.ma_quoc_gia} label={i18n.t(languageKeys.field_Ma_quoc_gia)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldQuocGia.ten_quoc_gia} label={i18n.t(languageKeys.field_Ten_quoc_gia)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldQuocGia.ten_goi_khac} label={i18n.t(languageKeys.field_Ten_goi_khac)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={fieldQuocGia.ten_goi_viet_tat} label={i18n.t(languageKeys.field_Ten_viet_tat)}>
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>

    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

      <Form.Item name={fieldQuocGia.hien_thi} valuePropName="checked" initialValue={false}>
        <Switch />
      </Form.Item>
    </div>
  </>
);

export const FormCachDung = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_Thong_tin_co_ban)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          {/* <Col span={12}>
            <Form.Item name={fieldDanhMucCachDung.stt} label={i18n.t(languageKeys.stt)}>
              <InputNumber placeholder={i18n.t(languageKeys.common_Nhap)} min={0} style={{ width: "100%", border: "none" }} />
            </Form.Item>
          </Col> */}

          <Col span={24}>
            <Form.Item
              rules={[{ required: true, whitespace: true, message: i18n.t(languageKeys.vui_long_nhap) }]}
              name={fieldDanhMucCachDung.ten_cach_dung}
              label={i18n.t(languageKeys.ten_cach_dung)}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name={fieldDanhMucCachDung.ghi_chu} label={i18n.t(languageKeys.field_Ghi_chu)}>
              <Input.TextArea rows={5} placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col>
            <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>
          </Col>
          <Col>
            <Form.Item name={fieldDanhMucCachDung.hien_thi} valuePropName="checked" initialValue={false}>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  </>
);

export const FormHinhThuc = () => (
  <>
    <div className={formDanhMuc["wrapper"]}>
      <h4>{i18n.t(languageKeys.field_hinh_thuc_thanh_toan)}</h4>

      <div className={formDanhMuc["inner"]}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name={fieldDanhMucHinhThuc.stt} label={i18n.t(languageKeys.stt)}>
              <InputNumber placeholder={i18n.t(languageKeys.common_Nhap)} min={0} style={{ width: "100%", border: "none" }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              rules={[{ required: true, whitespace: true, message: i18n.t(languageKeys.vui_long_nhap) }]}
              name={fieldDanhMucHinhThuc.ten_hinh_thuc_thanh_toan}
              label={i18n.t(languageKeys.ten_hinh_thuc_thanh_toan)}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name={fieldDanhMucHinhThuc.ghi_chu} label={i18n.t(languageKeys.field_Ghi_chu)}>
              <Input.TextArea rows={5} placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col>
            <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>
          </Col>
          <Col>
            <Form.Item name={fieldDanhMucHinhThuc.hien_thi} valuePropName="checked" initialValue={false}>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  </>
);
