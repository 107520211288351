import { Row, Col, Form, Radio, Input as AntInput, Space, TimePicker } from "antd";
import i18n, { languageKeys } from "../../../../../i18n";
import styles from "./style.module.less";
import { fieldKhamBenh } from "../constantsTTK";
import { KhamSan_CoTuCung, KhamSan_DauOi, KhamSan_DauVetMo, KhamSan_NuocOi, KhamSan_TinhThan, KOCO, NormalState } from "./data";

const KhamSanKTQ = ({ readOnly, disabled }) => {

  const Item = ({ children, ...props }) => (
    <Form.Item style={{ marginBottom: 6 }} {...props}>
      {children}
    </Form.Item>
  )

  const Input = (props) => (
    <AntInput
      placeholder={i18n.t(languageKeys.common_Nhap)}
      readOnly={readOnly}
      disabled={disabled}
      {...props}
    />
  )

  const RadioGroup = ({ children, ...props }) => (
    <Radio.Group disabled={readOnly || disabled} {...props}>
      {children}
    </Radio.Group>
  )

  return (
    <Row gutter={10}>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.tinh_than)}</span>
        <Item
          className={styles.noMinH}
          name={fieldKhamBenh.TINH_THAN}
        >
          <RadioGroup options={KhamSan_TinhThan} />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.phuf)}</span>
        <Item
          className={styles.noMinH}
          name={fieldKhamBenh.PHU}
        >
          <RadioGroup options={KOCO} />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.seo_mo_cu)}</span>
        <Item
          className={styles.noMinH}
          name={fieldKhamBenh.DAU_VET_MO}
        >
          <RadioGroup options={KhamSan_DauVetMo} />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.khung_chau)}</span>
        <Item
          className={styles.noMinH}
          name={fieldKhamBenh.KHUNG_CHAU}
        >
          <RadioGroup options={NormalState} />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.chieu_cao_tu_cung)}</span>
        <Item name={fieldKhamBenh.CHIEU_CAO_TU_CUNG}>
          <Input suffix="cm" />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.vong_bung)}</span>
        <Item name={fieldKhamBenh.VONG_BUNG}>
          <Input suffix="cm" />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.ngoi_thai)}</span>
      </Col>
      <Col span={12}>
        <Item
          name={fieldKhamBenh.NGOI_THAI}
          style={{ marginBottom: 0 }}
        >
          <RadioGroup options={NormalState} />
        </Item>
      </Col>
      <Col span={24}>
        <Item name={fieldKhamBenh.DESC_NGOI_THAI}>
          <Input placeholder={i18n.t(languageKeys.ghi_ro)} />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.con_co_tu_cung)}</span>
      </Col>
      <Col span={12}>
        <Item
          name={fieldKhamBenh.CON_CO_TU_CUNG}
          style={{ marginBottom: 0 }}
        >
          <RadioGroup options={KOCO} />
        </Item>
      </Col>
      <Col span={24}>
        <Item name={fieldKhamBenh.TAN_SO_CON_CO_TU_CUNG}>
          <Input placeholder={i18n.t(languageKeys.tan_so)} />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.tim_thai)}</span>
      </Col>
      <Col span={12}>
        <Item
          name={fieldKhamBenh.TIM_THAI}
          style={{ marginBottom: 0 }}
        >
          <RadioGroup options={KOCO} />
        </Item>
      </Col>
      <Col span={24}>
        <Item name={fieldKhamBenh.NHIP_TIM_THAI}>
          <Input
            placeholder={i18n.t(languageKeys.nhip_tim_thai)}
            suffix={`${i18n.t(languageKeys.field_Lan).toLowerCase()}/${i18n.t(languageKeys.field_phut)}`}
          />
        </Item>
      </Col>

      <Col span={12}>
        <span className={styles.label}>{i18n.t(languageKeys.co_tu_cung)}</span>
      </Col>
      <Col span={12}>
        <Item
          name={fieldKhamBenh.CO_TU_CUNG}
          style={{ marginBottom: 0 }}
        >
          <RadioGroup options={KhamSan_CoTuCung} />
        </Item>
      </Col>
      <Col span={24}>
        <Item name={fieldKhamBenh.CO_TU_CUNG_MO}>
          <Input placeholder={i18n.t(languageKeys.open)} suffix="cm" />
        </Item>
      </Col>

      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.dau_oi)}</span><br />
        <Space style={{ marginTop: -5 }}>
          <Item
            className={styles.noMinH}
            style={{ marginBottom: 0 }}
            name={fieldKhamBenh.DAU_OI}
          >
            <RadioGroup options={KhamSan_DauOi} />
          </Item>
          <Space>
            <span className={styles.label}>{i18n.t(languageKeys.luc)}:</span>
            <Item name={fieldKhamBenh.DAU_OI_TIME} style={{ marginBottom: 0 }}>
              <TimePicker
                placeholder={i18n.t(languageKeys.common_Chon)}
                format="HH:mm"
                style={{ background: "#f5f7fb" }}
              />
            </Item>
          </Space>
        </Space>
      </Col>

      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.nuoc_oi)}</span>
        <Item
          className={styles.noMinH}
          name={fieldKhamBenh.NUOC_OI}
        >
          <RadioGroup options={KhamSan_NuocOi} />
        </Item>
      </Col>

      <Col span={24}>
        <span className={styles.label}>{i18n.t(languageKeys.nhan_xet)}</span>
        <Item name={fieldKhamBenh.NHAN_XET}>
          <AntInput.TextArea
            placeholder={i18n.t(languageKeys.common_Nhap)}
            autoSize
            readOnly={readOnly}
            disabled={disabled}
          />
        </Item>
      </Col>

    </Row>
  )
}

export default KhamSanKTQ;