import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Radio, Space, Spin, notification } from 'antd'
import { layDsHoaDonKhaDung } from '../apisDvThanhToan/apisSiderBar'
import i18n, {languageKeys} from '../../../../i18n'
import style from '../style.module.less'

const ModalDebtList = ({setSoCongNoId = () => {}, soCongNoId = '', visible=false}) => {
    const userProfile = useSelector(state => state.auth.user)

    const [debtList, setDebtList] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getDebtList()
        if(!visible) {
            setSoCongNoId('')
        }
    }, [visible])


    const getDebtList = async () => {
        try {
            setLoading(true)
            let dataRequest = {
                LOAI_SO: 'SO_CONG_NO',
                limit: 1000,
                page: 1,
                NHANSU_ID: userProfile.NHANSU_ID
            };
            const response = await layDsHoaDonKhaDung(dataRequest);
            if(response?.status === 'OK') {
                setDebtList(response?.result)
            } else {
                setDebtList([])
                notification.error({
                    message: i18n.t(languageKeys.common_co_loi_xay_ra)
                })
            }
        } catch (error) {
            notification.error({
                message: i18n.t(languageKeys.common_co_loi_xay_ra)
            })
        } finally {
            setLoading(false)
        }
        
    }

    return (
        <Spin spinning={loading}>
            <div className={style['debt-list-container']}>
                <h4 className={style['title-modal']}>{i18n.t(languageKeys.vui_long_lua_chon_so_luu_cong_no)}</h4>
                <Radio.Group style={{marginLeft: '46px'}} onChange={e => setSoCongNoId(e.target.value)} value={soCongNoId}>
                    <Space direction="vertical">
                        {debtList.map(item => (
                            <Radio key={item.ID} value={item.ID}>{item.TEN_SO}</Radio>
                        ))}
                    </Space>
                </Radio.Group>
            </div>
        </Spin>
    )
}

export default ModalDebtList