import { Input } from "antd"
import i18n, { languageKeys } from "../../../../../i18n"
import { useEffect, useState } from "react"

const InputHDSD = ({ record, handleChangeInput }) => {

  const [hdsd, setHdsd] = useState()

  useEffect(() => {
    setHdsd(record.Chidan)
  }, [record])

  return (
    <Input.TextArea
      placeholder={i18n.t(languageKeys.common_Nhap)}
      value={hdsd}
      onChange={(e) => setHdsd(e.target.value)}
      onBlur={(e) => handleChangeInput(e.target.value, record.STT - 1, "Chidan")}
    />
  )
}

export default InputHDSD