import { Button, Col, Layout, Popover, Row, Spin, Tooltip, notification } from "antd";
import { Trash } from "assets/svg";
import classNames from "classnames";
import { ConfirmModal, Table } from "components";
import { apis } from "../../../constants";
import { getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { TiepDonActions, tiepDonState } from "ducks/slices/QlTiepdon/TiepDon.slice";
import { HLog, common_post, formatCurrency2, rid } from "helpers";
import { useEventListener } from "hooks";
import i18n, { languageKeys } from "i18n";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "../quanLyTiepDon.module.less";

const DanhsachMauDichvu = ({ open, setDsDvClsDaChon = () => {}, setDsDvKhamBenhDaChon = () => {}, setLoadingDv = () => {}, onSelectCombo = () => {}, disabled }) => {

  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.auth.user);
  let { visibleSubActions: visible } = useSelector(tiepDonState);
  const data = useSelector((state) => state.data);
  const { customerTarget } = useSelector(getSharedData);

  const [dsMauDichvu, setDsMauDichvu] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [delData, setDelData] = useState();

  const warnRef = useRef();

  const handleVisible = (bool) => {
    dispatch(TiepDonActions.setVisibleSubActions(bool));
    if (!bool) {
      warnRef.current && warnRef.current.close();
    }
  };

  useEffect(() => {
    if (open && visible) {
      handleGetModelList();
    }
    return () => {
      setDataSource([]);
      setDsMauDichvu([]);
      setSelectedModel();
      setLoading(false);
    }
  }, [open, visible]);

  useEffect(() => {

    if (!open) {
      handleVisible(false);
    }
  }, [open]);

  const handleGetModelList = async () => {
    setLoading(true)
    try {
      let body = {
        search_string: "",
        limit: 100,
        page: 1,
        TIEP_TAN_ID: userProfile.NHANSU_ID,
        //  MAU_ID
      };

      let res = await common_post(apis.layDsMauDichVu, body);
      if (res && res.status === "OK") {
        setDsMauDichvu(res.result.map(item => ({ ...item, key: rid() })));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoadingDv(true);
      let dsDv = selectedModel.dsDv;
      // return 
      if (dsDv && dsDv.length) {
        let DsKhB = []
        let DsCls = []
        dsDv.forEach((item) => {
          if (data.kham_benh.length) {
            const dvkb = data.kham_benh.find(i => i.DV_ID === item.DV_ID)
            if (dvkb) {
              DsKhB = [...DsKhB, {
                ...dvkb,
                SO_LUONG: item.SO_LUONG,
                _id: rid(7),
                key: rid(),
                GIA_DICH_VU: item.GIA_THU_PHI,
                NGUOI_TAO: userProfile,
                THANH_TIEN: item.THANH_TIEN,
                STT: undefined,
                ID: undefined,
                DM_DOI_TUONG_ID: customerTarget?.[0]?.ID
              }]
            }
          }
          if (data.cls.length) {
            const dvcls = data.cls.flatMap(i => i.DICH_VU).find(dv => dv.DV_ID === item.DV_ID)
            if (dvcls) {
              if (dvcls.MA_NHOM_BHYT === "COMBO_DV") {
                DsCls = [...DsCls, {
                  ...dvcls,
                  SO_LUONG: item.SO_LUONG,
                  _id: rid(7),
                  key: rid(),
                  GIA_DICH_VU: item.GIA_THU_PHI,
                  NGUOI_TAO: userProfile,
                  THANH_TIEN: item.THANH_TIEN,
                  PHONG_THUC_HIEN: {},
                  children: dvcls.DV_KEM_THEO?.map(dvkt => ({
                    ...dvkt,
                    _id: rid(7),
                    key: rid(),
                    TEN_DICHVU: dvkt.TEN_DICHVU || dvkt?.TEN_DICH_VU_KEM_THEO,
                  })),
                  DM_DOI_TUONG_ID: customerTarget?.[0]?.ID
                }]
              } else {
                DsCls = [...DsCls, {
                  ...dvcls,
                  SO_LUONG: item.SO_LUONG,
                  _id: rid(7),
                  key: rid(),
                  GIA_DICH_VU: item.GIA_THU_PHI,
                  NGUOI_TAO: userProfile,
                  THANH_TIEN: item.THANH_TIEN,
                  DM_DOI_TUONG_ID: customerTarget?.[0]?.ID
                }]
              }
            }
          }
        })
        setDsDvKhamBenhDaChon(DsKhB)
        setDsDvClsDaChon(DsCls)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDv(false);
      handleVisible(false);
    }
  };

  const handleSelectModel = (dv) => {
    setSelectedModel(dv);

    let { dsDv } = dv;

    if (dsDv && dsDv.length) {
      setDataSource(dsDv);
    }
  }

  const handleDeleteModel = async (dv, type = 'warn') => {
    if (type === 'warn') {
      setDelData(dv);
      warnRef.current.open(dv);
      return;
    }
    try {
      setLoading(true);
      let body = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        MAU_ID: dv.MAU_ID
      }
      let res = await common_post(apis.xoaMauDichVu, body);

      if (res && res.status === 'OK') {
        handleGetModelList();
        setDelData();
        notification.success({ placement: "bottomLeft", message: i18n.t(languageKeys.thao_tac_thanh_cong) })
        warnRef.current.close();
      }

    } catch (error) {

    } finally {
      setLoading(false);
    }
  }

  const keydownEvent = (e) => {
    const stopDefaultAction = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    try {
      if (visible) {
        if (e.ctrlKey && (e.key === "a" || e.key === "A")) {
          stopDefaultAction(e);
          handleSubmit();
        }
        if (e.key === "Escape") {
          stopDefaultAction(e);
          handleVisible(false);
        }
      }
    } catch (error) {
      HLog(error)
    }
  }
  useEventListener("keydown", keydownEvent, window, visible);

  return (
    <Popover
      trigger="click"
      placement="left"
      open={visible && !!open && !disabled}
      onOpenChange={handleVisible}
      showArrow={false}
      overlayClassName={style['popup-classname']}
      overlayStyle={{ visibility: delData ? 'hidden' : 'visible' }}
      content={
        <div className={style["container-mau-dich-vu"]}>
          <Row className={style["header"]} gutter={5}>
            <Col flex={"182px"}>
              <h4>{i18n.t(languageKeys.title_mau_chi_dinh_dich_vu)}</h4>
            </Col>
            <Col>
              {" "}
              <h4>{i18n.t(languageKeys.danh_sach_chi_dinh_dich_vu)}</h4>{" "}
            </Col>
          </Row>
          <Layout className={style["layout"]}>
            <Layout.Sider width={182} className={style["layout-sider"]} theme="light">
              <div className={style["layout-sider-wrapper"]}>
                <Spin spinning={loading}>
                  {dsMauDichvu.map((dv) => {

                    return (
                      <Row
                        key={dv.key}
                        gutter={10}
                        className={classNames(style["sider-item"], selectedModel?.key === dv.key ? style["sider-item-active"] : "")}
                        align={"middle"}
                        onClick={(e) => handleSelectModel(dv)}
                      >
                        <Col style={{ display: "flex" }}>
                          <Trash
                            style={{ margin: "auto" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleDeleteModel(dv);
                            }}
                          />
                        </Col>
                        <Col className={`navy-txt`}>
                          <Tooltip title={dv.TEN_MAU}>
                            <span className={style["txt-ellipsis"]} style={{ verticalAlign: "bottom" }}>
                              {dv.TEN_MAU}
                            </span>
                          </Tooltip>
                        </Col>
                      </Row>
                    );
                  })}
                </Spin>
              </div>
            </Layout.Sider>
            <Layout.Content className={style["layout-content"]}>
              <Table
                className={style['table-mau']}
                columns={[
                  {
                    title: i18n.t(languageKeys.field_Ten_dich_vu),
                    dataIndex: "TEN_DICHVU",
                    key: "TEN_DICHVU",
                  },
                  {
                    align: "center",
                    title: i18n.t(languageKeys.field_don_gia),
                    dataIndex: "GIA_THU_PHI",
                    width: "100px",
                    key: "GIA_THU_PHI",
                    render: (data) => {
                      return <span>{formatCurrency2(data, '', true)}</span>
                    }
                  },
                  {
                    align: "center",
                    title: i18n.t(languageKeys.sl),
                    dataIndex: "SO_LUONG",
                    width: "100px",
                    key: "SO_LLUONG",
                  },
                  {
                    align: "center",
                    title: i18n.t(languageKeys.thanh_tien),
                    dataIndex: "THANH_TIEN",
                    width: "100px",
                    key: "THANH_TIEN",
                    render: (data) => {
                      return <span>{formatCurrency2(data, '', true)}</span>
                    }
                  },
                ]}
                dataSource={dataSource}

                scroll={{ y: 200 }}
              />
              <Layout.Footer className={style['layout-footer']}>
                <Row gutter={10} justify={'end'}>
                  <Col>
                    <Button type="primary" ghost onClick={() => handleVisible(false)}>
                      {i18n.t(languageKeys.common_Thoat)} (ESC)
                    </Button>
                  </Col>

                  <Col>
                    <Button type="primary"
                      onClick={e => handleSubmit()}
                    >{i18n.t(languageKeys.common_Ap_dung)} (Ctrl + A)</Button>
                  </Col>
                </Row>
              </Layout.Footer>
            </Layout.Content>

            <ConfirmModal
              ref={warnRef}
              content={i18n.t(languageKeys.cf_Ban_co_chac_chan_xoa)}
              danger
              onOk={(dv) => handleDeleteModel(dv, "del")}
              onCancel={() => setDelData()}
            />

          </Layout>
        </div>
      }
    >
      <Button type="primary" ghost disabled={disabled}>
        {i18n.t(languageKeys.btn_mau_chi_dinh_dv)}
      </Button>
    </Popover>
  );
};

export default DanhsachMauDichvu;