import formDanhMuc from "./formDanhMuc.module.less";
import { Col, Form, Input, Row, Switch } from "antd";
import { fieldXuatKhac } from "../fieldsDanhMuc";
import i18n, { languageKeys } from "../../../../i18n";

const FormXuatKhac = ({
  initData,
  visible = false,
  isCreateNew = false,
  form,
}) => {
  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={fieldXuatKhac.ma_ly_do}
                label={i18n.t(languageKeys.field_ma_ly_do)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldXuatKhac.ly_do}
                label={i18n.t(languageKeys.field_ly_do)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            {/*==============END ROWS 1*/}
          </Row>
        </div>

        <br />

        <Row>
          <h4 style={{ marginTop: 5 }}>
            {i18n.t(languageKeys.field_Hien_thi)}
          </h4>

          <Form.Item name={fieldXuatKhac.hien_thi} valuePropName="checked">
            <Switch style={{ marginLeft: "20px" }} />
          </Form.Item>
        </Row>
      </div>
    </>
  );
};

export default FormXuatKhac;
