import { Input } from "antd";
import { useState } from "react";
import { Select } from "../../../../../components_v2";
import i18n, { languageKeys } from "../../../../../i18n";

export const SelectTanSuat = ({ readOnly = false, onSelect = () => {}, rowIndex, value }) => {

  const [open, setOpen] = useState(false)

  // return (
  //   <Form.Item name="TAN_SUAT_NGAY" label={i18n.t(languageKeys.tan_suat_sd)}
  //   //  rules={[{ required: true, message: i18n.t(languageKeys.vui_long_chon) }]}
  //    >
  //     {readOnly ? (
  //       <Input readOnly />
  //     ) : (
  //       <Select
  //         dataSource={arrNgay}
  //         labelKey="TAN_SUAT_NGAY"
  //         valueKey="TAN_SUAT_NGAY"
  //         allowClear={false}
  //         onSelect={onSelect}
  //       />
  //     )}
  //   </Form.Item>
  // );
  return readOnly ? <Input readOnly value={value} />
    : (
      <Select
        dataSource={arrNgay}
        labelKey="TAN_SUAT_NGAY"
        valueKey="TAN_SUAT_NGAY"
        onSelect={(val) => {
          onSelect(val, rowIndex, "TAN_SUAT_NGAY")
          setOpen(false)
        }}
        value={value ? `${value} ${i18n.t(languageKeys.txt_ngay)}` : undefined}
        open={open}
        onFocus={() => setTimeout(() => setOpen(true), 100)}
        onBlur={() => setOpen(false)}
        onClick={() => setOpen(!open)}
        onClear={(val) => onSelect(val, rowIndex, "TAN_SUAT_NGAY")}
      />
    )
};

const arrNgay = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
