import { apis, keys } from "../../../constants";
import { common_post, HLog } from "../../../helpers";

export async function layDsLichSuKham(dataRequest) {
  try {
    const res = await common_post(apis.khambenh_lay_lskb_benh_nhan, dataRequest, true);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    HLog("Apis DsBenhNhan layDSPhongBs lỗi: ", error);
    return;
  }
}

export async function layThongTinBenhNhan(dataRequest) {
  try {
    const res = await common_post(apis.khambenh_lay_chi_tiet_benh_nhan, dataRequest, true);
    if (!!res && res.status === "OK") {
      return res;
    }
  } catch (error) {
    HLog("Apis DsBenhNhan layDSPhongBs lỗi: ", error);
    return;
  }
}

export async function layKetQuaXuTri() {
  try {
    const res = await common_post(apis.khambenh_lay_ds_xu_tri, {}, true);
    if (!!res && res.status === "OK") {
      return res;
    }
  } catch (error) {
    HLog("Apis DsBenhNhan layDSPhongBs lỗi: ", error);
    return;
  }
}

export async function layThongTinBenhAn(dataRequest) {
  try {
    let res = await common_post(apis.khambenh_lay_thong_tin_kham, dataRequest, true);
    if (!!res && res.status === "OK") {
      // res.result.KHAM_NOI_GIAC_NGU = res.result.KHAM_NOI_GIAC_NGU ?true : false
      // res.result.THANG_DIEM_STOP_BANG = [
      //   "STOP_BANG_GTNAM",
      //   "STOP_BANG_CHUVI",
      //   "STOP_BANG_TUOI",
      //   "STOP_BANG_MBI"
      // ]
      return res;
    }
    return;
  } catch (error) {
    HLog("Apis DsBenhNhan layDSPhongBs lỗi: ", error);
    return;
  }
}

export async function layDanhSachBenh(search_string, PHAN_NHOM) {
  try {
    const res = await common_post(apis.dm_ICD_lay_ds, {
      page: 1,
      limit: keys.limit,
      ID_ICD: "",
      search_string: search_string,
      PHAN_NHOM,
    });
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    return;
  }
}

export async function luuThongTinKhamBenh(dataRequest) {
  try {
    const res = await common_post(apis.khambenh_luu_thong_tin_kham, dataRequest);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    return;
  }
}

export async function layDsDonThuocKB(dataRequest) {
  try {
    const res = await common_post(apis.khambenh_lay_ds_don_thuoc, dataRequest);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    return;
  }
}

export async function luuDonThuoc(dataRequest) {
  try {
    const res = await common_post(apis.khambenh_luu_don_thuoc, dataRequest);
    if (!!res && res.status === "OK") {
      return res.DON_THUOC_ID;
    }
    return;
  } catch (error) {
    return;
  }
}

export async function xoaDonThuoc(dataRequest) {
  try {
    const res = await common_post(apis.khambenh_xoa_ds_don_thuoc, dataRequest);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    return;
  }
}

export async function layChiTietDonThuoc(dataRequest) {
  try {
    const res = await common_post(apis.khamnbenh_lay_chi_tiet_don_thuoc, dataRequest);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    return;
  }
}

export async function apiLayThongTinDonThuoc({ partner_code = "", LUOT_DIEU_TRI_ID = "", KHO_THUOC = "" }) {
  try {
    const req = {
      partner_code,
      LUOT_DIEU_TRI_ID,
      KHO_THUOC,
    };
    const res = await common_post(apis.khambenh_lay_don_thuoc, req);
    if (!!res && res.status === "OK") {
      return res.result;
    }
  } catch (error) {
    return;
  }
}

export async function apiSaveOnlyChiSoSinhTon(payload) {
  try {
    const res = await common_post(apis.luu_only_csst, payload);
    if (!!res && res.status === "OK") {
      return res;
    }
  } catch (error) {
    return;
  }
}

export async function apiLayKhoaNoiTru({ partner_code = "", search_string = "", BENH_VIEN_ID = "", LOAI_KHOA = "NOI_TRU" }) {
  try {
    const req = {
      partner_code,
      search_string,
      BENH_VIEN_ID,
      limit: keys.limit,
      page: 1,
      LOAI_KHOA,
    };
    const res = await common_post(apis.dm_khoa_lay_ds, req);
    if (!!res && res.status === "OK") {
      return res.result;
    }
  } catch (error) {
    return;
  }
}

export async function apiLichSuKhaiThacKhamBenh(url, payload) {
  try {
    const res = await common_post(url, payload);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    throw error;
  }
}

export async function apiLayDsThuThuatTaiPK(payload) {
  try {
    const res = await common_post(apis.khambenh_lay_ds_thuthuat, payload);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    throw error;
  }
}

export async function apiLayDsDichVuTaiPhongThuThuat(payload) {
  try {
    const res = await common_post(apis.khambenh_lay_ds_dv_thuthuat, payload);
    if (res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    throw error;
  }
}

export async function apiNhapThongTinThuThuat(payload, saveOnly) {
  try {
    const res = await common_post(
      saveOnly ? apis.luuThongTinThuThuat : apis.khambenh_nhap_thong_tin_thu_thuat,
      payload
    );
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    throw error;
  }
}

export async function apiCapNhatHangChoLichKhamDieuTri(payload) {
  try {
    const res = await common_post(apis.cap_nhat_hang_cho_lich_kham, payload);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    throw error;
  }
}

export async function layMauTTTT(payload) {
  try {
    const res = await common_post(apis.lay_mau_thong_tin_thu_thuat, payload);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    throw error;
  }
}

export async function xoaMauTTTT(payload) {
  try {
    const res = await common_post(apis.xoa_mau_thong_tin_thu_thuat, payload);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    throw error;
  }
}

export async function layDsBacSi() {
  try {
    const res = await common_post(apis.layDsBacSiKham, {});
    if (!!res) {
      return res;
    }
    return;
  } catch (error) {
    return;
  }
}

export async function kiemTraLichTaiKham(payload) {
  try {
    const res = await common_post(apis.kiemTraLichTaiKham, payload);
    if (!!res && res.status === "OK" && res.result?.[0]) {
      return res.result;
    }
    return;
  } catch (error) {
    throw error;
  }
}