import { apis } from "../../constants";
import { common_post, HLog } from "../../helpers";

export const apiLayDsCauHoiCuaToi = async ({ NHANSU_ID = "", BENH_VIEN_ID = "", partner_code = "" }) => {
  try {
    const req = {
      NHANSU_ID,
      BENH_VIEN_ID,
      partner_code,
    };
    HLog("apisHoTro apiLayDsCauHoiCuaToi...");
    const res = await common_post(apis.lay_ds_cau_hoi_cua_toi, req, false);

    if (res.status === "OK") {
      return res;
    } else {
      HLog("Lay danh sach cau hoi cua toi fail", res);
      return null;
    }
  } catch (error) {
    HLog("Lay danh sach cau hoi cua toi error", error);
    return null;
  }
};

export const apiLayDsCauHoiThuongGap = async () => {
  try {
    const req = {};
    HLog("apisHoTro apiLayDsCauHoiThuongGap...");
    const res = await common_post(apis.lay_ds_cau_hoi_thuong_gap, req, false);

    if (res.status === "OK") {
      return res;
    } else {
      HLog("Lay danh sach cau hoi thuong gap fail", res);
      return null;
    }
  } catch (error) {
    HLog("Lay danh sach cau hoi thuong gap error", error);
    return null;
  }
};

export const apiTaoCauHoiHoTro = async ({
  NHANSU_ID = "",
  CAU_HOI = "",
  BENH_VIEN_ID = "",
  partner_code = "",
  TEN_CSKCB = "",
  TEN_NHAN_SU = "",
  SO_DIEN_THOAI = "",
  EMAIL = "",
}) => {
  try {
    const req = {
      NHANSU_ID,
      CAU_HOI,
      BENH_VIEN_ID,
      partner_code,
      TEN_CSKCB,
      TEN_NHAN_SU,
      SO_DIEN_THOAI,
      EMAIL,
    };
    HLog("apisHoTro apiTaoCauHoiHoTro...");
    const res = await common_post(apis.gui_cau_hoi, req, false);

    if (res.status === "OK") {
      return res;
    } else {
      HLog("Tao cau hoi fail", res);
      return null;
    }
  } catch (error) {
    HLog("Tao cau hoi error", error);
    return null;
  }
};
